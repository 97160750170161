<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl p-3">
                <div class="text-center text-lg pb-5 pt-3">
                    <BuyConfirmRedeem
                        :campaign="campaign"
                        v-if="type == 'buy'" />
                    <DefaultConfirmRedeem
                        :campaign="campaign"
                        v-if="type == 'default'" />
                    <DeliveryConfirmRedeem
                        :campaign="campaign"
                        v-if="type == 'delivery'" />
                    <DrawConfirmRedeem
                        :campaign="campaign"
                        v-if="type == 'draw'" />
                    <InterfaceConfirmRedeem
                        :campaign="campaign"
                        v-if="type == 'interface'" />
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <button class="w-full bg-gray-400 text-white rounded-md py-1" @click="clickCancel()">
                            {{btnCancel}}
                        </button>
                    </div>
                    <div>
                        <button class="w-full bg-blue-600 text-white rounded-md py-1" @click="clickOk()">
                            {{btnOk}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import { CampaignType } from "@/helper/StaticValue.js";
//component
import BuyConfirmRedeem from './component/BuyConfirmRedeem.vue';
import DefaultConfirmRedeem from './component/DefaultConfirmRedeem.vue';
import DeliveryConfirmRedeem from './component/DeliveryConfirmRedeem.vue';
import DrawConfirmRedeem from './component/DrawConfirmRedeem.vue';
import InterfaceConfirmRedeem from './component/InterfaceConfirmRedeem.vue';

export default {
    name: "ConfirmRedeemModal",
    mixins: [LocaleMixin],
    components: {
        BuyConfirmRedeem,
        DefaultConfirmRedeem,
        DeliveryConfirmRedeem,
        DrawConfirmRedeem,
        InterfaceConfirmRedeem,
    },
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,

            type: null,
            campaign: null,
            btnOk: '',
            btnCancel: '',
            busModal: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function(config) {
            this.campaign = config.campaign;
            this.btnOk = config.btnOk;
            this.btnCancel = config.btnCancel;

            if (Util.isEmptyStr(this.btnOk)) {
                this.btnOk = this.lbl.confirm_redeem.confirm;
            }
            if (Util.isEmptyStr(this.btnCancel)) {
                this.btnCancel = this.lbl.confirm_redeem.cancel;
            }

            this.initialModal();
            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickOk: function () {
            this.hide();
            this.controller.sendCallbackOk();
        },
        clickCancel: function() {
            this.hide();
            this.controller.sendCallbackCancel();
        },

        initialModal: function () {
            if (!this.campaign.delivered) {
                switch (this.campaign.type) {
                    case CampaignType.Buy:
                        this.type = 'buy';
                        break;
                    case CampaignType.Draw:
                        this.type = 'draw';
                        break;
                    case CampaignType.Interface:
                        this.type = 'interface';
                        break;
                    case CampaignType.Free:
                    case CampaignType.Deal:
                        this.type = 'default';
                        break;
                }
            } else {
                this.type = 'delivery';
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/modal.scss";
    .modal {
        @extend %modal;
    }
</style>
