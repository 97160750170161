<template>
    <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 24 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
    >
    <!-- sub_session_show : {{sub_session_show == 'insert_yandetail'||null||''}} -->
        <div>
            <div v-if="sub_session_show == 'insert_yandetail'||sub_session_show ==null||sub_session_show ==undefined">
                <ProductType
                    :formStates="formState"
                    @ProductstypeData="onchangeProductsTypeData($event)"
                    :fact_is_edit="fact_is_edit"
                ></ProductType>
                <div v-if="fact_is_edit.type != 'increasestock'">
                    <a-row v-if="isshowProductYan()">
                        <a-col flex="1" :order="1">
                            <a-form-item>
                                <a-checkbox-group
                                    @change="settypearray"
                                    v-model:value="
                                        formState.userproduct.professortype
                                    "
                                >
                                    <a-space :size="[8, 16]" wrap>
                                        <a-checkbox value="1" name="type"
                                            >อาจารย์เลือกให้</a-checkbox
                                        >
                                        <a-checkbox value="2" name="type"
                                            >รอคุยกับอาจารย์</a-checkbox
                                        >
                                        <a-checkbox value="5" name="type"
                                            >ขอรูปคู่อาจารย์</a-checkbox
                                        >
                                        <a-checkbox value="10" name="type"
                                            >มัดจำแล้วรอคุยกับอาจารย์</a-checkbox
                                        >
                                        <a-checkbox value="11" name="type"
                                            >เพิ่มขนาดไม่ทัน</a-checkbox
                                        >
                                        <a-checkbox value="12" name="type"
                                            >เพิ่มขนาดโดยใช้แทร็คใหม่</a-checkbox
                                        >
                                        <a-checkbox value="13" name="type"
                                            >ย้ายสินค้าเข้าสต๊อก</a-checkbox
                                        >
                                        <a-checkbox value="14" name="type"
                                            >อาจารย์ออกแบบโลโก้</a-checkbox
                                        >
                                    </a-space>
                                    <a-divider
                                        style="height: 1px; background-color: gray"
                                    />
                                </a-checkbox-group>
                                <br />

                                <a-radio-group
                                    @change="settypearray"
                                    v-model:value="
                                        formState.userproduct.senttype
                                    "
                                >
                                    <a-radio value="3" name="type"
                                        >พรีออเดอร์</a-radio
                                    >
                                    <a-radio value="4" name="type"
                                        >ส่งเลย</a-radio
                                    >
                                    <a-radio value="6" name="type"
                                        >จัดส่งพร้อมยันต์</a-radio
                                    ></a-radio-group
                                >
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="3">
                            <a-form-item
                                label="แนบรูปภาพ"
                                :name="['userproduct', 'productyanimage']"
                            >
                                <Uploadfile
                                    :sizeupimage="1"
                                    :contactimageurl="
                                        formState.userproduct.productyanimage
                                    "
                                    @fileList="
                                        formState.userproduct.productyanimage = $event
                                    "
                                ></Uploadfile>
                            </a-form-item>
                        </a-col>
                        <a-col class="p-1" flex="1" :order="2">
                            <a-form-item
                                label="หมายเหตุ"
                                :name="['userproduct', 'introduction']"
                            >
                                <a-textarea
                                    v-model:value="
                                        formState.userproduct.introduction
                                    "
                                    allow-clear
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row v-else>
                        <a-col flex="1 500px" :order="1">
                            <a-form-item>
                                <a-checkbox-group
                                    v-model:value="
                                        formState.userproduct.professortype
                                    "
                                >
                                    <a-checkbox value="4" name="type"
                                        >ส่งเลย</a-checkbox
                                    >
                                    <a-checkbox value="6" name="type"
                                        >จัดส่งพร้อมยันต์</a-checkbox
                                    >
                                    <a-checkbox value="3" name="type"
                                        >พรีออเดอร์</a-checkbox
                                    >
                                    <a-checkbox value="7" name="type"
                                        >จัดส่งทางไลน์</a-checkbox
                                    >
                                    <a-checkbox value="8" name="type"
                                        >รับเอง</a-checkbox
                                    >
                                    <a-checkbox value="9" name="type"
                                        >เก็บเงินปลายทาง</a-checkbox
                                    >
                                </a-checkbox-group>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1" :order="1">
                            <a-form-item
                                label="แนบรูปภาพ"
                                :name="['userproduct', 'productyanimage']"
                            >
                                <Uploadfile
                                    :sizeupimage="1"
                                    :contactimageurl="
                                        formState.userproduct.productyanimage
                                    "
                                    @fileList="
                                        formState.userproduct.productyanimage = $event
                                    "
                                ></Uploadfile>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1" :order="2"></a-col>
                        <a-col flex="1" :order="3"></a-col>
                    </a-row>
                    <a-divider
                        v-if="
                            formState?.userproduct?.professortype?.includes(
                                '14'
                            )
                        "
                        orientation="left"
                        >อาจารย์ออกแบบโลโก้</a-divider
                    >
                    <a-row
                        v-if="
                            formState?.userproduct?.professortype?.includes(
                                '14'
                            )
                        "
                    >
                        <a-col :span="8" flex="5">
                            <a-form-item
                                label="ราคา"
                                :name="['userproduct', 'logoprice']"
                            >
                                <a-textarea
                                    v-model:value="
                                        formState.userproduct.logoprice
                                    "
                                    allow-clear
                                    :auto-size="{ minRows: 1, maxRows: 1 }"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col :span="16" flex="10">
                            <a-form-item
                                label="แนบรูปภาพ"
                                :name="['userproduct', 'productlogoimage']"
                            >
                                <Uploadfile
                                    :sizeupimage="3"
                                    :contactimageurl="
                                        formState.userproduct.productlogoimage
                                    "
                                    @fileList="
                                        formState.userproduct.productlogoimage = $event
                                    "
                                ></Uploadfile>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="รหัสสินค้า"
                            :name="['userproduct', 'productcode']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก'
                            }"
                        >
                            <a-textarea
                                v-model:value="
                                    formState.userproduct.productcode
                                "
                                :auto-size="{ minRows: 1, maxRows: 1 }"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col
                        class="p-1"
                        flex="1 1 "
                        :order="2"
                        :class="{
                            disevent:
                                formState.userproduct.productstatus ==
                                'canceltrack'
                        }"
                    >
                        <a-form-item
                            label="ราคาสินค้า"
                            :name="['userproduct', 'productprice']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก'
                            }"
                        >
                            <a-textarea
                                :disabled="
                                    formState.userproduct.productstatus ==
                                        'canceltrack'
                                "
                                v-model:value="
                                    formState.userproduct.productprice
                                "
                                :auto-size="{ minRows: 1, maxRows: 1 }"
                            />

                            <a-tag
                                class="p-2"
                                v-if="
                                    formState.userproduct.productstatus ==
                                        'canceltrack'
                                "
                                color="success"
                                >ยกเลิกแทร็คแล้ว</a-tag
                            >
                        </a-form-item>
                    </a-col>
                    <a-col
                        class="p-1"
                        flex="1 1 "
                        :order="2"
                        v-if="!isshowProductYan()"
                    >
                        <a-form-item
                            label="จำนวน"
                            :name="['userproduct', 'productprice']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก'
                            }"
                        >
                            <a-input-number
                                id="inputNumber"
                                v-model:value="formState.userproduct.qty"
                                :min="1"
                                :max="2000"
                            />
                        </a-form-item>
                    </a-col>
                    <!-- <a-col class="p-1" flex="1 1 " :order="2" v-if="fact_is_edit.type == 'increasestock'">
                    <a-form-item label="จำนวนสต๊อก" :name="['userproduct', 'productprice']" :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }">
                        <a-input-number id="inputNumber" v-model:value="formState.userproduct.quantitystock" :min="1"
                            :max="10" />
                    </a-form-item>
                </a-col> -->
                    <a-col class="p-1" flex="1 1 " :order="2">
                        <a-form-item
                            v-if="
                                formState.userproduct.originalprice &&
                                    !formState.userproduct.category_2?.match(
                                        'สดขนาดพิเศษ'
                                    )
                            "
                            label="ราคาต้นทุน"
                            :name="['userproduct', 'originalprice']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก'
                            }"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                v-if="formState.userproduct.originalprice"
                                v-model:value="
                                    formState.userproduct.originalprice
                                "
                            />
                        </a-form-item>
                        <a-form-item
                            v-else-if="
                                formState.userproduct.category_2?.match(
                                    'สดขนาดพิเศษ'
                                )
                            "
                            label="ราคาต้นทุน"
                            :name="['userproduct', 'originalprice']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก'
                            }"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                v-if="
                                    formState.userproduct.category_2?.match(
                                        'สดขนาดพิเศษ'
                                    )
                                "
                                v-model:value="
                                    formState.userproduct.originalprice
                                "
                            />
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="2 1 " :order="3">
                        <a-form-item
                            label="คอมมิชชั่น"
                            :name="['userproduct', 'originalprice']"
                        >
                            <a-select
                                v-model:value="
                                    formState.userproduct.usercommission
                                "
                                mode="multiple"
                                :options="commissionoptions"
                                style="width: 250px"
                                @change="handleChange"
                            ></a-select>
                            <div class="p-4">
                                รายละเอียด(แก้ไข)
                                <a-button
                                    class="ml-4"
                                    type="primary"
                                    @click="showModalhistory"
                                    >ประวัติการแก้คอมมิชชั่น</a-button
                                >
                            </div>
                            <div>
                                <a-modal
                                    v-model:visible="visiblehistory"
                                    title="ประวัติการแก้ไขคอมมิชชั่น"
                                    @ok="handleOkhistory"
                                >
                                    <div
                                        v-if="commissionlogdetail?.length == 0"
                                    >
                                        ไม่พบข้อมูลการแก้ไข
                                    </div>
                                    <div
                                        v-if="commissionlogdetail?.length > 0"
                                        v-for="(element,
                                        index) in commissionlogdetail"
                                        :key="`day-${index}`"
                                    >
                                        <div class="p-3">
                                            วันที่แก้ไข :
                                            {{
                                                formatdatehistory(
                                                    element.LatestUpdateDate
                                                )
                                            }}

                                            <div class="p-3">
                                                <div class="p-1">
                                                    <ul>
                                                        <li
                                                            v-for="(element,
                                                            index) in JSON.parse(
                                                                JSON.stringify(
                                                                    element.UserCommissionLog
                                                                )
                                                            )"
                                                            :key="
                                                                `day-${index}`
                                                            "
                                                        >
                                                            {{ element }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </a-modal>
                            </div>
                        </a-form-item>
                    </a-col>
                </a-row>
                <div
                    v-if="
                        (isshowProductYan() || isNAWhasBith()) &&
                            fact_is_edit.type != 'increasestock'
                    "
                >
                    <a-divider orientation="left"
                        >รายชื่อ-วันเกิด (ผ้ายันต์)(1.1)</a-divider
                    >
                    <!-- :::: <pre>{{ dynamicValidateForm }}</pre> -->
                    <div
                        class="p-1"
                        v-for="(sight, index) in dynamicValidateForm.sights"
                        :key="sight.id"
                        style="display: flex; margin-bottom: 8px;flex-direction: column;"
                        align="baseline"
                    >
                        คนที่ {{ index + 1 }}
                        <div class="p-3">
                            <a-switch v-model:checked="sight.isoffice" /> บริษัท
                        </div>
                        <div>
                            <a-row
                                v-if="sight.isoffice"
                                style="display: flex;align-items: center;gap: 10px;"
                            >
                                <a-col style="width:10px">
                                    <MinusCircleOutlined
                                        style="position:absolute;"
                                        @click="removeSight(sight)"
                                    />
                                </a-col>
                                <a-col flex="1 1 0%" :order="1">
                                    <InputText
                                        v-model:inputValue="sight.name"
                                        :errorInfo="errorInfo[1]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.firstnameoffice"
                                        @change="updatelistbristname"
                                    ></InputText>
                                </a-col>
                                <a-col flex="1 1 0%" :order="2">
                                    <InputText
                                        v-model:inputValue="sight.lastname"
                                        :errorInfo="errorInfo[2]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="
                                            lbl.profile.officeregistration
                                        "
                                        @change="updatelistbristname"
                                    ></InputText>
                                </a-col>
                            </a-row>
                            <a-row
                                v-else
                                style="display: flex;align-items: center;gap: 10px;"
                            >
                                <a-col style="width:10px">
                                    <MinusCircleOutlined
                                        style="position:absolute;"
                                        @click="removeSight(sight)"
                                    />
                                </a-col>
                                <a-col flex="1 1 0%" :order="1">
                                    <InputText
                                        v-model:inputValue="sight.name"
                                        :errorInfo="errorInfo[1]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.firstname"
                                        @change="updatelistbristname"
                                    ></InputText>
                                </a-col>
                                <a-col flex="1 1 0%" :order="2">
                                    <InputText
                                        v-model:inputValue="sight.lastname"
                                        :errorInfo="errorInfo[2]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.lastname"
                                        @change="updatelistbristname"
                                    ></InputText>
                                </a-col>
                                <a-col flex="1 1 0%" :order="3">
                                    <InputText
                                        v-model:inputValue="sight.nickname"
                                        :errorInfo="errorInfo[3]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.nickname"
                                        @change="updatelistbristname"
                                    ></InputText>
                                </a-col>
                                <a-col flex="1 1 0%" :order="4">
                                    <InputNationalId
                                        v-model:inputValue="sight.idcard"
                                        :errorInfo="errorInfo[4]"
                                        :isFirstSave="isFirstSave"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.idCard"
                                        @change="updatelistbristname"
                                    >
                                    </InputNationalId>
                                </a-col>
                            </a-row>
                        </div>

                        <div>
                            <a-row
                                style="display: flex;align-items: center;gap: 10px;"
                            >
                                <a-col flex="1 1 0%" :order="5">
                                    <a-form-item
                                        :name="[
                                            'sights',
                                            index,
                                            'typebirthday'
                                        ]"
                                        label="วันเกิด"
                                    >
                                        <a-radio-group
                                            v-model:value="sight.typebirthday"
                                            @change="updatelistbristname"
                                            name="radioGroup"
                                        >
                                            <a-radio value="1"
                                                >ยังไม่แจ้ง</a-radio
                                            >
                                            <a-radio value="2">พ.ศ.</a-radio>
                                            <a-radio value="3">ค.ศ</a-radio>
                                            <a-radio value="4"
                                                >ทราบแค่ปีเกิด</a-radio
                                            >
                                        </a-radio-group>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 0%" :order="6">
                                    <a-form-item
                                        v-if="sight.typebirthday == 3"
                                        class=""
                                        :name="['sights', index, 'birthday']"
                                        :label="
                                            sight.isoffice
                                                ? 'วันก่อตั้ง'
                                                : 'ปีเกิด'
                                        "
                                    >
                                        <a-date-picker
                                            :disabled="isdisabled(sight)"
                                            v-model:value="sight.birthday"
                                            value-format="YYYY-MM-DD"
                                            @change="updatelistbristname"
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        v-if="sight.typebirthday == 4"
                                        class=""
                                        :name="['sights', index, 'birthday']"
                                        :label="
                                            sight.isoffice
                                                ? 'วันก่อตั้ง'
                                                : 'วันเกิด'
                                        "
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="sight.yearbirthday"
                                            placeholder="ปีเกิด"
                                        />
                                    </a-form-item>

                                    <a-form-item
                                        v-if="sight.typebirthday == 2"
                                        class=""
                                        :name="['sights', index, 'birthday']"
                                    >
                                        <DatePickerDropdown
                                            v-model:inputValue="sight.birthday"
                                            :errorInfo="errorInfo[6]"
                                            :isFirstSave="true"
                                            :isShowErrorLbl="true"
                                            :inputLbl="
                                                sight.isoffice
                                                    ? lbl.profile
                                                          .birthdateoffice
                                                    : lbl.profile.birthdate
                                            "
                                            :isISO_8601="true"
                                            @onDateChange="
                                                calculateAgeDropdown(
                                                    sight.birthday,
                                                    index
                                                )
                                            "
                                        >
                                        </DatePickerDropdown>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 0%" :order="7">
                                    <a-form-item
                                        label="อายุ"
                                        v-if="
                                            sight.typebirthday != 1 &&
                                                sight.typebirthday != 4
                                        "
                                    >
                                        <a-input-number
                                            :disabled="isdisabled(sight)"
                                            v-model:value="sight.age"
                                            :min="0"
                                            :max="100"
                                            @change="updatelistbristname"
                                        />
                                        ปี
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 10%" :order="8">
                                    <div
                                        v-if="
                                            sight.typebirthday != 1 &&
                                                sight.typebirthday != 4
                                        "
                                    >
                                        <div
                                            v-if="daybirthrecommend[index]"
                                            class="pl-4"
                                        >
                                            <u>แนะนำวันเกิดวัน </u>
                                            <a-typography-paragraph
                                                :copyable="{ tooltip: false }"
                                            >
                                                วัน{{
                                                    daybirthrecommend[index]
                                                }}
                                            </a-typography-paragraph>
                                        </div>
                                        <a-form-item
                                            label="กรอกเกิดวัน"
                                            :name="[
                                                'sights',
                                                index,
                                                'birthday'
                                            ]"
                                        >
                                            <a-input
                                                :style="{ color: 'green' }"
                                                v-model:value="
                                                    sight.birthdayname
                                                "
                                            />
                                        </a-form-item>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row
                                v-if="
                                    sight.isoffice == false ||
                                        sight.isoffice == null
                                "
                                style="display: flex;align-items: center;gap: 10px;"
                            >
                                <a-col flex="2 1 0%" :order="8">
                                    <div v-if="sight.typebirthday != 1">
                                        <!-- sight.career : {{ sight.career }} -->
                                        <a-form-item
                                            label="อาชีพ"
                                            :name="['userproduct', 'career']"
                                        >
                                            <!-- <a-select v-model:value="sight.career" show-search :filter-option="filterOption"
                                            style="width: 200px" :options="career.map((career) => ({ value: career }))"
                                            @change="updatelistbristname"></a-select> -->
                                            <Career
                                                v-model:inputValue="
                                                    sight.career
                                                "
                                                :initcareer="sight.career"
                                            ></Career>
                                        </a-form-item>
                                    </div>
                                </a-col>
                                <a-col flex="1 1" :order="9">
                                    <div
                                        v-if="
                                            formState.userproduct.category_1 ==
                                                'เคส'
                                        "
                                    >
                                        <a-form-item
                                            :label="
                                                formState.userproduct
                                                    .category_1 == 'เคส'
                                                    ? 'รุ่นโทรศัพท์'
                                                    : 'หมายเหตุ'
                                            "
                                            :name="[
                                                'sights',
                                                index,
                                                'birthday'
                                            ]"
                                        >
                                            <a-textarea
                                                v-model:value="sight.details"
                                                @change="updatelistbristname"
                                            />
                                        </a-form-item>
                                    </div>
                                </a-col>
                                <a-col flex="1 1" :order="9"></a-col>
                                <a-col flex="1 1" :order="9"></a-col>
                            </a-row>
                        </div>
                        <div v-if="false">
                            <a-row>
                                <a-col flex="1 1" :order="7">
                                    <a-form-item
                                        label="อาชีพ"
                                        :name="['userproduct', 'career']"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="sight.career"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="8">
                                    <a-form-item
                                        label="หมายเหตุ"
                                        :name="[
                                            'userproduct',
                                            'introductioncareer'
                                        ]"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                sight.introductioncareer
                                            "
                                            allow-clear
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="9"></a-col>
                            </a-row>
                            <a-row>
                                <a-col flex="1 1" :order="10">
                                    <a-form-item
                                        label="LINE ID"
                                        :name="['userproduct', 'lineid']"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="sight.lineid"
                                        >
                                            <template #prefix>
                                                <img
                                                    class="w-5"
                                                    src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                                />
                                            </template>
                                        </a-input>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="11">
                                    <a-form-item
                                        label="ชื่อ FACEBOOK"
                                        :name="['userproduct', 'facebook']"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="sight.facebook"
                                        >
                                            <template #prefix>
                                                <img
                                                    class="w-5"
                                                    src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg"
                                                />
                                            </template>
                                        </a-input>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="12"></a-col>
                            </a-row>

                            <a-row type="flex">
                                <a-col flex="1 1" :order="13">
                                    <a-form-item
                                        :name="['userproduct', 'phone']"
                                        label="เบอร์ติดต่อ"
                                        :rules="{
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="sight.phone"
                                            placeholder="เบอร์ติดต่อ"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="14">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerphoneemergency'
                                        ]"
                                        label="เบอร์ติดต่อ(ฉุกเฉิน)"
                                        :rules="{
                                            required: true,
                                            message: 'กรุณากรอก'
                                        }"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="
                                                sight.customerphoneemergency
                                            "
                                            placeholder="เบอร์ฉุกเฉิน"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1" :order="15"></a-col>
                            </a-row>
                            <a-row type="flex">
                                <a-col flex="1 1" :order="16">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerphonethai'
                                        ]"
                                        label="เบอร์ติดต่อที่ไทย"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="
                                                sight.customerphonethai
                                            "
                                            placeholder="เบอร์ติดต่อที่ไทย"
                                        />
                                    </a-form-item>
                                </a-col>
                                <!-- <a-col flex="1 1" :order="17">
                                <a-form-item :name="['userproduct', 'email']" label="E-Mail">
                                    <a-input v-model:value="sight.email" placeholder="E-Mail" />
                                </a-form-item>
                            </a-col> -->
                                <a-col flex="1 1" :order="18"> </a-col>
                            </a-row>
                        </div>
                        <hr />
                    </div>
                    <a-form-item>
                        <a-button
                            v-if="ischeckcountyan"
                            type="primary"
                            :style="'width:150px'"
                            block
                            @click="addSight"
                        >
                            <PlusOutlined />
                            เพิ่มรายชื่อ
                        </a-button>
                    </a-form-item>
                </div>
                <div v-if="fact_is_edit.type != 'increasestock'">
                    <div class="p-4">
                        <a-switch v-model:checked="isshowcustomerdescproduct" />
                        ข้อมูลเพิ่มเติม
                    </div>
                    <div v-if="isshowProductYan() && isshowcustomerdescproduct">
                        <!-- <pre>{{ formState.userproduct }}</pre> -->
                        <a-divider orientation="left"
                            >ข้อมูลลูกค้า(1.1)</a-divider
                        >
                        <div>
                            <a-row type="flex">
                                <a-col flex="1 1 500px" :order="1">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerlineid'
                                        ]"
                                        label="LINE ID"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="
                                                formState.userproduct
                                                    .customerlineid
                                            "
                                            placeholder="LINE ID"
                                        >
                                            <template #prefix>
                                                <img
                                                    class="w-5"
                                                    src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                                />
                                            </template>
                                        </a-input>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="2">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerfacebook'
                                        ]"
                                        label="FACEBOOK"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="
                                                formState.userproduct
                                                    .customerfacebook
                                            "
                                            placeholder="FACEBOOK"
                                        >
                                            <template #prefix>
                                                <img
                                                    class="w-5"
                                                    src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg"
                                                />
                                            </template>
                                        </a-input>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="3"></a-col>
                            </a-row>
                            <a-row type="flex">
                                <a-col flex="1 1 500px" :order="1">
                                    <!-- <a-form-item :name="['userproduct', 'customerfacebook']" label="เลขที่อยู่">
                                <a-textarea v-model:value="formState.userproduct.addressdesc" show-count
                                    placeholder="ที่อยู่" :maxlength="100" />
                            </a-form-item> -->
                                    <a-form-item
                                        class=""
                                        :name="[
                                            'userproduct',
                                            'customercountry'
                                        ]"
                                        label="ประเทศ"
                                    >
                                        <!-- formState.user::
                                    <pre> {{formState.user}}</pre>
                                    isfinistini : {{isfinistinit}} -->
                                        <a-radio-group
                                            v-model:value="
                                                formState.userproduct
                                                    .customeraddressType
                                            "
                                            name="radioGroup"
                                        >
                                            <a-radio value="1">ไทย</a-radio>
                                            <a-radio value="2"
                                                >ต่างประเทศ</a-radio
                                            >
                                        </a-radio-group>
                                        <div class="pt-2">
                                            <SelectOptionUserProduct
                                                :formStates="formState"
                                                v-if="
                                                    formState.userproduct
                                                        .customeraddressType ==
                                                        '2' &&
                                                        formState.userproduct
                                                            .selectcountry
                                                "
                                                v-model:value="
                                                    formState.userproduct
                                                        .selectcountry
                                                "
                                                :isshowinput="true"
                                            >
                                            </SelectOptionUserProduct>
                                        </div>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="2"> </a-col>
                                <a-col flex="1 1 500px" :order="3"> </a-col>
                            </a-row>

                            <a-row type="flex">
                                <a-col flex="1 1 500px" :order="1">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerphonethai'
                                        ]"
                                        label="เบอร์ติดต่อไทย(กรณีอยู่ตปท)"
                                        :rules="[
                                            {
                                                required: false,
                                                message: 'กรุณากรอก'
                                            }
                                        ]"
                                    >
                                        <InputContactNumber
                                            :key="update"
                                            v-model:inputValue="
                                                formState.userproduct
                                                    .customerphone
                                            "
                                            :errorInfo="errorInfo[3]"
                                            :isFirstSave="false"
                                            :isShowErrorLbl="false"
                                            :inputLbl="
                                                lbl.shipping.contactnumber
                                            "
                                            :minLength="12"
                                            :maxLength="12"
                                            delimeter="-"
                                        >
                                        </InputContactNumber>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="2">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'customerphoneemergency'
                                        ]"
                                        label="เบอร์ติดต่อ(ฉุกเฉิน)"
                                        :rules="[
                                            {
                                                required: false,
                                                message: 'กรุณากรอก'
                                            }
                                        ]"
                                    >
                                        <InputContactNumber
                                            :key="update"
                                            v-model:inputValue="
                                                formState.userproduct
                                                    .p_customerphoneemergency
                                            "
                                            :errorInfo="errorInfo[3]"
                                            :isFirstSave="false"
                                            :isShowErrorLbl="false"
                                            :inputLbl="
                                                lbl.shipping.contactnumber
                                            "
                                            :minLength="12"
                                            :maxLength="12"
                                            delimeter="-"
                                        >
                                        </InputContactNumber>
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="3"> </a-col>
                            </a-row>
                            <a-row type="flex">
                                <a-col flex="1 1 500px" :order="1">
                                    <!-- {{ formState.userproduct.selectcountry }} -->
                                    <a-form-item
                                        v-if="
                                            formState.userproduct
                                                .customeraddressType != '1' &&
                                                formState.userproduct
                                                    .selectcountry
                                                    .selectcountry != null
                                        "
                                        :name="['userproduct', 'customerphone']"
                                        label="เบอร์ติดต่อ(ต่างประเทศ)"
                                        :rules="[
                                            {
                                                required: false,
                                                message: 'กรุณากรอก'
                                            }
                                        ]"
                                    >
                                        <a-input-group compact>
                                            <a-select
                                                v-if="
                                                    formState.userproduct
                                                        .selectcountry
                                                "
                                                show-search
                                                v-model:value="
                                                    formState.userproduct
                                                        .selectcountry
                                                        .selectcountrycode
                                                "
                                                placeholder="รหัสโทร"
                                                style="width: 100px"
                                                :options="listcodephone"
                                                :filter-option="filterOption"
                                                @focus="handleFocus"
                                                @blur="handleBlur"
                                                @change="handleChange"
                                            ></a-select>
                                            <a-input
                                                :style="{ color: 'green' }"
                                                v-model:value="
                                                    formState.userproduct
                                                        .customerinternationalphone
                                                "
                                                @input="acceptNumber"
                                                style="width: 50%"
                                            ></a-input>
                                        </a-input-group>
                                    </a-form-item>
                                </a-col>
                                <!-- <a-col flex="1 1 500px" :order="2">
                            <a-form-item :name="['userproduct', 'customeremail']" label="E-Mail">
                                <a-input v-model:value="formState.userproduct.customeremail" placeholder="E-Mail" />
                            </a-form-item>
                        </a-col> -->
                                <a-col flex="1 1 500px" :order="3"> </a-col>
                            </a-row>
                            <a-row type="flex">
                                <a-col flex="1 1 500px" :order="1">
                                    <a-form-item
                                        ref=""
                                        :name="[
                                            'userproduct',
                                            'customercareer'
                                        ]"
                                        label="หมายเหตุ"
                                        :rules="[
                                            {
                                                required: false,
                                                message: 'กรุณากรอก'
                                            }
                                        ]"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                formState.userproduct
                                                    .p_customerdesc
                                            "
                                            show-count
                                            placeholder="หมายเหตุ"
                                            :maxlength="20000"
                                            :rows="10"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 500px" :order="2"></a-col>
                                <a-col flex="1 1 500px" :order="3"> </a-col>
                            </a-row>
                        </div>
                    </div>
                </div>
                <div v-if="fact_is_edit.type != 'increasestock'">
                    <div class="p-5 mb-5 card">
                        <pushpin-outlined class="pb-2" />
                        <a-col flex="1" :order="1">
                            <AddressFrom
                                v-if="isshowcustomerdescproduct"
                                :textbtn="'เพิ่มที่อยู่'"
                                :formStates="formState"
                                v-model:value="formState.user.addressfrom"
                            ></AddressFrom>
                        </a-col>
                    </div>
                </div>
            </div>
          
            <a-form-item v-if="sub_session_show == 'insert_system'||sub_session_show =='insert_yandetail'||sub_session_show ==null||sub_session_show ==undefined">
                <a-row v-if="isshowProductYan() && !iscreate" type="flex">
                    <a-col flex="1 1 300px" class="p-1" :order="1">
                        <a-divider orientation="left">ลงระบบ</a-divider>
                        <a-form-item
                            :name="['userproduct', 'queyan']"
                            label="ลงระบบคิวยันต์"
                        >
                            <a-radio-group
                                v-model:value="formState.userproduct.queyan"
                                name="radioGroup"
                            >
                                <a-radio value="1">ยังไม่ได้ลงระบบ</a-radio>
                                <a-radio value="2">ลงระบบแล้ว</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" class="p-1" :order="2"> </a-col>
                </a-row>
                <div class="card-container">
                    <a-modal
                        v-model:visible="visiblereject"
                        title="กรอกรายละเอียด"
                        @ok="handleOkreject"
                    >
                        <a-textarea
                            v-model:value="descmessage"
                            placeholder=""
                            :auto-size="{ minRows: 3, maxRows: 5 }"
                        />
                    </a-modal>
                    <a-tabs
                        v-if="formState.userproduct.queyan == 2"
                        v-model:activeKey="tab_yan_insert_system"
                        type="card"
                    >
                        <a-tab-pane key="1" tab="ตรวจสอบลงระบบ">
                            <div class="p-3">
                                <div>
                                    1.เมื่อ "เงินเข้าเรียบร้อย" สามารถลงระบบได้
                                </div>
                                <div>
                                    2.เมื่อ "ลงระบบแล้ว" สามารถลง คิวเขียนได้
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="ลงระบบ">
                            <div
                                :class="{ disablecss: checking_1 == 'checked' }"
                            >
                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <a-col
                                        flex="1 1 300px"
                                        class="p-1"
                                        :order="1"
                                    >
                                        <a-form-item
                                            :name="['userproduct', 'trackyan']"
                                            label="เลขแท็ก*"
                                        >
                                            <a-input
                                                :style="{ color: 'green' }"
                                                v-model:value="
                                                    formState.userproduct
                                                        .systemtrackyan
                                                "
                                                placeholder="Tracking"
                                            />
                                        </a-form-item>
                                    </a-col>
                                    <a-col
                                        flex="1 1 300px"
                                        class="p-1"
                                        :order="1"
                                    >
                                    </a-col>
                                </a-row>
                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <a-form-item
                                        :name="['userproduct', 'queyandate']"
                                        label="วันที่ลงระบบ"
                                    >
                                        <a-col
                                            :span="12"
                                            flex="1"
                                            class="p-1"
                                            :order="1"
                                        >
                                            <DatePickerDropdown
                                                v-if="!updateresetdate"
                                                v-model:inputValue="
                                                    formState.userproduct
                                                        .systemyandate
                                                "
                                                :errorInfo="errorInfo[6]"
                                                :isFirstSave="true"
                                                :isShowErrorLbl="true"
                                                :inputLbl="lbl.profile.quedate"
                                                :isISO_8601="true"
                                            >
                                            </DatePickerDropdown>
                                            <a-button
                                                type="primary"
                                                @click="
                                                    resetdate('systemyandate')
                                                "
                                                >เครียค่าวันที่</a-button
                                            >
                                        </a-col>
                                    </a-form-item>
                                </a-row>
                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <!-- formState.userproduct.queyanimage :{{ formState.userproduct.queyanimage }} -->
                                    <a-col
                                        flex="1 1 300px"
                                        class="p-1"
                                        :order="1"
                                    >
                                        <a-form-item
                                            :name="[
                                                'userproduct',
                                                'queyanimage'
                                            ]"
                                            label="แนบรูป"
                                        >
                                            <Uploadfile
                                                :sizeupimage="1"
                                                :contactimageurl="
                                                    formState.userproduct
                                                        .systemyanimage
                                                "
                                                @fileList="
                                                    formState.userproduct.systemyanimage = $event
                                                "
                                            ></Uploadfile>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row
                                    v-if="
                                        formState.userproduct
                                            .systemchecktabactive_1
                                    "
                                >
                                    <a-col :span="24">
                                        <u>ประวัติ</u>
                                        <div
                                            v-for="(item, idx) of JSON.parse(
                                                formState.userproduct
                                                    .systemchecktabactive_1
                                            )"
                                            :key="`v-list-item-${idx}`"
                                        >
                                            <a-row>
                                                <a-col :span="4">
                                                    สถานะ:
                                                    <a-typography-text
                                                        v-if="
                                                            item.checking ==
                                                                'reject'
                                                        "
                                                        type="danger"
                                                        strong
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-xmark"
                                                        />
                                                        ไม่ผ่าน</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        v-else-if="
                                                            item.checking ==
                                                                'other'
                                                        "
                                                        type="warning"
                                                        strong
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-xmark"
                                                        />
                                                        ยังระบุไม่ได้</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        type="success"
                                                        strong
                                                        v-else
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-check"
                                                        />
                                                        ผ่าน</a-typography-text
                                                    >
                                                </a-col>
                                                <a-col :span="8">
                                                    รายละเอียด:
                                                    <a-typography-text
                                                        v-if="
                                                            item.checking ==
                                                                'reject'
                                                        "
                                                        type="danger"
                                                        strong
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        v-else-if="
                                                            item.checking ==
                                                                'other'
                                                        "
                                                        type="warning"
                                                        strong
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        type="success"
                                                        strong
                                                        v-else
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                </a-col>
                                                <a-col :span="8">
                                                    <div>
                                                        วันที่:
                                                        {{
                                                            formatdate(
                                                                item.updatedate
                                                            )
                                                        }}
                                                    </div>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-col>
                                    <a-col :span="12"></a-col>
                                </a-row>
                            </div>
                            <div>
                                <a-form-item
                                    label=""
                                    name="paiddelivery"
                                    :class="{
                                        disevent:
                                            formState.userproduct
                                                .productstatus == 'canceltrack'
                                    }"
                                >
                                    <div
                                        v-if="
                                            checkgrantcancel(
                                                Storedata.state.profile.login
                                                    .role
                                            ) &&
                                                formState.userproduct
                                                    .systemtrackyan
                                        "
                                    >
                                        <a-popconfirm
                                            :title="
                                                'ถ้ายกเลิกเลข Track ราคาทุนและราคาสินค้าในระบบจะเป็น 0 บาท'
                                            "
                                            @confirm="canceltrack()"
                                            @cancel="cancel"
                                        >
                                            <a-button
                                                :disabled="
                                                    formState.userproduct
                                                        .productstatus ==
                                                        'canceltrack'
                                                "
                                                type="danger"
                                                >ยกเลิกเลขแท็ก
                                                {{
                                                    formState.userproduct
                                                        .productstatus ==
                                                    "canceltrack"
                                                        ? "(ยกเลิกแล้ว)"
                                                        : ""
                                                }}</a-button
                                            >
                                        </a-popconfirm>
                                        <UpdateStatusCancelTrack
                                            v-if="isshowpopcanceltrack"
                                            :formState="formState"
                                            :title="'ยกเลิกเลขแท็ก'"
                                            :subtitle="
                                                '*เมื่อยกเลิกเลข Track ราคาทุนและราคาสินค้าในระบบจะเป็น 0 บาท'
                                            "
                                            @close="
                                                setshowpopupcanceltrack($event)
                                            "
                                        ></UpdateStatusCancelTrack>
                                    </div>
                                </a-form-item>
                            </div>
                            <div
                                v-if="Storedata.methods.isnoaccount()"
                                class="p-4"
                                style="float: right"
                            >
                                <a-radio-group v-model:value="checking_1">
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'checked'"
                                        >ผ่าน</a-radio
                                    >
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'reject'"
                                        >ไม่ผ่าน</a-radio
                                    >
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'other'"
                                        >ยังระบุไม่ได้</a-radio
                                    >
                                </a-radio-group>
                                <a-popconfirm
                                    title="ต้องการอัพเดทตรวจสอบการเงิน?"
                                    @confirm="confirm('1')"
                                    @cancel="cancel"
                                >
                                    <a-button type="primary"
                                        >อัพเดทตรวจสอบการเงิน</a-button
                                    >
                                </a-popconfirm>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane
                            key="3"
                            tab="ลงคิวเขียน"
                            :disabled="checking_1 != 'checked'"
                        >
                            <div>
                                <a-space direction="vertical">
                                    <a-switch
                                        v-model:checked="
                                            formState.userproduct
                                                .setting_issentnotiqueuecustomer
                                        "
                                        checked-children="ตั้งค่า ส่ง line ให้ลูกค้า"
                                        un-checked-children="ตั้งค่า ไม่ส่ง line ให้ลูกค้า"
                                    />
                                    <div>
                                        <a-badge
                                            v-if="
                                                formState.userproduct
                                                    .issentnotiqueuecustomer
                                            "
                                            status="success"
                                            text="ระบบได้ส่ง line ให้ลูกค้าแล้ว"
                                        />
                                        <a-badge
                                            v-if="
                                                !formState.userproduct
                                                    .issentnotiqueuecustomer
                                            "
                                            status="default"
                                            text="ยังไม่ได้ส่งให้ลูกค้า"
                                        />
                                    </div>
                                </a-space>
                            </div>
                            <div>
                                <!-- :class="{ 'disablecss': (checking_1 == 'checked' && checking_2 == 'checked') || checking_1 != 'checked' }" -->

                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <a-col
                                        flex="1 1 300px"
                                        class="p-1"
                                        :order="1"
                                    >
                                        <a-form-item
                                            :name="['userproduct', 'trackyan']"
                                            label="เลขแท็ก*"
                                        >
                                            <a-input
                                                :style="{ color: 'green' }"
                                                v-model:value="
                                                    formState.userproduct
                                                        .trackyan
                                                "
                                                placeholder="Tracking"
                                            />
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <a-form-item
                                        :name="['userproduct', 'queyandate']"
                                        label="วันที่คิวเขียน"
                                    >
                                        <a-col
                                            :span="12"
                                            flex="1"
                                            class="p-1"
                                            :order="1"
                                        >
                                            <!-- {{ formState.userproduct.queyandate }} -->

                                            <DatePickerDropdown
                                                v-if="!updateresetdate"
                                                v-model:inputValue="
                                                    formState.userproduct
                                                        .queyandate
                                                "
                                                :errorInfo="errorInfo[6]"
                                                :isFirstSave="true"
                                                :isShowErrorLbl="true"
                                                :inputLbl="lbl.profile.quedate"
                                                :isISO_8601="true"
                                            >
                                            </DatePickerDropdown>
                                            <a-button
                                                type="primary"
                                                @click="resetdate('queyandate')"
                                                >เครียค่าวันที่</a-button
                                            >
                                        </a-col>
                                    </a-form-item>
                                </a-row>
                                <a-row
                                    type="flex"
                                    v-if="formState.userproduct.queyan == 2"
                                >
                                    <!-- formState.userproduct.queyanimage :{{ formState.userproduct.queyanimage }} -->
                                    <a-col
                                        flex="1 1 300px"
                                        class="p-1"
                                        :order="1"
                                    >
                                        <a-form-item
                                            :name="[
                                                'userproduct',
                                                'queyanimage'
                                            ]"
                                            label="แนบรูป"
                                        >
                                            <Uploadfile
                                                :sizeupimage="1"
                                                :contactimageurl="
                                                    formState.userproduct
                                                        .queyanimage
                                                "
                                                @fileList="
                                                    formState.userproduct.queyanimage = $event
                                                "
                                            ></Uploadfile>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row v-if="false">
                                    <a-col :span="24">
                                        <u>ประวัติ</u>
                                        <div
                                            v-for="(item, idx) of JSON.parse(
                                                JSON.stringify(
                                                    formState.userproduct
                                                        .systemchecktabactive_2
                                                )
                                            )"
                                            :key="`v-list-item-${idx}`"
                                        >
                                            <a-row>
                                                <a-col :span="4">
                                                    สถานะ:
                                                    <a-typography-text
                                                        v-if="
                                                            item.checking ==
                                                                'reject'
                                                        "
                                                        type="danger"
                                                        strong
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-xmark"
                                                        />
                                                        ไม่ผ่าน</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        v-else-if="
                                                            item.checking ==
                                                                'other'
                                                        "
                                                        type="warning"
                                                        strong
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-xmark"
                                                        />
                                                        ยังระบุไม่ได้</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        type="success"
                                                        strong
                                                        v-else
                                                    >
                                                        <font-awesome-icon
                                                            icon="fa-solid fa-check"
                                                        />
                                                        ผ่าน</a-typography-text
                                                    >
                                                </a-col>
                                                <a-col :span="8">
                                                    รายละเอียด:
                                                    <a-typography-text
                                                        v-if="
                                                            item.checking ==
                                                                'reject'
                                                        "
                                                        type="danger"
                                                        strong
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        v-else-if="
                                                            item.checking ==
                                                                'other'
                                                        "
                                                        type="warning"
                                                        strong
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                    <a-typography-text
                                                        type="success"
                                                        strong
                                                        v-else
                                                    >
                                                        {{
                                                            item.message || "-"
                                                        }}</a-typography-text
                                                    >
                                                </a-col>
                                                <a-col :span="8">
                                                    <div>
                                                        วันที่:
                                                        {{
                                                            formatdate(
                                                                item.updatedate
                                                            )
                                                        }}
                                                    </div>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </a-col>
                                    <a-col :span="12"></a-col>
                                </a-row>
                            </div>
                            <div
                                v-if="false && Storedata.methods.isnoaccount()"
                                class="p-4"
                                style="float: right"
                            >
                                <a-radio-group v-model:value="checking_2">
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'checked'"
                                        >ผ่าน</a-radio
                                    >
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'reject'"
                                        >ไม่ผ่าน</a-radio
                                    >
                                    <a-radio
                                        :style="radioStyle"
                                        :value="'other'"
                                        >ยังระบุไม่ได้</a-radio
                                    >
                                </a-radio-group>
                                <a-popconfirm
                                    title="ต้องการอัพเดทตรวจสอบการเงิน?"
                                    @confirm="confirm('2')"
                                    @cancel="cancel"
                                >
                                    <a-button type="primary"
                                        >อัพเดทตรวจสอบการเงิน</a-button
                                    >
                                </a-popconfirm>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane
                            key="4"
                            :disabled="checking_1 != 'checked'"
                            tab="ยันต์มาแล้ว"
                        >
                            <div>
                                <a-space direction="vertical">
                                    <a-switch
                                        v-model:checked="
                                            formState.userproduct
                                                .setting_issentnoticomingyancustomer
                                        "
                                        checked-children="ตั้งค่า ส่ง line ให้ลูกค้า"
                                        un-checked-children="ตั้งค่า ไม่ส่ง line ให้ลูกค้า"
                                    />
                                    <div>
                                        <a-badge
                                            v-if="
                                                formState.userproduct
                                                    .issentnoticomingyancustomer
                                            "
                                            status="success"
                                            text="ระบบได้ส่ง line ให้ลูกค้าแล้ว"
                                        />
                                        <a-badge
                                            v-if="
                                                !formState.userproduct
                                                    .issentnoticomingyancustomer
                                            "
                                            status="default"
                                            text="ยังไม่ได้ส่งให้ลูกค้า"
                                        />
                                    </div>
                                </a-space>
                            </div>
                            <a-row
                                type="flex"
                                v-if="formState.userproduct.queyan == 2"
                            >
                                <a-col flex="1 1 300px" class="p-1" :order="1">
                                    <a-form-item
                                        :name="['userproduct', 'trackyan']"
                                        label="เลขแท็ก*"
                                    >
                                        <a-input
                                            :style="{ color: 'green' }"
                                            v-model:value="
                                                formState.userproduct.trackyan
                                            "
                                            placeholder="Tracking"
                                        />
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row
                                type="flex"
                                v-if="formState.userproduct.queyan == 2"
                            >
                                <a-form-item
                                    :name="['userproduct', 'yancomingdate']"
                                    label="วันที่ยันต์มาแล้ว"
                                >
                                    <a-col
                                        :span="12"
                                        flex="1"
                                        class="p-1"
                                        :order="3"
                                    >
                                        <DatePickerDropdown
                                            v-model:inputValue="
                                                formState.userproduct
                                                    .yancomingdate
                                            "
                                            :errorInfo="errorInfo[6]"
                                            :isFirstSave="true"
                                            :isShowErrorLbl="true"
                                            :inputLbl="lbl.profile.quedate"
                                            :isISO_8601="true"
                                        >
                                        </DatePickerDropdown>
                                    </a-col>
                                </a-form-item>
                            </a-row>
                            <a-row
                                type="flex"
                                v-if="formState.userproduct.queyan == 2"
                            >
                                <a-col flex="1 1 300px" class="p-1" :order="4">
                                    <a-form-item
                                        :name="[
                                            'userproduct',
                                            'comingyanimage'
                                        ]"
                                        label="แนบรูป"
                                    >
                                        <Uploadfile
                                            :sizeupimage="1"
                                            :contactimageurl="
                                                formState.userproduct
                                                    .comingyanimage
                                            "
                                            @fileList="
                                                formState.userproduct.comingyanimage = $event
                                            "
                                        ></Uploadfile>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-row
                                type="flex"
                                v-if="formState.userproduct.queyan == 2"
                            >
                                <a-col flex="1 1 300px" class="p-1" :order="2">
                                    <a-form-item
                                        label="หมายเหตุ"
                                        :name="['userproduct', 'introduction']"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                formState.userproduct
                                                    .remindsentcomingdesc
                                            "
                                            show-count
                                            placeholder="หมายเหตุ"
                                            :maxlength="20000"
                                            :rows="4"
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col flex="1 1 300px" class="p-1" :order="1">
                                    <a-form-item
                                        :name="['userproduct', 'trackyan']"
                                        label="การยืนยันที่อยู่*"
                                    >
                                        <!-- <pre>::{{ customer_not_confirm_address_already }}</pre> -->
                                        <a-radio-group
                                            v-model:value="
                                                customer_not_confirm_address_already
                                            "
                                            style="width: 100%"
                                        >
                                            <a-row>
                                                <a-col :span="8">
                                                    <a-radio value="confirm"
                                                        >ลูกค้า(ยืนยันที่อยู่แล้ว)</a-radio
                                                    >
                                                </a-col>
                                                <a-col :span="8">
                                                    <a-radio
                                                        value="donotconfirm"
                                                        >ลูกค้า(ยังไม่ยืนยันที่อยู่)</a-radio
                                                    >
                                                </a-col>
                                            </a-row>
                                        </a-radio-group>
                                    </a-form-item>
                                </a-col>
                            </a-row>
                            <a-radio-group
                                v-model:value="remind_notpay_delivery"
                                name="radioGroup"
                            >
                                <a-radio value="paid"
                                    >รอชำระค่าจัดส่ง (ลูกค้าไม่เห็น)</a-radio
                                >
                                <a-radio value="notpay"
                                    >ลูกค้าชำระค่าจัดส่งแล้ว</a-radio
                                >
                            </a-radio-group>
                            <a-row type="flex">
                                <a-col flex="1" class="p-1" :order="1">
                                    <div
                                        v-if="remind_notpay_delivery == 'paid'"
                                    >
                                        <a-divider orientation="left"
                                            ><i
                                                ><b
                                                    >กรณีลูกค้ายังไม่ชำระค่าส่ง(ลูกค้าไม่เห็น)</b
                                                ></i
                                            ></a-divider
                                        >
                                        <a-form-item
                                            label="รอชำระจัดส่ง(ลูกค้าไม่เห็น)"
                                            name="paiddelivery"
                                        >
                                            <PaidRemind
                                                :formStates="formState"
                                                v-model:value="paiddeliveryobj"
                                                :type="type"
                                            >
                                            </PaidRemind>
                                        </a-form-item>
                                    </div>
                                    <div
                                        v-if="
                                            remind_notpay_delivery == 'notpay'
                                        "
                                    >
                                        <a-form-item
                                            label="ลูกค้าชำระค่าจัดส่งแล้ว"
                                            name="paiddelivery"
                                        >
                                            <a-textarea
                                                v-model:value="
                                                    formState.userproduct
                                                        .remindsentdesc
                                                "
                                                show-count
                                                placeholder="หมายเหตุ"
                                                :maxlength="20000"
                                                :rows="10"
                                            />
                                        </a-form-item>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <!-- paiddeliveryobj :{{paiddeliveryobj}} 
                formState.userproduct: {{formState.userproduct}} -->
                <a-row type="flex" v-if="fact_is_edit.type != 'increasestock'">
                    <a-col flex="1" class="p-1" :order="1">
                        <a-form-item label="ขนาด" name="paiddelivery">
                            <!-- <a-input /> -->
                            <PaidDelivery
                                :formStates="formState"
                                v-model:value="paiddeliveryobj"
                                :type="type"
                            >
                            </PaidDelivery>
                        </a-form-item>
                    </a-col>
                </a-row>
                <!-- //submit -->
                <!-- <a-button type="primary" html-type="submit">บันทึก</a-button> -->
                <!-- //submit -->
                <a-row
                    type="flex"
                    v-if="
                        formState.userproduct.productstatus != 'cancel' &&
                            fact_is_edit.type != 'increasestock'
                    "
                >
                    <a-col flex="1" class="p-1" :order="1">
                        <a-form-item label="ยกเลิก" name="paiddelivery">
                            <div
                                v-if="
                                    checkgrantcancel(
                                        Storedata.state.profile.login.role
                                    )
                                "
                            >
                                <u
                                    class="text-cancel"
                                    @click="isshowpopcancel = !isshowpopcancel"
                                    >ยกเลิกสินค้า</u
                                >
                                <UpdateStatusPopup
                                    v-if="isshowpopcancel"
                                    :formState="formState"
                                    :title="'ยกเลิกสินค้า'"
                                    :subtitle="
                                        '*เมื่อยกเลิกสินค้าแล้วจะไม่สามารถเรียกกลับได้'
                                    "
                                    @close="setshowpopup($event)"
                                ></UpdateStatusPopup>
                            </div>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row
                    type="flex"
                    v-if="formState.userproduct.productstatus == 'cancel'"
                >
                    <a-col flex="1" class="p-1" :order="1">
                        <a-form-item label="ยกเลิก" name="paiddelivery">
                            <div
                                v-if="
                                    checkgrantcancel(
                                        Storedata.state.profile.login.role
                                    )
                                "
                            >
                                <a-typography-text
                                    type="success"
                                    strong
                                    v-if="
                                        formState.userproduct.productstatus ==
                                            'cancel'
                                    "
                                    >ยกเลิกสินค้าเรียบร้อย
                                </a-typography-text>
                            </div>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row
                    type="flex"
                    v-if="formState.userproduct.productstatus != 'cancel'"
                >
                    <a-form-item :wrapper-col="{ offset: 16, span: 16 }">
                        <a-button
                            :style="'float: right;'"
                            type="primary"
                            html-type="submit"
                            >ตกลง</a-button
                        >
                    </a-form-item>
                </a-row>
            </a-form-item>
        </div>
    </a-form>
    <!-- <pre>formState: {{formState}}</pre> -->
</template>
<script>
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import UpdateStatusPopup from "./UpdateStatusPopup.vue";
import UpdateStatusCancelTrack from "./UpdateStatusCancelTrack.vue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch, onMounted, toRefs } from "vue";
import Uploadfile from "../form/Uploadfile.vue";
import PaidDelivery from "../form/PaidDelivery.vue";
import PaidRemind from "../form/PaidRemind.vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import axios from "axios";
import ProductType from "../form/productcomponent/ProductType.vue";
import { formStateStore } from "@/store/storebackoffice";
import Storedata from "@/store/Storedata.js";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Baseinput from "../form/Baseinput.vue";
import DatePickerDropdown from "@/components/Input/label/DatePickerDropdown.vue";
import InputNationalId from "@/components/Input/label/InputNationalId.vue";
import InputText from "@/components/Input/label/InputText.vue";

import { FormHelper } from "@/helper/FormHelper.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import { Modal } from "ant-design-vue";
import _ from "lodash";

import {
    ProductModel,
    GetProductBirthday
} from "../../../../../core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
import SelectOptionUserProduct from "@/module/CustomerOrder/SellCreate/component/form/SelectOptionUserProduct.vue";
import Address from "../Address.vue";
import moment from "moment";
import Locale from "@/helper/locale/Locale.js";
import AddressFrom from "./AddressFrom.vue";
import { formStateStoreCreate } from "@/store/storebackoffice";

import Career from "@/components/Input/label/Career.vue";
import { useRoute } from "vue-router";

export default defineComponent({
    provide: {
        Storedata: Storedata
    },
    mixins: [LocaleMixin],
    components: {
        Career,
        AddressFrom,
        InputText,
        InputNationalId,
        DatePickerDropdown,
        UpdateStatusPopup,
        MinusCircleOutlined,
        PlusOutlined,
        Uploadfile,
        PaidDelivery,
        PaidRemind,
        ProductType,
        SelectOptionUserProduct,
        Address,
        InputContactNumber,
        UpdateStatusCancelTrack

        // Baseinput,
    },
    props: {
        formStates: null,
        productjson: null,
        is_edit: null,
        type: null,
        isscan: false,
        saveall: null,
        sub_session_show: null
    },
    setup(props, { emit }) {
        debugger
        console.log("prop.sub_session_show",props.sub_session_show)

        const updateresetdate = ref(false);
        const iscreate = ref(false);
        const route = useRoute();
        if (route.query.p == "create") {
            iscreate.value = true;
        }
        const systemtabchecking = ref("");
        const CheckMessage = ref([]);
        const checking_1 = ref("");
        const checking_2 = ref("");
        const descmessage = ref("");
        const visiblereject = ref(false);
        const remind_notpay_delivery = ref(
            props.formStates.userproduct.remindnotpaydelivery || "notpay"
        );
        const customer_not_confirm_address_already = ref(
            props.formStates.userproduct.customernotconfirmaddressalready ||
                "donotconfirm"
        );

        const customer_acept = ref([]);
        const career = ref([]);
        const checkingpayment = ref(null);
        const isshowcustomerdescproduct = ref(true);
        if (
            props.formStates.userproduct.customername ||
            props.formStates.userproduct.customerlastname
        )
            isshowcustomerdescproduct.value = true;

        const tab_yan_insert_system = ref(1);
        const ischeckcountyan = ref(true);
        const istype = ref(props.type);
        const fact_is_edit = ref(props.is_edit);
        const isshowpopcancel = ref(false);
        const isshowpopcanceltrack = ref(false);
        const paiddeliveryobj = ref({});
        const remindpaiddeliveryobj = ref({});
        const gender = ref("1");
        const queyan = ref("1");
        const typebirthday = ref("1");

        const is_paid_delivery = ref("1");

        const agegender = ref("18");
        // const formState = reactive(props.formStates);

        console.log("props.formStates ", props.formStates);

        const formState = reactive(props.formStates);

        formState.updatecomponent++;

        console.log("formStates1", props.formStates);
        console.log("formStateStore2", formStateStore);

        const findAndAddValue = (arr, valueToAdd) => {
            // Check if the value is already in the array
            if (arr.indexOf(valueToAdd) === -1) {
                // If not found, add it to the array
                arr.push(valueToAdd);
            }
            return arr;
        };
        if (fact_is_edit.value.type == "increasestock") {
            if (!formState.userproduct.quantitystock) {
                formState.userproduct.quantitystock = 1;
            }

            // BzbsUser.apigetproducstocktbyid(props.formStates.user.sellordertransaction)
            //     .then(res => {

            //         res.data.forEach(items => {
            //             var result = _.transform(items, function (
            //                 result,
            //                 val,
            //                 key
            //             ) {
            //                 result[key.toLowerCase()] = val;
            //             });

            //             formState.userproduct = result

            //         });
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        }

        if (formState.userproduct.senttype) {
            formState.userproduct.senttype = formState.userproduct.senttype;
        } else if (formState.userproduct.professortype) {
            // if(fact_is_edit.value.type == 'increasesize'){
            //     console.log(formState.userproduct.professortype)
            //     formState.userproduct.professortype = findAndAddValue(formState.userproduct.professortype,"12")
            // }

            let forDeletion = ["3", "4"];
            var tempsent = [];
            if (
                typeof formState.userproduct.professortype !== "object" &&
                formState.userproduct.professortype !== null
            ) {
                JSON.parse(formState.userproduct.professortype).forEach(
                    element => {
                        if (forDeletion.includes(element)) {
                            tempsent.push(element);
                        }
                    }
                );

                formState.userproduct.senttype = tempsent?.toString();
            } else {
                if (
                    typeof formState.userproduct.professortype === "object" &&
                    formState.userproduct.professortype !== null
                ) {
                    formState.userproduct.professortype.forEach(element => {
                        if (forDeletion.includes(element)) {
                            tempsent.push(element);
                        }
                    });

                    formState.userproduct.senttype = tempsent?.toString();
                }
            }
        }
        // if (customer_not_confirm_address_already.value == "donotconfirm") {

        //     if (formState.userproduct.comingyanimage && formState.userproduct.yancomingdate) {
        //         formState.inside.forEach(element => {
        //             if (element.price) {
        //                 customer_not_confirm_address_already.value = "confirm"
        //                 formState.userproduct.customernotconfirmaddressalready = "confirm"
        //             }
        //         });
        //         formState.outside.forEach(element => {
        //             if (element.price) {
        //                 customer_not_confirm_address_already.value = "confirm"
        //                 formState.userproduct.customernotconfirmaddressalready = "confirm"
        //             }
        //         });
        //     }
        // }
        // const temp_formStateStore = toRefs(formStateStore);
        // const temp_formStateStore_2 = ref(null);
        // temp_formStateStore_2.value = temp_formStateStore.userproduct
        // console.log("temp_formStateStore_2", temp_formStateStore_2)

        const fileList = ref([]);

        if (formState.userproduct.productyanimage) {
            var url = formState.userproduct.productyanimage;
            fileList.value.push({
                uid: "-1",
                name: "image.png",
                status: "success",
                url: url
            });
        }

        if (formState.userproduct.productlogoimage) {
            var url = formState.userproduct.productlogoimage;
            fileList.value.push({
                uid: "-1",
                name: "image.png",
                status: "success",
                url: url
            });
        }

        // console.log(props.productjson[0])
        // console.log(formState.userproduct)
        // formState = props.formState
        const productData = [
            "ยันต์เขียนสด",
            "ยันต์ปลด/คิวด่วน",
            "วัตถุมงคล",
            "เคส/ภาพพื้นหลัง"
        ];
        // const sights = reactive();
        const sights = {
            ยันต์เขียนสด: [],
            หัวใจยัน: []
        };
        const sizeproduct = ref(null);
        const heartyanname = ref(null);
        const yanname = [
            {
                label: "หัวใจยันต์1",
                value: "หัวใจยันต์1"
            },
            {
                label: "หัวใจยันต์2",
                value: "หัวใจยันต์2"
            },
            {
                label: "หัวใจยันต์3",
                value: "หัวใจยันต์3"
            },
            {
                label: "หัวใจยันต์4",
                value: "หัวใจยันต์4"
            }
        ];
        const areas = [
            {
                label: "ยันต์เขียนสด",
                value: "yan"
            },
            {
                label: "สินค้า",
                value: "product"
            }
        ];

        onMounted(() => {
            apigetcommissionlog();
            initusercommission();
            queryyan();
            getcareer();

            if (istype.value != "add" && !props.isscan) initproductbirthday();
        });
        const queryyan = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/getproductjson/yan").then(res => {
                res?.data?.yan?.forEach(element => {
                    sights["ยันต์เขียนสด"].push(element["ขนาด"]);
                    var objheartyan = {};
                    objheartyan.yan_size = element["ขนาด"];
                    objheartyan.heartyan = element["หัวใจยันต์"];
                    sights["หัวใจยัน"].push(objheartyan);
                });
                let yan = sights["ยันต์เขียนสด"].filter((c, index) => {
                    return sights["ยันต์เขียนสด"].indexOf(c) === index;
                });
                sizeproduct.value = yan;
            });
        };

        const formRef = ref();

        const dynamicValidateForm = reactive({
            sights: [],
            area: undefined
        });
        if (props.type == "add") {
            dynamicValidateForm.sights = [];
        }
        if (props.type == "stock") {
            dynamicValidateForm.sights = [];
        }

        watch(
            () => customer_not_confirm_address_already.value,
            () => {
                formState.userproduct.customernotconfirmaddressalready =
                    customer_not_confirm_address_already.value;
                console.log(
                    "customer_not_confirm_address_already.value :",
                    customer_not_confirm_address_already.value
                );
            },
            () => daybirthrecommend,
            () => {
                console.log(
                    "dynamicValidateForm.sights :",
                    dynamicValidateForm.sights
                );
            }
        );
        watch(
            () => dynamicValidateForm,
            () => {
                console.log(
                    "dynamicValidateForm.sights :",
                    dynamicValidateForm.sights
                );
            }
        );
        watch(
            () => formState.userproduct.systemtrackyan,
            () => {
                formState.userproduct.trackyan = formState.userproduct.systemtrackyan?.replace(
                    /[^0-9]/g,
                    ""
                );
                formState.userproduct.productcode =
                    formState.userproduct.productcode.substring(0, 11) +
                    formState.userproduct.systemtrackyan?.replace(
                        /[^0-9]/g,
                        ""
                    );
            }
        );

        watch(
            () => formState.userproduct.trackyan,
            () => {
                formState.userproduct.systemtrackyan =
                    formState.userproduct.trackyan;
                formState.userproduct.productcode =
                    formState.userproduct.productcode.substring(0, 11) +
                    formState.userproduct.systemtrackyan;
            }
        );

        watch(
            () => remind_notpay_delivery.value,
            () => {
                set_remind_notpay_delivery();
            }
        );
        const setheartyan = () => {
            formState.userproduct.heartyanname = "";
            let listheartyanname = [];
            sights["หัวใจยัน"].filter(c => {
                if (c.yan_size == formState.userproduct.customerproductssize) {
                    listheartyanname.push(c.heartyan);
                }
            });
            heartyanname.value = listheartyanname;
            // console.log(heartyanname.value);
        };
        const removeSight = item => {
            let index = dynamicValidateForm.sights.indexOf(item);
            var param_del = {
                CustomerOrder: dynamicValidateForm.sights[index].customerorder,
                Id: dynamicValidateForm.sights[index].id
            };
            console.log("param_del : ", param_del);

            var name = "";
            if (dynamicValidateForm.sights[index]?.name != undefined) {
                name = dynamicValidateForm.sights[index]?.name;
            }
            var lastname = "";
            if (dynamicValidateForm.sights[index]?.lastname != undefined) {
                lastname = dynamicValidateForm.sights[index]?.lastname;
            }

            var fullname = name + " " + lastname;
            Modal.confirm({
                title: "คุณต้องการลบ?",
                content: fullname || "",
                okText: "ตกลง",
                okType: "danger",
                cancelText: "ยกเลิก",
                onOk() {
                    BzbsUser.apideleteproductbirthday(param_del)
                        .then(res => {
                            console.log(res);
                            if (index !== -1) {
                                dynamicValidateForm.sights.splice(index, 1);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                },
                onCancel() {
                    console.log("Cancel");
                }
            });
        };

        const addSight = () => {
            if (dynamicValidateForm.sights.length == 10) return;
            console.log(dynamicValidateForm.sights.length);

            dynamicValidateForm.sights.push({
                productkey: props.formStates.userproduct.productkey,
                value: undefined,
                price: undefined,
                career: undefined,
                typebirthday: "1",
                id: dynamicValidateForm.sights.length
            });
        };

        setTimeout(() => {
            if (dynamicValidateForm?.sights?.length == 0) {
                addSight();
            }
        }, 1000);
        console.log("maxBy", _.maxBy(formState.listproduct, "ProductKey"));
        // let outside = [];
        const onFinish = values => {
            if (values.userproduct.productcode == "") {
                openNotificationwarning("กรุณาเลือกสินค้าให้ครบทุกช่อง");
                return;
            }
            if (
                !formState.userproduct.increasesizeorder &&
                fact_is_edit.value.type == "increasesize"
            ) {
                openNotificationwarning("กรุณากรอกลำดับงาน(เพิ่มขนาด)");
                return;
            }
            if (istype.value != "add" && fact_is_edit.value.rowkey != null) {
                formStateStoreCreate.listbirstname[
                    fact_is_edit.value.rowkey
                ] = {
                    productkey: fact_is_edit.value.rowkey,
                    sights: formState.listbirstname.sights
                };
                console.log(
                    "formStateStoreCreate.listbirstname",
                    formStateStoreCreate.listbirstname
                );
            } else if (
                istype.value == "add" &&
                formState.listbirstname != undefined
            ) {
                formStateStoreCreate.listbirstname.push({
                    productkey: props.formStates.userproduct.productkey,
                    sights: formState.listbirstname.sights
                });
                console.log(
                    "formStateStoreCreate.listbirstname",
                    formStateStoreCreate.listbirstname
                );
            }

            Storedata.methods.callsave();

            // console.log("formState.userproduct :", formState.userproduct)
            // formState.listproduct = []

            //  var inside = Object.assign({}, formState.inside);

            if (isshowProductYan()) {
                if (formState.userproduct.trackyan) {
                    formState.userproduct.productcode =
                        formState.userproduct.productcode.substring(0, 11) +
                        formState.userproduct.trackyan;
                }
            }

            var inside = paiddeliveryobj.value.inside;
            var insidecod = paiddeliveryobj.value.insidecod;
            var outside = paiddeliveryobj.value.outside;

            var remindinside = remindpaiddeliveryobj.value.inside;
            var remindoutside = remindpaiddeliveryobj.value.outside;

            // outside.push(Object.assign({}, JSON.stringify(formState.outside)))
            // console.log("outside : ",outside)
            if (inside != undefined) formState.userproduct["inside"] = inside;
            if (insidecod != undefined)
                formState.userproduct["insidecod"] = insidecod;
            if (outside != undefined)
                formState.userproduct["outside"] = outside;

            if (remindinside != undefined)
                formState.userproduct["remindinside"] = remindinside;
            if (remindoutside != undefined)
                formState.userproduct["remindoutside"] = remindoutside;

            console.log("formState", formState);
            console.log("formState.inside ", formState.inside);
            console.log("formState.outside ", formState.outside);
            // formState.userproduct  = values.userproduct;
            // Object.assign(formState, formState.userproduct["outside"].push(formState.outside));

            if (formState.userproduct.productdetail.originalprice) {
                formState.userproduct.originalprice =
                    formState.userproduct.productdetail.originalprice;
            }

            if (fact_is_edit.value.type == "increasestock") {
            } else if (fact_is_edit.value.fact) {
                formState.listproduct.find(element => {
                    console.log(
                        "olde data :",
                        formState.listproduct[fact_is_edit.value.key]
                    );
                    var element_lower = _.transform(element, function(
                        result,
                        val,
                        key
                    ) {
                        result[key.toLowerCase()] = val;
                    });
                    var set_product = null;
                    var set_productstock = null;
                    if (element_lower.productkey == fact_is_edit.value.key) {
                        console.log(
                            "case 1 olde data :",
                            formState.listproduct[fact_is_edit.value.key]
                        );
                        //log
                        if (fact_is_edit.value.type == "increasesize") {
                            set_product = _.transform(
                                formState.listproduct[fact_is_edit.value.key],
                                function(result, val, key) {
                                    result[key.toLowerCase()] = val;
                                }
                            );

                            set_product.increasesizedesc =
                                formState.userproduct.increasesizedesc;
                            set_product.increasesizeorder =
                                formState.userproduct.increasesizeorder;
                            formState.listproductlogchange.push({
                                islog: true,
                                key: fact_is_edit.value.key,
                                userproduct: set_product
                            });

                            insertlogproductchange();
                        }

                        //เพิ่ม stock
                        if (fact_is_edit.value.type == "increasestock") {
                            set_productstock = _.transform(
                                formState.listproduct[fact_is_edit.value.key],
                                function(result, val, key) {
                                    result[key.toLowerCase()] = val;
                                }
                            );

                            // set_productstock.increasesizedesc = formState.userproduct.increasesizedesc
                            // set_productstock.increasesizeorder = formState.userproduct.increasesizeorder
                            formState.listproductstock.push({
                                islog: true,
                                key: fact_is_edit.value.key,
                                userproduct: set_productstock
                            });
                            // insertlogproductchange()
                        }

                        formState.listproduct[fact_is_edit.value.key] =
                            formState.userproduct;
                        console.log(
                            "case 1 new data :",
                            formState.listproduct[fact_is_edit.value.key]
                        );
                    }
                });
                // formState.listproduct[key] = formState.userproduct
                fact_is_edit.value.fact = false;
            } else {
                if (formState.listproduct.length) {
                    try {
                        var obj_max = _.maxBy(formState.listproduct, obj =>
                            parseInt(obj.ProductKey)
                        );
                        var pk = parseInt(obj_max?.ProductKey);
                        pk++;
                        formState.userproduct.productkey = pk?.toString();
                    } catch (error) {}
                }

                formState.listproduct.push(
                    Object.assign({}, formState.userproduct)
                );
                formStateStoreCreate.listproduct.push(
                    Object.assign({}, formState.userproduct)
                );
            }

            console.log("formState :", Object.assign({}, formState));
            emit("update:value", Object.assign({}, formState));
            emit("closemodal", false);
        };

        const insertlogproductchange = async () => {
            var index = 0;
            for await (let listproduct_items of formState.listproductlogchange) {
                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }

                listproduct_items.userproduct.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;
                listproduct_items.productkey = index;
                console.log("listproduct_items :", listproduct_items);

                listproduct_items.userproduct.ordertransaction =
                    formState.userproduct.ordertransaction;
                let params = new ProductModel(listproduct_items?.userproduct);

                console.log("apiupdateproduct", params);

                await BzbsUser.apiupdateproductlogchenge(params)
                    .then(res => {
                        index++;
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const insertlogproductstock = async () => {
            var index = 0;
            for await (let listproduct_items of formState.listproductlogchange) {
                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }

                listproduct_items.userproduct.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;
                listproduct_items.productkey = index;
                console.log("listproduct_items :", listproduct_items);

                listproduct_items.userproduct.ordertransaction =
                    formState.userproduct.ordertransaction;
                let params = new ProductModel(listproduct_items?.userproduct);

                console.log("apiupdateproduct", params);

                await BzbsUser.apiupdateproductlogchenge(params)
                    .then(res => {
                        index++;
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };
        const isdisabled = sights => {
            if (sights.typebirthday == 1) {
                delete sights.birthday;
                delete sights.age;
                return true;
            } else {
                sights.age = calculateAgeDropdown(sights);
            }
        };
        const calculateAgeDropdown = (sights, index) => {
            updatelistbristname();

            daybirth(sights, index);

            var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
                return Math.abs(ageDate.getUTCFullYear() - 1970);
            } else {
                return 0;
            }
        };

        const calculateAge = birthday => {
            console.log(birthday);
            if (birthday == null) return (formState.userproduct.customerage = 0);
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.userproduct.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
            if (isNaN(formState.userproduct.customerage))
                formState.userproduct.customerage = 0;
        };
        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            console.log("formState :", formState);
            emit("update:value", formState);
            formItemContext.onFieldChange();
        };
        const onchangeProductsTypeData = e => {
            console.log(
                "formState.increasesizedesc",
                formState.userproduct.increasesizedesc
            );
            console.log(
                "formState.increasesizeorder",
                formState.userproduct.increasesizeorder
            );
            formState.userproduct = e;
            checkbirstdatecount();
        };
        // watch(
        //     () => formState.userproduct.productdetail,
        //     () => {
        //         formState.userproduct.productprice =
        //             formState.userproduct?.productdetail?.price;
        //         formState.userproduct.productcode =
        //             formState.userproduct?.productdetail?.productcode;
        //         formState.userproduct.originalprice = formState.userproduct?.productdetail?.originalprice;
        //     }
        // );
        // watch(
        //     () => formState.userproduct.customerproductstype,
        //     () => {
        //         // formState.userproduct = {};
        //         formState.userproduct.productprice = "";
        //         formState.userproduct.productcode = "";
        //     }
        // );

        const checkgrantcancel = role => {
            return role == "admin" || role == "god";
        };
        const setshowpopup = e => {
            isshowpopcancel.value = e;
            console.log(" isshowpopcancel.value ", isshowpopcancel.value);
        };
        const setshowpopupcanceltrack = e => {
            isshowpopcanceltrack.value = e;
            console.log(
                "setshowpopupcanceltrack.value ",
                isshowpopcanceltrack.value
            );
        };

        const updatelistbristname = () => {
            console.log(formState);
            console.log(formStateStore);
            formStateStore.listbirstname = dynamicValidateForm;
            formStateStore.listbirstname.sights.productsellorder =
                formStateStore.user.sellorderTransaction;
            formStateStore.listbirstname.sights.customerorder =
                formStateStore.user.customercode;
            formState.userproduct.listbirstname = dynamicValidateForm;
        };
        if (istype.value == "add") {
            formState.userproduct = formStateStore.userproduct;
            // formState.userproduct = {
            //     "heartyanname": "",
            //     "queyan": "1",
            //     "professortype": [],
            //     "inside": [],
            //     "outside": [],
            //     "introductionyan": ""
            // }
        }
        const openNotification = massage => {
            notification["success"]({
                message: massage
            });
        };
        const openNotificationwarning = massage => {
            notification["warning"]({
                message: massage
            });
        };

        const isshowProductYan = () => {
            var array_name = ["YAN", "VIP"];

            if (
                (formState.userproduct.customerproductstypeobj &&
                    array_name.includes(
                        formState.userproduct.customerproductstypeobj.Type
                    )) ||
                formState.userproduct.customerproductstype.includes("ยันต์")
            ) {
                return true;
            } else {
                return false;
            }
        };
        const isNAWhasBith = () => {
            var array_name = [
                "เคส",
                "ภาพพื้นหลัง",
                "พิธีฝากดวงบรรจุแผ่นทองที่ฐานพระประธานโบสถ์",
                "พิธีฝากดวงบรรจุแผ่นทองที่ฐานพระประธานโบสถ์(2)",
                "พิธีฝากดวงบรรจุแผ่นทอง(เฉพาะแผ่นทอง)"
            ];
            if (
                array_name.includes(
                    formState.userproduct.category_1?.toString()?.trim()
                )
            ) {
                return true;
            }
            if (
                array_name.includes(
                    formState.userproduct.category_2?.toString()?.trim()
                ) ||
                formState.userproduct.category_2
                    ?.toString()
                    ?.trim()
                    .includes("พิธีฝากดวงบรรจุแผ่นทอง")
            ) {
                return true;
            }
        };

        const initproductbirthday = () => {
            console.log("formStateStoreCreate", formStateStoreCreate);
            if (iscreate.value) {
                formStateStoreCreate?.listbirstname?.forEach(
                    (element, index) => {
                        if (index == fact_is_edit.value.rowkey) {
                            dynamicValidateForm.sights = element.sights;
                        }
                    }
                );

                console.log(
                    "formStateStoreCreate.listbirstname ",
                    formStateStoreCreate.listbirstname
                );
                console.log(
                    "formStateStore.listbirstname ",
                    formStateStore.listbirstname
                );
                return;
            }

            var productkey = props.is_edit.key?.toString() || "";

            var productsellorder = formState.user.sellorderTransaction;
            BzbsUser.apigetproductbirthday(
                formState.user.customercode,
                productkey,
                productsellorder
            )
                .then(res => {
                    const temp_obj = [];
                    res.data.forEach(element => {
                        var obj = new GetProductBirthday(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    formStateStore.listbirstname = temp_obj;
                    // formState.userproduct.listbirstname = temp_obj
                    dynamicValidateForm.sights =
                        formStateStore?.listbirstname || [];

                    formState.userproduct.listbirstname = dynamicValidateForm;
                    console.log("initproductbirthday :", temp_obj);

                    // formState.getcontactlist = temp_obj;
                    // formState.user.contactList = temp_obj;
                    // console.log(
                    //     "formState.user.contactlist :",
                    //     formState.getcontactlist
                    // );
                })
                .catch(error => {
                    console.log(error);
                });
        };

        setTimeout(() => {
            checkbirstdatecount();
        }, 500);
        const checkbirstdatecount = () => {
            var array_name = [
                "10x10 ยันต์เปิดดวง",
                "20 ยันต์หนุนดวง",
                "20 องค์เทพ",
                "30 มหายันต์รวย",
                "30 วาระพิเศษ",
                "40 องค์เทพ",
                "50 มหายันต์รวย",
                "50 องเทพ",
                "10x10 ยันต์เปิดดวง",
                "20x20 ยันต์หนุนดวง (สิบทิศ)",
                "20x20 ยันต์หนุนดวง (พญานาค)",
                "20x20 ยันต์หนุนดวง (พญาครุฑ)",
                "20x20 ยันต์หนุนดวง (สิวลี)",
                "20x20 ยันต์หนุนดวง (พิฆเนศปางเสวยสุข)",
                "20x20 ยันต์หนุนดวง (พิฆเนศปางประทานพร)",
                "20x20 ยันต์หนุนดวง (นกถึดทือ)",
                "30x30 มหายันต์รวย (สิบทิศ)",
                "30x30 วาระพิเศษ (พระพุทธ)",
                "30x30 วาระพิเศษ (อาจารย์ธรรมโชติ)",
                "30x30 วาระพิเศษ (นาคราชเรียกทรัพย์)",
                "40x40 มหายันต์รวย (พญานาคเดี่ยว)",
                "40x40 มหายันต์รวย (พิฆเนศประทานพรแบบนั่ง)",
                "40x40 มหายันต์รวย (สิวลี)",
                "40x40 มหายันต์รวย (พระพุทธเจ้า)",
                "40x40 มหายันต์รวย (พญาครุฑ)",
                "40x40 มหายันต์รวย (นกถึดทือหุบปีก)",
                "40x40 มหายันต์รวย (นกถึดทือกางปีก)",
                "40x40 มหายันต์รวย (เสือเหลียวหลัง)",
                "40x40 มหายันต์รวย (ราหู)",
                "40x40 มหายันต์รวย (พระอาทิตย์)",
                "40x40 มหายันต์รวย ",
                "50x50 มหายันต์รวย (สิบทิศ)",
                "50x50 มหายันต์รวย (นาคเกี้ยวรุ้ง)",
                "50x50 มหายันต์รวย (นาคเกี้ยวเขียว)",
                "50x50 มหายันต์รวย (นาค 9 เศียร)",
                "50x50 มหายันต์รวย (นาคทอง)",
                "50x50 มหายันต์รวย (นาคเขียว)",
                "50x50 มหายันต์รวย (พิฆเนศนาคปรก)",
                "50x50 มหายันต์รวย (พิฆเนศเสวยสุข)",
                "50x50 มหายันต์รวย (พิฆเนศประทานพร)",
                "50x50 มหายันต์รวย (ตัวโอม)",
                "50x50 มหายันต์รวย (ครุฑแดง)",
                "50x50 มหายันต์รวย (ครุฑน้ำเงิน)",
                "50x50 มหายันต์รวย (ท้าวเวสสุวรรณ)",
                "50x50 มหายันต์รวย (สิวลี)",
                "50x50 มหายันต์รวย (พระนาคปรก)",
                "50x50 มหายันต์รวย (พระพุทธเจ้า)",
                "50x50 มหายันต์รวย (ราหู)",
                "50x50 มหายันต์รวย (กวนอิมดอกบัว)",
                "50x50 มหายันต์รวย (นกถึดทือหุบปีก)",
                "50x50 มหายันต์รวย (นกถึดทือกางปีก)",
                "50x50 มหายันต์รวย ",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีเขียว)",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีรุ้ง)",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีทอง)",
                "16.8x16.8 นาคาบารมีเกี้ยวคู่ (สีเขียว)",
                "16.8x16.8 นาคาบารมีเกี้ยวคู่ (สีรุ้ง)",
                "16.8x16.8 นาคาบารมี "
            ];

            if (
                array_name.includes(formState.userproduct.customerproductssize)
            ) {
                return (ischeckcountyan.value = false);
            } else {
                return (ischeckcountyan.value = true);
            }
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.userproduct.ProvinceName = values.provinceName;
            formState.userproduct.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.userproduct.BorderName = values.districtsName;
            formState.userproduct.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.userproduct.DistrictID = values.subdistrictsID;
            formState.userproduct.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.userproduct.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const settypearray = () => {
            let forDeletion = ["1", "2", "5", "10", "11", "12", "13", "14"];

            console.log(
                "formState.userproduct.professortype ",
                formState.userproduct.professortype
            );
            var tempprofessortype = [];
            if (
                typeof formState.userproduct.professortype === "object" &&
                formState.userproduct.professortype !== null
            ) {
                formState.userproduct.professortype.forEach(element => {
                    if (forDeletion.includes(element)) {
                        tempprofessortype.push(element);
                    }
                });

                formState.userproduct.professortype = tempprofessortype;
            } else {
                formState.userproduct.professortype = forDeletion
                    .filter(
                        item =>
                            item ==
                            JSON.parse(formState.userproduct.professortype)
                    )
                    ?.toString();
            }

            var array_list = [
                ...formState.userproduct.professortype,
                ...formState.userproduct.senttype
            ];

            formState.userproduct.professortype = [...new Set(array_list)];
        };

        const isEmptyStr = str => {
            return str === null || str === undefined || str === "";
        };
        const jsonParse = jsonStr => {
            if (!isEmptyStr(jsonStr)) {
                try {
                    var data = JSON.parse(jsonStr);
                    return [data];
                } catch (e) {
                    return [jsonStr];
                }
            }
            return null;
        };

        const daybirth = (birthday, index) => {
            let lbl = Locale.getLbl();
            let day = lbl.date.date_full;
            var daycal = day[moment(birthday).format("d") - 1];

            // if (!birthday.productkey && dynamicValidateForm.sights[index]) {
            //     dynamicValidateForm.sights[index].birthdayname = daycal
            // }

            // if (birthday.birthdayname) {
            //     dynamicValidateForm.sights[birthday.productkey].birthdayname = birthday.birthdayname
            // }

            // else {
            //     dynamicValidateForm.sights[index].birthdayname = daycal
            // }
            daybirth_recommend(birthday, index);
        };
        const daybirthrecommend = ref([]);
        const daybirth_recommend = (birthday, index) => {
            let lbl = Locale.getLbl();
            let day = lbl.date.date_full;

            var daycal = day[moment(birthday).format("d") - 1];

            if (moment(birthday).format("d") == 0) {
                daycal = day[6];
            }

            if (dynamicValidateForm.sights[index]) {
                daybirthrecommend.value[index] = daycal;
            }
        };

        const checkpayment = () => {
            var param = {
                ProductSellOrder: formState.user.sellorderTransaction,
                CustomerOrder: formState.user.customercode
            };

            BzbsUser.getchecking(param)
                .then(res => {
                    checkingpayment.value = res.data[0]?.Checking;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        checkpayment();

        const getcareer = () => {
            BzbsUser.getcareer()
                .then(res => {
                    career.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const OnChangeEmitCareer = () => {};
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        var mediaRecorder;
        var recordedChunks = [];

        const recordvideo = () => {
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(function(stream) {
                    var videoElement = document.getElementById("video");
                    videoElement.srcObject = stream;
                })
                .catch(function(error) {
                    console.error("Error accessing camera: ", error);
                });
        };

        const startRecording = () => {
            var videoElement = document.getElementById("video");
            mediaRecorder = new MediaRecorder(videoElement.srcObject);

            mediaRecorder.ondataavailable = function(event) {
                recordedChunks.push(event.data);
                console.log("recordedChunks : ", recordedChunks);
            };

            mediaRecorder.start();
        };

        const stopRecording = () => {
            mediaRecorder.stop();
        };
        const set_remind_notpay_delivery = () => {
            formState.userproduct.remindnotpaydelivery =
                remind_notpay_delivery.value;
        };

        const handleOkreject = e => {
            console.log(e);
            visiblereject.value = false;
            updatepaymentchecking();
        };

        const confirm = e => {
            systemtabchecking.value = e;
            console.log(e);
            return new Promise(resolve => {
                visiblereject.value = true;
            });
        };
        const updatepaymentchecking = () => {
            var CheckMessageParam = [];
            if (CheckMessage.value) {
                var checking =
                    systemtabchecking.value == "1"
                        ? checking_1.value
                        : checking_2.value;
                CheckMessageParam = [
                    ...CheckMessage.value,
                    {
                        tab: systemtabchecking.value,
                        checking: checking,
                        message: descmessage.value,
                        updatedate: Date.now().toString()
                    }
                ];
            } else {
                var checking =
                    systemtabchecking.value == "1"
                        ? checking_1.value
                        : checking_2.value;
                CheckMessageParam = [
                    {
                        tab: systemtabchecking.value,
                        checking: checking,
                        message: descmessage.value,
                        updatedate: Date.now().toString()
                    }
                ];
            }

            var param = {
                Tab: systemtabchecking.value,
                ProductSellOrder: formState.user.sellorderTransaction,
                CustomerOrder: formState.user.customercode,
                ProductKey: formState.userproduct.productkey,
                SystemCheckTabActive: JSON.stringify(CheckMessageParam)
            };
            console.log("param : ", param);
            BzbsUser.updatesystemchecktabactive(param)
                .then(res => {
                    setTimeout(() => {
                        initredio(param);
                    }, 500);
                    openNotification("อัพเดทสำเร็จ");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const initredio = param => {
            var sentparam = {
                ProductSellOrder: param.ProductSellOrder,
                CustomerOrder: param.CustomerOrder,
                ProductKey: param.ProductKey
            };

            BzbsUser.getsystemchecktabactive(sentparam)
                .then(res => {
                    setTimeout(() => {
                        formState.userproduct.systemchecktabactive_1 = JSON.parse(
                            res.data[0].SystemCheckTabActive_1 || "[]"
                        );
                        formState.userproduct.systemchecktabactive_2 = JSON.parse(
                            res.data[0].SystemCheckTabActive_2 || "[]"
                        );
                        formState.userproduct.systemchecktabactive_3 = JSON.parse(
                            res.data[0].SystemCheckTabActive_3 || "[]"
                        );
                    }, 500);
                    // checking.value = res.data[0].Checking
                    // CheckMessage.value = Util.jsonParse(res.data[0].CheckMessage)
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const formatdate = date => {
            if (date) {
                moment.locale("th");
                return moment(parseInt(date))
                    .add(543, "year")
                    .format("LLLL");
            } else {
                return "-";
            }
        };
        const formatdatehistory = date => {
            if (date) {
                moment.locale("th");
                return moment(date)
                    .utc()
                    .add(543, "year")
                    .format("LLLL");
            } else {
                return "-";
            }
        };

        const initcheck = () => {
            try {
                JSON.parse(
                    formState.userproduct.systemchecktabactive_1
                ).forEach(element => {
                    if (element.checking) {
                        checking_1.value = element.checking;
                    }
                });
                JSON.parse(
                    formState.userproduct.systemchecktabactive_2
                ).forEach(element => {
                    if (element.checking) {
                        checking_2.value = element.checking;
                    }
                });
            } catch (error) {}
        };
        initcheck();

        const checkshowother = text => {
            var result = [];
            var array_name = [
                "แผ่นทอง",
                "พิธีฝากดวงบรรจุแผ่นทองที่ฐานพระประธานโบสถ์",
                "พิธีฝากดวงบรรจุแผ่นทองที่ฐานพระประธานโบสถ์(2)",
                "พิธีฝากดวงบรรจุแผ่นทอง(เฉพาะแผ่นทอง)"
            ];
            result = array_name.filter(item => item == text);
            return result?.length > 0;
        };

        const canceltrack = () => {
            isshowpopcanceltrack.value = !isshowpopcanceltrack.value;
        };

        const callSomeAction = async () => {
            try {
                // Call the 'someAction' from the Vuex store
                await someAction();
                console.log("someAction executed successfully!");
            } catch (error) {
                console.error("Error calling someAction:", error);
            }
        };

        const resetdate = async type => {
            try {
                if (type == "systemyandate") {
                    formState.userproduct.systemyandate = "";
                    updateresetdate.value = !updateresetdate.value;
                    setTimeout(() => {
                        updateresetdate.value = !updateresetdate.value;
                    }, 1000);
                } else if (type == "queyandate") {
                    formState.userproduct.queyandate = "";
                    updateresetdate.value = !updateresetdate.value;
                    setTimeout(() => {
                        updateresetdate.value = !updateresetdate.value;
                    }, 200);
                }
            } catch (error) {}
        };
        var commissionoptions = ref([]);
        const initusercommission = param => {
            BzbsUser.getusercommission()
                .then(res => {
                    if (res.data.length) {
                        commissionoptions.value = res.data;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const visiblehistory = ref(false);
        const showModalhistory = () => {
            visiblehistory.value = true;
        };
        const handleOkhistory = e => {
            console.log(e);
            visiblehistory.value = false;
        };

        const commissionlogdetail = ref([]);
        const apigetcommissionlog = () => {
            BzbsUser.apigetusercommissionlog(
                formState.userproduct.productsellorder,
                formState.userproduct.productkey
            )
                .then(res => {
                    commissionlogdetail.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            commissionlogdetail,
            showModalhistory,
            visiblehistory,
            handleOkhistory,
            commissionoptions,
            callSomeAction,
            canceltrack,
            checkshowother,
            formatdate,
            formatdatehistory,
            set_remind_notpay_delivery,
            startRecording,
            stopRecording,
            recordvideo,
            filterOption,
            career,
            OnChangeEmitCareer,
            checkingpayment,
            daybirthrecommend,
            daybirth,
            settypearray,
            listcodephone,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            ischeckcountyan,
            initproductbirthday,
            isshowProductYan,
            openNotification,
            updatelistbristname,
            setshowpopup,
            setshowpopupcanceltrack,
            isshowpopcancel,
            isshowpopcancel,
            checkgrantcancel,
            paiddeliveryobj,
            remindpaiddeliveryobj,
            onchangeProductsTypeData,
            locale,
            formRef,
            dynamicValidateForm,
            onFinish,
            removeSight,
            addSight,
            areas,
            sights,
            gender,
            agegender,
            queyan,
            is_paid_delivery,
            formState,
            fromchange,
            yanname,
            typebirthday,
            isdisabled,
            calculateAge,
            calculateAgeDropdown,
            onFinishFailed,
            productData,
            sizeproduct,
            heartyanname,
            setheartyan,
            formStateStore,
            fileList,
            Storedata,
            errorInfo: FormHelper.genErrorInfo(7),
            BzbsUser,
            istype,
            fact_is_edit,
            toRefs,
            isshowcustomerdescproduct,
            tab_yan_insert_system,
            customer_acept,
            remind_notpay_delivery,
            customer_not_confirm_address_already,
            visiblereject,
            updatepaymentchecking,
            handleOkreject,
            descmessage,
            checking_1,
            checking_2,
            confirm,
            iscreate,
            isshowpopcanceltrack,
            isNAWhasBith,
            resetdate,
            updateresetdate
        };
    }
});
</script>
<style lang="scss">
.text-cancel {
    color: red;
}

.disablecss {
    background: #dddddd4f;
    border-radius: 15px;
    padding: 10px;
    pointer-events: none;
    margin-bottom: 15px;
}

.disevent {
    pointer-events: none;
}
</style>
