<template>
    <a-form v-if="formState?.reminduser?.selectcountry?.selectcountry" :model="formState" name="basic" autocomplete="off"
        @finish="onFinish" @finishFailed="onFinishFailed" @change="fromchange">

        <div v-if="set_isshowinput == false">
            {{formState.reminduser.selectcountry.selectcountry}}
        </div>
        <div v-if="set_isshowinput == null">

            <a-form-item v-if="options.length > 0" :name="['selectcountry']" label="เลือกประเทศ*"
                :rules="[{ required: true, message: 'Missing area' }]">

                <div>
                    <a-select show-search v-model:value="formState.reminduser.selectcountry.selectcountry"
                        placeholder="เลือกประเทศ" style="width: 200px" :options="options" :filter-option="filterOption"
                        @focus="handleFocus" @blur="handleBlur" @change="handleChange"></a-select>
                </div>



            </a-form-item>
        </div>
    </a-form>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { Form } from "ant-design-vue";
export default defineComponent({
    props: {
        textbtn: null,
        formStates: null,
        isshowinput: null
    },
    setup(props, { emit }) {

        const set_isshowinput = ref(props.isshowinput);
        const options = ref([]);
        const formState = reactive(props.formStates);
        // formState.reminduseraddress.selectcountry = formState?.reminduser?.selectcountry?.selectcountry

        console.log("formState ", formState)
        BzbsUser.apicountries()
            .then(res => {

                Object.keys(res.data).map(function (element) {
                    options.value.push({
                        value: [res.data[element].CountryNameTh, res.data[element].CountryCode],
                        label: res.data[element].CountryNameTh
                    });
                });
                if (formState.reminduser.selectcountry.selectcountry) {
                    options.value.forEach(element => {
                        if (element.label == formState.reminduser.selectcountry.selectcountry)
                            formState.reminduser.selectcountry.selectcountrycode = element.value[1];

                    })
                }
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            emit("update:value", formState);
            formItemContext.onFieldChange();
        };
        const handleChange = value => {

            formState.reminduser.selectcountry.selectcountry = value[0];
            formState.reminduser.selectcountry.selectcountrycode = value[1];
            // formState.reminduseraddress.selectcountry = value[0]
            // formState.reminduseraddress.selectcountrycode = value[1]
            // console.log(`selected ${value}`);
            // fromchange();
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
             
            // if(option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            //  console.log("option ::",option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,"input : ",input.toLowerCase(),"::",option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        return {
            value: ref(undefined),
            filterOption,
            handleBlur,
            handleFocus,
            handleChange,
            options,
            fromchange,
            formState,
            set_isshowinput
        };
    }
});
</script>
