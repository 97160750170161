<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl p-5">
                <AddressList
                        ref="address-list"
                        @onEditAddress="clickEditAddress($event)"
                        @onDeleteAddress="clickDeleteAddress($event)"
                ></AddressList>
                <div class="text-right pt-5">
                    <button class="bg-gray-300 text-white rounded-md py-1 px-3 mr-2" @click="hide()">
                        Close
                    </button>
                    <button class="bg-green-500 text-white rounded-md py-1 px-3" @click="clickAddAddress()">
                        Add new address
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Util from "@/helper/Utility.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
//component
import AddressList from "@/module/Address/DeliveryAddress/component/AddressList.vue";
//service
import BzbsAddressList from "@/core/Address/service/BzbsAddressList.js";

export default {
    name: "ListAddressModal",
    mixins: [LocaleMixin],
    components: {
        AddressList,
    },
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        onModalHidden: function() {
            this.controller.sendCallbackHidden();
        },
        show: function() {
            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
            this.onModalHidden();
        },
        reloadAddressList: function() {
            this.$refs['address-list'].getAddressList();
        },

        //#region address event
        clickAddAddress: function() {
            this.hide();
            this.controller.addAddressModal.show();
            this.controller.addAddressModal.onClickCancel(() => {
                this.show();
            });
            this.controller.addAddressModal.onAddSuccess((address) => {
                if (!this.controller.notShowModalAfterUpdate) {
                    this.show();
                    this.$nextTick(() => {
                        this.reloadAddressList();
                    });
                }
                this.controller.sendCallbackAddSuccess(address);
                this.controller.sendCallbackUpdateSuccess(address);
            });
        },
        clickEditAddress: function(address) {
            this.hide();

            this.controller.editAddressModal.setAddress(address).show();
            this.controller.editAddressModal.onClickCancel(() => {
                this.show();
            });
            this.controller.editAddressModal.onEditSuccess(() => {
                if (!this.controller.notShowModalAfterUpdate) {
                    this.show();
                    this.$nextTick(() => {
                        this.reloadAddressList();
                    });
                }

                this.controller.sendCallbackEditSuccess(address);
                this.controller.sendCallbackUpdateSuccess(address);
            });
        },
        clickDeleteAddress: function(address) {
            this.hide();

            this.controller.confirmModal.clearModal()
                    .setMsg("ต้องการลบ Address " + address.addressName + " ?")
                    .show();

            this.controller.confirmModal.onClickOk(async () => {
                this.controller.loadingModal.show();
                await this.apiDeleteAddress(address);
                this.controller.loadingModal.hide();

                this.show();
                this.$nextTick(() => {
                    this.reloadAddressList();
                });
            });

            this.controller.confirmModal.onClickCancel(() => {
                this.show();
            });
        },
        //#endregion address event

        //#region api
        apiDeleteAddress: function(address) {
            return new Promise((resolve) => {
                BzbsAddressList.apiDeleteAddress(address.rowKey).subscribe(() => {
                    resolve();
                }, (err) => {
                    this.errorApi(err);
                    resolve();
                });
            });
        },
        //#endregion api
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/base.scss";
    @import "@/style/modal.scss";

    .modal {
        @extend %modal;

        .modal-content {
            width: 420px;

            @include mobile {
                width: 100%;
                padding: 16px;
            }
        }
    }
</style>
