import mitt from "mitt";
// import Util from "@/helper/Utility.js";
/**
 * setting
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onClickOk
 * event
 * @event sendCallbackOk
 */
export function LoadingModalController () {
    this.busModal = mitt();

    //#region setter

    //#endregion setter

    //#region method
    this.show = function() {
        var config = {
        };
        this.busModal.emit('show', config);
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event

    //#endregion event
}
