import { reactive } from "vue";
import { Observable } from "rxjs";

import Account from "@/helper/AccountHelper.js";
import BzbsProfile from "@/core/Account/service/BzbsProfile.js";

const obserInit = new Observable((subscriber) => {
    if (state.isInitial) {
        subscriber.next(state.isLogin);
    } else {
        subInitList.push(subscriber);
    }
});
const subInitList = [];

const state = reactive({
    isInitial: false,
    isLogin: false,
    profile: null,
    email: null,
});

const methods = {
    init: function () {
        if (Account.isLogin()) {
            BzbsProfile.getProfile().subscribe((data) => {
                // console.log('profile', data);
                state.isLogin = true;
                state.profile = data.profile;
                state.isInitial = true;

                sendEventInit();
            }, (err) => {
                state.isLogin = false;
                state.isInitial = true;
                console.log(err);

                if (err.id == 412) {
                    Account.removeCacheUser();
                    window.location.reload();
                }
            });
        } else {
            state.isLogin = false;
            state.isInitial = true;
        }
    },

    loginStatus: function () {
        return obserInit;
    },

    setProfile: function (profile) {
        state.profile = profile;
    },

};

function sendEventInit() {
    for (let i of subInitList) {
        i.next(state.isLogin);
    }
}

export default {
    state: state,
    methods: methods,
};
