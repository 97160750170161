<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl p-3">
                <div class="text-center text-xl pt-3" v-html="title"></div>
                <div class="text-center text-lg pb-5 pt-3" v-html="message"></div>
                <div class="w-1/2 m-auto">
                    <button class="w-full bg-blue-600 text-white rounded-md py-1" @click="clickOk()">
                        {{btnOk}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: "AlertModal",
    mixins: [LocaleMixin],
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,

            title: '',
            message: '',
            btnOk: '',
            busModal: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function(config) {
            this.title = config.title;
            this.message = config.msg;
            this.btnOk = config.btnOk;

            if (Util.isEmptyStr(this.btnOk)) {
                this.btnOk = this.lbl.default.ok;
            }

            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickOk: function () {
            this.hide();
            this.controller.sendCallbackOk();
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/base.scss";
    @import "@/style/modal.scss";
    .modal {
        @extend %modal;
    }
</style>
