export const campaignConfig = {
    campaignListConfig: 'campaign_devapp', // campaign_devapp
};

export const dashboardConfig = {
    mainDashboard: '',
};

export const categoryConfig = {
    menuCategory: 'menu_devapp',
};

export const historyConfig = {
    purchase: 'purchase',
};
