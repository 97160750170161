import mitt from "mitt";
import Util from "@/helper/Utility.js";
/**
 * setting
 * @method setSerial
 * @method clearModal
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onCloseModal
 * @method onSerialExpire
 * event
 * @event sendCloseModal
 * @event sendSerialExpire
 */
export function SerialModalController() {
    this.serialModel = null;
    this.busModal = mitt();

    this.callbackClose = null;
    this.callbackSerialExpire = null;

    //#region setter
    this.setSerial = function (serialModel) {
        this.serialModel = serialModel;
        return this;
    };
    this.clearModal = function () {
        this.serialModel = null;
        this.callbackClose = null;
        this.callbackSerialExpire = null;
        return this;
    };
    //#endregion setter

    //#region method
    this.show = function() {
        var config = {
            serialModel: this.serialModel,
        };
        this.busModal.emit('show', config);
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event
    this.onCloseModal = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackClose = callback;
        }
    };
    this.sendCloseModal = function(data) {
        if (Util.isFunction(this.callbackClose)) {
            this.callbackClose(data);
        }
    };

    this.onSerialExpire = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackSerialExpire = callback;
        }
    };
    this.sendSerialExpire = function(data) {
        if (Util.isFunction(this.callbackSerialExpire)) {
            this.callbackSerialExpire(data);
        }
    };
    //#endregion event
}
