
<template>
    <div class="">
        <!-- formState.masterproduct.status = {{formState.masterproduct}} -->
        <div class="p-4" v-show="formState.masterproduct.isshowsteps">
            <a-steps :current="formState.masterproduct.status">
                <a-step @click="gotostep(0)">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>เพิ่ม/แก้ไข</template>
                <!-- <template #description>
                        <span>สร้างรหัสลูกค้าใหม่</span>
                                                                    </template> -->
                </a-step>
                <a-step @click="gotostep(1)">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>เลือกประเภทสินค้า</template>
                </a-step>
                <a-step @click="gotostep(2)">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>กรอกข้อมูล</template>
                <!-- <template #description>
                        <span>สร้างรหัสลูกค้าใหม่</span>
                                                                    </template> -->
                </a-step>
            <!-- <a-step @click="formState.masterproduct.status = 4">
                   
                    <template #title>ที่อยู่</template> 
                                    </a-step> -->
            </a-steps>
        </div>

    </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import { useRouter } from 'vue-router'
import { formStateStore } from "@/store/storebackoffice";
export default defineComponent({

    components: {
        TopBar
    },
    setup() {
        const router = useRouter();

        const formState = reactive(formStateStore)

        // if (formState.masterproduct.status == 0) {
        //     setTimeout(() => { 
        //         router.push('/product');
        //     }, 1000);
        //     // window.location = '/product'
        // }

        // if (formState.masterproduct.status == 1) {
        //     setTimeout(() => { 
        //         router.push('/insertproduct');
        //     }, 1000);
        //     // window.location = '/insertproduct'
        // }



        formState.masterproduct.isshowsteps = true

        const productData = ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน", "วัตถุมงคล", "เคส/ภาพพื้นหลัง"];
        const options = ref([{
            value: 'ยันต์เขียนสด',
            label: 'ยันต์เขียนสด',
        }, {
            value: 'ยันต์ปลด/คิวด่วน',
            label: 'ยันต์ปลด/คิวด่วน',
        }, {
            value: 'วัตถุมงคล',
            label: 'วัตถุมงคล',
        }, {
            value: 'เคส/ภาพพื้นหลัง',
            label: 'เคส/ภาพพื้นหลัง',
        }]);
        const handleChange = value => {
            console.log(`selected ${value}`);
        };
        const handleBlur = () => {
            console.log('blur');
        };
        const handleFocus = () => {
            console.log('focus');
        };
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const gotostep = (step) => {
            formState.masterproduct.status = step
            if (formState.masterproduct.status == 0) {
                setTimeout(() => {
                    router.push('/product');
                }, 100);
                // window.location = '/product'
            }

            if (formState.masterproduct.status == 1) {
                setTimeout(() => {
                    router.push('/insertproduct');
                }, 100);
                // window.location = '/insertproduct'
            }


        }
        return {
            value: ref(undefined),
            filterOption,
            handleBlur,
            handleFocus,
            handleChange,
            options,
            formState,
            gotostep
        };
    },
});
</script>