<template>
    <div class="flex flex-wrap">
        <!-- <div class="flex-none px-4 py-4" :class="boxClass(null, idx)"></div> -->
        <template v-for="(item, idx) of items" :key="`tab-${idx}`">
            <div
                class="px-4 py-4 text-sm text-center font-semibold first:rounded-tl"
                :class="boxClass(item, idx)"
                :style="{ minWidth: minWidthComputed }"
                @click="selectTab(item[itemValue] != undefined ? item[itemValue] : idx)"
            >{{ getLabel(item) }}</div>
        </template>
        <div class="flex-1 px-4 py-4" :class="boxClass(null, idx)"></div>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: "VTabNav",
    props: {
        modelValue: {
            type: [Number, String],
            default: 0
        },
        items: {
            type: Array,
            default: () => []
        },
        itemText: {
            type: String,
            default: "text"
        },
        itemValue: {
            type: [Number, String],
            default: "value"
        },
        minWidth: {
            type: [String, Number],
            default: undefined
        },
        textColor: {
            type: String,
            default: "text-primary"
        },
        textColorNoneActive: {
            type: String,
            default: "text-gray-500"
        },
        border: {
            type: String,
            default: undefined
        },
        hover: {
            type: String,
            default: undefined
        },
        activeColor: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        minWidthComputed: function () {
            if (this.minWidth != undefined) {
                if (typeof this.minWidth == "number") {
                    return `${this.minWidth}px`
                } else {
                    if (this.minWidth.indexOf("px") != -1) {
                        return this.minWidth
                    } else {
                        return `${this.minWidth}px`
                    }
                }
            }
            return null
        },
        borderComputed: function () {
            return PropsHelper.getStringProp(this.border)
        },
        hoverComputed: function () {
            return PropsHelper.getStringProp(this.hover)
        },
        activeColorComputed: function () {
            return PropsHelper.getStringProp(this.activeColor)
        },
    },
    emits: ["update:modelValue"],
    methods: {
        boxClass: function (item, idx) {
            if (item != null) {
                if (this.disabled) {
                    return this.setClassDisabled()
                }

                if (item[this.itemValue] != undefined) {
                    if (item[this.itemValue] == this.modelValue) {
                        return this.setClassActive()
                    }
                } else {
                    if (idx == this.modelValue) {
                        return this.setClassActive()
                    }
                }
            }

            return this.setClassNoneActive(item == null)
        },
        setShareClass: function () {
            let cls = ["cursor-pointer"]

            if (this.hoverComputed != null) {
                cls.push(`hover:${this.hoverComputed} hover:${this.textColor}`)
            } else {
                cls.push('hover:bg-primary-lighter hover:text-primary')
            }

            return cls
        },
        setClassActive: function () {
            let cls = this.setShareClass()
            cls.push('border-b-2 border-primary')
            cls.push(this.textColor)

            if (this.activeColorComputed != null) {
                cls.push(this.activeColorComputed)
            }

            return cls
        },
        setClassNoneActive: function (isItemNull = false) {
            let cls = this.setShareClass()
            if (isItemNull) {
                cls = [] // clear share class if item is null
            }

            cls.push('border-b-2')
            if (this.borderComputed != null) {
                cls.push(this.borderComputed)
            } else {
                cls.push("border-transparent")
            }

            cls.push(this.textColorNoneActive)
            return cls
        },
        setClassDisabled: function () {
            let cls = ["text-gray-400", "cursor-default", "border-b-2"]
            if (this.borderComputed != null) {
                cls.push(this.borderComputed)
            } else {
                cls.push("border-transparent")
            }
            return cls
        },
        selectTab: function (val) {
            if (this.disabled) return
            this.$emit("update:modelValue", val)
        },
        getLabel: function (item) {
            // console.log(typeof item == "string")
            if (typeof item == "string") {
                return item
            } else if (typeof item == "object") {
                return item[this.itemText]
            } else {
                return "Can not render"
            }
        }
    }
}
</script>
