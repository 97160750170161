<template>
    <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 24 }" autocomplete="off"
        @finish="onFinish" @finishFailed="onFinishFailed">
        <div>
            <a-button type="link" @click="clickblack()">
                <left-outlined />ย้อนกลับ(1.3)
            </a-button>
        </div>
        <a-divider orientation="left">ข้อมูลลูกค้า</a-divider>
        <div class="p-5 mb-5 card">
            <a-row type="flex">
                <a-col :span="3" :order="1">
                    <font-awesome-icon icon="fa-solid fa-user" />
                    <span class="pl-2"> ข้อมูลลูกค้า</span>
                </a-col>
                <a-col :span="9" :order="2">
                    <a-form-item :name="['user', 'customercode']" label="รหัสลูกค้า"
                        :rules="[{ required: true, message: 'กรุณากรอก' }]">
                        <a-input :disabled="true" style="background: #80808038"
                            v-model:value="formState.user.customercode" />
                    </a-form-item>
                </a-col>
                <a-col :span="12" :order="3">
                    <a-form-item :name="['user', 'customercode']" label="หมายเลขการจัดส่ง"
                        :rules="[{ required: false, message: 'กรุณากรอก' }]">
                        <vue-barcode :value="formStateStore.rowselecteddetail" :options="{ displayValue: true }"
                            style="width:250px"></vue-barcode>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex">
                <a-col :span="3" :order="1">
                    ช่องทางการติดต่อ
                </a-col>
                <a-col :span="6" :order="2">
                    <a-input v-model:value="formState.user.customerlineid" placeholder="LINE ID" style="border: none;">
                        <template #prefix>
                            <img class="w-5"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                        </template>
                    </a-input>
                    <a-input v-model:value="formState.user.customerfacebook" placeholder="FACEBOOK" style="border: none;">
                        <template #prefix>
                            <img class="w-5"
                                src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg" />
                        </template>
                    </a-input>
                </a-col>
                <a-col :span="3" :order="3">ชื่อการติดต่อ</a-col>
                <a-col :span="6" :order="4">
                    {{ formState.user.customername }}
                    {{ formState.user.customerlastname }}
                    <span v-show="formState.user.customernickname">({{ formState.user.customernickname }}) </span>

                </a-col>
            </a-row>
        </div>
        <a-divider orientation="left">ที่อยู่-ลูกค้า(1.2)</a-divider>

        <div class="p-5 mb-5 card" style="pointer-events:none">
            <a-row type="flex">
                <a-col :span="3" :order="1">
                    <font-awesome-icon icon="fa-solid fa-address-book" />
                    <span class="pl-2"> ที่อยู่จัดส่ง(1.2)</span>
                </a-col>
                <a-col :span="6" :order="2">
                    <a-form-item :name="['user', 'customercountry']">
                        <div v-show="formState.user.addressdesc">
                            ที่อยู่ : {{ formState.user.addressdesc }}
                        </div>
                        <div v-show="formState.user.customeraddressType == '1'">ประเทศ ไทย</div>
                        <div v-show="formState.user.customeraddressType == '2'">ต่างประเทศ</div>
                        <Address v-if="isshowsentaddress && formState.user.customeraddressType == '1'" :key="update"
                            :setprovince="formState.user.ProvinceName" :setdistricts="formState.user.BorderName"
                            :setsubdistricts="formState.user.DistrictName" :setpostcode="formState.user.PostCode"
                            @province="onprovince($event)" @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)" @postcode="onpostcode($event)" :isshowinput="false">
                        </Address>
                        <SelectOption :formStates="formState" v-if="formState.user.customeraddressType == '2'"
                            v-model:value="formState.user.selectcountry"></SelectOption>
                    </a-form-item>
                </a-col>
                <a-col :span="6" :order="3">
                    <div>
                        เบอร์ติดต่อ :{{ formStateStore.user.selectcountry.selectcountrycode_number }} {{
                            formState.user.customerphonethai }}
                    </div>
                    <div>
                        เบอร์ติดต่อ(ฉุกเฉิน) : {{ formState.user.customerphoneemergency }}
                    </div>
                    <div v-show="formState.user.customerphone">
                        เบอร์ติดต่อต่างประเทศ <a-input-group compact>
                            <a-select :disabled="true" show-search v-model:value="formState.user.selectcountry
                                .selectcountrycode
                                " placeholder="รหัสโทร" style="width: 100px" :options="listcodephone"
                                :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
                                @change="handleChange"></a-select>
                            <a-input :disabled="true" v-model:value="formState.user.customerphone" @input="acceptNumber"
                                style="width: 50%">
                            </a-input>
                        </a-input-group>
                    </div>

                </a-col>
            </a-row>
        </div>

        <ProductListByCustomerCode v-if="false && Storedata.methods.checkGrantProductListByCustomerCode()"
            :rowselected="rowselected" @selectedsellorder="showdetialselectedsellorder($event)">
        </ProductListByCustomerCode>


        <MoreDelivery v-if="false && Storedata.methods.checkGrantMoreDelivery()" :rowselected="rowselected"
            @selectedsellorder="showdetialselectedRowKeys($event)">
        </MoreDelivery> 
        <div class="p-5 mb-5 card">
            <TableAcceptCreateDelivery v-if="false && !isshowpackproduct"></TableAcceptCreateDelivery>
            <div v-show="Storedata.methods.checkshowscanpacking()" class="relative p-4 card">

                <a-row>
                    <a-col :span="24" class="m-auto text-center pb-4"> <a-divider orientation="left">เลือกสินค้า
                        </a-divider>
                        <a-input-group compact>
                            <a-input v-focus v-model:value="formStateStore.scanpacking" style="width: calc(100% - 60%)" />
                            <a-tooltip title="copy git url">
                                <a-button>
                                    <template #icon>
                                        <BarcodeOutlined />
                                    </template>
                                </a-button>  
                                <a-button class="ml-4"
                                    :disabled="!(formStateStore.user.finishpacked_scanproduct && formStateStore.user.finishpacked_scantracking)"
                                    v-show="Storedata.methods.checkgrantbtnsaveDelivery()" style="float: right"
                                    type="primary" @click="isClickSubmit()">
                                    {{ formStateStore.btnsubmit }}</a-button>
                            </a-tooltip>
                        </a-input-group>
                    </a-col>

                </a-row>
                <a-row>
                    <a-col :span="6">
                        <div class="m-auto text-center pb-4">
                            <div v-show="__is_start_record" class="led">
                            </div>

                            กล้อง
                            <!-- <a-button @click="startTimer">Start</a-button>
                            <a-button @click="stopTimer">Stop</a-button> -->
                            <video :class="{ 'isstart': __is_start_record }"
                                style="width: 50%;border: 2px solid black;margin: auto;margin-bottom: 20px;" id="video"
                                autoplay></video>
                            <div :style="timeStyle">
                                <a-typography-text type="danger" strong>{{ currentTime }} นาที</a-typography-text>
                            </div>
                            <a-button class="m-3" @click="recordvideo">เปิดกล้อง</a-button>
                            <a-button class="m-3" @click="stopcam">ปิดกล้อง</a-button>
                            <a-button class="m-3" @click="startRecording">อัด </a-button>
                            <a-button class="m-3" @click="captureImage">ถ่ายภาพ</a-button>
                        </div>

                        <div class="m-auto text-center pb-4">
                            <canvas ref="canvasElement" width="100" height="100"
                                style="width: 50% !important;margin: auto; display: none;"></canvas>
                        </div>
                        <div class="m-auto text-center pb-4">
                            <span class="p-3"> รูปถ่าย </span>
                            <img :src="capturedImage" v-if="capturedImage" alt="Captured Image"
                                style="width: 50%;border: 2px solid black;margin: auto;margin-bottom: 20px;">
                            <div v-else>
                                <a-skeleton-image />
                            </div>
                        </div>

                    </a-col>
                    <a-col :span="18">
                        <div class="p-2">
                            <a-spin v-show="formStateStore.scanpacking" tip="รอสักครู่...">
                                <a-alert message="กำลังตรวจสอบ"
                                    :description="'กำลังตรวจสอบ Barcode ' + formStateStore.scanpacking"></a-alert>
                            </a-spin>
                        </div>
                        <CreateDeliveryTranfer v-if="formStateStore?.listcreatedelivery.length > 0" :isdisable="true">
                        </CreateDeliveryTranfer>
                        <CreateDeliveryTrackingTranfer :key="updatecomponent" :isdisable="true">
                        </CreateDeliveryTrackingTranfer>
                    </a-col>
                </a-row>
                <!-- <a-row>
                    <a-col :span="8">
                        <div class="m-auto text-center pb-4">
                            <canvas ref="canvasElement" width="100" height="100"
                                style="width: 50% !important;margin: auto; display: none;"></canvas>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="m-auto text-center pb-4">
                            รูปถ่าย
                            <img :src="capturedImage" v-if="capturedImage" alt="Captured Image"
                                style="width: 50%;border: 2px solid black;margin: auto;margin-bottom: 20px;">
                        </div>
                    </a-col>
                </a-row> -->

            </div>


        </div>
    </a-form>

    <!-- เรียกกัน error -->
    <ProductItems v-if="flase"></ProductItems>
    <!-- เรียกกัน error -->

    <div class="pt-4">
        <!-- แสดง จากการกด SO รายการขาย -->
        <FromCustomerProductDetail v-if="false && isshowDetailSellOrder.isshow" :rowselected="rowselected"
            :isshowDetailSellOrder="isshowDetailSellOrder"></FromCustomerProductDetail>
        <!-- แสดง จากการกด SO รายการขาย -->
    </div>

    <div>
        <a-modal v-model:visible="visiblesms" width="800px" title="ส่ง sms" @ok="handleOksms">
            <div class="p-3">
                <a-textarea v-model:value="sms.message" placeholder="ข้อความ" :auto-size="{ minRows: 10, maxRows: 20 }" />
            </div>
            <div class="p-3">
                <a-input v-model:value="sms.link" addon-before="link:" placeholder="link" />
            </div>
            <div class="p-3">
                <a-input v-model:value="sms.phone" addon-before="เบอร์โทรที่ส่ง:" placeholder="เบอร์โทร" />
            </div>
            <div class="p-3">
                <a-checkbox v-model:checked="checkedsms">ส่ง sms หาลูกค้า</a-checkbox>
            </div>
        </a-modal>

    </div>
</template>
<script>

import CreateRecord from '@/module/Delivery/component/CreateRecord.vue'
import VueBarcode from '@chenfengyuan/vue-barcode';
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    computed,
    watch,
    createVNode
} from "vue";
import ProductItems from "../../CustomerOrder/SellCreate/component/ProductItems.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import axios from "axios";
import Storedata from "@/store/Storedata.js";
import { Modal } from "ant-design-vue";
// import Uploadfile from "../component/form/Uploadfile.vue";
import Address from "../../CustomerOrder/SellCreate/component/Address.vue";
import FromCustomerProductDetail from "@/module/CustomerOrder/SellCreate/component/FromCustomerProductDetail.vue";
// import Inputnumber from "@/module/Sell/SellCreate/component/form/Inputnumber.vue";
import {
    UserOutlined,
    LeftOutlined
} from "@ant-design/icons-vue";
import SelectOption from "@/module/Sell/SellCreate/component/form/SelectOption.vue";

import _ from "lodash";
import { FormHelper } from "@/helper/FormHelper.js";
import {
    ContactModel,
    GetContactModel,
    CustomerModel,
    ProductModel,
    ProductDeliveryAddressModel,
    PaymentModel
} from "@/core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
import ProductListByCustomerCode from "@/module/CustomerOrder/SellCreate/component/form/ProductListByCustomerCode.vue";
import { formStateStore } from "@/store/storebackoffice";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import MoreDelivery from "@/module/Delivery/component/MoreDelivery.vue";
import { useRoute } from 'vue-router'
import TableAcceptCreateDelivery from "./TableAcceptCreateDelivery.vue";
import CreateDeliveryTranfer from "./CreateDeliveryTranfer.vue";
import CreateDeliveryTrackingTranfer from "./CreateDeliveryTrackingTranfer.vue";
import {
    ExclamationCircleOutlined,
    BarcodeOutlined,
} from "@ant-design/icons-vue";
import { async } from 'q';

export default defineComponent({
    name: "FromCreate",
    provide: {
        Storedata: Storedata
    },
    props: {
        rowselected: null,
        isrecord: null
    },
    mixins: [LocaleMixin],
    components: {
        LeftOutlined,
        VueBarcode,
        BarcodeOutlined,
        CreateDeliveryTrackingTranfer,
        FromCustomerProductDetail,
        ProductItems,
        // Uploadfile,
        Address,
        // Inputnumber,
        UserOutlined,
        SelectOption,
        // NumberOutlined,   
        ProductListByCustomerCode
        // Baseinput,
        ,
        MoreDelivery,
        TableAcceptCreateDelivery,
        CreateDeliveryTranfer,
        CreateRecord
    },
    setup(props) {

        const canvasElement = ref(null);
        const capturedImage = ref(null);

        const is_record = ref(props.isrecord)
        const sms = ref({
            phone: "",
            message: "จากเพจคุณลูกกอ สามารถตรวจสอบสถานะได้ดังนี้",
            link: "",
        })
        // const stopcam = ref(false)
        const visiblesms = ref(false)
        const checkedsms = ref(false)
        const isshowsentaddress = ref(false)
        formStateStore.scanpacking = "";
        formStateStore.btnsubmit = 'เริ่มแพ็คสินค้า'
        const isshowcanceldelivery = ref(false)
        const updatecomponent = ref(0)
        const route = useRoute()
        console.log("Storedata.state.profile : ", Storedata.state.profile.login.role)
        const rowselecteddetail = reactive(props.rowselected);

        const title = "ลูกค้า";
        const producttype = ref([]);
        // const gender = ref("1");
        const typelocal = ref("1");
        const update = ref(0);

        const age = ref(18);
        moment.locale("th");
        const datecreatetoday = moment()
            .add(543, "year")
            .format("LL");
        const layout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 12
            }
        };
        const validateMessages = {
            required: "${label} is required!",
            types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!"
            },
            number: {
                range: "${label} must be between ${min} and ${max}"
            }
        };

        const calculateAge = birthday => {
            console.log(birthday)
            if (birthday == null) return (formState.user.customerage = 0);
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.user.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
        };

        const calculateAge_setting = birthday => {
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        const onModalAddressChagne = values => {
            console.log(values);
        };
        const onselectchange = value => {
            formState.user.customername =
                value[0].item.CustomerName +
                " " +
                value[0].item.CustomerLastName;
            formState.user.customertel = value[0].item.CustomerContractNumber;
            formState.user.customeremail = value[0].item.CustomerEmail;
            formState.user.lineid = value[0].item.lineid;
            formState.user.customerAddress = value[0].item.CustomerAddress;
            formState.user.customerbirthday = value[0].item.CustomerBirthDay;
            formState.user.customerintroduction = value[0].item.CustomerDesc;
            formState.user.customerage = calculateAge_setting(
                value[0].item.CustomerBirthDay
            );
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });
        const formState = reactive(formStateStore)
        formState.user.customercode = rowselecteddetail.customercode

        const updatetracktable = () => {
            updatecomponent.value++

        }



        // cam #####################
        const __is_record = ref(props.is_record)
        const __is_start_record = ref(false)
        const __is_stop = ref(false)
        var mediaRecorder = null
        var videoElement

        const getCameraDeviceNames = async () => {
            try {
     
                // Get a list of available media devices
                const devices = await navigator.mediaDevices.enumerateDevices();

                // Filter for video input devices (cameras)
                const cameraDevices = devices.filter(device => device.kind === 'videoinput');

                // Extract the names of camera devices
                const cameraDeviceNames = cameraDevices.map(device => device.label);
                console.log("cameraDeviceNames : ", cameraDeviceNames)


                // Log the camera device names
                return cameraDeviceNames
            } catch (error) {
                console.error('Error accessing media devices:', error);
            }
        }
 
        getCameraDeviceNames()
        const recordvideo = async () => {
            try {
                var deviceName = "USB 2.0 HD1080P PC Camera (0458:6006)"
                var mac = "FaceTime HD Camera"
                // Get a list of available media devices
                const devices = await navigator.mediaDevices.enumerateDevices();

                // Find the desired camera device by name
                const cameraDevice = devices.find(device => device.kind === 'videoinput' && (device.label === deviceName|| device.label === mac));

                if (cameraDevice) {
                    // Constraints to use the selected camera device
                    const constraints = {
                        video: {
                            deviceId: cameraDevice.deviceId
                        }
                    };

                    // Access the camera stream using the selected device
                    const stream = await navigator.mediaDevices.getUserMedia(constraints);
                    videoElement = document.getElementById('video');
                    videoElement.srcObject = stream;


                    if (is_record.value) {
                        stopTimer()
                        startRecording()
                    }
                    // Use the camera stream in your application
                    // ...
                } else {
                    console.error('Camera device not found.');
                }
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }


        var recordedChunks = ref([]);
        if (is_record.value && !__is_stop.value) {


            recordvideo()
        }


        const startRecording = async () => {


            __is_record.value = true
            __is_start_record.value = true
            startTimer();
            var videoElement = document.getElementById('video');
            const options = { mimeType: 'video/webm;codecs=vp9' }
            mediaRecorder = new MediaRecorder(videoElement.srcObject, options);

            mediaRecorder.ondataavailable = async function (event) {
                recordedChunks.value.push(event.data);
                //  
                // recordedChunks.value = await convertBlobToBase64(event.data)
                //  
                // if (recordedChunks.value) {
                //     stopRecording()
                // }
            };

            const filestop = ref(null)
            mediaRecorder.onstop = async () => {
                const blob = new Blob(recordedChunks.value, { type: 'video/mp4' }); // Create a Blob from recorded chunks

                // Convert the Blob to an MP4 file
                const reader = new FileReader();
                reader.onloadend = () => {
                    const buffer = new Uint8Array(reader.result);
                    const file = new Blob([buffer], { type: 'video/webm' });

                    // Perform further actions with the MP4 file (e.g., save or display it)
                    // Replace the example code below with your own logic
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(file);
                    downloadLink.download = 'recorded.webm';
                    downloadLink.click();

                    filestop.value = file
                    console.log("filestop.value ", filestop.value)
                    if (filestop.value) {
                        stopRecording(filestop.value)
                    }
                };



                reader.readAsArrayBuffer(blob);
            };

            mediaRecorder.start();
        }


        const convertBlobToBase64 = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];

                    resolve(base64String);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(blob);
            });
        }
        const stopRecording = async (filestop) => {
            // mediaRecorder.stop();

            setTimeout(async () => {

                if (filestop) {

                    var uploadparam = {};
                    var data = new FormData();
                    data.append('file', filestop);
                    data.append('filename', formStateStore.rowselecteddetail + ".webm");
                    data.append('folder', 'trackvideo');
                    await BzbsUser.apiuploadtypefile(data)
                        .then(res => {

                            // stopcam()
                            // window.location.reload()
                            console.log(res.data);

                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

            }, 3000);

        }

        const stopcam = () => {
            stopTimer()
            __is_start_record.value = false
            setTimeout(() => {
                __is_stop.value = true
                mediaRecorder.stop();
                videoElement.srcObject.getTracks().forEach(track => track.stop());
                __is_record.value = false
                __is_start_record.value = false
            }, 1000);

            // setTimeout(() => {
            //     stopRecording()
            // }, 2000);

        }

        // if (__is_record.value) {
        //      
        //     stopcam()

        // }

        //#####################

        watch(

            () => formStateStore.scanpacking,
            () => {
                if (formStateStore.scanpacking == formStateStore.rowselecteddetail) {

                    isClickSubmit()
                }
            },
            () => formState.user.customeraddressType,
            () => {
                if (formState.user.customeraddressType == "1") {

                    formState.user.selectcountry.selectcountry = "TH";
                }
            },
            () => formState.user.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    )
                ) {
                    formState.user.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    );
                }
            }

        );
        // setTimeout(() => {
        //     formState.user.orderdate = moment().format("YYYY-MM-DD");
        // }, 100);
        onMounted(async () => {

            await gencodecustomer();
            await getdeliveryaddress()
        });
        const getdeliveryaddress = async () => {

            var param = {
                customerorder: formState.user.customercode,
                productsellorder: formState.user.productsellorder,
                additionaltage: formState.rowselecteddetail,
            }
            console.log(" formStateStore.productsellorder :", formStateStore.productsellorder)


            await BzbsUser.apigetdefaultproductdeliveryaddress(param.customerorder, param.productsellorder, param.additionaltage)
                .then(res => {
                    if (res.data.length == 0) return
                    console.log("Sent Address ", res)
                    formStateStore.user.customernickname = res.data[0].Name
                    formStateStore.user.customerlastname = res.data[0].Lastname
                    formStateStore.user.customername = res.data[0].Name
                    formState.user.customeraddressType = res.data[0].TypeAddress
                    formState.user.addressdesc = res.data[0].Address
                    formState.user.ProvinceName = res.data[0].ProvinceName
                    formState.user.BorderName = res.data[0].BorderName
                    formState.user.DistrictName = res.data[0].DistrictName
                    formState.user.PostCode = res.data[0].PostCode

                    formStateStore.user.customeraddressType = res.data[0].TypeAddress
                    formStateStore.user.addressdesc = res.data[0].Address
                    formStateStore.user.ProvinceName = res.data[0].ProvinceName
                    formStateStore.user.BorderName = res.data[0].BorderName
                    formStateStore.user.DistrictName = res.data[0].DistrictName
                    formStateStore.user.PostCode = res.data[0].PostCode
                    formStateStore.user.customerphonethai = res.data[0].Phone

                    sms.value.phone = res.data[0].Phone



                    console.log("formState.user ", formState.user)
                    isshowsentaddress.value = true;

                    setTimeout(() => {


                        var ccode = formStateStore.user.selectcountry
                            .selectcountrycode
                        var findcode = listcodephone.value.filter(item => {
                            return item.value == ccode

                        })

                        if (findcode.length > 0) {
                            formStateStore.user.selectcountry.selectcountrycode_number = findcode[0].label
                            sms.value.phone = formStateStore.user.selectcountry.selectcountrycode_number + res.data[0].Phone
                        }

                    }, 3000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        const SellOrderTransaction = () => {
            BzbsUser.apiSellOrderTransaction(null)
                .then(res => {
                    // formState.user.customercode =
                    //   "C-" + moment(new Date()).format("YYYYMMDDHHmmss");
                    return (formState.user.sellorderTransaction =
                        res.data[0].SellOrderTransactionID);
                })

                .catch(error => {
                    console.log(error);
                });
        };

        const imageslip = imglist => {
            if (imglist) {
                formState.user.fileList = imglist;
            }
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const options = ref([]);
        const handleChange = value => {
            onselectchange(options.value.filter(item => item.value == value));
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {

            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const queryProductType = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/producttype").then(res => {
                res?.data[0]?.map(item => {
                    let obj = {};
                    obj.label = item.ProductName;
                    obj.value = item.ProductName;
                    producttype.value.push(obj);
                });
            });
        };

        const getCustomer = async customercode => {

            console.log("customercode", customercode)
            await BzbsUser.apiGetCustomercustomercode(customercode)
                .then(res => {

                    if (res.data[0]) {
                        return initfil(res.data[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };


        const onprovince = values => {
            console.log("onprovince", values);
            formState.user.ProvinceName = values.provinceName;
            formState.user.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.user.BorderName = values.districtsName;
            formState.user.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.user.DistrictID = values.subdistrictsID;
            formState.user.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.user.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function (element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });



                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const initcontact = CustomerCode => {
            BzbsUser.apicontact(CustomerCode)
                .then(res => {
                    const temp_obj = [];
                    res.data?.forEach(element => {
                        element.sellorderTransaction =
                            formState.user.sellorderTransaction;
                        element.customercode = formState.user.customercode;
                        var obj = new GetContactModel(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    formState.getcontactlist = temp_obj;
                    formState.user.contactList = temp_obj;
                    console.log(
                        "formState.user.contactlist :",
                        formState.getcontactlist
                    );
                    formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const gencodecustomer = async () => {
            var e = {};

            if (rowselecteddetail.customercode != undefined) {
                initcontact(rowselecteddetail.customercode);
                await getCustomer(rowselecteddetail.customercode);
            }
            if (e == "") {
                return;
            }
        };
        const initfil = async e => {


            formState.user.productsellorder = e.ProductSellOrder
            formState.user.contact = e.Contact;
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = e.CustomerName;
            formState.user.customerlastname = e.CustomerLastName;
            formState.user.customerphone = e.CustomerContractNumber;
            formState.user.customerbirthday = e.CustomerBirthDay;
            formState.user.customeremail = e.CustomerEmail;
            formState.user.addressdesc = e.CustomerAddress;
            formState.user.introduction = e.CustomerDesc;
            formState.user.ProvinceID = e.ProvinceID;
            formState.user.ProvinceName = e.ProvinceName;
            formState.user.BorderID = e.BorderID;
            formState.user.BorderName = e.BorderName;
            formState.user.BorderID = e.DistrictId;
            formState.user.DistrictID = e.DistrictID;
            formState.user.DistrictName = e.DistrictName;
            formState.user.PostCode = e.PostCode;
            formState.user.customerlineid = e.LineID;
            formState.user.customerfacebook = e.Facebook;
            formState.user.contactname = e.ContactName;
            formState.user.contact = e.Contact;
            formState.user.customergender =
                e.CustomerGender != undefined
                    ? e.CustomerGender.replace(/\s/g, "")
                    : "notspecified";
            formState.user.customernickname = e.CustomerNickName;
            formState.user.customerbirthdaytype = e.CustomerBirthDayType;
            formState.user.customercareer = e.CustomerCareer;
            formState.user.customeraddressType = e.CustomerAddressType;
            formState.user.selectcountry.selectcountry = e.SelectCountry;
            formState.user.customerphoneemergency = e.CustomerPhoneEmergency;
            formState.user.customerphonethai = e.CustomerPhoneThai;
            formState.user.contactimageurl = e.ContactImageUrl;

            update.value = update.value + 1;
            console.log(e);
            calculateAge(formState.user.customerbirthday)
        };
        // const clearfil = e => {
        //     formState.user.contact = "";
        //     formState.user.customercode = e.CustomerCode;
        //     formState.user.customername = "";
        //     formState.user.customerlastname = "";
        //     formState.user.customerphone = "";
        //     formState.user.customerbirthday = "";
        //     formState.user.customeremail = "";
        //     formState.user.addressdesc = "";
        //     formState.user.introduction = "";
        //     formState.user.ProvinceID = "";
        //     formState.user.ProvinceName = "";
        //     formState.user.BorderID = "";
        //     formState.user.BorderName = "";
        //     formState.user.BorderID = "";
        //     formState.user.DistrictName = "";
        //     formState.user.PostCode = "";
        //     formState.user.customerlineid = "";
        //     formState.user.customerfacebook = "";
        //     formState.user.contactname = "";
        //     formState.user.contact = "";
        //     // ImageURL: "",
        //     formState.user.customergender = "notspecified";
        //     formState.user.customernickname = "";
        //     formState.user.customerbirthdaytype = "";
        //     formState.user.customercareer = "";
        //     formState.user.customeraddressType = "1";
        //     formState.user.customerphoneemergency = "";
        //     formState.user.customerphonethai = "";
        //     formState.user.selectcountry.selectcountry = "";
        //     update.value = update.value + 1;
        // };

        const onFinish = async values => {
            // progress.value = true;

            insertdatabase();
            console.log("DATA >> ", values);
            console.log("Success:", values);
        };
        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const successpack = message => {
            notification["success"]({
                message: message
            });
        };
        const checkcondition = async () => {
            console.log("checkcondition", formState.user.contactList)
            const result = formState.user.contactList.some(
                item => item.contactstartdefault == "true"
            );
            if (result) {
                return true;
            } else {
                warning("กรุณาตั้งค่าเริ่มต้นช่องทางการติดต่อ");
                return false;
            }
        };

        const insertdatabase = async () => {


            if (checkcondition()) {
                Storedata.state.loading.ishow = true;
                await insertcontract();
                Storedata.state.loading.percent += 14.28;
                await insertcustomer();
                Storedata.state.loading.percent += 14.28;
                await insertdeliveryinside();
                Storedata.state.loading.percent += 14.28;
                await insertdeliveryoutside();
                Storedata.state.loading.percent += 14.28;
                await insertproduct();
                Storedata.state.loading.percent += 14.28;
                await insertproductdeliveryaddress();
                Storedata.state.loading.percent += 14.28;
                await insertpayment();
                Storedata.state.loading.percent += 14.28;
                // await this.insertcreatecustomer();
                // await this.insertcreateproduct();
                // await this.insertcreatedeliveryaddress();
                // await this.insertcreatepayment();

                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                    success();
                }, 2000);
            }
        };
        const insertcontract = async () => {
            let params = [];
            await formState.user.contactList.forEach((element, index) => {

                element.productsellorder = formState.user.sellorderTransaction;
                element.customerorder = formState.user.customercode;
                element.key = (index + 1).toString();
                var obj = new ContactModel(element);
                console.log("obj", obj);
                params.push(obj);
            });
            console.log("param", params);

            // let slip_url = [];
            var index_uploadimage = 0;
            for await (let items of params) {
                if (items.ContactImage[0]?.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename:
                            formState.user.customercode +
                            "_" +
                            index_uploadimage +
                            ".jpg",
                        base64: items.ContactImage[0].preview,
                        folder: "contact"
                    };
                    await BzbsUser.apiuploadimage(uploadparam)
                        .then(res => {
                            params[index_uploadimage]["ContactImage"] = "";
                            params[index_uploadimage]["ContactImageUrl"] = "";
                            params[index_uploadimage]["ContactImage"] =
                                uploadparam.filename;
                            params[index_uploadimage]["ContactImageUrl"] =
                                res.data.url;
                            console.log(res.data);
                            index_uploadimage++;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    index_uploadimage++;
                }
            }

            for await (let obj of params) {
                await BzbsUser.apiupdatecontact(obj)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertcustomer = async () => {
 
            let params = new CustomerModel(formState.user);

            console.log("param", params);

            await BzbsUser.apiUpdateCustomer(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // } 
        };
        const insertdeliveryinside = async () => {
            //insert inside จัดส่ง ในประเทศ
            if (formState.inside.length <= 0) return;
            // var inside = formState.inside;
            // var index_inside = 0;
            // for await (let items of inside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_inside = {};
            //         uploadparam_inside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_inside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "inside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_inside)
            //             .then(res => {
            //                 formState.inside[index_inside]["slipiurl"] = "";
            //                 formState.inside[index_inside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_inside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let inside_obj of formState.listproduct) {
                if (inside_obj.inside != undefined) {
                    for await (let items of inside_obj.inside) {
                        var param_inside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "inside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: inside_obj.productkey
                        };
                        await BzbsUser.apicreatedelivery(param_inside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertdeliveryoutside = async () => {
            //insert outside จัดส่ง ในประเทศ
            if (formState.outside.length <= 0) return;
            // var outside = formState.outside;
            // var index_outside = 0;
            // for await (let items of outside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_outside = {};
            //         uploadparam_outside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_outside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "outside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_outside)
            //             .then(res => {
            //                 formState.outside[index_outside]["slipiurl"] = "";
            //                 formState.outside[index_outside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_outside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let outside_obj of formState.listproduct) {
                if (outside_obj.inside != undefined) {
                    for await (let items of outside_obj.outside) {
                        var param_outside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "outside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl
                        };
                        await BzbsUser.apiupdateproductdelivery(param_outside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertproduct = async () => {
 
            console.log("insert :", formState.listproduct)

            for await (let listproduct_items of formState.listproduct) {

                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename: formState.user.customercode + '_' + Math.floor(Date.now() / 1000) + ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
                listproduct_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;

                let params = new ProductModel(listproduct_items);

                console.log("param", params);

                await BzbsUser.apiupdateproduct(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertproductdeliveryaddress = async () => {

            for await (let listproductdeliveryaddress_items of formState.user
                .addressfrom) {
                // if (listproduct_items.productyanimage != undefined) {
                //     var queyanimage = listproduct_items.productyanimage;
                //     for await (let items of queyanimage) {
                //         if (items.preview != undefined) {
                //             var uploadparam = {};
                //             uploadparam = {
                //                 filename: formState.user.customercode + ".jpg",
                //                 base64: items.preview,
                //                 folder: "queyan"
                //             };
                //             await BzbsUser.apiuploadimage(uploadparam)
                //                 .then(res => {
                //                     listproduct_items["productyanimage"] = "";
                //                     listproduct_items["productyanimage"] =
                //                         res.data.url;
                //                     console.log(res.data);
                //                 })
                //                 .catch(error => {
                //                     console.log(error);
                //                 });
                //         }
                //     }
                // }

                listproductdeliveryaddress_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproductdeliveryaddress_items.customerorder =
                    formState.user.customercode;

                var phonecode = ""
                if (listproductdeliveryaddress_items?.phonecode) {
                    phonecode = listproductdeliveryaddress_items?.phonecode
                }
                else if (listproductdeliveryaddress_items.phonecode && listproductdeliveryaddress_items.phonecode[0].label) {
                    phonecode = listproductdeliveryaddress_items.phonecode[0].label
                }
                if (phonecode) {

                    listproductdeliveryaddress_items.phonecode = phonecode
                }
                let params = new ProductDeliveryAddressModel(
                    listproductdeliveryaddress_items
                );

                console.log("param", params);

                await BzbsUser.apiupdateproductdelivery(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertpayment = async () => {
            var indeximage = 0
            if (formState.pay.slipimage != undefined) {
                for await (let items of formState.pay.slipimage) {
                    indeximage++
                    if (items.preview != undefined) {
                        var uploadparam = {};
                        uploadparam = {
                            filename: formState.user.customercode + '_' + indeximage + '_' + Math.floor(Date.now() / 1000) + ".jpg",
                            base64: items.preview,
                            folder: "payment"
                        };
                        await BzbsUser.apiuploadimage(uploadparam)
                            .then(res => {
                                formState.pay["slipimageurl"] = "";
                                formState.pay["slipimageurl"] = res.data.url;
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
            formState.pay.productsellorder =
                formState.user.sellorderTransaction;
            formState.pay.customerorder = formState.user.customercode;

            let params = new PaymentModel(formState.pay);
            console.log("param", params);
            await BzbsUser.apipayment(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const isshowDetailSellOrder = reactive({
            isshow: false,
            sellorder: ""
        });

        const showdetialselectedRowKeys = e => {
            // formState.user.sellorderTransaction = e
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };
        const showdetialselectedsellorder = e => {
            formState.user.sellorderTransaction = e
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };


        const clickblack = () => {

            formStateStore.isshowpacking = false

        }

        const acceptNumber = () => {
            var x = formState.user.customerphone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            formState.user.customerphone = !x[2] ? x[1] : x[1] + x[2] + (x[3] ? x[3] : '');
        }


        const checkGrantProductListByCustomerCode = () => {
            if (route.path == '/delivery') {
                return false
            }
            else {
                return true
            }
        }
        const isshowpackproduct = ref(false)
        const isClickSubmit = () => {

            isshowpackproduct.value = true
            if (route.path == '/createdeliverylist' && formStateStore.btnsubmit == 'ยืนยันการจัดส่ง') {
                formStateStore.btnsubmit = 'สร้าง Tracking No.'
            }
            else if (route.path == '/createdeliverylist' && formStateStore.btnsubmit == 'ยืนยันสินค้าจัดส่ง') {
                formStateStore.btnsubmit = 'ยืนยันการจัดส่ง'
            }
            else if (route.path == '/packproduct' && formStateStore.btnsubmit == 'เริ่มแพ็คสินค้า') {
                var title = "คุณต้องการแพ็คสินค้าใช่หรือไม่?"
                showConfirm(title, 'pack')

            }

        }
        const showConfirm = (title, status) => {
            console.log(status)
            Modal.confirm({
                title: title,
                icon: createVNode(ExclamationCircleOutlined),
                onOk() {
                    console.log('OK');
                    sms.value.link = "https://www.khunlookkor.com/tracking?track=" + formStateStore.listcreatedelivery[0].PD_TrackingNo
                    // visiblesms.value = true
                    //callsentsms()
                    apiupdateproductstatus()

                },
                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        };

        const apiupdateproductstatus = async () => {

            var param = {
                DeliveryStatus: 'packed',
                Desc: 'แพ็คเรียบร้อย',
                AdditionalTage: formState.rowselecteddetail
            }

            await BzbsUser.apiupdateproductdeliverystatus(param)
                .then(res => {
                    // captureImage()

                    stopcam()
                    console.log("update status ", res)
                    successpack("แพ็คเรียบร้อย")
                    // updatecomponent.value++

                    // callsentsms()
                    // visible.value = false;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const setshowpopup = (e) => {
            isshowcanceldelivery.value = e

        }

        const handleOksms = (e) => {
            visiblesms
                .value = false

            if (checkedsms.value) {

                callsentsms()

                //call api sms
            }
            //ok
            //call api sms
            //

        }

        const callsentsms = async () => {

            var param = {
                "sender": "Demo-SMS",
                "message": sms.value.message,
                "url": sms.value.link,
                "phone": sms.value.phone
            }

            var type = formStateStore.listcreatedelivery[0].CustomerAddressType
            if (type == '1') {
                await BzbsUser.apisentsmsthai(param)
                    .then(res => {
                        console.log(res.data);
                        setTimeout(() => {
                            successpack("ส่ง sms สำเร็จ")
                        }, 3000);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else if (type == '2') {
                await BzbsUser.apisentsmsinter(param)
                    .then(res => {
                        console.log(res.data);
                        setTimeout(() => {
                            successpack("ส่ง sms สำเร็จ")
                        }, 3000);
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        }


        // const takePhoto = () => {
        //     // Set the canvas size to match the video element
        //     // canvasElement.width = videoElement.videoWidth;
        //     // canvasElement.height = videoElement.videoHeight;

        //     // Draw the current video frame onto the canvas
        //     var context = canvasElement.getContext('2d');
        //     context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

        //     // Get the image data from the canvas as a data URL
        //     var dataURL = canvasElement.toDataURL('image/jpeg');

        //     // Do something with the data URL (e.g., display it in an <img> element)
        //     var imgElement = document.getElementById('imgElement');
        //     imgElement.src = dataURL;
        //     console.log("imgElement.src", imgElement.src)
        // }
        const captureImage = () => {
            if (videoElement && canvasElement.value) {

                const context = canvasElement.value.getContext('2d');
                canvasElement.value.width = videoElement.videoWidth;
                canvasElement.value.height = videoElement.videoHeight;
                context.drawImage(videoElement, 0, 0, canvasElement.value.width, canvasElement.value.height);
                capturedImage.value = canvasElement.value.toDataURL('image/png');

                setTimeout(async () => {
                    if (capturedImage.value) {
                        var uploadparam = {};
                        uploadparam = {
                            filename: formStateStore.rowselecteddetail +
                                ".jpeg",
                            base64: capturedImage.value,
                            folder: "trackimage"
                        };
                        await BzbsUser.apiuploadimage(uploadparam)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }

                }, 1000);
            }
        };

        let startTime;
        let timerInterval;
        const currentTime = ref("00:00")
        const startTimer = () => {
            startTime = new Date().getTime();
            timerInterval = setInterval(updateTimer, 1000);
        }

        const stopTimer = () => {
            clearInterval(timerInterval);
        }

        const updateTimer = () => {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - startTime;
            const formattedTime = formatTime(elapsedTime);
            console.log(formattedTime);
        }

        const formatTime = (milliseconds) => {
            const seconds = Math.floor(milliseconds / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes % 60).padStart(2, '0');
            const formattedSeconds = String(seconds % 60).padStart(2, '0');

            currentTime.value = `${formattedMinutes}:${formattedSeconds}`;
        }



        return {
            sms,
            callsentsms,
            checkedsms,
            visiblesms,
            handleOksms,
            isshowcanceldelivery,
            setshowpopup,
            updatecomponent,
            isshowpackproduct,
            isClickSubmit,
            checkGrantProductListByCustomerCode,
            acceptNumber,
            clickblack,
            isshowDetailSellOrder,
            showdetialselectedRowKeys,
            showdetialselectedsellorder,
            insertproduct,
            insertproductdeliveryaddress,
            gencodecustomer,
            title,
            formState,
            onFinish,
            layout,
            validateMessages,
            SellOrderTransaction,
            onselectchange,
            calculateAge,
            options,
            listcodephone,
            handleChange,
            handleBlur,
            handleFocus,
            filterOption,
            onFinishFailed,
            queryProductType,
            producttype,
            onModalAddressChagne,
            publishedBooksMessage,
            getallproduct,
            success,
            imageslip,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            calculateAge_setting,
            datecreatetoday,
            typelocal,
            age,
            update,
            insertdatabase,
            insertcontract,
            insertcustomer,
            rowselecteddetail,
            getCustomer,
            formStateStore, Storedata, errorInfo: FormHelper.genErrorInfo(7),
            datetest: ref(new Date()),
            updatetracktable,
            successpack,
            isshowsentaddress,
            stopcam,
            is_record,
            __is_record,
            recordedChunks,
            recordvideo,
            startRecording,
            stopRecording,
            __is_start_record,
            captureImage,
            canvasElement,
            capturedImage,
            captureImage,
            currentTime
        };
    }
    // computed: {
    //     createproduct: function() {
    //         return Storedata.state.createproduct;
    //     }
    // }
});
</script>
<style lang="scss" scoped>
.ant-row {
    flex-direction: row;
}

.date_create {
    display: flex;
    flex-flow: row-reverse;
}

.wrapper_createnew {
    display: flex;
    align-items: center;
}

.wrapper-icon-create {
    position: relative;
    top: 26px;
    left: 30px;
}


.led {
    position: absolute;
    left: 68%;
    top: 30px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(255, 136, 115);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    -webkit-animation: led 0.8s infinite;
    -moz-animation: led 0.8s infinite;
    -ms-animation: led 0.8s infinite;
    -o-animation: led 0.8s infinite;
    animation: led 0.8s infinite;
}



/* keyframes */
@-webkit-keyframes led {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes led {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@-ms-keyframes led {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes led {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@keyframes led {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes btn {
    from {
        margin-top: 0px;
        top: 22px;
    }

    to {
        margin-top: 2px;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.4);
    }
}
</style>
