<template>
    <div class="border-t last:border-b py-2">
        <div class="font-bold text-lg" v-if="address.personType == 'company'">
            {{address.companyName}}
        </div>
        <div class="font-bold text-lg" v-else>
            {{address.firstname}} {{address.lastname}}
        </div>
        <div class="text-gray-400">
            {{address.address}}
            {{address.subDistrictName}}
            {{address.districtName}}
            {{address.provinceName}}
            {{address.zipcode}}
        </div>
        <div class="pt-2">
            <button class="bg-yellow-500 text-white py-1 px-4 rounded-md text-sm mr-2" @click="onEditAddress()">Edit</button>
            <button class="bg-red-700 text-white py-1 px-4 rounded-md text-sm" @click="onDeleteAddress()">Delete</button>
        </div>
    </div>
</template>
<script>
import LocaleMixin from "@/mixin/LocaleMixin.js";
/**
 * @event onDeleteSuccess
 */
export default {
    name: "TaxAddressItem",
    mixins: [LocaleMixin],
    props: {
        address: Object,
    },
    methods: {
        onDeleteAddress: function() {
            this.$emit('onDeleteAddress', this.address);
        },
        onEditAddress: function() {
            this.$emit('onEditAddress', this.address);
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
