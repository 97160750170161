<template>
    <div>
        <InputNumber
                v-model:inputValue="address.zipcode"
                :minLength="5"
                :maxLength="5"
                :errorInfo="errorAddressInfo[0]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="isShowErrorLbl"
                :inputLbl="inputLbl.zipcode"
                @update:inputValue="onZipcodeChange()"
        ></InputNumber>
        <InputDropdown
                ref="dropdown-province"
                v-model:inputValue="address.provinceCode"
                :itemList="dropdownProvince"
                :errorInfo="errorAddressInfo[1]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="isShowErrorLbl"
                :inputLbl="inputLbl.province"
                @update:inputValue="selectProvince($event)"
        ></InputDropdown>
        <InputDropdown
                ref="dropdown-district"
                v-model:inputValue="address.disctictCode"
                :itemList="dropdownDistrict"
                :errorInfo="errorAddressInfo[2]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="isShowErrorLbl"
                :inputLbl="inputLbl.district"
                @update:inputValue="selectDistrict($event)"
        ></InputDropdown>
        <InputDropdown
                ref="dropdown-sub-district"
                v-model:inputValue="address.subDistrictCode"
                :itemList="dropdownSubDistrict"
                :errorInfo="errorAddressInfo[3]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="isShowErrorLbl"
                :inputLbl="inputLbl.subdistrict"
                @update:inputValue="selectSubDistrict($event)"
        ></InputDropdown>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import RegxHelper from "@/helper/RegxHelper.js";
import InputNumber from "@/components/Input/label/InputNumber.vue";
import InputDropdown from "@/components/Input/label/InputDropdown.vue";
import { FormHelper, DropdownListValue } from "@/helper/FormHelper.js";
//
import BzbsAddress from "@/core/Address/service/BzbsAddress.js";

export default {
    name: "FormAddress",
    components: {
        InputNumber,
        InputDropdown,
    },
    props: {
        //v-model
        inputValue:     { type: Object },
        //props
        errorInfo:      { type: Object, required: true },
        isFirstSave:    { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        //text
        inputLbl:       { type: Object, required: true },
    },
    data: function() {
        return {
            errorAddressInfo: FormHelper.genErrorInfo(4),

            address: null,

            arrProvince: [],
            arrDistrict: [],
            arrSubDistrict: [],
            arrFilteredProvince: [],
            arrFilteredDistrict: [],
            arrFilteredSubDistrict: [],

            dropdownProvince: [],
            dropdownDistrict: [],
            dropdownSubDistrict: [],
        };
    },
    created: function() {
        this.address = this.inputValue;
    },
    mounted: function() {
        if (this.address != null && !Util.isEmptyStr(this.address.zipcode)) {
            this.onZipcodeChange();
        }
    },
    methods: {
        //Event
        selectProvince: function (provinceCode) {
            if(!provinceCode) return;

            let province = this.arrFilteredProvince.find(item => item.provinceCode == provinceCode);
            this.filterDistrictByProvince(province);
        },
        selectDistrict: function (districtCode) {
            if(!districtCode) return;

            let district = this.arrFilteredDistrict.find(item => item.districtCode == districtCode);
            this.filterSubDistrictByDistrict(district);
        },
        selectSubDistrict: function (subDistrictCode) {
            if(!subDistrictCode) return;

            let subdistrict = this.arrFilteredSubDistrict.find(item => item.subdistrictCode == subDistrictCode);

            this.address.subDistrictCode = subdistrict.subdistrictCode;
            this.address.subDistrictName = this.locale == 1033
                ? subdistrict.subdistrictNameEN
                : subdistrict.subdistrictName;
        },
        onZipcodeChange: function () {
            if (RegxHelper.isZipcode(this.address.zipcode)) {
                BzbsAddress.apiGetProvinceFilterByZipcode(this.address.zipcode).subscribe((data) => {
                    //Reset array.
                    this.resetArrAddress();
                    // check not exists zipcode validation from return lists
                    if (data == null){
                        this.errorAddressInfo[0].isValid = false;
                        this.errorAddressInfo[0].msg = this.inputLbl.zipcode.invalid;
                    }
                    else{
                        //Setup address array.
                        this.arrProvince = data.listProvince;
                        this.arrDistrict = data.listDistrict;
                        this.arrSubDistrict = data.listSubDistrict;
                        this.initialAddressDDL();
                    }
                }, (err) => {
                    console.log(err);
                });
            } else {
                this.resetSelectValue();
                this.resetArrAddress();
                this.validateSelect();
            }
        },
        //Method
        initialAddressDDL: function () {
            if(this.arrProvince.length === 0) return;

            //Setup province data.
            var province;
            if(!this.address.provinceCode) {
                province = this.arrProvince[0];
            } else {
                province = this.arrProvince.find((item) => {
                    return (item.provinceCode == this.address.provinceCode);
                });
            }
            this.arrFilteredProvince.push.apply(this.arrFilteredProvince, this.arrProvince);

            let itemList = new DropdownListValue();
            for (let i of this.arrFilteredProvince) {
                let text = this.localeCode == 1033 ? i.provinceNameEN : i.provinceName;
                itemList.add(text, i.provinceCode);
            }
            this.dropdownProvince = itemList.getList();

            //Fill data.
            this.address.provinceCode = province.provinceCode;
            this.address.provinceName = this.locale == 1033
                    ? province.provinceNameEN
                    : province.provinceName;

            this.filterDistrictByProvince(province);
            this.$nextTick(() => {
                this.$refs['dropdown-province'].setValue(province.provinceCode);
            });
        },
        resetSelectValue: function () {
            this.$refs['dropdown-province'].clearSelect();
            this.$refs['dropdown-district'].clearSelect();
            this.$refs['dropdown-sub-district'].clearSelect();

            this.address.provinceCode = null;
            this.address.districtCode = null;
            this.address.subDistrictCode = null;
            this.address.provinceName = '';
            this.address.districtName = '';
            this.address.subDistrictName = '';
        },
        resetArrAddress: function () {
            this.arrProvince.length = 0;
            this.arrDistrict.length = 0;
            this.arrSubDistrict.length = 0;
            this.arrFilteredProvince.length = 0;
            this.arrFilteredDistrict.length = 0;
            this.arrFilteredSubDistrict.length = 0;

            this.dropdownProvince = [];
            this.dropdownDistrict = [];
            this.dropdownSubDistrict = [];
        },
        validateSelect: function () {
            if (this.address.provinceCode) {
                this.errorAddressInfo[1].isValid = true;
                this.errorAddressInfo[1].msg = '';
            } else {
                this.errorAddressInfo[1].isValid = false;
                this.errorAddressInfo[1].msg = this.inputLbl.province.required;
            }

            if (this.address.districtCode) {
                this.errorAddressInfo[2].isValid = true;
                this.errorAddressInfo[2].msg = '';
            } else {
                this.errorAddressInfo[2].isValid = false;
                this.errorAddressInfo[2].msg = this.inputLbl.district.required;
            }

            if (this.address.subDistrictCode) {
                this.errorAddressInfo[3].isValid = true;
                this.errorAddressInfo[3].msg = '';
            } else {
                this.errorAddressInfo[3].isValid = false;
                this.errorAddressInfo[3].msg = this.inputLbl.subdistrict.required;
            }

            if (!FormHelper.validate(this.errorAddressInfo)) {
                this.errorInfo.setValid(false);
                // this.errorInfo.setMsg()
            } else {
                this.errorInfo.setValid(true);
                this.$emit('update:inputValue', this.address);
            }
        },
        filterDistrictByProvince: function (province) {
            //Fill data.
            this.address.provinceCode = province.provinceCode;
            this.address.provinceName = this.locale == 1033
                    ? province.provinceNameEN
                    : province.provinceName;
            //Setup district data.
            var allfilteredDistrict = this.arrDistrict.filter(function (item) {
                return (item.provinceCode == province.provinceCode);
            });
            // this.arrFilteredDistrict.push.apply(this.arrFilteredDistrict, allfilteredDistrict);
            this.arrFilteredDistrict = allfilteredDistrict

            //Set selected item.
            // var self = this;
            var district;
            var filteredByDistrictCode;
            if(!this.address.districtCode) {
                district = allfilteredDistrict[0]
            } else {
                filteredByDistrictCode = this.arrFilteredDistrict.filter((item) => {
                    return (item.districtCode == this.address.districtCode);
                });

                district = filteredByDistrictCode && filteredByDistrictCode.length > 0
                    ? filteredByDistrictCode[0]
                    : allfilteredDistrict[0];
            }

            let itemList = new DropdownListValue();
            for (let i of this.arrFilteredDistrict) {
                let text = this.localeCode == 1033 ? i.districtNameEN : i.districtName;
                itemList.add(text, i.districtCode);
            }
            this.dropdownDistrict = itemList.getList();

            //Filter subdistrict.
            this.filterSubDistrictByDistrict(district);

            this.$nextTick(() => {
                this.$refs['dropdown-district'].setValue(district.districtCode);
            });
        },
        filterSubDistrictByDistrict: function (district) {
            //Fill data.
            this.address.districtCode = district.districtCode;
            this.address.districtName = this.locale == 1033
                    ? district.districtNameEN
                    : district.districtName;
            //Setup subdistrict data.
            var allfilteredSubDistrict = this.arrSubDistrict.filter(function (item) {
                return (item.districtCode == district.districtCode);
            });
            this.arrFilteredSubDistrict = allfilteredSubDistrict;
            // this.arrFilteredSubDistrict.push.apply(this.arrFilteredSubDistrict, allfilteredSubDistrict);

            //Set selected item.
            // var self = this;
            var subdistrict;
            var filteredBySubDistrictCode;
            if(!this.address.subDistrictCode) {
                subdistrict = allfilteredSubDistrict[0]
            } else {
                filteredBySubDistrictCode = this.arrFilteredSubDistrict.filter((item) => {
                    return (item.subdistrictCode == this.address.subDistrictCode);
                });

                subdistrict = filteredBySubDistrictCode && filteredBySubDistrictCode.length > 0
                    ? filteredBySubDistrictCode[0]
                    : allfilteredSubDistrict[0];
            }

            // Util.log("Filter sub district", this.arrFilteredSubDistrict);

            let itemList = new DropdownListValue();
            for (let i of this.arrFilteredSubDistrict) {
                let text = this.localeCode == 1033 ? i.subdistrictNameEN : i.subdistrictName;
                itemList.add(text, i.subdistrictCode);
            }
            this.dropdownSubDistrict = itemList.getList();

            //Fill data.
            this.address.subDistrictCode = subdistrict.subdistrictCode;
            this.address.subDistrictName = this.locale == 1033
                    ? subdistrict.subdistrictNameEN
                    : subdistrict.subdistrictName;

            this.$nextTick(() => {
                this.$refs['dropdown-sub-district'].setValue(subdistrict.subdistrictCode);
            });
            this.validateSelect();
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
