<template>
    <div class="clearfix">
        <!-- <pre>{{fileList}}</pre> -->
        <a-upload
            v-model:file-list="fileList"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            list-type="picture-card"
            @preview="handlePreview"
            @change="handleChange"
        >
            <div v-if="fileList.length < sizeupload">
                <plus-outlined />
                <div style="margin-top: 8px">Upload</div>
            </div>
        </a-upload>
        <a-modal
            :visible="previewVisible"
            :title="previewTitle"
            :footer="null"
            @cancel="handleCancel"
            @remove="handleremove"
        >
            <img
                alt="example"
                style="width: 100%"
                :src="previewImage || hasimage"
            />
        </a-modal>
    </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { number, string } from "yargs";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
    });
}
async function reduce_image_file_size(
    base64Str,
    MAX_WIDTH = 450,
    MAX_HEIGHT = 450
) {
    let resized_base64 = await new Promise(resolve => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
            let canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL()); // this will return base64 image results after resize
        };
    });
    return resized_base64;
}
export default defineComponent({
    components: {
        PlusOutlined
    },
    props: {
        contactimageurl: string,
        sku: string,
        sizeupimage: number,
        initimage: Array
    },
    emits: ["fileList","deletefile"],
    setup(props, context) {
        const sizeupload = ref(props.sizeupimage);
        const hasimage = ref(props.contactimageurl);
        const hassku = ref(props.sku);

        const previewVisible = ref(false);
        const previewImage = ref("");
        const previewTitle = ref("");
        // var param = {
        //     url: hasimage
        // };

        const fileList = ref([]);

        if (props.initimage?.length > 0) {
            fileList.value = props.initimage;
        }

        const handleCancel = () => {
            previewVisible.value = false;
            previewTitle.value = "";
        };

        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            previewImage.value = file.url || file.preview;
            previewVisible.value = true;
            previewTitle.value =
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
        };
        const handleChange = async file => {
 
            if (file.file.originFileObj) {
                file.file.preview = await getBase64(file.file.originFileObj);
                if (isFileImage(file.file)) {
                    file.file.preview = await reduce_image_file_size(
                        file.file.preview,
                        1800,
                        1800
                    );
                }
            }
            if (file.file.preview) {
                if (hassku) {
                    file.file.sku = hassku.value;
                }

                context.emit("fileList", fileList.value);
            }
            if (file.file.status == "removed") {
                context.emit("deletefile", file);
            }
        };
        function isFileImage(file) {
            if (file["type"].split("/")[1] == "gif") return false;
            return file && file["type"].split("/")[0] === "image";
        }

        const handleremove = e => {
     
        };
        return {
            handleremove,
            previewVisible,
            previewImage,
            fileList,
            handleCancel,
            handlePreview,
            previewTitle,
            handleChange,
            sizeupload,
            hasimage
        };
    }
});
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #666 !important;
}
</style>
