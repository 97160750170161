<template>
    <!-- <pre>{{ dataSources }}</pre> -->

    <div class="p-3">

        <a-row>
            <a-col :span="12"><span class="p-3"> วันที่ (คิวยันต์สต๊อก)</span>
                <a-range-picker v-model:value="daterang" />
                <a-button class="ml-4 mr-4" @click="getinfo">ค้นหา</a-button>
                <a-button class="ml-2 mr-4" @click="getreport">Export Report</a-button>
                <a-button type="primary" v-show="showloading" loading>Loading</a-button></a-col>
            <a-col :span="12"> <a-input-group compact>
                    <a-form-item style="width: calc(100% - 60%)">
                        <a-input-search v-model:value="scanpickup" style="width: 400px;"
                            placeholder="สแกนรับสินค้าสต๊อก Barcode" enter-button="ยืนยัน" size="large" @search="onEnter" />
                    </a-form-item>
                </a-input-group></a-col>
        </a-row>



    </div>

    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table size="middle" v-if="dataSources && !rowselecteddetail.ishowdetail" :scroll="{ x: 0 }"
                :columns="columns" :data-source="dataSources" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        {{ index + 1 }}
                        <!-- <pre>{{ record }}</pre> -->
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <pre>{{ record.ProductSellOrder }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'contactname'">
                        <pre>{{ record.CustomerOrder }}{{ record.ContactName }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'IsUse'">
                        <div v-if="record.StockStatus == 'used'">
                            <a-typography-text type="danger">ใช้แล้ว</a-typography-text>
                        </div>
                        <div v-else>
                            <a-typography-text type="success">ว่าง</a-typography-text>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'UseDetail'">
                        <div style="font-size:12px" v-if="record.StockStatus == 'used'">
                            <h4><u>ข้อมูลผู้ใช้สินค้าสต๊อก</u></h4>
                            <div><u>รายการที่ใช้สต๊อก</u> :{{ record.UsedByProductSellOrder }}</div>
                            <div><u>รหัสลูกค้า</u> :{{ record.UsedByCustomerOrder }}</div>
                            <div><u>ลำดับงานที่ใช้</u>:{{ record.UsedByOrderTransaction }}</div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Productdetail'">
                        <font-awesome-icon icon="fa-solid fa-user" class="pr-2" />
                        {{
                            record.CustomerProductsType + record.CustomerProductsSize + record.HeartYanName }}
                        <br>
                        <!-- <br>
                        <ul>
                            <font-awesome-icon icon="fa-solid fa-address-card" class="pr-2" />ที่อยู่
                            <li v-show="record.ShipAddress.ProvinceName">จ.{{ record.ShipAddress.ProvinceName }}</li>
                            <li v-show="record.ShipAddress.BorderName">อ.{{ record.ShipAddress.BorderName }}</li>
                            <li v-show="record.ShipAddress.DistrictName">ต.{{ record.ShipAddress.DistrictName }}</li>
                            <li v-show="record.ShipAddress.Postcode">{{ record.ShipAddress.Postcode }}</li>
                        </ul> -->


                    </template>
                    <template v-if="column.dataIndex === 'QueYanDate'">
                        {{ formatdate(record.QueYanDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Stauts'">
                        <div v-if="record.StatusReport == '1'">
                            <a-typography-text type="success">รับสินค้า</a-typography-text>
                        </div>
                        <div v-else-if="record.ProductStatus == 'cancel'">
                            <a-typography-text type="danger">ยกเลิกสินค้าแล้ว</a-typography-text>
                            <div>
                                <a-typography-text type="danger">เหตุผล: {{ record.ProductStatusDesc }}</a-typography-text>
                            </div>
                        </div>
                        <div v-else>
                            <a-typography-text type="warning">ยังไม่ได้รับสินค้าสต๊อก</a-typography-text>
                        </div>
                    </template>

                </template>
                <!-- <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="padding: 8px">
                        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </template> -->
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 
import Utility from "@/helper/Utility";
import _ from "lodash";
import { Modal } from "ant-design-vue";
const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad
    },

    setup() {

        const scanpickup = ref("")
        const showloading = ref(false)
        const daterang = ref([])
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
            },
            // {
            //     title: "สร้างสต๊อกจากรายการ",
            //     dataIndex: "ProductSellOrder",
            // },
            {
                title: "ชื่อสินค้า",
                dataIndex: "Productdetail",
            },
            {
                title: "รหัสลูกค้า",
                dataIndex: "contactname",
            },
            {
                title: "วันที่ลงคิวเขียน",
                dataIndex: "QueYanDate",
            },
            {
                title: "สถานะการใช้",
                dataIndex: "IsUse",
            },
            {
                title: "ข้อมูลผู้ใช้สต๊อก",
                dataIndex: "UseDetail",
            },
            {
                title: "สถานะ",
                dataIndex: "Stauts",
            },

        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสินค้า",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = (date, TypeBirthday) => {
            if (moment(date).isValid()) {
                if (TypeBirthday == '2') {
                    return moment(date)
                        .add(543, "year")
                        .format("L");
                }
                else if (TypeBirthday == '3') {
                    return moment(date)
                        .format("L");
                }
                else {
                    if (moment(date).isValid()) {
                        return moment(date)
                            .add(543, "year")
                            .format("L");
                    }
                    else {
                        return "-"
                    }
                }
            }
            else {
                return "-"
            }


        };

        const querycustomer = () => {
            const { startDate, endDate } = getRangeForCurrentMonth();
            console.log("daterang : ", daterang.value)
            var startdate = setformat(daterang.value[0] || startDate)
            var enddate = setformat(daterang.value[1] || endDate)
            console.log("startdate : ", startdate)
            console.log("enddate : ", enddate)

            var typeshow = "showsearch"
            if (daterang.value[0] == undefined && daterang.value[1] == undefined) {
                typeshow = "showall"
            }
            return BzbsUser.getyanstock(startdate, enddate, typeshow)
                .then(res => {

                    dataSources.value = res.data
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const getRangeForCurrentMonth = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();

            // Get the first day of the current month
            const firstDayOfMonth = new Date(year, month, 1);

            // Get the last day of the current month
            const lastDayOfMonth = new Date(year, month + 1, 0);

            return {
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth,
            };
        }

        const setformat = (datevalue) => {

            const currentDate = new Date(datevalue);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because it's zero-based
            const day = String(currentDate.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: '',
            searchedColumn: '',
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };

        const getinfo = () => {

            querycustomer()
        }

        const getreport = () => {

            showloading.value = true
            querygetreport()
        }



        const querygetreport = () => {
            var listreport = []
            var totalprice = 0

            dataSources.value.forEach(element => {
                var item = {}
                var desc = ""
                var stock_detail = ""


                desc = element.StatusReport == "1" ? "สแกนรับสินค้าแล้ว" : "ยังไม่ได้สแกนรับสินค้า";
                stock_detail = `ผู้ใช้สต๊อก ${element.UsedByCustomerOrder}/${element.UsedByProductSellOrder}/ลำดับงานที่ใช้ ${element.UsedByOrderTransaction}`
                if (element.UsedByCustomerOrder == null) {
                    stock_detail = "ยังไม่ได้ใช้สต๊อก"
                }

                if (element.ProductStatus == "cancel") {
                    desc = "ยกเลิกสินค้า"
                }
                var listbd = []
                if (element.Birthday) {
                    JSON.parse(element.Birthday)?.map((item) => {
                        if (element.ProductKey == item.ProductKey)
                            listbd.push(formatdate(item.Birthday, item.TypeBirthday))

                    })
                }
                item.name = element.CustomerProductsType + " " + element.Category_2 + " " + element.Category_3,
                    item.birthday = listbd,
                    item.yancomingdate = formatdate(element.YanComingDate),
                    item.systemyandate = formatdate(element.SystemYanDate),
                    item.productorder = element.ProductOrder,
                    item.ordertransaction = element.OrderTransaction,
                    item.customerproductssize = element.Category_2,
                    item.productprice = element.ProductPrice,
                    item.originalprice = element.OriginalPrice,
                    item.productcode = element.ProductCode,
                    item.productsellorder = element.ProductSellOrder,
                    item.customerorder = element.CustomerOrder,
                    item.barcode = element.ProductCode,
                    item.contactname = element.ContactName || "",
                    item.statusreport = desc,
                    item.stockdetail = stock_detail

                listreport.push(item)
                totalprice = totalprice + element.OriginalPrice
            });

            // let orderInfo = {
            //     products: listreport,
            // };
            var exportreport = {
                orderInfo: listreport,
                totalprice: totalprice
            }

            console.log(exportreport)

            BzbsUser.apireportgetyanstock(exportreport)
                .then(response => {
                    showloading.value = false
                    let blob = new Blob([response.data], { type: "application/pdf" })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ยันต์มา.pdf'
                    link.click()

                })
                .catch(error => {
                    showloading.value = false
                    console.log(error);
                });
        }

        const onEnter = () => {

            showConfirm("ต้องการรับสินค้า")
        }

        const showConfirm = (title) => {
            Modal.confirm({
                title: title,
                onOk() {
                    apiupdatereportstatus()

                },
                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        };

        const apiupdatereportstatus = () => {
            var param = {
                "ProductCode": scanpickup.value,
                "StatusReport": "1"
            }

            BzbsUser.updatestatusreportstock(param)
                .then(response => {
                    success()
                    querycustomer()
                })
                .catch(error => {
                    showloading.value = false
                    console.log(error);
                });

        }
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };

        return {
            scanpickup,
            onEnter,
            getinfo,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            daterang,
            getreport,
            showloading,
            scanpickup
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
