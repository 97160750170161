<template>
    <div class="clearfix">
        <a-upload
            v-model:file-list="fileList"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            list-type="picture-card"
            @preview="handlePreview"
            @change="handleChange"
            @remove="handleRemove"
        >
            <div v-if="fileList.length < sizeupload">
                <plus-outlined />
                <div style="margin-top: 8px">Upload</div>
            </div>
        </a-upload>
        <a-modal
            :visible="previewVisible"
            :title="previewTitle"
            :footer="null"
            @cancel="handleCancel"
        >
            <img
                alt="example"
                style="width: 100%"
                :src="previewImage || hasimage"
            />
        </a-modal>
        <div v-if="false">
            <div v-if="ischeckeasyslip == false && !slipdetail.length == 0">
                กำลังตรสจสอบ...
            </div>
            <div v-else-if="slipdetail.length > 0" class="p-3">
                <div v-for="(Item, idx) of slipdetail" :key="`item-${idx}`">
                    <div v-if="Item?.ReacordEasySlipJSON">
                        <a-typography-text type="success" class="pr-3"
                            ><u>ตรวจสอบสำเร็จ</u></a-typography-text
                        >
                        <a-progress
                            v-if="
                                JSON.parse(Item?.ReacordEasySlipJSON)?.url
                                    ?.status
                            "
                            type="circle"
                            :percent="100"
                            :width="20"
                        />
                        <div>
                            <a-typography-paragraph
                                :copyable="{
                                    text: JSON.parse(Item?.ReacordEasySlipJSON)
                                        ?.url?.data?.transRef
                                }"
                            >
                                เลขอ้างอิง :
                                {{
                                    JSON.parse(Item?.ReacordEasySlipJSON)?.url
                                        ?.data?.transRef
                                }}
                            </a-typography-paragraph>
                        </div>
                        <div>
                            โอนวันที่ :
                            {{
                                formatdate(
                                    JSON.parse(Item?.ReacordEasySlipJSON)?.url
                                        ?.data?.date
                                )
                            }}
                        </div>
                        ยอดโอน :
                        {{
                            JSON.parse(
                                Item?.ReacordEasySlipJSON
                            )?.url?.data?.amount?.amount?.toLocaleString()
                        }}
                        บาท
                    </div>
                </div>
            </div>
        </div>
        <!-- <pre>sight : {{sight.numberref}}</pre>
        <pre>formStateStore : {{formStateStore.pay}}</pre> -->
    </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import { number, string } from "yargs";
import { onEdit } from "@/store/store";
import moment from "moment";
import { formStateStore } from "@/store/storebackoffice";
import "moment/locale/th"; // without this line it didn't work
// import Util from "@/helper/Utility";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { notification } from "ant-design-vue";
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
    });
}

export default defineComponent({
    components: {
        PlusOutlined
    },
    props: {
        contactimageurl: string,
        sizeupimage: number,
        sight:null
    },
    emits: ["fileList"],
    setup(props, context) {
        const slipdetail = ref([]);
        const isEmptyStr = str => {
            return str === null || str === undefined || str === "";
        };
        const jsonParse = jsonStr => {
            if (!isEmptyStr(jsonStr)) {
                try {
                    var data = JSON.parse(jsonStr);
                    return data;
                } catch (e) {
                    // if(jsonStr.length>1) return jsonStr;
                    // if(jsonStr.length==0) return null;
                    if (Array.isArray(jsonStr)) {
                        return null;
                    } else {
                        return [jsonStr];
                    }
                }
            }
            return null;
        };

        const sizeupload = ref(props.sizeupimage);
        const sight = ref(props.sight);
        const hasimage = ref(props.contactimageurl);

        var listimage = [];

        if (hasimage.value) {
            hasimage.value = jsonParse(hasimage.value);
            if (hasimage.value) {
                hasimage.value.forEach(element => {
                    listimage.push({ url: element });
                });
            }
        }

        const fileList = ref(listimage);

        const previewVisible = ref(false);
        const previewImage = ref("");
        const previewTitle = ref("");

        const handleCancel = () => {
            previewVisible.value = false;
            previewTitle.value = "";
        };
        console.log(onEdit);

        if (onEdit.value.type == "image" && onEdit.value.isshowedit) {
            var url = onEdit.value.editobj.detail.value;
            fileList.value.push({
                uid: "-1",
                name: "image.png",
                status: "success",
                url: url
            });
        }

        if (onEdit.value.type == "slideimage" && onEdit.value.isshowedit) {
            var listurl = onEdit.value.editobj.detail.value;
            listurl.forEach((element, index) => {
                fileList.value.push({
                    uid: index,
                    name: "image.png",
                    status: "success",
                    url: element.value
                });
            });
            console.log(fileList.value);
        }
        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            previewImage.value = file.url || file.preview;
            previewVisible.value = true;
            previewTitle.value =
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
        };

        const ischeckeasyslip = ref(false);
        const meassslip = ref("");
        const handleChange = async file => {
          
            if (file?.file?.status == "removed") {
                ischeckeasyslip.value = false;
                return;
            }
            console.log("check file file", file);

            if (file.file.originFileObj) {
                file.file.preview = await getBase64(file.file.originFileObj);
                file.file.status = "success";
                if (isFileImage(file.file)) {
                    file.file.preview = await reduce_image_file_size(
                        file.file.preview,
                        1800,
                        1800
                    );
                    file.file.status = "success";
                }

                //CHECK

         
                await easyslip(file.file.name, file.file.originFileObj);
            }

            // if (file.file.preview) {
    
            await context.emit("fileList", fileList.value);
            // }
        };

        const easyslip = async (filename, filestop) => {
            if (ischeckeasyslip.value) return;
            const formState = reactive(formStateStore);
            var sellordertransaction = formState.user.sellorderTransaction;
            var customercode = formState.user.customercode;

            ischeckeasyslip.value = true;
            if (filestop) {
                var uploadparam = {};
                var data = new FormData();
                data.append("file", filestop);
                data.append("filename", filename);
                data.append("folder", "easyslip");
                data.append("productsellorder", sellordertransaction);
                data.append("customerorder", customercode);
                await BzbsUser.apiuploadtypefile(data)
                    .then(async(res) => {

 

                        if(sight?.value && sight?.value?.numberref?.length > 0){
                            if(res?.url?.data?.transRef == undefined){
                                openNotificationWithIcon("info","สลิปไม่มี QR code",`จากรูปทำให้ตรวจสอบไม่ได้`);
                            }
                            else if(sight?.value?.numberref != res?.url?.data?.transRef){
                                openNotificationWithIcon("error","เลขอ้างอิงสลิปไม่ตรงกันรูป",`จากรูปเลข ${res?.url?.data?.transRef} ไม่ตรงกับ ที่กรอก ${sight?.value.numberref} `);
                            }
                        }
                        // fileList.value[0]['easyslip'] = res.data
                        if (res?.url?.status == 200) {
                            meassslip.value = "โปรดตรวจสอบสลิปนี้เองอีกที";
                            var param = {
                                ProductSellOrder: sellordertransaction,
                                CustomerOrder: customercode,
                                TransRef: res?.url?.data?.transRef
                            };
                           await  gethistoryeasyslip(param);
                      
                        } else if (res?.url == "quota_exceeded") {
                            openNotificationWithIcon("warning");
                        } else if (res.data == undefined) {
                            meassslip.value = "โปรดตรวจสอบสลิปนี้เองอีกที";  
                             openNotificationWithIcon("info","โปรดตรวจสอบสลิปนี้เอง",""); 
                        }

                        // stopcam()
                        // window.location.reload()
                        // console.log(res.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const gethistoryeasyslip = async param => {
            await BzbsUser.apigethistoryeasyslip(param)
                .then(res => {
            
                    slipdetail.value = res.data;
                    if (res.data.length > 0) {
                        openNotificationWithIcon(
                            "success",
                            JSON.parse(res.data[0].ReacordEasySlipJSON)
                        );
                        fileList.value[0]['slipdetail'] = slipdetail.value
                    }
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            } else {
                return "-";
            }
        };
        function isFileImage(file) {
            if (file["type"].split("/")[1] == "gif") return false;
            return file && file["type"].split("/")[0] === "image";
        }
        async function reduce_image_file_size(
            base64Str,
            MAX_WIDTH = 450,
            MAX_HEIGHT = 450
        ) {
            let resized_base64 = await new Promise(resolve => {
                let img = new Image();
                img.src = base64Str;
                img.onload = () => {
                    let canvas = document.createElement("canvas");
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL("image/jpeg", 0.8)); // this will return base64 image results after resize
                };
            });
            return resized_base64;
        }
        const openNotificationWithIcon = (type, message,description) => {
            if (type == "success") {
                const description_data = `
                    Transaction Reference: ${message?.url?.data?.transRef}
                    Date: ${formatdate(message?.url?.data?.date)}
                    Country Code: ${message?.url.data.countryCode}
                    Amount: ${message?.url.data.amount.amount.toLocaleString()}
                    Sender Bank: ${message.url.data.sender.bank.name}
                    Sender Name: ${message.url.data.sender.account.name.th}
                    Sender Account: ${message.url.data.sender.account.bank.account}
                    Receiver Bank: ${message.url.data.receiver.bank.name}
                    Receiver Name: ${message.url.data.receiver.account.name.th}
                    Receiver Account: ${message.url.data.receiver.account.bank.account}
                `;
                notification[type]({
                    message: "สลิปถูกต้อง",
                    description: `${description_data}`,
                    duration :0
                });
            } else if (type == "error") {
                notification["error"]({
                    message: message,
                    description: description,
                    duration :0
                });
            }
            else if (type == "info") {
                notification["info"]({
                    message: message,
                    description: description,
                    duration :0
                });
            } else if (type == "warning") {
                notification["warning"]({
                    message: "โปรดตรวจสอบสลิป",
                    description: `เนื่องจากไม่ได้ชำระค่าบริการ easyslip.com`,
                    duration :0
                });
            }
        };

        const handleRemove = e => {};
        return {
            previewVisible,
            previewImage,
            fileList,
            handleCancel,
            handlePreview,
            previewTitle,
            handleChange,
            handleRemove,
            sizeupload,
            hasimage,
            onEdit,
            slipdetail,
            formatdate,
            ischeckeasyslip,
            meassslip,
            formStateStore,
            sight
        };
    }
});
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #666 !important;
}
</style>
