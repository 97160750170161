<template>
    <div>
        <UpdateStatusPopup v-if="isshowpopcancel" :formState="formState" :iscancelall="'cancelall'"
            :cancelallboj="cancelallboj" :title="'ยกเลิกสินค้า'" :subtitle="'*เมื่อยกเลิกสินค้าแล้วจะไม่สามารถเรียกกลับได้'"
            @close="setshowpopup($event)">
        </UpdateStatusPopup>
        <i>* Download ใบเขียวด้านในรายการ</i>
        <a-table v-if="dataSource.length != 0" size="middle" :columns="columns" :data-source="dataSource" bordered
            :scroll="{ x: 0 }" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'key'">
                    <div>รายการที่ {{ index + 1 }}</div>
                </template>
                <template v-else-if="column.dataIndex === 'productorder'">
                    <div :class="{ 'disable': record.ProductStatus == 'cancel' }">


                        <!-- <a-typography-text @click="clickSO(record.ProductSellOrder)">
                            <span class="squiggle">{{ record.ProductSellOrder }}</span>
                        </a-typography-text> -->
                        <!-- {{ record.ProductSellOrder }} -->
                        <router-link :to="qr + '&so=' + record.ProductSellOrder">
                            <u @click="clickSO(record.ProductSellOrder, qr, '&so=' + record.ProductSellOrder)"> {{
                                record.ProductSellOrder }}</u>
                        </router-link>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'dateorder'">
                    <div>
                        {{ formatdate(record.OrderDate) }}
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'status'">

                    <a-popconfirm :class="{ 'disable': record.ProductStatus == 'cancel' }" title="คุณต้องการยกเลิกรายการ?"
                        @confirm="onCancelAll(record)">
                        <!-- <setting-outlined :style="{ fontSize: '18px', color: '#08c', padding: '10px' }" /> -->
                        <a-button :disabled="record.ProductStatus == 'cancel'" type="primary">{{ record.ProductStatus ==
                            'cancel' ? 'ยกเลิกแล้ว' : 'ยกเลิก' }}</a-button>
                    </a-popconfirm>



                </template>
                <template v-else-if="column.dataIndex === 'statusdata'">
                    <!-- <pre>{{record}}</pre> -->
                    <!-- {{record.ProfessorType}} -->
                    <div v-if="record.ProfessorType">
                        <!-- 1:{{JSON.parse(record.ProfessorType)}}
                         2:{{record.PaymentMethod}}
                         3:{{record.Address }} -->
                        <a-typography-text mark strong v-if="JSON.parse(record.ProfessorType)?.includes(
                            '2'
                        )
                            ">*รอคุยกับอาจารย์</a-typography-text>
                        <a-typography-text type="danger" strong v-else-if="record.ProductStatus == 'cancel'">
                            ยกเลิกรายการ</a-typography-text>
                        <a-typography-text mark type="success" strong v-else-if="JSON.parse(
                            record.ProfessorType
                        )?.includes('1')
                            ">อาจารย์เลือกให้</a-typography-text>
                        <a-typography-text type="danger" strong
                            v-else-if="!record.QueYanDate && checkIsYan(record.CustomerProductsType)">
                            ยังไม่ได้ลงระบบคิวยันต์</a-typography-text>
                        <a-typography-text type="success" strong
                            v-else-if="(record.Payment && Util.jsonParse(record.Payment)[0].Price) && JSON.parse(record.ProfessorType) && record.Address && record.ContactImageUrl">
                            ข้อมูลครบ</a-typography-text>
                        <a-typography-text type="danger" strong v-else>
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                            ข้อมูลไม่ครบ
                        </a-typography-text>
                    </div>
                    <!-- <div>
                        <a-typography-text type="danger" strong>ข้อมูลไม่ครบถ้วน</a-typography-text>
                    </div> -->
                </template>

                <template v-else-if="column.dataIndex === 'contactimage'">
                    <div v-if="record.contactimage">
                        <a-image :width="200" :src="record.contactimage[0].preview ||
                            record.contactimageurl
                            " />
                    </div>
                    <div v-else>
                        <a-avatar shape="square" :size="64">
                            <template #icon>
                                <UserOutlined />
                            </template>
                        </a-avatar>
                    </div>
                </template>

                <template v-else-if="column.dataIndex === 'introduction'">
                    <div>
                        {{ record.introduction }}
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'start'">
                    <div>
                        <span class="m-2">
                            <a-popconfirm title="ตั้งค่าที่อยู่เริ่มต้น?" @confirm="setstart(record, index)">
                                <setting-outlined :style="{ fontSize: '18px', color: '#08c', padding: '10px' }" />
                                <a>ตั้งค่าเริ่มต้น</a>
                            </a-popconfirm>
                        </span>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'startdefault'">
                    <div :style="{ margin: 'auto', 'text-align': 'center' }">
                        <!-- <check-circle-outlined v-if="record.contactstartdefault == 'true'"
                            :style="{ fontSize: '32px', color: 'green' }" />
                        <minus-circle-outlined v-if="
                            record.contactstartdefault == undefined ||
                                record.contactstartdefault == 'false'
                        " :style="{ fontSize: '32px', color: 'red' }" /> -->

                        <div class="text-center">
                            <font-awesome-icon v-if="record.contactstartdefault == 'true'" icon="fa-solid fa-check"
                                center />
                        </div>
                        <div class="text-center">
                            <font-awesome-icon v-if="record.contactstartdefault == undefined ||
                                record.contactstartdefault == 'false'
                                " icon="fa-solid fa-xmark" center />
                        </div>
                    </div>
                </template>
                <template v-else-if="column.dataIndex === 'operation'">
                    <div class="editable-row-operations">
                        <span v-if="editableData[record.key]">
                            <a-typography-link @click="save(record.key)">บันทึก</a-typography-link>
                            <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.key)">
                                <a>ยกเลิก</a>
                            </a-popconfirm>
                        </span>
                        <span v-else class="m-2">
                            <setting-outlined :style="{ fontSize: '18px', color: '#08c', padding: '10px' }" />
                            <a @click="edit(record.key)">แก้ไข</a>
                        </span>
                    </div>
                </template>
            </template>
        </a-table>
        <TableLoad v-else :isload="true"></TableLoad>
        <!-- //ตาราง -->
    </div>
</template>
<script>
import Storedata from "@/store/Storedata.js";
import {
    MinusCircleOutlined,
    CheckCircleOutlined,
    SettingOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
// import SelectOption from "../form/SelectOption.vue";
// import Address from "../../component/Address.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { UserOutlined } from "@ant-design/icons-vue";
import { ContactModel } from "../../../../../core/Create/model/CreateModel";
import moment from "moment";

import TableLoad from "@/components/Skeleton/TableLoad"
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility.js";
import { useRouter, useRoute } from "vue-router";
import UpdateStatusPopup from "./UpdateStatusPopup.vue" 

var columns = [
    {
        title: "ลำดับ",
        dataIndex: "key"
    },
    {
        title: "รายการขาย",
        dataIndex: "productorder"
    },
    {
        title: "วันที่สั่งสินค้า",
        dataIndex: "dateorder"
    },
    {
        title: "สถานะ",
        dataIndex: "status"
    },
];



const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        SettingOutlined,
        TableLoad,
        // SelectOption,
        // Address,
        UserOutlined,
        MinusCircleOutlined,
        CheckCircleOutlined,
        UpdateStatusPopup
    },
    props: {
        textbtn: null,
        formStates: null,
        rowselected: null
    },
    setup(props, { emit }) { 
        if (Storedata.methods.isnoaccount()){

            columns = columns.filter(column => column.dataIndex !== "status");
        }



        const cancelallboj = ref({})
        const isshowpopcancel = ref(false)
        var qr = ""
         
        if (window.location.search) {
            let url = new URL(location.href);
            url.searchParams.delete('so');
            qr = window.location.pathname + url.search
            
        }
        else {
            qr = window.location.pathname + '?'

        }
        const router = useRouter();
        const route = useRoute();
        const rowselecteddetail = reactive(props.rowselected);
        const visible = ref(false);
        const visibleEdit = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            contact: {
                sights: []
            },
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "TH"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    )
                ) {
                    formState.useraddress.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    );
                }
            }
        );
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            // if (visibleEdit.value) {
            //   formState.listproduct[editIndex.value] = formState.useraddress;
            // } else {
            //   let obj = {};
            //   obj = formState.useraddress;
            //   obj.key = list.listproduct.length == 0 ? 0 : list.listproduct.length;
            //   console.log("obj.key", obj.key);
            //   console.log("obj", obj);
            //   list.listproduct[obj.key] = obj;
            //   console.log("list.listproduct", list.listproduct);
            // }
            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function (element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {

            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };
        const dataSource = ref([]);
        // if (props.formStates.getcontactlist.length > 0) {
        //     dataSource.value = props.formStates.getcontactlist;
        // }
        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            Object.assign(
                dataSource.value.filter(item => key === item.key)[0],
                editableData[key]
            );
            delete editableData[key];
        };

        const cancel = key => {
            console.log(key);
        };
        const setstart = (record, key) => {
            if (props.formStates.getcontactlist.length > 0) {
                formState.contact.sights = props.formStates.getcontactlist;
            }
            let index_select = parseInt(key);
            formState.contact.sights.forEach((element, index) => {
                if (index == index_select) {
                    formState.contact.sights[index].contactstartdefault =
                        "true";
                } else {
                    formState.contact.sights[index].contactstartdefault =
                        "false";
                }
            });
            //  updatelist(record)
        };
        const updatelist = async record => {
            let params = [];
            // formState.contact.sights.forEach(element => {
            //     var obj = new ContactModel(record);
            //     console.log("obj", obj);
            //     params.push(obj);
            // });

            console.log("param", params);

            // for await (let obj of params) {
            await BzbsUser.apiupdatecontact(new ContactModel(record))
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        var count = 1;
        const handleclosemodal = e => {
            // eslint-disable-next-line vue/no-mutating-props
            props.formStates.getcontactlist = formState.contact.sights;
            dataSource.value = formState.contact.sights;
            visible.value = e;
            const product = {};
            product.key = count;
            product.inside = formState.productlist.inside;
            product.outside = formState.productlist.outside;
            product.userproduct = formState.productlist.userproduct;
            product.sellorderTransaction =
                formState.productlist.sellorderTransaction;
            product.orderdate = formState.productlist.orderdate;

            list.listproduct.push(product);
            count = count + 1;
            emit("update:value", formState.contact.sights);
        };

        onMounted(() => {
            getCustomerProductlist();
        });

        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const getCustomerProductlist = async () => {
            await BzbsUser.apiGetCustomerProductbyCustomercode(
                rowselecteddetail.rowselected,
                route.query.ot
            )
                .then(res => {
                    console.log("CustomerProductbyCustomercode", res.data[0]);
                    var arr1 = getUniqueListBy(res.data[0], "ProductSellOrder");
debugger

                    if(rowselecteddetail.so =='') rowselecteddetail.so=route.query.so
                    arr1 =  arr1.filter(item=>item.ProductSellOrder == rowselecteddetail.so)
        
                    return (dataSource.value = arr1);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const clickSO = (so, path, qr) => {
            emit("selectedsellorder", so);

            //  window.location = path + qr
        };
        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };


        const checkIsYan = (CustomerProductsType) => {
            return ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(CustomerProductsType)
        }
        const onCancelAll = (record) => {
            cancelallboj.value = {
                ProductSellOrder: record.ProductSellOrder,
                CustomerOrder: record.CustomerOrder
            }
            isshowpopcancel.value = true
        }
        const setshowpopup = (e) => {
            isshowpopcancel.value = e
            console.log(" isshowpopcancel.value ", isshowpopcancel.value)

        }
        return {
            cancelallboj,
            onCancelAll,
            checkIsYan,
            formatdate,
            clickSO,
            getCustomerProductlist,
            updatelist,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            setstart,
            rowselecteddetail,
            Util, router, route,
            qr,
            isshowpopcancel,
            setshowpopup,
            Storedata
        };
    }
});
</script> 
<style>
.disable {
    pointer-events: none !important;
}
</style>