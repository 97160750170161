<template>
    <div class="relative p-4 card">
        <!-- <pre>{{dataSources}}</pre> -->
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table v-if="dataSources && !rowselecteddetail.ishowdetail" :scroll="{ x: 0 }"
                :columns="columns" :data-source="dataSources" @change="handleTableChange" @expandFixed="true" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <!-- <template v-if="false" #expandedRowRender="{ record }"> -->

                <!-- <Drawer :sellorder="record.SellOrder" :shipping="JSON.parse(record.Productshipping)"
                        @updatedata="updatedata"></Drawer> -->
                <!-- <div class="mt-1">
                        <Previewslip :slip="JSON.parse(record.SlipUrl)"></Previewslip>
                    </div> -->
                <!-- </template> -->
                <template #bodyCell="{ column, record,index }">
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'OrderDate'">
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button type="dashed">
                                <a-typography-text strong v-if="Item.OrderDate">{{ formatdate(Item.OrderDate)
                                }}</a-typography-text>
                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </a-button>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'ProductSellOrder'">

                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button type="dashed" danger>
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul v-for="items in JSON.parse(
                                            Item.ProductDetail
                                        )" :key="items">
                                            <li>{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5" copyable>{{
                                    Item.ProductSellOrder
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <!-- <pre>{{record}}</pre> -->
                        <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <!-- {{
                                        record.find(
                                        item =>
                                        item.ContactStartDefault ==
                                        "true"
                                        )?.ContactName
                                        }} -->
                                        <a-image :width="200" :src="
                                            record.find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    'true'
                                            ).ContactImageUrl
                                        " />
                                    </template>
                                    <!-- <pre>{{record}}</pre> -->
                                    <div v-for="(Item, idx) of record" :key="`item-${idx}`"
                                        @click="rowselected(record)">
                                        {{Item.ContactName}}
                                        <!-- <div v-show="Item.ContactStartDefault=='true'">
                                            <a-typography-link underline>
                                                {{Item.ContactName}}
                                            </a-typography-link>
                                        </div> -->

                                    </div>
                                </a-tooltip>

                                <a-tag :color="
                                    record
                                        .find(
                                            item =>
                                                item.ContactStartDefault ==
                                                'true'
                                        )?.ContactType?.toUpperCase() ==
                                    'FACEBOOK'
                                        ? 'geekblue'
                                        : 'green'
                                ">
                                    {{
                                    record
                                    .find(
                                    item =>
                                    item.ContactStartDefault ==
                                    "true"
                                    )?.ContactType?.toUpperCase()
                                    }}
                                </a-tag>
                            </a-space>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template> -->
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span>
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined :style="{ color: 'red', fontSize: '14px' }" />
                            <span :style="{ color: 'red', fontSize: '14px' }">ยังไม่ได้จัดส่ง</span>
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Status'">
                        <div>
                            <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">

                                <div v-if="Item.DeliveryStatus == 'packed'">
                                    แพ็คสินค้าแล้ว
                                </div>
                                <div v-if="Item.DeliveryStatus == 'prepare_pack'">
                                    เตรียมแพ็คสินค้าแล้ว
                                </div>
                                <div v-else>
                                    <a-typography-text strong>รอเช็คเงิน</a-typography-text>
                                </div>

                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                                <div v-if="Item.ProfessorType">
                                    <a-typography-text mark strong v-if="
                                        JSON.parse(Item.ProfessorType)?.includes(
                                            '2'
                                        )
                                    ">*รอคุยกับอาจารย์</a-typography-text>

                                    <a-typography-text mark type="danger" strong v-if="
                                        JSON.parse(
                                            Item.ProfessorType
                                        )?.includes('1')
                                    ">อาจารย์เลือกให้</a-typography-text>
                                    <a-typography-text type="danger" strong v-else-if="!Item.QueYanDate">
                                        ยังไม่ได้ลงระบบคิวยันต์</a-typography-text>
                                    <a-typography-text type="success" strong
                                        v-else-if="(Item.Payment && Util.jsonParse(Item.Payment)[0].Price) && JSON.parse(Item.ProfessorType) && Item.Address && Item.ContactImageUrl">
                                        ข้อมูลครบ</a-typography-text>
                                    <a-typography-text type="danger" strong v-else>
                                        <font-awesome-icon icon="fa-solid fa-xmark" />
                                        ข้อมูลไม่ครบ
                                    </a-typography-text>
                                </div>

                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>

        </div>
        <FromCreateSell v-if="rowselecteddetail.ishowdetail" :rowselected="rowselecteddetail"></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";

import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility.js";
// import BarcodeGenerator from "./BarcodeGenerator.vue";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "5%"
    },
    {
        title: "ชื่อการติดต่อ",
        dataIndex: "CustomerOrder",
        sorter: true,
        width: "30%"
    },
    {
        title: "รายการขาย",
        dataIndex: "ProductSellOrder",
        sorter: true,
        width: "25%"
    },
    {
        title: "วันที่สั่งสินค้า",
        dataIndex: "OrderDate",
        sorter: true,
        width: "15%"
    },
    // {
    //     title: "สถานะ",
    //     dataIndex: "Status",
    //     width: "10%"
    // },
    {
        title: "สถานะข้อมูล(1.1)",
        dataIndex: "StatusData",
        width: "20%"
    },
    {
        title: "ผู้รับผิดชอบ",
        dataIndex: "Customertel",
        width: "13%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        // BarcodeGenerator,
        // Drawer,
        CloseOutlined,
        // CheckOutlined,
        // Previewslip,
        FromCreateSell
    },

    setup() {
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสั่งสินค้า(1.2)1",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apicustomerproduct(null)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    dataSources.value = groupBy(res.data[0], "CustomerOrder");
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });

             
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            // rowselecteddetail.rowselected = record[0].CustomerOrder;
            // rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = (arraySellOrder) => {
            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }

        return {
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            Util
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
