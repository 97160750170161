<template>
    <div>
        <a-modal v-model:visible="visible" title="โคลนเซลเพจ(ข้ามโดเมน)" @cancel="oncancel()" :maskClosable="false">

            <a-space :size="5">
                <a-select v-model:value="value1" :options="options1" style="width: 150px"
                    @change="handleChangeSubDomain">
                </a-select>
                <a-input v-show="value1 !='เลือก ซับโดเมน'" v-model:value="salepagename"
                    placeholder="ตั้งชื่อเซลเพจใหม่" />
            </a-space>
            <a-alert v-show="isfound?.value !=null" style="margin-top: 20px;" v-if="issame" message="ชื่อเซลเพจซ้ำ"
                type="error" />
            <div class="p-4" v-show="ishowmainurl">
                <a-steps progress-dot :current="current" direction="vertical">
                    <a-step title="เริ่มต้น">
                        <template #description>
                            <a-typography-text type="secondary"> Domain (www.{{
                            config_namahayant.salepageurl
                            }})
                            </a-typography-text>
                        </template>
                    </a-step>
                    <a-step title="แสดงเนื้อหาของเซลเพจ">
                        <template #description>
                            <a-typography-link
                                :href="'https://' + value1 + '.' + config_namahayant.salepageurl + '/' + value2"
                                target="_blank">
                                https://{{ value1 }}.{{ config_namahayant.salepageurl }}/{{ value2 }}
                            </a-typography-link>
                        </template>
                    </a-step>
                    <a-step title="ยืนยัน">
                        <template #description>
                            <a-typography-text type="secondary"> กดเลือก</a-typography-text>
                        </template>
                    </a-step>
                    <a-step title="กดเพื่อทดสอบเข้า (domain หลัก)">
                        <template #description>
                            <a-typography-link :href="'https://' + config_namahayant.salepageurl" @click="current = 3"
                                target="_blank">
                                https://{{ config_namahayant.salepageurl }}
                            </a-typography-link>
                        </template>
                    </a-step>
                </a-steps>
            </div>

            <div class="pt-3">
                <a-alert v-if="hassuccess" message="คั้งค่า สำเร็จ" type="success" />
                <a-alert v-if="haserror" message="เลือกข้อมูลไม่ถูกต้อง" type="error" />
            </div>
            <template #footer>
                <a-button v-if="!hassuccess" key="submit" type="primary" :loading="loading" @click="handleOk">
                    สร้าง</a-button>
                <a-button v-if="hassuccess" key="submit" type="primary" @click="oncancel()">ปิด</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { shopname } from '@/store/store'
import { config_namahayant } from '@/store/store'

const value1 = ref('เลือก ซับโดเมน');
const value2 = ref('เลือก เซลเพจ');
const options1 = ref([]);
const options2 = ref([]);
const ishowmainurl = ref(false);
export default defineComponent({
    props: ['type', 'subdomainname', 'salepageid', 'subdomainoriginal'],
    components: {
    },
    setup(props) {
        console.log("props.subdomainname: ", props.subdomainname)
        props.subdomainname.forEach(element => {
            options1.value.push({
                value: element.SubDomainName,
                label: element.SubDomainName,
            })
        });
        const current = ref(1);
        const visible = ref(true);
        const haserror = ref(false);
        const hassuccess = ref(false);
        const loading = ref(false);
        const showModal = () => {
            visible.value = true;
        };

        const subdomainname = ref(props.subdomainname);
        if (props.type == 'edit') {

            console.log("1");
            shopname.value = subdomainname.value
        }

        const handleOk = () => {

            window.location = '/salepageedit?cloneto=' + salepagename.value + '&id=' + props.salepageid + '&subdomainsalepage=' + value1.value + '&subdomain_original=' + props.subdomainoriginal + '&iscrossdomain=true'
            console.log(shopname.value);
            // querycustomer();
            // visible.value = false;
        };



        const oncancel = () => {
            window.location = '/subdomainlist'
        };


        const querycustomerSalepage = (subdomain) => {
        
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/salepagebysubdomain/" + subdomain).then(res => {
                // dataSources.value = res.data.filter(item => item.IsActive != 0);
            
                res.data.forEach(element => {
                    options2.value.push({
                        value: element.SalePageID,
                        label: element.SalePageID,
                    })
                });
                console.log(res.data);
            });
        };
        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
        watch(shopname, () => {
            hassuccess.value = false;
        })

        const salepagename = ref("");
        const isfound = ref({});
        const issame = ref(false);

        watch(salepagename, () => {
            const found = options2?.value.find(v => salepagename.value == v.value);
            isfound.value = found
            if (found != null) {
                issame.value = true;
                // setTimeout(() => {
                //     issame.value = false;
                // }, 1000);
            }
            else {
                issame.value = false;
            }
        })


        const handleChangeSubDomain = () => {
         
            hassuccess.value = false;
            current.value = 1;
            options2.value = [];
            value2.value = 'เลือก เซลเพจ';
            ishowmainurl.value = false
         
            querycustomerSalepage(value1.value)
        }

        const handleChangeSalepage = () => {
            hassuccess.value = false;
            current.value = 1;
            ishowmainurl.value = true;
        }

        return {
            isLetter,
            visible,
            showModal,
            handleOk, shopname, haserror, hassuccess,
            loading,
            oncancel, config_namahayant,
            options1, options2, value1, value2,
            querycustomerSalepage,
            handleChangeSubDomain,
            handleChangeSalepage,
            ishowmainurl,
            current,
            salepagename,
            isfound,
            issame

        };
    }
});
</script>
