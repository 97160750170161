<template>
  <a-button type="dash" block danger @click="showDrawer('large')"
    >ดูที่อยู่จัดส่ง</a-button
  >
  <a-drawer
    v-model:visible="visible"
    class="custom-class"
    style="color: red"
    title="ข้อมูลที่อยู่ (ระบบพร้อมโพส)"
    placement="right"
    @after-visible-change="afterVisibleChange"
    :size="size"
  >
    <div v-if="shipping == null" :style="{ fontSize: '32px' }">
      <a-empty :description="null" />

      <div>
        <DetailSent 
          :sellorder="sellorder"
          @updatedata="updatedata"
        ></DetailSent>
      </div>
    </div>
    <div v-if="shipping != null">
      <a-descriptions title="" layout="vertical">
        <a-descriptions-item label="หัวข้อ">
          <a-typography-text mark>{{ shipping.title }}</a-typography-text>
        </a-descriptions-item>
        <!-- <a-descriptions-item label="รายละเอียด"
                    >{{shipping.title}}</a-descriptions-item
                > -->
        <a-descriptions-item label="จัดส่ง">
          <a-typography-text mark>{{
            shipping.modifier == "private" ? "ในประเทศ" : "ต่างประเทศ"
          }}</a-typography-text>
        </a-descriptions-item>
        <a-descriptions-item label="ที่อยู่" :span="2">
          <a-typography-text mark>
            {{ shipping.description }}
            {{ shipping.address.province.provinceName }}
            {{ shipping.address.districts.districtsName }}
            {{ shipping.address.subdistricts.subdistrictsName }}
            {{ shipping.address.postcode }}
          </a-typography-text>
        </a-descriptions-item>

        <a-descriptions-item
          v-if="shipping.address.prompostcode != null"
          label="Tracking Prompost"
        >
          <a-typography-text mark copyable>{{
            shipping.address.prompostcode
          }}</a-typography-text>
        </a-descriptions-item>
      </a-descriptions>
      <div class="p-1">
        <DetailSent
          :tracking="shipping.address.prompostcode"
          :sellorder="sellorder"
          @updatedata="updatedata"
        ></DetailSent>
      </div>
      <div class="p-1">
        <PDFviewer
          v-if="shipping.address.prompostcode != null"
          :prompostcode="shipping.address.prompostcode"
        ></PDFviewer>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { createVNode, defineComponent, ref } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import DetailSent from "../component/DetailSent.vue";
import PDFviewer from "../component/PDFviewer.vue";

// import Previewslip from "../component/Previewslip.vue";
export default defineComponent({
  props: {
    shipping: {
      type: Object,
    },
    slip: {
      type: Object,
    },
    sellorder: {
      type: String,
    },
  },
  components: { DetailSent, PDFviewer },
  emits: ["updatedata"],
  setup(props, ctx) {
    const visible = ref(false);
    const size = ref("default");
    const afterVisibleChange = (bool) => {
      console.log("visible", bool);
    };

    const showDrawer = (val) => {
      size.value = val;
      visible.value = true;
    };
    const updatedata = () => {
      ctx.emit("updatedata", {
        updatedata: true,
      });
    };

    const showConfirm = () => {
      Modal.confirm({
        title: "คุณต้องการอัพเดทสถานะจัดส่งสินค้าเรียบร้อย?",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode("div", {
          style: "color:red;",
        }),

        onOk() {
          console.log("OK");
        },

        onCancel() {
          console.log("Cancel");
        },

        class: "test",
      });
    };
    return {
      visible,
      afterVisibleChange,
      showDrawer,
      size,
      showConfirm,
      updatedata,
    };
  },
});
</script>
