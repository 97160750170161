<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="รูป" @ok="handleOk" @cancel="close()">

      <UploadFile :sizeupimage="sizeupimage" @fileList="onchangeimages($event)"></UploadFile>
      <ul class="pt-3">
        <li>สกุลไฟล์ที่รองรับ: <span class="text-red-500">jpg, png, gif, jpeg</span></li>
        <li>ขนาดรูปที่เเนะนำ <span class="text-red-500">800 X 700</span></li>
        <li>ขนาดของไฟล์ที่อัพโหลดได้สูงสุดคือ <span class="text-red-500">10 MB</span></li>
      </ul>
      <ul class="pt-3" v-if="sizeupimage == 1">
        <li>ตัวอย่างลิ้ง : <span class="text-blue-500">http://www.example.com/index.html</span></li>
      </ul>
      <ul class="pt-3" v-else>
        <!-- {{slideconfig}} -->
        <li class="pt-3">สไลด์เล่นอัตโนมัติ :
          <a-switch v-model:checked="slideconfig.autoplay" />
        </li>
        <li class="pt-3">ทัชสไลด์ :
          <a-switch v-model:checked="slideconfig.touchdrag" />
        </li>
        <li class="pt-3" v-show="slideconfig.autoplay">สไลด์ (วินาที) :
          <a-row>
            <a-col :span="8">
              <a-slider v-model:value="slideconfig.timeplay" :min="0" :max="20" />
            </a-col>
            <a-col :span="8">
              <a-input-number v-model:value="slideconfig.timeplay" :min="0" :max="20"
                style="margin-left: 16px;text-align: center;" center />
              <span class="p-2">วินาที</span>
            </a-col>
          </a-row>
        </li>
      </ul>

      <a-form-item :has-feedback="urlStatus == 'success'" :validate-status="urlStatus">
        <a-input v-if="sizeupimage == 1" v-model:value="link" @change="onchangelink" addon-before="ลิ้งรูปภาพ" />
      </a-form-item>



      <template #footer>
        <a-button key="back" @click="close()">ยกเลิก</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">ตกลง</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import UploadFile from "@/module/Salepage/SalepageList/component/Uploadfile.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { onEdit, value } from '@/store/store'
// import { notification } from 'ant-design-vue';
export default defineComponent({
  components: { UploadFile },
  props: ['sizeupimage'],
  setup(props, { emit }) {
    const urlStatus = ref("")
    const visible = ref(false);
    const link = ref("");
    const slideconfig = ref({
      autoplay: false,
      touchdrag: false,
      timeplay: 0
    })
    const showModal = () => {
      visible.value = true;
    };
    const formState = reactive({
      user: {
        facebookpixel: "",
        images: [],
        slideimages: []

      }
    });
    if (onEdit.value.type == "image" && onEdit.value.isshowedit && props.sizeupimage == 1) {
      link.value = onEdit.value.editobj.detail.link
    }
    if (onEdit.value.type == "slideimage" && onEdit.value.isshowedit && props.sizeupimage > 1) {

      onEdit.value.editobj.detail.value.forEach(element => {
        if (element.slideconfig) {
          console.log(element.slideconfig)
          slideconfig.value = element.slideconfig
        }
      });

    }
    const close = () => {
      visible.value = false
      value.value = ["เลือก"]
      emit('onclose', null)
      onEdit.value.isshowedit = false
    }
    showModal()
    const loading = ref(false)
    const handleOk = async (e) => {


      if (onEdit.value.isshowedit && slideconfig) {
        if (onEdit.value.editobj?.detail?.value[0]?.slideconfig) {
          onEdit.value.editobj.detail.value[0].slideconfig = slideconfig.value
        }

      } 
      if (onEdit.value.isshowedit && slideconfig.value.autoplay == false) {
        slideconfig.value.timeplay = 0
        if (onEdit.value.editobj?.detail?.value[0]?.slideconfig) {
          onEdit.value.editobj.detail.value[0].slideconfig = slideconfig.value
        }
      }

      console.log(e)

      var uploadparam = {}
      loading.value = true
      if (formState.user.slideimages.length > 0) {

        var listslideimage = {
          value: [],
          type: "slideimage"

        }

        for await (let element of formState.user.slideimages) {
          console.log(element)
          if (element.preview != undefined) {

            uploadparam = {
              filename: Date.now() +
                ".webp",
              base64: element.preview,
              folder: "salepage"
            };

            await BzbsUser.apiuploadimage(uploadparam)
              .then(res => {
                listslideimage.value.push({ value: res.data.url, type: 'slideimage', slideconfig: slideconfig })
                if (listslideimage.value.length == formState.user.slideimages.length) {
                  emit('onimage', listslideimage)
                  onEdit.value.isshowedit = false
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          else if (element.status == 'success') {
            listslideimage.value.push({ value: element.url, type: 'slideimage', slideconfig: slideconfig })
            if (listslideimage.value.length == formState.user.slideimages.length) {
              emit('onimage', listslideimage)
            }
          }

        }


      }
      else if (formState.user.images[0]?.preview != undefined) {
        uploadparam = {
          filename: Date.now() +
            ".webp",
          base64: formState.user.images[0].preview,
          folder: "salepage"
        };
        await BzbsUser.apiuploadimage(uploadparam)
          .then(res => {
            loading.value = false
            emit('onimage', { value: res.data.url, type: 'image', link: link.value })
            onEdit.value.isshowedit = false
          })
          .catch(error => {
            console.log(error);
          });
      }
      else {
        visible.value = false;
        value.value = ["เลือก"]

        onEdit.value.isshowedit = false
      }

    };
    const onchangeimages = e => {
      if (props.sizeupimage > 1) {
        formState.user.slideimages = e
        console.log(formState.user.slideimages);
      }
      else if (props.sizeupimage == 1) {

        e[0].link = link.value
        formState.user.images = e;
      }
    };
    const onchangelink = () => {

      if (props.sizeupimage == 1) {
        if (validateUrl(link.value)) {
          urlStatus.value = "success"
          onEdit.value.editobj.detail.link = link.value
        }
        else {
          console.log("url wrong")
          if (link.value != '') {
            urlStatus.value = "error"
          }
          else {
            urlStatus.value = ""
          }

          // notification['warning']({
          //   message: 'url ไม่ถูกต้อง',
          //   description: 'กรุณากรอก url ให้ถูกต้อง',
          // });
        }
      }
    };
    const validateUrl = (string) => {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    }
    return {
      loading,
      visible,
      showModal,
      handleOk,
      onchangeimages, formState,
      close,
      link, onchangelink, urlStatus,
      slideconfig,
      BzbsUser
    };
  },

});
</script>

 