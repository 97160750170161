<template>
    <div class="page max-w-full overflow-x-hidden w-full h-full flex flex-col card">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar @clickexport="modalController.exportModal = true"></Toolbar>
            </template>
        </TopBar>
        <Step></Step>

        <SelectType></SelectType>

        <Footer></Footer>
    </div>
</template>
 

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import SelectType from "../component/SelectType.vue";
import Step from "../component/Step.vue";
import { formStateStore } from "@/store/storebackoffice";



import { computed, defineComponent, onMounted, ref, reactive } from "vue";
export default defineComponent({
    components: { TopBar, Footer, SelectType, Step },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "สร้างข้อมูลลูกค้า"
                }
            ];
        }
    },
    setup() {

        const formState = reactive(formStateStore)
        formState.masterproduct.status = 0
        const setproduct = (set) => {
            formState.masterproduct.mastertype = set
            if (set = 'insert_product') { 
            }


        }

        return {
            formState,
            setproduct,
        };
    }
});
</script>

<style lang="scss">
.p-select {
    padding-top: 200px;
    display: -ms-inline-grid;
    justify-content: center;
    min-height: 100vh;

    .card {
        text-align: center;
    }
}
</style>
