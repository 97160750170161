<template>
    <!-- <pre>{{ dataSources }}</pre> -->

    <div class="p-3">
        <a-row>
            <a-col :span="12">
                <span class="p-3"> วันที่</span>
                <a-range-picker v-model:value="daterang" />
                <a-button class="ml-4 mr-4" @click="getinfo">ค้นหา</a-button>
                <a-button class="ml-2 mr-4" @click="getreport"
                    >Export Excel</a-button
                >
                <a-button type="primary" v-show="showloading" loading
                    >Loading</a-button
                ></a-col
            >
        </a-row>
    </div>

    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-table
                size="middle"
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                :scroll="{ x: 0 }"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template #headerCell="{ column }">
                    <template v-if="column.key === 'CustomerOrder'">
                        <span>รหัสลูกค้า</span>
                    </template>
                </template>
                <template
                    #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                >
                    <div style="padding: 8px">
                        <a-input
                            ref="searchInput"
                            :placeholder="`Search ${column.dataIndex}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="
                                e =>
                                    setSelectedKeys(
                                        e.target.value ? [e.target.value] : []
                                    )
                            "
                            @pressEnter="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        />
                        <a-button
                            type="primary"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        >
                            <template #icon><SearchOutlined /></template>
                            Search
                        </a-button>
                        <a-button
                            size="small"
                            style="width: 90px"
                            @click="handleReset(clearFilters)"
                        >
                            Reset
                        </a-button>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'contact'">
                       <div>{{ record.Name }} {{ record.Lastname }}</div>
                    </template>
                    <template v-if="column.dataIndex === 'Address'">
                        <div>{{ record.Address }}</div>
                        <div>{{ record.DistrictName }}</div>
                        <div>{{ record.BorderName }}</div>
                        <div>{{ record.ProvinceName }}</div>
                        <div>{{ record.PostCode }}</div>
                    </template>
                    <template v-if="column.dataIndex === 'Phone'">
                        <div>{{ record.Phone || "" }}</div>
                    </template>
                    <template v-if="column.dataIndex === 'PayType'">
                        <div>
                            {{
                                record.PayType == "1"
                                    ? "บริษัทจำกัด"
                                    : "บุคคลธรรมดา"
                            }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'UpdateDate'">
                        <div v-if="record.UpdateDate">
                            {{ formatdate(record.UpdateDate) }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Checking'">
                        <div class="text-center">
                            <a-typography-text type="success">{{
                                record.Checking == "checked" ? "อนุมัติ" : ""
                            }}</a-typography-text>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'Productdetail'">
                        <font-awesome-icon
                            icon="fa-solid fa-user"
                            class="pr-2"
                        />
                        {{
                            record.CustomerProductsType +
                                record.CustomerProductsSize +
                                record.HeartYanName
                        }}
                        <br />
                        <!-- <br>
                        <ul>
                            <font-awesome-icon icon="fa-solid fa-address-card" class="pr-2" />ที่อยู่
                            <li v-show="record.ShipAddress.ProvinceName">จ.{{ record.ShipAddress.ProvinceName }}</li>
                            <li v-show="record.ShipAddress.BorderName">อ.{{ record.ShipAddress.BorderName }}</li>
                            <li v-show="record.ShipAddress.DistrictName">ต.{{ record.ShipAddress.DistrictName }}</li>
                            <li v-show="record.ShipAddress.Postcode">{{ record.ShipAddress.Postcode }}</li>
                        </ul> -->
                    </template>
                    <template v-if="column.dataIndex === 'BankName'">
                        <div v-if="record.BankName.split('|').length > 0">
                            <div
                                v-for="(item, index) in record.BankName.split(
                                    '|'
                                )"
                                :key="index"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import { usePagination } from "vue-request";
import { SearchOutlined } from "@ant-design/icons-vue";
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import Utility from "@/helper/Utility";
import _ from "lodash";
const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        SearchOutlined
    },

    setup() {
        const scanpickup = ref("");
        const showloading = ref(false);
        const daterang = ref([]);
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "รหัสลูกค้า",
                dataIndex: "CustomerOrder",
                key: "CustomerOrder",
                width: "10%",
                customFilterDropdown: true,
                onFilter: (value, record) =>
                    record.CustomerOrder.toString()
                        .toLowerCase()
                        .includes(value.toLowerCase()),
                onFilterDropdownOpenChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                            searchInput.value.focus();
                        }, 100);
                    }
                }
            },
            {
                title: "บัญชีธนาคาร",
                dataIndex: "PayType",
                filters: [
                    {
                        text: "บริษัทจำกัด",
                        value: "1"
                    },
                    {
                        text: "บุคคลธรรมดา",
                        value: "2"
                    }
                ],
                onFilter: (value, record) => record.PayType.startsWith(value),
                filterSearch: (input, filter) =>
                    filter.value.indexOf(input) > -1,
                width: "10%"
            },
            {
                title: "ชื่อ",
                dataIndex: "contact"
            },
            {
                title: "ที่อยู่จัดส่ง",
                dataIndex: "Address",
                width: "15%"
            },
            {
                title: "เบอร์",
                dataIndex: "Phone"
            },
            {
                title: "วันที่อัพเดท",
                dataIndex: "UpdateDate"
            },
            {
                title: "บัญชีธนาคาร",
                dataIndex: "BankName",
                filters: [
                    {
                        text:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคารไทยพาณิชย์ (SCB)|(171-427644-4)",
                        value:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคารไทยพาณิชย์ (SCB)|(171-427644-4)"
                    },
                    {
                        text:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคารกสิกรไทย (Kbank)|(054-2-86856-2)",
                        value:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคารกสิกรไทย (Kbank)|(054-2-86856-2)"
                    },
                    {
                        text:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคาร(SCB)พิธีแผ่นดวง|(468-118-657-4)",
                        value:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคาร(SCB)พิธีแผ่นดวง|(468-118-657-4)"
                    },
                    {
                        text:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคาร(SCB)กระแสรายวัน|(468-118-657-4)",
                        value:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|ธนาคาร(SCB)กระแสรายวัน|(468-118-657-4)"
                    },
                    {
                        text:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|(SCB)ธนาคารไทยพาณิชย์|(171-427644-4)",
                        value:
                            "บริษัท นะมะหายัน กรุ๊ป จำกัด|(SCB)ธนาคารไทยพาณิชย์|(171-427644-4)"
                    },
                    {
                        text:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารไทยพาณิชย์ (SCB)|(171-419225-0)",
                        value:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารไทยพาณิชย์ (SCB)|(171-419225-0)"
                    },
                    {
                        text:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกสิกรไทย (Kbank)|(103-1-34585-1)",
                        value:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกสิกรไทย (Kbank)|(103-1-34585-1)"
                    },
                    {
                        text:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกรุงไทย (KTB)|(488-3-01403-7)",
                        value:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกรุงไทย (KTB)|(488-3-01403-7)"
                    },
                    {
                        text:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกรุงเทพ (BBL)|(093-7-31775-8)",
                        value:
                            "นาย กัมปนาท  มาลาขาว|ธนาคารกรุงเทพ (BBL)|(093-7-31775-8)"
                    },
                    {
                        text:
                            "น.ส.นภัสนันท์ พิชยะพัฒนกุล|(BBL)ธนาคารกรุงเทพ|(865-406-7878)",
                        value:
                            "น.ส.นภัสนันท์ พิชยะพัฒนกุล|(BBL)ธนาคารกรุงเทพ|(865-406-7878)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารออมสิน (GSB)|(204-0-927915-3)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารออมสิน (GSB)|(204-0-927915-3)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงศรีอยุธยา (Bay)|(788-1-14773-3)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงศรีอยุธยา (Bay)|(788-1-14773-3)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงไทย (KTB)|(488-3-18782-9)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงไทย (KTB)|(488-3-18782-9)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงเทพ (BBL)|(865-4-02110-7)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|ธนาคารกรุงเทพ (BBL)|(865-4-02110-7)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|(GSB)ธนาคารออมสิน|(204-0-927915-3)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|(GSB)ธนาคารออมสิน|(204-0-927915-3)"
                    },
                    {
                        text:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|(BBL)ธนาคารกรุงเทพ|(865-4-02110-7)",
                        value:
                            "น.ส.ทิพย์รัตน์ โชติพันธ์|(BBL)ธนาคารกรุงเทพ|(865-4-02110-7)"
                    },
                    {
                        text:
                            "น.ส. เอื้อมพร คิดชอบ|ธนาคารกรุงไทย (KTB)|(488-0-82789-4)",
                        value:
                            "น.ส. เอื้อมพร คิดชอบ|ธนาคารกรุงไทย (KTB)|(488-0-82789-4)"
                    },
                    {
                        text:
                            "น.ส. เอื้อมพร คิดชอบ|ธนาคารกรุงเทพ (BBL)|(865-0-82807-5)",
                        value:
                            "น.ส. เอื้อมพร คิดชอบ|ธนาคารกรุงเทพ (BBL)|(865-0-82807-5)"
                    },
                    {
                        text:
                            "น.ส. พชรมน ธนภรณ์ทิชากร|ธนาคารกรุงเทพ (BBL)|(865-0-85231-5)",
                        value:
                            "น.ส. พชรมน ธนภรณ์ทิชากร|ธนาคารกรุงเทพ (BBL)|(865-0-85231-5)"
                    },
                    {
                        text: "ธนาคารไทยพาณิชย์(SCB)",
                        value: "ธนาคารไทยพาณิชย์(SCB)"
                    },
                    {
                        text: "ธนาคารซิตี้แบงก์(CITI)",
                        value: "ธนาคารซิตี้แบงก์(CITI)"
                    },
                    {
                        text: "(SCB)ธนาคารไทยพาณิชย์",
                        value: "(SCB)ธนาคารไทยพาณิชย์"
                    }
                ],
                onFilter: (value, record) => record.BankName.startsWith(value),
                filterSearch: (input, filter) =>
                    filter.value.indexOf(input) > -1,
                width: "15%"
            },
            {
                title: "สถานะการเงิน",
                dataIndex: "Checking",
                width: "10%"
            }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสินค้า",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            } else {
                return "-";
            }
        };

        const list_bb = ref([]);
        const getlistbank = () => {
            return BzbsUser.getlistaccount()
                .then(res => {
                    res.data.map(item => {
                        list_bb.value.push({
                            text: item.BankName,
                            value: item.BankName
                        });
                    });

                    console.log("list_bb", list_bb);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        // getlistbank();
        const querycustomer = () => {
            data_export_excel.value  = []
            console.log("daterang : ", daterang.value);
            var startdate = setformat(daterang.value[0] || new Date());
            var enddate = setformat(daterang.value[1] || new Date());
            console.log("startdate : ", startdate);
            console.log("enddate : ", enddate);
            return BzbsUser.getcontactpeak(startdate, enddate)
                .then(res => {
                    dataSources.value = res.data;
                    data_export_excel.value = dataSources.value 
                    var list_bank = groupBy(res.data, "BankName");
                    console.log("list_bank", list_bank);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const setformat = datevalue => {
            const currentDate = new Date(datevalue);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because it's zero-based
            const day = String(currentDate.getDate()).padStart(2, "0");

            return `${year}-${month}-${day}`;
        };

        const data_export_excel = ref([]);
        const handleTableChange = (pag, filters, sorter) => {
       

            const result = dataSources.value.filter(items => {
  
                if (checkCriteria(items, filters)) return items;
            });
            data_export_excel.value =[]
            data_export_excel.value = result;

            console.log("result :", result);

            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };

        const checkCriteria = (obj, filterCriteria) => {
            // เช็คเงื่อนไข CustomerOrder
            if (
                !filterCriteria.CustomerOrder ||
                !filterCriteria.CustomerOrder.includes(obj.CustomerOrder)
            ) {
                return false;
            }

            // เช็คเงื่อนไข PayType
            if (
                filterCriteria.PayType !== null &&
                obj.PayType !== filterCriteria.PayType
            ) {
                return false;
            }

            // เช็คเงื่อนไข BankName
            if (
                filterCriteria.BankName !== null &&
                obj.BankName !== filterCriteria.BankName
            ) {
                return false;
            }

            // ถ้าผ่านทุกเงื่อนไข คืนค่า true
            return true;
        };

        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: "",
            searchedColumn: ""
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };

        const getinfo = () => {
            querycustomer();
        };

        const getreport = () => {
            showloading.value = true;
            querygetreport();
        };

        const querygetreport = () => {
            var param = {
                recordset: data_export_excel.value
            };
 
            BzbsUser.getexcelcontact_obj(param)
                .then(response => {
                    showloading.value = false;
                    window.open(
                        "https://namahayan.blob.core.windows.net/filecreate/output.xlsx"
                    );
                })
                .catch(error => {
                    showloading.value = false;
                    console.log(error);
                });
        };

        const onEnter = () => {
            showConfirm("ต้องการรับสินค้า");
        };

        const showConfirm = title => {
            Modal.confirm({
                title: title,
                onOk() {
                    apiupdatereportstatus();
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test"
            });
        };

        const apiupdatereportstatus = () => {
            var param = {
                ProductCode: "YAN040ME03H123456",
                StatusReport: "1"
            };

            BzbsUser.updatestatusreport(param)
                .then(response => {
                    success();
                })
                .catch(error => {
                    showloading.value = false;
                    console.log(error);
                });
        };
        const success = () => {
            Modal.success({
                title: "รับสำเร็จ",
                onOk() {}
            });
        };

        return {
            scanpickup,
            onEnter,
            getinfo,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            daterang,
            getreport,
            showloading
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
