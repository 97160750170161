export const units = ["px", "em", "rem"]

//#region definition
/**
 * Extract box unit from string
 * @typedef {Object} BoxUnit
 * @property {*} source - prop value
 * @property {number} value - number of unit
 * @property {string} unit - unit such as px, em, rem
 */
//#endregion

/**
 * using only when need to have unit
 * @param {string} prop
 * @returns {BoxUnit}
 */
export const extractBoxUnit = function (prop) {
    let exp = /(-?[\d.]+)([a-z%]*)/
    let unit = prop.match(exp)
    return {
        source: unit[0],
        value: parseFloat(unit[1]),
        unit: unit[2] || null
    }
}

export const getBoxUnit = function (prop, half = false) {
    if (prop == undefined) {
        return null
    } else {
        if (typeof prop == "string") {
            if (prop.indexOf("%") != -1) {
                return prop
            } else {

                let findHasUnit = units.filter(u => {
                    if (prop.indexOf(u) != -1) return true
                    else return false
                })

                if (findHasUnit != null) {
                    let exp = /(-?[\d.]+)([a-z%]*)/
                    let unit = prop.match(exp)
                    if (half) {
                        return `${parseFloat(unit[1]) / 2}${unit[2]}`
                    } else {
                        return prop
                    }
                }
            }
        }

        // assume as pixel
        if (half) {
            return `${parseFloat(prop) / 2}px`
        } else {
            return `${prop}px`
        }
    }
}

export const getStringProp = function (prop) {
    if (prop == undefined) {
        return null
    } else {
        if (typeof prop == "string") {
            return prop
        } else {
            console.error("prop value expected as string")
            return null
        }
    }
}

export default { getBoxUnit, getStringProp, extractBoxUnit }
