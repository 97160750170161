<template>
    <div class="relative p-4" v-if="!isshowDetailSellOrder.isshow">
        <template v-if="title != null">
            <div class="m-4 text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div>
            <a-button type="link" @click="clickblack()">
                <left-outlined />ย้อนกลับ(1.1)
            </a-button>
        </div>

        <!-- <div>{{ formState.user.ProvinceName }}</div>
        <div>{{ formState.user.ProvinceID }}</div>
        <div>{{ formState.user.BorderName }}</div>
        <div>{{ formState.user.BorderID }}</div>
        <div>{{ formState.user.DistrictID }}</div>
        <div>{{ formState.user.DistrictName }}</div> -->
        <a-form
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >
            <!-- ================================================ข้อมูลลูกค้า -->
            <div v-if="Storedata.methods.checkgrantCustomerProfile()">
                <a-divider orientation="left">ข้อมูลลูกค้า</a-divider>
                <div class="p-5 mb-5 card">
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <user-outlined />
                            <a-form-item
                                :name="['user', 'customercode']"
                                label="รหัสลูกค้า"
                                :rules="[
                                    { required: true, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input
                                    style="background: #80808038"
                                    v-model:value="formState.user.customercode"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col
                            flex="1 1 500px"
                            :order="2"
                            class="p-2 wrapper_createnew"
                        >
                            <create-customerfrom
                                v-if="false"
                                @customercode="gencodecustomer($event)"
                            >
                            </create-customerfrom>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <!-- formState.user.contactList :: -->

                        <!-- <pre>{{ formState.user.contactList }}</pre> -->
                        <a-col flex="1" :order="1">
                            <Contact
                                :key="formState.updatecomponent"
                                :textbtn="'แก้ไข-ช่องทางการติดต่อ'"
                                :formStates="formState"
                                v-model:value="formState.user.contactList"
                            ></Contact>
                        </a-col>
                    </a-row>
                    <div class="p-4">
                        <a-switch v-model:checked="isshowcustomerdesc" />
                        รายละเอียดลูกค้า
                    </div>
                    <!-- formState.user.contactlist:{{ formState.user.getcontactlist }} -->
                    <div
                        v-if="
                            isshowcustomerdesc &&
                                Storedata.methods.checkGrantCustomerDesc()
                        "
                    >
                        <a-divider orientation="left"
                            >รายละเอียด-ลูกค้า</a-divider
                        >
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customername']"
                                    label="ชื่อ"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customername
                                        "
                                        placeholder="ชื่อ"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2">
                                <a-form-item
                                    :name="['user', 'customerlastname']"
                                    label="นามสกุล"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customerlastname
                                        "
                                        placeholder="นามสกุล"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customernickname']"
                                    label="ชื่อเล่น"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customernickname
                                        "
                                        placeholder="ชื่อเล่น"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2">
                                <a-form-item
                                    :name="['user', 'customergender']"
                                    label="เพศ*"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-radio-group
                                        v-model:value="
                                            formState.user.customergender
                                        "
                                        name="radioGroup"
                                    >
                                        <a-radio value="man">ชาย</a-radio>
                                        <a-radio value="female">หญิง</a-radio>
                                        <a-radio value="notspecified"
                                            >ไม่ระบุ</a-radio
                                        >
                                    </a-radio-group>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customerbirthday']"
                                    label="วันเกิด"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-radio-group
                                        v-model:value="
                                            formState.user.customerbirthdaytype
                                        "
                                        name="radioGroup"
                                        @change="
                                            calculateAge(
                                                formState.user.customerbirthday
                                            )
                                        "
                                    >
                                        <a-radio value="1">ยังไม่แจ้ง</a-radio>
                                        <a-radio value="2">พ.ศ.</a-radio>
                                        <a-radio value="3">ค.ศ</a-radio>
                                    </a-radio-group>

                                    <a-date-picker
                                        v-if="
                                            formState.user
                                                .customerbirthdaytype == 3
                                        "
                                        @change="
                                            calculateAge(
                                                formState.user.customerbirthday
                                            )
                                        "
                                        v-model:value="
                                            formState.user.customerbirthday
                                        "
                                        value-format="YYYY-MM-DD"
                                    />
                                    <DatePickerDropdown
                                        v-if="
                                            formState.user
                                                .customerbirthdaytype == 2
                                        "
                                        v-model:inputValue="
                                            formState.user.customerbirthday
                                        "
                                        :errorInfo="errorInfo[6]"
                                        :isFirstSave="true"
                                        :isShowErrorLbl="true"
                                        :inputLbl="lbl.profile.birthdate"
                                        :isISO_8601="true"
                                        @onDateChange="
                                            calculateAge(
                                                formState.user.customerbirthday
                                            )
                                        "
                                    >
                                    </DatePickerDropdown>
                                </a-form-item>
                            </a-col>
                            <a-col
                                v-if="formState.user.customerbirthdaytype != 1"
                                flex="1 1 500px"
                                :order="2"
                            >
                                <a-form-item
                                    class=""
                                    :name="['user', 'sellorderTransaction']"
                                    label="อายุ"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-input-number
                                        id="inputNumber"
                                        v-model:value="
                                            formState.user.customerage
                                        "
                                        :min="0"
                                        :max="100"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    ref=""
                                    :name="['user', 'customercareer']"
                                    label="อาชีพ"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <a-textarea
                                        v-model:value="
                                            formState.user.customercareer
                                        "
                                        show-count
                                        placeholder="กรอกอาชีพ"
                                        :maxlength="3000"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2"></a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customerlineid']"
                                    label="LINE ID"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customerlineid
                                        "
                                        placeholder="LINE ID"
                                    >
                                        <template #prefix>
                                            <img
                                                class="w-5"
                                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                            />
                                        </template>
                                    </a-input>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2">
                                <a-form-item
                                    :name="['user', 'customerfacebook']"
                                    label="FACEBOOK"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customerfacebook
                                        "
                                        placeholder="FACEBOOK"
                                    >
                                        <template #prefix>
                                            <img
                                                class="w-5"
                                                src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg"
                                            />
                                        </template>
                                    </a-input>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"></a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customerfacebook']"
                                    label="เลขที่อยู่"
                                >
                                    <a-textarea
                                        v-model:value="
                                            formState.user.addressdesc
                                        "
                                        show-count
                                        placeholder="ที่อยู่"
                                        :maxlength="3000"
                                    />
                                </a-form-item>
                                <a-form-item
                                    class=""
                                    :name="['user', 'customercountry']"
                                    label="ประเทศ"
                                >
                                    <!-- formState.user::
                                    <pre> {{formState.user}}</pre>
                                    isfinistini : {{isfinistinit}} -->
                                    <a-radio-group
                                        v-model:value="
                                            formState.user.customeraddressType
                                        "
                                        name="radioGroup"
                                    >
                                        <a-radio value="1">ไทย</a-radio>
                                        <a-radio value="2">ต่างประเทศ</a-radio>
                                    </a-radio-group>
                                    <div class="pt-2">
                                        <Address
                                            v-if="
                                                isfinistinit &&
                                                    formState.user
                                                        .customeraddressType ==
                                                        '1'
                                            "
                                            :key="update"
                                            :setprovince="
                                                formState.user.ProvinceName
                                            "
                                            :setdistricts="
                                                formState.user.BorderName
                                            "
                                            :setsubdistricts="
                                                formState.user.DistrictName
                                            "
                                            :setpostcode="
                                                formState.user.PostCode
                                            "
                                            @province="onprovince($event)"
                                            @districts="ondistricts($event)"
                                            @subdistricts="
                                                onsubdistricts($event)
                                            "
                                            @postcode="onpostcode($event)"
                                        ></Address>

                                        <SelectOption
                                            :formStates="formState"
                                            v-if="
                                                formState.user
                                                    .customeraddressType == '2'
                                            "
                                            v-model:value="
                                                formState.user.selectcountry
                                            "
                                            :isshowinput="true"
                                        ></SelectOption>
                                    </div>

                                    <div
                                        v-show="
                                            formState.user
                                                .customeraddressType == '2'
                                        "
                                    >
                                        <a-form-item
                                            :name="['user', 'customerfacebook']"
                                            label="เมือง (ต่างประเทศ)"
                                        >
                                            <a-textarea
                                                v-model:value="
                                                    formState.user.citypostinter
                                                "
                                                show-count
                                                placeholder="เมือง"
                                                :maxlength="100"
                                            />
                                        </a-form-item>
                                        <a-form-item
                                            :name="['user', 'customerfacebook']"
                                            label="จังหวัด (ต่างประเทศ)"
                                        >
                                            <a-textarea
                                                v-model:value="
                                                    formState.user
                                                        .provincepostinter
                                                "
                                                show-count
                                                placeholder="จังหวัด"
                                                :maxlength="100"
                                            />
                                        </a-form-item>
                                        <a-form-item
                                            :name="['user', 'customerfacebook']"
                                            label="รหัสไปรษณีย์ (ต่างประเทศ)"
                                        >
                                            <a-textarea
                                                v-model:value="
                                                    formState.user
                                                        .postcodepostinter
                                                "
                                                show-count
                                                placeholder="รหัสไปรษณีย์"
                                                :maxlength="100"
                                            />
                                        </a-form-item>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2"> </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customerphonethai']"
                                    label="เบอร์ติดต่อ"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <InputContactNumber
                                        :key="update"
                                        v-model:inputValue="
                                            formState.user.customerphonethai
                                        "
                                        :errorInfo="errorInfo[3]"
                                        :isFirstSave="false"
                                        :isShowErrorLbl="false"
                                        :inputLbl="lbl.shipping.contactnumber"
                                        :minLength="12"
                                        :maxLength="12"
                                        delimeter="-"
                                    >
                                    </InputContactNumber>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2">
                                <a-form-item
                                    :name="['user', 'customerphoneemergency']"
                                    label="เบอร์ติดต่อ(ฉุกเฉิน)"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <!-- <InputContactNumber
                                        :key="update"
                                        v-model:inputValue="
                                            formState.user
                                                .customerphoneemergency
                                        "
                                        :errorInfo="errorInfo[3]"
                                        :isFirstSave="false"
                                        :isShowErrorLbl="false"
                                        :inputLbl="lbl.shipping.contactnumber"
                                        :minLength="12"
                                        :maxLength="12"
                                        delimeter="-"
                                    >
                                    </InputContactNumber> -->
                                    <a-input
                                        v-model:value="
                                            formState.user
                                                .customerphoneemergency
                                        "
                                        placeholder="เบอร์ฉุกเฉิน"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'customerphone']"
                                    label="เบอร์ติดต่อ(ต่างประเทศ)"
                                    :rules="[
                                        {
                                            required: false,
                                            message: 'กรุณากรอก'
                                        }
                                    ]"
                                >
                                    <div class="p-2">
                                        <a-switch
                                            v-model:checked="
                                                formState.user
                                                    .switchnothasphonecode
                                            "
                                            checked-children="ไม่มี-รหัสประเทศ"
                                            un-checked-children="มี-รหัสประเทศ"
                                        />
                                    </div>
                                    <!-- <a-input-group compact>
                                        <a-select show-search v-model:value="formState.user.selectcountry
                                            .selectcountrycode
                                            " placeholder="รหัสโทร" style="width: 100px" :options="listcodephone"
                                            :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
                                            @change="handleChange"></a-select>
                                        <a-input v-model:value="formState.user.customerphone" @input="acceptNumber"
                                            style="width: 50%"></a-input>
                                    </a-input-group> -->
                                    <!-- <pre>switchnothasphonecode : {{formState.user.switchnothasphonecode}}</pre> -->
                                    <div
                                        v-if="
                                            formState.user.switchnothasphonecode
                                        "
                                    >
                                        <a-input
                                            v-model:value="
                                                formState.user.customerphone
                                            "
                                            style="width: 50%"
                                        ></a-input>
                                    </div>
                                    <div
                                        v-else-if="
                                            !formState.user
                                                .switchnothasphonecode
                                        "
                                    >
                                        <a-input-group compact>
                                            <a-select
                                                show-search
                                                v-model:value="
                                                    formState.user.selectcountry
                                                        .selectcountrycode
                                                "
                                                placeholder="รหัสโทร"
                                                style="width: 100px"
                                                :options="listcodephone"
                                                :filter-option="filterOption"
                                                @focus="handleFocus"
                                                @blur="handleBlur"
                                                @change="handleChange"
                                            ></a-select>
                                            <a-input
                                                v-model:value="
                                                    formState.user.customerphone
                                                "
                                                @input="acceptNumber"
                                                style="width: 50%"
                                            ></a-input>
                                        </a-input-group>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2">
                                <a-form-item
                                    :name="['user', 'customeremail']"
                                    label="E-Mail"
                                >
                                    <a-input
                                        v-model:value="
                                            formState.user.customeremail
                                        "
                                        placeholder="E-Mail"
                                    />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="3"> </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
            <a-divider orientation="left" v-if="false">สินค้า</a-divider>
            <div class="p-5 mb-5 card" v-if="false">
                <barcode-outlined />
                <a-row type="flex">
                    <a-col flex="1" :order="1">
                        <ProductFrom
                            :textbtn="'เพิ่มสินค้า'"
                            :formStates="formState"
                            v-model:value="formState.listproduct"
                        >
                        </ProductFrom>
                        <div v-if="false">
                            <ListCreateproduct
                                v-if="formState != undefined"
                                :formStates="formState"
                                v-model:value="formState.user.productfrom"
                            ></ListCreateproduct>
                        </div>
                    </a-col>
                </a-row>
                <!-- 
                 formState.user.test: {{formState.user.test}} -->
                <!-- formState.user.productfrom : {{formState.user.productfrom}} -->
            </div>
            <a-divider orientation="left" v-if="false">ที่อยู่</a-divider>
            <div class="p-5 mb-5 card" v-if="false">
                <pushpin-outlined class="pb-2" />
                <a-col flex="1" :order="1">
                    <AddressFrom
                        :textbtn="'เพิ่มที่อยู่'"
                        :formStates="formState"
                        v-model:value="formState.user.addressfrom"
                    ></AddressFrom>
                    <!-- <pre>{{ formState.user.addressfrom }}</pre> -->
                </a-col>
            </div>
            <div v-if="false">
                <a-divider orientation="left">ค่าส่งเพิ่มเติม</a-divider>
                <div class="p-5 mb-5 card">
                    <sisternode-outlined />
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'sellorderTransaction']"
                            >
                                <PaidDeliveryMore></PaidDeliveryMore>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2"> </a-col>
                    </a-row>
                </div>
            </div>
            <!-- formState.pay {{formState.pay}} -->
            <a-divider orientation="left" v-if="false"
                >การชำระเงิน(1.1)</a-divider
            >
            <div class="p-5 mb-5 card" v-if="false">
                <dollar-outlined />
                <a-row type="flex">
                    <a-col flex="1" :order="1">
                        <!-- formState : {{formState}} -->
                        <a-form-item :name="['user', 'sellorderTransaction']">
                            <Pay
                                :formStates="formState"
                                v-model:value="formState.pay"
                            ></Pay>
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>

            <ProductListByCustomerCode
                v-if="Storedata.methods.checkGrantProductListByCustomerCode()"
                :rowselected="rowselected"
                @selectedsellorder="showdetialselectedsellorder($event)"
            >
            </ProductListByCustomerCode>

            <MoreDelivery
                v-if="Storedata.methods.checkGrantMoreDelivery()"
                :rowselected="rowselected"
                @selectedsellorder="showdetialselectedRowKeys($event)"
            >
            </MoreDelivery>
            <CreateDeliveryDetailCustomer
                :key="update"
                v-if="
                    Storedata.methods.checkgrantCreateDeliveryDetailCustomer()
                "
                :rowselected="rowselected"
            ></CreateDeliveryDetailCustomer>

            <a-form-item
                v-show="Storedata.methods.checkgrantbtnsave()"
                :wrapper-col="{ offset: 8, span: 24 }"
            >
                <a-button
                    style="float: right"
                    type="primary"
                    html-type="submit"
                    :disabled="!isfinistinit"
                    >บันทึก
                </a-button>
            </a-form-item>
        </a-form>

        <!-- เรียกกัน error -->
        <ProductItems v-if="flase"></ProductItems>
        <Address v-if="flase"></Address>
        <!-- เรียกกัน error -->
    </div>
    <div class="relative p-4">
        <!-- แสดง จากการกด SO รายการขาย -->
        <FromCustomerProductDetail
            v-if="isshowDetailSellOrder.isshow"
            :rowselected="rowselected"
            :isshowDetailSellOrder="isshowDetailSellOrder"
        ></FromCustomerProductDetail>
        <!-- แสดง จากการกด SO รายการขาย -->
    </div>
</template>
<script>
import DatePickerDropdown from "@/components/Input/label/DatePickerDropdown.vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    computed,
    watch
} from "vue";
import ProductItems from "../component/ProductItems.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import axios from "axios";
import Storedata from "@/store/Storedata.js";
import { Modal } from "ant-design-vue";
// import Uploadfile from "../component/form/Uploadfile.vue";
import Address from "../component/Address.vue";
import FromCustomerProductDetail from "@/module/CustomerOrder/SellCreate/component/FromCustomerProductDetail.vue";
// import Inputnumber from "@/module/Sell/SellCreate/component/form/Inputnumber.vue";
import {
    UserOutlined,
    BarcodeOutlined,
    PushpinOutlined,
    // NumberOutlined,
    SisternodeOutlined,
    DollarOutlined,
    LeftOutlined,
    FilePdfOutlined
} from "@ant-design/icons-vue";
import SelectOption from "@/module/CustomerOrder/SellCreate/component/form/SelectOption.vue";
import ListCreateproduct from "@/module/Sell/SellCreate/component/form/ListCreateproduct.vue";
import AddressFrom from "@/module/Sell/SellCreate/component/form/AddressFrom.vue";
import PaidDeliveryMore from "@/module/Sell/SellCreate/component/form/PaidDeliveryMore.vue";

import Pay from "./form/Pay.vue";
import ProductFrom from "./form/ProductFrom.vue";
import CreateCustomerfrom from "./form/CreateCustomerfrom.vue";
// import Baseinput from "./form/Baseinput.vue";
import _ from "lodash";
import Contact from "./form/Contact.vue";
import { FormHelper } from "@/helper/FormHelper.js";
import {
    ContactModel,
    GetContactModel,
    CustomerModel,
    ProductModel,
    ProductDeliveryAddressModel,
    PaymentModel
} from "../../../../core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
import ProductListByCustomerCode from "@/module/CustomerOrder/SellCreate/component/form/ProductListByCustomerCode.vue";
import { formStateStore } from "../../../../store/storebackoffice";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import MoreDelivery from "@/module/Delivery/component/MoreDelivery.vue";
import { useRoute } from "vue-router";
import CreateDeliveryDetailCustomer from "@/module/Delivery/component/CreateDeliveryDetailCustomer.vue";
import Util from "@/helper/Utility";
import { useRouter } from "vue-router";
import Account from "@/helper/AccountHelper.js";

export default defineComponent({
    name: "FromCreate",
    provide: {
        Storedata: Storedata
    },
    props: {
        rowselected: null
    },
    mixins: [LocaleMixin],
    components: {
        FilePdfOutlined,
        InputContactNumber,
        DatePickerDropdown,
        FromCustomerProductDetail,
        LeftOutlined,
        Contact,
        ProductItems,
        // Uploadfile,
        Address,
        // Inputnumber,
        UserOutlined,
        SelectOption,
        ListCreateproduct,
        AddressFrom,
        PaidDeliveryMore,
        Pay,
        BarcodeOutlined,
        PushpinOutlined,
        // NumberOutlined,
        SisternodeOutlined,
        DollarOutlined,
        ProductFrom,
        CreateCustomerfrom,
        ProductListByCustomerCode,
        // Baseinput,
        MoreDelivery,
        CreateDeliveryDetailCustomer
    },
    setup(props) {
        const isshowcustomerdesc = ref(false);
        const imageDataURL = ref(null);
        const router = useRouter();
        const isshowDetailSellOrder = reactive({
            isshow: false,
            sellorder: ""
        });

        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);

        if (urlParams.has("issshowdetail")) {
            isshowDetailSellOrder.isshow = true;
        }

        Util.blockback();
        const isfinistinit = ref(false);
        const route = useRoute();
        console.log(
            "Storedata.state.profile : ",
            Storedata.state.profile.login.role
        );
        const rowselecteddetail = reactive(props.rowselected);

        const title = "ลูกค้า";
        const producttype = ref([]);
        // const gender = ref("1");
        const typelocal = ref("1");
        const update = ref(0);

        const age = ref(18);
        moment.locale("th");
        const datecreatetoday = moment()
            .add(543, "year")
            .format("LL");
        const layout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 12
            }
        };
        const validateMessages = {
            required: "${label} is required!",
            types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!"
            },
            number: {
                range: "${label} must be between ${min} and ${max}"
            }
        };

        const calculateAge = birthday => {
            console.log(birthday);

            if (birthday == null) return (formState.user.customerage = 0);

            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.user.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
        };

        const calculateAge_setting = birthday => {
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        const onModalAddressChagne = values => {
            console.log(values);
        };
        const onselectchange = value => {
            formState.user.customername =
                value[0].item.CustomerName +
                " " +
                value[0].item.CustomerLastName;
            formState.user.customertel = value[0].item.CustomerContractNumber;
            formState.user.customeremail = value[0].item.CustomerEmail;
            formState.user.lineid = value[0].item.lineid;
            formState.user.customerAddress = value[0].item.CustomerAddress;
            formState.user.customerbirthday = value[0].item.CustomerBirthDay;
            formState.user.customerintroduction = value[0].item.CustomerDesc;
            formState.user.customerage = calculateAge_setting(
                value[0].item.CustomerBirthDay
            );
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });
        const formState = reactive(formStateStore);
        formState.user.customercode = rowselecteddetail.rowselected;

        watch(
            () => formState.user.customeraddressType,
            () => {
                if (formState.user.customeraddressType == "1") {
                    formState.user.selectcountry.selectcountry = "";
                }
            },
            () => formState.user.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    )
                ) {
                    formState.user.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    );
                }
            }
        );
        // setTimeout(() => {
        //     formState.user.orderdate = moment().format("YYYY-MM-DD");
        // }, 100);
        onMounted(() => {
            gencodecustomer();
        });
        const SellOrderTransaction = () => {
            BzbsUser.apiSellOrderTransaction(null)
                .then(res => {
                    // formState.user.customercode =
                    //   "C-" + moment(new Date()).format("YYYYMMDDHHmmss");
                    return (formState.user.sellorderTransaction =
                        res.data[0].SellOrderTransactionID);
                })

                .catch(error => {
                    console.log(error);
                });
        };

        const imageslip = imglist => {
            if (imglist) {
                formState.user.fileList = imglist;
            }
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const options = ref([]);
        const handleChange = value => {
            onselectchange(options.value.filter(item => item.value == value));
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const queryProductType = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/producttype").then(res => {
                res?.data[0]?.map(item => {
                    let obj = {};
                    obj.label = item.ProductName;
                    obj.value = item.ProductName;
                    producttype.value.push(obj);
                });
            });
        };

        const getCustomer = async customercode => {
            await BzbsUser.apiGetCustomercustomercode(customercode)
                .then(res => {
                    // if (res.data[0]) {
                    return initfil(res.data[0]);
                    // }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            console.log("1.1");
            console.log("onprovince", values);
            formState.user.ProvinceName = values.provinceName;
            formState.user.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("1.2");
            console.log("ondistricts", values);
            formState.user.BorderName = values.districtsName;
            formState.user.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("1.3");
            console.log("onsubdistricts", values);
            formState.user.DistrictID = values.subdistrictsID;
            formState.user.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("1");
            console.log("onpostcode", values);
            formState.user.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const initcontact = CustomerCode => {
            BzbsUser.apicontact(CustomerCode)
                .then(res => {
                    const temp_obj = [];
                    res.data?.forEach(element => {
                        element.sellorderTransaction =
                            formState.user.sellorderTransaction;
                        element.customercode = formState.user.customercode;
                        var obj = new GetContactModel(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    formState.getcontactlist = temp_obj;
                    formState.user.contactList = temp_obj;
                    console.log(
                        "formState.user.contactlist :",
                        formState.getcontactlist
                    );
                    formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const customer_page = route.query.customercode;
        const gencodecustomer = async () => {
            var e = {};
            if (rowselecteddetail.rowselected != undefined) {
                initcontact(rowselecteddetail.rowselected);
                await getCustomer(rowselecteddetail.rowselected);
            } else if (customer_page) {
                initcontact(customer_page);
                await getCustomer(customer_page);
            }
            if (e == "") {
                return;
            }
        };
        const initfil = async e => {
            formState.user.contact = e.Contact;
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = e.CustomerName;
            formState.user.customerlastname = e.CustomerLastName;
            formState.user.customerphone = e.CustomerContractNumber;
            formState.user.customerbirthday = e.CustomerBirthDay;
            formState.user.customeremail = e.CustomerEmail;
            formState.user.addressdesc = e.CustomerAddress;
            formState.user.introduction = e.CustomerDesc;
            formState.user.ProvinceID = e.ProvinceID;
            formState.user.ProvinceName = e.ProvinceName;
            formState.user.BorderID = e.BorderID;
            formState.user.BorderName = e.BorderName;
            formState.user.BorderID = e.DistrictId;
            formState.user.DistrictID = e.DistrictID;
            formState.user.DistrictName = e.DistrictName;
            formState.user.PostCode = e.PostCode;
            formState.user.customerlineid = e.LineID;
            formState.user.customerfacebook = e.Facebook;
            formState.user.contactname = e.ContactName;
            formState.user.contact = e.Contact;
            formState.user.customergender =
                e.CustomerGender != undefined
                    ? e.CustomerGender.replace(/\s/g, "")
                    : "notspecified";
            formState.user.customernickname = e.CustomerNickName;
            formState.user.customerbirthdaytype = e.CustomerBirthDayType;
            formState.user.customercareer = e.CustomerCareer;
            formState.user.customeraddressType = e.CustomerAddressType;
            formState.user.selectcountry.selectcountry = e.SelectCountry;
            formState.user.customerphoneemergency = e.CustomerPhoneEmergency;
            formState.user.customerphonethai = e.CustomerPhoneThai;
            formState.user.contactimageurl = e.ContactImageUrl;
            formState.user.provincepostinter = e.ProvincePostinter;
            formState.user.citypostinter = e.CityPostinter;
            formState.user.postcodepostinter = e.PostcodePostinter;

            formState.user.customerphonenocode = e.CustomerPhoneNoCode;
            formState.user.switchnothasphonecode = e.SwitchNotHasPhoneCode;

            update.value = update.value + 1;
            console.log(e);
            calculateAge(formState.user.customerbirthday);
            setTimeout(() => {
                isfinistinit.value = true;
            }, 1500);
        };
        // const clearfil = e => {
        //     formState.user.contact = "";
        //     formState.user.customercode = e.CustomerCode;
        //     formState.user.customername = "";
        //     formState.user.customerlastname = "";
        //     formState.user.customerphone = "";
        //     formState.user.customerbirthday = "";
        //     formState.user.customeremail = "";
        //     formState.user.addressdesc = "";
        //     formState.user.introduction = "";
        //     formState.user.ProvinceID = "";
        //     formState.user.ProvinceName = "";
        //     formState.user.BorderID = "";
        //     formState.user.BorderName = "";
        //     formState.user.BorderID = "";
        //     formState.user.DistrictName = "";
        //     formState.user.PostCode = "";
        //     formState.user.customerlineid = "";
        //     formState.user.customerfacebook = "";
        //     formState.user.contactname = "";
        //     formState.user.contact = "";
        //     // ImageURL: "",
        //     formState.user.customergender = "notspecified";
        //     formState.user.customernickname = "";
        //     formState.user.customerbirthdaytype = "";
        //     formState.user.customercareer = "";
        //     formState.user.customeraddressType = "1";
        //     formState.user.customerphoneemergency = "";
        //     formState.user.customerphonethai = "";
        //     formState.user.selectcountry.selectcountry = "";
        //     update.value = update.value + 1;
        // };

        const onFinish = async values => {
            // progress.value = true;

            insertdatabase();
            console.log("DATA >> ", values);
            console.log("Success:", values);
        };
        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const checkcondition = async () => {
            console.log("checkcondition", formState.user.contactList);
            const result = formState.user.contactList.some(
                item => item.contactstartdefault == "true"
            );
            if (result) {
                return true;
            } else {
                warning("กรุณาตั้งค่าเริ่มต้นช่องทางการติดต่อ");
                return false;
            }
        };

        const insertdatabase = async () => {
            // if (checkcondition()) {
            Storedata.state.loading.ishow = true;
            await insertcontract();
            Storedata.state.loading.percent += 14.28;
            await insertcustomer();
            Storedata.state.loading.percent += 14.28;
            // await insertdeliveryinside();
            // Storedata.state.loading.percent += 14.28;
            // await insertdeliveryoutside();
            // Storedata.state.loading.percent += 14.28;
            // await insertproduct();
            // Storedata.state.loading.percent += 14.28;
            // await insertproductdeliveryaddress();
            // Storedata.state.loading.percent += 14.28;
            // await insertpayment();
            // Storedata.state.loading.percent += 14.28;
            // await this.insertcreatecustomer();
            // await this.insertcreateproduct();
            // await this.insertcreatedeliveryaddress();
            // await this.insertcreatepayment();

            setTimeout(() => {
                Storedata.state.loading.ishow = false;
                success();
            }, 2000);
            // }
        };
        const insertcontract = async () => {
            let params = [];
            await formState.user.contactList.forEach((element, index) => {
                element.productsellorder = formState.user.sellorderTransaction;
                element.customerorder = formState.user.customercode;
                element.key = (index + 1).toString();
                var obj = new ContactModel(element);
                console.log("obj", obj);
                params.push(obj);
            });
            console.log("param", params);

            // let slip_url = [];
            var index_uploadimage = 0;
            for await (let items of params) {
                if (items.ContactImage[0]?.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename:
                            formState.user.customercode +
                            "_" +
                            index_uploadimage +
                            ".jpg",
                        base64: items.ContactImage[0].preview,
                        folder: "contact"
                    };
                    await BzbsUser.apiuploadimage(uploadparam)
                        .then(res => {
                            params[index_uploadimage]["ContactImage"] = "";
                            params[index_uploadimage]["ContactImageUrl"] = "";
                            params[index_uploadimage]["ContactImage"] =
                                uploadparam.filename;
                            params[index_uploadimage]["ContactImageUrl"] =
                                res.data.url;
                            console.log(res.data);
                            index_uploadimage++;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    index_uploadimage++;
                }
            }

            for await (let obj of params) {
                await BzbsUser.apiupdatecontact(obj)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertcustomer = async () => {
            let params = new CustomerModel(formState.user);

            console.log("param", params);

            await BzbsUser.apiUpdateCustomer(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        const insertdeliveryinside = async () => {
            //insert inside จัดส่ง ในประเทศ
            if (formState.inside.length <= 0) return;
            // var inside = formState.inside;
            // var index_inside = 0;
            // for await (let items of inside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_inside = {};
            //         uploadparam_inside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_inside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "inside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_inside)
            //             .then(res => {
            //                 formState.inside[index_inside]["slipiurl"] = "";
            //                 formState.inside[index_inside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_inside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let inside_obj of formState.listproduct) {
                if (inside_obj.inside != undefined) {
                    for await (let items of inside_obj.inside) {
                        var param_inside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "inside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: inside_obj.productkey
                        };
                        await BzbsUser.apicreatedelivery(param_inside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertdeliveryoutside = async () => {
            //insert outside จัดส่ง ในประเทศ
            if (formState.outside.length <= 0) return;
            // var outside = formState.outside;
            // var index_outside = 0;
            // for await (let items of outside) {
            //     if (items.slipimage[0].preview != undefined) {
            //         var uploadparam_outside = {};
            //         uploadparam_outside = {
            //             filename:
            //                 formState.user.customercode +
            //                 "_" +
            //                 index_outside +
            //                 ".jpg",
            //             base64: items.slipimage[0].preview,
            //             folder: "outside"
            //         };
            //         await BzbsUser.apiuploadimage(uploadparam_outside)
            //             .then(res => {
            //                 formState.outside[index_outside]["slipiurl"] = "";
            //                 formState.outside[index_outside]["slipiurl"] =
            //                     res.data.url;
            //                 console.log(res.data);
            //                 index_outside++;
            //             })
            //             .catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
            for await (let outside_obj of formState.listproduct) {
                if (outside_obj.inside != undefined) {
                    for await (let items of outside_obj.outside) {
                        var param_outside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "outside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl
                        };
                        await BzbsUser.apiupdateproductdelivery(param_outside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertproduct = async () => {
            console.log("insert :", formState.listproduct);

            for await (let listproduct_items of formState.listproduct) {
                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
                listproduct_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;
                listproduct_items.productresponsibleperson = JSON.stringify(
                    Account.getCacheUserBackoffice()
                );

                let params = new ProductModel(listproduct_items);

                console.log("param", params);

                await BzbsUser.apiupdateproduct(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertproductdeliveryaddress = async () => {
            for await (let listproductdeliveryaddress_items of formState.user
                .addressfrom) {
                // if (listproduct_items.productyanimage != undefined) {
                //     var queyanimage = listproduct_items.productyanimage;
                //     for await (let items of queyanimage) {
                //         if (items.preview != undefined) {
                //             var uploadparam = {};
                //             uploadparam = {
                //                 filename: formState.user.customercode + ".jpg",
                //                 base64: items.preview,
                //                 folder: "queyan"
                //             };
                //             await BzbsUser.apiuploadimage(uploadparam)
                //                 .then(res => {
                //                     listproduct_items["productyanimage"] = "";
                //                     listproduct_items["productyanimage"] =
                //                         res.data.url;
                //                     console.log(res.data);
                //                 })
                //                 .catch(error => {
                //                     console.log(error);
                //                 });
                //         }
                //     }
                // }

                listproductdeliveryaddress_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproductdeliveryaddress_items.customerorder =
                    formState.user.customercode;

                var phonecode = "";
                if (listproductdeliveryaddress_items?.phonecode) {
                    phonecode = listproductdeliveryaddress_items?.phonecode;
                } else if (
                    listproductdeliveryaddress_items.phonecode &&
                    listproductdeliveryaddress_items.phonecode[0].label
                ) {
                    phonecode =
                        listproductdeliveryaddress_items.phonecode[0].label;
                }
                if (phonecode) {
                    listproductdeliveryaddress_items.phonecode = phonecode;
                }
                let params = new ProductDeliveryAddressModel(
                    listproductdeliveryaddress_items
                );

                console.log("param", params);

                await BzbsUser.apiupdateproductdelivery(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertpayment = async () => {
            var indeximage = 0;
            if (formState.pay.slipimage != undefined) {
                for await (let items of formState.pay.slipimage) {
                    if (items.preview != undefined) {
                        var uploadparam = {};
                        indeximage++;
                        uploadparam = {
                            filename:
                                formState.user.customercode +
                                "_" +
                                indeximage +
                                "_" +
                                Math.floor(Date.now() / 1000) +
                                ".jpg",
                            base64: items.preview,
                            folder: "payment"
                        };
                        await BzbsUser.apiuploadimage(uploadparam)
                            .then(res => {
                                formState.pay["slipimageurl"] = "";
                                formState.pay["slipimageurl"] = res.data.url;
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
            formState.pay.productsellorder =
                formState.user.sellorderTransaction;
            formState.pay.customerorder = formState.user.customercode;

            let params = new PaymentModel(formState.pay);
            console.log("param", params);
            await BzbsUser.apiupdatepayment(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const showdetialselectedRowKeys = e => {
            // formState.user.sellorderTransaction = e
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };

        if (route.query.so) {
            formState.user.sellorderTransaction = route.query.so;
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = route.query.so;
        }

        const showdetialselectedsellorder = e => {
            formState.user.sellorderTransaction = e;
            isshowDetailSellOrder.isshow = true;
            isshowDetailSellOrder.sellorder = e;
            console.log("sellorder:", e);
        };

        const clickblack = () => {
            if (Storedata.methods.isnoaccount()) {
                return (window.location = "/checkmoneyselllist");
            }
            if (customer_page) {
                // this.$router.push('/customermember')
                router.push({ name: "customer_member" });
                // route.push({ name: 'customer_member' })
                // route.push("customermember")
                // window.location = '/customermember'
            }
            rowselecteddetail.ishowdetail = false;
        };

        const acceptNumber = () => {
            var x = formState.user.customerphone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            formState.user.customerphone = !x[2]
                ? x[1]
                : x[1] + x[2] + (x[3] ? x[3] : "");
        };

        const checkGrantProductListByCustomerCode = () => {
            if (route.path == "/delivery") {
                return false;
            } else {
                return true;
            }
        };
        const visiblechanal = ref(true);
        const showModalchanal = () => {
            visiblechanal.value = true;
        };
        const handleOkchanal = e => {
            console.log(e);
            visiblechanal.value = false;
        };

        return {
            visiblechanal,
            handleOkchanal,
            showModalchanal,
            isfinistinit,
            checkGrantProductListByCustomerCode,
            acceptNumber,
            clickblack,
            isshowDetailSellOrder,
            showdetialselectedRowKeys,
            showdetialselectedsellorder,
            insertproduct,
            insertproductdeliveryaddress,
            gencodecustomer,
            title,
            formState,
            onFinish,
            layout,
            validateMessages,
            SellOrderTransaction,
            onselectchange,
            calculateAge,
            options,
            listcodephone,
            handleChange,
            handleBlur,
            handleFocus,
            filterOption,
            onFinishFailed,
            queryProductType,
            producttype,
            onModalAddressChagne,
            publishedBooksMessage,
            getallproduct,
            success,
            imageslip,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            calculateAge_setting,
            datecreatetoday,
            typelocal,
            age,
            update,
            insertdatabase,
            insertcontract,
            insertcustomer,
            rowselecteddetail,
            getCustomer,
            formStateStore,
            Storedata,
            errorInfo: FormHelper.genErrorInfo(7),
            datetest: ref(new Date()),
            insertpayment,
            insertdeliveryinside,
            insertdeliveryoutside,
            isshowcustomerdesc
        };
    }
    // computed: {
    //     createproduct: function() {
    //         return Storedata.state.createproduct;
    //     }
    // }
});
</script>
<style lang="scss" scoped>
.ant-row {
    flex-direction: row;
}

.date_create {
    display: flex;
    flex-flow: row-reverse;
}

.wrapper_createnew {
    display: flex;
    align-items: center;
}

.wrapper-icon-create {
    position: relative;
    top: 26px;
    left: 30px;
}
</style>
