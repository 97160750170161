export default {

    /**
     * @function removeEmoji
     * @function removeNumber
     * @function removeNotNumber
     * @function removeSymbol
     * @function removeEnglish
     * @function removeThai
    */
    //#region remove
    removeEmoji: function (text) {
        let rs = text.replace(/((\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]\s?)+)/g, '');
        return rs;
    },
    removeNumber: function (text) {
        let rs = text.replace(/[0-9]/g, '');
        return rs;
    },
    removeNotNumber: function (text) {
        let rs = text.replace(/[^0-9]/g, '');
        return rs;
    },
    removeSymbol: function (text) {
        let rs = text.replace(/[-!$@฿#%^&*()_+|~=`{}[\]:";'<>?,./]/g, '');
        return rs;
    },
    removeEnglish: function (text) {
        let rs = text.replace(/[a-zA-Z.\s]/g, '');
        return rs;
    },
    removeThai: function (text) {
        let rs = text.replace(/[^\u0E01-\u0E3A\u0E40-\u0E4D\s]/g, '');
        return rs;
    },
    //#endregion remove

    /**
     * @function isEmailFormat
     * @function isZipcode
     * @function isContactNumber
     */
    //#region check
    isEmailFormat: function (email) {
        // eslint-disable-next-line
        var rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return rex.test(email);
    },
    isZipcode: function (strZipCode) {
        return strZipCode.match(/^[0-9]{5}/);
    },
    isContactNumber: function (number) {
        // eslint-disable-next-line
        var rex = /^0(\d{9})$/;

        return rex.test(number);
    },
    //#endregion check
}
