<template>
    <a-form :model="formState" name="basic" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed"
        @change="fromchange">
        <a-form-item v-if="options.length > 0" :name="['selectcountry']" label="เลือกประเทศ*"
            :rules="[{ required: true, message: 'Missing area' }]">   
            <a-select show-search v-model:value="formState.user.selectcountry.selectcountry" placeholder="เลือกประเทศ"
                style="width: 200px" :options="options" :filter-option="filterOption" @focus="handleFocus"
                @blur="handleBlur" @change="handleChange"></a-select>
            <!-- <pre>{{options}}</pre> -->
        </a-form-item>
    </a-form>
</template>
<script>
import { defineComponent, ref, reactive,watch } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { Form } from "ant-design-vue";
export default defineComponent({
    props: {
        textbtn: null,
        formStates: null
    },
    setup(props, { emit }) {
debugger
        const options = ref([]);
        const formState = reactive(props.formStates);
        formState.useraddress.selectcountry = formState.user.selectcountry.selectcountry
        if(formState.user.selectcountry.selectcountry == "1"){

            formState.user.selectcountry.selectcountry = ""
        }
        // formState.user.selectcountry.selectcountry = null
        console.log("formState ", formState)
        BzbsUser.apicountries()
            .then(res => {
                 
                Object.keys(res.data).map(function (element) {  
                    if(formState.user.selectcountry.selectcountry =='TH' || res.data[element].CountryCode =="TH"|| formState.user.selectcountry.selectcountry =="1") {
                        formState.user.selectcountry.selectcountry = ""
                        return
                    }
                    options.value.push({
                        value: [res.data[element].CountryNameTh, res.data[element].CountryCode,res.data[element].CountryNameEng],
                        label: res.data[element].CountryNameTh,
                        labelen: res.data[element].CountryNameEng
                    });
                });

                if (formState.user.selectcountry.selectcountry) {
                    var result_fine = options.value.find(item => item.label == formState.user.selectcountry.selectcountry)
                    console.log(result_fine)
                    var value = []
                    value[0] = result_fine.label
                    value[1] = result_fine.value[1]
                    value[2] = result_fine.value[2]
                    handleChange(value)
                }
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            emit("update:value", formState);
            formItemContext.onFieldChange();
        };
        const handleChange = value => {
            try {
                formState.user.selectcountry.selectcountry = value[0];
                formState.user.selectcountry.selectcountrycode = value[1];
                formState.useraddress.selectcountry = value[0]
                formState.useraddress.selectcountrycode = value[1]
                formState.useraddress.CountryName = value[0] 
                formState.useraddress.CountryNameEn = value[2]
                console.log(`selected ${value}`);
                // fromchange();
            } catch (error) {
                console.log(error)
            }

        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
             
            // if(option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            //  console.log("option ::",option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,"input : ",input.toLowerCase(),"::",option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        watch(
            () => formState.user.selectcountry.selectcountry,
            () => {

                if (formState.user.selectcountry.selectcountry == "1") {
                    formState.user.selectcountry.selectcountry = "";
                }
            },
          
        );
        return {
            value: ref(undefined),
            filterOption,
            handleBlur,
            handleFocus,
            handleChange,
            options,
            fromchange,
            formState
        };
    }
});
</script>
