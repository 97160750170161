<template>
    <div class="flex flex-col text-center mt-8 mb-8 justify-center items-center">
        <div class="flex relative justify-center mb-6">
            <div class="w-20 h-20 rounded-full bg-gray-500 mx-auto"></div>
            <div class="absolute -bottom-4 text-center">
                <img src="@/assets/imgs/logo/Logo_NA-Black.png" alt />
            </div>
        </div>
        <div class="h-5 bg-gray-400 vertical-timeline-divider"></div>
        <div class="text-center my-1">
            <div class="text-sm font-bold text-black-1">Joined : 16 / Apr / 20</div>
            <div class="text-sm text-gray-2">1Y 1M 1D</div>
        </div>
        <div class="h-5 bg-gray-400 vertical-timeline-divider"></div>
        <div class="text-center my-1">
            <div class="text-sm font-bold text-black-1">Last Seen : 16 / Apr / 20</div>
            <div class="text-sm text-gray-2">Web on Safari iOS 14.6</div>
        </div>
        <div class="h-5 bg-gray-400 vertical-timeline-divider"></div>
        <div class="text-center my-1">
            <a href="#" class="text-xs underline text-blue-700" @click="openTab(6)">All Timeline</a>
        </div>
    </div>

    <div class="p-6">
        <div class="flex justify-between items-center">
            <div class="font-semibold text-black-1">Tags</div>
            <div>
                <a href="#" @click="openTab(1)" class="text-xs underline text-blue-700">View All</a>
            </div>
        </div>

        <div class="mt-4 flex flex-wrap">
            <template v-for="(tag, idx) of tags" :key="`tag-${idx}`">
                <v-tag class="p-2 text-black-1">{{ tag.name }}</v-tag>
            </template>
        </div>
    </div>
</template>

<script>
import VTag from "@/components/Tag/Tag.vue";

export default {
    name: "UserProfileLeftTimeline",
    components: {
        VTag
    },
    data: function () {
        return {
            tags: []
        }
    },
    emits: ["changeTab"],
    created: function () {
        this.tags = [
            { id: 1, name: "Male" },
            { id: 2, name: "Age > 30" },
            { id: 3, name: "Silver" },
            { id: 4, name: "Buy > 2,000" },
            { id: 5, name: "Active > 7 Days" },
        ]
    },
    methods: {
        openTab: function (tab) {
            this.$emit("changeTab", tab)
        }
    }
}
</script>

<style lang="scss" scoped>
.vertical-timeline-divider {
    width: 2px;
}
</style>
