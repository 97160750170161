
<template>
    <!-- <pre>formStateStore {{formStateStore}}</pre> -->
    <a-divider orientation="left">Product(1.1)</a-divider>
    <!-- <pre>{{ formStateStore.reportred }}</pre> -->
    <!-- 
     <pre>{{ targetKeys }}</pre> -->
     <!-- <pre>{{ mockData }}</pre>  -->
    <a-transfer v-model:target-keys="targetKeys" :data-source="mockData" :list-style="{
        width: '100%',
        height: 'auto',
    }" @change="handleChange">
        <template #render="item">
            <a-row type="flex" style="padding: 10px;">
                <a-col flex="1 1 200px">
                    <div v-if="item.title == null">
                        ไม่พบ Barcode
                    </div>
                    <div v-else>
                        <vue-barcode :value="item.title" :options="{ displayValue: true }" style="width:200px">
                        </vue-barcode>
                    </div>
                </a-col>
                <a-col flex="1 1 200px">
                    <div class="content-code" style="color: black;font-size: 18px;line-height: normal;">
                        {{ item.description }}
                    </div>
                    <!-- <div v-if="item.detail?.length > 0" style="color: black;font-size: 20px;line-height: normal;">
                        <a-divider orientation="left">วันเกิด</a-divider>
                    </div> -->
                    <div v-for="(bcItem, idx) of item.detail" :key="`bc-item-${idx}`">
                        <div v-if="bcItem.birthday" style="color: black;font-size: 18px;line-height: normal;">
                            วันเกิด ({{ formatdate(bcItem.birthday, bcItem.typebirthday) }})
                        </div>
                    </div>
                    <div class="content-code" style="color: black;font-size: 18px;line-height: normal;">
                        {{ item.title }}
                        <pre>จำนวน {{ item.qty || 1 }} ชิ้น</pre>
                    </div>


                </a-col>
            </a-row>
        </template>
    </a-transfer>
</template>
<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
import Storedata from "@/store/Storedata.js";
import { defineComponent, ref, onMounted, watch } from 'vue';
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work

import {
    GetProductBirthday
} from "@/core/Create/model/CreateModel";
export default defineComponent({
    props: {
        isdisable: null
    },
    components: {
        VueBarcode
    },
    setup(props) {
        const mockData = ref([]);
        var disabledtranfer = ref(props.isdisable);

        console.log("formStateStore?.listcreatedelivery :", formStateStore?.listcreatedelivery)

        const setshort = txt => {
            if (!txt) return ""
            var short = txt.match(/\d/g);
            return short = short?.join("");
        };

        var list_listcreatedelivery = formStateStore.listcreatedelivery;
     
            var find_cancel = list_listcreatedelivery.find(
                item => item.ProductStatus == "cancel"
            );

            list_listcreatedelivery = list_listcreatedelivery.filter(
                item => item.ProductStatus != "cancel"
            );
            if (find_cancel) {
                list_listcreatedelivery.forEach((obj, index) => {
                    obj.key = index;
                });
            }

            list_listcreatedelivery.forEach((element, index) => {
 
            console.log("element", element)

            var content = element.CustomerProductsType + ' ' + element.CustomerProductsSize + ' ' + element.HeartYanName + ' ' + element.IntroductionYan
            console.log("ใบแดง", element)
            const productData = ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"];
//PD_Desc
            if (productData.includes(element.CustomerProductsType)) { //เป็นผ้ายันต์ 

                BzbsUser.apigetproductbirthday(element.CustomerOrder, element.ProductKey, element.PD_ProductSellOrder)
                    .then(res => {

                        const temp_obj = [];
                        res.data.forEach(element => {

                            var obj = new GetProductBirthday(element);
                            console.log("obj", obj);
                            temp_obj.push(obj);
                        });

                        var shot = setshort(element.Category_2);

 
                        mockData.value.push({
                            key: index.toString(),
                            title: element.ProductCode,
                            description: content,
                            detail: temp_obj,
                            disabled: disabledtranfer.value,
                            chosen: true,
                            shot: element.Shotness || shot,
                            Category_1: element.Category_1,
                            Category_2: element.Category_2,
                            Category_3: element.Category_3,
                            qty: element.QTYProduct,
                            PD_DeliveryType: element.PD_DeliveryType,
                            introduction: element.Introduction,
                            productstatus: element.ProductStatus,
                            productkey:element.ProductKey
                        });


                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {

 
                var shot = setshort(element.Category_2);
                mockData.value.push({
                    key: index.toString(),
                    title: element.ProductCode,
                    description: content,
                    detail: [],
                    disabled: disabledtranfer.value,
                    chosen: true,
                    shot: element.Shotness || shot,
                    Category_1: element.Category_1,
                    Category_2: element.Category_2,
                    Category_3: element.Category_3,
                    qty: element.QTYProduct,
                    PD_DeliveryType: element.PD_DeliveryType,
                    ProfessorType: element.ProfessorType,
                    introduction: element.Introduction,
                    productstatus: element.ProductStatus,
                    productkey:element.ProductKey
                });
            }
             
            formStateStore.reportred.mockData = mockData.value

        });


        // const originTargetKeys = mockData.value.filter(item => +item.key % 3 > 1).map(item => item.key);
        const leftTableColumns = [{
            dataIndex: 'title',
            title: 'รหัสสินค้า',
        }, {
            dataIndex: 'description',
            title: 'รายการสินค้า',
        }];
        const rightTableColumns = [{
            dataIndex: 'title',
            title: 'รหัสสินค้า',
        }, {
            dataIndex: 'description',
            title: 'รายการสินค้า',
        }];

        const targetKeys = ref([]);
        const disabled = ref(false);
        const showSearch = ref(true);
        const leftColumns = ref(leftTableColumns);
        const rightColumns = ref(rightTableColumns);
        const onChange = nextTargetKeys => {
            console.log('nextTargetKeys', nextTargetKeys);
        };
        const getRowSelection = ({
            disabled,
            selectedKeys,
            onItemSelectAll,
            onItemSelect,
        }) => {
            return {
                getCheckboxProps: item => ({
                    disabled: disabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({
                        key,
                    }) => key);
                    onItemSelectAll(treeSelectedKeys, selected);
                },
                onSelect({
                    key,
                }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: selectedKeys,
            };
        };

        onMounted(() => {
        });

        watch(
            () => formStateStore.scanpacking,
            () => {

                if (formStateStore.scanpacking.length > 10) {


                    isshowpacking.value = false
                    apiscantrackingproduct()
                    updatepacking.value++
                }
                else {
                    isshowpacking.value = false
                    updatepacking.value++
                }
            },
        );
        const isshowpacking = ref(false)
        const updatepacking = ref(0)
        const apiscantrackingproduct = () => {



            var packproduct_code = formStateStore.scanpacking;
            packproduct_code = packproduct_code.split('|')

            var idex_move_tack = mockData.value.filter(item => item.title == packproduct_code)
            var param = {
                // "ProductSellOrder": packproduct_code[0],
                // "CustomerOrder": packproduct_code[1],
                // "ProductKey": packproduct_code[2],
                "ProductCode": packproduct_code[0],
                "AdditionalTage": formStateStore.rowselecteddetail
            }
            BzbsUser.apiscantrackingproduct(param)
                .then(res => {

               

                    if (res.data.isfound) {
                        targetKeys.value.push(idex_move_tack[0].key)
                        isshowpacking.value = true
                        formStateStore.scanpacking = '';
                    }

                    if (targetKeys.value.length == mockData.value.length) {
                        formStateStore.user.finishpacked_scanproduct = true
                    }


                })

                .catch(error => {
                    console.log(error);
                });
        };

        const formatdate = (date, typebirthday) => {

            if (typebirthday == '3') {
                return moment(date)
                    .format("l");

            }
            else if (typebirthday == '2') {
                return moment(date)
                    .add(543, "year")
                    .format("l");
            }

        };
        return {
            formatdate,
            isshowpacking,
            Storedata,
            apiscantrackingproduct,
            mockData,
            targetKeys,
            disabled,
            showSearch,
            leftColumns,
            rightColumns,
            onChange,
            getRowSelection,
            formStateStore
        };
    },
});
</script>