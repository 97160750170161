<template>
    <div>
        <a-button type="link" @click="showModal">ดูตัวอย่าง</a-button>
        <a-modal v-model:visible="visible" width="1000px" title="ตัวอย่าง" @ok="handleOk">
            <video controls :src="url_link">

            </video>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
    props: {
        url: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const url_link = ref(props.url);
        const visible = ref(false);
        const showModal = () => {
            visible.value = true;
        };
        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        return {
            visible,
            showModal,
            handleOk,
            url_link
        };
    },
});
</script>