<template>
    <div
        class="sidebar-header w-full flex items-center justify-center p-2"
        @click="toggleSidebar()"
        :style="headerStyles"
    >
        <div class :class="{ 'flex items-center flex-grow': isOpen }">
            <div class="logo">
                <img src="../../../../assets/imgs/logo/Logo_NA-Black.png" />
            </div>
            <template v-if="shown">
                <div class="text text-black text-sm pl-2 font-semibold">
                    {{ text }}
                </div>
            </template>
        </div>

        <!-- <div class="flex-none">
            <div
                class="button cursor-pointer transition duration-500 ease-in-out px-1 py-2"
               
                :class="[
                    isOpen ? 'transform rotate-0' : 'transform rotate-180'
                ]"
            >
                <img src="@/assets/icons/arrow/arrow-chevron-left.svg" />
            </div>
        </div> -->
    </div>
</template>

<script>
import SidebarMixin from "../mixin/sidebar.mixin";
export default {
    name: "SidebarHeader",
    mixins: [SidebarMixin],
    props: {
        logo: {
            type: String
        },
        text: {
            type: String
        },
        height: {
            type: Number,
            default: 55
        },
        bgColor: {
            type: String,
            default: "#fff"
        },
        isOpen: {
            type: Boolean
        }
    },
    data: function() {
        return {};
    },
    computed: {
        headerStyles: function() {
            return {
                height: `${this.height}px`,
                backgroundColor: this.bgColor
            };
        }
    },
    methods: {
        toggleSidebar: function() {
            this.$emit("onClickToggle");
        }
    }
};
</script>
<style lang="scss" scoped>
.logo {
    img {
        height: auto;
        width: 100px;
    }
    background-color: white;
    border-radius: 15px;
}
</style>
