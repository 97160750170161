<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-table
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <!-- <template v-if="false" #expandedRowRender="{ record }"> -->

                <!-- <Drawer :sellorder="record.SellOrder" :shipping="JSON.parse(record.Productshipping)"
                        @updatedata="updatedata"></Drawer> -->
                <!-- <div class="mt-1">
                        <Previewslip :slip="JSON.parse(record.SlipUrl)"></Previewslip>
                    </div> -->
                <!-- </template> -->
                <template
                    #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                >
                    <div style="padding: 8px">
                        <a-input
                            ref="searchInput"
                            :placeholder="`Search ${column.dataIndex}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="
                                e =>
                                    setSelectedKeys(
                                        e.target.value ? [e.target.value] : []
                                    )
                            "
                            @pressEnter="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        />
                        <a-button
                            type="primary"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        >
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button
                            size="small"
                            style="width: 90px"
                            @click="handleReset(clearFilters)"
                        >
                            Reset
                        </a-button>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <!-- <pre>{{record}}</pre> -->
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>

                    <template v-if="column.dataIndex === 'AdditionalTage'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-typography-text
                                copyable
                                strong
                                v-if="Item.AdditionalTage"
                            >
                                {{ Item.AdditionalTage }}
                            </a-typography-text>

                            <a-typography-text delete v-else
                                >ไม่พบข้อมูล</a-typography-text
                            >
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrderSearch'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-typography-text
                                copyable
                                strong
                                v-if="Item.CustomerOrder"
                            >
                                {{ Item.CustomerOrder }}
                            </a-typography-text>

                            <a-typography-text delete v-else
                                >ไม่พบข้อมูล</a-typography-text
                            >
                        </div>
                    </template>
                    <template
                        v-if="column.dataIndex === 'AdditionalTageStatus'"
                    >
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div v-if="Item.AdditionalTage && Item.Checking">
                                <div>
                                    <div v-if="Item.AdditionalShipping <= 0">
                                        <a-tag color="blue"
                                            >ไม่มีค่าส่งเพิ่ม</a-tag
                                        >
                                    </div>
                                    <div v-else>
                                        <a-tag
                                            v-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'ตรวจสอบแล้วผ่าน'
                                            "
                                            color="#87d068"
                                            >ตรวจสอบแล้วผ่าน</a-tag
                                        >
                                        <a-tag
                                            v-else-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'ไม่ผ่าน'
                                            "
                                            color="#f50"
                                            >ไม่ผ่าน</a-tag
                                        >
                                        <a-tag
                                            v-else-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'บัญชียังไม่ได้ตรวจสอบ'
                                            "
                                            color="#108ee9"
                                            >บัญชียังไม่ได้ตรวจสอบ</a-tag
                                        >
                                    </div>
                                </div>
                                <!-- <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text> -->
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Create'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div v-if="Item.AdditionalTage">
                                <div>
                                    <div v-if="Item.AdditionalShipping <= 0">
                                        <div
                                            v-if="
                                                Item.DeliveryStatus == 'shipped'
                                            "
                                        >
                                            <a-button
                                                @click="
                                                    rowselected(record, idx)
                                                "
                                                style="background-color: #f50;width: 100%;border-color: white;"
                                                type="primary"
                                                >จัดส่งแล้ว</a-button
                                            >
                                        </div>
                                        <div v-else>
                                            <a-button
                                                :disabled="true"
                                                v-if="
                                                    record[idx].ProductStatus ==
                                                        'cancel'
                                                "
                                            >
                                                รายการนี้ถูกยกเลิก
                                            </a-button>
                                            <a-button
                                                v-else
                                                @click="
                                                    rowselected(record, idx)
                                                "
                                                type="primary"
                                                >กดสร้างรายการจัดส่ง</a-button
                                            >
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="
                                            isLastMessageChecked(
                                                JSON.parse(Item.CheckMessage)
                                            ) == 'ตรวจสอบแล้วผ่าน'
                                        "
                                    >
                                        <div
                                            v-if="
                                                Item.DeliveryStatus == 'shipped'
                                            "
                                        >
                                            <a-button
                                                @click="
                                                    rowselected(record, idx)
                                                "
                                                style="background-color: #f50;width: 100%;border-color: white;"
                                                type="primary"
                                                >จัดส่งแล้ว</a-button
                                            >
                                        </div>
                                        <div v-else>
                                            <a-button
                                                :disabled="true"
                                                v-if="
                                                    record[idx].ProductStatus ==
                                                        'cancel'
                                                "
                                            >
                                                รายการนี้ถูกยกเลิก
                                            </a-button>
                                            <a-button
                                                v-else
                                                @click="
                                                    rowselected(record, idx)
                                                "
                                                type="primary"
                                                >กดสร้างรายการจัดส่ง</a-button
                                            >
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- <pre>Item.CheckMessage : {{Item.CheckMessage}}</pre> -->
                                        <a-tag
                                            v-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'ไม่ผ่าน'
                                            "
                                            color="#f50"
                                            >ไม่ผ่าน</a-tag
                                        >

                                        <a-tag
                                            v-else-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'บัญชียังไม่ได้ตรวจสอบ'
                                            "
                                            color="orange"
                                            >บัญชียังไม่ได้ตรวจสอบ</a-tag
                                        >
                                        <div v-else>
                                            <a-button
                                                :disabled="true"
                                                v-if="
                                                    record[idx].ProductStatus ==
                                                        'cancel'
                                                "
                                            >
                                                รายการนี้ถูกยกเลิก
                                            </a-button>
                                            <a-button
                                                v-else
                                                @click="
                                                    rowselected(record, idx)
                                                "
                                                type="primary"
                                                >กดสร้างรายการจัดส่ง</a-button
                                            >
                                        </div>
                                    </div>
                                </div>
                                <!-- <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text> -->
                            </div>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'CancelSP'">
                        <a-popconfirm
                            title="ต้องการยกเลิก sp ?"
                            @confirm="clickcancelsp(record)"
                            @cancel="cancel"
                        >
                            <a-button type="danger">
                                ยกเลิก sp</a-button
                            >
                        </a-popconfirm>
                        <!-- <a-button
                            type="danger"
                            @confirm="clickcancelsp(record)"
                        >
                            ยกเลิก {{ record[0].AdditionalTage }}
                        </a-button> -->
                    </template>
                    <template v-else-if="column.dataIndex === 'ProductImage'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-image :width="100" :src="Item.SlipImageURL" />
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            {{ Item.ProductSellOrder }}
                        </div>
                        <!-- <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            {{Item.ProductSellOrder}}
                        </div> -->
                    </template>
                    <template v-if="column.dataIndex === 'ProductCode'">
                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <!-- <a-button  style="width:100%">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul v-for="items in Util.jsonParse(
                                            Item.ProductCode
                                        )" :key="items">
                                            <li>{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5">{{
                                        Item.ProductCode
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button> -->
                            {{ Item.ProductCode }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <!-- <pre>{{record}}</pre> -->
                        <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <!-- {{
                                        record.find(
                                        item =>
                                        item.ContactStartDefault ==
                                        "true"
                                        )?.ContactName
                                        }} -->
                                        <a-image
                                            :width="200"
                                            :src="
                                                record.find(
                                                    item =>
                                                        item.ContactStartDefault ==
                                                        'true'
                                                ).ContactImageUrl
                                            "
                                        />
                                    </template>
                                    <!-- <pre>{{record}}</pre> -->
                                    <div
                                        v-for="(Item, idx) of record"
                                        :key="`item-${idx}`"
                                        @click="rowselected(record)"
                                    >
                                        {{ Item.ContactName }}
                                        <!-- <div v-show="Item.ContactStartDefault=='true'">
                                            <a-typography-link underline>
                                                {{Item.ContactName}}
                                            </a-typography-link>
                                        </div> -->
                                    </div>
                                </a-tooltip>

                                <a-tag
                                    :color="
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    'true'
                                            )
                                            ?.ContactType?.toUpperCase() ==
                                        'FACEBOOK'
                                            ? 'geekblue'
                                            : 'green'
                                    "
                                >
                                    {{
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    "true"
                                            )
                                            ?.ContactType?.toUpperCase()
                                    }}
                                </a-tag>
                            </a-space>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'PaiddeliveryObj'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text strong v-if="Item.Price">{{
                                    showprice(Item.Price)
                                }}</a-typography-text>
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalShipping'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text
                                    strong
                                    v-if="Item.AdditionalShipping"
                                    >{{
                                        showprice(Item.AdditionalShipping)
                                    }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template> -->
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img
                                class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                            />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span
                            >
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined
                                :style="{ color: 'red', fontSize: '14px' }"
                            />
                            <span :style="{ color: 'red', fontSize: '14px' }"
                                >ยังไม่ได้จัดส่ง</span
                            >
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductStatus'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div>
                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-if="Item.DeliveryStatus == 'cancel'"
                                    >
                                        <span
                                            :style="{
                                                color: 'red',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <div>
                                                <close-square-outlined />
                                                (ยกเลิกการจัดส่ง)
                                            </div>
                                            หมายเหตุ : <i>{{ Item.Desc }}</i>
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-else-if="
                                            Item.DeliveryStatus == 'shipped'
                                        "
                                        ><span
                                            :style="{
                                                color: 'blue',
                                                fontSize: '12px'
                                            }"
                                        >
                                            <check-square-outlined /> จัดส่งแล้ว
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-else-if="
                                            Item.DeliveryStatus == 'canceltrack'
                                        "
                                        ><span
                                            :style="{
                                                color: 'red',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <clock-circle-outlined /> ยกเลิก
                                            Track {{ record.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-else-if="
                                            Item.DeliveryStatus ==
                                                'prepare_pack'
                                        "
                                        ><span
                                            :style="{
                                                color: 'orange',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <clock-circle-outlined />
                                            {{ Item.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-else-if="
                                            Item.DeliveryStatus == 'packed'
                                        "
                                    >
                                        <span
                                            :style="{
                                                color: 'green',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <check-square-outlined />
                                            {{ Item.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text
                                        strong
                                        v-else-if="Item.DeliveryStatus"
                                        >{{
                                            Item.DeliveryStatus
                                        }}</a-typography-text
                                    >
                                    <a-typography-text delete v-else
                                        >ข้อมูลยังไม่ครบถ้วน</a-typography-text
                                    >
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div v-if="Item.ProfessorType && idx == 0">
                                    <a-typography-text
                                        mark
                                        strong
                                        v-if="
                                            JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                        >*รอคุยกับอาจารย์</a-typography-text
                                    >

                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-if="
                                            !JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                        />
                                        ข้อมูลไม่ครบ
                                    </a-typography-text>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Customertel'">
                        <div>
                            <div
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <!-- {{Item.ProductResponsiblePerson}} -->
                                <div
                                    v-if="
                                        Item.ProductResponsiblePerson &&
                                            idx == 0
                                    "
                                    class="flex items-start"
                                >
                                    <div class="flex-auto">
                                        <div class="text-xs text-gray-1-light">
                                            {{
                                                JSON.parse(
                                                    Item.ProductResponsiblePerson
                                                ).login?.shortedName
                                            }}
                                            |
                                            {{
                                                JSON.parse(
                                                    Item.ProductResponsiblePerson
                                                ).login?.role
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
        <FromCreateSell
            v-if="rowselecteddetail.ishowdetail"
            :rowselected="rowselecteddetail"
        ></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import {
    CloseOutlined,
    CloseSquareOutlined,
    CheckSquareOutlined,
    ClockCircleOutlined
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import { formStateStore } from "@/store/storebackoffice";
import Util from "@/helper/Utility.js";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
// import BarcodeGenerator from "./BarcodeGenerator.vue";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "5%"
    },
    // {
    //     title: "รายการสค่าส่งเพิ่ม",
    //     dataIndex: "AdditionalTage",
    //     width: "20%",
    //     onFilter: (value, record) => (record[0].AdditionalTage.toString()).includes(value.toLowerCase()),
    //     onFilterDropdownVisibleChange: visible => {
    //         if (visible) {
    //             setTimeout(() => {
    //                 searchInput.value.focus();
    //             }, 100);
    //         }
    //     },
    // },
    {
        title: "รายการสค่าส่งเพิ่ม",
        dataIndex: "AdditionalTage",
        width: "20%",
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record[0].AdditionalTage.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        }
    },
    {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerOrderSearch",
        width: "15%",
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record[0].CustomerOrder.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        }
    },
    {
        title: "รหัสรายการ",
        dataIndex: "ProductSellOrder",
        width: "20%"
    },
    // {
    //     title: "ชื่อการติดต่อ",
    //     dataIndex: "Contract",
    //     width: "10%"
    // },
    // {
    //     title: "รูป",
    //     dataIndex: "ProductImage",
    //     width: "10%"
    // },
    {
        title: "รหัสสินค้า",
        dataIndex: "ProductCode",
        width: "20%"
    },
    // {
    //     title: "ค่าส่ง",
    //     dataIndex: "PaiddeliveryObj",
    //     width: "10%"
    // }, {
    //     title: "ค่าส่งเพิ่ม",
    //     dataIndex: "AdditionalShipping",
    //     width: "10%"
    // },
    // {
    //     title: "วันที่สั่งสินค้า",
    //     dataIndex: "OrderDate",
    //     sorter: true,
    //     width: "15%"
    // },
    {
        title: "สถานะ",
        dataIndex: "ProductStatus",
        width: "15%"
    },
    // {
    //     title: "สถานะข้อมูล",
    //     dataIndex: "ProductStatusData",
    //     width: "15%"
    // },
    // {
    //     title: "สถานะข้อมูล(1.1)",
    //     dataIndex: "StatusData",
    //     width: "10%"
    // },
    {
        title: "ตรวจสอบ(บัญชี)",
        dataIndex: "AdditionalTageStatus",
        filters: [
            {
                text: "ตรวจสอบแล้วผ่าน",
                value: "ตรวจสอบแล้วผ่าน"
            },
            {
                text: "ไม่ผ่าน",
                value: "ไม่ผ่าน"
            },
            {
                text: "บัญชียังไม่ได้ตรวจสอบ",
                value: "บัญชียังไม่ได้ตรวจสอบ"
            }
        ],
        onFilter: (value, record) =>
            record.find(item => {
                console.log("> : ", value);
                if (item.CheckMessage != null) {
                    var ItemArray = JSON.parse(item.CheckMessage);

                    if (ItemArray?.length > 0) {
                        // Get the last element in the array
                        const lastlist = ItemArray[ItemArray.length - 1];

                        // Check if the "checking" property is set to "checked"
                        if (lastlist.checking === "checked") {
                            return "ตรวจสอบแล้วผ่าน".startsWith(value);
                        } else if (lastlist.checking === "reject") {
                            return "ไม่ผ่าน".startsWith(value);
                        }
                    } else {
                        return "บัญชียังไม่ได้ตรวจสอบ".startsWith(value);
                    }
                } else {
                    if (item.AdditionalShipping <= 0) {
                        return "ไม่มีค่าส่งเพิ่ม".startsWith(value);
                    } else {
                        return "บัญชียังไม่ได้ตรวจสอบ".startsWith(value);
                    }
                }
            }),
        filterSearch: (input, filter) => filter.value.indexOf(input) > -1
    },
    // {
    //     title: "สถานะตรวจสอบ(บัญชี)",
    //     dataIndex: "AccountChecking",
    //     width: "20%"
    // },
    {
        title: "สร้างรายการจัดส่ง",
        dataIndex: "Create",
        width: "20%"
    },
    {
        title: "ยกเลิก sp",
        dataIndex: "CancelSP",
        width: "20%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        CloseSquareOutlined,
        CheckSquareOutlined,
        ClockCircleOutlined,
        TableLoad,
        SearchOutlined,
        // BarcodeGenerator,
        // Drawer,
        CloseOutlined,
        // CheckOutlined,
        // Previewslip,
        FromCreateSell
    },
    props: {
        querybystatus: null
    },
    setup(props) {
        const state = reactive({
            searchText: "",
            searchedColumn: ""
        });
        const searchInput = ref();
        formStateStore.btnsubmit = "ยืนยันสินค้าจัดส่ง";
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "สร้างรายการจัดส่ง(5.3.1)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apigetadditionaldeliveryorder("createdelivery")
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });

                    dataSources.value = groupBy(res.data[0], "CustomerOrder");

                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const warning = (title, content) => {
            Modal.warning({
                title: title,
                content: content
            });
        };
        const rowselected = (record, index) => {
            // if (record[0].DeliveryStatus == 'packed') {

            //     warning('แพ็คสินค้าแล้ว', '')

            //     return
            // }
            formStateStore.rowselected_status = "";
            formStateStore.rowselected_productsellorder =
                record[index].ProductSellOrder;
            formStateStore.productsellorder = record[index].ProductSellOrder;
            console.log(
                " formStateStore.productsellorder :",
                formStateStore.productsellorder
            );

            if (record[0].DeliveryStatus == "cancel") {
                formStateStore.rowselected_status =
                    record[index].DeliveryStatus;
            }

            rowselecteddetail.rowselected = record[index].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            formStateStore.rowselecteddetail = record[index].AdditionalTage;

            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = arraySellOrder => {
            return arraySellOrder.group(
                ({ ProductSellOrder }) => ProductSellOrder
            );
        };
        const showprice = price => {
            if (typeof price == undefined || price == undefined) return "-";
            return price?.toLocaleString() + " บาท";
        };
        const groupDuplicates = array => {
            //clear AdditionalTage Duplicates in one sp
            return array.filter(
                (v, i, a) =>
                    a.findIndex(
                        v2 => v2.AdditionalTage === v.AdditionalTage
                    ) === i
            );
        };

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };

        const isLastMessageChecked = ItemArray => {
            // Check if the array is not empty
            if (ItemArray?.length > 0) {
                // Get the last element in the array
                const lastlist = ItemArray[ItemArray.length - 1];

                // Check if the "checking" property is set to "checked"
                if (lastlist.checking === "checked") {
                    return "ตรวจสอบแล้วผ่าน";
                } else if (lastlist.checking === "reject") {
                    return "ไม่ผ่าน";
                }
            } else {
                return "บัญชียังไม่ได้ตรวจสอบ";
            }
        };

        const findDuplicates = arr => {
            let encountered = {};
            let distinctValues = [];

            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const additionalTage = obj.AdditionalTage;

                if (!encountered[additionalTage]) {
                    distinctValues.push(obj);
                    encountered[additionalTage] = true;
                }
            }

            return distinctValues;
        };

        const clickcancelsp = record => {
            debugger;
            var param = { 
                CustomerOrder: record[0].CustomerOrder,
                AdditionalTage: record[0].AdditionalTage
            };
            console.log(param); 
            Swal.fire({
                title: `ยกเลิก ${record[0].AdditionalTage} กรอก sp ยืนยัน`,
                input: "text",
                inputAttributes: {
                    autocapitalize: "off"
                },
                showCancelButton: true,
                confirmButtonText: "ตกลง",
                showLoaderOnConfirm: true,
                preConfirm: async login => {
                    try {
                        if (login == record[0].AdditionalTage) {
                            start_cancel_sp(param);
                        } else {
                            Swal.showValidationMessage(
                                `กรอกข้อมูลไม่ตรง ${record[0].AdditionalTage}`
                            );
                        }
                    } catch (error) { 
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "สำเร็จ!",
                        icon: "success"
                    });
                }
            });
        };

        const start_cancel_sp = param => {
            return BzbsUser.apicancelsp(param)
                .then(res => {
                    window.location.reload();
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            clickcancelsp,
            findDuplicates,
            isLastMessageChecked,
            ...toRefs(state),
            searchInput,
            handleSearch,
            handleReset,
            groupDuplicates,
            showprice,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            Util
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
