<template>
    <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 24 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
    >
        <div>
            <a-divider orientation="left">รายการ-ช่องทางการติดต่อ</a-divider>
            <!-- <pre v-if="formState.isshowjason">{{ dynamicValidateForm }}</pre> -->
            <a-row
                class="p-1"
                type="flex"
                v-for="(sight, index) in dynamicValidateForm.sights"
                :key="sight.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-col class="p-1" flex="1 1 10%" :order="1">
                    <a-form-item
                        :name="['sights', index, 'contacttype']"
                        :label="'ช่องทางที่ ' + (index + 1)"
                    >
                        <a-select
                            v-model:value="sight.contacttype"
                            placeholder="ช่องทางการติดต่อ"
                        >
                            <a-select-option value="Line">Line</a-select-option>
                            <a-select-option value="Facebook"
                                >Facebook</a-select-option
                            >
                            <a-select-option value="Instargram"
                                >Instargram</a-select-option
                            >
                            <a-select-option value="Tiktok"
                                >Tiktok</a-select-option
                            >
                            <a-select-option value="Phone"
                                >Phone</a-select-option
                            >
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 10%" :order="2">
                    <a-form-item
                        label="ชื่อการติดต่อ"
                        :name="['sights', index, 'contactname']"
                    > 
                        <a-input v-model:value="sight.contactname"/>
                    </a-form-item>
                </a-col>

                <a-col class="p-1" flex="1 1 10%" :order="3">
                    <a-form-item
                        :name="['sights', index, 'contactimage']"
                        label="แนบรูป"
                    >
                        <Uploadfile
                            :contactimageurl="sight.contactimageurl"
                            :sizeupimage="1"
                            @fileList="sight.contactimage = $event"
                        ></Uploadfile>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 10%" :order="4">
                    <a-form-item
                        label="หมายเหตุ"
                        :name="['sights', index, 'introduction']"
                    >
                        <a-textarea v-model:value="sight.introduction" />
                    </a-form-item>
                </a-col>

                <a-popconfirm
                    v-if="index != 0"
                    title="ต้องการโหลด?"
                    @confirm="removeSight(sight)"
                    @cancel="cancel"
                >
                    <MinusCircleOutlined />
                </a-popconfirm>
            </a-row>
            <a-form-item>
                <a-button
                    type="primary"
                    :style="'width:150px'"
                    block
                    @click="addSight"
                >
                    <PlusOutlined />
                    เพิ่มรายชื่อ
                </a-button>
            </a-form-item>

            <a-form-item>
                <a-button
                    style="float: right;"
                    type="primary"
                    html-type="submit"
                    >บันทึก</a-button
                >
            </a-form-item>
        </div>
    </a-form>
</template>
<script>
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch } from "vue";
import Uploadfile from "./Uploadfile.vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Baseinput from "../form/Baseinput.vue";
export default defineComponent({
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        Uploadfile
        // Baseinput,
    },
    props: {
        formStates: null
    },
    setup(props, { emit }) {
        const formState = reactive(props.formStates);
        const formRef = ref();
        const dynamicValidateForm = reactive({
            sights: [...props.formStates.getcontactlist],
            area: undefined
        });
        watch(
            () => dynamicValidateForm.area,
            () => {
                dynamicValidateForm.sights = [];
            }
        );

        const removeSight = async item => {
            let index = dynamicValidateForm.sights.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.sights.splice(index, 1);
            }

            var param = {
                CustomerOrder: item.customerorder,
                Key: item.key
            };
            await BzbsUser.apidelcontact(param)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        var i = 1;
        const addSight = () => {
            dynamicValidateForm.sights.push({
                value: undefined,
                price: undefined,
                introduction: "",
                id: Date.now(),
                key: i
            });
            i++;
        };

        const onFinish = values => {
            console.log("formState :", dynamicValidateForm);
            emit("update:value", dynamicValidateForm);
            emit("closemodal", false);
            formItemContext.onFieldChange();
            console.log("Received values of form:", values);
            console.log("dynamicValidateForm:", dynamicValidateForm);
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            console.log("formState :", formState);
            emit("update:value", formState);
            formItemContext.onFieldChange();
        };
        const onchangeProductsTypeData = e => {
            console.log(e);

            formState.userproduct = e;
        };
        const cancel = e => {
            console.log(e);
            message.error("Click on No");
        };
      
        return {
            cancel,
            onchangeProductsTypeData,
            locale,
            formRef,
            dynamicValidateForm,
            onFinish,
            removeSight,
            addSight,
            formState,
            fromchange,
            onFinishFailed
        };
    }
});
</script>
