<template>
    <!-- <a-button
        type="primary"
        style="margin-right: 8px"
        @click="showDrawer('default')"
    >
        Open Default Size (378px)
    </a-button> -->
    <a-button type="dash" block danger @click="showDrawer('large')"
        >กดดู ข้อมูลสลิป</a-button
    >
    <a-drawer
        title="ข้อมูลสลิปโอนเงิน"
        :size="size"
        :visible="visible"
        @close="onClose"
    >
        <div class="text-center">
            <a-typography-title :level="4">ข้อมูลสลิป </a-typography-title>
            <div v-for="(items, k) in slip" :key="k">
                <a-image :width="500" :src="items" />
            </div>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    props: {
        slip: {
            type: Object
        }
    },
    setup() {
        const visible = ref(false);
        const size = ref("default");

        const showDrawer = val => {
            size.value = val;
            visible.value = true;
        };

        const onClose = () => {
            visible.value = false;
        };

        return {
            visible,
            size,
            showDrawer,
            onClose
        };
    }
});
</script>
