import { reactive } from "vue";
import CacheHelper from "@/helper/CacheHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import { useRoute } from "vue-router";

var Cache = new CacheHelper(ConfigManager.getCacheType());
const state = reactive({
    createproduct: {},
    allproduct: [],
    loading: {
        title: "",
        ishow: false,
        percent: 0
    },
    profile: Cache.getCache("profile"),
    config: {
        isshow_btn_save: true
    },
    startcallsave:0,
    startcallselllist:0,
});

const methods = {
    setproduct: function(items) {
        state.createproduct = items;
    },
    setallproduct: function(items) {
        console.log(items);
        state.allproduct.push(items);
    },
    clearBreadcrumb: function() {
        state.breadcrumb = [];
    },
    setloading: function(fact) {
        state.loading.ishow = fact;
    },
    checkgrantbtnsave: function() {
        var role = state.profile.login.role;
        //ถ้าเป็นพนักงานบัญชี จะกดบันทึก เปลี่ยนแปลงข้อมูลไม่ได้

        if (role == "account") return false;

        const route = useRoute();
        if (route.path == "/delivery") {
            return false;
        } else if (route.path == "/createdeliverylist") {
            return false;
        } else if (route.path == "/manageorder") {
            return false;
        } else {
            return true;
        }
    },
    checkgreendownload: function() {
        var role = state.profile.login.role;
        const route = useRoute();
        if (route.path == "/selllist" && role != "account") {
            return true;
        }
        else{
            return false
        }
    },
    checkgrantccountonly: function() {
        try {
            var role = state.profile.login.role;
            //ถ้าเป็นพนักงานบัญชี จะกดบันทึก เปลี่ยนแปลงข้อมูลไม่ได้
            return role == "account" ? false : true;
        } catch (error) {
            window.location = '/อุอากะสะ'
        }
      
    }, 
    checkgrantsalepage: function() {
         
        var role = state.profile.login.role;
        console.log("role : ",role)
        //ถ้าเป็นพนักงานบัญชี จะกดบันทึก เปลี่ยนแปลงข้อมูลไม่ได้
        return role == "salepage" ? false : true;
    }, 
    checkgrantcommission: function() {
         
        var role = state.profile.login.role;
        console.log("role : ",role)
        //ถ้าเป็นพนักงานบัญชี จะกดบันทึก เปลี่ยนแปลงข้อมูลไม่ได้
        return role == "commission" ? false : true;
    }, 
    isnoaccount: function() {
        var role = state.profile.login.role; 
        return role == "account" ? true : false;
    },
    isadmin: function() {
        var role = state.profile.login.role; 
        return role == "admin" ? true : false;
    },
    isofficer: function() {
        var role = state.profile.login.role; 
        return role == "officer"|| role == 'officersupport' ? true : false;
    },
    isofficersupport: function() {
        var role = state.profile.login.role; 
        return role == "officersupport" ? true : false;
    },
    issupport: function() {
        var role = state.profile.login.role; 
        return role == "support" ? true : false;
    },
    checkgrantbtnsaveDelivery: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            return false;
        } else if (route.path == "/createdeliverylist") {
            return true;
        } else if (route.path == "/packproduct") {
            return true;
        } else {
            return true;
        }
    },
    checkshowscanpacking: function() {
        const route = useRoute();
        if (route.path == "/packproduct") {
            return true;
        } else {
            return false;
        }
    },

    checkGrantProductListByCustomerCode: function() {
        const route = useRoute();
        //ถ้า กด มาจากหน้าลูกค้า
        if (route.query.customercode) return false;
        if (route.path == "/delivery") {
            state.config.isshow_btn_save = false;
            return false;
        } else if (route.path == "/createdeliverylist") {
            return false;
        } else {
            return true;
        }
    },
    checkGrantMoreDelivery: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            state.config.isshow_btn_save = true;
            return true;
        } else {
            return false;
        }
    },
    checkGrantAddressFrom: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            return false;
        } else {
            return true;
        }
    },
    checkgrantaccount: function() {
        const route = useRoute();
        if (route.path == "/delivery") return false;

        var role = state.profile.login.role;
        return role == "account" || role == "god" || role == "admin" || role == "support" || role == "officer" || role == "officersupport";
        // if (route.path == "/delivery") {
        //     return false;
        // } else {
        //     return true;
        // }
    },
    checkGrantProductFrom: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            state.config.isshow_btn_save = true;
            return false;
        } else {
            return true;
        }
    },
    checkGrantMoreDeliveryDetail: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            state.config.isshow_btn_save = true;
            return true;
        } else {
            return false;
        }
    },
    checkGrantCustomerDesc: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            return false;
        } else {
            return true;
        }
    },
    checkGrantOrderNumber: function() {
        const route = useRoute();
        if (route.path == "/delivery") {
            return false;
        } else {
            return true;
        }
    },
    checkgrantCreateDeliveryDetailCustomer: function() {
        const route = useRoute();
        if (route.path == "/createdeliverylist") {
            return true;
        } else {
            return false;
        }
    },
    checkgrantCustomerProfile: function() {
        const route = useRoute();
        if (route.path == "/createdeliverylist") {
            return false;
        } else {
            return true;
        }
    },
    callsave: function () {
        console.log("SAVE 99999")
        state.startcallsave++
    }, 
    callselllist: function () {
        console.log("startcallselllist 99999")
        state.startcallselllist++
    }, 
    
};

export default {
    state: state,
    methods: methods
};
