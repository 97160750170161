<template> 
  <a-form ref="formRef" name="dynamic_form_nest_item" :model="dynamicValidateForm" @change="onFinish">
    <a-form-item name="type" :rules="[{ required: true, message: 'Missing type' }]">
      <a-select v-model:value="dynamicValidateForm.type" :options="types" />
    </a-form-item>
    <a-space v-for="(sight, index) in dynamicValidateForm.items" :key="sight.id"
      style="display: flex; margin-bottom: 8px" align="baseline">
      <a-form-item :name="['items', index, 'value']" label="Pixcel ID" :rules="{
        required: true,
        message: 'Missing sight',
      }">
        <a-input v-model:value="sight.pixcelid" />
      </a-form-item>
      <a-form-item label="Pixcel Token" :name="['items', index, 'price']" :rules="{
        required: true,
        message: 'Missing price',
      }">
        <a-input v-model:value="sight.token" />
      </a-form-item>
      <MinusCircleOutlined @click="removeSight(sight)" />
    </a-space>
    <a-form-item>
      <a-button type="dashed" block @click="addSight">
        <PlusOutlined />
        Add items
      </a-button>
    </a-form-item>
    <!-- <a-form-item>
      <a-button type="primary" html-type="submit">Submit</a-button>
    </a-form-item> -->
  </a-form>
</template>
<script>
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, watch } from 'vue';
export default defineComponent({
  components: {
    MinusCircleOutlined,
    PlusOutlined,
  },
  props: ['facebookpixel'],
  setup(props, { emit }) { 
    const types = [{
      label: 'facebook',
      value: 'facebook',
    }];
    const items = {
      facebook: ['Tiananmen', 'Great Wall'],
      Shanghai: ['Oriental Pearl', 'The Bund'],
    };
    const formRef = ref();
    const dynamicValidateForm = reactive({
      items: [],
      type: undefined,
    });
    if (props?.facebookpixel?.items) {
      dynamicValidateForm.type = 'facebook' 
      props.facebookpixel?.items.forEach(element => {
        console.log(element)
        dynamicValidateForm.items.push(element) 
      }); 
    }

    if (JSON.parse(props?.facebookpixel || '{}').items) {
        dynamicValidateForm.type = 'facebook' 
      JSON.parse(props?.facebookpixel || '{}').items.forEach(element => {
        console.log(element)
        dynamicValidateForm.items.push(element)
        // dynamicValidateForm.items.push(element)
      }); 
    }

    watch(() => dynamicValidateForm.type, () => {
      dynamicValidateForm.items = [];
    });

    const removeSight = item => {
      let index = dynamicValidateForm.items.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.items.splice(index, 1);
      }
    };

    const addSight = () => {
      dynamicValidateForm.items.push({
        pixcelid: undefined,
        toekn: undefined,
        id: Date.now(),
      });
    };

    const onFinish = values => {
      emit("onfacebook", dynamicValidateForm)
      console.log('Received values of form:', values);
      console.log('dynamicValidateForm:', dynamicValidateForm);
    };

    return {
      formRef,
      dynamicValidateForm,
      onFinish,
      removeSight,
      addSight,
      types,
      items,
    };
  },

});
</script>