<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl p-3">
                <div class="text-center text-xl pt-3">{{title}}</div>
                <FormShippingAddress ref="form-shipping"></FormShippingAddress>
                <div class="w-1/2 m-auto">
                    <button class="w-full bg-gray-300 rounded-md py-1 mb-2" @click="hide()">Cancel</button>
                    <button class="w-full bg-blue-600 text-white rounded-md py-1" @click="clickOk()">
                        {{btnOk}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
//component
import FormShippingAddress from "./component/FormShippingAddress.vue";
//mixin
import LocaleMixin from "@/mixin/LocaleMixin.js";
//service
import { RedeemShippingAddressConfigBuilder } from "@/core/Redeem/config/RedeemConfigBuilder.js";

export default {
    name: "ShippingAddressModal",
    mixins: [LocaleMixin],
    components: {
        FormShippingAddress
    },
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,

            title: '',
            btnOk: '',
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function(config) {
            this.title = config.title;

            if (Util.isEmptyStr(this.btnOk)) {
                this.btnOk = this.lbl.default.ok;
            }

            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickOk: function () {
            // this.hide();
            // this.controller.sendCallbackOk();
            this.submitAddress();
        },

        submitAddress: function() {
            let address = this.$refs['form-shipping'].submitForm();
            // Util.log('shipping address', address);
            if (address != null) {
                this.onSaveShippingAddress(address)
            }
        },

        onSaveShippingAddress: function(data) {
            // console.log(data);
            var builder = new RedeemShippingAddressConfigBuilder();
            builder.setAddress(data.getFullAddressText())
            builder.setContactNumber(data.contactNumber);
            builder.setShippingFirstName(data.firstname);
            builder.setShippingLastName(data.lastname);
            builder.setZipcode(data.zipcode);

            // var redeemShippingAddressConfig = builder.create();
            this.hide();
            this.controller.sendCallbackSaveAddress(builder);
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/base.scss";
    @import "@/style/modal.scss";

    .modal {
        @extend %modal;

        .modal-content {
            width: 400px;

            @include mobile {
                width: 100%;
                padding: 16px;
            }
        }
    }
</style>
