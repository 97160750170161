<template>
    <div
        class="tab-items-container flex items-stretch flex-row flex-auto flex-nowrap overflow-x-scroll hide-scroll"
        :class="{ 'h-full': fullHeight, 'min-h-full': minHeightFull }"
        ref="tab-items-container"
    >
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'TabItemsNoSplideJs',
    props: {
        id: {
            type: String,
            required: true
        },
        tab: {
            type: [String, Number],
            default: 0
        },
        autoHeight: {
            type: Boolean,
            default: true
        },
        fullHeight: {
            type: Boolean,
            default: false
        },
        minHeightFull: { type: Boolean },
        smoothMode: { type: Boolean }
    },
    components: {},
    mixins: [],
    emits: [],
    data: function () { return {} },
    computed: {},
    watch: {
        tab: function (val) {
            this.next(val)
        }
    },
    created: function () { },
    mounted: function () {
        this.next()
    },
    methods: {
        next() {
            // console.log(this.tab)
            /**@type HTMLElement */
            const el = this.$refs["tab-items-container"]
            // console.log(this.tab * el.offsetWidth)
            el.scrollTo({
                left: this.tab * el.offsetWidth,
                behavior: this.smoothMode ? "smooth" : "auto",
            })
        }
    }
}
</script>

