<template>
    <div v-if="dataSources" class="relative p-4 wrapper-track">

        <div style="pointer-events: none;">
            <a-image
            :preview="{ visible: false }"
            class="image-center banner"
            alt="มหายันต์ลูกกอ"
            src="https://namahayan.blob.core.windows.net/assets/banner.webp"
            ></a-image>
        </div>
        <div class="text-lg font-bold text-center" style="font-size:20px">{{dataSources?.recordset[0]?.SystemCheckTabActive_1?'หมดเวลา':''}}</div>
        <div class="text-lg font-bold text-center" style="font-size:14px">
            ลูกค้าลงข้อมูล "วันเดือนปีเกิด" ในผืนผ้ายันต์
            <pre style="color: gray;font-size:12px">
({{ dataSources?.recordset[0]?.CustomerOrder }})</pre
            >
        </div>
        <div class="p-2 text-center">
            <pre><u :style="{ fontSize: '15px' }">{{dataSources?.recordset[0]?.Category_1}} {{dataSources?.recordset[0]?.Category_2}} กรอกข้อมูลได้ {{numberOfPeople}} คน</u></pre>
        </div>


     <div class="text-center text-lg">
        {{dataSources?.recordset[0]?.SystemCheckTabActive_1?'':'รอแอดมินลงระบบถึงจะลงวันเกิดได้'}}
     </div>
        <a-form
        :style="{ fontSize: '20px' }"
        :class="{'disabled_content':dataSources?.recordset[0]?.SystemCheckTabActive_1}"
            ref="formRef"
            name="dynamic_form_nest_item"
            :model="dynamicValidateForm"
            @finish="onFinish"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
        >
            <a-space
                v-for="(user, index) in dynamicValidateForm.users"
                :key="user.id"
                style="margin-bottom: 8px"
            >
                <a-row type="flex" class="bg-campaign-user mb-4">
                    <a-col :span="24">
                        <a-row type="flex" class="p-2">
                            <a-col :flex="3"
                                >(กรอกข้อมูลเอง)
                                <u class="pr-3">สมาชิกคนที่ {{ index + 1 }}</u>
                                <div style="width: 170px">
                                    <a-progress
                                        v-if="
                                            dynamicValidateForm.users.length ==
                                                numberOfPeople
                                        "
                                        :percent="100"
                                        style="font-size: 16px"
                                        :format="
                                            percent =>
                                                ` ครบแล้ว ${numberOfPeople} คน`
                                        "
                                        size="small"
                                    />
                                    <a-progress
                                        v-else-if="index + 1 < numberOfPeople"
                                        :percent="percent_current(index + 1)"
                                        :format="
                                            percent =>
                                                `${index +
                                                    1}/${numberOfPeople} คน`
                                        "
                                        size="small"
                                        status="active"
                                    />
                                </div>
                            </a-col>
                            <a-col :flex="2" style="text-align: right;"
                                ><MinusCircleOutlined @click="removeUser(user)"
                            /></a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'name']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอกชื่อ'
                            }"
                        >
                            <a-input
                                :style="{ width: '100%' }"
                                class="color-input"
                                v-model:value="user.name"
                                placeholder="ชื่อ"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'lastname']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอกนามสกุล'
                            }"
                        >
                            <a-input
                                class="color-input"
                                v-model:value="user.lastname"
                                placeholder="นามสกุล"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'nickname']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอกชื่อเล่น'
                            }"
                        >
                            <a-input
                                class="color-input"
                                v-model:value="user.nickname"
                                placeholder="ชื่อเล่น"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'idcard']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอก เลขบัตรประชาชน'
                            }"
                        >
                            <a-input
                                class="color-input"
                                v-model:value="user.idcard"
                                placeholder="เลขบัตรประชาชน"
                                @input="handleInput(index, $event.target.value)"
                            />
                            <a-typography-text class="pt-1" type="warning">{{
                                user.idcard.length < 13 &&
                                user.idcard.length >= 1
                                    ? "กรอกเลขบัตรประชาชน ยังไม่ครบ"
                                    : ""
                            }}</a-typography-text>
                            <!-- <span class="text-green">{{user.idcard.length>=13 ?'เลขบัตรประชาชนครบแล้ว':''}}</span> -->
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'typebirthday']"
                            :rules="{
                                required: false,
                                message: 'เลือกประเภท'
                            }"
                        >
                            <a-radio-group v-model:value="user.typebirthday">
                                <a-radio value="2">พ.ศ.</a-radio>
                                <a-radio value="3">ค.ศ</a-radio>
                                <a-radio value="4">อื่นๆ / ทราบแค่ปีเกิด</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            v-if="user.typebirthday == 2"
                            :name="['users', index, 'birthday']"
                            :rules="{
                                required: false,
                                message: 'กรุณากรอกวันเกิด'
                            }"
                        >
                            <DatePickerDropdown
                                v-model:inputValue="user.birthday"
                                :errorInfo="errorInfo[6]"
                                :isFirstSave="true"
                                :isShowErrorLbl="true"
                                :inputLbl="lbl.profile.birthdate"
                                :isISO_8601="true"
                                @onDateChange="
                                    calculateAgeDropdown(user.birthday, index)
                                "
                            >
                            </DatePickerDropdown>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item
                            v-if="user.typebirthday == 3"
                            class=""
                            :name="['users', index, 'birthday']"
                            :label="'วันเกิด'"
                        >
                            <a-date-picker
                                :disabled-date="disabledFutureDates"
                                v-model:value="user.birthday"
                                value-format="YYYY-MM-DD"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            v-if="user.typebirthday == 4"
                            class=""
                            :name="['users', index, 'birthday']"
                            :label="'ปีเกิด'"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                v-model:value="user.yearbirthday"
                                placeholder="ปีเกิด"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <!-- <a-form-item
                            v-if="
                                user.typebirthday != 1 && user.typebirthday != 4
                            "
                            :name="['users', index, 'birthday']"
                        >
                            <span
                                v-if="daybirthrecommend[index]"
                                class="flex p-2"
                            >
                                <u class="pr-3">แนะนำวันเกิดวัน </u>
                                <a-typography-paragraph
                                    :copyable="{ tooltip: false }"
                                >
                                    วัน{{ daybirthrecommend[index] }}
                                </a-typography-paragraph>
                            </span>
                            <a-input
                                :style="{ color: 'green' }"
                                placeholder="เกิดวัน"
                                v-model:value="user.birthdayname"
                            />
                        </a-form-item> -->
                        <a-form-item
                            v-if="
                                user.typebirthday != 1 && user.typebirthday != 4
                            "
                            name="select"
                            label="เกิดวัน"
                            :name="['users', index, 'birthday']"
                            :rules="[
                                {
                                    required: false,
                                    message: 'เลือกเกิดวัน'
                                }
                            ]"
                        >
                            <a-select
                                v-model:value="user.birthdayname"
                                placeholder="เกิดวัน"
                            >
                                <a-select-option value="ไม่ทราบ"
                                    >ไม่ทราบ</a-select-option
                                >
                                <a-select-option value="วันจันทร์"
                                    >วันจันทร์</a-select-option
                                >
                                <a-select-option value="วันอังคาร"
                                    >วันอังคาร</a-select-option
                                >
                                <a-select-option value="วันพุธ"
                                    >วันพุธ</a-select-option
                                >
                                <a-select-option value="วันพฤหัสบดี"
                                    >วันพฤหัสบดี</a-select-option
                                >
                                <a-select-option value="วันศุกร์"
                                    >วันศุกร์</a-select-option
                                >
                                <a-select-option value="วันเสาร์"
                                    >วันเสาร์</a-select-option
                                >
                                <a-select-option value="วันอาทิตย์"
                                    >วันอาทิตย์</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', index, 'birthday']"
                            :label="'อายุ'"
                            v-if="
                                user.typebirthday != 1 && user.typebirthday != 4
                            "
                        >
                            <a-input-number
                                class="text-center"
                                :disabled="isdisabled(user, index)"
                                v-model:value="user.age"
                                :min="0"
                                :max="100"
                                placeholder="อายุ"
                                @change="updatelistbristname"
                            />
                            ปี
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item
                            :name="['users', 'career']"
                            :label="'อาชีพ'"
                        >
                            <Career
                                v-model:inputValue="user.career"
                                :initcareer="user.career"
                            ></Career>
                        </a-form-item>
                    </a-col>
                </a-row>
                <!-- <a-row>
                    <a-col :span="24">
                        <MinusCircleOutlined @click="removeUser(user)" />
                    </a-col>
                </a-row> -->
            </a-space>
            <a-row>
                <a-col :span="24" style="margin:auto">
                    <a-button type="primary" v-show="numberOfPeople > 1" block @click="addUser">
                        <PlusOutlined />
                        เพิ่มสมาชิก
                    </a-button>
                </a-col>
            </a-row>
            <a-row v-if="dataSources?.recordset[0]?.SystemCheckTabActive_1!=null">
                <a-col :span="24">
                    <div class="pt-4">
                        <a-button
                            type="primary"
                            class="btn_accept "
                            html-type="submit"
                            >ตกลงบันทึกข้อมูล</a-button
                        >
                    </div>
                </a-col>
            </a-row>
        </a-form>
    </div>

    <!-- <pre>{{dynamicValidateForm.users}}</pre> -->
</template>

<script>
import TableLoad from "@/components/Skeleton/TableLoad";

import {
    onErrorCaptured,
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs
} from "vue";
import {
    UserOutlined,
    SolutionOutlined,
    LoadingOutlined,
    SmileOutlined
} from "@ant-design/icons-vue";
import {
    GetProductBirthday,
    ProductBirthday
} from "@/core/Create/model/CreateModel";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility";
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import { Form, Input, Button } from "ant-design-vue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import Locale from "@/helper/locale/Locale.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import { FormHelper } from "@/helper/FormHelper.js";
import DatePickerDropdown from "@/components/Input/label/DatePickerDropdown.vue";
import Career from "@/components/Input/label/Career.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default defineComponent({
    mixins: [LocaleMixin],
    components: {
        DatePickerDropdown,
        TableLoad,
        UserOutlined,
        SolutionOutlined,
        LoadingOutlined,
        SmileOutlined,
        MinusCircleOutlined,
        PlusOutlined,
        Career
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        var customer_so = route.query.so;
        var customer_cuscode = route.query.cuscode;
        var customer_pk = route.query.pk;
        const ispaymentsuccess = ref(false);
        const errorOccurred = ref(false);

        onErrorCaptured((error, vm, info) => {
            // Handle the error here
            console.error("Error occurred during rendering:", error);
            console.error("Vue instance:", vm);
            console.error("Additional information:", info);

            // Update the errorOccurred flag
            errorOccurred.value = true;

            // Prevent the error from propagating further
            return false;
        });

        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        const track = ref("-");
        const cuscode = ref("-");
        const so = ref("-");
        const pk = ref("-");
        const current_status = ref(0);

        const searchInput = ref();
        const dataSources = ref(null);

        const getgreencard = ref([]);
        onMounted(() => {
            if (urlParams.has("so")) {
                so.value = urlParams.get("so") || "-";
            }
            if (urlParams.has("cuscode")) {
                cuscode.value = urlParams.get("cuscode") || "-";
            }
            if (urlParams.has("track")) {
                track.value = urlParams.get("track") || "-";
            }
            if (urlParams.has("pk")) {
                pk.value = urlParams.get("pk") || "-";
            }

            querycustomer();
            getimagegreen();
        });

        const numberOfPeople = ref(0);
        const getimagegreen = () => {
            return BzbsUser.getimagegreen(so.value)
                .then(res => {
                    getgreencard.value = res.data.find(
                        item =>
                            item.ProductKey == pk.value &&
                            item.ProductCustomerOrder == cuscode.value
                    );

                    let Shotness = getgreencard.value?.Shotness;

                    // ลบวงเล็บและตัวเลขออกจาก string
                    Shotness = Shotness.replace(/[()]/g, "");

                    if (
                        Shotness === "10" ||
                        Shotness === "20" ||
                        Shotness === "30" ||
                        Shotness === "40" ||
                        Shotness === "50"
                    ) {
                        // 10, 20, 30, 40, 50: 1 คน
                        numberOfPeople.value = 1;
                    } else if (Shotness === "789") {
                        // 789: 5 คน
                        numberOfPeople.value = 5;
                    } else if (Shotness === "88") {
                        // 88: 8 คน
                        numberOfPeople.value = 8;
                    } else if (Shotness === "100") {
                        // 100: 10 คน
                        numberOfPeople.value = 10;
                    } else {
                        // ค่าอื่นๆ
                        numberOfPeople.value = 0; // หรือสามารถกำหนดเป็นค่าอื่นๆ ตามที่ต้องการ
                    }
                    // alert(`จำนวนคน: ${numberOfPeople.value} คน`)
                    // console.log(`จำนวนคน: ${numberOfPeople.value} คน`);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const percent_current = size => {
            // คำนวณเปอร์เซ็นต์ของ Progress Bar โดยคิดเป็นจำนวนคนที่ได้รับหารด้วยจำนวนทั้งหมด
            return (size / numberOfPeople.value) * 100; // ค่าสูงสุดของ numberOfPeople เป็น 10
        };
        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("L");
        };

        const querycustomer = () => {
            return BzbsUser.getconsumerhistory(
                track.value,
                cuscode.value,
                so.value,
                pk.value
            )
                .then(res => {
                    dataSources.value = res.data;

                    checkstatuspayment();
                    // querycustomerdetail(res.data[0].CustomerOrder)
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const checkstatuspayment = () => {
            var hasNotsuccess = false;

            dataSources.value.payment.forEach(element => {
                if (element.Checking != "checked") {
                    hasNotsuccess = true;
                }
            });

            if (hasNotsuccess) {
                ispaymentsuccess.value = false;
            } else {
                ispaymentsuccess.value = true;
            }
        };

        const getdatagreen = json => {
            return JSON.parse(JSON.stringify(json));
        };

        const gototrack = paramtrack => {
            if (urlParams.has("inter")) {
                window.location =
                    "/consumertrackinginter?track=" +
                    paramtrack +
                    "&cuscode=" +
                    cuscode.value +
                    "&inter=true";
            } else {
                window.location = "/consumertracking?cuscode=" + cuscode.value;
            }
        };

        const formRef = ref();
        const dynamicValidateForm = reactive({
            users: []
        });
        const removeUser = item => {
            let index = dynamicValidateForm.users.indexOf(item);

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons
                .fire({
                    text: "ต้องการลบใช่หรือไม่?",
                    showCancelButton: true,
                    confirmButtonText: "ตกลง",
                    cancelButtonText: "ปิด",
                    reverseButtons: true
                })
                .then(result => {
                    if (result.isConfirmed) {
                        var params = {
                            ProductSellOrder: customer_so,
                            ProductKey: customer_pk,
                            CustomerOrder: customer_cuscode
                        };
                        BzbsUser.apidelete_consumer_productbirthday(params)
                            .then(res => {
                                console.log(res);
                                Swal.fire({
                                    title: "ลบสำเร็จ!",
                                    icon: "success",
                                    cancelButtonText: "ปิด"
                                });
                            })
                            .catch(error => {
                                console.log(error);
                            });

                        if (index !== -1) {
                            dynamicValidateForm.users.splice(index, 1);
                        }
                    }
                });
        };
        const addUser = () => {
            if (dynamicValidateForm.users.length == numberOfPeople.value) {
                Swal.fire({
                    text: `ลงข้อมูลวันเกิดได้  ${numberOfPeople.value} คน`,
                    confirmButtonText: "ปิด"
                });
                return;
            }

            dynamicValidateForm.users.push({
                typebirthday: "2",
                name: "",
                lastname: "",
                nickname: "",
                idcard: "",
                gender: "", // Initialize gender field
                id: Date.now()
            });
        };
        const onFinish = values => {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons
                .fire({
                    text: "ยืนยันการกรอกข้อมูล",
                    showCancelButton: true,
                    confirmButtonText: "ตกลง",
                    cancelButtonText: "ปิด",
                    reverseButtons: true
                })
                .then(result => {
                    if (result.isConfirmed) {
                        update_birth_data();
                    }
                });
        };
        const daybirthrecommend = ref([]);
        const daybirth = (birthday, index) => {
            let lbl = Locale.getLbl();
            let day = lbl.date.date_full;
            var daycal = day[moment(birthday).format("d") - 1];
            daybirth_recommend(birthday, index);
        };
        const daybirth_recommend = (birthday, index) => {
            let lbl = Locale.getLbl();
            let day = lbl.date.date_full;
            var daycal = day[moment(birthday).format("d") - 1];
            if (moment(birthday).format("d") == 0) {
                daycal = day[6];
            }
            if (dynamicValidateForm.users[index]) {
                daybirthrecommend.value[index] = daycal;
            }
        };

        const calculateAgeDropdown = (sights, index) => {
            daybirth(sights, index);

            var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
                return Math.abs(ageDate.getUTCFullYear() - 1970);
            } else {
                return 0;
            }
        };
        const isdisabled = (sights, index) => {
            if (sights.typebirthday == 1) {
                delete sights.birthday;
                delete sights.age;
                return true;
            } else {
                sights.age = calculateAgeDropdown(sights, index);
            }
        };

        const initproductbirthday = () => {
            // console.log("formStateStoreCreate", formStateStoreCreate);
            // if (iscreate.value) {
            //     formStateStoreCreate?.listbirstname?.forEach(
            //         (element, index) => {
            //             if (index == fact_is_edit.value.rowkey) {
            //                 dynamicValidateForm.sights = element.sights;
            //             }
            //         }
            //     );

            //     console.log(
            //         "formStateStoreCreate.listbirstname ",
            //         formStateStoreCreate.listbirstname
            //     );
            //     console.log(
            //         "formStateStore.listbirstname ",
            //         formStateStore.listbirstname
            //     );
            //     return;
            // }

            var customercode = customer_cuscode;
            var productkey = customer_pk;
            var productsellorder = customer_so;
            BzbsUser.apigetproductbirthday(
                customercode,
                productkey,
                productsellorder
            )
                .then(res => {
                    const temp_obj = [];
                    res.data.forEach(element => {
                        var obj = new GetProductBirthday(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    dynamicValidateForm.users = temp_obj || [];
                })
                .catch(error => {
                    console.log(error);
                });
        };
        initproductbirthday();

        const update_birth_data = async () => {
            const line_profile_info = JSON.parse(
                localStorage.getItem("profile") || "[]"
            );
            var count_index = 0;
            for await (let element of dynamicValidateForm.users) {
                element.productsellorder = customer_so;
                element.customerorder = customer_cuscode;
                element.productkey = customer_pk;

                element.userId = line_profile_info.userId;
                element.pictureUrl = line_profile_info.pictureUrl;
                element.displayName = line_profile_info.displayName;
                element.StatusMessage = line_profile_info.StatusMessage;

                let params = new ProductBirthday(element);

                await BzbsUser.apiupdateproductbirthday(params)
                    .then(res => {
                        console.log(res);
                        if (
                            dynamicValidateForm.users.length - 1 ==
                            count_index
                        ) {
                            Swal.fire({
                                title: "สำเร็จ!",
                                icon: "success",
                                cancelButtonText: "ปิด"
                            });
                        }

                        count_index = count_index + 1;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const disabledFutureDates = date => {
            return date && date.valueOf() > Date.now();
        };

        const formattedValue = ref("");

        const handleInput = (index, value) => {
            let formattedValue = value.replace(/[^\d-]/g, ""); // เอาอักขระที่ไม่ใช่ตัวเลขหรือเครื่องหมาย '-' ออก
            // กำหนดให้มีความยาวไม่เกิน 13 ตัวอักษร
            formattedValue = formattedValue.slice(0, 13);
            if (formattedValue.length > 0 && formattedValue[0] === "-") {
                // ตรวจสอบว่าถ้ามี '-' อยู่หน้าสุด
                formattedValue =
                    "-" + formattedValue.slice(1).replace(/-/g, ""); // ลบ '-' ที่ไม่ใช่ตัวเลขทิ้ง
            } else {
                formattedValue = formattedValue.replace(/-/g, ""); // ลบ '-' ที่ไม่ใช่ตัวเลขทิ้ง
            }
            dynamicValidateForm.users[index].idcard = formattedValue;
        };

        const check_owner_account = async () => {
            const line_profile_info = JSON.parse(
                localStorage.getItem("profile") || "[]"
            );
            var params = {
                CustomerOrder: customer_cuscode
            };

            await BzbsUser.apicheckowneraccount(params)
                .then(res => {
                    if(res.data?.length>0){
                        const isUserIdFound = Boolean(res.data.find(item => item.UserId != line_profile_info.userId));
                        Swal.fire({
                            text: `ผู้ใช้ ${res.data[0].Name}  ${res.data[0].LastName} ลงทะเบียนแล้ว`,
                            showConfirmButton: false
                        });
                    }
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        // check_owner_account();
        return {
            isdisabled,
            gototrack,
            getgreencard,
            current_status,
            dataSources,
            querycustomer,
            formatdate,
            searchInput,
            Util,
            getdatagreen,
            pk,
            errorOccurred,
            ispaymentsuccess,
            formRef,
            dynamicValidateForm,
            onFinish,
            removeUser,
            addUser,
            errorInfo: FormHelper.genErrorInfo(7),
            daybirthrecommend,
            disabledFutureDates,
            numberOfPeople,
            percent_current,
            calculateAgeDropdown,
            handleInput
        };
    }
});
</script>
<style lang="scss">
.wrapper-track {
    width: 100%;
    max-width: 450px;
    margin: auto;

    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: #ae8b40 !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-tail::after {
        background-color: #ae8b40 !important;
    }

    .green-table {
        font-family: "Arial, Helvetica, sans-serif";
        font-size: 14px;
        color: #ffffff;
        border: 1px solid #cccccc;
        padding-left: 0px;
        text-align: center;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#ccffcc),
            to(#99cccc)
        );
        background: -moz-linear-gradient(top, #ccffcc, #99cccc);
        filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#ccffcc, EndColorStr=#99cccc);
    }

    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
        float: left;
        margin-right: 10px;
    }

    .namegreen {
        position: absolute;
        color: rgb(255 255 255 / 30%);
        font-size: 37px;
        top: 45%;
        left: 15px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        pointer-events: none;
        -webkit-transform: rotate(-45deg);
        font-family: tahoma;
    }

    .cus-desc {
        word-break: break-all;
        white-space: pre-line;
    }

    .panel-title {
        display: inline-flex;
        width: 90%;
    }

    .title-left {
        flex: 2;
        text-align: left;
    }

    .title-right {
        flex: 1;
        text-align: right;
    }

    .ant-steps-item-container {
        margin-left: -10px;
    }

    .ant-steps-item-tail {
        margin-left: -15px;
    }

    .ant-steps-item-content {
        margin-right: 15px;
    }
    .color-input {
        color: green;
    }
}
.bg-campaign-user {
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0 0 3px #555bff, 0 0 0 6px #1fc11b, 0 0 0 9px #ffd913,
        0 0 0 12px #ff9c55, 0 0 0 15px #f55;
}

.btn-success {
    margin: 10px;
    color: #fff !important;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-danger {
    margin: 10px;
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn_accept {
    width: 100%;
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.disabled_content{
    pointer-events: none;
    opacity: 0.4;

    input{
        font-size: 18px;
    }
} 
</style>
