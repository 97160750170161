<template> 
    <a-form :model="formState" name="basic" :label-col="{ span: 12 }" :wrapper-col="{ span: 24 }" autocomplete="off"
        @finish.once="onFinish" @finishFailed="onFinishFailed">
        <div> 
            <ProductType :formStates="formState" @ProductstypeData="onchangeProductsTypeData($event)"></ProductType>
            <a-row v-if="isshowProductYan()">
                <a-col class="p-1" flex="1" :order="1">
                    <a-form-item>
                        <a-checkbox-group v-model:value="formState.userproduct.professortype">
                            <a-space :size="[8, 16]" wrap>
                            <a-checkbox value="1" name="type">อาจารย์เลือกให้</a-checkbox>
                            <a-checkbox value="2" name="type">รอคุยกับอาจารย์</a-checkbox> 
                            <a-checkbox value="5" name="type">ขอรูปคู่อาจารย์</a-checkbox> 
                            <a-checkbox value="10" name="type">มัดจำแล้วรอคุยกับอาจารย์</a-checkbox> 
                            <a-checkbox value="11" name="type">เพิ่มขนาดไม่ทัน</a-checkbox> 
                            <a-checkbox value="12" name="type">เพิ่มขนาดโดยใช้แทร็คใหม่</a-checkbox> 
                            <a-checkbox value="13" name="type">ย้ายสินค้าเข้าสต๊อก</a-checkbox> 
                            <a-checkbox value="14" name="type">อาจารย์ออกแบบโลโก้</a-checkbox> 
                            <a-divider style="height: 1px; background-color: gray" />
                            <a-checkbox value="3" name="type">พรีออเดอร์</a-checkbox>
                            <a-checkbox value="4" name="type">ส่งเลย</a-checkbox>
                        </a-space>
                        </a-checkbox-group>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1" :order="3">
                    <a-form-item label="แนบรูปภาพ" :name="['userproduct', 'productyanimage']">
                        <Uploadfile :sizeupimage="1" @fileList="
                            formState.userproduct.productyanimage = $event
                        "></Uploadfile>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1" :order="2">
                    <a-form-item label="หมายเหตุ" :name="['userproduct', 'introduction']">
                        <a-textarea v-model:value="formState.userproduct.introduction" allow-clear />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row v-else>
                <a-col class="p-1" flex="1" :order="1">
                    <a-form-item>
                        <a-checkbox-group v-model:value="formState.userproduct.professortype">    
                            <a-checkbox value="4" name="type">ส่งเลย</a-checkbox>
                            <a-checkbox value="6" name="type">จัดส่งพร้อมยันต์</a-checkbox>
                            <a-checkbox value="7" name="type">จัดส่งทางไลน์</a-checkbox>
                            <a-checkbox value="8" name="type">รับเอง</a-checkbox>
                            <a-checkbox value="9" name="type">เก็บเงินปลายทาง</a-checkbox>
                            <a-checkbox value="3" name="type">พรีออเดอร์</a-checkbox>
                        </a-checkbox-group>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1" :order="1">
                    <a-form-item label="แนบรูปภาพ" :name="['userproduct', 'productyanimage']">
                        <Uploadfile :sizeupimage="1" :contactimageurl="formState.userproduct.productyanimage" @fileList="
                            formState.userproduct.productyanimage = $event
                        "></Uploadfile>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1" :order="2"></a-col>
                <a-col class="p-1" flex="1" :order="3"></a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="รหัสสินค้า" :name="['userproduct', 'productcode']" :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }">
                        <a-textarea v-model:value="formState.userproduct.productcode"
                            :auto-size="{ minRows: 1, maxRows: 1 }" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="2">
                    <a-form-item label="ราคาสินค้า" :name="['userproduct', 'productprice']" :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }">
                        <a-textarea v-model:value="formState.userproduct.productprice"
                            :auto-size="{ minRows: 1, maxRows: 1 }" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="2">
                    <a-form-item v-if="formState.userproduct.productdetail" label="ราคาต้นทุน"
                        :name="['userproduct', 'originalprice']" :rules="{
                            required: false,
                            message: 'กรุณากรอก'
                        }">
                        <a-input disabled="true" v-model:value="formState.userproduct.productdetail.originalprice" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="3"></a-col>
            </a-row>
            <div v-if="isshowProductYan()"> 
                <a-divider orientation="left">รายชื่อ-วันเกิด (ผ้ายันต์)</a-divider>
                <!-- <pre v-if="formState.isshowjason">{{ dynamicValidateForm }}</pre> -->
                <div class="p-1" v-for="(sight, index) in dynamicValidateForm.sights" :key="sight.id"
                    style="display: flex; margin-bottom: 8px;flex-direction: column;" align="baseline">
                    <div>
                        <a-row style="display: flex;align-items: center;gap: 10px;">
                            <a-col style="width:10px">
                                <MinusCircleOutlined style="position:absolute;" @click="removeSight(sight)" />

                            </a-col>
                            <a-col flex="1 1 0%" :order="1">
                                <InputText v-model:inputValue="sight.name" :errorInfo="errorInfo[1]"
                                    :isFirstSave="isFirstSave" :isShowErrorLbl="true" :inputLbl="lbl.profile.firstname"
                                    @change="updatelistbristname"></InputText>
                            </a-col>
                            <a-col flex="1 1 0%" :order="2">
                                <InputText v-model:inputValue="sight.lastname" :errorInfo="errorInfo[2]"
                                    :isFirstSave="isFirstSave" :isShowErrorLbl="true" :inputLbl="lbl.profile.lastname"
                                    @change="updatelistbristname"></InputText>
                                <!-- <a-form-item label="นามสกุล" :name="['sights', index, 'lastname']">
                                <a-input v-model:value="sight.lastname" @change="updatelistbristname" />
                            </a-form-item> -->
                            </a-col>
                            <a-col flex="1 1 0%" :order="3">
                                <!-- <a-form-item label="" :name="['sights', index, 'nickname']">
                                <a-input v-model:value="sight.nickname" @change="updatelistbristname" />

                            </a-form-item> -->
                                <!-- lbl.profile.nickname :{{lbl.profile}} -->
                                <!-- lbl.profile.idCard : {{lbl.profile.idCard}} -->
                                <InputText v-model:inputValue="sight.nickname" :errorInfo="errorInfo[3]"
                                    :isFirstSave="isFirstSave" :isShowErrorLbl="true" :inputLbl="lbl.profile.nickname"
                                    @change="updatelistbristname"></InputText>
                            </a-col>
                            <a-col flex="1 1 0%" :order="4">
                                <!-- <a-form-item label="เลขบัตรประชาชน" :name="['sights', index, 'idcard']">
                                <a-input v-model:value="sight.idcard" @change="updatelistbristname" />
                            </a-form-item> -->
                                <InputNationalId v-model:inputValue="sight.idcard" :errorInfo="errorInfo[4]"
                                    :isFirstSave="isFirstSave" :isShowErrorLbl="true" :inputLbl="lbl.profile.idCard"
                                    @change="updatelistbristname">
                                </InputNationalId>
                            </a-col>
                        </a-row>
                    </div>

                    <div>
                        <a-row style="display: flex;align-items: center;gap: 10px;">
                            <a-col v-if="isshowProductYan()" flex="1 1 0%" :order="5">
                                <a-form-item :name="['sights', index, 'typebirthday']" label="วันเกิด">
                                    <a-radio-group v-model:value="sight.typebirthday" @change="updatelistbristname"
                                        name="radioGroup">
                                        <a-radio value="1">ยังไม่แจ้ง</a-radio>
                                        <a-radio value="2">พ.ศ.</a-radio>
                                        <a-radio value="3">ค.ศ</a-radio>
                                    </a-radio-group>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 0%" :order="6">
                                <a-form-item v-if="sight.typebirthday == 3" class="" :name="['sights', index, 'birthday']"
                                    label="วันเกิด">
                                    <a-date-picker :disabled="isdisabled(sight)" v-model:value="sight.birthday"
                                        value-format="YYYY-MM-DD" @change="updatelistbristname" />
                                </a-form-item>
                                <a-form-item v-if="sight.typebirthday == 2" class="" :name="['sights', index, 'birthday']">
                                    <DatePickerDropdown v-model:inputValue="sight.birthday" :errorInfo="errorInfo[6]"
                                        :isFirstSave="true" :isShowErrorLbl="true" :inputLbl="lbl.profile.birthdate"
                                        :isISO_8601="true" @onDateChange="
                                            calculateAge(
                                                sight.birthday
                                            )
                                        ">
                                    </DatePickerDropdown>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 0%" :order="7">
                                <a-form-item label="อายุ" v-if="sight.typebirthday != 1">
                                    <a-input-number :disabled="isdisabled(sight)" v-model:value="sight.age" :min="0"
                                        :max="100" @change="updatelistbristname" />
                                    ปี
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 0%" :order="8">
                            </a-col>
                        </a-row>
                    </div>
                    <div v-if="false">
                        <a-row>
                            <a-col flex="1 1" :order="7">
                                <a-form-item label="อาชีพ" :name="['userproduct', 'career']">
                                    <a-input :style="{ color: 'green' }" v-model:value="sight.career" />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="8">
                                <a-form-item label="หมายเหตุ" :name="['userproduct', 'introductioncareer']">
                                    <a-textarea v-model:value="
                                        sight.introductioncareer
                                    " allow-clear />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="9"></a-col>
                        </a-row>
                        <a-row>
                            <a-col flex="1 1" :order="10">
                                <a-form-item label="LINE ID" :name="['userproduct', 'lineid']">
                                    <a-input :style="{ color: 'green' }" v-model:value="sight.lineid">
                                        <template #prefix>
                                            <img class="w-5"
                                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                                        </template>
                                    </a-input>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="11">
                                <a-form-item label="FACEBOOK" :name="['userproduct', 'facebook']">
                                    <a-input :style="{ color: 'green' }" v-model:value="sight.facebook">
                                        <template #prefix>
                                            <img class="w-5"
                                                src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg" />
                                        </template>
                                    </a-input>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="12"></a-col>
                        </a-row>

                        <a-row type="flex">
                            <a-col flex="1 1" :order="13">
                                <a-form-item :name="['userproduct', 'phone']" label="เบอร์ติดต่อ" :rules="{
                                    required: false,
                                    message: 'กรุณากรอก'
                                }">
                                    <a-input :style="{ color: 'green' }" v-model:value="sight.phone" placeholder="เบอร์ติดต่อ" />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="14">
                                <a-form-item :name="['userproduct', 'phoneemergency']" label="เบอร์ติดต่อ(ฉุกเฉิน)" :rules="{
                                    required: false,
                                    message: 'กรุณากรอก'
                                }">
                                    <a-input :style="{ color: 'green' }" v-model:value="
                                        sight.phoneemergency
                                    " placeholder="เบอร์ฉุกเฉิน" />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="15"></a-col>
                        </a-row>
                        <a-row type="flex">
                            <a-col flex="1 1" :order="16">
                                <a-form-item :name="['userproduct', 'customerphonethai']" label="เบอร์ติดต่อที่ไทย">
                                    <a-input :style="{ color: 'green' }" v-model:value="
                                        sight.customerphonethai
                                    " placeholder="เบอร์ติดต่อที่ไทย" />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="17">
                                <a-form-item :name="['userproduct', 'email']" label="E-Mail">
                                    <a-input :style="{ color: 'green' }" v-model:value="sight.email" placeholder="E-Mail" />
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1" :order="18"></a-col>
                        </a-row>
                    </div>
                </div>
                <a-form-item>
                    <a-button v-if="ischeckcountyan" type="primary" :style="'width:150px'" block @click="addSight">
                        <PlusOutlined />
                        เพิ่มรายชื่อ
                    </a-button>
                </a-form-item>
            </div>

            <a-form-item>
                <a-row>
                    <a-col class="p-1" flex="1 1 500px" :order="1">
                        <a-form-item label="อาชีพ" :name="['userproduct', 'career']">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.career" />
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="2">
                        <a-form-item label="หมายเหตุ" :name="['userproduct', 'introductioncareer']">
                            <a-textarea v-model:value="
                                formState.userproduct.introductioncareer
                            " allow-clear />
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="3"></a-col>
                </a-row>
                <a-row>
                    <a-col class="p-1" flex="1 1 500px" :order="1">
                        <a-form-item label="LINE ID" :name="['userproduct', 'lineid']">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.lineid">
                                <template #prefix>
                                    <img class="w-5"
                                        src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                                </template>
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="2">
                        <a-form-item label="FACEBOOK" :name="['userproduct', 'facebook']">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.facebook">
                                <template #prefix>
                                    <img class="w-5"
                                        src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg" />
                                </template>
                            </a-input>
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="3"></a-col>
                </a-row>
                <a-row type="flex">
                    <a-col flex="1 1 500px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'phone']" label="เบอร์ติดต่อ" :rules="{
                            required: true,
                            message: 'กรุณากรอก'
                        }">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.phone" placeholder="เบอร์ติดต่อ" />
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 500px" class="p-1" :order="2">
                        <a-form-item :name="['userproduct', 'phoneemergency']" label="เบอร์ติดต่อ(ฉุกเฉิน)" :rules="{
                            required: true,
                            message: 'กรุณากรอก'
                        }">
                            <a-input :style="{ color: 'green' }" v-model:value="
                                formState.userproduct.phoneemergency
                            " placeholder="เบอร์ฉุกเฉิน" />
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="3"></a-col>
                </a-row>
                <a-row type="flex">
                    <a-col flex="1 1 500px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'customerphonethai']" label="เบอร์ติดต่อที่ไทย">
                            <a-input :style="{ color: 'green' }" v-model:value="
                                formState.userproduct.customerphonethai
                            " placeholder="เบอร์ติดต่อที่ไทย" />
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 500px" class="p-1" :order="2">
                        <a-form-item :name="['userproduct', 'email']" label="E-Mail">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.email" placeholder="E-Mail" />
                        </a-form-item>
                    </a-col>
                    <a-col class="p-1" flex="1 1 500px" :order="3"></a-col>
                </a-row>
                 
                <a-row v-if="isshowProductYan()" type="flex">
                    <a-col flex="1 1 300px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'queyan']" label="ลงระบบคิวยันต์">
                            <a-radio-group v-model:value="formState.userproduct.queyan" name="radioGroup">
                                <a-radio value="1">ยังไม่ได้ลงระบบ</a-radio>
                                <a-radio value="2">ลงระบบแล้ว</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" class="p-1" :order="2"> </a-col>
                </a-row>
                <a-row type="flex" v-if="formState.userproduct.queyan == 2">
                    <a-col flex="1 1 300px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'trackyan']" label="เลขแท็ก*">
                            <a-input :style="{ color: 'green' }" v-model:value="formState.userproduct.trackyan" placeholder="Tracking" />
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'queyandate']" label="คิวเขียน(วันที่)">

                            <DatePickerDropdown v-model:inputValue="formState.userproduct.queyandate"
                                :errorInfo="errorInfo[6]" :isFirstSave="true" :isShowErrorLbl="true"
                                :inputLbl="lbl.profile.quedate" :isISO_8601="true">
                            </DatePickerDropdown>
                        </a-form-item>
                        <a-form-item :name="['userproduct', 'yancomingdate']" label="วันที่ยันต์มา(วันที่)">

                            <DatePickerDropdown v-model:inputValue="formState.userproduct.yancomingdate"
                                :errorInfo="errorInfo[6]" :isFirstSave="true" :isShowErrorLbl="true"
                                :inputLbl="lbl.profile.quedate" :isISO_8601="true">
                            </DatePickerDropdown>
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" class="p-1" :order="1">
                        <a-form-item :name="['userproduct', 'queyanimage']" label="แนบรูป">
                            <Uploadfile :sizeupimage="1" :contactimageurl="formState.userproduct.queyanimage" @fileList="
                                formState.userproduct.queyanimage = $event
                            "></Uploadfile>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row type="flex">
                    <a-col flex="1" class="p-1" :order="1">
                        <a-form-item label="ขนาด" name="paiddelivery">
                            <!-- <a-input /> -->
                            <PaidDelivery :formStates="formState" v-model:value="paiddeliveryobj"></PaidDelivery>
                        </a-form-item>
                    </a-col>
                </a-row>
                <!-- //submit -->
                <!-- <a-button type="primary" html-type="submit">บันทึก</a-button> -->
                <!-- //submit -->
                <a-row type="flex">
                    <a-form-item :wrapper-col="{ offset: 16, span: 16 }">
                        <a-button :style="'float: right;'" type="primary" html-type="submit">บันทึก</a-button>
                    </a-form-item>
                </a-row>
            </a-form-item>
        </div>
    </a-form>
</template>
<script>
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import Uploadfile from "../form/Uploadfile.vue";
import PaidDelivery from "../form/PaidDelivery.vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import axios from "axios";
import ProductType from "../form/productcomponent/ProductType.vue";
import InputNationalId from "@/components/Input/label/InputNationalId.vue";
import InputText from "@/components/Input/label/InputText.vue";
import { FormHelper } from "@/helper/FormHelper.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import { formStateStore } from "../../../../../store/storebackoffice"
import DatePickerDropdown from "@/components/Input/label/DatePickerDropdown.vue";
import { useRoute } from 'vue-router'
// import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Baseinput from "../form/Baseinput.vue";
export default defineComponent({
    mixins: [LocaleMixin],
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        Uploadfile,
        PaidDelivery,
        ProductType,
        InputNationalId,
        InputText,
        DatePickerDropdown
        // Baseinput,
    },
    props: {
        formStates: null,
        subshow:null
    },
    setup(props, { emit }) {
        const iscreate = ref(false)
        const route = useRoute()
        if(route.query.p == 'create'){
            iscreate.value = true
        }
        const ischeckcountyan = ref(true)
        const paiddeliveryobj = ref({})
        const gender = ref("1");
        const queyan = ref("1");
        const typebirthday = ref("1");

        const is_paid_delivery = ref("1");

        const agegender = ref("18");
        // const formState = reactive(props.formStates);
        const formState = reactive(props.formStates);
        // formState = props.formState
        const productData = ["ยันต์เขียนสด", "โทรศัพท์"];
        // const sights = reactive();
        const sights = {
            ยันต์เขียนสด: [],
            หัวใจยัน: []
        };
        const sizeproduct = ref(null);
        const heartyanname = ref(null);
        const yanname = [
            {
                label: "หัวใจยันต์1",
                value: "หัวใจยันต์1"
            },
            {
                label: "หัวใจยันต์2",
                value: "หัวใจยันต์2"
            },
            {
                label: "หัวใจยันต์3",
                value: "หัวใจยันต์3"
            },
            {
                label: "หัวใจยันต์4",
                value: "หัวใจยันต์4"
            }
        ];
        const areas = [
            {
                label: "ยันต์เขียนสด",
                value: "yan"
            },
            {
                label: "สินค้า",
                value: "product"
            }
        ];

        onMounted(() => {
            queryyan();
        });
        const queryyan = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/getproductjson/yan").then(res => {
                res?.data?.yan?.forEach(element => {

                    sights["ยันต์เขียนสด"].push(element["ขนาด"]);
                    var objheartyan = {};
                    objheartyan.yan_size = element["ขนาด"];
                    objheartyan.heartyan = element["หัวใจยันต์"];
                    sights["หัวใจยัน"].push(objheartyan);
                });
                let yan = sights["ยันต์เขียนสด"].filter((c, index) => {
                    return sights["ยันต์เขียนสด"].indexOf(c) === index;
                });
                sizeproduct.value = yan;
            });
        };

        const formRef = ref();
        const dynamicValidateForm = reactive({
            sights: [],
            area: undefined
        });
        watch(
            () => dynamicValidateForm.area,
            () => {
                dynamicValidateForm.sights = [];
            }
        );

        const setheartyan = () => {
            formState.userproduct.heartyanname = "";
            let listheartyanname = [];
            sights["หัวใจยัน"].filter(c => {
                if (c.yan_size == formState.userproduct.customerproductssize) {
                    listheartyanname.push(c.heartyan);
                }
            });
            heartyanname.value = listheartyanname;
            // console.log(heartyanname.value);
        };
        const removeSight = item => {
            let index = dynamicValidateForm.sights.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.sights.splice(index, 1);
            }
        };

        const addSight = () => {
            if (dynamicValidateForm.sights.length == 10) return
            dynamicValidateForm.sights.push({
                productkey: props.formStates.userproduct.productkey,
                value: undefined,
                price: undefined,
                typebirthday: "1",
                id: Date.now()
            });
        };

        setTimeout(() => {

            if (dynamicValidateForm?.sights?.length == 0) {
                addSight()
            }
        }, 1000);
        // let outside = [];
        const onFinish = values => {

            //  var inside = Object.assign({}, formState.inside);
            var inside = paiddeliveryobj.value.inside;
            var insidecod = paiddeliveryobj.value.insidecod;
            var outside = paiddeliveryobj.value.outside;
            // outside.push(Object.assign({}, JSON.stringify(formState.outside)))
            // console.log("outside : ",outside)
            formState.userproduct["inside"] = (inside);
            formState.userproduct["insidecod"] = (insidecod);
            formState.userproduct["outside"] = (outside);
            // Object.assign(formState, formState.userproduct["outside"].push(formState.outside)); 
            formState.listproduct.push(
                Object.assign({}, formState.userproduct)
            );
            console.log("formState :", Object.assign({}, formState));
            emit("update:value", Object.assign({}, formState));
            emit("closemodal", false);
            formItemContext.onFieldChange();
            console.log("Received values of form:", values);
            console.log("dynamicValidateForm:", dynamicValidateForm);

            // const params = {
            //     ProductSellOrder: formState.user.sellorderTransaction,
            //     CustomerOrder: formState.user.customercode,
            //     ProductKey: formState.userproduct.productkey,
            //     ProductName: formState.userproduct.productname,
            //     ProductType: formState.userproduct.producttype,
            //     Info1: formState.userproduct.info1,
            //     Info2: formState.userproduct.info2,
            //     Info3: formState.userproduct.info3,
            //     Info4: formState.userproduct.info4,
            //     ProductSize: formState.userproduct.productsize,
            //     ProductPrice: formState.userproduct.productprice,
            //     ProductStock: formState.userproduct.productstock,
            //     Queyan: formState.userproduct.queyan,
            //     CustomerProductsType:
            //         formState.userproduct.customerproductstype,
            //     ProductCode: formState.userproduct.productcode,
            //     CustomerProductsSize:
            //         formState.userproduct.customerproductssize,
            //     HeartYanName: formState.userproduct.heartyanname,
            //     ProductDetail: formState.userproduct.productdetail,
            //     IntroductionYan: formState.userproduct.introductionyan,
            //     ProfessorType: formState.userproduct.professortype,
            //     Introduction: formState.userproduct.introduction,
            //     ProductYanImage: formState.userproduct.productyanimage,
            //     Career: formState.userproduct.career,
            //     IntroductionCareer: formState.userproduct.introductioncareer,
            //     LineId: formState.userproduct.lineid,
            //     Facebook: formState.userproduct.facebook,
            //     Phone: formState.userproduct.phone,
            //     PhoneEmergency: formState.userproduct.phoneemergency,
            //     CustomerPhoneThai: formState.userproduct.customerphonethai,
            //     Email: formState.userproduct.email,
            //     TrackYan: formState.userproduct.trackyan,
            //     QueYanDate: formState.userproduct.queyandate,
            //     QueYanImage: formState.userproduct.queyanimage
            // };
            // BzbsUser.apiproduct(params)
            //     .then(res => {
            //         console.log(res);
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };
        const isdisabled = sights => {
            if (sights.typebirthday == 1) {
                delete sights.birthday;
                delete sights.age;
                return true;
            } else {
                sights.age = calculateAge(sights);
            }
        };

        const calculateAge = sights => {
            var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
                return Math.abs(ageDate.getUTCFullYear() - 1970);
            } else {
                return 0;
            }
        };
        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            console.log("formState :", formState);
            emit("update:value", formState);
            formItemContext.onFieldChange();
        };
        const onchangeProductsTypeData = e => {

            console.log(e);
            formState.userproduct = e;
            checkbirstdatecount()
        };
        // watch(
        //     () => formState.userproduct.productdetail,
        //     () => {
        
        //         formState.userproduct.productprice =
        //             formState.userproduct.productdetail.price;
        //         formState.userproduct.productcode =
        //             formState.userproduct.productdetail.productcode;
        //     }
        // );
        watch(
            () => formState.userproduct.customerproductstype,
            () => {
                // formState.userproduct = {};
                formState.userproduct.productprice = "";
                formState.userproduct.productcode = "";
            }
        );
        const updatelistbristname = () => {

            console.log(formStateStore)
            formStateStore.listbirstname = dynamicValidateForm
            formStateStore.listbirstname.sights.productsellorder = formStateStore.user.sellorderTransaction
            formStateStore.listbirstname.sights.customerorder = formStateStore.user.customercode
        }
        const isshowProductYan = () => {
            var array_name = ['ยันต์ปลด/คิวด่วน', 'ยันต์เขียนสด']
            if (array_name.includes(formState.userproduct.customerproductstype)) {

                return true
            } else {
                return false
            }

        }
        const checkbirstdatecount = () => {
            var array_name = ["10x10 ยันต์เปิดดวง", "20 ยันต์หนุนดวง", "20 องค์เทพ", "30 มหายันต์รวย", "30 วาระพิเศษ", "40 องค์เทพ", "50 มหายันต์รวย", "50 องเทพ",
                "10x10 ยันต์เปิดดวง",
                "20x20 ยันต์หนุนดวง (สิบทิศ)",
                "20x20 ยันต์หนุนดวง (พญานาค)",
                "20x20 ยันต์หนุนดวง (พญาครุฑ)",
                "20x20 ยันต์หนุนดวง (สิวลี)",
                "20x20 ยันต์หนุนดวง (พิฆเนศปางเสวยสุข)",
                "20x20 ยันต์หนุนดวง (พิฆเนศปางประทานพร)",
                "20x20 ยันต์หนุนดวง (นกถึดทือ)",
                "30x30 มหายันต์รวย (สิบทิศ)",
                "30x30 วาระพิเศษ (พระพุทธ)",
                "30x30 วาระพิเศษ (อาจารย์ธรรมโชติ)",
                "30x30 วาระพิเศษ (นาคราชเรียกทรัพย์)",
                "40x40 มหายันต์รวย (พญานาคเดี่ยว)",
                "40x40 มหายันต์รวย (พิฆเนศประทานพรแบบนั่ง)",
                "40x40 มหายันต์รวย (สิวลี)",
                "40x40 มหายันต์รวย (พระพุทธเจ้า)",
                "40x40 มหายันต์รวย (พญาครุฑ)",
                "40x40 มหายันต์รวย (นกถึดทือหุบปีก)",
                "40x40 มหายันต์รวย (นกถึดทือกางปีก)",
                "40x40 มหายันต์รวย (เสือเหลียวหลัง)",
                "40x40 มหายันต์รวย (ราหู)",
                "40x40 มหายันต์รวย (พระอาทิตย์)",
                "40x40 มหายันต์รวย ",
                "50x50 มหายันต์รวย (สิบทิศ)",
                "50x50 มหายันต์รวย (นาคเกี้ยวรุ้ง)",
                "50x50 มหายันต์รวย (นาคเกี้ยวเขียว)",
                "50x50 มหายันต์รวย (นาค 9 เศียร)",
                "50x50 มหายันต์รวย (นาคทอง)",
                "50x50 มหายันต์รวย (นาคเขียว)",
                "50x50 มหายันต์รวย (พิฆเนศนาคปรก)",
                "50x50 มหายันต์รวย (พิฆเนศเสวยสุข)",
                "50x50 มหายันต์รวย (พิฆเนศประทานพร)",
                "50x50 มหายันต์รวย (ตัวโอม)",
                "50x50 มหายันต์รวย (ครุฑแดง)",
                "50x50 มหายันต์รวย (ครุฑน้ำเงิน)",
                "50x50 มหายันต์รวย (ท้าวเวสสุวรรณ)",
                "50x50 มหายันต์รวย (สิวลี)",
                "50x50 มหายันต์รวย (พระนาคปรก)",
                "50x50 มหายันต์รวย (พระพุทธเจ้า)",
                "50x50 มหายันต์รวย (ราหู)",
                "50x50 มหายันต์รวย (กวนอิมดอกบัว)",
                "50x50 มหายันต์รวย (นกถึดทือหุบปีก)",
                "50x50 มหายันต์รวย (นกถึดทือกางปีก)",
                "50x50 มหายันต์รวย ",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีเขียว)",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีรุ้ง)",
                "16.8x16.8 นาคาบารมีเดี่ยว (สีทอง)",
                "16.8x16.8 นาคาบารมีเกี้ยวคู่ (สีเขียว)",
                "16.8x16.8 นาคาบารมีเกี้ยวคู่ (สีรุ้ง)",
                "16.8x16.8 นาคาบารมี "
            ]

            if (array_name.includes(formState.userproduct.customerproductssize)) {
                return ischeckcountyan.value = false
            }
            else {
                return ischeckcountyan.value = true
            }


        }
        return {
            ischeckcountyan,
            isshowProductYan,
            updatelistbristname,
            paiddeliveryobj,
            onchangeProductsTypeData,
            locale,
            formRef,
            dynamicValidateForm,
            onFinish,
            removeSight,
            addSight,
            areas,
            sights,
            gender,
            agegender,
            queyan,
            is_paid_delivery,
            formState,
            fromchange,
            yanname,
            typebirthday,
            isdisabled,
            calculateAge,
            onFinishFailed,
            productData,
            sizeproduct,
            heartyanname,
            setheartyan,
            errorInfo: FormHelper.genErrorInfo(7),
            iscreate
        };
    }
});
</script>
