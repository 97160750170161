import Util from "@/helper/Utility.js";
import ModalMaster from "@/components/Modal/ModalMaster.vue";
import ModalFactory from "@/components/Modal/ModalFactory.js";

export default {
    data: function () {
        return {
            modalFactory: new ModalFactory(),
            modalController: null,
        };
    },
    components: {
        ModalMaster,
    },
    created: function () {
        this.initialGenericModal();

        if (Util.isFunction(this.onSetupModalCompleted)) {
            this.onSetupModalCompleted();
        }
    },
    methods: {
        initialGenericModal: function () {
            this.modalController = this.modalFactory.getModalController();
            this.alertModal = this.modalFactory.getAlertModal();
            this.confirmModal = this.modalFactory.getConfirmModal();
            this.loadingModal = this.modalFactory.getLoadingModal();
        },
        initialRedeemModal: function () {
            this.confirmRedeemModal = this.modalFactory.getConfirmRedeemModal();
            this.drawDeliveryModal = this.modalFactory.getDrawDeliveryModal();
            this.serialModal = this.modalFactory.getSerialModal();
            this.shippingAddressModal = this.modalFactory.getShippingAddressModal();
        },
        initialHistoryModal: function () {
            this.serialModal = this.modalFactory.getSerialModal();
        },
        initialAddressModal: function() {
            this.addAddressModal = this.modalFactory.getAddAddressModal();
            this.editAddressModal = this.modalFactory.getEditAddressModal();
            this.listAddressModal = this.modalFactory.getListAddressModal();
        },
        initialTaxAddressModal: function() {
            this.addTaxAddressModal = this.modalFactory.getAddTaxAddressModal();
            this.editTaxAddressModal = this.modalFactory.getEditTaxAddressModal();
            this.listTaxAddressModal = this.modalFactory.getListTaxAddressModal();
        },
    }
}
