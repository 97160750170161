<template>
    <div class="flex px-4">
        <div class="w-1/2">
            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.firstName"
                    :inputLbl="{ name: 'First Name', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[0]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>

            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.lastName"
                    :inputLbl="{ name: 'Last Name', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[1]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>

            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.birthday"
                    :inputLbl="{ name: 'Birthday', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[2]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>

            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.contactNo"
                    :inputLbl="{ name: 'Contact Number', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[3]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>

            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.email"
                    :inputLbl="{ name: 'Email', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[4]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>
        </div>
        <div class="w-1/2">
            <div class="mb-2">
                <v-input-text-area
                    v-model:inputValue="model.streetAddress"
                    :inputLbl="{ name: 'Street Address', align: 'text-right' }"
                    :maxLength="300"
                    :errorInfo="errInfo[5]"
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-area>
                <!-- <div class="flex">
                    <div
                        class="flex-none text-sm mb-1 px-6 mt-2 text-right text-gray-500"
                        :style="{ width: '160px' }"
                    >Street Address</div>
                    <textarea
                        v-model="model.streetAddress"
                        class="rounded bg-gray-50 p-2 flex-auto mb-5 border text-sm"
                        rows="2"
                    ></textarea>
                </div>-->
            </div>

            <div class="mb-2">
                <v-input-text-inline-label
                    v-model:inputValue="model.zipCode"
                    :inputLbl="{ name: 'Zip Code', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[6]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-input-text-inline-label>
            </div>

            <div class="mb-2">
                <v-dropdown-list
                    class="pb-5"
                    v-model="model.province"
                    :items="itemsProvince"
                    :inputLbl="{ name: 'Province', align: 'text-right' }"
                    :errorInfo="errInfo[7]"
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-dropdown-list>
            </div>

            <div class="mb-2">
                <v-dropdown-list
                    class="pb-5"
                    v-model="model.district"
                    :items="itemsDistrict"
                    :inputLbl="{ name: 'District', align: 'text-right' }"
                    :errorInfo="errInfo[8]"
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-dropdown-list>
            </div>

            <div class="mb-2">
                <v-dropdown-list
                    class="pb-5"
                    v-model="model.subDistrict"
                    :items="itemsSubDistrict"
                    :inputLbl="{ name: 'Sub-District', align: 'text-right' }"
                    :errorInfo="errInfo[9]"
                    :labelWidth="160"
                    bg="bg-gray-50"
                ></v-dropdown-list>
            </div>
        </div>
    </div>

    <div class="mt-3 px-4 text-right">
        <button
            class="btn btn-outlined btn-blue-1 w-32 disabled:opacity-50"
            :disabled="false"
        >Update</button>
    </div>
</template>

<script>
import VInputTextInlineLabel from "@/components/Input/label/InputTextInlineLabel.vue"
import VInputTextArea from "@/components/Input/label/InputTextArea.vue"
import VDropdownList from "@/components/Input/label/DropdownList.vue"
// helper
import { FormHelper } from "@/helper/FormHelper"

export default {
    name: "UserProfileForm",
    components: {
        VInputTextInlineLabel,
        VInputTextArea,
        VDropdownList
    },
    emits: [],
    data: function () {
        return {
            model: {
                firstName: "-",
                lastName: "-",
                birthday: "2020-04-16",
                contactNo: "-",
                email: "Korn@korn.com",
                streetAddress: "-",
                zipCode: "-",
                province: "-",
                district: "-",
                subDistrict: "-"
            },
            errInfo: FormHelper.genErrorInfo(10),
            itemsProvince: [
                { text: "Bangkok", value: "Bangkok" }
            ],
            itemsDistrict: [
                { text: "Huaykwang", value: "Huaykwang" }
            ],
            itemsSubDistrict: [
                { text: "Huaykwang", value: "Huaykwang" }
            ]
        }
    },
    computed: {

    },
    methods: {

    }
}
</script>
