<template>
    <div class="clearfix">
        <a-upload v-model:file-list="fileList" action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            list-type="picture-card" @preview="handlePreview" @change="handleChange">
            <div v-if="fileList.length < sizeupload">
                <plus-outlined />
                <div style="margin-top: 8px">Upload Video</div>
            </div>
        </a-upload>
        <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage || hasimage" />
        </a-modal> 
    </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { number, string } from "yargs";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = error => reject(error);
    });
}

export default defineComponent({
    components: {
        PlusOutlined,
    },
    props: {
        contactimageurl: string,
        sizeupimage: number
    },
    emits: ["fileList"],
    setup(props, context) {
        const sizeupload = ref(props.sizeupimage);
        const hasimage = ref(props.contactimageurl);

        const previewVisible = ref(false);
        const previewImage = ref("");
        const previewTitle = ref("");

        const isloading = ref(false);
        // var param = {
        //     url: hasimage
        // };

        const fileList = ref([]);

        const handleCancel = () => { 
            previewVisible.value = false;
            previewTitle.value = ""; 
        };

        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            previewImage.value = file.url || file.preview;
            previewVisible.value = true;
            previewTitle.value =
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
        };

        const handleChange = async file => {

            isloading.value = true
            if (file.file.originFileObj) {
                file.file.preview = await getBase64(file.file.originFileObj);
                var uploadparam = {}; 
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear(); 
                today = '_'+mm + '_' + dd + '_' + yyyy;
                uploadparam = {
                    filename: file.fileList[0].name.split(".")[0] + today,
                    base64: file.file.preview,
                    folder: "video"
                };
                await BzbsUser.apiuploadimage(uploadparam)
                    .then(res => {
                        console.log(res)
                        context.emit("fileList", res.data.url);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            // if (file.file.preview) {  
            //     context.emit("fileList", fileList.value);
            // }
        };

        return {
            previewVisible,
            previewImage,
            fileList,
            handleCancel,
            handlePreview,
            previewTitle,
            handleChange,
            sizeupload,
            hasimage
        };
    }
});
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
