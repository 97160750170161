<template>
    <div class="">
        <div class="text-sm" :class="[isError ? 'text-red-500' : 'text-gray-800']">
            {{inputLbl.name}}
        </div>
        <div class="relative pb-5">
            <div class="flex py-1"
                 v-for="(item) in itemList"
                 :key="item.value"
                 @click="selectRadio(item.value)">
                <div class="w-6 h-6 border-2 border-blue-500 rounded-full cursor-pointer flex justify-center items-center">
                    <div v-if="item.value == content" class="w-4 h-4 bg-blue-500 rounded-full"></div>
                </div>
                <div class="pl-2"
                     :class="{'error': isError}">
                    {{item.text}}
                </div>
            </div>
            <div class="text-red-500 text-sm absolute bottom-0 left-0" v-show="isError">
                {{errorInfo.msg}}
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";

/**
 * @property {String} v-model:inputValue (required) bind value
 * @property {Object} inputLbl (required) Text for component.
 * @property {Array} itemList (required) example : { text: '', value: ''}
 * @property {Boolean} isFirstSave (required) Show/Hide Error after save.
 * @property {Object} errorInfo (required) Validate result. example : { isValid: true, msg: '',}
 * @property {Boolean} isShowErrorLbl (optional) Show/Hide Error label.
 */
export default {
    name: "InputRadio",
    props: {
        inputValue: { type: String },
        inputLbl: { type: Object, required: true },
        itemList: { type: Array, required: true },
        errorInfo: { type: Object, required: true },
        isFirstSave: {
            type: Boolean,
            default: false,
        },
        isShowErrorLbl: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            content: '',
            customClass: '',
        };
    },
    created: function() {
        this.customClass = this.addClass == null ? '' : this.addClass;
        this.content = this.inputValue;
        this.validate();
    },
    computed: {
        isError: function() {
            return !this.isFirstSave
                && this.isShowErrorLbl
                && !this.errorInfo.isValid;
        },
    },
    methods: {
        selectRadio: function(value) {
            this.content = value;
            this.validate();
            this.$emit('update:inputValue', value);
        },
        validate: function() {
            if(!this.inputLbl) {
                return;
            }
            //Set default.
            this.errorInfo.setValid(true);
            this.errorInfo.setMsg('');

            if (this.inputLbl.required && Util.isEmptyStr(this.content)) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.required);
                return;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
