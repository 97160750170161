<template>
    <div>
        <a-button
            style="float: right;"
            type="primary"
            @click="showModalHistoryStock"
        >
            <template #icon> <dropbox-outlined /> </template>
            เลือกใช้รายการสต๊อก</a-button
        >

        <!-- :: <pre>{{ is_edit_product }}</pre> -->
        <a-modal
            v-model:visible="visiblehistorystock"
            width="1000px"
            title="รายการเพิ่ม Stock"
            @ok="handleOkHistoryStock"
        >
            <a-table :dataSource="listhistorystock" :columns="columnstock">
                <template #bodyCell="{ column, text, record, index }">
                    <!-- <pre>{{ column.dataIndex }}</pre> -->

                    <template v-if="column.dataIndex === 'key'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'userproduct'">
                        <div>
                            <div>
                                <!-- <pre>{{ record }}</pre> -->
                                <div v-show="record.CustomerProductsType">
                                    <font-awesome-icon
                                        :icon="'fa-solid fa-cart-arrow-down'"
                                    />
                                    <span class="pl-2">{{
                                        record.CustomerProductsType
                                    }}</span>
                                </div>
                                <!-- <div>{{ record.userproduct.birthday }}</div> -->
                                <div>{{ record.HeartYanName }}</div>
                                <div>{{ record.CustomerProductsSize }}</div>

                                <div class="pt-2" v-show="record.ProductCode">
                                    <font-awesome-icon
                                        :icon="'fa-solid fa-barcode'"
                                    />
                                    {{ record.ProductCode }}
                                </div>
                                <div>
                                    <div v-if="record.ProfessorType.length > 0">
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '1'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >อาจารย์เลือกให้</a-tag
                                            >
                                            <!-- <a-typography-text>อาจารย์เลือกให้</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '2'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >รอคุยกับอาจารย์</a-tag
                                            >
                                            <!-- <a-typography-text>รอคุยกับอาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '5'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >ขอรูปคู่อาจารย์</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '10'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >มัดจำแล้วรอคุยกับอาจารย์</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '11'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >เพิ่มขนาดไม่ทัน</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '12'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >เพิ่มขนาดโดยใช้แทร็คใหม่</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>

                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '13'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >ย้ายสินค้าเข้าสต๊อก</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '14'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >อาจารย์ออกแบบโลโก้</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '3'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >พรีออเดอร์</a-tag
                                            >
                                            <!-- <a-typography-text>พรีออเดอร์</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '4'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50">ส่งเลย</a-tag>
                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '6'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >จัดส่งพร้อมยันต์</a-tag
                                            >
                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '7'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >จัดส่งทางไลน์</a-tag
                                            >
                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '8'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50">รับเอง</a-tag>
                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '9'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >เก็บเงินปลายทาง</a-tag
                                            >
                                            <!-- <a-typography-text>ส่งเลย</a-typography-text> -->
                                        </div>
                                        <div
                                            v-if="
                                                record.ProfessorType.includes(
                                                    '5'
                                                )
                                            "
                                        >
                                            <span style="color:red">*</span>
                                            <a-tag color="#f50"
                                                >ขอรูปคู่อาจารย์</a-tag
                                            >
                                            <!-- <a-typography-text>ขอรูปคู่อาจารย์</a-typography-text> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'price'">
                        <div>{{ showprice(record.ProductPrice) }}</div>
                    </template>
                    <template
                        v-else-if="column.dataIndex === 'introductionyan'"
                    >
                        <div>
                            {{ record.IntroductionYan }}
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'OrderDate'">
                        <div style="font-size: 12px;">
                            {{ showthaidate(record.OrderDate) }}
                        </div>
                    </template>
                    <template
                        v-else-if="column.dataIndex === 'IsUsedItemStock'"
                    >
                        <div v-if="record.IsUsedItemStock == 'unused'">
                            <a-typography-text type="success"
                                >ว่าง</a-typography-text
                            >
                        </div>
                        <div v-else-if="record.IsUsedItemStock == 'used'">
                            <a-typography-text type="danger"
                                >ใช้แล้ว</a-typography-text
                            >
                        </div>
                    </template>

                    <template v-else-if="column.dataIndex === 'Delete'">
                        <a-popconfirm
                            v-if="dataSource.length >= 2"
                            title="คุณต้องการลบ?"
                            @confirm="onDeleteStock(record)"
                        >
                            <delete-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a><u>ลบ</u></a>
                        </a-popconfirm>
                    </template>
                    <template v-else-if="column.dataIndex === 'UseStock'">
                        <div v-if="record.IsUsedItemStock">
                            <div
                                :class="{
                                    disstock: record.IsUsedItemStock == 'used'
                                }"
                            >
                                <a-popconfirm
                                    title="คุณต้องการใช้รายการ?"
                                    @confirm="onUseStock(record, 'usestock')"
                                >
                                    <a v-if="record.IsUsedItemStock != 'used'"
                                        ><u>ใช้สินค้า Stock</u></a
                                    >
                                    <div
                                        v-else-if="
                                            record.IsUsedItemStock == 'used'
                                        "
                                        style="color: gray;"
                                    >
                                        <i>ใช้แล้ว</i>
                                    </div>
                                </a-popconfirm>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
        </a-modal>
    </div>
</template>
<script>
import {
    defineComponent,
    reactive,
    ref,
    watch,
    onMounted,
    computed
} from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import ListCreateproduct from "@/module/CustomerOrder/SellCreate/component/form/ListCreateproduct.vue";
// import { formStateStore } from "../../../../../store/storebackoffice";
import _ from "lodash";
import { formStateStore } from "@/store/storebackoffice";
// import { formStatestore } from "@/store/store";
import TableLoad from "@/components/Skeleton/TableLoad";
import {
    FullscreenOutlined,
    SettingOutlined,
    DeleteOutlined,
    PartitionOutlined,
    DropboxOutlined
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { notification } from "ant-design-vue";
import moment from "moment";
import Util from "@/helper/Utility.js";
import Storedata from "@/store/Storedata.js";

var columnstock = [
    // {
    //     title: "ลำดับ",
    //     dataIndex: "key",
    //     width: "5%",
    // },
    {
        title: "สินค้า",
        dataIndex: "userproduct",
        width: "25%"
    },
    {
        title: "รหัสสต๊อก",
        dataIndex: "ProductSellOrder",
        width: "20%"
    },
    {
        title: "ราคาสินค้า",
        width: "15%",
        dataIndex: "price"
    },
    {
        title: "วันที่เพิ่ม stok",
        width: "15%",
        dataIndex: "OrderDate"
    },
    {
        title: "สถานะ",
        width: "15%",
        dataIndex: "IsUsedItemStock"
    },
    {
        title: "ใช้รายการ",
        width: "15%",
        dataIndex: "UseStock"
    }
    // {
    //     title: "ลบ",
    //     width: "15%",
    //     dataIndex: "Delete",
    // },
    // {
    //     title: "แก้ไข",
    //     width: "15%",
    //     dataIndex: "Edit",
    // },
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        PartitionOutlined,
        SettingOutlined,
        DeleteOutlined,
        FullscreenOutlined,
        TableLoad,
        ListCreateproduct,
        DropboxOutlined
    },
    props: {
        textbtn: null,
        formStates: null
    },
    setup(props, { emit }) {
        const visiblehistory = ref(false);
        const visiblehistorystock = ref(false);
        const isscan = ref(false);
        const isclick = ref("edit");
        const visible = ref(false);
        const visibleEdit = ref(false);
        const listhistorychangesize = ref([]);
        const listhistorystock = ref([]);
        const dataSource = ref([]);
        const is_edit_product = ref({
            fact: false,
            key: 0,
            type: "",
            iseditstock: false,
            iseditstock__uuid: ""
        });

        var edit_productjson = ref(null);
        const formStates = reactive(formStateStore);

        onMounted(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get("p");
            if (
                myParam != "create" &&
                window.location.pathname == "/sellcreate"
            ) {
                window.location = "/sellcreate?p=create";
            }

            getCustomerProductlist();
        });
        var countlist = 1;
        const getCustomerProductlist = async () => {
            var sellordertransaction = formStateStore.user.sellorderTransaction;
            if (is_edit_product.value.type == "increasestock") {
                console.log("formStateStore: ", formStateStore);

                if (is_edit_product.value.iseditstock == true) {
                    sellordertransaction =
                        is_edit_product.value.iseditstock__uuid;
                    getproducstockdetail(sellordertransaction);
                } else {
                    getproducstocklist(sellordertransaction);
                }
            } else {
                await BzbsUser.apigetproductbyid(sellordertransaction)
                    .then(res => {
                        countlist = 1;
                        console.log("CustomerProductbyCustomercode", res.data);
                        formStateStore.listproduct = [];

                        dataSource.value = [];

                        formStateStore.listproduct = res.data;
                        res.data.forEach(items => {
                            var result = _.transform(items, function(
                                result,
                                val,
                                key
                            ) {
                                result[key.toLowerCase()] = val;
                            });

                            handleclosemodal(result);
                            setTimeout(() => {
                                totalBorrow.value = 0;
                                totalDelivery.value = 0;
                                funtotals();
                                funtotalsdelivery();
                            }, 1000);
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const getproducstocklist = async sellordertransaction => {
            await BzbsUser.apigetproducstocktbyid(sellordertransaction)
                .then(res => {
                    if (res.data.length == 0) return showModalStock();

                    res.data.forEach(items => {
                        var result = _.transform(items, function(
                            result,
                            val,
                            key
                        ) {
                            result[key.toLowerCase()] = val;
                        });

                        handleclosemodalstock(result);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getproducstockdetail = async pk => {
            await BzbsUser.apigetproductstockdetail(pk)
                .then(res => {
                    if (res.data.length == 0) return showModalStock();

                    res.data.forEach(items => {
                        var result = _.transform(items, function(
                            result,
                            val,
                            key
                        ) {
                            result[key.toLowerCase()] = val;
                        });

                        handleclosemodalstock(result);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const showModal = () => {
            update.value++;
            isclick.value = "edit";
            visible.value = true;
        };
        const showModalStock = () => {
            update.value++;
            isclick.value = "stock";
            visible.value = true;
        };
        const showModalIncreasesize = () => {
            isclick.value = "edit";
            visible.value = true;
        };

        const addproduct = () => {
            clearuserproduct();
            console.log("addddd ", formState.userproduct);
            isclick.value = "add";
            is_edit_product.value.type = "add";
            is_edit_product.value.fact = false;
            visible.value = true;
            update.value++;
        };
        const clearuserproduct = () => {
            formStates.userproduct = {
                heartyanname: "",
                queyan: "1",
                professortype: [],
                inside: [],
                outside: [],
                introductionyan: "",
                isgencode: false,
                addressfrom: [],
                contactList: [],
                customerproductstype: "",
                selectcountry: { selectcountry: "TH" },
                ordertransaction: "",
                customeraddressType: "1"
            };
        };
        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const update = ref(1);

        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "TH"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                try {
                    if (
                        listcodephone.value.find(
                            word =>
                                word.value ==
                                formState.useraddress.selectcountry
                                    .selectcountry
                        )
                    ) {
                        formState.useraddress.phonecode = listcodephone.value.filter(
                            word =>
                                word.value ==
                                formState.useraddress.selectcountry
                                    .selectcountry
                        );
                    }
                } catch (error) {}
            },
            () => formState.productlist,
            () => {
                console.log(formState.productlist);
            }
        );
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            if (visibleEdit.value) {
                // formState.listproduct[editIndex.value] = formState.useraddress;
            }

            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };

        console.log("list.listproduct ", list.listproduct);

        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            console.log(key);
            // Object.assign(
            //     dataSource.value.filter(item => key === item.key)[0],
            //     editableData[key]
            // );
            // delete editableData[key];
        };

        const cancel = key => {
            delete editableData[key];
        };
        const onDelete = key => {
            // dataSource.value = dataSource.value.filter(
            //     item => item.key !== key
            // );

            var index = dataSource.value.findIndex(
                e => e.userproduct.productkey === key
            );
            calldeleteproduct(dataSource.value[index]);
            dataSource.value.splice(
                dataSource.value.findIndex(
                    e => e.userproduct.productkey === key
                ),
                1
            );

            formStateStore.listproduct = [];
            dataSource.value.forEach(element => {
                formStateStore.listproduct.push(element.userproduct);
            });

            console.log(
                "formStateStore.listproduct :",
                formStateStore.listproduct
            );
            update.value++;
        };

        const onDeleteStock = recode => {
            calldeleteproductstock(recode);
        };

        const onEditStockHistory = (info, type) => {
            formStateStore.is_edit_product = type;

            is_edit_product.value.iseditstock = true;
            is_edit_product.value.iseditstock__uuid = info.ProductKey;
            visiblehistorystock.value = false;
            is_edit_product.value.type = "increasestock";

            getCustomerProductlist();

            update.value++;
        };

        const calldeleteproductstock = recode => {
            var param_del = {
                CustomerOrder: recode.CustomerOrder,
                ProductSellOrder: recode.ProductSellOrder,
                ProductKey: recode.ProductKey
            };

            console.log("param_del :", param_del);
            BzbsUser.deleteproductstock(param_del)
                .then(res => {
                    openNotification("ลบรายการ Stock เรียบร้อย");
                    gethistorystock();
                    // setTimeout(() => {
                    //     getCustomerProductlist();
                    // }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const calldeleteproduct = item => {
            console.log("remove product:", item);

            var param_del = {
                CustomerOrder: item.userproduct.customerorder,
                ProductSellOrder: formStateStore.user.sellordertransaction,
                ProductKey: item.userproduct.productkey
            };
            console.log("param_del : ", param_del);

            BzbsUser.deleteproduct(param_del)
                .then(res => {
                    openNotification("ลบรายการเรียบร้อย");
                    console.log(res);
                    setTimeout(() => {
                        getCustomerProductlist();
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onEdit = (info, type) => {
            formStateStore.userproduct = info.userproduct;
            formState.userproduct = info.userproduct;
            formStateStore.is_edit_product = type;
            console.log("info formStateStore: ", formStateStore.userproduct);
            console.log("info formState: ", formState.userproduct);
            is_edit_product.value.type = type;
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.userproduct.productkey;

            formStateStore.inside = info.inside;
            formStateStore.outside = info.outside;
            showModal();

            formState.listproduct = dataSource.value;
            update.value++;
        };

        const onEditStock = (info, type) => {
            formStateStore.userproduct = info.userproduct;
            formState.userproduct = info.userproduct;
            formStateStore.is_edit_product = type;
            console.log("info formStateStore: ", formStateStore.userproduct);
            console.log("info formState: ", formState.userproduct);
            is_edit_product.value.type = type;
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.userproduct.productkey;

            formStateStore.inside = info.inside;
            formStateStore.outside = info.outside;

            getCustomerProductlist();

            formState.listproduct = dataSource.value;
            update.value++;
        };

        const increasesize = info => {
            console.log("info : ", info);
            is_edit_product.value.fact = true;
            is_edit_product.value.key = info.key - 1;
            formStateStore.userproduct = info.userproduct;

            formStateStore.inside = info.inside;
            formStateStore.outside = info.outside;
            showModalIncreasesize();

            formState.listproduct = dataSource.value;
            update.value++;
        };

        let resulttemp = [];
        const handleclosemodal = e => {
            console.log("productcode_", e.productcode);

            if (e == undefined) {
                visible.value = false;
                return;
            }

            var inside = [];
            var outside = [];

            if (e.pd_deliverytype == "inside") {
                inside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            if (e.pd_deliverytype == "outside") {
                outside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            var set_product = [];
            var ProductChangeLog = [];
            if (e.productchangelog != null) {
                JSON.parse(e.productchangelog).forEach(element => {
                    var itemchild = {
                        islog: true,
                        key: "",
                        userproduct: set_product
                    };
                    set_product = _.transform(element, function(
                        result,
                        val,
                        key
                    ) {
                        result[key?.toLowerCase()] = val;
                    });
                    if (element.ProductKey != e.productkey) return;
                    itemchild.userproduct = set_product;
                    ProductChangeLog.push(itemchild);
                });
            }

            var product = {
                key: countlist,
                inside: inside,
                outside: outside,
                userproduct: {
                    quantitystock: e.quantitystock,
                    shotness: e.shotness,
                    category_1: e.category_1,
                    category_2: e.category_2,
                    category_3: e.category_3,
                    category_4: e.category_4,
                    category_1_code: e.category_1_code,
                    category_2_code: e.category_2_code,
                    category_3_code: e.category_3_code,
                    category_4_code: e.category_4_code,
                    systemchecktabactive_1: e.systemchecktabactive_1,
                    systemchecktabactive_2: e.systemchecktabactive_2,
                    systemchecktabactive_3: e.systemchecktabactive_3,
                    remindstatus: e.remindstatus,
                    remindsentcomingdesc: e.remindsentcomingdesc,
                    remindsentdesc: e.remindsentdesc,
                    remindnotpaydelivery: e.remindnotpaydelivery,
                    customernotconfirmaddressalready:
                        e.customernotconfirmaddressalready,
                    customerconfirmaddressalready:
                        e.customerconfirmaddressalready,
                    comingyanimage: e.comingyanimage,
                    addresskey: e.addresskey,
                    qty: e.qty,
                    increasesizedesc: e.increasesizedesc,
                    increasesizeorder: e.increasesizeorder,
                    ordertransaction: e.ordertransaction,
                    queyan: e.queyan,
                    trackyan: e.trackyan,
                    systemtrackyan: e.systemtrackyan,
                    birthday: Util.jsonParse(e.birthday),
                    queyanimage: e.queyanimage,
                    systemyanimage: e.systemyanimage,
                    queyandate: e.queyandate,
                    systemyandate: e.systemyandate,
                    yancomingdate: e.yancomingdate,
                    professortype: e.professortype,
                    deliverytype: e.deliverytype,
                    customerproductstype: e.customerproductstype,
                    productprice: e.productprice,
                    originalprice: e.originalprice,
                    productcode: e.productcode,
                    productsellorder: e.productsellorder,
                    customerorder: e.customerorder,
                    productkey: e.productkey,
                    customerproductssize: e.customerproductssize,
                    heartyanname: e.heartyanname,
                    productdetail: JSON.parse(e.productdetail || []),
                    introductionyan: e.introductionyan,
                    phone: e.phone,
                    p_phoneemergency: e.p_phoneemergency,
                    productyanimage: e.productyanimage,
                    productyanimagebase64: e.productyanimage,
                    introduction: e.introduction,
                    email: e.email,
                    career: e.career,
                    introductioncareer: e.introductioncareer,
                    lineid: e.lineid,
                    facebook: e.facebook,
                    customerphonethai: e.customerphonethai,
                    productstatus: e.productstatus,
                    productstatusdesc: e.productstatusdesc,
                    customername: e.customername,
                    customerlastname: e.customerlastname,
                    customernickname: e.customernickname,
                    customerbirthdaytype: e.customerbirthdaytype,
                    customerage: e.customerage,
                    customergender: e.customergender,
                    customerbirthday: e.customerbirthday,
                    customercareer: e.customercareer,
                    customerlineid: e.customerlineid,
                    customerfacebook: e.customerfacebook,
                    addressdesc: e.customeraddress,
                    provincepostinter: e.provincepostinter,
                    citypostinter: e.citypostinter,
                    postcodepostinter: e.postcodepostinter,
                    customerphone: e.customerphone,
                    customeremail: e.customeremail,
                    DistrictName: e.districtname,
                    PostCode: e.postcode,
                    selectcountry: {
                        selectcountry: e.customerselectcountry,
                        selectcountrycode: e.customerselectcountrycode
                    },
                    ProvinceName: e.provincename,
                    BorderName: e.bordername,
                    CustomerSelectCountry: e.customerselectcountry,
                    CustomerSelectcountryCode: e.customerselectcountrycode,
                    p_customerphoneemergency: e.p_customerphoneemergency,
                    p_customerdesc: e.p_customerdesc,
                    customerphone: e.customerphone,
                    customeraddressType:
                        e.p_customeraddresstype || e.customeraddresstype,
                    customerinternationalphone: e.customerinternationalphone,
                    orderdate: e.orderdate

                    // children: ProductChangeLog,
                },
                sellorderTransaction: e.sellorderTransaction,
                orderdate: e.orderdate,
                productjson: e.productjson,
                children: ProductChangeLog.length == 0 ? null : ProductChangeLog
            };
            // formStates.userproduct = product.userproduct

            console.log("product :", product);
            console.log("formState.userproduct", formState.userproduct);

            dataSource.value.push(product);

            formState.listproduct = dataSource.value;
     
            formState.productlist.listproduct = dataSource.value;
            countlist = countlist + 1;
            update.value++;
        };
        const handleclosemodalstock = e => {
 

            if (e == undefined) {
                visible.value = false;
                return;
            }

            var inside = [];
            var outside = [];

            if (e.pd_deliverytype == "inside") {
                inside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            if (e.pd_deliverytype == "outside") {
                outside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            var set_product = [];

            var product = {
                key: countlist,
                inside: inside,
                outside: outside,
                userproduct: {
                    quantitystock: e.quantitystock,
                    shotness: e.shotness,
                    stockuuid: e.productkey,
                    category_1: e.category_1,
                    category_2: e.category_2,
                    category_3: e.category_3,
                    category_4: e.category_4,
                    category_1_code: e.category_1_code,
                    category_2_code: e.category_2_code,
                    category_3_code: e.category_3_code,
                    category_4_code: e.category_4_code,
                    systemchecktabactive_1: e.systemchecktabactive_1,
                    systemchecktabactive_2: e.systemchecktabactive_2,
                    systemchecktabactive_3: e.systemchecktabactive_3,
                    remindstatus: e.remindstatus,
                    remindsentcomingdesc: e.remindsentcomingdesc,
                    remindsentdesc: e.remindsentdesc,
                    remindnotpaydelivery: e.remindnotpaydelivery,
                    customernotconfirmaddressalready:
                        e.customernotconfirmaddressalready,
                    customerconfirmaddressalready:
                        e.customerconfirmaddressalready,
                    comingyanimage: e.comingyanimage,
                    addresskey: e.addresskey,
                    qty: e.qty,
                    increasesizedesc: e.increasesizedesc,
                    increasesizeorder: e.increasesizeorder,
                    ordertransaction: e.ordertransaction,
                    queyan: e.queyan,
                    trackyan: e.trackyan,
                    systemtrackyan: e.systemtrackyan,
                    birthday: Util.jsonParse(e.birthday),
                    queyanimage: e.queyanimage,
                    systemyanimage: e.systemyanimage,
                    queyandate: e.queyandate,
                    systemyandate: e.systemyandate,
                    yancomingdate: e.yancomingdate,
                    professortype: e.professortype,
                    deliverytype: e.deliverytype,
                    customerproductstype: e.customerproductstype,
                    productprice: e.productprice,
                    originalprice: e.originalprice,
                    productcode: e.productcode,
                    productsellorder: e.productsellorder,
                    customerorder: e.customerorder,
                    productkey: e.productkey,
                    customerproductssize: e.customerproductssize,
                    heartyanname: e.heartyanname,
                    productdetail: JSON.parse(e.productdetail || []),
                    introductionyan: e.introductionyan,
                    phone: e.phone,
                    p_phoneemergency: e.p_phoneemergency,
                    productyanimage: e.productyanimage,
                    productyanimagebase64: e.productyanimage,
                    introduction: e.introduction,
                    email: e.email,
                    career: e.career,
                    introductioncareer: e.introductioncareer,
                    lineid: e.lineid,
                    facebook: e.facebook,
                    customerphonethai: e.customerphonethai,
                    productstatus: e.productstatus,
                    productstatusdesc: e.productstatusdesc,
                    customername: e.customername,
                    customerlastname: e.customerlastname,
                    customernickname: e.customernickname,
                    customerbirthdaytype: e.customerbirthdaytype,
                    customerage: e.customerage,
                    customergender: e.customergender,
                    customerbirthday: e.customerbirthday,
                    customercareer: e.customercareer,
                    customerlineid: e.customerlineid,
                    customerfacebook: e.customerfacebook,
                    addressdesc: e.customeraddress,
                    provincepostinter: e.provincepostinter,
                    citypostinter: e.citypostinter,
                    postcodepostinter: e.postcodepostinter,
                    customerphone: e.customerphone,
                    customeremail: e.customeremail,
                    DistrictName: e.districtname,
                    PostCode: e.postcode,
                    selectcountry: {
                        selectcountry: e.customerselectcountry,
                        selectcountrycode: e.customerselectcountrycode
                    },
                    ProvinceName: e.provincename,
                    BorderName: e.bordername,
                    CustomerSelectCountry: e.customerselectcountry,
                    CustomerSelectcountryCode: e.customerselectcountrycode,
                    p_customerphoneemergency: e.p_customerphoneemergency,
                    p_customerdesc: e.p_customerdesc,
                    customerphone: e.customerphone,
                    customeraddressType:
                        e.p_customeraddresstype || e.customeraddresstype,
                    customerinternationalphone: e.customerinternationalphone,
                    orderdate: e.orderdate

                    // children: ProductChangeLog,
                },
                sellorderTransaction: e.sellorderTransaction,
                orderdate: e.orderdate,
                productjson: e.productjson,
                children: null
            };

 
            //ใช้สต๊อก ตอนมี สินค้าในตาราง
            if (formStates.listproduct?.length > 0) {
                if (formStateStore.is_edit_product == "edit") {
                    product.userproduct.productkey = (
                        formStates.listproduct?.length - 1
                    ).toString();
                } else {
    
                    product.userproduct.productkey = formStates.listproduct?.length?.toString();
                }

                product.userproduct.ordertransaction =
                    formStates.listproduct[0]?.OrderTransaction;
            } else {
                //ใช้สต๊อก ตอนสร้างครั้งแรก
                product.userproduct.productkey = formStates.listproduct?.length?.toString();
                product.userproduct.ordertransaction =
                    formStates.user.ordertransaction;
            }
            formStates.userproduct = product.userproduct;

            showModalStock();
            // console.log("product :", product)
            // console.log("formState.userproduct", formState.userproduct)

            // dataSource.value.push(product);

            // formState.productlist.listproduct = dataSource.value
            // countlist = countlist + 1;
            //update.value++;
        };

        const handleclosemodalproduct = e => {
            visible.value = false;

            dataSource.value = [];

            try {
                formState.productlist.listproduct.forEach((items, index) => {
                    var result = _.transform(items, function(result, val, key) {
                        result[key.toLowerCase()] = val;
                    });

                    var inside = [];
                    var outside = [];
                    if (result.pd_deliverytype == "inside") {
                        inside = [
                            {
                                price: result.pd_price,
                                id: result.pd_id,
                                introduction: result.pd_introduction
                            }
                        ];
                    }
                    // else {
                    //     inside = formState.productlist.inside
                    // }
                    if (result.pd_deliverytype == "outside") {
                        outside = [
                            {
                                price: result.pd_price,
                                id: result.pd_id,
                                introduction: result.pd_introduction
                            }
                        ];
                    }
                    // else {
                    //     outside = formState.productlist.outside
                    // }
                    if (result.inside?.length > 0) inside = result.inside;
                    if (result.outside?.length > 0) outside = result.outside;

                    console.log(
                        "formState.productlist.listproductlogchange",
                        formState.productlist.listproductlogchange
                    );

                    var set_product = [];
                    var ProductChangeLog = [];

                    if (
                        result.productchangelog &&
                        formStateStore.is_edit_product == "increasesize"
                    ) {
                        JSON.parse(result.productchangelog).forEach(element => {
                            var itemchild = {
                                islog: true,
                                key: "",
                                userproduct: set_product
                            };
                            set_product = _.transform(element, function(
                                result,
                                val,
                                key
                            ) {
                                result[key?.toLowerCase()] = val;
                            });
                            itemchild.userproduct = set_product;
                            ProductChangeLog.push(itemchild);
                        });
                    }

                    var product = {
                        key: index + 1,
                        inside: inside,
                        outside: outside,
                        userproduct: {
                            quantitystock: result.quantitystock,
                            shotness: result.shotness,
                            category_1: result.category_1,
                            category_2: result.category_2,
                            category_3: result.category_3,
                            category_4: result.category_4,
                            category_1_code: result.category_1_code,
                            category_2_code: result.category_2_code,
                            category_3_code: result.category_3_code,
                            category_4_code: result.category_4_code,
                            systemchecktabactive_1:
                                result.systemchecktabactive_1,
                            systemchecktabactive_2:
                                result.systemchecktabactive_2,
                            systemchecktabactive_3:
                                result.systemchecktabactive_3,
                            remindstatus: result.remindstatus,
                            remindsentcomingdesc: result.remindsentcomingdesc,
                            remindsentdesc: result.remindsentdesc,
                            remindnotpaydelivery: result.remindnotpaydelivery,
                            customernotconfirmaddressalready:
                                result.customernotconfirmaddressalready,
                            customerconfirmaddressalready:
                                result.customerconfirmaddressalready,
                            comingyanimage: result.comingyanimage,
                            addresskey: result.addresskey,
                            queyan: result.queyan,
                            qty: result.qty,
                            increasesizedesc: result.increasesizedesc,
                            increasesizeorder: result.increasesizeorder,
                            ordertransaction: result.ordertransaction,
                            queyandate: result.queyandate,
                            systemyandate: result.systemyandate,
                            yancomingdate: result.yancomingdate,
                            trackyan: result.trackyan,
                            systemtrackyan: result.systemtrackyan,
                            queyanimage: result.queyanimage,
                            systemyanimage: result.systemyanimage,
                            professortype: result.professortype,
                            deliverytype: result.deliverytype,
                            customerproductstype: result.customerproductstype,
                            productprice: result.productprice,
                            originalprice: result.originalprice,
                            productcode: result.productcode,
                            productsellorder: result.productsellorder,
                            customerorder: result.customerorder,
                            productkey: result.productkey,
                            customerproductssize: result.customerproductssize,
                            heartyanname: result.heartyanname,
                            productdetail: safelyParseJSON(
                                result.productdetail || []
                            ),
                            introductionyan: result.introductionyan,
                            phone: result.phone,
                            phoneemergency: result.phoneemergency,
                            productyanimage: result.productyanimage,
                            introduction: result.introduction,
                            email: result.email,
                            career: result.career,
                            introductioncareer: result.introductioncareer,
                            lineid: result.lineid,
                            facebook: result.facebook,
                            customerphonethai: result.customerphonethai,
                            productstatus: result.productstatus,
                            productstatusdesc: result.productstatusdesc,
                            customername: result.customername,
                            customerlastname: result.customerlastname,
                            customernickname: result.customernickname,
                            customerbirthdaytype: result.customerbirthdaytype,
                            customerage: result.customerage,
                            customergender: result.customergender,
                            customerbirthday: result.customerbirthday,
                            customercareer: result.customercareer,
                            customerlineid: result.customerlineid,
                            customerfacebook: result.customerfacebook,
                            addressdesc: result.addressdesc,
                            provincepostinter: result.provincepostinter,
                            citypostinter: result.citypostinter,
                            postcodepostinter: result.postcodepostinter,
                            customerphone: result.customerphone,
                            customeremail: result.customeremail,
                            DistrictName: result.districtname,
                            PostCode: result.postcode,
                            selectcountry: {
                                selectcountry: result.customerselectcountry,
                                selectcountrycode:
                                    result.customerselectcountrycode
                            },
                            ProvinceName: result.provincename,
                            BorderName: result.bordername,
                            CustomerSelectCountry: result.customerselectcountry,
                            CustomerSelectcountryCode:
                                result.customerselectcountrycode,
                            p_customerphoneemergency:
                                result.p_customerphoneemergency,
                            p_customerdesc: result.p_customerdesc,
                            customerphone: result.customerphone,
                            customerinternationalphone:
                                result.customerinternationalphone,
                            customeraddressType:
                                result.p_customeraddresstype ||
                                result.customeraddresstype,
                            children:
                                ProductChangeLog.length > 0
                                    ? ProductChangeLog
                                    : formState.listproductlogchange,
                            orderdate: result.orderdate
                        },
                        sellorderTransaction: result.sellorderTransaction,
                        orderdate: result.orderdate,
                        productjson: result.productjson,
                        children:
                            ProductChangeLog.length > 0
                                ? ProductChangeLog
                                : formState.listproductlogchange
                    };
                    console.log("product >> ", product);
                    console.log(
                        "index :" + index + "product" + JSON.stringify(product)
                    );

                    const update = Object.fromEntries(
                        Object.entries(product).filter(([, v]) => v != null)
                    );
                    console.log("updatelog" + index + 1 + " result " + update);

                    dataSource.value.push(product);
              
                    formState.productlist.listproduct = dataSource.value;

                    update.value++;
                });
                countlist = countlist + 1;
                setTimeout(() => {
                    totalBorrow.value = 0;
                    totalDelivery.value = 0;
                    funtotals();
                    funtotalsdelivery();
                }, 1000);
            } catch (error) {
                console.log(error);
            }

            // if (is_edit_product.value.type == 'increasesize') {
            //     // if (e == undefined) {
            //     //     visible.value = false;
            //     // }
            //     setTimeout(() => {
            //         getCustomerProductlist();
            //     }, 2000);
            // }
        };

        const safelyParseJSON = obj => {
            if (typeof obj === "string") {
                try {
                    const parsedObject = JSON.parse(obj);
                    return parsedObject;
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    return null; // Return null or handle the error as needed
                }
            } else {
                return obj; // Return null or handle the error as needed
            }
        };
        const showprice = price => {
            if (typeof price == undefined || price == undefined) return "-";
            return price?.toLocaleString() + " บาท";
        };
        const totalBorrow = ref(0);
        const totalDelivery = ref(0);
        const funtotals = () => {
            dataSource.value.forEach(item => {
                try {
                    totalBorrow.value +=
                        item.userproduct.productprice *
                        (item.userproduct.qty || 1);
                } catch (error) {
                    console.log(error);
                }
            });
        };
        const funtotalsdelivery = () => {
            dataSource.value.forEach(item => {
                try {
                    item.outside.forEach(element => {
                        if (element.price)
                            totalDelivery.value += parseInt(element.price);
                    });
                    item.inside.forEach(element => {
                        if (element.price)
                            totalDelivery.value += parseInt(element.price);
                    });
                } catch (error) {
                    console.log(error);
                }
            });
        };
        const success = () => {
            Modal.success({
                title: "ลบสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const openNotification = massage => {
            notification["success"]({
                message: massage
            });
        };

        const showthaidate = date => {
            console.log("showthaidate ", date);
            if (date) {
                moment.locale("th");
                return moment(date)
                    .add(543, "year")
                    .format("LL");
            }
        };

        const handleCancel = e => {
            // update.value++
        };

        const onresultscan = e => {
            console.log(e);
            if (e.length) {
                isclick.value = "edit";
                isscan.value = true;
                is_edit_product.value = {
                    fact: false,
                    key: 0,
                    type: ""
                };
                openNotification("สแกนสำเร็จ");

                formStates.userproduct = { ...formStates.userproduct, ...e[0] };
                formStateStore.userproduct = {
                    ...formStates.userproduct,
                    ...e[0]
                };

                visible.value = true;
                update.value++;
            }
        };

        const getBase64FromUrl = async url => {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("l");
        };

        const showModalHistory = () => {
            gethistory();
            visiblehistory.value = true;
        };
        const handleOkHistory = e => {
            console.log(e);
            visiblehistory.value = false;
        };

        const showModalHistoryStock = () => {
            // gethistorystock()
            gethistorystocklist();
            visiblehistorystock.value = true;
        };
        const handleOkHistoryStock = e => {
            console.log(e);
            visiblehistorystock.value = false;
        };

        const gethistory = () => {
            BzbsUser.getproductlogchangehistory(
                props.formStates.user.sellordertransaction
            )
                .then(res => {
                    listhistorychangesize.value = res.data;
                    visiblehistory.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const gethistorystock = () => {
            BzbsUser.getproductstock(formStateStore.user.sellordertransaction)
                .then(res => {
                    listhistorystock.value = res.data;
                    visiblehistory.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const gethistorystocklist = () => {
            BzbsUser.getproductstocklistall()
                .then(res => {
                    listhistorystock.value = res.data;
                    visiblehistory.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onUseStock = (info, type) => {
            if (type == "edit") {
                type = "edit";
            }
            formStateStore.is_edit_product = type;

            is_edit_product.value.iseditstock = true;
            is_edit_product.value.iseditstock__uuid = info.ProductKey;
            visiblehistorystock.value = false;
            is_edit_product.value.type = "increasestock";

            getCustomerProductlist();

            update.value++;
        };

        return {
            onUseStock,
            listhistorychangesize,
            listhistorystock,
            gethistory,
            showModalHistory,
            handleOkHistory,
            showModalHistoryStock,
            handleOkHistoryStock,
            formatdate,
            getBase64FromUrl,
            isscan,
            onresultscan,
            handleCancel,
            showthaidate,
            openNotification,
            success,
            funtotalsdelivery,
            totalDelivery,
            totalBorrow,
            funtotals,
            addproduct,
            is_edit_product,
            handleclosemodalproduct,
            showprice,
            increasesize,
            resulttemp,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columnstock,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            onDelete,
            update,
            onEdit,
            onEditStock,
            edit_productjson,
            formStateStore,
            isclick,
            computed,
            Storedata,
            visiblehistory,
            visiblehistorystock,
            onDeleteStock,
            onEditStockHistory
        };
    }
});
</script>

<style lang="scss">
.disstock {
    pointer-events: none;
    color: gray !important;
}
</style>
