<template>
    <div class="mb-2">
        <a-button type="primary" @click="showModal">ลำดับงานในระบบ</a-button>
        <a-modal v-model:visible="visible" width="1200px" title="แสดงลำดับงาน" @ok="handleOk">
            <div style="display:flex;flex-wrap: wrap;justify-content: center;padding-bottom: 20px;">
                <div class="pr-3">
                    <a-tag color="#f50">สีแดง</a-tag> : ยังไม่ได้ใช้
                </div>
                <div>
                    <a-tag color="#87d068">สีเขียว</a-tag>:ใช้แล้ว
                </div>
            </div>
            <div style="display:flex;flex-wrap: wrap;justify-content: center;">
                <!-- <pre>{{ resultlist }}</pre> -->
                <div v-for="item in resultlist">
                    <div class="flex-container p-2">
                        <div v-if="item.status == 'empty'"><a-tag color="#f50">{{
                            item.ordertransaction }}</a-tag></div>
                        <div v-if="item.status == 'used'"> <a-tag color="#87d068">{{
                            item.ordertransaction }}</a-tag></div>

                    </div>
                </div>

            </div>
            <div v-if="resultlist.length == 0">
                <a-skeleton />
            </div>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    setup() {
        const resultlist = ref([])
        const getdata = () => {
            return BzbsUser.apigetproductnotcreate(null)
                .then(res => {
                    resultlist.value = res.data



                })
                .catch(error => {
                    console.log(error);
                });
        };

        const visible = ref(false);
        const showModal = () => {
            getdata()
            visible.value = true;
        };
        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        return {
            visible,
            showModal,
            handleOk,
            resultlist
        };
    },
});
</script>
<style>
.flex-container {
    display: flex;
    flex-direction: row;
}
</style>