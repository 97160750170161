<template>
    <div
        class="page max-w-full overflow-x-hidden w-full h-full flex flex-col card"
    >
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar
                    @clickexport="modalController.exportModal = true"
                ></Toolbar>
            </template>
        </TopBar>
        <!-- formState.masterproduct.mastertype.isshowsteps: {{ formState.masterproduct.isshowsteps }} -->
        <Step></Step>
        <!-- <div class="centerindiv"> -->
        <div class="p-4">
            <!-- <pre>            {{ formState.masterproduct }}</pre> -->

            <a-form
                :model="formState"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <div v-if="formState.masterproduct.items.type">
                    <DetailCatagory
                        :detail="formState.masterproduct.items"
                    ></DetailCatagory>
                </div>
                <div class="p-4">
                    กรอกข้อมูล ({{ formState.masterproduct.items?.typename }})
                </div>

                <a-form-item label="ชื่อสินค้าแสดงลูกค้า(1.1)">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Name
                        "
                    />
                </a-form-item>
                <!-- <a-form-item label="ขนาด">
                    <a-input v-model:value="formState.masterproduct.items.detail.Size" />
                </a-form-item>
                <a-form-item label="รหัสขนาด">
                    <a-input v-model:value="formState.masterproduct.items.detail.SizeCode" />
                                                                                                                                    </a-form-item> -->
                <a-form-item label="หมวดหมู่ 1">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_1
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 1"
                        :options="options_category_1"
                        @change="handleChange_Category_1($event)"
                    ></a-select>
                    <!-- <a-textarea v-model:value="formState.masterproduct.items.detail.Category_1"
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                  
                    </a-tooltip> -->
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 1">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_1_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 2">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_2
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 2"
                        :options="options_category_2"
                        @change="handleChange_Category_2"
                    ></a-select>
                    <!-- <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_2
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                    </a-tooltip> -->
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 2">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_2_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 3">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_3
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 3"
                        :options="options_category_3"
                        @change="handleChange_Category_3"
                    ></a-select>
                    <!-- <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_3
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                    </a-tooltip> -->
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 3">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_3_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 4">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_4
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 4"
                        :options="options_category_4"
                        @change="handleChange_Category_4"
                    ></a-select>
                    <!-- <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_4
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                    </a-tooltip> -->
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 4">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_4_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <!-- <a-form-item label="หมวดหมู่ 3">
                    <a-textarea v-model:value="formState.masterproduct.items.detail.Category_3"
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                        - องค์เทพ
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 3">
                    <a-textarea v-model:value="formState.masterproduct.items.detail.Category_3_Code"
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                    <a-tooltip> 
                        - รหัสรหัสองค์เทพ
                    </a-tooltip>
                </a-form-item> -->
                <a-form-item label="ตัวขั้น Hash track">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Track
                        "
                    />
                </a-form-item>
                <a-form-item label="รหัสสินค้า">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.ProductCode
                        "
                    />
                </a-form-item>
                <a-form-item label="ราคาขาย(ตัวเลข)">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Price
                        "
                        @input="fomatNum()"
                        type="number"
                    />
                </a-form-item>

                <a-form-item label="ต้นทุน(ตัวเลข)">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Cost
                        "
                        @input="fomatNum()"
                        type="number"
                    />
                </a-form-item>

                <a-form-item label="ตัวย่อ">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Desc
                        "
                        placeholder="ตัวย่อ"
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-popconfirm
                        :title="'บันทึก'"
                        @confirm="onSubmit()"
                        @cancel="cancel"
                    >
                        <a-button type="primary" html-type="submit"
                            >บันทึก</a-button
                        >
                    </a-popconfirm>
                    <!-- <a-button type="primary" @click="onSubmit">บันทึก</a-button> -->
                    <!-- <a-button style="margin-left: 10px">Cancel</a-button> -->
                    <!-- <a-button type="primary" html-type="submit">Submit</a-button> -->
                </a-form-item>

                <!-- <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertYanFastbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master ยันต์ (ยันต์ปลด/คิวด่วน)</a-button>
                    </a-popconfirm>
                </div>
                <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertYanbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master ยันต์</a-button>
                    </a-popconfirm>
                </div>
                <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertEventbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master Event</a-button>
                    </a-popconfirm>
                </div>
                <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertCaseWallbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master เคสวอ/เปเปอร์</a-button>
                    </a-popconfirm>
                </div>
               
                <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertholytbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master วัตถุมงคล</a-button>
                    </a-popconfirm>
                </div>
                <div class="p-3">
                    <a-popconfirm :title="'บันทึก'" @confirm="insertcaseandwalltbyjson()" @cancel="cancel">
                        <a-button type="primary">Insert Master ภาพพื้นหลัง</a-button>
                    </a-popconfirm>
                </div>   -->
            </a-form>
            <!-- <pre>{{ formState.masterproduct.items.detail }}</pre> -->
        </div>
        <!-- </div> -->
    </div>
</template>
<script>
import DetailCatagory from "@/module/Product/CustomerList/component/DetailCatagory.vue";
import Step from "@/module/Product/CustomerList/component/Step.vue";
import { defineComponent, ref, reactive } from "vue";
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import { useRouter } from "vue-router";
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import axios from "axios";
import { Modal } from "ant-design-vue";
import Util from "@/helper/Utility";
import { notification } from "ant-design-vue";
export default defineComponent({
    components: {
        TopBar,
        Step,
        DetailCatagory
    },
    setup() {
        const router = useRouter();

        const formState = reactive(formStateStore);

        if (formState.masterproduct.items == undefined) {
            setTimeout(() => {
                window.location = "/product";
                // router.push("/product");
            }, 100);
        }

        formState.masterproduct.status = 2;
        formState.masterproduct.isshowsteps = true;

        // const formState = =({
        //     name: '',
        //     delivery: false,
        //     type: [],
        //     resource: '',
        //     desc: '',
        // });

        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };

        const openNotification = message => {
            notification.open({
                message: message,
                duration: 0
            });
        };
        const onSubmit = () => {
            if (formState.masterproduct.items.detail.ProductCode) {
                apiinsertdetail();
            }
             else {
                openNotification("กรอกรหัสสินค้า");
            }
        };

        const apiinsertdetail = () => {
            formState.masterproduct.items.detail.BarCode =
                formState.masterproduct.items.detail.ProductCode;
            formState.masterproduct.items.detail.Type =
                formState.masterproduct.items?.type;
            BzbsUser.apiupdatemasterproduct(
                formState.masterproduct.items.detail
            )
                .then(res => {
                    success();
                    setTimeout(() => {
                        router.push("/insertproduct");
                    }, 1000);
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const cancel = e => {
            console.log(e);
        };

        const insertcaseandwalltbyjson = async () => {
            var typename = "caseandwall";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(path + "/api/getproductjson/" + typename)
                .then(res => {
                    res.data?.caseandwall?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item["สินค้า"]),
                            (obj.ProductCode = item["บาร์โค้ด"]),
                            (obj.BarCode = item["บาร์โค้ด"]),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = item["รหัสวัตถุมงคล"]),
                            (obj.Price = item["ราคา(บาท)"]),
                            (obj.Cost = item["ต้นทุน"]),
                            (obj.ShippingCost = null),
                            (obj.CashOnDelivery = null),
                            (obj.DeliveryType = null),
                            (obj.Desc = null),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item["ประเภท"]),
                            (obj.Category_1_Code = item["ประเภทหลัก"]),
                            (obj.Category_2 = item["รูปแบบ/สี/ไซส์"]),
                            (obj.Category_2_Code = item["รูปแบบ"]),
                            (obj.Category_3 = null),
                            (obj.Category_3_Code = null),
                            (obj.Category_4 = null),
                            (obj.Category_4_Code = null);
                        await BzbsUser.apiupdatemasterproduct(obj)
                            .then(res => {
                                console.log("index :", index, "res ", res);
                            })
                            .catch(error => {
                                console.log(
                                    "Error index :",
                                    index,
                                    "res ",
                                    res
                                );
                                console.log(error);
                            });
                        // templist.value.push(obj);
                    });
                });
        };

        const insertYanFastbyjson = async () => {
            var typename = "VIP";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(
                    "https://script.googleusercontent.com/macros/echo?user_content_key=uXjUQA3Y2puvlkFFGa6LJ1iWeLN1xYsgvkzKwE0kSO5TQ_Yq7-hYebbOQN405wZad-nuywWYbEmreMUTV_9azIN4Otw8IyMtm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJb5skwvlMdJaR7rY05IP-mn4umElW5Hz_zkuD72cT24sedqjIMHJIIKaFf5p1VCMGN1FykRBAGFeCvOJEMlr53AJRA-bBGbBNz9Jw9Md8uu&lib=MWM4FVxZ2n2KD5_vljb5bDd6ImNiVgkNq"
                )
                .then(res => {
                    res.data?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item.Name),
                            (obj.ProductCode = item.ProductCode),
                            (obj.BarCode = item.ProductCode),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = null),
                            (obj.Price = item.Price),
                            (obj.Cost = item.Cost),
                            (obj.ShippingCost = item.ShippingCost),
                            (obj.CashOnDelivery = item.CashOnDelivery),
                            (obj.DeliveryType = null),
                            (obj.Desc = item.Desc.toString()),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 =
                                item.Category_1 +
                                "(" +
                                item.Price?.toLocaleString() +
                                ")"),
                            (obj.Category_1_Code = item.ProductCode),
                            (obj.Category_2 = null),
                            (obj.Category_2_Code = null),
                            (obj.Category_3 = null),
                            (obj.Category_3_Code = null),
                            (obj.Category_4 = null),
                            // obj.Category_4_Code = item.Category_4_Code.toString(),
                            await BzbsUser.apiupdatemasterproduct(obj)
                                .then(res => {
                                    console.log("index :", index, "res ", res);
                                })
                                .catch(error => {
                                    console.log(
                                        "Error index :",
                                        index,
                                        "res ",
                                        res
                                    );
                                    console.log(error);
                                });
                        // templist.value.push(obj);
                    });
                });
        };

        const insertCaseWallbyjson = async () => {
            var typename = "CASWALL";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(
                    "https://script.googleusercontent.com/macros/echo?user_content_key=Rx_WNhTf3PKZth6MksSp6hNjengJHqgdRmXb2kSx_jEFvbBngHKCBh6uaOTkbmw7cXPIq7hBLpgv8Erlg-XIsn6w0eBS5rz9m5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnL7_B4ixfJ_0a8Ip6x9c5_RNooDkBspN38etsvbQjWyENlwsNDS_4rW0N-P9OhctRgVBFn9NdhQoPDQccYGiXmlBKABpDR5oUg&lib=MWM4FVxZ2n2KD5_vljb5bDd6ImNiVgkNq"
                )
                .then(res => {
                    res.data?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item.Name),
                            (obj.ProductCode = item.ProductCode),
                            (obj.BarCode = item.ProductCode),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = item.Track),
                            (obj.Price = item.Price),
                            (obj.Cost = item.Cost),
                            (obj.ShippingCost = item.ShippingCost),
                            (obj.CashOnDelivery = item.CashOnDelivery),
                            (obj.DeliveryType = null),
                            (obj.Desc = item.Desc),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item.Category_1),
                            (obj.Category_1_Code = item.Category_1_Code.toString()),
                            (obj.Category_2 = item.Category_2),
                            (obj.Category_2_Code = item.Category_2_Code.toString()),
                            (obj.Category_3 = item.Category_3),
                            (obj.Category_3_Code = item.Category_3_Code.toString()),
                            // obj.Category_4 =item.Goddesscode_radius,
                            // obj.Category_4_Code = item.Category_4_Code.toString(),
                            await BzbsUser.apiupdatemasterproduct(obj)
                                .then(res => {
                                    console.log("index :", index, "res ", res);
                                })
                                .catch(error => {
                                    console.log(
                                        "Error index :",
                                        index,
                                        "res ",
                                        res
                                    );
                                    console.log(error);
                                });
                        // templist.value.push(obj);
                    });
                });
        };
        const insertYanbyjson = async () => {
            var typename = "YAN";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(
                    "https://script.googleusercontent.com/macros/echo?user_content_key=lpvhDowaM8zw0jF-o3AHKIUKV6BCqqQmsVG81lW8McH43QZpgu-psXDXBul8hzw-yX9PJkSHhCKrTfowvQa4vRuatZM85cirm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJb5skwvlMdJaR7rY05IP-mn4umElW5Hz_zkuD72cT24sedqjIMHJIIKaFf5p1VCMGN1FykRBAGFeCvOJEMlr53AJRA-bBGbBNz9Jw9Md8uu&lib=MWM4FVxZ2n2KD5_vljb5bDd6ImNiVgkNq"
                )
                .then(res => {
                    res.data?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item.Name),
                            (obj.ProductCode = item.ProductCode),
                            (obj.BarCode = item.ProductCode),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = item.Track),
                            (obj.Price = item.Price),
                            (obj.Cost = item.Cost),
                            (obj.ShippingCost = item.ShippingCost),
                            (obj.CashOnDelivery = item.CashOnDelivery),
                            (obj.DeliveryType = null),
                            (obj.Desc = item.Desc),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item.Category_1),
                            (obj.Category_1_Code = item.Category_1_Code.toString()),
                            (obj.Category_2 = item.Category_2),
                            (obj.Category_2_Code = item.Category_2_Code.toString()),
                            (obj.Category_3 = item.Category_3),
                            (obj.Category_3_Code = item.Category_3_Code.toString()),
                            (obj.Category_4 = item.Goddesscode_radius),
                            // obj.Category_4_Code = item.Category_4_Code.toString(),
                            await BzbsUser.apiupdatemasterproduct(obj)
                                .then(res => {
                                    console.log("index :", index, "res ", res);
                                })
                                .catch(error => {
                                    console.log(
                                        "Error index :",
                                        index,
                                        "res ",
                                        res
                                    );
                                    console.log(error);
                                });
                        // templist.value.push(obj);
                    });
                });
        };

        // https://script.google.com/macros/s/AKfycbxL2KKRkW1FuhjRpFK724P4l6STYSTkSe6IEYOdAGZT3JFKcVZ_g8J88MV4e3Nwia2h/exec
        const insertEventbyjson = async () => {
            var typename = "EVT";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(
                    "https://script.google.com/macros/s/AKfycbxL2KKRkW1FuhjRpFK724P4l6STYSTkSe6IEYOdAGZT3JFKcVZ_g8J88MV4e3Nwia2h/exec"
                )
                .then(res => {
                    res.data?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item.Name),
                            (obj.ProductCode = item.ProductCode),
                            (obj.BarCode = item.ProductCode),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = null),
                            (obj.Price = item.Price),
                            (obj.Cost = item.Cost),
                            (obj.ShippingCost = item.ShippingCost),
                            (obj.CashOnDelivery = item.CashOnDelivery),
                            (obj.DeliveryType = null),
                            (obj.Desc = item.Desc),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item.Category_1),
                            (obj.Category_1_Code = item.Category_1_Code.toString()),
                            (obj.Category_2 = item.Category_2),
                            (obj.Category_2_Code = item.Category_2_Code.toString()),
                            (obj.Category_3 = item.Category_3),
                            (obj.Category_3_Code = item.Category_3_Code.toString()),
                            (obj.Category_4 = item.Category_4),
                            (obj.Category_4_Code = item.Category_4_Code.toString()),
                            await BzbsUser.apiupdatemasterproduct(obj)
                                .then(res => {
                                    console.log("index :", index, "res ", res);
                                })
                                .catch(error => {
                                    console.log(
                                        "Error index :",
                                        index,
                                        "res ",
                                        res
                                    );
                                    console.log(error);
                                });
                        // templist.value.push(obj);
                    });
                });
        };
        const insertholytbyjson = async () => {
            var typename = "NAW";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(
                    "https://script.google.com/macros/s/AKfycbyf1tMAw8jjc5WprPh_hXioYRwt6DVczvYDs4n-bzUOc_00GqFabFPnBDEmcx8bEJrvYA/exec"
                )
                .then(res => {
                    res.data?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item.Name),
                            (obj.ProductCode = item.ProductCode),
                            (obj.BarCode = item.ProductCode),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = null),
                            (obj.Price = item.Price),
                            (obj.Cost = item.Cost),
                            (obj.ShippingCost = item.ShippingCost),
                            (obj.CashOnDelivery = item.CashOnDelivery),
                            (obj.DeliveryType = null),
                            (obj.Desc = item.Desc),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item.Category_1),
                            (obj.Category_1_Code = item.Category_1_Code.toString()),
                            (obj.Category_2 = item.Category_2),
                            (obj.Category_2_Code = item.Category_2_Code.toString()),
                            (obj.Category_3 = item.Category_3),
                            (obj.Category_3_Code = item.Category_3_Code.toString()),
                            (obj.Category_4 = item.Category_4),
                            (obj.Category_4_Code = item.Category_4_Code.toString()),
                            await BzbsUser.apiupdatemasterproduct(obj)
                                .then(res => {
                                    console.log("index :", index, "res ", res);
                                })
                                .catch(error => {
                                    console.log(
                                        "Error index :",
                                        index,
                                        "res ",
                                        res
                                    );
                                    console.log(error);
                                });
                        // templist.value.push(obj);
                    });
                });
        };
        const insertbyjson = async () => {
            var typename = "yanpot";
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios
                .get(path + "/api/getproductjson/" + typename)
                .then(res => {
                    res.data?.yanpot?.map(async (item, index) => {
                        let obj = {};
                        (obj.Name = item["ชื่อสินค้าแสดงลูกค้า"]),
                            (obj.ProductCode = item["รวมรหัส"]),
                            (obj.BarCode = item["รวมรหัส"]),
                            (obj.Type = typename),
                            (obj.TypeCode = null),
                            (obj.Size = null),
                            (obj.SizeCode = null),
                            (obj.Track = item["ตัวขั้น Hash track"]),
                            (obj.Price = item["ราคาขาย"]),
                            (obj.Cost = item["ต้นทุน"]),
                            (obj.ShippingCost = null),
                            (obj.CashOnDelivery = null),
                            (obj.DeliveryType = null),
                            (obj.Desc = null),
                            (obj.DateCreate = null),
                            (obj.UpdateDate = null),
                            (obj.Category_1 = item["ขนาด"]),
                            (obj.Category_1_Code = item["รหัสขนาด"]),
                            (obj.Category_2 = item["หัวใจยันต์"]),
                            (obj.Category_2_Code = item["รหัสองค์เทพ"]),
                            (obj.Category_3 = null),
                            (obj.Category_3_Code = null),
                            (obj.Category_4 = null),
                            (obj.Category_4_Code = null);
                        await BzbsUser.apiupdatemasterproduct(obj)
                            .then(res => {
                                console.log("index :", index, "res ", res);
                            })
                            .catch(error => {
                                console.log(
                                    "Error index :",
                                    index,
                                    "res ",
                                    res
                                );
                                console.log(error);
                            });
                        // templist.value.push(obj);
                    });
                });
        };

        const fomatNum = val => {
            return Util.formatNum(val, 0);
        };
        const options_category_1 = ref([]);
        const options_category_2 = ref([]);
        const options_category_3 = ref([]);
        const options_category_4 = ref([]);

        const _init_options_category_1 = async () => {
            var obj = {
                type: formState?.masterproduct?.items?.type
            };
            await BzbsUser.apigetoptionscategory1(obj)
                .then(res => {
                    debugger;
                    options_category_1.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const _init_options_category_2 = async () => {
            var obj = {
                type: formState?.masterproduct?.items?.type,
                category_1: formState.masterproduct.items.detail.Category_1?.toString()
            };
            console.log("_init_options_category_2 :", obj);
            await BzbsUser.apigetoptionscategory2(obj)
                .then(res => {
                    debugger;
                    options_category_2.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const _init_options_category_3 = async () => {
            debugger;
            var obj = {
                type: formState?.masterproduct?.items?.type,
                category_2: formState.masterproduct.items.detail.Category_2?.toString()
            };
            console.log("obj : ", obj);
            await BzbsUser.apigetoptionscategory3(obj)
                .then(res => {
                    debugger;
                    options_category_3.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const _init_options_category_4 = async () => {
            debugger;
            var obj = {
                type: formState?.masterproduct?.items?.type,
                category_3: formState.masterproduct.items.detail.Category_3?.toString()
            };
            console.log("obj : ", obj);
            await BzbsUser.apigetoptionscategory4(obj)
                .then(res => {
                    debugger;
                    options_category_4.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        _init_options_category_1();

        const handleChange_Category_1 = e => {
            formState.masterproduct.items.detail.Category_2 = [];
            formState.masterproduct.items.detail.Category_2_Code = [];
            formState.masterproduct.items.detail.Category_3 = [];
            formState.masterproduct.items.detail.Category_3_Code = [];
            formState.masterproduct.items.detail.Category_4 = [];
            formState.masterproduct.items.detail.Category_4_Code = [];
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_1.length > 1) {
                formState.masterproduct.items.detail.Category_1 = formState.masterproduct.items.detail.Category_1.pop();
            }
            formState.masterproduct.items.detail.Category_1_Code = options_category_1.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_1
            )?.Category_1_Code;
            _init_options_category_2();
        };
        const handleChange_Category_2 = e => {
            formState.masterproduct.items.detail.Category_3 = [];
            formState.masterproduct.items.detail.Category_3_Code = [];
            formState.masterproduct.items.detail.Category_4 = [];
            formState.masterproduct.items.detail.Category_4_Code = [];
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_2.length > 1) {
                formState.masterproduct.items.detail.Category_2 = formState.masterproduct.items.detail.Category_2.pop();
            }
            formState.masterproduct.items.detail.Category_2_Code = options_category_2.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_2
            )?.Category_2_Code;
            _init_options_category_3();
        };
        const handleChange_Category_3 = e => {
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_3.length > 1) {
                formState.masterproduct.items.detail.Category_3 = formState.masterproduct.items.detail.Category_3.pop();
            }
            formState.masterproduct.items.detail.Category_3_Code = options_category_3.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_3
            )?.Category_3_Code;
            _init_options_category_4();
        };
        const handleChange_Category_4 = e => {
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_4.length > 1) {
                formState.masterproduct.items.detail.Category_4 = formState.masterproduct.items.detail.Category_4.pop();
            }
            formState.masterproduct.items.detail.Category_4_Code = options_category_4.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_4
            )?.Category_4_Code;
        };

        return {
            options_category_1,
            options_category_2,
            options_category_3,
            options_category_4,
            handleChange_Category_1,
            handleChange_Category_2,
            handleChange_Category_3,
            handleChange_Category_4,
            insertYanFastbyjson,
            insertYanbyjson,
            insertcaseandwalltbyjson,
            insertholytbyjson,
            insertEventbyjson,
            insertbyjson,
            cancel,
            onSubmit,
            apiinsertdetail,
            insertCaseWallbyjson,
            formState,
            labelCol: {
                style: {
                    width: "150px"
                }
            },
            wrapperCol: {
                span: 14
            }
        };
    }
});
</script>
