<template>
    <template v-for="(tag) in tags" :key="tag">
        <a-tooltip v-if="tag.length > 20" :title="tag">
            <a-tag :closable="true" @close="handleClose(tag)">
                {{ `${tag.slice(0, 20)}...` }}
            </a-tag>
        </a-tooltip>
        <a-tag v-else :closable="true" @close="handleClose(tag)">
            {{ tag }}
        </a-tag>
    </template>
    <a-input v-if="inputVisible" ref="inputRef" v-model:value="inputValue" type="text" size="small"
        :style="{ width: '78px' }" @blur="handleInputConfirm" />
    <a-tag v-else style="background: #fff; border-style: dashed" @click="showInput">
        <plus-outlined />
        New Tag
    </a-tag>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, nextTick } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
export default defineComponent({
    props: ['googleseotags'],
    components: {
        PlusOutlined,
    },
    setup(props, { emit }) {
        const inputRef = ref();
        const state = reactive({
            tags: [],
            inputVisible: false,
            inputValue: '',
        });
        const googleseotags = reactive(props.googleseotags);
        if (googleseotags) { 
            state.tags = googleseotags
        }
        const senttag = () => { 
            emit("ontags", state.tags) 
        };

        const handleClose = removedTag => { 
            const tags = state.tags.filter(tag => tag !== removedTag);
            console.log(tags);
            state.tags = tags;
            emit("ontags", tags)
        };

        const showInput = () => {
            state.inputVisible = true;
            nextTick(() => {
                inputRef.value.focus();
            });
        };

        const handleInputConfirm = () => {
            const inputValue = state.inputValue;
            let tags = state.tags;
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue];
            }
            emit("ontags", tags)

            console.log(tags);
            Object.assign(state, {
                tags,
                inputVisible: false,
                inputValue: '',
            });
        };
        return {
            ...toRefs(state),
            handleClose,
            showInput,
            handleInputConfirm,
            inputRef,
            senttag,

        };
    },

});
</script>