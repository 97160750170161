import mitt from "mitt";
import Util from "@/helper/Utility.js";
/**
 * setting
 * @method setTitle
 * @method setMsg
 * @method setBtnOk
 * @method clearModal
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onClickOk
 * event
 * @event sendCallbackOk
 */
export function ShippingAddressModalController () {
    this.busModal = mitt();
    this.title = '';
    this.btnOk = '';

    this.callbackOk = null;
    this.callbackSaveAddress = null;

    //#region setter
    this.clearModal = function () {
        this.title = '';
        this.btnOk = '';
        this.callbackOk = null;
        this.callbackSaveAddress = null;
        return this;
    };
    this.setTitle = function (title) {
        this.title = title;
        return this;
    };
    this.setBtnOk = function (btnOk) {
        this.btnOk = btnOk;
        return this;
    };
    //#endregion setter

    //#region method
    this.show = function () {
        let config = {
            title: this.title,
            btnOk: this.btnOk,
        };
        this.busModal.emit('show', config);
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event
    this.onClickOk = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackOk = callback;
        }
    },
    this.sendCallbackOk = function(data) {
        if (Util.isFunction(this.callbackOk)) {
            this.callbackOk(data);
        }
    };

    this.onSaveAddress = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackSaveAddress = callback;
        }
    };
    this.sendCallbackSaveAddress = function(data) {
        if (Util.isFunction(this.callbackSaveAddress)) {
            this.callbackSaveAddress(data);
        }
    };
    //#endregion event
}
