<template>
    <div class="text-center text-yellow-500">
        <div v-html="privilegeMsg"></div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: "PrivilegeMessage",
    mixins: [LocaleMixin],
    props: {
        serialModel: Object,
    },
    data: function() {
        return {
            privilegeMsg: "",
        };
    },
    created: function() {
        this.setPrivilegeMessage();
    },
    methods: {
        setPrivilegeMessage: function() {
            if (this.isSetPrivilege()) {
                this.privilegeMsg = this.serialModel.privilegeMessage;
            } else {
                this.privilegeMsg = this.lbl.serial.show_code_at_shop;
            }
        },
        isSetPrivilege: function() {
            if (Util.isEmptyStr(this.serialModel.privilegeMessage)) {
                return false;
            }

            if (this.serialModel.privilegeMessageFormat == "link") {
                return false;
            }

            return true;
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
