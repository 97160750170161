<template>
  <div>
    <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 24 }" autocomplete="off"
      @finish="onFinish" @finishFailed="onFinishFailed">
      <a-row>
        <a-col class="p-1" flex="1 1" :order="1">
          <a-form-item :name="['userproduct', 'customerproductstype']" label="สินค้าที่สั่งซื้อ*(1.2)"
            :rules="[{ required: true, message: 'Missing area' }]" has-feedback>
            <a-select @change="OnChangeEmit()" v-model:value="formState.userproduct.customerproductstype"
              style="width: 500px" :options="productData.map((pro) => ({ value: pro }))"></a-select>
          </a-form-item>
        </a-col>
        <a-col class="p-1" flex="1 1 500px" :order="1"> </a-col>
        <a-col flex="1 1 500px" :order="3"> </a-col>
      </a-row>
      <Yan v-if="formState.userproduct.customerproductstype == 'ยันต์เขียนสด'" :key="updatecomponent"
        :formStates="formState" @ProductstypeData="onchangeYan($event)"></Yan>
      <YanPot v-if="formState.userproduct.customerproductstype == 'ยันต์ปลด/คิวด่วน'" :key="updatecomponent"
        :formStates="formState" @ProductstypeData="onchangeYan($event)"></YanPot>
      <Mobileandwall v-if="formState.userproduct.customerproductstype == 'วัตถุมงคล'" :key="updatecomponent"
        :formStates="formState" @ProductstypeData="onchangeYan($event)"></Mobileandwall>
      <Caseandwall v-if="formState.userproduct.customerproductstype == 'เคส/ภาพพื้นหลัง'" :key="updatecomponent"
        :formStates="formState" @ProductstypeData="onchangeYan($event)"></Caseandwall>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
// import axios from "axios";
// import Baseinput from "../form/Baseinput.vue";
import Yan from "../productcomponent/products/Yan.vue";
import YanPot from "../productcomponent/products/YanPot.vue";
import Mobileandwall from "../productcomponent/products/Mobileandwall";
import Caseandwall from "./products/Caseandwall.vue";

export default defineComponent({
  components: { Yan, YanPot, Mobileandwall, Caseandwall },
  props: {
    formStates: null,
  },
  emits: ["ProductTypeData"],
  setup(props, { emit }) {
    const gender = ref("1");
    const queyan = ref("1");
    const typebirthday = ref("1");

    const is_paid_delivery = ref("1");

    const updatecomponent = ref(0);
    const agegender = ref("18");
    const formState = reactive(props.formStates);

    // formState = props.formState
    const productData = ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน", "วัตถุมงคล", "เคส/ภาพพื้นหลัง"];
    // const sights = reactive();
    const sights = {
      ยันต์เขียนสด: [],
      หัวใจยัน: [],
    };

    const sizeproduct = ref(null);
    const heartyanname = ref(null);
    const productdetail = ref(null);
    const yanname = [
      {
        label: "หัวใจยันต์1",
        value: "หัวใจยันต์1",
      },
      {
        label: "หัวใจยันต์2",
        value: "หัวใจยันต์2",
      },
      {
        label: "หัวใจยันต์3",
        value: "หัวใจยันต์3",
      },
      {
        label: "หัวใจยันต์4",
        value: "หัวใจยันต์4",
      },
    ];

    watch(
      () => formState.userproduct.customerproductstype,
      () => {
        // formState.userproduct = {};
        updatecomponent.value += updatecomponent.value++;
      }
    );
    onMounted(() => { });

    // const queryyan = (filename) => {
    //   let path = window.location.host.includes("localhost")
    //     ? "http://localhost:8090"
    //     : "https://process.env.VUE_APP_API_BASE_URLhayanbackoffice.azurewebsites.net";
    //   return axios.get(path + "/api/getproductjson/" + filename).then((res) => {
    //     if (formState.userproduct.customerproductstype == "ยันต์เขียนสด") {
    //       setupselectyan(res);
    //     } else if (formState.userproduct.customerproductstype == "วัตถุมงคล") {

    //       setupselectmobile(res);
    //     }
    //   });
    // };
    // const setupselectmobile = (res) => {
    //   res.data.mobile.forEach((element) => {
    //     sightsmobile["สินค้า"].push(element["สินค้า"]);
    //     // var objheartyan = {};
    //     // objheartyan.yan_size = element["ขนาด"];
    //     // objheartyan.heartyan = element["หัวใจยันต์"];
    //     // objheartyan.price = element["ราคาขาย"];
    //     // objheartyan.yancode = element["รวมรหัส"];
    //     // sights["หัวใจยัน"].push(objheartyan);
    //   });
    //   let productall = sightsmobile["สินค้า"].filter((c, index) => {
    //     return sightsmobile["สินค้า"].indexOf(c) === index;
    //   });
    //   sizeproduct.value = productall;
    // };

    // const setupselectyan = (res) => {
    //   res.data.yan.forEach((element) => {
    //     sights["ยันต์เขียนสด"].push(element["ขนาด"]);
    //     var objheartyan = {};
    //     objheartyan.yan_size = element["ขนาด"];
    //     objheartyan.heartyan = element["หัวใจยันต์"];
    //     objheartyan.price = element["ราคาขาย"];
    //     objheartyan.yancode = element["รวมรหัส"];
    //     sights["หัวใจยัน"].push(objheartyan);
    //   });
    //   let yan = sights["ยันต์เขียนสด"].filter((c, index) => {
    //     return sights["ยันต์เขียนสด"].indexOf(c) === index;
    //   });
    //   sizeproduct.value = yan;
    // };

    const formRef = ref();
    const dynamicValidateForm = reactive({
      sights: [],
      area: undefined,
    });
    watch(
      () => dynamicValidateForm.area,
      () => {
        dynamicValidateForm.sights = [];
      }
    );

    const setheartyan = () => {
      emit("ProductstypeData", formState.userproduct);
      formState.userproduct.heartyanname = "";
      let listheartyanname = [];
      sights["หัวใจยัน"].filter((c) => {
        if (c.yan_size == formState.userproduct.customerproductssize) {
          listheartyanname.push(c);
        }
      });
      heartyanname.value = listheartyanname;
    };
    const removeSight = (item) => {
      let index = dynamicValidateForm.sights.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.sights.splice(index, 1);
      }
    };

    const addSight = () => {
      dynamicValidateForm.sights.push({
        value: undefined,
        price: undefined,
        typebirthday: "1",
        id: Date.now(),
      });
    };

    const onFinish = (values) => {
      console.log("formState :", formState);
      emit("update:value", formState);
      emit("closemodal", false);
      formItemContext.onFieldChange();
      console.log("Received values of form:", values);
      console.log("dynamicValidateForm:", dynamicValidateForm);
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const isdisabled = (sights) => {
      if (sights.typebirthday == 1) {
        delete sights.birthday;
        delete sights.age;
        return true;
      } else {
        sights.age = calculateAge(sights);
      }
    };

    const calculateAge = (sights) => {
      var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        return 0;
      }
    };
    const formItemContext = Form.useInjectFormItemContext();
    const fromchange = () => {
      console.log("formState :", formState);
      emit("update:value", formState);
      formItemContext.onFieldChange();
    };
    const OnChangeEmit = () => {
       
      formState.userproduct.customerproductssize = ""
      formState.userproduct.heartyanname = ""
      formState.userproduct.customerproduct = ""
      formState.userproduct.sub_customerproduct = ""
      formState.userproduct.productcode = ""
      formState.userproduct.productprice = ""
      formState.userproduct.productdetail = "" 
      updatecomponent.value++
      // formState.userproduct.yandetail =
      //   heartyanname[formState.userproduct.heartyanname];
      emit("ProductstypeData", formState.userproduct);
    };
    const OnChangeEmitIntroductionyan = () => {
      emit("ProductstypeData", formState.userproduct);
    };
    const handleChange = (value) => {
      heartyanname.value.forEach((element) => {
        if (element.heartyan == value) {
          formState.userproduct.productdetail = element;
        }
      });
    };
    const onchangeYan = () => {
      console.log("ProductstypeData 1:", formState.userproduct)
      emit("ProductstypeData", formState.userproduct);
    };

    return {
      onchangeYan,
      OnChangeEmit,
      OnChangeEmitIntroductionyan,
      locale,
      formRef,
      dynamicValidateForm,
      onFinish,
      removeSight,
      addSight,
      sights,
      gender,
      agegender,
      queyan,
      is_paid_delivery,
      formState,
      fromchange,
      yanname,
      typebirthday,
      isdisabled,
      calculateAge,
      onFinishFailed,
      productData,
      sizeproduct,
      heartyanname,
      setheartyan,
      productdetail,
      handleChange,
      updatecomponent,
    };
  },
});
</script>