<template>
    (checking 1.1.2)
    <a-table
        v-if="dataSource.length != 0"
        size="small"
        :key="update"
        :columns="columns"
        :data-source="dataSource"
        bordered
    >
        <template #bodyCell="{ column, text, record }">
            <!-- <pre>{{ record }}</pre> -->
            <!-- {{record}} -->
            <template
                v-if="['name', 'age', 'address'].includes(column.dataIndex)"
            >
                <div>
                    <a-input
                        v-if="editableData[record.key]"
                        v-model:value="
                            editableData[record.key][column.dataIndex]
                        "
                        style="margin: -5px 0"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'key'">
                <div>
                    <u
                        >หมายเลขการขาย
                        {{ record.userproduct.productsellorder }}</u
                    >
                </div>
                <div>รายการที่ {{ record.key }}</div>
            </template>
            <template v-else-if="column.dataIndex === 'price'">
                <div>{{ showprice(record.userproduct.productprice) }}</div>
            </template>
            <template v-else-if="column.dataIndex === 'que'">
                <div>{{ record.userproduct.trackyan }}</div>
                <div>{{ record.userproduct.queyandate }}</div>
            </template>
            <template v-else-if="column.dataIndex === 'detail'">
                <div>
                    <div v-if="record.userproduct.professortype.length > 0">
                        <div
                            v-if="
                                record.userproduct.professortype.includes('1')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >อาจารย์เลือกให้</a-typography-text
                            >
                        </div>
                        <div
                            v-if="
                                record.userproduct.professortype.includes('2')
                            "
                        >
                            <span style="color:red">*</span>
                            <a-typography-text mark
                                >รอคุยกับอาจารย์</a-typography-text
                            >
                        </div>
                    </div>
                </div>
            </template>

            <template v-else-if="column.dataIndex === 'userproduct'">
                <div>
                    <div>{{ record.userproduct.customerproductstype }}</div>
                    <div>{{ record.userproduct.heartyanname }}</div>
                    <div>{{ record.userproduct.customerproductssize }}</div>
                    <div>{{ record.userproduct.productcode }}</div>
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'productyanimage'">
                <div>
                    <a-image
                        :width="100"
                        :src="record.userproduct.comingyanimage"
                    />
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'productslipimageurl'">
                <div>
                    <a-image
                        :width="100"
                        :src="record.userproduct.slipimageurl"
                    />
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'deliver'">
                <div v-if="record.userproduct.deliverystatus == 'prepare_pack'">
                    <a-typography-text type="warning"
                        >เคยใช้สร้างรายการจัดส่งแล้ว</a-typography-text
                    >
                </div>
            </template>

            <template v-else-if="column.dataIndex === 'paiddeliveryobj'">
                <div>
                    <div v-for="item in record?.inside" :key="item.id">
                        {{ showprice(item.price) }}
                    </div>
                    <div v-for="item in record?.outside" :key="item.id">
                        {{ showprice(item.price) }}
                    </div>
                      <a-typography-text
                        type="danger"
                        strong
                        v-if="
                            record?.insidecod.length == 0 &&
                                record?.inside.length == 0 &&
                                record?.outside.length == 0
                        "
                    >
                        (*สำคัญ อย่าลืมลงค่าจัดส่งปกติในระบบ)
                    </a-typography-text>
                    <Video
                        v-if="
                            record?.insidecod.length == 0 &&
                                record?.inside.length == 0 &&
                                record?.outside.length == 0
                        "
                        :url="
                            'https://namahayan.blob.core.windows.net/video/Screen Recording 2566-01-25 at 12.45.25.mov'
                        "
                    >
                    </Video> 
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'additionalshipping'">
                <div>
                    {{ showprice(record.additionalshipping) }}
                </div>
                <!-- <pre>{{record}}</pre> -->
            </template>

            <template v-else-if="column.dataIndex === 'introductionyan'">
                <div>
                    {{ record.userproduct.introductionyan }}
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'productstatus'">
                <div>
                    <a-typography-text
                        type="danger"
                        strong
                        v-if="record.userproduct.productstatus == 'cancel'"
                        >{{
                            record.userproduct.productstatus
                        }}</a-typography-text
                    >
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'productstatusdesc'">
                <div>
                    <a-typography-text
                        type="success"
                        strong
                        v-if="record.userproduct.productstatus == 'cancel'"
                        >{{
                            record.userproduct.productstatusdesc
                        }}</a-typography-text
                    >
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'increasesize'">
                <div>
                    <span class="m-2">
                        <a-popconfirm
                            v-if="dataSource.length"
                            title="คุณต้องการเพิ่มขนาด?"
                            @confirm="increasesize(record.key)"
                        >
                            <fullscreen-outlined
                                :style="{
                                    fontSize: '24px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>เพิ่มขนาด</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>

            <template v-else-if="column.dataIndex === 'operation'">
                <div class="editable-row-operations">
                    <span v-if="editableData[record.key]">
                        <a-typography-link @click="save(record.key)"
                            >Save</a-typography-link
                        >
                        <a-popconfirm
                            title="Sure to cancel?"
                            @confirm="cancel(record.key)"
                        >
                            <a>Cancel</a>
                        </a-popconfirm>
                    </span>
                    <!-- <span v-else class="m-2">
                        <a @click="edit(record.key)">Edit</a>
                    </span> -->
                    <span class="m-2">
                        <a-popconfirm
                            v-if="dataSource.length"
                            title="คุณต้องการแก้ไข?"
                            @confirm="onEdit(record)"
                        >
                            <setting-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>แก้ไข</a>
                        </a-popconfirm>
                    </span>
                    <span class="m-2">
                        <a-popconfirm
                            v-if="dataSource.length"
                            title="คุณต้องการลบ?"
                            @confirm="onDelete(record.key)"
                        >
                            <delete-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>ลบ</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
        </template>
    </a-table>
    <TableLoad v-else :isload="true"></TableLoad>

    <MoreDeliveryTypeForAccounting></MoreDeliveryTypeForAccounting>

    <a-button
        class="mt-4 float-right "
        type="primary"
        :loading="loading"
        @click="start"
    >
        อัพเดทบันทึกค่าจัดส่งเพิ่ม
    </a-button>

    <!-- <pre>{{formStates.listproduct}}</pre> -->
    <!-- formStates :
    <pre>{{formStates}}</pre>
    ===========================
    dataSource :
    <pre>{{dataSource}}</pre> -->
    <!-- //ตาราง -->
</template>
<script>
import {
    defineComponent,
    reactive,
    ref,
    watch,
    onMounted,
    toRefs,
    computed
} from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
//import ListCreateproduct from "@/module/CustomerOrder/SellCreate/component/form/ListCreateproduct.vue";
// import { formStateStore } from "../../../../../store/storebackoffice";
import _ from "lodash";
import { formStateStore } from "@/store/storebackoffice";
// import { formStatestore } from "@/store/store";
import TableLoad from "@/components/Skeleton/TableLoad";
import {
    FullscreenOutlined,
    SettingOutlined,
    DeleteOutlined
} from "@ant-design/icons-vue";
import MoreDeliveryTypeForAccounting from "./MoreDeliveryTypeForAccounting.vue";
import moment from "moment";
import { notification } from "ant-design-vue";
import Video from "../../../components/Video/Video.vue";
import { PaymentAdditionalModel } from "@/core/Create/model/CreateModel";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "key"
    },
    {
        title: "รูปสินค้า",
        dataIndex: "productyanimage"
    },
    {
        title: "รูปสลิป",
        dataIndex: "productslipimageurl"
    },
    {
        title: "สินค้า",
        dataIndex: "userproduct"
    },
    {
        title: "ค่าส่ง",
        dataIndex: "paiddeliveryobj"
    },
    {
        title: "ค่าส่งเพิ่ม",
        dataIndex: "additionalshipping"
    },
    // {
    //     title: "ข้อมูล",
    //     dataIndex: "detail",
    // },
    {
        title: "ราคาสินค้า",
        dataIndex: "price"
    },
    {
        title: "การจัดส่ง",
        dataIndex: "deliver"
    }
    // {
    //     title: "คิวเขียน",
    //     width: "7.6%",
    //     dataIndex: "que"
    // },
    // {
    //     title: "วันที่ยันต์มา",
    //     width: "7.6%",
    //     dataIndex: "dateyanarrival"
    // },
    // {
    //     title: "หมายเหตุ",
    //     width: "7.6%",
    //     dataIndex: "introductionyan"
    // },
    // {
    //     title: "สถานะ",
    //     width: "7.6%",
    //     fixed: 'right',
    //     dataIndex: "productstatus"
    // },
    // {
    //     title: "สถานะ(รายละเอียด)",
    //     width: "7.6%",
    //     fixed: 'right',
    //     dataIndex: "productstatusdesc"
    // },
    // {
    //     title: "เพิ่มขนาด",
    //     width: "10%",
    //     fixed: 'right',
    //     dataIndex: "increasesize"
    // },
    // {
    //     title: 'จัดการ',
    //     dataIndex: "operation",
    //     fixed: 'right',
    //     width: "12%"
    // }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        SettingOutlined,
        DeleteOutlined,
        FullscreenOutlined,
        TableLoad,
        MoreDeliveryTypeForAccounting,
        Video
    },
    props: {
        textbtn: null,
        formStates: null
    },
    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();
        const listgroupby_selectedRowKeys = ref([]);
        const visible = ref(false);
        const visibleEdit = ref(false);
        const dataSource = ref([]);
        var edit_productjson = ref(null);
        // const formStates = reactive();

        onMounted(() => {
            dataSource.value = [];

            console.log(
                "formStateStore.user.selectedRowKeys : ",
                formStateStore.user.selectedRowKeys
            );

            formStateStore.user.selectedRowKeys = [route.query.so];
            formStateStore.user.customercode = route.query.customercode;
            formStateStore.user.selectedRowKeys.forEach(element => {
                var obj_item = {
                    ProductSellOrder: element,
                    item: []
                };
                listgroupby_selectedRowKeys.value.push(obj_item);
                getCustomerProductlist(element);
            });
        });
        const getCustomerProductlist = async sellordertransaction => {
            await BzbsUser.apigetproductbyid(sellordertransaction)
                .then(res => {
                    console.log("CustomerProductbyCustomercode", res.data);

                    formStateStore.listproduct = [];
                    formStateStore.listproduct = res.data;
                    res.data.forEach(items => {
                        var result = _.transform(items, function(
                            result,
                            val,
                            key
                        ) {
                            result[key.toLowerCase()] = val;
                        });
                        debugger
                        if(result.pd_additionaltage == route.query.sp)
                        handleclosemodal(result, sellordertransaction);
                    });

                    // dataSource.value = [
                    //     {
                    //         key: 2,
                    //         inside: {},
                    //         outside: {},
                    //         userproduct: {
                    //             queyan: "1",
                    //             professortype: [],
                    //             customerproductstype: "ยันต์เขียนสด",
                    //             productprice: 1599,
                    //             productcode: "YAN010NN01HXXXXXX",
                    //             customerproductssize: "10x10 ยันต์เปิดดวง",
                    //             heartyanname: "10 สิบทิศ",
                    //             productdetail: {
                    //                 yan_size: "10x10 ยันต์เปิดดวง",
                    //                 heartyan: "10 สิบทิศ",
                    //                 price: 1599,
                    //                 productcode: "YAN010NN01HXXXXXX"
                    //             },
                    //             introductionyan: "sss",
                    //             phone: "sss",
                    //             phoneemergency: "ss"
                    //         },
                    //         sellorderTransaction: {},
                    //         orderdate: {}
                    //     }
                    // ]);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const update = ref(1);

        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "Thailand"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    )
                ) {
                    formState.useraddress.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    );
                }
            },
            () => formState.productlist,
            () => {
                console.log(formState.productlist);

                // formState.listproduct.forEach(items => {
                //     var result = _.transform(items, function (
                //         result,
                //         val,
                //         key
                //     ) {
                //         result[key.toLowerCase()] = val;
                //     });

                //     console.log(result)
                //     handleclosemodal(result);
                // });
            }
        );
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            if (visibleEdit.value) {
                // formState.listproduct[editIndex.value] = formState.useraddress;
            }

            // else {
            //     let obj = {};
            //     obj = formState.useraddress;
            //     // obj.key = i;
            //     // console.log("obj.key", obj.key);
            //     // obj.key =
            //     //     list.listproduct.length == 0 ? 0 : list.listproduct.length;
            //     console.log("obj.key", obj.key);
            //     console.log("obj", obj);
            //     list.listproduct[obj.key] = obj;
            //     console.log("list.listproduct", list.listproduct);
            // }
            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };

        console.log("list.listproduct ", list.listproduct);

        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            console.log(key);
            // Object.assign(
            //     dataSource.value.filter(item => key === item.key)[0],
            //     editableData[key]
            // );
            // delete editableData[key];
        };

        const cancel = key => {
            delete editableData[key];
        };
        const onDelete = key => {
            // dataSource.value = dataSource.value.filter(
            //     item => item.key !== key
            // );
            dataSource.value.splice(
                dataSource.value.findIndex(e => e.key === key),
                1
            );
            update.value++;

            // emit("update:value", dataSource.value);
            formStateStore.listproduct = [];
            dataSource.value.forEach(element => {
                formStateStore.listproduct.push(element.userproduct);
            });

            console.log(
                " formStateStore.listproduct :",
                formStateStore.listproduct
            );
        };

        const onEdit = info => {
            console.log(info);

            formStateStore.userproduct = info.userproduct;
            // console.log(form)
            showModal();
            // dataSource.value = dataSource.value.filter(
            //     item => item.key !== key
            // );
            // dataSource.value.splice(
            //     dataSource.value.findIndex(e => e.key === key),
            //     1
            // );
            // update.value++;
            // emit("update:value", dataSource.value);

            formState.listproduct = dataSource.value;
        };
        const increasesize = key => {
            delete editableData[key];
        };

        var countlist = 1;
        let resulttemp = [];
        const handleclosemodal = (e, sellordertransaction) => {
            console.log(e);

            if (e == undefined) {
                visible.value = false;
                return;
            }
            var inside = [];
            var insidecod = [];
            var outside = [];
            if (e.pd_deliverytype == "inside") {
                inside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            if (e.pd_deliverytype == "insidecod") {
                insidecod = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            if (e.pd_deliverytype == "outside") {
                outside = [
                    {
                        price: e.pd_price,
                        id: e.pd_id,
                        introduction: e.pd_introduction
                    }
                ];
            }
            var product = {
                key: countlist,
                additionalshipping: e.pd_additionalshipping,
                payadditional: e.payadditional,
                inside: inside,
                insidecod: insidecod,
                outside: outside,
                userproduct: {
                    queyan: e.queyan,
                    trackyan: e.trackyan,
                    queyanimage: e.queyanimage,
                    queyanimage: e.queyanimage,
                    comingyanimage: e.comingyanimage,
                    yancomingdate: e.yancomingdate,
                    professortype: e.professortype,
                    customerproductstype: e.customerproductstype,
                    productprice: e.productprice,
                    productcode: e.productcode,
                    productsellorder: sellordertransaction,
                    customerorder: e.customerorder,
                    productkey: e.productkey,
                    customerproductssize: e.customerproductssize,
                    heartyanname: e.heartyanname,
                    productdetail: JSON.parse(e.productdetail | []),
                    introductionyan: e.introductionyan,
                    phone: e.phone,
                    phoneemergency: e.phoneemergency,
                    productyanimage: e.productyanimage,
                    introduction: e.introduction,
                    email: e.email,
                    career: e.career,
                    introductioncareer: e.introductioncareer,
                    lineid: e.lineid,
                    facebook: e.facebook,
                    customerphonethai: e.customerphonethai,
                    productstatus: e.productstatus,
                    productstatusdesc: e.productstatusdesc,
                    slipimageurl: e.pd_slipimageurl,
                    customername: e.customername,
                    customerlastname: e.customerlastname,
                    deliverystatus: e.pd_deliverystatus
                },
                sellorderTransaction: e.sellorderTransaction,
                orderdate: e.orderdate,
                productjson: e.productjson
            };

            // formStates.userproduct = product.userproduct

            console.log("product :", product);
            console.log("formState.userproduct", formState.userproduct);
            dataSource.value.push(product);

            listgroupby_selectedRowKeys.value.forEach(element => {
                if (element.ProductSellOrder == sellordertransaction) {
                    element.item.push(product);
                }
            });
            countlist = countlist + 1;
        };

        const handleclosemodalproduct = e => {
            console.log(e);
            if (e == undefined) {
                visible.value = false;
                // return
            }
            dataSource.value = [];
            try {
                formState.productlist.listproduct.forEach((items, index) => {
                    //     console.log(items)
                    //     console.log(index)
                    // })
                    var result = _.transform(items, function(result, val, key) {
                        result[key.toLowerCase()] = val;
                    });

                    var product = {
                        key: index + 1,
                        inside: {},
                        insidecod: {},
                        outside: {},
                        userproduct: {
                            queyan: result.queyan,
                            professortype: result.professortype,
                            customerproductstype: result.customerproductstype,
                            productprice: result.productprice,
                            productcode: result.productcode,
                            productsellorder: result.productsellorder,
                            customerorder: result.customerorder,
                            productkey: result.productkey,
                            customerproductssize: result.customerproductssize,
                            heartyanname: result.heartyanname,
                            productdetail: JSON.parse(
                                result.productdetail | []
                            ),
                            introductionyan: result.introductionyan,
                            phone: result.phone,
                            phoneemergency: result.phoneemergency,
                            productyanimage: result.productyanimage,
                            introduction: result.introduction,
                            email: result.email,
                            career: result.career,
                            introductioncareer: result.introductioncareer,
                            lineid: result.lineid,
                            facebook: result.facebook,
                            customerphonethai: result.customerphonethai,
                            productstatus: result.productstatus,
                            productstatusdesc: result.productstatusdesc
                        },
                        sellorderTransaction: result.sellorderTransaction,
                        orderdate: result.orderdate,
                        productjson: result.productjson
                    };
                    dataSource.value.push(product);
                });
                countlist = countlist + 1;
            } catch (error) {
                console.log(error);
            }
        };

        const showprice = price => {
            if (typeof price == undefined || price == undefined) return "-";
            return price?.toLocaleString() + " บาท";
        };

        const state = reactive({
            selectedRowKeysProduct: [],
            // Check here to configure the default column
            loading: false
        });
        const hasSelected = computed(() => recordselectlist.value.length > 0);
        const start = async () => {
            state.loading = true;
            // ajax request after empty completing
            // var sp = await getmakesp()
            await apiupdateadditionalshipping_update_edit(route.query.sp);

            setTimeout(() => {
                state.loading = false;
                // state.selectedRowKeys = [];
                //clickSO()
            }, 1000);
        };

        const reinit = SP_CODE => {
            dataSource.value = [];
            listgroupby_selectedRowKeys.value = [];
            formStateStore.user.selectedRowKeys.forEach(element => {
                var obj_item = {
                    ProductSellOrder: element,
                    item: []
                };
                listgroupby_selectedRowKeys.value.push(obj_item);
                getCustomerProductlist(element);
            });
            setTimeout(() => {
                openNotificationWithIcon("success", SP_CODE);
            }, 1000);
        };

        const apiupdateadditionalshipping_update_edit = async sp => {
          
            console.log(
                "formStateStore.user.selectedRowKeys ",
                formStateStore.user.selectedRowKeys
            );
            console.log("recordselectlist ", recordselectlist);
            console.log(
                "formStateStore.pay_additional ",
                formStateStore.pay_additional
            );
            var SP_CODE = sp;

            try {
                for await (let detail of formStateStore?.pay_additional
                    ?.listpay) {
                    for await (let items of detail.slipimagearray) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formStateStore.pay_additional
                                        .customerorder +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "slip"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    // listproduct_items["productyanimage"] = "";
                                    // listproduct_items["productyanimage"] =
                                    //     res.data.url;
                                    formStateStore.pay_additional.slipimage =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } catch (error) {
                var exmsg = "";
                if (error.message) {
                    exmsg += error.message;
                }
                if (error.stack) {
                    exmsg += " | stack: " + error.stack;
                }
                console.log(exmsg);
            }

            console.log("formStateStore : ", formStateStore);
            // var index = 0;
            var listdetail = {};
            const formState = reactive(formStateStore);
            if (formStateStore?.pay_additional?.listpay.length) {
                for (let detail of formState?.pay_additional?.listpay) {
                    detail.slipimagearray = [];
                    detail.slipimagearray.push(
                        formStateStore.pay_additional.slipimage
                    );
                    detail.slipimage = formStateStore.pay_additional.slipimage;
                    console.log(formStateStore.pay_additional.addprice);

                    var params = {
                        CustomerOrder:
                            formStateStore.pay_additional.customerorder,
                        AdditionalShipping: detail.price || "0",
                        SlipImageURL: formStateStore.pay_additional.slipimage,
                        AdditionalTage: SP_CODE,
                        PayAdditional: JSON.stringify(detail)
                    };

                    call_apiupdateadditionalshipping_update_edit(params);
                    // index++
                }
            } else {
                var params = {
                    ProductSellOrder: element.userproduct.productsellorder,
                    CustomerOrder: formStateStore.pay_additional.customerorder,
                    AdditionalShipping: "0",
                    SlipImageURL: formStateStore.pay_additional.slipimage,
                    AdditionalTage: SP_CODE,
                    ProductKey: element.userproduct.productkey?.toString(),
                    PayAdditional: JSON.stringify([])
                };

                call_apiupdateadditionalshipping_update_edit(params);
            }

            setTimeout(() => {
                reinit(SP_CODE);
            }, 3000);
        };

        const getmakesp = async () => {
            return await BzbsUser.apiGetMakeSP()
                .then(res => {
                    return res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const call_apiupdateadditionalshipping_update_edit = async param => {
            console.log("param :", param);
        
            await insertpayment_update_edit(param);
            // await BzbsUser.apiupdateadditionalshipping_update_edit(param)
            //     .then(res => {
            //         console.log(res);
            //         insertpayment_update_edit(param)
            //     })
            //     .catch(error => {
            //         console.log(error);
            //     });
        };

        const insertpayment_update_edit = async p => {
          
            console.log("formStateStore", formStateStore);
            // console.log("formStateStore.user?.selectedRowKeys[0]", formStateStore.user?.selectedRowKeys[0])
            // console.log("formStateStore.user?.customercode", formStateStore.user?.customercode)
            // // formState.pay_additional = {}
            // formStateStore.pay_additional.productsellorder = formStateStore.user?.selectedRowKeys[0];
            // formStateStore.pay_additional.customerorder = formStateStore.user?.customercode;
            // formStateStore.pay_additional.paymenttype = "pay_additional";
            // console.log("listpay", formStateStore.pay_additional.listpay[0]);
      
            let params = new PaymentAdditionalModel(
                formStateStore.pay_additional.listpay[0]
            );
              
            params.ProductSellOrder = formStateStore.user?.selectedRowKeys[0];
            params.CustomerOrder = formStateStore.user?.customercode;
            params.AdditionalTage = p.AdditionalTage;
            // console.log("param", params);
            await BzbsUser.apiupdatepaymentadditional_update_edit(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const recordselectlist = ref([]);
        const onSelectChange = (selectedRowKeys, record) => {
            console.log(record);
            console.log("selectedRowKeys :", selectedRowKeys);
            recordselectlist.value = record;
        };
        const getCheckboxProps = record => {
            if (
                record?.insidecod.length == 0 &&
                record?.inside.length == 0 &&
                record?.outside.length == 0
            ) {
                return {
                    disabled: true
                };
            }
            if (record?.userproduct.deliverystatus == "prepare_pack") {
                return {
                    disabled: true
                };
            }
        };
        const openNotificationWithIcon = (type, message) => {
            notification[type]({
                message: message
            });
        };
        return {
            openNotificationWithIcon,
            getCheckboxProps,
            recordselectlist,
            onSelectChange,
            computed,
            hasSelected,
            ...toRefs(state),
            start,
            listgroupby_selectedRowKeys,
            handleclosemodalproduct,
            showprice,
            increasesize,
            resulttemp,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            onDelete,
            update,
            onEdit,
            edit_productjson,
            formStateStore,
            getCustomerProductlist
        };
    }
});
</script>
