<template>
    <div>
        <p v-html="msg"></p>
    </div>
</template>
<script>
import DatetimeHelper from '@/helper/DatetimeHelper.js';
import Util from '@/helper/Utility.js';
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: 'DrawConfirmRedeem',
    mixins: [LocaleMixin],
    props: {
        campaign: Object
    },
    data: function () {
        return {
            msg: ''
        };
    },
    created: function () {
        var datetime = DatetimeHelper.getDatetimeFullFormat(this.campaign.expireDate);
        this.msg = Util.stringFormat(this.lbl.confirm_redeem.draw, datetime);
    }
}
</script>
