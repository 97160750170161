<template>
    <div
        class="fixed top-0 right-0 h-screen z-40 bg-opacity-60 bg-black"
        :style="containerStyle"
        :class="{ 'w-full': value }"
    >
        <transition

            enter-active-class="transition ease-out duration-300"
            enter-from-class="transform-gpu translate-x-full"
            enter-to-class="transform-gpu  translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="transform-gpu translate-x-0"
            leave-to-class="transform-gpu translate-x-full"
        >
            <div
                class="absolute top-0 right-0 h-full shadow-xl bg-white border-l"
                :style="mainBlockStyle"
                v-if="value"
                v-click-away="onClickAway"
            >
                <div class="flex flex-col h-full">
                    <div class="panel-header flex-none border-b-2">
                        <div class="flex-grow font-semibold text-sm text-gray-1">Notifications</div>
                        <div class="flex-none">
                            <v-toggle class="w-6" v-model="muted"></v-toggle>
                        </div>
                    </div>
                    <div class="border-b-2 flex-auto overflow-y-auto">
                        <notification-list :items="items"></notification-list>
                    </div>
                    <div class="flex-none">
                        <div class="p-3 text-center">
                            <button class="text-sm text-blue-1 h-9 w-full" @click="dismiss()">
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import VToggle from "@/components/Toggle/VToggle.vue"
import NotificationList from "./NotificationList.vue"

// helper
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: "NotificationPanel",
    props: {
        width: {
            type: [Number, String],
            default: 364,
        }
    },
    inject: ["TopBarStore"],
    components: {
        VToggle,
        NotificationList
    },
    data: function () {
        return {
            items: [],
            muted: true
        }
    },
    computed: {
        mainBlockStyle: function () {
            return {
                width: PropsHelper.getBoxUnit(this.width)
            }
        },
        containerStyle: function () {
            return {

            }
        },
        notificationCount: {
            get: function () {
                return this.TopBarStore.state.notificationCount
            },
            set: function (newVal) {
                this.TopBarStore.methods.setNotificationCount(newVal)
            }
        },
        value: {
            get: function () {
                return this.TopBarStore.state.notification
            },
            set: function (newVal) {
                this.TopBarStore.methods.setNotification(newVal)
            }
        }
    },
    watch: {
        value: function (val) {
            // console.log('save list', 'value', val)
            if (val) {
                this.disableScroll();
            } else {
                this.enableScroll()
            }
        }
    },
    emits: ['update:value', 'update:muted'],
    created: function () {

        this.items = [
            {
                content: "requested to change a user level. Click for details and respond.",
                action: 2,
                timestamp: "4 min ago",
                user: {
                    shortedName: "BS",
                    firstName: "Boonchanin"
                }
            },
            {
                content: "added a new user list. Click to view.",
                action: 1,
                timestamp: "8 min ago",
                user: {
                    shortedName: "EK",
                    firstName: "Ekaraj"
                }
            },
            {
                content: "added a new user level <strong>Silver</strong>. Please respond.",
                action: 3,
                timestamp: "1 hour ago",
                user: {
                    shortedName: "KR",
                    firstName: "Kittisak"
                }
            }
        ]

        // this.TopBarStore.methods.setNotificationCount(this.items.length)
        this.TopBarStore.methods.setNotificationCount(99)
    },
    methods: {
        onClickAway: function () {
            this.dismiss();
            this.$nextTick(() => {
                this.$emit("onClickAway")
            })
        },
        dismiss: function () {
            this.value = false
        },
        disableScroll: function () {
            setTimeout(() => {
                document.body.classList.add('overflow-hidden')
            }, 100);
        },
        enableScroll: function () {
            document.body.classList.remove('overflow-hidden')
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/base/base.scss";
.panel-header{
    height: $topbar-height;
}
</style>
