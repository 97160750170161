<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table v-if="dataSources" :scroll="{ x: 1300, y: 1000 }" :columns="columns" :data-source="dataSources"
                @change="handleTableChange" @expandFixed="true" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record,index }">

                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>


                    <template v-if="column.dataIndex === 'UserId'">
                        <div style="font-size:12px">
                            {{record.UserId}}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'PictureUrl'">
                        <div>
                            <img class="img-profile" :src="record.PictureUrlStorage 
                            " alt="Avatar" />
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'DisplayName'">
                        <div>
                            {{record.DisplayName}}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CreateDate'">
                        <div> {{ record.CreateDate ?formatdate(record.CreateDate):"" }}</div>
                    </template>
                    <template v-if="column.dataIndex === 'UpdateDate'">

                        <div> {{ record.UpdateDate ?formatdate(record.UpdateDate):"" }}</div>
                    </template>

                </template>
            </a-table>

        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";

import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import Drawer from "../../DashboardInfo/component/Drawer.vue";
import Previewslip from "../../DashboardInfo/component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import BarcodeGenerator from "../../DashboardInfo/component/BarcodeGenerator.vue";

import { useRoute } from 'vue-router'
import Noti from "@/components/Noti/Noti.vue";

//order	PictureUrl	UserId	StatusMessage	DisplayName	Email	AccessToken	OS	Language	Version	Channel_ID	CreateDate	UpdateDate
//10	https://profile.line-scdn.net/0hvuLBgvdZKVphOzyIGppXJRFrKjBCSnBIGFsxPQM8f2hUXz5bH1oxPlA4d2NZWDwEGl1nPlE5dm9tKF48f23VbmYLd21YDWYOSVxvuw	U6c394b9046b139dc2a6a41a8ca6b953c	NULL	Ton	tongiggabite@gmail.com	eyJhbGciOiJIUzI1NiJ9.ZBG5WdHlSN46hcE5tGbotw0SaMEemnA4IXdIoKsg6Wr0M1t-PZFiHsiMn_oScu3dCT8qidwkZH2DtRoAtHedYjtiPlBRvO_CmQE8e4aVDhn6micnkShXSSAffjEke-xi.l7kNiMvpqEMdfnudQUm9fdmm8jjbfcRZP9vIJS5HXYE	ios	th-TH	2.21.4	1657935086	2566-02-21 00:00:00.000	2566-02-21 00:00:00.000
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "10%"
    },
    {
        title: "ภาพโปรไฟล์",
        dataIndex: "PictureUrl",
        sorter: true,
        width: "15%",

    },
    {
        title: "ชื่อ",
        dataIndex: "DisplayName",
        sorter: true,
        width: "10%",

    },
    {
        title: "Email",
        dataIndex: "Email",
        sorter: true,
        width: "25%",
    },
    {
        title: "ใช้มือถือระบบ",
        dataIndex: "OS",
        width: "15%"
    },
    {
        title: "Line UserID",
        dataIndex: "UserId",
        width: "15%"
    },
    {
        title: "ใช้ภาษา",
        dataIndex: "Language",
        width: "15%"
    },

    {
        title: "วันแรกในระบบ",
        dataIndex: "CreateDate",
        width: "15%",
    },
    {
        title: "วันล่าสุดที่ใช้",
        dataIndex: "UpdateDate",
        width: "15%"
    },
];


export default defineComponent({
    components: {
        TableLoad,
        BarcodeGenerator,
        Drawer,
        CloseOutlined,
        // CheckOutlined,
        Previewslip,
        FromCreateSell,
        Noti
    },

    setup() {
        const route = useRoute()
        const query = ref(route.query)
        const customercode = ref(route.query.customercode)
        const dataSources = ref(null);


        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        if (customercode.value) {
            rowselecteddetail.rowselected = customercode.value;
            rowselecteddetail.ishowdetail = true;
        }
        const {
            data: dataSource,
            run,
            loading,
            title = "เก็บข้อมูลลูกค้า ACCOUNT LINE ",
            current,
            pageSize
        } = usePagination('', {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apilineuser(null)
                .then(res => {
              
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    dataSources.value = res?.data[0]
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // }); 
                   
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };



        const groupBySellOrder = (arraySellOrder) => {

            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };

        return {
            handleSearch,
            handleReset,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            query
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
