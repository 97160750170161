<template>
    <div>

    </div>
</template>
<script>
import Account from '@/helper/AccountHelper.js';
import AuthPageMixin from '@/mixin/AuthPageMixin.js';

export default {
    name: 'LogoutAd',
    inject: ["MsAccountStore"],
    mixins: [AuthPageMixin],
    methods: {
        //#region mixin AuthPageMixin
        onAccountInit: function() {
            this.logout();
        },
        //#endregion mixin AuthPageMixin

        logout: async function() {
            console.log('logout bzbs');
            await this.logoutBzbs();
            console.log('logout ms');
            this.logoutMs();
        },
        logoutBzbs: function() {
            return new Promise((resolve) => {
                Account.setLogout().then(() => {
                    resolve();
                }).catch(() => {
                    resolve();
                })
            })
        },
        logoutMs: function() {
            // localStorage.clear();
            this.MsAccountStore.methods.logout();
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
