<template>
    <div v-if="dataSources" class="relative p-4 wrapper-track">

        <!-- 
        <pre>{{ dataSources?.recordset?.length }}</pre>
        <pre>error {{ errorOccurred }}</pre> -->
        <div style="pointer-events: none;">
            <a-image :preview="{ visible: false }" class="image-center banner" alt="มหายันต์ลูกกอ"
                src="https://namahayan.blob.core.windows.net/assets/banner.webp"></a-image>
        </div>
        <div class="text-lg font-bold text-gray-500 text-center p-3" style="font-size:14px">ข้อมูลสินค้า
            <pre style="color: gray;font-size:12px">({{ dataSources?.recordset[0]?.CustomerOrder }})</pre>
        </div>

        <!-- <pre>{{ dataSources.recordset.CustomerProductsType?.includes('ยันต์') }}</pre> -->

        <div v-if="dataSources?.recordset?.length > 0" :key="idx" v-for="(item, idx) of dataSources.recordset">
            <div class="panel-title mb-4">
                <div class="title-left"><a-typography-text strong underline :level="4">{{ item.CustomerProductsType + ' ' +
                    item.CustomerProductsSize }}</a-typography-text> </div>
                <div class="title-right"><a-typography-text strong underline :level="4">{{ formatdate(item.OrderDate)
                }}</a-typography-text></div>
            </div>
            <!-- <a-steps direction="vertical" :current="current_status">
                <a-step title="รอตรวจสอบยอดชำระ (บัญชี)">
                    <template #description>
                        <div v-if="dataSources?.payment?.length > 0" :key="idx" v-for="(p, idx) of dataSources.payment">
                            <div v-if="p.Checking == null">กำลังตรวจสอบ จากบัญชี</div>

                            <div v-else-if="p.Checking == 'checked'">
                                <span v-show="current_status = 0"></span>
                                <a-typography-text strong underline :level="3" type="success">สำเร็จ</a-typography-text>
                            </div>
                        </div>
                    </template>
                </a-step>
            </a-steps> -->
        </div>
        <a-steps direction="vertical" :current="current_status">
            <a-step title="รอตรวจสอบยอดชำระ (บัญชี)">
                <template #description>
                    <!-- <pre> dataSources.payment : {{dataSources.payment}}</pre> -->
                    <!-- <div v-if="dataSources?.payment?.length > 0" :key="idx" v-for="(p, idx) of dataSources.payment"> -->


                    <div v-if="ispaymentsuccess">
                        <span v-show="current_status = 2"></span>
                        <a-typography-text strong underline :level="3" type="success">สำเร็จ</a-typography-text>
                    </div>
                    <div v-else>
                        <span v-show="current_status = 0"></span>
                        กำลังตรวจสอบ จากบัญชี
                    </div>
                    <!-- </div> -->
                </template>
            </a-step>
            <a-step title="เชคเงินเข้าเรียบร้อยแล้ว (บัญชีเชคเงินเรียบร้อย)">
                <template #description>
                    <div v-if="ispaymentsuccess">
                        <span v-show="current_status = 3"></span>
                        <a-typography-text strong underline :level="3" type="success">สำเร็จ</a-typography-text>
                    </div>
                    <div v-else>
                        <span v-show="current_status = 0"></span>
                        กำลังตรวจสอบ จากบัญชี
                    </div>
                </template>
            </a-step>
            <a-step v-show="dataSources.recordset[0].CustomerProductsType?.includes('ยันต์')"
                title="รอแจ้งใบคิวเขียน (รอลงระบบ)">
                <template #description>
                    <div style="font-size:12px;">
                        <div v-if="getgreencard?.length > 0" v-for="(sub_item, idx) of getdatagreen(getgreencard)"
                            :key="`v-list-item-${idx}`">

                            <!-- <pre>{{ dataSources }}</pre> -->
                            <div v-if="sub_item.ProductKey == pk && sub_item.QueYanDate" class="green-table-2">
                                <p class="name-green">NAMAHAYAN</p>
                                <a-row class="mt-2">
                                    <a-col :span="4">
                                        <div style="text-align: start;">ลำดับ<div><a-tag color="#833014"
                                                    style="padding: 0px 3px 0px 3px;margin: 0px;">{{
                                                        sub_item.OrderTransaction }}</a-tag></div>

                                        </div>
                                    </a-col>
                                    <a-col :span="14" style="text-align: initial;">
                                        <div>ชื่อ: {{ sub_item.Name + " " + sub_item.LastName }}</div>
                                        <div>ขนาด:{{ sub_item.Category_2 }}</div>
                                        <div>หัวใจยันต์: {{ sub_item.Category_3 }}</div>
                                        <div style="white-space: nowrap;">เลขแทร็ค: {{
                                            sub_item.ProductCode }}</div>
                                        <div>
                                            <u> รายชื่อ-วันเกิด(ผ้ายันต์)</u>
                                            <div style="padding-top: 10xp;display: flex;flex-wrap: wrap;">

                                                <div class="pr-2"
                                                    v-for="(productbirthday, idx) of dataSources.productbirthday?.recordset"
                                                    :key="`v-list-item-${idx}`">
                                                    {{ formatdate(productbirthday.Birthday) }}
                                                </div>
                                            </div>
                                            <!-- <div v-if="sub_item.ProfessorType" class="mb-2 mt-2"> 
                                                <a-row :gutter="16">
                                                    <a-col :span="15" class="mb-1 gutter-row"
                                                        v-for="(p_item, idx) of JSON.parse(sub_item.ProfessorType)"
                                                        :key="`v-list-item-${idx}`">
                                                        <a-tag color="#f50" class="tag-green">
                                                            *{{ getProfessortype(p_item) }}
                                                        </a-tag>
                                                    </a-col>
                                                </a-row> 
                                            </div> -->
                                        </div>
                                    </a-col>
                                    <a-col :span="6" class="text-center">
                                        <div style="text-align: end;">คิวเขียน<div><a-tag color="#108ee9"
                                                    style="padding: 0px 2px 0px 2px;margin: 0px; font-size: 10px;">{{
                                                        formatdate(sub_item.QueYanDate) }}</a-tag>
                                            </div>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                    </div>
                </template>
            </a-step>
            <a-step v-show="false && dataSources.recordset[0].CustomerProductsType?.includes('ยันต์')"
                title="รอแจ้งใบคิวเขียน (รอลงระบบ)">
                <template #description>

                    <div v-if="getgreencard?.length > 0" v-for="(sub_item, idx) of getdatagreen(getgreencard)"
                        :key="`v-list-item-${idx}`">
                        <div v-if="sub_item.ProductKey == pk && sub_item.QueYanDate">
                            <div v-if="ispaymentsuccess">
                                <span v-show="current_status = 2"></span>
                            </div>
                            <div class="green-table">
                                <p class="namegreen">NAMAHAYAN</p>
                                <a-row>
                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <a-row>
                                            <a-col :span="24">
                                                <font
                                                    style="font-family:tahoma; font-size:14px; color:#000066; border:0px solid #F1F1F2; padding-left:2px; text-align:center;     white-space: pre;">
                                                    ชื่อ-นามสกุล :</font>
                                            </a-col>
                                            <a-col :span="24">
                                                <!-- <div>
                                                    <font
                                                        style="font-family:tahoma; font-size:12px; color:#336699; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                        {{ dataSources[0]?.ShipAddress.FullName }}
                                                    </font>
                                                </div> -->
                                                <div class="p-2">
                                                    <font
                                                        style="font-family:tahoma; font-size:12px; color:#336699; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                        {{
                                                            sub_item.Name + " " + sub_item.LastName }}
                                                    </font>
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                    <a-col :span="12">
                                        <a-row>
                                            <a-col :span="24">
                                                <font
                                                    style="width:100; font-family:tahoma; font-size:14px; color:#000066; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                    วันที่เสร็จ :</font>
                                            </a-col>
                                            <a-col :span="24">

                                                <div class="p-2">
                                                    <font v-if="sub_item.QueYanDate"
                                                        style="width:180; font-family:tahoma; font-size:14px; color:#336699; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                        {{ formatdate(sub_item.QueYanDate) }}
                                                    </font>

                                                </div>

                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <a-row>
                                            <a-col :span="24">
                                                <font
                                                    style="font-family:tahoma; font-size:14px; color:#000066; border:0px solid #F1F1F2; padding-left:2px; text-align:center;     white-space: pre;">
                                                    วันเกิด :</font>
                                            </a-col>
                                            <a-col :span="24">
                                                <div>
                                                    <font
                                                        style="width:180; font-family:tahoma; font-size:14px; color:#336699; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">

                                                        <span v-for="(d_item, idx) of JSON.parse(d_item?.Birthday)">
                                                            {{ formatdate(d_item.Birthday) }}
                                                        </span>
                                                    </font>
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-row>
                                            <a-col>
                                                <font
                                                    style="width:100; font-family:tahoma; font-size:14px; color:#000066; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                    เลขที่บัตรฯ :</font>
                                            </a-col>
                                            <a-col>

                                                <font
                                                    style="width:180; font-family:tahoma; font-size:14px; color:#336699; border:0px solid #F1F1F2; padding-left:2px; text-align:center;">
                                                    -
                                                </font>

                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">ขนาด : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            {{ sub_item.CustomerProductsSize }}</font>
                                    </a-col>

                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">หัวใจยันต์ : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            {{ sub_item.HeartYanName }}</font>
                                    </a-col>

                                </a-row>
                                <a-row>

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">หมายเหตุหัวใจยันต์ : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            {{ sub_item.IntroductionYan }}</font>
                                    </a-col>

                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">หมายเหตุฯ : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            {{ sub_item.ProductIntroduction }}</font>
                                    </a-col>
                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">เลขแทรค : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            {{ sub_item.TrackYan }}</font>
                                    </a-col>
                                </a-row>
                                <a-row style="border: 1px solid #ffffff;">

                                    <a-col :span="12" style="border-right: 1px solid rgb(255, 255, 255);">
                                        <font color="#666666">เพจ : </font>&nbsp;&nbsp;<font color="#000000">

                                        </font>
                                    </a-col>
                                    <a-col :span="8">
                                        <font color="#000000">
                                            Namahayan</font>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                        <div v-else>
                            <span v-show="sub_item.ProductKey == pk">
                                รอดำเนินการ
                            </span>
                        </div>
                    </div>

                </template>
            </a-step>
            <a-step v-show="dataSources.recordset[0].CustomerProductsType?.includes('ยันต์')" title="ใบคิว">
                <template #description>
                    <div v-if="dataSources?.recordset?.length > 0 && dataSources?.recordset[0]?.QueYanImage">
                        <div v-if="ispaymentsuccess">
                            <span v-show="current_status = 4"></span>
                        </div>
                        <a-image :preview="true" :width="auto" :src="dataSources?.recordset[0]?.QueYanImage" />
                    </div>
                    <div v-else>
                        รอดำเนินการ
                    </div>
                </template>
            </a-step>
            <a-step v-show="dataSources.recordset[0].CustomerProductsType?.includes('ยันต์')" title="รูปยันต์">
                <template #description>

                    <div v-if="dataSources?.recordset?.length > 0 && dataSources?.recordset[0]?.ComingYanImage">
                        <div v-if="ispaymentsuccess">
                            <span v-show="current_status = 5"></span>
                        </div>
                        <a-image :preview="true" :width="auto" :src="dataSources?.recordset[0]?.ComingYanImage" />
                    </div>
                    <div v-else>
                        รอดำเนินการ
                    </div>
                </template>
            </a-step>
            <a-step title="เตรียมจัดส่ง (ตรวจสอบสถานะ)">
                <template #description>
                    <div class="text-lg font-bold item-menu"
                        v-if="dataSources?.recordset?.length > 0 && dataSources?.recordset[0]?.TrackingNo"
                        @click="gototrack(dataSources?.recordset[0]?.TrackingNo)">
                        <div v-if="ispaymentsuccess">
                            <!-- <span v-show="current_status = 5"></span> -->
                        </div>
                        ตรวจสอบสถานะ
                    </div>
                    <div v-else>
                        รอดำเนินการ
                    </div>
                </template>
            </a-step>
        </a-steps>


        <a-image :preview="false" :width="auto" src="https://namahayan.blob.core.windows.net/salepage/1668068170807.webp" />

    </div>
</template>
 
<script>

import TableLoad from "@/components/Skeleton/TableLoad"

import { onErrorCaptured, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons-vue';

import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility";
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 


export default defineComponent({
    components: {
        TableLoad,
        UserOutlined,
        SolutionOutlined,
        LoadingOutlined,
        SmileOutlined,
    },

    setup() {

        const ispaymentsuccess = ref(false)
        const errorOccurred = ref(false);

        onErrorCaptured((error, vm, info) => {
            // Handle the error here
            console.error('Error occurred during rendering:', error);
            console.error('Vue instance:', vm);
            console.error('Additional information:', info);

            // Update the errorOccurred flag
            errorOccurred.value = true;

            // Prevent the error from propagating further
            return false;
        });


        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        const track = ref("-")
        const cuscode = ref("-")
        const so = ref("-")
        const pk = ref("-")
        const current_status = ref(0)

        const searchInput = ref();
        const dataSources = ref(null);




        const getgreencard = ref([])
        onMounted(() => {
            if (urlParams.has('so')) {
                so.value = urlParams.get('so') || "-";
            }
            if (urlParams.has('cuscode')) {
                cuscode.value = urlParams.get('cuscode') || "-";
            }
            if (urlParams.has('track')) {
                track.value = urlParams.get('track') || "-";
            }
            if (urlParams.has('pk')) {
                pk.value = urlParams.get('pk') || "-";
            }


            querycustomer()
            getimagegreen()

        });

        const getimagegreen = () => {

            return BzbsUser.getimagegreen(so.value)
                .then(res => {

                    getgreencard.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("L");
        };

        const querycustomer = () => {
             
            return BzbsUser.getconsumerhistory(track.value, cuscode.value, so.value, pk.value)
                .then(res => {

                    dataSources.value = res.data

                    checkstatuspayment()
                    // querycustomerdetail(res.data[0].CustomerOrder)


                })
                .catch(error => {
                    console.log(error);
                });
        };

        const checkstatuspayment = () => {

            var hasNotsuccess = false

            dataSources.value.payment.forEach(element => {

                if (element.Checking != 'checked') {
                    hasNotsuccess = true
                }
            });

            if (hasNotsuccess) {
                ispaymentsuccess.value = false
            }
            else {
                ispaymentsuccess.value = true

            }
        }


        const getdatagreen = json => {

            return JSON.parse(JSON.stringify(json));
        };

        const gototrack = (paramtrack) => {
            if (urlParams.has('inter')) {
                window.location = "/consumertrackinginter?track=" + paramtrack + "&cuscode=" + cuscode.value + '&inter=true'
            }
            else {

                window.location = "/consumertracking?cuscode=" + cuscode.value
            }
        }

        const listdesc = ref([])
        const getProfessortype = (ProfessorType) => {
             
            if (ProfessorType.includes("1")) {
                return 'อาจารย์เลือกให้'
            }
            if (ProfessorType.includes("2")) {
                return 'รอคุยกับอาจารย์'
            }
            if (ProfessorType.includes("4")) {
                return 'ส่งเลย'
            }
            if (ProfessorType.includes("6")) {
                return 'จัดส่งพร้อมยันต์'
            }
            if (ProfessorType.includes("7")) {
                return 'จัดส่งทางไลน์'
            }
            if (ProfessorType.includes("8")) {
                return 'รับเอง'
            }
            if (ProfessorType.includes("9")) {
                return 'เก็บเงินปลายทาง'
            }
            
            if (ProfessorType.includes("3")) {
                return 'พรีออเดอร์'
            }
            if (ProfessorType.includes("5")) {
                return 'ขอรูปคู่อาจารย์'
            }
            
        }
        return {
            getProfessortype,
            listdesc,
            gototrack,
            getgreencard,
            current_status,
            dataSources,
            querycustomer,
            formatdate,
            searchInput,
            Util,
            getdatagreen,
            pk,
            errorOccurred,
            ispaymentsuccess
        };
    }
});
</script>
<style lang="scss">
.wrapper-track {
    width: 100%;
    max-width: 450px;
    margin: auto;

    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background: #ae8b40 !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-tail::after {
        background-color: #ae8b40 !important;
    }

    .green-table {
        font-family: 'Arial, Helvetica, sans-serif';
        font-size: 14px;
        color: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding-left: 0px;
        text-align: center;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffcc), to(#99cccc));
        background: -moz-linear-gradient(top, #ccffcc, #99cccc);
        filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#ccffcc, EndColorStr=#99cccc);
    }

    .green-table-2 {
        font-size: 12px;
        padding: 0px 10px 0px 10px;
        color: #000000;
        border: 1px solid #CCCCCC;
        text-align: center;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffcc), to(#99cccc));
    }

    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        float: left;
        margin-right: 10px;
    }




    .cus-desc {
        word-break: break-all;
        white-space: pre-line;
    }

    .panel-title {
        display: inline-flex;
        width: 90%;
    }

    .title-left {
        flex: 2;
        text-align: left;
    }

    .title-right {
        flex: 1;
        text-align: right;
    }

    .ant-steps-item-container {
        margin-left: -10px;
    }

    .ant-steps-item-tail {
        margin-left: -15px;
    }

    .ant-steps-item-content {
        margin-right: 15px;
    }
}

.name-green {
    position: absolute;
    color: rgb(255 255 255 / 50%);
    font-size: 20px;
    top: 50%;
    left: -5px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    pointer-events: none;
    -webkit-transform: rotate(-23deg);
    font-family: tahoma;
}

.tag-green {

    padding: 0px 2px 0px 2px;
    font-size: 9px;
}
</style>
