<template>
  <div>
    <a-button type="primary" class="mb-3" @click="showModal">
      <template #icon>
        <SearchOutlined />
      </template>เพิ่ม SO อื่นๆ</a-button>
    <a-modal v-model:visible="visible" title="ค้นหาสมาชิก" @ok="handleOk" width="1400px">
      <template #footer>
        <a-button key="back" @click="handleCancel">กลับ</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">เลือก</a-button>
      </template>
      <TableMember @customercodeselected="getcustomercode($event)"></TableMember>
    </a-modal>
  </div>
</template>
<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import TableMember from "./TableMember.vue";
import { Modal } from "ant-design-vue";
export default defineComponent({
  components: { SearchOutlined, TableMember },
  emits: ["customerselected"],
  setup(props, { emit }) {
    const loading = ref(false);
    const visible = ref(false);
    const customercodeselected = ref(null);

    const getcustomercode = (e) => {
      customercodeselected.value = e;
    };
    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
       
      if (customercodeselected.value == null) {
        info();
      } else {
        emit("customerselected", customercodeselected.value);
        loading.value = true;
        setTimeout(() => {
          loading.value = false;
          visible.value = false;
          success(customercodeselected.value.CustomerCode);
        }, 1000);
      }
    };

    const success = (code) => {
      Modal.success({
        title: "เลือกสำเร็จ!",
        content: "รหัสลูกค้า : " + code,
      });
    };
    const info = () => {
      Modal.info({
        title: "ไม่พบรหัสลูกค้า",
        content: "กรุณาขอรหัสลูกค้าใหม่",

        onOk() {
          console.log("ok");
        },
      });
    };
    const handleCancel = () => {
      visible.value = false;
    };

    return {
      getcustomercode,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      customercodeselected,
    };
  },
});
</script>