import Util from "@/helper/Utility.js";

export default {
    inject: ["AccountStore"],
    computed: {
        isInitial: function () {
            return this.AccountStore.state.isInitial;
        },
        isLogin: function () {
            return this.AccountStore.state.isLogin;
        },
        profile: function () {
            return this.AccountStore.state.profile;
        },
    },
    created: function () {
        this.AccountStore.methods.loginStatus().subscribe((status) => {
            if (!status) {
                this.$router.replace('/home');
                return;
            }

            if (Util.isFunction(this.onAccountInit)) {
                this.onAccountInit();
            }
        });
    },
}
