<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <!-- <pre>{{dataSource}}</pre> -->
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                v-if="dataSource && !rowselecteddetail.ishowdetail" size="middle" :columns="columns"
                :data-source="dataSource" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">

                <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="padding: 8px">
                        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>

                    <template v-if="column.dataIndex === 'AdditionalTage'">

                        <div class="pb-2">
                            <div>
                                <a-typography-text copyable strong v-if="record.AdditionalTage">{{ record.AdditionalTage
                                }}</a-typography-text>

                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </div>
                        </div>
                    </template>
                    <!-- <template v-else-if="column.dataIndex === 'ProductImage'">

                        <div>
                            <a-image :width="100" :src="record.SlipImageURL" />
                        </div>

                    </template> -->

                    <template v-if="column.dataIndex === 'ProductCode'">
                        {{ record.ProductCode }}
                        <!-- <div class="pb-2"   :key="`item-${idx}`">
                            
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul v-for="items in Util.jsonParse(
                                            record.ProductCode
                                        )" :key="items">
                                            <li>{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5" style="text-align: right;" copyable>{{
                                        record.ProductCode
                                    }}</a-typography-title>
                                </a-tooltip>
                          
                        </div> -->
                    </template>

                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        {{ record.CustomerOrder }}
                        <!-- <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <a-image :width="200" :src="record.find(
                                            item =>
                                                item.ContactStartDefault ==
                                                'true'
                                        ).ContactImageUrl
                                            " />
                                    </template>
                                    <div v-for="(Item, idx) of record" :key="`item-${idx}`" @click="rowselected(record)">
                                        {{ Item.ContactName }}
                                    </div>
                                </a-tooltip>

                                <a-tag :color="record
                                    .find(
                                        item =>
                                            item.ContactStartDefault ==
                                            'true'
                                    )?.ContactType?.toUpperCase() ==
                                    'FACEBOOK'
                                    ? 'geekblue'
                                    : 'green'
                                    ">
                                    {{
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    "true"
                                            )?.ContactType?.toUpperCase()
                                    }}
                                </a-tag>
                            </a-space>
                        </div> -->
                    </template>

                    <!-- <template v-if="column.dataIndex === 'PaiddeliveryObj'">
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button type="dashed">
                                <a-typography-text strong v-if="Item.Price">{{ showprice(Item.Price)
                                }}</a-typography-text>
                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalShipping'">
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button type="dashed">
                                <a-typography-text strong v-if="Item.AdditionalShipping">{{
                                    showprice(Item.AdditionalShipping)
                                }}</a-typography-text>
                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span>
                        </div>
                    </template>
                   -->

                    <template v-if="column.dataIndex === 'ProductStatus'">
                        <div>

                            <div class="pb-2">
                                <div>
                                    <a-typography-text type="danger" strong v-if="record.DeliveryStatus == 'cancel'">
                                        <span :style="{ color: 'red', fontSize: '14px' }">
                                            <div>
                                                <close-square-outlined /> (ยกเลิกการจัดส่ง)
                                            </div>
                                            หมายเหตุ : <i>{{ record.Desc }}</i>
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong
                                        v-else-if="record.DeliveryStatus == 'prepare_pack'"><span
                                            :style="{ color: 'orange', fontSize: '14px' }">
                                            <clock-circle-outlined /> {{ record.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong
                                        v-else-if="record.DeliveryStatus == 'canceltrack'"><span
                                            :style="{ color: 'red', fontSize: '14px' }">
                                            <clock-circle-outlined /> ยกเลิก Track {{ record.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong
                                        v-else-if="record.DeliveryStatus == 'shipped'"><span
                                            :style="{ color: 'blue', fontSize: '14px' }">
                                            <check-square-outlined /> {{ record.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong v-else-if="record.DeliveryStatus == 'packed'">
                                        <span :style="{ color: 'green', fontSize: '14px' }">
                                            <check-square-outlined /> {{ record.Desc }}
                                        </span>
                                    </a-typography-text>
                                    <a-typography-text strong v-else-if="record.DeliveryStatus">{{ record.DeliveryStatus
                                    }}</a-typography-text>
                                    <a-typography-text delete v-else>ข้อมูลยังไม่ครบถ้วน</a-typography-text>
                                </div>
                            </div>
                        </div>
                    </template> 
                    <template v-if="column.dataIndex === 'ThailandpostStatus'">
                        <div v-if="record.ThailandpostStatus">
                            <div v-for="(Item, idx) of JSON.parse(record.ThailandpostStatus)" :key="`item-${idx}`">
                                <div v-if="JSON.parse(record.ThailandpostStatus).length - 1 == idx">
                                    <div v-if="Item.status_description == 'นำจ่ายสำเร็จ'">
                                        <a-badge style="font-size:10px" status="success" :text="Item.status_description+' @'+Item.location" />
                                    </div>
                                    <div v-else>
                                        <a-badge style="font-size:10px" status="processing"
                                            :text="Item.status_description" />
                                    </div>
                                </div>
                                <div v-else>
                                    <a-badge style="font-size:10px" status="default" :text="Item.status_description+' @'+Item.location" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <!--
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                                <div v-if="Item.ProfessorType && idx == 0">
                                    <a-typography-text mark strong v-if="JSON.parse(Item.ProfessorType)?.includes(
                                        '2'
                                    )
                                        ">*รอคุยกับอาจารย์</a-typography-text>

                                    <a-typography-text type="danger" strong v-if="!JSON.parse(
                                        Item.ProfessorType
                                    )?.includes('2')
                                        ">
                                        <font-awesome-icon icon="fa-solid fa-xmark" />
                                        ข้อมูลไม่ครบ
                                    </a-typography-text>

                                </div>

                            </div>
                        </div>
                    </template> 
                -->
                    <template v-if="column.dataIndex === 'Customertel'">
                        <div>
                            <div>

                                <div v-if="record.ProductResponsiblePerson" class="flex items-start">
                                    <div class="flex-auto">
                                        <div class="text-xs text-gray-1-light">{{
                                            JSON.parse(record.ProductResponsiblePerson).login?.shortedName }} | {{
        JSON.parse(record.ProductResponsiblePerson).login?.role }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div style="margin-bottom: 16px;padding: 10px ;">
                <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="start">
                    อัพเดทส่งแล้ว
                </a-button>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                        {{ `เลือก ${selectedRowKeys.length} รายการ` }}
                    </template>
                </span>
            </div>
            <div v-if="!dataSource && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>

        </div>
        <FromCreateSell v-if="rowselecteddetail.ishowdetail" :rowselected="rowselecteddetail"></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined, CloseSquareOutlined, CheckSquareOutlined, ClockCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from 'ant-design-vue';
import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import { formStateStore } from "@/store/storebackoffice";
import Util from "@/helper/Utility.js";
import { SearchOutlined } from '@ant-design/icons-vue';
const searchInput = ref();
// import BarcodeGenerator from "./BarcodeGenerator.vue";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order"
    },
    {
        title: "รายการสค่าส่งเพิ่ม",
        dataIndex: "AdditionalTage",
        customFilterDropdown: true,
        onFilter: (value, record) => (record?.AdditionalTage.toString()).toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput?.value.focus();
                }, 100);
            }
        },
    },
    // {
    //     title: "ชื่อการติดต่อ",
    //     dataIndex: "Contract",
    //     width: "10%"
    // },
    // {
    //     title: "รูป",
    //     dataIndex: "ProductImage",
    //     width: "10%"
    // },
    {
        title: "รหัสสินค้า",
        dataIndex: "ProductCode"
    },
    // {
    //     title: "ค่าส่ง",
    //     dataIndex: "PaiddeliveryObj",
    //     width: "10%"
    // }, {
    //     title: "ค่าส่งเพิ่ม",
    //     dataIndex: "AdditionalShipping",
    //     width: "10%"
    // },
    // {
    //     title: "วันที่สั่งสินค้า",
    //     dataIndex: "OrderDate",
    //     sorter: true,
    //     width: "15%"
    // },
    {
        title: "สถานะ",
        dataIndex: "ProductStatus"
    },
    {
        title: "สถานะไปรษณีย์ไทยพัสดุ",
        dataIndex: "ThailandpostStatus",
        width: "15%"
    },
    // {
    //     title: "สถานะข้อมูล",
    //     dataIndex: "ProductStatusData",
    //     width: "15%"
    // },
    // {
    //     title: "สถานะข้อมูล(1.1)",
    //     dataIndex: "StatusData",
    //     width: "10%"
    // },
    {
        title: "ผู้รับผิดชอบ",
        dataIndex: "Customertel"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        CloseSquareOutlined,
        CheckSquareOutlined,
        ClockCircleOutlined,
        TableLoad,
        SearchOutlined,
        // BarcodeGenerator,
        // Drawer,
        CloseOutlined,
        // CheckOutlined,
        // Previewslip,
        FromCreateSell
    },
    props: {
        querybystatus: null
    },
    setup(props) {
        const state = reactive({
            selectedRowKeys: [],
            loading: false,
            searchText: '',
            searchedColumn: '',
        });

        formStateStore.btnsubmit = 'ยืนยันสินค้าจัดส่ง'
        const dataSource = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        // const {
        //     data: dataSource,
        //     run,
        //     loading,
        //     title = "สร้างรายการจัดส่ง(5.3.2)",
        //     current,
        //     pageSize
        // } = usePagination(queryData, {
        //     formatResult: res => res.data.results,
        //     pagination: {
        //         currentKey: "page",
        //         pageSizeKey: "results"
        //     }
        // });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apigetadditionaldeliveryorder(props.querybystatus)
                .then(res => {

                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    console.log("res.data[0] : ", res.data[0])

                    dataSource.value = res.data[0].filter((item) => item.DeliveryStatus != null)
                    console.log("dataSource.value : ", dataSource.value)

                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });

                    
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            // run({
            //     results: pag.pageSize,
            //     page: pag?.current,
            //     sortField: sorter.field,
            //     sortOrder: sorter.order,
            //     ...filters
            // });
        };
        const warning = (title, content) => {
            Modal.warning({
                title: title,
                content: content,
            });
        };
        const rowselected = record => {
            console.log(record)
            if (record[0].DeliveryStatus == 'packed') {

                warning('แพ็คสินค้าแล้ว', '')

                return
            }
            formStateStore.rowselected_status = ""
            formStateStore.rowselected_productsellorder = record.ProductSellOrder
            if (record[0].DeliveryStatus == 'cancel') {
                formStateStore.rowselected_status = record[0].DeliveryStatus
            }


            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            formStateStore.rowselecteddetail = record[0].AdditionalTage
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = (arraySellOrder) => {

            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }
        const showprice = (price) => {
            if (typeof price == undefined || price == undefined) return '-'
            return price?.toLocaleString() + ' บาท'
        }


        const hasSelected = computed(() => state.selectedRowKeys.length > 0);
        const start = () => {
            state.loading = true;
            // ajax request after empty completing
            setTimeout(() => {
                state.loading = false;
                state.selectedRowKeys = [];
                updateSystemyandate()
            }, 1000);
        };

        const listselected = ref([]);
        const onSelectChange = selectedRowKeys => {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            state.selectedRowKeys = selectedRowKeys;
            listselected.value = selectedRowKeys;
        };

        const updateSystemyandate = async () => {

            var list = []

            console.log("listselected", listselected.value)
            listselected.value.forEach(element => {
                var found = dataSource.value.filter(item => item.key == element)
                list.push(found)
            });

            console.log("LIST : ", list)
            for await (let item of list) {
                console.log("updatestatussupport item :", item)
                var CheckMessageParam = [{
                    tab: "1",
                    checking: 'shipped',
                    message: 'จัดส่งแล้ว',
                    updatedate: Date.now().toString(),
                }]
                for await (let detail of item) {
                    var param = {
                        "Tab": "1",
                        "ProductSellOrder": detail.ProductSellOrder,
                        "CustomerOrder": detail.CustomerOrder,
                        "ProductKey": detail.ProductKey,
                        "SystemCheckTabActive": JSON.stringify(CheckMessageParam),
                        "AdditionalTage": detail.AdditionalTage,
                        "ProductDeliveryOrder": detail.ProductDeliveryOrder
                    }

                    console.log("updatestatussupport Param :", param)
                    await BzbsUser.updatestatussupport(param)
                        .then(res => {

                            // setTimeout(() => {
                            //     window.location.reload()
                            // }, 1000);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                setTimeout(() => {
                    window.location.reload()
                }, 5000);
            }


        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        return {
            searchInput,
            handleSearch,
            handleReset,
            start,
            hasSelected,
            listselected,
            onSelectChange,
            updateSystemyandate,
            showprice,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            pagination,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            Util,
            ...toRefs(state),
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}

.ant-badge-status-text {
    font-size: 10px !important;
}
</style>
