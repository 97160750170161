<template>
  <a-time-picker v-model:value="value" format="HH:mm" />
</template>
<script>
import dayjs from 'dayjs';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  setup() {
    const value = ref();
    return {
      value,
      dayjs,
    };
  },

});
</script>