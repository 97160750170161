<template>
    <div class="relative p-4 card">
        <div v-if="isdel">
            <CheckToken :visible="isdel" :title="'ตรวจสอบ token จาก Email'" :keygen="delsalepage.salepagename"
                @close="isdel = $event" @checksuccess="calldelete()"></CheckToken>
        </div>
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-button @click="gotocreate(subdomain)" class="mt-2 mb-3" type="primary">

                <template #icon>
                    <plus-outlined />
                </template>
                <span>สร้างเซลเพจ</span>
            </a-button>
            <a-table :scroll="{ x: 0 }" :columns="columns" :data-source="dataSources"
                :pagination="{ pageSize: 30 }" @change="handleTableChange" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record }">
                    <div>
                        <template v-if="column.dataIndex === 'MAINSALEPAGE'">

                            <div class="text-center animate-spin">
                                <star-two-tone v-if="this.$route.query.mainsalepage == record.SalePageID"
                                    two-tone-color="DAA520" style="font-size: 20px;" />
                            </div>
                        </template>
                        <template v-if="column.dataIndex === 'LINK'">
                            <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                                <a
                                    :href="'/salepageedit?id=' + record.SalePageID + '&subdomainsalepage=' + subdomain + '&mainsalepage=' + this.$route.query.mainsalepage"><u>https://{{
                                    record.Subdomain
                                    }}.{{ config_namahayant.salepageurl }}/{{ record.SalePageID }}</u></a>
                            </a-typography-title>
                        </template>
                        <template v-if="column.dataIndex === 'DESC'">
                            <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                                <i>{{ record.SalePageDetail ? JSON.parse(record.SalePageDetail)?.desc : "" }}</i>
                            </a-typography-title>


                        </template>
                    </div>
                    <template v-if="column.dataIndex === 'EDIT'">
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="handleMenuClick">
                                    <a-menu-item :key="['1', record.Subdomain, record.SalePageID]">
                                        <eye-outlined class="pr-2" />ดูตัวอย่าง
                                    </a-menu-item>
                                    <a-menu-item :key="['2', record.SalePageID, record.Subdomain]">
                                        <edit-outlined class="pr-2" />แก้ไข
                                    </a-menu-item>
                                    <a-menu-item :key="['3', record.SalePageID]" @click="onpopclone(record.SalePageID)">
                                        <file-add-outlined class="pr-2" />โคลนเซลเพจ
                                    </a-menu-item>
                                    <a-menu-item :key="['4', record.Subdomain, record.SalePageID]">
                                        <global-outlined class="pr-2" />เลือกเป็นเซลเพจหลัก
                                    </a-menu-item>
                                    <a-menu-item :key="['5', record.Subdomain, record.SalePageID]">
                                        <delete-outlined class="pr-2" />
                                        ลบเซลเพจ
                                    </a-menu-item>
                                    <a-menu-item :key="['6', record.SalePageID, record.Subdomain]">
                                        <edit-outlined class="pr-2" />แก้ไข/ชื่อเซลเพจ
                                    </a-menu-item>
                                    <a-menu-item :key="['7', record.SalePageID, record.Subdomain]">
                                        <edit-outlined class="pr-2" />โคลนเซลเพจ/ข้ามโดเมน
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button>
                                <setting-outlined />
                                ตัวจัดการ
                                <DownOutlined />
                            </a-button>
                        </a-dropdown>
                    </template>
                </template>
            </a-table>

            <CloneSalepageCrossDomain v-if="visiblecloneotherdomain" :type="'edit'"
                :subdomainname="dataSourcessubdomainall" :salepageid="SalePageID" :subdomainoriginal="SubDomainName">
            </CloneSalepageCrossDomain>
            <EditeSalepageName v-if="iseditsalepagename" :type="'edit'" :subdomainname="SubDomainName"
                :salepageid="SalePageID">
            </EditeSalepageName>
        </div>
        <a-modal v-model:visible="visibleclonesalepage" title="โคลนเซลเพจ" @ok="handleOk">
            <a-input v-model:value="CloneName" placeholder="ตั้งชื่อเซลเพจ" />
            <a-alert style="margin-top: 20px;" v-if="issame" message="ชื่อซ้ำ" type="error" />
        </a-modal>
    </div>
</template>
<script>
import { usePagination } from "vue-request";
import EditeSalepageName from "../page/Modal/EditeSalepageName.vue"
import { createVNode, computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { Modal } from 'ant-design-vue';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { StarTwoTone, PlusOutlined } from '@ant-design/icons-vue';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, GlobalOutlined, FileAddOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { config_namahayant } from '@/store/store'
import CloneSalepageCrossDomain from "../page/Modal/CloneSalepageCrossDomain.vue"
import CheckToken from "@/components/Form/CheckToken.vue";
const columns = [
    {
        title: "เซลเพจหลัก",
        dataIndex: "MAINSALEPAGE",
        width: "80px",
        fixed: 'left',
    },
    {
        title: "ชื่อเซลเพจ",
        dataIndex: "LINK",
        width: "100",
        fixed: 'left',
    },
    {
        title: "หมายเหตุ",
        dataIndex: "DESC",
        width: "100",
        fixed: 'left',
    },
    {
        title: "จัดการ",
        dataIndex: "EDIT",
        fixed: 'right',
        width: "140px"
    },

];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: { StarTwoTone, DeleteOutlined, EditOutlined, EyeOutlined, GlobalOutlined, FileAddOutlined, SettingOutlined, PlusOutlined, EditeSalepageName, CloneSalepageCrossDomain, CheckToken },
    setup() {
        const isdel = ref(false)
        const iseditsalepagename = ref(false)
        const route = useRoute();
        const SubDomainName = ref("");
        const SalePageID = ref("");
        console.log("subdomainname", route.query.id)
        const subdomain = route.query.id
        const dataSources = ref(null);
        const dataSourcessubdomainall = ref(null);
        const CloneName = ref("");
        const {
            data: dataSource,
            run,
            loading,
            title = "SubDomain : " + route.query.id,
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();

        });

        const querycustomer = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/salepagebysubdomain/" + route.query.id).then(res => {

                dataSources.value = res.data.filter(item => item.IsActive != 0);
                console.log(res.data);
            });
        };

        const querysubdomainall = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/subdomainall").then(res => {

                dataSourcessubdomainall.value = res.data[0];
                console.log("dataSourcessubdomainall.value : ", dataSourcessubdomainall.value)
                visiblecloneotherdomain.value = true;


            });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };

        const handleMenuClick = e => {
            if (e.key[0] == 1) {
                var domain = config_namahayant.value.salepageurl;
                console.log(domain)
                window.open("https://" + e.key[1] + "." + domain + "/" + e.key[2]);
            }
            else if (e.key[0] == 2) {
                window.location = "/salepageedit?id=" + e.key[1] + '&subdomainsalepage=' + e.key[2];
            }
            else if (e.key[0] == 4) {
                setmainsalepage(e.key[1], e.key[2])
                // main sale page 
            }
            else if (e.key[0] == 5) {
                showConfirm(e.key[1], e.key[2])
                // main sale page 
            }
            else if (e.key[0] == 6) {


                iseditsalepagename.value = true;
                SubDomainName.value = e.key[2];
                SalePageID.value = e.key[1];
            }
            else if (e.key[0] == 7) {
                querysubdomainall();
                SubDomainName.value = e.key[2];
                SalePageID.value = e.key[1];

            }
        };
        const delsalepage = ref({
            subdomain: "",
            salepagename: ""
        })
        const showConfirm = (subdomain, salepagename) => {
            delsalepage.value = {
                subdomain: "",
                salepagename: ""

            }
            delsalepage.value.subdomain = subdomain
            delsalepage.value.salepagename = salepagename

            console.log("delsalepage.value ", delsalepage.value)
            Modal.confirm({
                title: 'คุณต้องการลบ เซลเพจ?',
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode('div', {
                    style: 'color:red;',
                }, ''),

                onOk() {
                    gettoken()
                    isdel.value = true;

                },

                onCancel() {
                    console.log('Cancel');
                },

                class: 'test',
            });
        };

        const gettoken = () => {
            var param = {
                message: "Token นี้ ใช้สำหลับลบ salepage " + delsalepage.value.salepagename,
                key: delsalepage.value.salepagename,
            }

            BzbsUser.apisentemail(param)
                .then(res => {
                    console.log(res)
                })
                .catch(error => {
                    console.log(error);
                });
        }

        const calldelete = () => {
            setdeletesalepage(delsalepage.value.subdomain, delsalepage.value.salepagename)

        }
        const issame = ref(false);
        const cloneid = ref("");

        const querychecksalepage = () => {
            loading.value = true;
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;

            return axios.get(path + "/api/salepageid_bof/" + route.query.id + "/" + CloneName.value).then(res => {

                if (res.data.length == 0) {

                    window.location = "/salepageedit?cloneto=" + CloneName.value.replace(/ /g, '') + '&id=' + cloneid.value + '&subdomainsalepage=' + subdomain;


                    visible.value = false;
                }
                else {
                    issame.value = true;
                    setTimeout(() => {
                        issame.value = false;
                    }, 2000);
                }

            });
        };
        const handleOk = () => {
            querychecksalepage()
            // if (CloneName.value && (CloneName.value.replace(/ /g, '') != cloneid.value)) {

            //     window.location = "/salepageedit?cloneto=" + CloneName.value.replace(/ /g, '') + '&id=' + cloneid.value + '&subdomainsalepage=' + subdomain;


            //     visible.value = false;
            // }
            // else {
            //     issame.value = true;
            //     setTimeout(() => {
            //         issame.value = false;
            //     }, 2000);
            // }
        };
        const visible = ref(false);
        const visibleclonesalepage = ref(false);
        const visiblecloneotherdomain = ref(false);

        const showModal = () => {
            visible.value = true;
        };
        const onpopclone = (e) => {
            cloneid.value = e
            visibleclonesalepage.value = true;
        };
        const success = () => {
            Modal.success({
                title: "แก้ไขสำเร็จ !!",
                onOk() {
                }
            });
        };
        const setmainsalepage = async (subdomain, salepagename) => {

            const param = {
                SubDomainName: subdomain,
                MainSalepage: salepagename,
                TYPE: 'UPDATE'
            };


            await BzbsUser.apisetmainsalepage(param)
                .then(res => {
                    success();
                    console.log(res)
                    window.location = "/subdomainsalepage?id=" + subdomain + '&mainsalepage=' + salepagename;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const setdeletesalepage = async (subdomain, salepagename) => {

            const param = {
                SalePageID: salepagename,
                SubDomainName: subdomain,
                IsActive: '0',
                TYPE: 'UPDATE'
            };

            await BzbsUser.apisetdeletesalepage(param)
                .then(res => {
                    success();
                    console.log(res)
                    window.location = "/subdomainsalepage?id=" + subdomain + '&mainsalepage=' + salepagename;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const gotocreate = (subdomain) => {
            window.location = '/salepagecreate?subdomain=' + subdomain
            // route.push('/salepagecreate?subdomain=' + subdomain)
        }
        return {
            issame,
            visibleclonesalepage,
            onpopclone,
            CloneName,
            showModal,
            handleOk,
            visible,
            handleMenuClick,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            subdomain,
            gotocreate,
            config_namahayant,
            SalePageID,
            SubDomainName,
            iseditsalepagename,
            visiblecloneotherdomain,
            dataSourcessubdomainall,
            isdel,
            calldelete,
            delsalepage
        };
    }
});
</script>
