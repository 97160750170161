<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="Youtube" @ok="handleOk" @cancel="close()">

      <a-input v-model:value="value1" @change="onchangelink" addon-before="Youtube URL :" />
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { onEdit, value } from '@/store/store'
export default defineComponent({
  emits: ['onyoutube'],
  setup(props, { emit }) {
    const value1 = ref()
    if (onEdit.value.isshowedit) {
      value1.value = 'https://www.youtube.com/embed/' + onEdit.value.editobj.detail.value
    }
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    showModal()
    const close = () => {
      visible.value = false
      value.value = ["เลือก"]
      onEdit.value.isshowedit = false
    }
    const handleOk = e => {

      if (onEdit.value.isshowedit) {
        value.value = ["เลือก"]
        onEdit.value.isshowedit = false

        visible.value = false
        // emit('onyoutube', { value: constgetId(value1.value), type: 'youtube' })
        // value.value = ["เลือก"] 
        // var code = constgetId(value1.value);
        // try {
        //   emit('onyoutube', { value: code, type: 'youtube' })
        // } catch (error) {
        //   console.log(error)
        // }
      } else {
        visible.value = false
        onEdit.value.isshowedit = false
        emit('onyoutube', { value: constgetId(value1.value), type: 'youtube' })
        console.log(e);
      }

    };
    const constgetId = url => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    }
    const onchangelink = () => {

      onEdit.value.editobj.detail.value = constgetId(value1.value)

    };
    return {
      onchangelink,
      close,
      visible,
      showModal,
      handleOk,
      value1,
      onEdit
    };
  },

});
</script>