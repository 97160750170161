<template>
    <div class="bg-login">
        <div class="flex flex-col justify-center w-screen h-screen">
            <div class="bg-white box-login rounded-6">
                <div class="text-sm font-bold text-center">
                    <a-typography-text type="warning" strong>
                        ระบบหลังบ้าน <span v-if="isdev">(DEV)</span>
                    </a-typography-text>
                </div>
                <div class="mt-7">
                    <!-- <PrimaryButton
                        class="text-sm"
                        text="Sign in"
                        @click="clickLogin()"
                    ></PrimaryButton> -->
                    <LoginForm></LoginForm>
                </div>
                <div v-if="false" class="mt-7">
                    <!-- <Googleauth></Googleauth> -->
                </div>
                <div class="mt-7" v-if="false">
                    <!-- <Lineauth></Lineauth> -->
                </div>
                <div class="mt-8">
                    <div class="text-sm font-bold text-center">
                        <a-typography-text type="warning" strong>
                            Backoffice <span v-if="isdev">(DEV)</span>
                        </a-typography-text>
                    </div>
                    <!-- <img
                        class="m-auto system-logo"
                        src="@/assets/bzbslogo/korn.png"
                    /> -->
                </div>
            </div>
        </div>
        <div v-if="false" class="wrapper-footer">
            <FooterLogin></FooterLogin>
        </div>
        <ModalMaster :controller="modalController"></ModalMaster>
    </div>
</template>
<script>
// import Googleauth from "../page/component/Googleauth.vue"
// import Lineauth from "../page/component/Lineauth.vue"
import Account from "@/helper/AccountHelper.js";
// import Util from '@/helper/Utility.js';
//conponent
// import PrimaryButton from "@/components/Button/PrimaryButton.vue";
//service
import BzbsAuth from "@/core/Account/service/BzbsAuth.js";
//mixin
import ModalMixin from "@/mixin/ModalMixin.js";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
import CacheHelper from "@/helper/CacheHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import FooterLogin from "@/components/Layout/Footer/FooterLogin.vue";
import LoginForm from "../page/LoginForm.vue";
var Cache = new CacheHelper(ConfigManager.getCacheType());
export default {
    name: "LoginAd",
    inject: ["MsAccountStore", "AccountStore"],
    mixins: [ModalMixin, ErrorApiMixin],
    components: {
        FooterLogin,
        // PrimaryButton,
        LoginForm,
        // Googleauth,
        // Lineauth
    },
    data: function () {
        return {
            isdev: process.env.VUE_APP_SERVER == 'dev' ? true : false,
            idToken: null
        };
    },
    computed: {
        // isLogin: function() {
        //     return this.MsAccountStore.state.isLogin;
        // },
        // msProfile: function() {
        //     return this.MsAccountStore.state.msProfile;
        // },
    },
    mounted: function () {
        // console.log('is login ms', this.isLogin);
        // if (this.isLogin) {
        //     // this.flowLoginBzbs();
        // } else {
        //     this.MsAccountStore.methods.initAd().then(() => {
        //         console.log('after init, is login ms', this.isLogin);
        //         // this.flowLoginBzbs();
        //     }).catch((err) => {
        //         console.log(err);
        //     });
        // }
    },
    methods: {
        clickLogin: function () {
            this.loginAd();
        },

        loginAd: function () {
            setTimeout(() => {
                Cache.setCache("msStatus", "signin");
                this.$router.push({ name: "sell_list" });
            }, 1000);
        },

        flowLoginBzbs: async function () {
            let idToken = await this.getIdToken();
            if (idToken == null) {
                this.MsAccountStore.methods.setLoginStatus(false);
                localStorage.clear(); // clear ms cache
                return;
            }

            if (this.isLogin) {
                this.loginBzbs(idToken);
            }
        },

        loginBzbs: async function (idToken) {
            console.log("login bzbs");
            let isBzbsLogin = await this.isBzbsLogin();
            if (isBzbsLogin) {
                console.log("isBzbsLogin ");
                window.location.replace("/shop");
                return;
            }

            console.log("bzbs not login");

            this.loadingModal.show();
            // let idToken = await this.getIdToken();
            // if (idToken == null) {
            //     return;
            // }

            console.log("connect api login");
            BzbsAuth.apiLoginAd(idToken).subscribe({
                next: data => {
                    Account.setLogin(data);
                    console.log("api login", data);

                    window.location.replace("/shop");
                },
                err: err => {
                    this.loadingModal.hide();
                    console.log(err);
                    this.errorApi(err);
                }
            });
        },

        isBzbsLogin: function () {
            return new Promise(resolve => {
                this.AccountStore.methods.loginStatus().subscribe(() => {
                    console.log("sub login status");
                    resolve(this.AccountStore.state.isLogin);
                });
                console.log("call login status");
            });
        },

        getIdToken: function () {
            return new Promise(resolve => {
                this.MsAccountStore.methods
                    .getAdToken()
                    .then(data => {
                        console.log("get ms token", data);
                        // let token = data.accessToken;
                        // this.idToken = data.idToken;
                        this.idToken = data.accessToken;
                        // this.getMsProfile(token);
                        // this.getMsGroup(token);
                        resolve(data.accessToken);
                    })
                    .catch(err => {
                        console.log(err);
                        resolve(null);
                    });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.bg-login {
    background: url("../../../assets/background/login_new.png");
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
}

.box-login {
    width: 291px;
    margin: auto;
    padding: 30px 40px 20px 40px;
    border-radius: 6px;
}

.system-logo {
    width: 56px;
}

.wrapper-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    margin: 0;
}
</style>
