<template>
    <div v-if="dataSources" class="relative p-4 wrapper-track">

        <!-- 
        <pre>{{ dataSources?.recordset?.length }}</pre>
        <pre>error {{ errorOccurred }}</pre> -->
        <div style="pointer-events: none;">
            <a-image :preview="{ visible: false }" class="image-center banner" alt="มหายันต์ลูกกอ"
                src="https://namahayan.blob.core.windows.net/assets/banner.webp"></a-image>
        </div>
        <div class="text-lg font-bold text-gray-500 text-center p-3" style="font-size:14px">ข้อมูลสินค้า
            <pre style="color: gray;font-size:12px">({{ dataSources?.recordset[0]?.CustomerOrder }})</pre>
        </div>

        <!-- <pre>{{ dataSources.recordset.CustomerProductsType?.includes('ยันต์') }}</pre> -->

        <div v-if="dataSources?.recordset?.length > 0" :key="idx" v-for="(item, idx) of dataSources.recordset">
            <div class="panel-title mb-4">
                <div class="title-left"><a-typography-text strong underline :level="4">{{ item.CustomerProductsType + ' ' +
                    item.CustomerProductsSize }}</a-typography-text> </div>
                <div class="title-right"><a-typography-text strong underline :level="4">{{ formatdate(item.OrderDate)
                }}</a-typography-text></div>

            </div>
            <pre> {{ item.CustomerDesc }}</pre>
        </div>
        <a-image :preview="false" :width="auto" src="https://namahayan.blob.core.windows.net/salepage/1668068170807.webp" />

    </div>
</template>
 
<script>

import TableLoad from "@/components/Skeleton/TableLoad"

import { onErrorCaptured, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons-vue';

import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility";
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 


export default defineComponent({
    components: {
        TableLoad,
        UserOutlined,
        SolutionOutlined,
        LoadingOutlined,
        SmileOutlined,
    },

    setup() {

        const ispaymentsuccess = ref(false)
        const errorOccurred = ref(false);

        onErrorCaptured((error, vm, info) => {
            // Handle the error here
            console.error('Error occurred during rendering:', error);
            console.error('Vue instance:', vm);
            console.error('Additional information:', info);

            // Update the errorOccurred flag
            errorOccurred.value = true;

            // Prevent the error from propagating further
            return false;
        });


        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        const track = ref("-")
        const cuscode = ref("-")
        const so = ref("-")
        const pk = ref("-")
        const current_status = ref(0)

        const searchInput = ref();
        const dataSources = ref(null);




        const getgreencard = ref([])
        onMounted(() => {
            if (urlParams.has('so')) {
                so.value = urlParams.get('so') || "-";
            }
            if (urlParams.has('cuscode')) {
                cuscode.value = urlParams.get('cuscode') || "-";
            }
            if (urlParams.has('track')) {
                track.value = urlParams.get('track') || "-";
            }
            if (urlParams.has('pk')) {
                pk.value = urlParams.get('pk') || "-";
            }


            querycustomer()
            getimagegreen()

        });

        const getimagegreen = () => {

            return BzbsUser.getimagegreen(so.value)
                .then(res => {

                    getgreencard.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("L");
        };

        const querycustomer = () => {

            return BzbsUser.getconsumerhistory(track.value, cuscode.value, so.value, pk.value)
                .then(res => {

                    dataSources.value = res.data

                    checkstatuspayment()
                    // querycustomerdetail(res.data[0].CustomerOrder)


                })
                .catch(error => {
                    console.log(error);
                });
        };

        const checkstatuspayment = () => {

            var hasNotsuccess = false

            dataSources.value.payment.forEach(element => {

                if (element.Checking != 'checked') {
                    hasNotsuccess = true
                }
            });

            if (hasNotsuccess) {
                ispaymentsuccess.value = false
            }
            else {
                ispaymentsuccess.value = true

            }
        }


        const getdatagreen = json => {

            return JSON.parse(JSON.stringify(json));
        };

        const gototrack = (paramtrack) => {
            if (urlParams.has('inter')) {
                window.location = "/consumertrackinginter?track=" + paramtrack + "&cuscode=" + cuscode.value + '&inter=true'
            }
            else {

                window.location = "/consumertracking?cuscode=" + cuscode.value
            }
        }
        return {
            gototrack,
            getgreencard,
            current_status,
            dataSources,
            querycustomer,
            formatdate,
            searchInput,
            Util,
            getdatagreen,
            pk,
            errorOccurred,
            ispaymentsuccess
        };
    }
});
</script>
<style lang="scss">
.wrapper-track {
    width: 100%;
    max-width: 450px;
    margin: auto;

    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background: #ae8b40 !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-tail::after {
        background-color: #ae8b40 !important;
    }

    .green-table {
        font-family: 'Arial, Helvetica, sans-serif';
        font-size: 14px;
        color: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding-left: 0px;
        text-align: center;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffcc), to(#99cccc));
        background: -moz-linear-gradient(top, #ccffcc, #99cccc);
        filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#ccffcc, EndColorStr=#99cccc);
    }

    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        float: left;
        margin-right: 10px;
    }

    .namegreen {
        position: absolute;
        color: rgb(255 255 255 / 30%);
        font-size: 37px;
        top: 45%;
        left: 15px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        pointer-events: none;
        -webkit-transform: rotate(-45deg);
        font-family: tahoma;
    }


    .cus-desc {
        word-break: break-all;
        white-space: pre-line;
    }

    .panel-title {
        display: inline-flex;
        width: 90%;
    }

    .title-left {
        flex: 2;
        text-align: left;
    }

    .title-right {
        flex: 1;
        text-align: right;
    }

    .ant-steps-item-container {
        margin-left: -10px;
    }

    .ant-steps-item-tail {
        margin-left: -15px;
    }

    .ant-steps-item-content {
        margin-right: 15px;
    }
}
</style>
