<template>
    <VOverlay
        :value="value"
        class="flex flex-col items-center justify-center backdrop"
        @dismiss="dismiss()"
        :lockBackdrop="lockBackdrop"
    >
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div class="modal card has-divider" :style="cardStyle">
                <template v-if="title != undefined">
                    <div class="card-header">
                        <div class="flex-grow text-gray-1" :class="titleClass" v-html="title"></div>
                        <template v-if="!hideHeaderCloseBtn">
                            <div class="flex-none btn-dismiss" @click="dismiss()">
                                <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
                            </div>
                        </template>
                    </div>
                </template>
                <div class="card-body">
                    <slot></slot>
                </div>
                <template v-if="$slots['actions']">
                    <div class="card-actions">
                        <slot name="actions"></slot>
                    </div>
                </template>
            </div>
        </transition>
    </VOverlay>
</template>

<script>
export default {
    name: 'VDialog',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: undefined
        },
        titleClass:{
            type: String,
            default: "text-sm"
        },
        width: {
            type: Number,
            default: 400
        },
        hideHeaderCloseBtn: {
            type: Boolean,
            default: false
        },
        lockBackdrop: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        cardStyle: function () {
            return {
                width: `${this.width}px`
            }
        }
    },
    methods: {
        dismiss: function () {
            this.$emit("update:value", false)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../style/base/base.scss";

.backdrop {
    z-index: $zindex-modal-backdrop;
}
.modal {
    z-index: $zindex-modal;
}
</style>
