<template>
  <div>
    <a-modal v-model:visible="visible" title="Track" @ok="handleOk" @cancel="close()">
      <div class="text-center">
        <img class="p-1 m-auto" :width="100" src="../../../../../../assets/icons/btn/track_img.png" />
      </div>
      <div class="text-center">(ตัวอย่าง Format)</div>
      <div class="text-center" style="font-size: 18px;">[
        <span style="color:red">ลำดับ</span>
        <span>-</span>
        <span style="color:red">ยอด</span>
        <span>-</span>
        <span style="color:red">วันที่</span>
        <span>-</span>
        <span style="color:red">เวลา</span>
        ]
      </div>
      <!-- <a-input v-model:value="value1" @change="onchangelink" addon-before="Youtube URL :" /> -->
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { onEdit, value } from '@/store/store'
export default defineComponent({
  emits: ['ontrack'],
  setup(props, { emit }) {
    const value1 = ref()
    if (onEdit.value.isshowedit) {
      value1.value = onEdit.value.editobj.detail.value
    }
    const visible = ref(false);

    const showModal = () => { 
      visible.value = true;
    };
    showModal()
    const close = () => {
      visible.value = false
      value.value = ["เลือก"]
      onEdit.value.isshowedit = false
    }
    const handleOk = e => {
      if (onEdit.value.isshowedit) {
        onEdit.value.isshowedit = false

        visible.value = false
        // emit('onyoutube', { value: constgetId(value1.value), type: 'youtube' })
        // value.value = ["เลือก"] 
        // var code = constgetId(value1.value);
        // try {
        //   emit('onyoutube', { value: code, type: 'youtube' })
        // } catch (error) {
        //   console.log(error)
        // }
      } else {
        visible.value = false
        onEdit.value.isshowedit = false
        emit('ontrack', { value: "showtrack", type: 'track' })
        console.log(e);
      }

    };
    const onchangelink = () => {

      // onEdit.value.editobj.detail.value = constgetId(value1.value)

    };
    return {
      onchangelink,
      close,
      visible,
      showModal,
      handleOk,
      value1,
      onEdit
    };
  },

});
</script> 