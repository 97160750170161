<template>
    <div v-if="typepost == 'private'">
        <a-button type="dash" danger @click="showModal">พิมพิ์ใบปะหน้า(ในประเทศ)</a-button>
        <a-modal v-model:visible="visible" title="พิมพิ์ใบปะหน้า1.1" width="ึ100%" wrap-class-name="full-modal"
            @ok="handleOk"> 
            <iframe :src="
                'https://promptpost.thailandpost.com/backend/api/stores/'+storeid+'/printmailings?mailingBarcode=' +
                    prompostcode +
                    '&LabelOption=10'
            " frameBorder="0" scrolling="auto" height="100%" width="100%"></iframe>
        </a-modal>
    </div>
    <div v-if="typepost == 'public' && posturl">
 
        <a-button type="dash" danger @click="showModal">พิมพิ์ใบปะหน้า(ต่างประเทศ)</a-button>
        <a-modal v-model:visible="visible" title="พิมพิ์ใบปะหน้า1.2" width="ึ100%" wrap-class-name="full-modal"
            @ok="handleOk">
            <iframe :src="posturl" frameBorder="0" scrolling="auto" height="100%" width="100%"></iframe>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    props: {
        typepost: {
            type: String
        },
        prompostcode: {
            type: String
        },
        posturl: {
            type: String
        }, storeid: {
            type: String
        },
    },

    setup() {
        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };

        return { 
            visible,
            showModal,
            handleOk
        };
    }
});
</script>
<style lang="scss">
.full-modal {
    .ant-modal {
        max-width: 100%;
        top: 0;
        padding-bottom: 0;
        margin: 0;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: calc(100vh);
    }

    .ant-modal-body {
        flex: 1;
    }
}
</style>
