<template>
    <div class="page max-w-full overflow-x-hidden w-full h-full flex flex-col">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar @clickexport="modalController.exportModal = true"></Toolbar>
            </template>
        </TopBar>
        <div v-if="!Storedata.methods.checkgrantccountonly()">
            <TableCheckmoney></TableCheckmoney>
        </div>
        <div v-else class="card w-full" style="min-height:100vh">
            <a-result status="403" title="" sub-title="หน้านี้ใช้งานได้เฉพาะ Account บัญชี">
                <template #extra>
                </template>
            </a-result>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import TableCheckmoney from "../../../DashboardInfo/component/TableCheckmoney.vue";
import Storedata from "@/store/Storedata.js";
export default {
    components: { TopBar, Footer, TableCheckmoney },
    data() {
        return {
            Storedata
        }
    },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "รายการขาย"
                }
            ];
        }
    }
};
</script>

<style></style>
