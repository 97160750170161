<template>
    <a-button class="ml-4" type="primary" @click="showDrawer"
        >ตัวช่วยเหลือข้อมูล</a-button
    >

    <a-drawer
        :width="1700"
        v-model:visible="visible"
        class="custom-class"
        style="color: red"
        :title="p_data.typename"
        placement="right"
        @after-visible-change="afterVisibleChange"
    >
        <a-table
            v-if="categoty_list"
            :columns="columns"
            size="small"
            :data-source="categoty_list"
            :pagination="false">
            <template #bodyCell="{ column, text }">
                <template v-if="column.dataIndex === 'Category_1_Code'">
                    <span style="color:blue;font-size:20px;">{{ text }}</span>
                </template>

                <template v-if="column.dataIndex === 'Category_2_Code'">
                    <span style="color:blue;font-size:20px;">{{ text }}</span>
                </template>

                <template v-if="column.dataIndex === 'Category_3_Code'">
                    <span style="color:blue;font-size:20px;">{{ text }}</span>
                </template>

                <template v-if="column.dataIndex === 'Category_4_Code'">
                    <span style="color:blue;font-size:20px;">{{ text }}</span>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>
<script>
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { defineComponent, ref, computed } from "vue";
import { usePagination } from "vue-request";
const columns = [
    {
        title: "ชื่อสินค้าแสดงลูกค้า",
        dataIndex: "Name"
    },
    {
        title: "หมวดหมู่ 1",
        dataIndex: "Category_1"
    },
    {
        title: "รหัสหมวดหมู่ 1",
        dataIndex: "Category_1_Code",
        sorter: {
            compare: (a, b) => a.Category_1_Code - b.Category_1_Code,
            multiple: 3
        }
    },
    {
        title: "หมวดหมู่ 2",
        dataIndex: "Category_2",
    },
    {
        title: "รหัสหมวดหมู่ 2",
        dataIndex: "Category_2_Code",
        sorter: {
            compare: (a, b) => a.Category_2_Code - b.Category_2_Code,
            multiple: 3
        }
    },
    {
        title: "หมวดหมู่ 3",
        dataIndex: "Category_3"
    },
    {
        title: "รหัสหมวดหมู่ 3",
        dataIndex: "Category_3_Code",
        sorter: {
            compare: (a, b) => a.Category_3_Code - b.Category_3_Code,
            multiple: 3
        }
    },
    {
        title: "หมวดหมู่ 4",
        dataIndex: "Category_4"
    },
    {
        title: "รหัสหมวดหมู่ 4",
        dataIndex: "Category_4_Code",
        sorter: {
            compare: (a, b) => a.Category_4_Code - b.Category_4_Code,
            multiple: 3
        }
    },
    {
        title: "ตัวขั้น Hash track",
        dataIndex: "Track"
    },
    {
        title: "รหัสสินค้า",
        dataIndex: "ProductCode"
    },
    {
        title: "ราคาขาย(ตัวเลข)",
        dataIndex: "Price"
    },
    {
        title: "ต้นทุน(ตัวเลข)",
        dataIndex: "Cost"
    },
    {
        title: "ตัวย่อ",
        dataIndex: "Desc"
    }
];
export default defineComponent({
    props: {
        detail: null
    },
    setup(props) {
        debugger;
        const p_data = ref(props.detail);
        const visible = ref(false);
        const afterVisibleChange = bool => {
            console.log("visible", bool);
        };
        const showDrawer = () => {
            visible.value = true;
        };
        const categoty_list = ref([]);
        const apigetlistCategoryType = () => {
            var type = props.detail.type || props.detail.Type;
            BzbsUser.apigetcategorymastertype(type)
                .then(res => {
                    res.data.forEach((item, index) => {
                        categoty_list.value.push({
                            key: index,
                            ...item,
                            Category_1_Code: parseInt(item.Category_1_Code, 10),
                            Category_2_Code: parseInt(item.Category_2_Code, 10),
                            Category_3_Code: parseInt(item.Category_3_Code, 10),
                            Category_4_Code: parseInt(item.Category_4_Code, 10)
                        });
                    });
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        apigetlistCategoryType();
        return {
            categoty_list,
            visible,
            afterVisibleChange,
            showDrawer,
            columns,
            p_data
        };
    }
});
</script>
