<template>

    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-space :size="10">
                <a-button v-if="dataSources" @click="gotoaddsalepageofdomain(subdomain)" class="mt-2 mb-3"
                    type="primary">
                    <template #icon>
                        <plus-outlined />
                    </template>
                    <span>เลือกเซลเพจหลักสำหรับ Domain</span>
                </a-button>
                <a-typography-text type="secondary"> เซลเพจหลักปัจจุบัน
                </a-typography-text>
                <a-typography-link
                    v-if="dataSources && dataSources[0]?.MainSubDomainOfDomain && dataSources[0]?.MainSalePageIDOfDomain"
                    :href="'https://' + dataSources[0]?.MainSubDomainOfDomain + '.' + DomainUrl + '/' + dataSources[0]?.MainSalePageIDOfDomain"
                    target="_blank">
                    (https://{{ dataSources[0]?.MainSubDomainOfDomain }}.{{ DomainUrl
                    }}/{{ dataSources[0]?.MainSalePageIDOfDomain }})
                </a-typography-link>
            </a-space>
            <a-table :scroll="{ x: 0 }" :columns="columns" :data-source="dataSources"
                :pagination="{ pageSize: 30 }" @change="handleTableChange" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record }">

                    <template v-if="column.dataIndex === 'LINK'">
                        <div class="">

                            <span>
                                <branches-outlined class="mr-2" />
                                <a
                                    :href="'/subdomainsalepage?id=' + record.SubDomainName + '&mainsalepage=' + dataSources[0]?.MainSalePageIDOfDomain || record.MainSalepage">
                                    {{ record.SubDomainName }}
                                </a>
                            </span>
                            <!-- <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                                {{ record.SubDomainName }}
                            </a-typography-title> -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'EDIT'">
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="handleMenuClick">
                                    <a-menu-item :key="['1', record.SubDomainName, record.MainSalepage]">
                                        <profile-outlined class="pr-2" />รายการเซลเพจ
                                    </a-menu-item>
                                    <a-menu-item :key="['2', record.SubDomainName]">
                                        <plus-circle-outlined class="pr-2" />สร้างเซลเพจ
                                    </a-menu-item>
                                    <a-menu-item :key="['3', record.SubDomainName]">
                                        <global-outlined class="pr-2" />ลิ้งเซลเพจหลัก
                                    </a-menu-item>
                                    <a-menu-item :key="['4', record.SubDomainName]"
                                        @click="onpopeditname(record.SubDomainName, record.SalePageID)">
                                        <edit-outlined class="pr-2" />แก้ไข/ชื่อ
                                    </a-menu-item>
                                    <a-menu-item :disabled="true" :key="['5', record.SubDomainName, record.SalePageID]">
                                        <delete-outlined class="pr-2" />
                                        ลบโดเมน
                                    </a-menu-item>
                                    <!-- <a-menu-item :key="['6', record.SubDomainName, record.SalePageID]">
                                        <file-protect-outlined class="pr-2" />
                                    </a-menu-item> -->
                                </a-menu>
                            </template>
                            <a-button>
                                <setting-outlined />
                                ตัวจัดการ
                                <DownOutlined />
                            </a-button>
                        </a-dropdown>
                        <!-- <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                            <a :href="'/salepageedit?id=' + record.SalePageID"><u>ตัวจัดการ</u></a>
                        </a-typography-title> -->
                        <!-- <a-modal v-model:visible="visible" title="แก้ไขชื่อ Subdomain" @ok="handleOk">
                            <a-input v-model:value="CloneName" placeholder="ตั้งชื่อ Subdomain" />
                        </a-modal> -->

                    </template>

                    <template v-if="column.dataIndex === 'BACKUP'">
                        <!-- <div v-show="!bk" @click="setparam(record.SubDomainName)">{{record.SubDomainName}}</div> -->


                        <download-excel @click="setparam(record.SubDomainName)" :fetch="fetchbackup"
                            :name="record.SubDomainName+getcurrentdate()+'.csv'" :type="'csv'">
                            <a>สำรองข้อมูล</a>
                        </download-excel>


                    </template>
                </template>
            </a-table>
            <AddSalepageOfDomain v-if="visibleaddsalepageofdomain" :type="'edit'" :subdomainname="dataSources"
                :salepageid="SalePageID">
            </AddSalepageOfDomain>

            <AddSubDomainName v-if="visible" :type="'edit'" :subdomainname="SubDomainName" :salepageid="SalePageID">
            </AddSubDomainName>
        </div>
    </div>
</template>
<script>
import { usePagination } from "vue-request";
import { createVNode, computed, defineComponent, onMounted, ref } from "vue";
import axios from "axios";
import AddSubDomainName from "../page/Modal/AddSubDomainName.vue"
import AddSalepageOfDomain from "../page/Modal/AddSalepageOfDomain.vue"
import { DeleteOutlined, ExclamationCircleOutlined, ProfileOutlined, PlusCircleOutlined, GlobalOutlined, SettingOutlined, BranchesOutlined, EditOutlined } from '@ant-design/icons-vue';
import { config_namahayant } from '@/store/store'
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { Modal } from 'ant-design-vue';
const columns = [
    {
        title: "ชื่อ โดเมน",
        dataIndex: "LINK",
        width: "50",
        fixed: 'left',
    },
    {
        title: "จัดการ",
        dataIndex: "EDIT",
        width: "140px",
        fixed: 'right',
    },
    {
        title: "สำรองข้อมูล",
        dataIndex: "BACKUP",
        width: "140px",
        fixed: 'right',
    },


];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: { DeleteOutlined, ProfileOutlined, PlusCircleOutlined, GlobalOutlined, SettingOutlined, BranchesOutlined, EditOutlined, AddSubDomainName, AddSalepageOfDomain },
    setup() {
        const DomainUrl = ref(process.env.VUE_APP_SALEPAGE_URL);
        const SubDomainName = ref("");
        const SalePageID = ref("");
        const dataSources = ref(null);
        const CloneName = ref("");
        const visibleaddsalepageofdomain = ref(false)
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการ โดเมน",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const querycustomer = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/subdomainall").then(res => {

                dataSources.value = res.data[0];
                console.log(res.data[0]);
            });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const bk = ref(false)
        const s1 = ref("")

        const setparam = (p) => {
            console.log("xxx: ", p)
            s1.value = p
            bk.value = true
        }
        const fetchbackup = async () => {
            try {
                if (!bk.value) return
                let path = window.location.host.includes("localhost")
                    ? "http://localhost:8090"
                    : process.env.VUE_APP_API_BASE_URL;
                console.log(path)
                console.log(s1.value)
                console.log(path + '/api/salepagebysubdomain/' + s1.value)
                const response = await fetch(path + '/api/salepagebysubdomain/' + s1.value);
                const json_backup = await response.json();
                bk.value = false
                return json_backup;
            } catch (error) {
                console.log(error)
            }

        }
        const handleMenuClick = e => {
            if (e.key[0] == 1) {
                window.location = "/subdomainsalepage?id=" + e.key[1] + '&mainsalepage=' + e.key[2];
            }
            else if (e.key[0] == 2) {
                window.location = "/salepagecreate?subdomain=" + e.key[1];
            }
            else if (e.key[0] == 3) {
                window.open("https://" + e.key[1] + "." + config_namahayant.value.salepageurl + "/");
            } else if (e.key[0] == 5) {
                showConfirm(e.key[1])
                // main sale page 
            }
            else if (e.key[0] == 6) {
                // showConfirm(e.key[1])
                // main sale page 
            }
        };
        const showConfirm = (subdomain) => {
            Modal.confirm({
                title: 'คุณต้องการลบ เซลเพจ?',
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode('div', {
                    style: 'color:red;',
                }, ''),

                onOk() {
                    setdeleteDomain(subdomain)
                },

                onCancel() {
                    console.log('Cancel');
                },

                class: 'test',
            });
        };
        const setdeleteDomain = async (subdomain) => {

            const param = {
                id: subdomain,
            };

            await BzbsUser.apisetdeletesubdomain(param)
                .then(res => {
                    success();
                    console.log(res)

                })
                .catch(error => {
                    console.log(error);
                });
        };
        const success = () => {
            Modal.success({
                title: "แก้ไขสำเร็จ !!",
                onOk() {
                    window.location.reload()
                }
            });
        };
        const cloneid = ref("");
        const handleOk = e => {
            console.log(e)
            if (CloneName.value) {

                window.location = "/salepageedit?cloneto=" + CloneName.value.replace(/ /g, '') + '&id=' + cloneid.value;
            }
            visible.value = false;
        };
        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };
        const onpopeditname = (subdomainname, salepageid) => {
            SubDomainName.value = subdomainname
            SalePageID.value = salepageid
            visible.value = true;
        };

        const gotoaddsalepageofdomain = (e) => {
            console.log(e)
            visibleaddsalepageofdomain.value = true;
        }

        const getcurrentdate = () => {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = parseInt(today.getFullYear()) + 543;
            var hh = String(today.getHours()).padStart(2, '0');
            var mi = String(today.getMinutes()).padStart(2, '0');

            return today = '_' + dd + '_' + mm + '_' + yyyy + '_' + hh + '.' + mi
        }


        return {
            getcurrentdate,
            onpopeditname,
            CloneName,
            showModal,
            handleOk,
            visible,
            handleMenuClick,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            SubDomainName,
            SalePageID,
            visibleaddsalepageofdomain,
            gotoaddsalepageofdomain,
            DomainUrl,
            fetchbackup,
            setparam,
            json_data: [
                {
                    name: "Tony Peña",
                    city: "New York",
                    country: "United States",
                    birthdate: "1978-03-15",
                    phone: {
                        mobile: "1-541-754-3010",
                        landline: "(541) 754-3010",
                    },
                }]
        };
    }
});
</script>
