/**
 * Generic modal
 * @method setAlertModal
 */
export function ModalController() {
    //generic
    this.alertModal = null;
    this.confirmModal = null;
    this.loadingModal = null;
    //redeem
    this.confirmRedeemModal = null;
    this.serialModal = null;
    this.drawDeliveryModal = null;
    this.shippingAddressModal = null;
    //address
    this.addAddressModal = null;
    this.editAddressModal = null;
    this.listAddressModal = null;
    this.addTaxAddressModal = null;
    this.editTaxAddressModal = null;
    this.listTaxAddressModal = null;

    //#region generic
    this.setAlertModal = function(alertModalController) {
        this.alertModal = alertModalController;
    };
    this.setConfirmModal = function (confirmModalController) {
        this.confirmModal = confirmModalController;
    };
    this.setLoadingModal = function (loadingModalController) {
        this.loadingModal = loadingModalController;
    };
    //#endregion generic

    //#region redeem
    this.setConfirmRedeemModal = function (confirmRedeemModalController) {
        this.confirmRedeemModal = confirmRedeemModalController;
    };
    this.setSerialModal = function (serialModalController) {
        this.serialModal = serialModalController;
    };
    this.setDrawDeliveryModal = function (drawDeliveryModalController) {
        this.drawDeliveryModal = drawDeliveryModalController;
    };
    this.setShippingAddressModal = function (shippingAddressModalController) {
        this.shippingAddressModal = shippingAddressModalController;
    };
    //#endregion redeem

    //#region address
    this.setAddAddressModal = function (addAddressModalController) {
        this.addAddressModal = addAddressModalController;
    };
    this.setEditAddressModal = function (editAddressModalController) {
        this.editAddressModal = editAddressModalController;
    };
    this.setListAddressModal = function (listAddressModalController) {
        this.listAddressModal = listAddressModalController;
    };
    this.setAddTaxAddressModal = function (addTaxAddressModalController) {
        this.addTaxAddressModal = addTaxAddressModalController;
    };
    this.setEditTaxAddressModal = function (editTaxAddressModalController) {
        this.editTaxAddressModal = editTaxAddressModalController;
    };
    this.setListTaxAddressModal = function (listTaxAddressModalController) {
        this.listTaxAddressModal = listTaxAddressModalController;
    };
    //#endregion address
}
