import { reactive } from "vue";

const state = reactive({
    breadcrumb: [],
    notification: false,
    notificationCount: 0,
    userAccountPanel: false,
    SearchUserlist: {},
    isOnChangeSelectType: false,
    sortby: {}
});

const methods = {
    setBreadcrumb: function(items) {
        state.breadcrumb = items;
    },
    clearBreadcrumb: function() {
        state.breadcrumb = [];
    },
    setNotification: function(val) {
        state.notification = val;
    },
    setNotificationCount: function(val) {
        state.notificationCount = val;
    },
    setUserAccountPanel: function(val) {
        state.userAccountPanel = val;
    },
    setSearchUserlist: function(val) {
        state.SearchUserlist = val;
    },
    setisOnChangeSelectType: function(val) {
        state.isOnChangeSelectType = val;
    },
    setsortby: function(val) {
        state.sortby = val;
    }
};

export default {
    state: state,
    methods: methods
};
