<template>
    <div class="p-select">
        <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="4">
            </a-col>
            <a-col :span="4">
                <!-- <FontAwesomeIcon icon="fa-solid fa-plus" /> -->
                <!-- <font-awesome-icon icon="fa-solid fa-plus" /> -->

                <router-link to="/insertproduct" class="bg-green-600 text-white rounded-md py-1 px-4 mr-2 btn_select">
                    <font-awesome-icon icon="fa-solid fa-plus" class="pr-2 bg-cyan-500 hover:bg-cyan-600" />สร้างสินค้า</router-link>
                <!-- <div @click="setproduct('insert_product')" class="height-100 relative p-4 card">สร้างสินค้า</div> -->
            </a-col>
            <a-col :span="4">
                <router-link to="/editproduct"
                    class="bg-green-600 text-white rounded-md py-1 px-4 mr-2 btn_select"><font-awesome-icon icon="fa-solid fa-edit" class="pr-2" />แก้ไข</router-link>
            </a-col>
            <a-col :span="4">
            </a-col>
        </a-row>
    </div>
</template> 
<script>
import InsertProduct from "./insert/InsertProduct.vue";
import { formStateStore } from "@/store/storebackoffice";
import { computed, defineComponent, onMounted, ref, reactive } from "vue";
export default defineComponent({
    components: {
        InsertProduct
    },
    props: ["producttype"],
    setup() {
        const formState = reactive(formStateStore)
        const setproduct = (set) => {
            formState.masterproduct.mastertype = set
            if (set = 'insert_product') {
                insertproduct
            }


        }

        return {
            formState,
            setproduct
        };
    }
});
</script>

<style lang="scss"></style>
