<template>
  <div>
    <a-modal v-model:visible="visible" :title="detail.title" @cancel="close" @ok="handleOk">
      <div class="desc-calcel">{{detail.subtitle}}</div>

      <a-form-item ref="" label="หมายเหตุ*" :rules="[{ required: true, message: 'กรุณากรอกหมายเหตุ' }]">
        <a-textarea v-model:value="ProductStatusDesc" placeholder="หมายเหตุ" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-item>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { notification } from 'ant-design-vue';
export default defineComponent({
  props: {
    formState: null,
    title: null,
    subtitle: null,
  },
  setup(props, { emit }) {
    const detail = ref({
      title: props.title,
      subtitle: props.subtitle
    })
    const ProductStatusDesc = ref("")
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;

    };
    const handleOk = e => {
      console.log(e);

      apiupdateproductstatus()
    };
    const close = e => {
      console.log(e)
      visible.value = false;
      emit('close', false)

    }

    const apiupdateproductstatus = async () => {
      var param = {
        DeliveryStatus: "cancel",
        Desc: ProductStatusDesc.value,
        AdditionalTage: props.formState.rowselecteddetail
      }
      console.log("props.formState", props.formState.rowselecteddetail)
      console.log("param cancel ", param)

      await BzbsUser.apiupdateproductdeliverystatus(param)
        .then(res => {
          console.log("update status ", res)
          openNotificationWithIcon('success', 'ยกเลิกเรียบร้อย')
          visible.value = false;

        })
        .catch(error => {
          console.log(error);
        });
    };
    showModal()
    const openNotificationWithIcon = (type, message) => {
      notification[type]({
        message: message,
      });
    }
    return {
      openNotificationWithIcon,
      ProductStatusDesc,
      detail,
      close,
      visible,
      showModal,
      handleOk,
      BzbsUser
    };
  },
});
</script>
<style lang="scss">
.desc-calcel {
  color: red;
  padding-bottom: 10px;
}
</style>