<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl p-3">
                <div class="text-center text-lg pb-5 pt-3">
                    <span v-if="isPrivilegeMessage"
                          v-html="privilegeMessage"></span>
                    <span v-if="isDelivery"
                          v-html="deliveryMessage"></span>
                </div>
                <div class="w-1/2 m-auto">
                    <button class="w-full bg-blue-600 text-white rounded-md py-1" @click="clickOk()">
                        {{lbl.default.close}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import DatetimeHelper from "@/helper/DatetimeHelper.js";
import { CampaignType } from "@/helper/StaticValue.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: "DrawDeliveryModal",
    mixins: [LocaleMixin],
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,

            drawDeliveryModel: null,

            deliveryMessage: '',
            isDelivery: false,

            privilegeMessage: '',
            isPrivilegeMessage: false,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function(config) {
            this.drawDeliveryModel = config.drawDeliveryModel;
            this.initial();

            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickOk: function () {
            this.hide();
            this.controller.sendCallbackClose();
        },

        initial: function () {
            if(this.drawDeliveryModel.privilegeMessage) {
                this.isPrivilegeMessage = true;
                this.privilegeMessage= this.drawDeliveryModel.privilegeMessage;
            } else {
                if(this.drawDeliveryModel.delivered == true) {
                    this.isDelivery = true;
                    this.deliveryMessage = this.lbl.serial.delivery;
                }

                if(this.drawDeliveryModel.type == CampaignType.Draw) {
                    this.isPrivilegeMessage = true;
                    this.privilegeMessage = this.getDrawMessage();
                }
            }
        },

        getDrawMessage: function () {
            let date = DatetimeHelper.getDatetimeFullFormat( this.drawDeliveryModel.campaignExpiredTimestamp);
            return Util.stringFormat(this.lbl.serial.draw, date);
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/modal.scss";
    .modal {
        @extend %modal;
    }
</style>
