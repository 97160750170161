import { reactive, provide, onMounted } from "vue"
import { DateTime } from "luxon";
import Utility from "@/helper/Utility";

export default function useDashboardSummaryComposable() {

    const states = reactive({
        trendingData: null,
        earnData: null,
        burnData: null,
    })

    //#region graph options
    const getGraphOptions = function (labels, flag = 0) {
        return {
            chart: {
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            grid: {
                show: true,
                position: "back",
                yaxis: {
                    lines: {
                        show: true,
                    }
                },
                xaxis: {
                    lines: {
                        show: true,
                    }
                },
            },
            yaxis: {
                show: true,
                axisBorder: {
                    show: false
                },
                labels: {
                    formatter: function (val) {
                        return Utility.formatNum(val)
                    }
                }
            },
            xaxis: {
                categories: labels,
                type: "numeric",
                tickAmount: 'dataPoints',
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                    formatter: function (value) {
                        if (flag === 1) {
                            return DateTime.fromMillis(value).toFormat("dd LLL")
                        } else {
                            return value != undefined ? DateTime.fromMillis(value).toFormat("dd LLL") : ""
                        }

                    }
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            },
            legend: {
                show: true,
                position: "top",
                horizontalAlign: 'right',
                markers: {
                    radius: 4,
                    width: 30,
                    height: 4
                },
                offsetY: 16,
                labels:{
                    useSeriesColors: true
                }
            },
            tooltip: {
                enabled: true,
                x: {
                    formatter: function(val){
                        console.log(val)
                        return DateTime.fromMillis(val).toFormat("dd MMM yyyy")
                    }
                },
            },
            colors: ["#fbac6b", "#5cc6c6"],
            stroke: {
                curve: "smooth",
            },
            markers: {
                size: 4
            }
        }
    }
    //#endregion

    const getDashboardSummary = function () {
        setTimeout(() => {

            //#region trand graph data 1
            let labelsTrend = []
            let jsDate = new Date()
            jsDate.setHours(0, 0, 0, 0)
            let curDt = DateTime.fromJSDate(jsDate)
            for (let i = 31; i > 0; i--) {
                let dt = curDt.plus({ days: -1 * i }).toJSDate().getTime()
                labelsTrend.push(dt)
            }
            // labelsTrend.push(curDt.toJSDate())
            let datapoints1 = [];
            let datapoints2 = [];

            // eslint-disable-next-line no-unused-vars
            for (const dt of labelsTrend) {
                let rnd = Math.floor(Math.random() * 2000) + 100
                datapoints1.push(rnd)
            }

            // eslint-disable-next-line no-unused-vars
            for (const dt of labelsTrend) {
                let rnd = Math.floor(Math.random() * 1000) + 100
                datapoints2.push(rnd)
            }

            const data = {
                options: getGraphOptions(labelsTrend),
                series: [
                    {
                        name: "New User",
                        data: datapoints1
                    },
                    {
                        name: "Active User",
                        data: datapoints2
                    }
                ],
            };

            states.trendingData = data

            //#endregion

            //#region earn graph data
            let labelsEarn = []
            for (let i = 31; i > 0; i--) {
                let dt = curDt.plus({ days: -1 * i }).toJSDate().getTime()
                labelsEarn.push(dt)
            }

            let dataEarn = [], dataEarnUsr = []
            for (let i = 0; i < 31; i++) {
                let rnd = Math.floor(Math.random() * 3000) + 10
                dataEarn.push(rnd)
                dataEarnUsr.push(Math.floor(Math.random() * 1000))
            }


            states.earnData = {
                options: getGraphOptions(labelsEarn),
                series: [
                    {
                        name: 'Earn Points',
                        data: dataEarn
                    },
                    {
                        name: 'Earn Users',
                        data: dataEarnUsr
                    }
                ],
            }
            //#endregion

            //#region burn graph data
            let labelsBurn = []
            for (let i = 31; i > 0; i--) {
                let dt = curDt.plus({ days: -1 * i }).toJSDate().getTime()
                labelsBurn.push(dt)
            }

            let dataBurn = [], dataBurnUsr = []
            for (let i = 0; i < 31; i++) {
                let rnd = Math.floor(Math.random() * 5000) + 10
                dataBurn.push(rnd)
                dataBurnUsr.push(Math.floor(Math.random() * 3000))
            }

            states.burnData = {
                options: getGraphOptions(labelsBurn),
                series: [
                    {
                        name: 'Burn Points',
                        data: dataEarn
                    },
                    {
                        name: 'Burn Users',
                        data: dataEarnUsr
                    }
                ]
            }
            //#endregion

        }, 100);
    }

    onMounted(() => {
        getDashboardSummary()
    })

    provide("dashboardSummaryStates", states)

    return {
        dashboardSummaryStates: states
    }

}
