<template>
    <!-- dataSources :
    <pre>{{dataSources}}</pre> -->
    <div class="relative p-4">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-table
                size="middle"
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalTage'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text
                                    strong
                                    v-if="Item.AdditionalTage"
                                    >{{
                                        Item.AdditionalTage
                                    }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'ProductImage'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-image :width="100" :src="Item.SlipImageURL" />
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductCode'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button>
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul
                                            v-for="items in Util.jsonParse(
                                                Item.ProductCode
                                            )"
                                            :key="items"
                                        >
                                            <li>{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5" copyable>{{
                                        Item.ProductCode
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <!-- <pre>{{record}}</pre> -->
                        <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <!-- {{
                                        record.find(
                                        item =>
                                        item.ContactStartDefault ==
                                        "true"
                                        )?.ContactName
                                        }} -->
                                        <a-image
                                            :width="200"
                                            :src="
                                                record.find(
                                                    item =>
                                                        item.ContactStartDefault ==
                                                        'true'
                                                ).ContactImageUrl
                                            "
                                        />
                                    </template>
                                    <!-- <pre>{{record}}</pre> -->
                                    <div
                                        v-for="(Item, idx) of record"
                                        :key="`item-${idx}`"
                                        @click="rowselected(record)"
                                    >
                                        {{ Item.ContactName }}
                                        <!-- <div v-show="Item.ContactStartDefault=='true'">
                                            <a-typography-link underline>
                                                {{Item.ContactName}}
                                            </a-typography-link>
                                        </div> -->
                                    </div>
                                </a-tooltip>

                                <a-tag
                                    :color="
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    'true'
                                            )
                                            ?.ContactType?.toUpperCase() ==
                                        'FACEBOOK'
                                            ? 'geekblue'
                                            : 'green'
                                    "
                                >
                                    {{
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    "true"
                                            )
                                            ?.ContactType?.toUpperCase()
                                    }}
                                </a-tag>
                            </a-space>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'PaiddeliveryObj'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text
                                    strong
                                    v-if="Item.PD_Price"
                                    >{{
                                        showprice(Item.PD_Price)
                                    }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalShipping'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text
                                    strong
                                    v-if="Item.PD_AdditionalShippingas"
                                    >{{
                                        showprice(Item.PD_AdditionalShippingas)
                                    }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>

                    <template v-if="column.dataIndex === 'Productdetail'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div>{{ Item.CustomerProductsType }}</div>
                            <div>{{ Item.ProductCode }}</div>
                            <div>{{ Item.CustomerProductsSize }}</div>
                            <div>{{ Item.HeartYanName }}</div>
                            <!-- <div>{{Item.ProductDetail}}</div> -->
                            <div>{{ Item.IntroductionYan }}</div>
                        </div>
                    </template>
                    <!-- <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template> -->
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img
                                class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                            />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span
                            >
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined
                                :style="{ color: 'red', fontSize: '14px' }"
                            />
                            <span :style="{ color: 'red', fontSize: '14px' }"
                                >ยังไม่ได้จัดส่ง</span
                            >
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductStatus'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <a-typography-text
                                    type="danger"
                                    strong
                                    v-if="Item.ProductStatus == 'cancel'"
                                    >{{ Item.ProductStatus
                                    }}<span
                                        :style="{
                                            color: 'red',
                                            fontSize: '14px'
                                        }"
                                    >
                                        {{ Item.ProductStatusDesc }}
                                    </span>
                                </a-typography-text>
                                <a-typography-text
                                    strong
                                    v-else-if="Item.ProductStatus"
                                    >{{ Item.ProductStatus }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div v-if="Item.ProfessorType">
                                    <a-typography-text
                                        mark
                                        strong
                                        v-if="
                                            JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                        >*รอคุยกับอาจารย์</a-typography-text
                                    >

                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-if="
                                            !JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                        />
                                        ข้อมูลไม่ครบ</a-typography-text
                                    >
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
            <!-- dataSources: {{dataSources}}
            !rowselecteddetail.ishowdetail:{{!rowselecteddetail.ishowdetail}} -->

            <CreateDeliveryTranfer
                v-if="false && isshowCreateDeliveryTranfer"
            ></CreateDeliveryTranfer>
        </div>
        <FromCreateSell
            v-if="rowselecteddetail.ishowdetail"
            :rowselected="rowselecteddetail"
        ></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";

import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import { formStateStore } from "@/store/storebackoffice";
import CreateDeliveryTranfer from "../component/CreateDeliveryTranfer.vue";
import Util from "@/helper/Utility.js";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "5%"
    },
    {
        title: "รหัสสินค้า",
        dataIndex: "ProductCode",
        width: "20%"
    },
    {
        title: "รายการสินค้า",
        dataIndex: "Productdetail",
        width: "20%"
    },
    {
        title: "ค่าส่ง",
        dataIndex: "PaiddeliveryObj",
        width: "10%"
    },
    {
        title: "ค่าส่งเพิ่ม",
        dataIndex: "AdditionalShipping",
        width: "10%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        CloseOutlined,
        FromCreateSell,
        CreateDeliveryTranfer
    },
    setup() {
        const isshowCreateDeliveryTranfer = ref(false);
        // const formState = reactive(formStateStore)
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "หมายเลขการขาย(5.1.3)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            console.log(
                "formStateStore.rowselecteddetail.rowselected : ",
                formStateStore.rowselecteddetail
            );
            var sp = formStateStore.rowselecteddetail;
            return BzbsUser.getcreatedelivery(sp)
                .then(res => {
                    res.data.forEach((element, index) => {
                        res.data[index].key = index;
                    });

                    console.log("getcreatedelivery :", res.data[0]);

                 
                    var find_cancel = res.data.find(
                        item => item.ProductStatus == "cancel"
                    );

                    res.data = res.data.filter(
                        item => item.ProductStatus != "cancel"
                    );
                    if (find_cancel) {
                        res.data.forEach((obj, index) => {
                            obj.key = index;
                        });
                    }
 
                  

                    dataSources.value = groupBy(res.data, "CustomerOrder");
                    formStateStore.listcreatedelivery = res.data;
                    isshowCreateDeliveryTranfer.value = true;
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = arraySellOrder => {
            return arraySellOrder.group(
                ({ ProductSellOrder }) => ProductSellOrder
            );
        };
        const showprice = price => {
            if (typeof price == undefined || price == undefined) return "-";
            return price?.toLocaleString() + " บาท";
        };

        return {
            showprice,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            isshowCreateDeliveryTranfer,
            Util
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
