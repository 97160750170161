<template>
    <div>
        <AlertModal
            v-if="controller.alertModal != null"
            :controller="controller.alertModal"
        ></AlertModal>


        <ConfirmModal
            v-if="controller.confirmModal != null"
            :controller="controller.confirmModal"
        ></ConfirmModal>
        <LoadingModal
            v-if="controller.loadingModal != null"
            :controller="controller.loadingModal"
        ></LoadingModal>
        <ConfirmRedeemModal
            v-if="controller.confirmRedeemModal != null"
            :controller="controller.confirmRedeemModal"
        ></ConfirmRedeemModal>
        <SerialModal
            v-if="controller.serialModal != null"
            :controller="controller.serialModal"
        ></SerialModal>
        <DrawDeliveryModal
            v-if="controller.drawDeliveryModal != null"
            :controller="controller.drawDeliveryModal"
        ></DrawDeliveryModal>
        <ShippingAddressModal
            v-if="controller.shippingAddressModal != null"
            :controller="controller.shippingAddressModal"
        ></ShippingAddressModal>
        <AddAddressModal
            v-if="controller.addAddressModal != null"
            :controller="controller.addAddressModal"
        ></AddAddressModal>
        <EditAddressModal
            v-if="controller.editAddressModal != null"
            :controller="controller.editAddressModal"
        ></EditAddressModal>
        <ListAddressModal
            v-if="controller.listAddressModal != null"
            :controller="controller.listAddressModal"
        ></ListAddressModal>
        <AddTaxAddressModal
            v-if="controller.addTaxAddressModal != null"
            :controller="controller.addTaxAddressModal"
        ></AddTaxAddressModal>
        <EditTaxAddressModal
            v-if="controller.editTaxAddressModal != null"
            :controller="controller.editTaxAddressModal"
        ></EditTaxAddressModal>
        <ListTaxAddressModal
            v-if="controller.listTaxAddressModal != null"
            :controller="controller.listTaxAddressModal"
        ></ListTaxAddressModal>
    </div>
</template>
<script>
//generic
import AlertModal from "./Generic/AlertModal/AlertModal.vue";
import ConfirmModal from "./Generic/ConfirmModal/ConfirmModal.vue";
import LoadingModal from "./Generic/LoadingModal/LoadingModal.vue";
//redeem
import ConfirmRedeemModal from "@/module/Detail/modal/ConfirmRedeemModal/ConfirmRedeemModal.vue";
import SerialModal from "@/module/Detail/modal/SerialModal/SerialModal.vue";
import DrawDeliveryModal from "@/module/Detail/modal/DrawDeliveryModal/DrawDeliveryModal.vue";
import ShippingAddressModal from "@/module/Detail/modal/ShippingAddressModal/ShippingAddressModal.vue";
//address
import AddAddressModal from "@/module/Address/DeliveryAddress/modal/AddAddressModal/AddAddressModal.vue";
import EditAddressModal from "@/module/Address/DeliveryAddress/modal/EditAddressModal/EditAddressModal.vue";
import ListAddressModal from "@/module/Address/DeliveryAddress/modal/ListAddressModal/ListAddressModal.vue";
import AddTaxAddressModal from "@/module/Address/TaxAddress/modal/AddTaxAddressModal/AddTaxAddressModal.vue";
import EditTaxAddressModal from "@/module/Address/TaxAddress/modal/EditTaxAddressModal/EditTaxAddressModal.vue";
import ListTaxAddressModal from "@/module/Address/TaxAddress/modal/ListTaxAddressModal/ListTaxAddressModal.vue";

export default {
    name: "ModalMaster",
    components: {
        AlertModal,
        ConfirmModal,
        LoadingModal,
        ConfirmRedeemModal,
        SerialModal,
        ShippingAddressModal,
        DrawDeliveryModal,
        AddAddressModal,
        EditAddressModal,
        ListAddressModal,
        AddTaxAddressModal,
        EditTaxAddressModal,
        ListTaxAddressModal,
    },
    props: {
        controller: {
            type: Object,
            required: true
        }
    }
};
</script>
