export default {
    methods: {
        errorApi: function(err) {
            var alertMsg = '';
            switch (err.id) {
                case 401:
                    alertMsg = err.message;
                    break;
                case 412:
                    alertMsg = "Token invalid";
                    break;
                default:
                    alertMsg = "Network connection error!";
            }

            if (this.alertModal != null) {
                this.alertModal.clearModal()
                        .setMsg(alertMsg)
                        .show();
            } else if (this.modalController.alertModal != null) {
                this.modalController.alertModal.clearModal()
                        .setMsg(alertMsg)
                        .show();
            }
        },
    },
}
