<template>
    <div v-if="serialModel.showTimer" class="text-red-600 text-center">
        <div v-if="!isTimeExpired">
            {{lbl.serial.expire_in}} {{displayTime}}
        </div>
        <div v-else>
            {{lbl.serial.code_expired}}
        </div>
    </div>
</template>
<script>
import TimerHelper from '@/helper/TimerHelper.js';
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: "SerialTimer",
    mixins: [LocaleMixin],
    props: {
        serialModel: Object,
    },
    data: function () {
        return {
            timer: new TimerHelper(),
            displayTime: "",
            isTimeExpired: false,
        };
    },
    created: function () {
        this.initial();
    },
    beforeUnmount: function() {
        if (this.timer.interval) {
            this.timer.stopTimer();
        }
    },
    methods: {
        initial: function () {
            if (this.timer.interval) {
                this.timer.stopTimer();
            }

            this.timer.initialTimer(this.serialModel.expireIn);
            if (this.serialModel.showTimer) {
                this.isTimeExpired = false;
                this.timer.runTimer((display) => {
                    this.displayTime = display;
                }, () => {
                    this.isTimeExpired = true;
                    this.serialExpired();
                });
            }
        },
        serialExpired: function () {
            this.$emit("onSerialExpired");
        },
    }
}
</script>
<style lang="scss" scoped>

</style>
