import { h } from 'vue'
export default {
    name: "VCard",
    props: {
        shadow: {
            type: [String, Boolean],
            default: undefined
        }
    },
    computed: {
        shadowComputed: function () {
            if (this.shadow == undefined) {
                return 'shadow'
            } else {
                if (this.shadow instanceof Boolean) {
                    return this.shadow == true ? 'shadow' : null
                } else {
                    if (this.shadow != '' && this.shadow != null) {
                        return this.shadow
                    } else {
                        return null
                    }
                }
            }
        }
    },
    methods: {

    },
    render: function () {
        let cardClass = ["card"]
        if (this.shadowComputed != null) {
            cardClass.push(this.shadowComputed)
        }

        return h("div",
            {
                class: cardClass
            },
            this.$slots.default()
        )
    }
}
