<template>
    <div class="flex flex-col w-full h-full max-w-full overflow-x-hidden page scroll-touch">
        <TopBar class="flex-none scroll-touch" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar
                    @clickexport="modalController.exportModal = true"
                ></Toolbar>
            </template>
        </TopBar>
        <div> 
         <SubdomainSalepageTable></SubdomainSalepageTable>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
// import Footer from "@/components/Layout/Footer/Footer.vue";
// import SalepageFrom from "@/module/Salepage/SalepageCreate/component/FromCreateSell.vue";
import SubdomainSalepageTable from "../component/SubdomainSalepageTable.vue"


export default {
    components: { TopBar,SubdomainSalepageTable },
    computed: {
        bcItems: function() {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "สร้างรายการขาย"
                }
            ];
        }
    }
};
</script>

<style></style>
