<template>
    <div class="flex">
        <div>
            <div class="w-6 h-6 border-2 border-blue-500 rounded-full flex justify-center items-center cursor-pointer"
                 @click="toggleCheck()">
                <div v-if="check" class="w-4 h-4 bg-blue-500 rounded-full"></div>
            </div>
        </div>
        <div class="pl-2" v-html="text"></div>
    </div>
</template>
<script>
export default {
    name: "Checkbox",
    props: {
        check: { type: Boolean }, // v-model:check
        text: { type: String, required: true },
    },
    methods: {
        toggleCheck: function() {
            let value = !this.check;
            this.$emit('update:check', value);
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
