<template>
  {{ numberrefcode }}
  <a-button type="primary" @click="openNotification">Open the notification box</a-button>
</template>
<script>
import { notification } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    numberref: String
  },
  setup(props) {
    const numberrefcode = ref(props.numberref)

    const openNotification = () => {
      notification.open({
        message: 'หมายเลขอ้าง',
        description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
        },
        class: 'notification-custom-class',
      });
    };
    return {
      openNotification,
      numberrefcode
    };
  },
});
</script>