<template>
    <div class="relative p-4 card">
        <!-- <pre>{{dataSources}}</pre> -->
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <Search v-if="!rowselecteddetail.ishowdetail" @dataSources="dataSources = $event"></Search>
        <!-- <pre>{{dataSources?.length}}</pre> -->
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table v-if="dataSources && !rowselecteddetail.ishowdetail" :scroll="{ x: 1300, y: 1000 }" :columns="columns"
                :data-source="dataSources" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="padding: 8px">
                        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </template>

                <template v-if="false" #expandedRowRender="{ record }">


                    <Drawer :sellorder="record.SellOrder" :shipping="JSON.parse(record.Productshipping)"
                        @updatedata="updatedata"></Drawer>
                    <div class="mt-1">
                        <Previewslip :slip="JSON.parse(record.SlipUrl)"></Previewslip>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">

                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'OrderTransaction'">

                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div v-if="idx == 0" @click="rowselected(record)">
                                {{ Item.OrderTransaction }}
                            </div>
                        </div>
                    </template> -->

                    <template v-if="column.dataIndex === 'OrderTransaction'">

                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div v-if="idx == 0"
                                @click="clickcheck('/selllist?c=' + Item.CustomerOrder + '&n=' + Item.ContactName + '&ot=' + Item.OrderTransaction+ '&so=' + Item.ProductSellOrder)">
                                <!-- <router-link :to="'/selllist?c=' + Item.CustomerOrder + '&n=' + Item.ContactName"> -->
                                <u> {{ Item.OrderTransaction }}</u>
                                <!-- </router-link> -->


                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'OrderDate'">
                        <div v-for=" (Item, idx) of record" :key="`item-${idx}`">
                            <a-button v-if="idx == 0" type="txt">
                                <a-typography-text strong v-if="Item.OrderDate">{{ formatdate(Item.OrderDate)
                                }}</a-typography-text>
                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">

                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button v-if="idx == 0" type="txt" danger>
                                <a-tooltip placement="right">
                                    <a-typography-title :level="5" copyable>{{
                                        Item.CustomerOrder
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">

                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button v-if="idx == 0" type="txt">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul v-for="items in JSON.parse(
                                            Item.ProductDetail
                                        )" :key="items">
                                            <li v-if="idx">{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5" copyable>{{
                                        Item.ProductSellOrder
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'imageprofile'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">

                            <div v-if="idx == 0" style="font-size:12px">
                                {{
                                    Item.CustomerOrder
                                }}{{ Item.ContactName }}
                            </div>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template>
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span>
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined :style="{ color: 'red', fontSize: '14px' }" />
                            <span :style="{ color: 'red', fontSize: '14px' }">ยังไม่ได้จัดส่ง</span>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Status'">
                        <div>
                            <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                                <div v-if="idx == 0">

                                    <div v-if="Item.DeliveryStatus == 'packed'">
                                        <a-typography-text strong :style="{ color: 'green', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-sharp fa-solid fa-truck-fast" />

                                            <span style="font-size:12px">แพ็คสินค้าแล้ว</span>
                                        </a-typography-text>
                                    </div>
                                    <div v-else-if="Item.DeliveryStatus == 'prepare_pack'">
                                        <a-typography-text strong :style="{ color: 'orange', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-solid fa-truck-pickup" />

                                            <span style="font-size:12px">เตรียมแพ็คสินค้่า</span>
                                        </a-typography-text>

                                    </div>
                                    <div v-else-if="Item.PaymentMethod">
                                        <a-typography-text strong :style="{ color: 'green', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-sharp fa-solid fa-clock" />

                                            <span style="font-size:12px">รอเช็คเงิน</span>
                                        </a-typography-text>
                                    </div>

                                    <div v-else-if="Item.PaymentMethod == ''">
                                        <a-typography-text strong :style="{ color: 'red', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-solid fa-xmark" />

                                            <span style="font-size:12px">ยังไม่ได้ชำระเงิน</span>
                                        </a-typography-text>
                                    </div>
                                    <div v-else>
                                        <a-typography-text strong :style="{ color: 'red', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-solid fa-xmark" />
                                            <span style="font-size:14px">ข้อมูลยังไม่ครบ</span>
                                        </a-typography-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Customertel'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <!-- {{Item.ProductResponsiblePerson}} -->
                            <div v-if="Item.ProductResponsiblePerson && idx == 0" class="flex items-start">
                                <div class="flex-auto">
                                    <div class="text-xs text-gray-1-light">{{
                                        JSON.parse(Item.ProductResponsiblePerson).login?.shortedName }} | {{
        JSON.parse(Item.ProductResponsiblePerson).login?.role }}</div>

                                </div>
                            </div>
                        </div>
                    </template>


                    <template v-if="column.dataIndex === 'StatusAll'">
                        <div v-for="(Item, idx) of  record " :key="`item-${idx}`">
                            <a-tag :color="Item.Color">{{ Item.StatusAll }} </a-tag>
                        </div>
                    </template>
                    <template v-if="false && column.dataIndex === 'Checking'">

                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">

                            <div
                                v-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != null && Item.ContactName && (checkbirthdayofyan(Item))">

                                <a-typography-text type="success" strong
                                    v-if="isshowProductYan(Item.CustomerProductsType) && Item.SystemYanDate && Item.QueYanDate && (Item.Payment && Util.jsonParse(Item.Payment)[0].Price) != null && Item.ContactName && (checkbirthdayofyan(Item)) && (Util.jsonParse(Item.Payment)[0].Checking != 'checked')">

                                    <a-tag color="#108ee9"
                                        v-if="(Util.jsonParse(Item.Payment)[0].Checking != 'reject')">สินค้า {{ idx + 1
                                        }}
                                        รอบัญชีตรวจสอบการเงิน
                                    </a-tag>
                                </a-typography-text>

                                <a-typography-text type="success" strong
                                    v-if="isshowProductYan(Item.CustomerProductsType) && Item.SystemYanDate != '' && Item.QueYanDate == '' && Item.SystemCheckTabActive_1 == null">


                                    <a-tag color="#108ee9">สินค้า {{ idx + 1 }} รอบัญชีตรวจสอบลงระบบ</a-tag>
                                </a-typography-text>

                                <a-typography-text type="success" strong
                                    v-if="!isshowProductYan(Item.CustomerProductsType) && (Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != null && Util.jsonParse(Item.Payment)[0]?.Checking == 'checked' && Item.ContactName && (checkbirthdayofyan(Item))">
                                    <!-- เคสไม่ใช่ ยัน -->
                                    <a-tag color="#87d068">สินค้า {{ idx + 1 }} การเงินแล้วผ่าน </a-tag>

                                </a-typography-text>

                                <a-typography-text type="success" strong
                                    v-if="(Util.jsonParse(Item.Payment)[0].Checking == 'checked') && ischecked(Item.SystemCheckTabActive_2)">

                                    <a-tag color="#87d068">สินค้า {{ idx + 1 }} การเงินแล้วผ่าน </a-tag>

                                </a-typography-text>
                                <a-typography-text type="danger" strong
                                    v-else-if="(Util.jsonParse(Item.Payment)[0].Checking == 'reject')">
                                    <!-- <span style="font-size:12px">- ตรวจแล้วไม่ผ่าน</span> -->
                                    <a-tag color="#f50">สินค้า {{ idx + 1 }} ตรวจแล้วไม่ผ่าน</a-tag>

                                </a-typography-text>
                                <div
                                    v-else-if="isshowProductYan(Item.CustomerProductsType) && Item.SystemYanDate == '' && Item.QueYanDate == ''">
                                    <a-tag color="#f50">สินค้า {{ idx + 1 }} ข้อมูลยังไม่ครบ</a-tag>
                                </div>
                                <div
                                    v-else-if="!isshowProductYan(Item.CustomerProductsType) && (Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) == null">

                                    <a-tag color="#f50">สินค้า {{ idx + 1 }} ข้อมูลยังไม่ครบ</a-tag>
                                </div>
                                <div
                                    v-else-if="!isshowProductYan(Item.CustomerProductsType) && (Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != null && (Util.jsonParse(Item.Payment)[0].Checking == null || Util.jsonParse(Item.Payment)[0].Checking == 'reject')">

                                    <a-tag color="#108ee9">สินค้า {{ idx + 1 }} รอบัญชีตรวจสอบการเงิน</a-tag>
                                </div>
                                <a-typography-text type="text" strong v-else>
                                    <div v-if="ischecked(Item.SystemCheckTabActive_1)">
                                        <a-tag color="#87d068">สินค้า {{ idx + 1 }} ลงระบบ : ผ่าน</a-tag>
                                    </div>
                                    <div v-if="isreject(Item.SystemCheckTabActive_1)">
                                        <a-tag color="#f50">สินค้า {{ idx + 1 }} ลงระบบ : ไม่ผ่าน</a-tag>
                                    </div>
                                </a-typography-text>
                            </div>
                            <div v-else>
                                <a-tag color="#f50">สินค้า {{ idx + 1 }} ข้อมูลยังไม่ครบ</a-tag>
                            </div>
                        </div>

                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>

                            <div v-for="( Item, idx ) of  record " :key="`item-${idx}`">
                                <!-- <pre>{{ Item }}</pre> -->
                                <div v-if="Util.jsonParse(Item.ProfessorType).includes('9')">
                                    <a-tag color="#2db7f5">- สินค้า {{ idx + 1 }}
                                        เก็บเงินปลายทาง</a-tag>
                                </div>
                                <div v-else>
                                    <div v-if="Item.ProfessorType">
                                        <a-tag v-if="!Item.SystemYanDate && isshowProductYan(Item.CustomerProductsType)"
                                            color="#f50">- สินค้า {{ idx + 1 }}
                                            ยันต์ยังไม่ได้ลงระบบ</a-tag>
                                        <a-tag
                                            v-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != false && Item.SystemYanDate && isshowProductYan(Item.CustomerProductsType)"
                                            color="#87d068">- สินค้า {{ idx + 1 }}{{}}
                                            ยันต์ลงระบบแล้ว</a-tag>
                                        <!-- <a-tag v-if="!Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)"
                                        color="#f50">- สินค้า {{ idx + 1 }}
                                        ยังไม่ได้ลงคิวยันต์</a-tag> -->
                                        <!-- <a-tag v-if="Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)"
                                        color="#87d068">- สินค้า {{ idx + 1 }}
                                        ยันต์ลงคิวยันต์แล้ว</a-tag> -->
                                        <a-tag
                                            v-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) == false && Item.SystemYanDate && isshowProductYan(Item.CustomerProductsType)"
                                            color="#faad14">- สินค้า {{ idx + 1 }}
                                            ยันต์ลงระบบแล้วแต่ยังไม่ได้ชำระ</a-tag>
                                        <a-tag
                                            v-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) == false && !isshowProductYan(Item.CustomerProductsType)"
                                            color="#faad14">- สินค้า {{ idx + 1 }}
                                            วัตถุมงคล ยังไม่ได้ชำระเงิน</a-tag>

                                        <a-tag
                                            v-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != false && !isshowProductYan(Item.CustomerProductsType)"
                                            color="#87d068">- สินค้า {{ idx + 1 }}
                                            วัตุมงคลชำระเงินแล้ว</a-tag> 
                                    </div>
                                </div>
                                <!-- {{Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)}} -->

                            </div>

                            <div v-if="false" v-for="(Item, idx) of record" :key="`item-${idx}`">

                                <!-- Item.QueYanDate: {{Item.QueYanDate}} -->
                                <div v-if="Item.ProfessorType">
                                    <a-typography-text mark strong v-if="JSON.parse(Item.ProfessorType)?.includes(
                                        '2'
                                    )
                                        ">*รอคุยกับอาจารย์</a-typography-text>
                                    <a-typography-text type="danger" strong v-else-if="Item.ProductStatus == 'cancel'">
                                        ยกเลิกรายการ</a-typography-text>
                                    <a-typography-text mark type="success" strong v-else-if="JSON.parse(
                                        Item.ProfessorType
                                    )?.includes('1')
                                        ">อาจารย์เลือกให้</a-typography-text>

                                    <a-typography-text type="danger" strong
                                        v-else-if="!Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)">
                                        <span style="font-size:12px">- รายการที่ {{ idx + 1 }}
                                            ยังไม่ได้ลงระบบคิวยันต์</span>
                                        <hr />
                                    </a-typography-text>

                                    <a-typography-text type="success" strong
                                        v-else-if="(Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) && Item.ContactName && Item.CustomerProductsType">
                                        <span style="font-size:12px">- รายการที่ {{ idx + 1 }} ข้อมูลครบ</span>
                                        <hr />
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong v-else>
                                        <font-awesome-icon icon="fa-solid fa-xmark" />
                                        <span style="font-size:12px">- รายการที่ {{ idx + 1 }} ข้อมูลไม่ครบ</span>
                                        <hr />
                                    </a-typography-text>
                                    <!-- <div v-if="Item.Payment">

                                        {{ Util.jsonParse(Item.Payment)[0].Price }}
                                    </div> -->

                                </div>


                            </div>
                        </div>
                    </template>
                </template>


            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
            <Noti v-if="query.p" :title="query.p" :message="'สามาถสร้างข้อมูลการขายได้'"></Noti>

        </div>
        <FromCreateSell v-if="rowselecteddetail.ishowdetail" :rowselected="rowselecteddetail"></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";
import Search from "@/components/Search/Search.vue"
import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import Drawer from "./Drawer.vue";
import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import BarcodeGenerator from "./BarcodeGenerator.vue";

import { useRoute } from 'vue-router'
import Noti from "@/components/Noti/Noti.vue";

import Util from "@/helper/Utility.js";

import _ from "lodash";


const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "10%"
    },
    {
        title: "ลำดับงาน",
        dataIndex: "OrderTransaction",
        width: "15%",
        customFilterDropdown: true,
        onFilter: (value, record) => record[0].OrderTransaction.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "ชื่อการติดต่อ",
        dataIndex: "imageprofile",
        sorter: true,
        width: "30%",
        // customFilterDropdown: true,
        // onFilter: (value, record) => record[0].ContactName.toString().toLowerCase().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: visible => {
        //     if (visible) {
        //         setTimeout(() => {
        //             searchInput.value.focus();
        //         }, 100);
        //     }
        // },
    },

    {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerOrder",
        sorter: true,
        width: "25%",
        customFilterDropdown: true,
        onFilter: (value, record) => record[0].CustomerOrder.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "รายการขาย",
        dataIndex: "ProductSellOrder",
        sorter: true,
        width: "28%",
        customFilterDropdown: true,
        onFilter: (value, record) => record[0].ProductSellOrder.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        },
    },
    {
        title: "วันที่สั่งสินค้า",
        dataIndex: "OrderDate",
        sorter: true,
        width: "20%"
    },
    // {
    //     title: "สถานะ",
    //     dataIndex: "Status",
    //     width: "15%",
    // },
    {
        title: "สถานะข้อมูล(1.3)",
        dataIndex: "StatusData",
        width: "30%"
    },
    // {
    //     title: "สถานะการตรวจสอบ",
    //     dataIndex: "Checking",
    //     width: "30%"
    // },
    {
        title: 'สถานะการตรวจสอบ',
        dataIndex: 'StatusAll',
        filters: [{
            text: 'รอบัญชีตรวจสอบเงิน',
            value: 'รอบัญชีตรวจสอบเงิน',
        }, {
            text: 'รอบัญชีตรวจสอบลงระบบ',
            value: 'รอบัญชีตรวจสอบลงระบบ',
        },
        {
            text: 'การเงินแล้วผ่าน',
            value: 'การเงินแล้วผ่าน',
        },
        {
            text: 'ตรวจแล้วไม่ผ่าน',
            value: 'ตรวจแล้วไม่ผ่าน',
        },
        {
            text: 'ข้อมูลยังไม่ครบ',
            value: 'ข้อมูลยังไม่ครบ',
        },
        {
            text: 'ลงระบบ : ผ่าน',
            value: 'ลงระบบ : ผ่าน',
        },
        {
            text: 'ลงระบบ : ไม่ผ่าน',
            value: 'ลงระบบ : ไม่ผ่าน',
        }
        ],
        onFilter: (value, record) => record.find(item => item.StatusAll.startsWith(value)),
        filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
        width: '25%',
    }
    // {
    //     title: "ผู้รับผิดชอบ",
    //     dataIndex: "Customertel",
    //     width: "13%"
    // }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};


export default defineComponent({
    components: {
        TableLoad,
        BarcodeGenerator,
        Drawer,
        CloseOutlined,
        // CheckOutlined,
        Previewslip,
        FromCreateSell,
        Noti,
        Search
    },

    setup() {

        const route = useRoute()
        const query = ref(route.query)
        const customercode = ref(route.query.customercode)
        const dataSources = ref([]);


        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        if (customercode.value) {
            rowselecteddetail.rowselected = customercode.value;
            rowselecteddetail.ishowdetail = true;
        }
        const {
            data: dataSource,
            run,
            loading,
            title = "ตรวจเชครายการที่โอนเงินแล้ว (สำหรับบัญชี)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            // querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apicustomerproduct(null)
                .then(res => {
                    // res.data[0].forEach((element, index) => {
                    //     res.data[0][index].key = index;
                    // });
                    // var temparray = []
                    // temparray = groupBy(res.data[0], "CustomerOrder");


                    // var finishfilter = []
                    // temparray.forEach((element, index) => {
                    //     var itemfilter = []
                    //     element.forEach((item, subindex) => {

                    //         itemfilter.push(item)

                    //     });
                    //     if (itemfilter.length > 0)
                    //         finishfilter.push(itemfilter)

                    // });
                    // dataSources.value = finishfilter


                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0].filter(item => item.ProductStatus != 'cancel')
                    // dataSources.value = groupBy(result, "CustomerOrder");

                    result = generateStatusTags(result)
                    dataSources.value = groupBy(result, "OrderTransaction");

                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });


                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {

            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const rowselected_scanbycustomercode = record => {
            var CustomerOrder = route.query.customerorder
            rowselecteddetail.rowselected = CustomerOrder;
            rowselecteddetail.ishowdetail = true;
        };



        const groupBySellOrder = (arraySellOrder) => {

            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        const isshowProductYan = (type) => {
            var array_name = ['ยันต์ปลด/คิวด่วน', 'ยันต์เขียนสด']
            if (array_name.includes(type)) {
                return true
            }
            else {
                return false
            }

        }

        const clickcheck = (path) => {
 
            window.location = path

        }

        const checkbirthdayofyan = (item) => {

            var countbirthday = 0
            countbirthday = jsonParse(item.ProductBirthday)?.length
            if (item.CustomerProductsType.includes("ยันต์") && countbirthday) {
                var nodata = true
                jsonParse(item.ProductBirthday)?.forEach(element => {
                    if (element.Birthday == "") {
                        nodata = false
                    }
                    else {
                        nodata = true
                    }
                });
                return nodata == false ? nodata : true
            }
            else if (!item.CustomerProductsType.includes("ยันต์")) {
                return true
            }
            else {
                return false
            }


        }
        const jsonParse = (jsonStr) => {
            if (!isEmptyStr(jsonStr)) {
                try {
                    var data = JSON.parse(jsonStr);
                    return data;
                } catch (e) {
                    return null;
                }
            }
            return null;
        }
        const isEmptyStr = (str) => {
            return str === null || str === undefined || str === "";
        }

        const ischecked = (array) => {
            if (array) {
                array = JSON.parse(array)

                return array[array.length - 1].checking == 'checked'
            }

        }
        const isreject = (array) => {
            if (array) {
                array = JSON.parse(array)

                return array[array.length - 1].checking == 'reject'
            }

        }


        function generateStatusTags(record) {
            return record.map((Item, idx) => {
 
 
                var lastPaymentPrice = 0
                var lastPaymentPriceChecking = ''
                var index_last_payment = 0

                if (Item.Payment != null) {
                    index_last_payment = Util.jsonParse(Item.Payment)?.length - 1
                    lastPaymentPrice = Util.jsonParse(Item.Payment)[Util.jsonParse(Item.Payment)?.length - 1]?.Price;
                    lastPaymentPriceChecking = Util.jsonParse(Item.Payment)[Util.jsonParse(Item.Payment)?.length - 1]?.Checking;
                }
                let statusTag = '';
                let color = '';
                if (
                    (Item.Payment && Util.jsonParse(Item.Payment)[index_last_payment]?.Price) != null &&
                    Item.ContactName &&
                    checkbirthdayofyan(Item)
                ) {
                    if (
                        isshowProductYan(Item.CustomerProductsType) &&
                        Item.SystemYanDate &&
                        (Item.Payment && Util.jsonParse(Item.Payment)[index_last_payment].Price) != null &&
                        Item.ContactName &&
                        checkbirthdayofyan(Item) &&
                        (Util.jsonParse(Item.Payment)[index_last_payment].Checking != 'checked')
                    ) {

                        if (Util.jsonParse(Item.Payment)[index_last_payment].Checking != 'reject') {
                            statusTag = `รอบัญชีตรวจสอบเงิน`;
                            color = '#108ee9'
                        }

                    }
                    else if (
                        !isshowProductYan(Item.CustomerProductsType) && 
                        (Item.Payment && Util.jsonParse(Item.Payment)[index_last_payment].Price) != null &&
                        Item.ContactName && 
                        (Util.jsonParse(Item.Payment)[index_last_payment].Checking != 'checked')
                    ) { 
                        if(Util.jsonParse(Item.ProfessorType).includes('9')){
                        //เก็บเงินปลายทาง
                        }
                        else if (Util.jsonParse(Item.Payment)[index_last_payment].Checking != 'reject') {
                            statusTag = `รอบัญชีตรวจสอบเงิน`;
                            color = '#108ee9'
                        } 
                        else if (Util.jsonParse(Item.Payment)[index_last_payment].Checking == 'reject') {
                            statusTag = `ตรวจแล้วไม่ผ่าน`;
                            color = '#f50'
                        } 
                    }
                     else if (
                        isshowProductYan(Item.CustomerProductsType) &&
                        Item.SystemYanDate != '' &&
                        Item.SystemCheckTabActive_1 == null
                    ) {
                        statusTag = `รอบัญชีตรวจสอบลงระบบ`;
                        color = '#108ee9'
                    } else if (
                        !isshowProductYan(Item.CustomerProductsType) &&
                        (Item.Payment && Util.jsonParse(Item.Payment)[index_last_payment]?.Price) != null &&
                        Util.jsonParse(Item.Payment)[index_last_payment]?.Checking == 'checked' &&
                        Item.ContactName &&
                        checkbirthdayofyan(Item)
                    ) {
                        statusTag = `การเงินแล้วผ่าน`;
                        color = '#87d068'
                    } else if (
                        (Util.jsonParse(Item.Payment)[index_last_payment].Checking == 'checked') &&
                        ischecked(Item.SystemCheckTabActive_1)
                    ) {
                        statusTag = `การเงินแล้วผ่าน`;
                        color = '#87d068'
                    } else if ((Util.jsonParse(Item.Payment)[index_last_payment].Checking == 'reject')) {
                        statusTag = `ตรวจแล้วไม่ผ่าน`;
                        color = '#f50'
                    } else if (
                        isshowProductYan(Item.CustomerProductsType) &&
                        Item.SystemYanDate == ''
                    ) {
                        statusTag = `ข้อมูลยังไม่ครบ`;
                        color = '#f50'
                    } else if (
                        !isshowProductYan(Item.CustomerProductsType) &&
                        (Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) == null
                    ) {
                        statusTag = `ข้อมูลยังไม่ครบ`;
                        color = '#f50'
                    } else if (
                        !isshowProductYan(Item.CustomerProductsType) &&
                        (Item.Payment && Util.jsonParse(Item.Payment)[0]?.Price) != null &&
                        (Util.jsonParse(Item.Payment)[index_last_payment].Checking == null || Util.jsonParse(Item.Payment)[Util.jsonParse(Item.Payment)?.length - 1].Checking == 'reject')
                    ) {
                        statusTag = `รอบัญชีตรวจสอบเงิน`;
                        color = '#108ee9'
                    } else {
                        if (ischecked(Item.SystemCheckTabActive_1)) {
                            statusTag = `ลงระบบ : ผ่าน`;
                            color = '#87d068'
                        } else if (isreject(Item.SystemCheckTabActive_1)) {
                            statusTag = `ลงระบบ : ไม่ผ่าน`;
                            color = '#f50'
                        }
                    }
                }
                else if (Item.Payment && lastPaymentPrice >= 0 && _.isEmpty(lastPaymentPriceChecking)) {
                    statusTag = `รอบัญชีตรวจสอบเงิน`;
                    color = '#108ee9'
                }
                else {
                    statusTag = `ข้อมูลยังไม่ครบ`;
                    color = '#f50'
                }

                return { ...Item, StatusAll: statusTag, Color: color }; // Adding the StatusAll field to the item
            });
        }

        return {
            ischecked,
            isreject,
            checkbirthdayofyan,
            clickcheck,
            isshowProductYan,
            handleSearch,
            handleReset,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            query,
            Util
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
