<template>
    <!-- <a-button @click="countDown">Open modal to close in 5s</a-button> -->
    <a-modal
        v-model:visible="visible"
        :title="title"
        @ok="handleOk"
        @cancel="handleCancel"
        :ok-button-props="{ disabled: true }"
        :cancel-button-props="{ disabled: true }" 
    >  
        <a-progress
            :stroke-color="{
                '0%': '#108ee9',
                '100%': '#87d068'
            }"
            :percent="parseInt(Storedata.state.loading.percent)"
        />
    </a-modal>
</template>
<script>
import { Modal } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import Storedata from "../../../src/store/Storedata";
export default defineComponent({
    props: ["visiblemodal", "isloading", "percentmodal"],
    setup(props) {
        const title = ref(props.titlemodal || "Loading");
        const percentload = ref(props.percentmodal || 0);
        const visible = ref(props.visiblemodal || false);
        if (props.isloading) {
            let secondsToGo = 5;
            const modal = Modal.success({
                title: "This is a notification message",
                content: `This modal will be destroyed after ${secondsToGo} second.`
            });
            const interval = setInterval(() => {
                secondsToGo -= 1;
                modal.update({
                    content: `This modal will be destroyed after ${secondsToGo} second.`
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(interval);
                modal.destroy();
            }, secondsToGo * 1000);
        }
        const countDown = () => {
            visible.value = true;
        };
        const handleOk = () => {
            Storedata.state.loading.ishow = false;
        };

        const handleCancel = () => {
            Storedata.state.loading.ishow = false;
        };

        return {
            visible,
            countDown,
            handleOk,
            handleCancel,
            percentload,
            title,
            Storedata
        };
    }
});
</script>
