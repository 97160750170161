<template>
    <!-- <pre>{{ dataSources }}</pre> -->

    <div class="p-3">

        <a-row>
            <a-col><span class="p-3"> วันที่ (ลงระบบ)</span>
                <a-range-picker v-model:value="daterang" />
                <a-button class="ml-4 mr-4" @click="getinfo">ค้นหา</a-button>
                <a-button class="ml-2 mr-4" @click="getreport">Export Report</a-button>
                <a-checkbox v-model:checked="checkedVIP">ยันต์ด่วนยังไม่จ่าย (VIP)</a-checkbox>
                <a-checkbox v-model:checked="checkedChangeSize">ยันต์เพิ่มขนาดยังไม่จ่าย</a-checkbox>
                <a-button type="primary" v-show="showloading" loading>Loading</a-button>
            </a-col>
            <!-- <a-col class="ml-4 mr-4"> <a-input-group compact>
                    <a-form-item style="width: calc(100% - 60%)">
                        <a-input-search v-model:value="scanpickup" style="width: 400px;" placeholder="สแกนชำระแล้ว Barcode"
                            enter-button="ยืนยัน" size="large" @search="onEnter" />
                    </a-form-item>
                </a-input-group></a-col> -->
        </a-row>



    </div>

    <!-- <pre>{{dataSources}}</pre> -->
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table size="middle" v-if="dataSources && !rowselecteddetail.ishowdetail"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :scroll="{ x: 0 }"
                :columns="columns" :data-source="dataSources" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <pre>{{ record.ProductSellOrder }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'contactname'">
                        <pre>{{ record.CustomerOrder }}{{ record.ContactName }}</pre>
                    </template>

                    <template v-if="column.dataIndex === 'Productdetail'">
                        <font-awesome-icon icon="fa-solid fa-user" class="pr-2" />
                        {{
                            record.CustomerProductsType + record.CustomerProductsSize + record.HeartYanName }}
                        <br>
                    </template>
                    <template v-if="column.dataIndex === 'SystemYanDate'">
                        {{ formatdate(record.SystemYanDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Stauts'">

                        <div v-if="record.ProductStatus">
                            <a-tag v-if="record.ProductStatus == 'canceltrack'" color="#f50">*ยกเลิกแทรค</a-tag>
                            <a-tag v-if="record.ProductStatus == 'cancel'" color="#f50">ยกเลิกสินค้า</a-tag>
                        </div>

                        <div v-if="record.SystemCheckTabActive_1">
                            <a-tag color="#87d068">อัพเดทลงระบบแล้ว</a-tag>
                        </div>
                        <div v-else-if="!record.SystemCheckTabActive_1">
                            <a-tag color="#f50">อัพเดทลงระบบแล้ว</a-tag>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Checking'">
                        <div v-if="!record.Is_System_And_Payment">
                            <a-tag color="#f50">ยังไม่ได้สแกนชำระ</a-tag>
                        </div>
                        <div v-else-if="record.Is_System_And_Payment == 'paid'">
                            <a-tag color="#87d068">ชำระเงินแล้ว</a-tag>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
            <div style="margin-bottom: 16px;padding: 10px ;">
                <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="start">
                    ยกเลิกชำระ
                </a-button>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                        {{ `เลือก ${selectedRowKeys.length} รายการ` }}
                    </template>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs, watch } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 
import Utility from "@/helper/Utility";
import _ from "lodash";
import { Modal } from "ant-design-vue";
import { filter } from "rxjs/operators";
const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad
    },

    setup() {


        const scanpickup = ref("")
        const showloading = ref(false)
        const checkedVIP = ref(false)
        const checkedChangeSize = ref(false)
        const daterang = ref([])
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "รหัสรายการสินค้า",
                dataIndex: "ProductSellOrder",
                width: "20%"
            },
            {
                title: "ชื่อสินค้า",
                dataIndex: "Productdetail",
                width: "30%"
            },
            {
                title: "รหัสลูกค้า",
                dataIndex: "contactname",
                width: "20%"
            },
            {
                title: "วันที่ลงระบบ",
                dataIndex: "SystemYanDate",
            },
            {
                title: "สถานะ",
                dataIndex: "Stauts",
            },
            {
                title: "สถานะการเงิน",
                dataIndex: "Checking",
            }


        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสินค้า",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            }
            else {
                return "-"
            }

        };

        watch(
            () => checkedVIP.value,
            () => {

                querycustomer()
            },
        );
        watch(
            () => checkedChangeSize.value,
            () => {

                querycustomer()
            },
        );

        const querycustomer = () => {
            const { startDate, endDate } = getRangeForCurrentMonth();
            console.log("daterang : ", daterang.value)
            var startdate = setformat(daterang.value[0] || startDate)
            var enddate = setformat(daterang.value[1] || endDate)
            console.log("startdate : ", startdate)
            console.log("enddate : ", enddate)
            var isshowvip = checkedVIP.value
            var isshowchangesize = checkedChangeSize.value

            return BzbsUser.getreportnotpay(startdate, enddate, isshowvip, isshowchangesize)
                .then(res => {


                    dataSources.value = res.data.filter((item) => item.Is_System_And_Payment != null)
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getRangeForCurrentMonth = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();

            // Get the first day of the current month
            const firstDayOfMonth = new Date(year, month, 1);

            // Get the last day of the current month
            const lastDayOfMonth = new Date(year, month + 1, 0);

            return {
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth,
            };
        }
        const setformat = (datevalue) => {

            const currentDate = new Date(datevalue);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because it's zero-based
            const day = String(currentDate.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        }
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: '',
            searchedColumn: '',
            selectedRowKeys: [],
            loading: false,
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };

        const getinfo = () => {

            querycustomer()
        }

        const getreport = () => {

            showloading.value = true
            querygetreport()
        }



        const querygetreport = async() => {
            var listreport = []
            var totalprice = 0
            for (const element of dataSources.value) {
                var price_original_cal_system = await BzbsUser.apigetcalpricereportchangesize(element.OrderTransaction)
                if (element.Is_System_And_Payment == 'paid') {

                    var item = {}
                    var desc = ""


                    if (element.Category_1.includes('VIP')) {
                        element.Category_2 = element.HeartYanName
                    }



                    desc = element.Is_System_And_Payment == 'paid' ? "ชำระแล้ว" : "ยังไม่ได้สแกนชำระ";
                    if (element.ProductStatus == "cancel") {
                        desc = "ยกเลิกสินค้า"
                    }
                    else if (element.ProductStatus == "canceltrack") {
                        desc = "*ยกเลิกแทรค"
                    }
                    item.name = element.CustomerProductsType + " " + element.Category_2 + " " + element.Category_3,
                        item.yancomingdate = formatdate(element.YanComingDate),
                        item.systemyandate = formatdate(element.SystemYanDate),
                        item.ordertransaction = element.OrderTransaction,
                        item.customerproductssize = element.Category_2,
                        item.productprice = element.ProductPrice,
                        item.originalprice = element.OriginalPrice,
                        item.productcode = element.ProductCode,
                        item.productsellorder = element.ProductSellOrder,
                        item.customerorder = element.CustomerOrder,
                        item.barcode = element.ProductCode,
                        item.contactname = element.ContactName || "",
                        item.statusreport = desc

                    if (price_original_cal_system?.data?.list_change_size_history_detail.length) {
                        item.originalprice = element.OriginalPrice - price_original_cal_system?.data?.result
                        item.list_change_size_history_detail = price_original_cal_system?.data?.list_change_size_history_detail?.toString()
                        item.queyandate = formatdate(element.QueYanDate)
                        item.trackyan = element.TrackYan
                        item.orderdate = formatdate(element.OrderDate)
                    }
                    totalprice = totalprice + item.originalprice
                    listreport.push(item)

                }
            };

            // let orderInfo = {
            //     products: listreport,
            // };
            var exportreport = {
                orderInfo: listreport,
                totalprice: totalprice,
                ispaid: true,
            }

            console.log(exportreport)

            if (checkedChangeSize.value == true) {
                BzbsUser.apireportgetyanchangesize(exportreport)
                    .then(response => {
                        showloading.value = false
                        let blob = new Blob([response.data], { type: "application/pdf" })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'ยันต์เพิ่มขนาด.pdf'
                        link.click()

                    })
                    .catch(error => {
                        showloading.value = false
                        console.log(error);
                    });
            }
            else {
                BzbsUser.getreportpdfnotpay(exportreport)
                    .then(response => {
                        showloading.value = false
                        let blob = new Blob([response.data], { type: "application/pdf" })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'รายงานลงระบบที่ยังไม่ได้จ่าย.pdf'
                        link.click()

                    })
                    .catch(error => {
                        showloading.value = false
                        console.log(error);
                    });
            }
        }

        const onEnter = () => {

            showConfirm("ต้องการสแกนจ่ายแล้ว")
        }

        const truncateString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            } else {
                return str.substring(0, maxLength) + "...";
            }
        }
        const showConfirm = (title) => {
            Modal.confirm({
                title: title,
                onOk() {
                    apiupdatereportstatus()
                },
                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        };

        const apiupdatereportstatus = () => {
            var param = {
                "ProductCode": scanpickup.value,
                "StatusReport": "paid"
            }



            BzbsUser.updatestatusreportissystemandpayment(param)
                .then(response => {
                    success()
                    querycustomer()
                })
                .catch(error => {
                    showloading.value = false
                    console.log(error);
                });

        }
        const success = () => {
            Modal.success({
                title: "สำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };



        const hasSelected = computed(() => state.selectedRowKeys.length > 0);
        const start = () => {
            state.loading = true;
            // ajax request after empty completing
            setTimeout(() => {
                state.loading = false;
                state.selectedRowKeys = [];
                updateSystemyandate()
            }, 1000);
        };

        const listselected = ref([]);
        const onSelectChange = selectedRowKeys => {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            state.selectedRowKeys = selectedRowKeys;
            listselected.value = selectedRowKeys;
        };

        const updateSystemyandate = async () => {

            var list = []

            console.log("listselected", listselected.value)
            listselected.value.forEach(element => {
                var found = dataSources.value.filter(item => item.key == element)
                list.push(found)
            });

            console.log("LIST : ", list)
            for await (let item of list) {

                // var CheckMessageParam = [{
                //     tab: "1",
                //     checking: 'checked',
                //     message: 'ผ่าน(oneclick)',
                //     updatedate: Date.now().toString(),
                // }]
                for await (let detail of item) {

                    // var param = {
                    //     "Tab": "1",
                    //     "ProductSellOrder": detail.ProductSellOrder,
                    //     "CustomerOrder": detail.CustomerOrder,
                    //     "ProductKey": detail.ProductKey,
                    //     "SystemCheckTabActive": JSON.stringify(CheckMessageParam)
                    // }
                    // await BzbsUser.updatesystemchecktabactive(param)
                    //     .then(res => {

                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });

                    var param = {
                        "ProductCode": detail.ProductCode,
                        "StatusReport": null
                    }

                    BzbsUser.updatestatusreportissystemandpayment(param)
                        .then(response => {
                            // success()
                            querycustomer()
                        })
                        .catch(error => {
                            showloading.value = false
                            console.log(error);
                        });

                }
            }
            await querycustomer();
            // console.log("param : ", param)

        }
        return {
            start,
            onSelectChange,
            scanpickup,
            onEnter,
            getinfo,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            daterang,
            getreport,
            showloading,
            scanpickup,
            checkedVIP,
            checkedChangeSize,
            hasSelected
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
