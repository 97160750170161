import mitt from "mitt";
import Util from "@/helper/Utility.js";
/**
 * setting
 * @method setTitle
 * @method setMsg
 * @method setBtnOk
 * @method clearModal
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onClickOk
 * event
 * @event sendCallbackOk
 */
export function DrawDeliveryModalController () {
    this.busModal = mitt();
    this.callbackClose = null;
    this.drawDeliveryModel = null;

    //#region setter
    this.setDrawDelivery = function (drawDeliveryModel) {
        this.drawDeliveryModel = drawDeliveryModel;
        return this;
    };
    this.clearModal = function () {
        this.drawDeliveryModel = null;
        this.callbackClose = null;
        return this;
    };
    //#endregion setter

    //#region method
    this.show = function() {
        var config = {
            drawDeliveryModel: this.drawDeliveryModel,
        };
        this.busModal.emit('show', config);
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event
    this.onClose = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackClose = callback;
        }
    },
    this.sendCallbackClose = function(data) {
        if (Util.isFunction(this.callbackClose)) {
            this.callbackClose(data);
        }
    };
    //#endregion event
}
