<template>
    <div class="text-center text-lg">
        Page not found
    </div>
</template>
<script>
export default {
    name: 'Notfound',
    mounted() {
        window.location.href = 'https://shopping-store-three-sable.vercel.app/';
    }
}
</script>
<style lang="scss" scoped>
</style>
