<template>
    <!-- <label>จังหวัด</label> -->
    <div v-if="set_isshowinput==false">
        <a-row>
            จังหวัด : {{province}}
        </a-row>
        <a-row>
            อำเภอ : {{secondCity}}
        </a-row>
        <a-row>
            ตำบล : {{districts}}
        </a-row>
        <a-row>
            รหัสไปรษณีย์ : {{postcode}}
        </a-row>
    </div>
    <div v-if="set_isshowinput==null">

        <label>จังหวัด</label>
        <a-row v-if="provinceData.length >0" class="pt-3 pb-3">
            <a-select show-search :filter-option="filterOption" placeholder="เลือกจังหวัด" v-model:value="province"
                style="width: 200px" :options="provinceData.map(pro => ({ value: pro.PROVINCE_NAME }))"
                @change="onchangeselectProvince()">
            </a-select>
        </a-row>
        <label v-if="!(Object.keys(cityData).length === 0)">อำเภอ</label>
        <a-row class="pb-3" v-if="!(Object.keys(cityData).length === 0)">
            <a-select show-search  mode="tags" :filter-option="filterOption" placeholder="เลือกอำเภอ" v-model:value="secondCity"
                style="width: 200px" :options="cityData.map((city, index) => ({ value: city }))"
                @change="onchangeselectsecondCity(secondCity)">
            </a-select>
        </a-row>
        <div v-if="districtsData != null">
            <label v-if="!(Object.keys(districtsData).length === 0)">ตำบล</label>
            <a-row class="pb-3" v-if="!(Object.keys(districtsData).length === 0)">
                <a-select show-search mode="tags" :filter-option="filterOption" placeholder="เลือกตำบล" v-model:value="districts"
                    style="width: 200px" :options="districtsData.map(city => ({ value: city }))"
                    @change="onchangeselectdistricts(districts)">
                </a-select>
            </a-row>
        </div>
        <div v-if="postalsData != null">
            <label v-if="!(Object.keys(postalsData).length === 0)">รหัสไปรษณีย์</label>
            <a-row class="pb-3" v-if="!(Object.keys(postalsData).length === 0)">
                <a-select show-search  mode="tags" :filter-option="filterOption" placeholder="เลือกรหัสไปรษณีย์"
                    v-model:value="postcode" style="width: 200px" :options="postalsData.map(city => ({ value: city }))"
                    @change="onchangeselectpostcode(postcode)">
                </a-select> 
            </a-row>
        </div> 
    </div>
    <div class="wrapper-load">
        <div>
            <row v-if="provinceData.length == 0">
                <div class="title"></div>
                <div class="select"></div>
            </row>
            <row v-if="province && (Object.keys(cityData).length === 0)">
                <div class="title"></div>
                <div class="select"></div>
            </row>
            <row v-if="province && districts == null">
                <div class="title"></div>
                <div class="select"></div>
            </row>
            <row v-if="province && postalsData == null">
                <div class="title"></div>
                <div class="select"></div>
            </row>
        </div>
    </div>

</template>
<script>
const provinceData = [];
const cityData = {};
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import { PoweroffOutlined } from "@ant-design/icons-vue";
import {
    defineComponent,
    reactive,
    toRefs,
    computed,
    onMounted,
    watch,
    ref
} from "vue";
// import { message } from "ant-design-vue";
export default defineComponent({
    components: {
        // PoweroffOutlined
    },
    props: {
        setprovince: null,
        setdistricts: null,
        setsubdistricts: null,
        setpostcode: null,
        isshowinput: null,
    },
    emits: ["province", "districts", "subdistricts", "postcode"],
    setup(props, ctx) {

        const set_isshowinput = ref(props.isshowinput)
        const setprovince_props = ref(props.setprovince)
        const setdistricts_props = ref(props.setdistricts)
        const setsubdistricts_props = ref(props.setsubdistricts)
        const setpostcode_props = ref(props.setpostcode)
        const iconLoading = ref(false);

        const province = provinceData[0];
        const state = reactive({
            province,
            provinceData,
            cityData,
            bordersData: null,
            districtsData: null,
            districtsDataRes: null,
            secondCity: "",
            districts: "",
            districtsid: "",
            postalsData: null,
            postcode: "",
            prompostcode: ""
        });
        console.log("see setprovince :", props.setprovince);
        console.log("see.setdistricts :", props.setdistricts);
        console.log("see.setsubdistricts :", state.setsubdistricts);
        console.log("see.setpostcode :", state.setpostcode);

        const cities = computed(() => {
            return cityData[state.province];
        });



        const stateinfo = reactive({
            invoices: [],
            selectedInvoices: []
        });
        onMounted(async () => {
            BzbsUser.apiprovince()
                .then(res => {
                    state.provinceData = res.data;
                    console.log("province :: ", state.provinceData);

                    state.province = setprovince_props.value; 
                })
                .catch(error => {
                    console.log(error);
                });
        });

 
        watch(
            () => state.province,
            val => {
                 
                let provincecode = state.provinceData.filter(
                    item => item.PROVINCE_NAME == val
                );
                if (state.province == "") return;
                BzbsUser.apiborders(provincecode[0]?.PROVINCE_ID)
                    .then(res => {

                        state.cityData = {};
                        let borders = [];
                        state.bordersData = res.data;
                        res.data.forEach(element => {
                            borders.push(element.BORDER_NAME);
                        });


                        state.cityData = borders;
                        state.secondCity = state.cityData[0];

                        ctx.emit("province", {
                            provinceName: state.province,
                            provinceID: provincecode[0].PROVINCE_ID.toString()
                        });
                        if (
                            setdistricts_props.value != undefined &&
                            setdistricts_props.value != ""
                        ) {
                            state.secondCity = setdistricts_props.value;
                        }
                        else if (state.secondCity) {
                            console.log(state.secondCity)
                        }

                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        );

        watch(
            () => state.secondCity,
            val => {
                 
                console.log("1");
                let provincecode = state.provinceData.filter(
                    item => item.PROVINCE_NAME == state.province
                );
                let cityDatacode = state.bordersData.filter(
                    item => item.BORDER_NAME == val
                );
                var citycode = cityDatacode[0]?.BORDER_ID ? cityDatacode[0]?.BORDER_ID : state.bordersData[0].BORDER_ID

                console.log("provincecode :", provincecode)
                console.log("cityDatacode :", cityDatacode)

                BzbsUser.apidistricts(
                    provincecode[0]?.PROVINCE_ID,
                    citycode
                )
                    .then(res => {

                        state.districtsData = {};
                        let borders = [];
                        state.districtsDataRes = res.data;
                        res.data.forEach(element => {
                            borders.push(element.DISTRICT_NAME);
                        });

                        state.districtsData = borders;
                         
                        state.districts = state.districtsData[0];

                        var districts = res.data.find(element => {
                            if (element.DISTRICT_NAME == props.setsubdistricts && element.BORDER_ID == citycode) {
                                return element.DISTRICT_ID
                            }
                        }); 
                        if (!districts) {
                            districts = state.districtsDataRes.find(element => {
                                if (element.DISTRICT_NAME == state.secondCity) {
                                    return element.DISTRICT_ID
                                }
                            });
                            state.districtsid = districts?.DISTRICT_ID?.toString()
                        }
                  
                        if (
                            setsubdistricts_props.value != undefined &&
                            setsubdistricts_props.value != ""
                        ) {
                    
                            state.districts = setsubdistricts_props.value;
                            state.districtsid = districts?.DISTRICT_ID?.toString()

                        }

                        else if (state.districts) {
                            console.log(state.districts)
                        }

                  
                        ctx.emit("districts", {
                            districtsName: state.secondCity.toString(),
                            districtsID: state.districtsid
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        );

        watch(
            () => state.districts,
            val => {
                  
                let districts = state.districtsDataRes.filter(
                    item => item.DISTRICT_NAME == val
                );
                var districtscode = districts[0]?.PROVINCE_ID ? districts[0]?.PROVINCE_ID : state.districtsDataRes[0].PROVINCE_ID

                BzbsUser.apipostals(districtscode)
                    .then(res => {
                     
                        state.postalsData = {};
                        let postals = [];
                        res.data.forEach(element => {
                            postals.push(element.POSTAL_CODE);
                        });

                        state.postalsData = postals;
                        var subdistrictsID = state.districtsDataRes.find(element => {
                            if (element.DISTRICT_NAME == setsubdistricts_props.value && element.DISTRICT_NAME == state.districts) {
                                return element?.DISTRICT_ID
                            }
                        });
            
                        ctx.emit("subdistricts", {
                            subdistrictsName: state.districts.toString(),
                            subdistrictsID: subdistrictsID?.BORDER_ID?.toString()
                        });
                        if (
                            setpostcode_props.value != undefined &&
                            setpostcode_props.value != ""
                        ) {
                            state.postcode = setpostcode_props.value;
                        }
                        else if (state.postalsData) {
                            console.log(state.postalsData)
                            state.postcode = state.postalsData[0]
                        }
                        else {
                            state.postcode = state.postalsData[0];
                        }
                        // state.postcode = props.setpostcode;
                        // state.postcode = state.postalsData[0];
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        );

        watch(
            () => state.prompostcode,
            val => {
                 
                console.log("prompostcode :: ", val);

                ctx.emit("prompostcode", val);
            }
        );
        watch(
            () => state.postcode,
            val => {

                ctx.emit("postcode", val.toString());
            }
        );
        const onchangeselectProvince = () => {
            setdistricts_props.value = '';
            setsubdistricts_props.value = '';
            setdistricts_props.value = '';
        
        }
        const onchangeselectsecondCity = () => {
            setdistricts_props.value = '';
            setsubdistricts_props.value = '';
            setdistricts_props.value = '';
            if(state.secondCity.length>1){
                state.secondCity =state.secondCity.pop(); 
            }
        }
        const onchangeselectdistricts = () => {
            setsubdistricts_props.value = '';
            if(state.districts.length>1){
                state.districts =state.districts.pop(); 
            }


        }
        const onchangeselectpostcode = () => {
            setdistricts_props.value = '';
            if(state.postcode.length>1){
                state.postcode =state.postcode.pop();

            }
        }
        const filterOption = (input, option) => {
             
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        return {
            filterOption,
            onchangeselectProvince,
            onchangeselectsecondCity,
            onchangeselectdistricts,
            onchangeselectpostcode,
            ...toRefs(state),
            cities,
            stateinfo,
            iconLoading,
            set_isshowinput
        };
    }
});
</script>
<style lang="scss">
.wrapper-load {


    .title {
        width: 100px;
        height: 24px;
        background: #eeee;
        margin-bottom: 8px;
        animation: bg-sk 0.5s infinite;
        border-radius: 5px;

    }

    .select {
        width: 150px;
        height: 24px;
        background: #eeee;
        margin-bottom: 10px;
        animation: bg-sk 0.5s infinite;
        border-radius: 5px;
    }

    @keyframes bg-sk {
        0% {
            background: #eee;
        }

        50% {
            background: #eaeaea;
        }

        100% {
            background: #eee;
        }
    }
}
</style>
