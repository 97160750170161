<template>
    <div class="breadcrumbs">
        <template v-if="breadcrumbItems.length == 0">
            <!-- default slot if breadcrumbs is empty -->
            <slot></slot>
        </template>
        <template v-else>
            <template
                v-for="(bcItem, idx) of breadcrumbItems"
                :key="`bc-item-${idx}`"
            >
                <div class="inline-block">
                    <span class="text-sm text-black-1">
                        {{ bcItem.text }}
                    </span>
                </div>
                <div
                    class="inline-block px-2 text-black-1"
                    v-if="idx < breadcrumbItems.length - 1"
                >
                    <slot name="divider">
                        <span class="text-base">/</span>
                    </slot>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "VBreadcrumb",
    inject: ["AccountStore", "TopBarStore"],
    props: {
        breadcrumb: {
            type: Array,
            default: null,
        },
        propsMode: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        breadcrumbItems: function () {
            if (this.propsMode) {
                let items = this.breadcrumb || [];
                return items;
            } else {
                let items = this.TopBarStore.state.breadcrumb || [];
                return items;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.breadcrumbs{
    white-space: nowrap;
}
</style>