<template>
    <div  v-if="refresh">
        <InputText
                v-model:inputValue="formModel.firstname"
                :errorInfo="errorInfo[0]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.firstname"
        ></InputText>
        <InputText
                v-model:inputValue="formModel.lastname"
                :errorInfo="errorInfo[1]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.lastname"
        ></InputText>
        <InputContactNumber
                v-model:inputValue="formModel.contactNumber"
                :errorInfo="errorInfo[2]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.contactnumber"
                :minLength="10"
                :maxLength="12"
                delimeter="-"
        ></InputContactNumber>
        <InputText
                v-model:inputValue="formModel.address"
                :errorInfo="errorInfo[3]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.address"
        ></InputText>
        <FormAddress
                v-model:inputValue="addressModel"
                :errorInfo="errorInfo[4]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping"
        ></FormAddress>
    </div>
</template>
<script>
import { FormHelper } from "@/helper/FormHelper.js";
//component
import InputText from "@/components/Input/label/InputText.vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import FormAddress from "@/components/Form/FormAddress.vue";
//mixin
import LocaleMixin from "@/mixin/LocaleMixin.js";
//service
import { AddressModel } from "@/core/Address/model/AddressModel.js";
import { FormAddressModel } from "../model/FormAddressModel.js";

export default {
    name: "FormShippingAddress",
    mixins: [LocaleMixin],
    components: {
        InputText,
        InputContactNumber,
        FormAddress,
    },
    data: function() {
        return {
            isFirstSave: true,
            errorInfo: [],
            refresh: true,

            formModel: null,
            addressModel: null,
        };
    },
    created: function() {
        this.initForm(null);
        this.errorInfo = FormHelper.genErrorInfo(5);
    },
    methods: {
        initForm: function() {
            this.isFirstSave = true;

            this.addressModel = new AddressModel();
            this.formModel = new FormAddressModel();

            this.refresh = false;
            this.$nextTick(() => {
                this.refresh = true;
            });
        },

        clearForm: function() {
            this.initForm(null);
        },
        submitForm: function() {
            this.isFirstSave = false;
            if (this.validate()) {
                this.formModel.setValueFromAddressModel(this.addressModel);
                // this.addressModel.setValueFromAddressModel(this.formAddressModel);
                return this.formModel;
            }
            return null
        },
        validate: function() {
            return FormHelper.validate(this.errorInfo);
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
