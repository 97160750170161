// lbl.profile
export const profile = {
    nickname: {
        name: "ชื่อเล่น",
        placeholder: "ชื่อเล่น",
        required: "กรุณากรอกชื่อเล่น",
        too_short: "",
        invalid: ""
    },
    firstname: {
        name: "ชื่อ",
        placeholder: "ชื่อ",
        required: "กรุณากรอกชื่อ",
        too_short: "",
        invalid: ""
    },
    firstnameoffice: {
        name: "ชื่อบริษัท",
        placeholder: "ชื่อบริษัท",
        required: "กรุณากรอกชื่อบริษัท",
        too_short: "",
        invalid: ""
    },
    officeregistration: {
        name: "ทะเบียนนิติบุคล",
        placeholder: "ทะเบียนนิติบุคล",
        required: "กรุณากรอกทะเบียนนิติบุคล",
        too_short: "",
        invalid: ""
    },
    lastname: {
        name: "นามสกุล",
        placeholder: "นามสกุล",
        required: "กรุณากรอกนามสกุล",
        too_short: "",
        invalid: ""
    },
    contactNumber: {
        name: "เบอร์โทรศัพท์",
        placeholder: "เบอร์โทรศัพท์",
        required: "กรุณากรอกเบอร์โทรศัพท์",
        too_short: "เบอร์โทรศัพท์ต้องมี {0} หลัก",
        invalid: "เบอร์โทรศัพท์ไม่ถูกต้อง"
    },
    email: {
        name: "อีเมล",
        placeholder: "อีเมล",
        required: "กรุณากรอกอีเมล",
        too_short: "",
        invalid: "อีเมลไม่ถูกต้อง"
    },
    idCard: {
        name: "เลขบัตรประชาชน",
        placeholder: "เลขบัตรประชาชน",
        required: "กรุณากรอกเลขบัตรประชาชน",
        too_short: "กรุณากรอกเลขบัตรประชาชน 13 หลัก",
        invalid: "เลขบัตรประชาชนไม่ถูกต้อง"
    },
    password: {
        name: "รหัสผ่าน",
        placeholder: "รหัสผ่าน",
        required: "กรุณากรอกรหัสผ่าน",
        too_short: "",
        invalid: ""
    },
    gender: {
        name: "เพศ",
        placeholder: "กรุณาเลือก",
        required: "กรุณาเลือกเพศ",
        too_short: "",
        invalid: ""
    },
    birthdate: {
        name: "วันเกิด",
        placeholder: "",
        required: "กรุณาเลือกวันเกิด",
        too_short: "",
        invalid: "กรุณาเลือกใันที่ให้ถูกต้อง",
        date: {
            name: "",
            defaultText: "วัน",
            required: " "
        },
        month: {
            name: "",
            defaultText: "เดือน",
            required: " "
        },
        year: {
            name: "",
            defaultText: "ปี",
            required: " "
        }
    },
    birthdateoffice: {
        name: "วันก่อตั้ง",
        placeholder: "",
        required: "กรุณาเลือกวันก่อตั้ง",
        too_short: "",
        invalid: "กรุณาเลือกใันที่ให้ถูกต้อง",
        date: {
            name: "",
            defaultText: "วัน",
            required: " "
        },
        month: {
            name: "",
            defaultText: "เดือน",
            required: " "
        },
        year: {
            name: "",
            defaultText: "ปี",
            required: " "
        }
    },
    quedate: {
        name: "วันที่",
        placeholder: "",
        required: "กรุณาเลือกวันที่",
        too_short: "",
        invalid: "กรุณาเลือกใันที่ให้ถูกต้อง",
        date: {
            name: "",
            defaultText: "วัน",
            required: " "
        },
        month: {
            name: "",
            defaultText: "เดือน",
            required: " "
        },
        year: {
            name: "",
            defaultText: "ปี",
            required: " "
        }
    },
    zipcode: {
        name: "รหัสไปรษณีย์",
        placeholder: "รหัสไปรษณีย์",
        required: "กรุณากรอกรหัสไปรษณีย์",
        too_short: "รหัสไปรษณีย์ต้องมี {0} หลัก",
        invalid: "รหัสไปรษณีย์ไม่ถูกต้อง"
    },
    subdistrict: {
        name: "แขวง/ตำบล",
        placeholder: "กรุณาเลือกแขวง/ตำบล",
        required: "กรุณาเลือกแขวง/ตำบล",
        too_short: "",
        invalid: ""
    },
    district: {
        name: "เขต/อำเภอ",
        placeholder: "กรุณาเลือกเขต/อำเภอ",
        required: "กรุณาเลือกเขต/อำเภอ",
        too_short: "",
        invalid: ""
    },
    province: {
        name: "จังหวัด",
        placeholder: "กรุณาเลือกจังหวัด",
        required: "กรุณาเลือกจังหวัด",
        too_short: "",
        invalid: ""
    },

    male: "ชาย",
    female: "หญิง",
    notspecified: "ไม่ระบุ"
};
