<template>
    <div class="p-4 card relative">
        <template v-if="title != null">
            <div class="text-gray-500 text-lg font-bold">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <div id="chart">
                <apexchart
                    type="pie"
                    width="445"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        // seriesData: {
        //     type: Object,
        //     default: null
        // },
        // height: {
        //     type: [String, Number],
        //     default: 400
        // }
    },
    data: function() {
        return {
            series: [44, 55, 13, 43, 22],
            chartOptions: {
                chart: {
                    width: 380,
                    type: "pie"
                },
                labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: "bottom"
                            }
                        }
                    }
                ]
            }
        };
    },
    methods: {
        updateChart() {
            const max = 90;
            const min = 20;
            const newData = this.series[0].data.map(() => {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            });

            const colors = [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0"
            ];

            // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
            this.chartOptions = {
                colors: [colors[Math.floor(Math.random() * colors.length)]]
            };
            // In the same way, update the series option
            this.series = [
                {
                    data: newData
                }
            ];
        }
    }
};
</script>

<style></style>
