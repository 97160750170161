<template>
  <div class="font-main-regular">
    <div v-if="Storedata.state.loading.ishow">
      <LoadingVue :visiblemodal="Storedata.state.loading.ishow" :title="'กำลังอัพเดทข้อมูลโปรดรอ'"
        :percentmodal="Storedata.state.loading.percent">
      </LoadingVue>
    </div>
    <div>
      <template>
        <a-result status="500" title="500" sub-title="คุณยังไม่ได้เชื่อมอินเตอร์เน็ต">
          <template #extra>
            <!-- <a-button type="primary">Back Home</a-button> -->
          </template>
        </a-result>
      </template>
    </div>
    <router-view />
  </div>
</template>
<script>
import { Modal } from 'ant-design-vue';
import LoadingVue from "./components/Loading/Loading.vue";
import ConfigManager from "@/config/ConfigManager.js";
//store
import SideMenuStore from "@/store/SideMenuStore.js";
import AccountStore from "@/store/AccountStore.js";
import TopBarStore from "@/store/TopBarStore";
import Storedata from "@/store/Storedata";
import CacheHelper from "@/helper/CacheHelper.js";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";

// import { includes } from "lodash";
var Cache = new CacheHelper(ConfigManager.getCacheType());
var list_path = ["/dashboard",
  "/customermember",
  "/sellcreate",
  "/selllist",
  "/customercreate",
  "/customerlist",
  "/subdomaincreate",
  "/subdomainlist",
  "/subdomainsalepage",
  "/salepageedit",
  "/salepagecreate",
  "/sellcreate?p=create",
  "/additionalorder",
  "/packproduct",
  "/checkmoneyselllist",
  "/thailandpost_inter",
  "/getyan",
  "/getyanstock",
  "/getyanvip",
  "/getyanchangesize",
  "/getacceptsystemyandate",
  "/getreportcommision",
  "/getreportnotpay",
  "/getreportproduct",
  "/manageorder",
  "/createdeliverylist",
  "/delivery",
  "/deliveryaccountchecking",
  "/loglinepay",
  "/shoppingcart",
  "/customermission",
  "/lineuser",
  "/product",
  "/insertproduct",
  "/insertdetailproduct",
  "/editproduct",
  "/editproductdetail",
  "/additionalorderforaccounting",
  "/getexcelcontact"
]
export default {
  components: {
    LoadingVue
  },
  data() {
    const isNotconnect = false
    return {
      Storedata,
      isNotconnect
    }
  },

  created: function () { 
    

    window.addEventListener("load", (event) => {
      const statusDisplay = document.getElementById("status");
      if (navigator.onLine) {
        this.isNotconnect = false;
      }
      else {
        this.isNotconnect = true;
        info()
      }

    });

    const info = () => {
      Modal.info({
        title: 'ระบบตรวจพบไม่ได้เชื่อมต่อ Internet',
        content: "กรุณาเชื่อมต่อ Internet ก่อนใช้งาน",
        onOk() {
          console.log('ok');
        },
      });
    };
 
    AccountStore.methods.init();
    let ismainurl = window.location.href == 'http://localhost:8080/' ? true : false || window.location.href == process.env.VUE_APP_API_BASE_URL + '/' ? true : false

    if (!ismainurl) {
       
      if (Cache.getCache("auth") != undefined) {
        const param = {
          "token": Cache.getCache("auth")?.accessToken
        };
        BzbsUser.apiposts(param)
          .then(res => {
            try {

              if (!list_path.includes(window.location.pathname)) {
 
                if (decodeURI(window.location.pathname) == '/อุอากะสะ') {
 
                  return window.location = "/selllist"
                }
                //Cache.removeCache("auth")
                return
              }
              console.log(res)
            } catch (error) {
              Cache.removeCache("auth")
              // window.location = "/อุอากะสะ";

              console.log(error)
            }
          })
          .catch(error => {
            console.log(error);
          });

      } else { 

        Cache.removeCache("auth")
        // if (Cache.getCache("auth") == undefined) {
        //   return
        // }


        if (decodeURI(window.location.pathname) == "/") return
        if (decodeURI(window.location.pathname) != "/อุอากะสะ") {

          if (window.location.pathname.includes('consumer')) return

          if (!list_path.includes(window.location.pathname)) {

            //เปิดป้องกัน
            //  window.location = "/อุอากะสะ";
            //เปิดป้องกัน
          }
          else {
            Cache.removeCache("auth")
            //เปิดป้องกัน
            //  window.location = "/อุอากะสะ";
            //เปิดป้องกัน
          }
          //window.location = "/อุอากะสะ";
        }
      }
    }
    // else {
    //   window.location = "/";
    // }

    // if (Cache.getCache("logined") == undefined) {
    //   Cache.setCache("logined", false);
    //   window.location = "/";
    // }
  },
  provide: {
    SideMenuStore: SideMenuStore,
    AccountStore: AccountStore,
    TopBarStore: TopBarStore,
    Storedata: Storedata
  },
};
</script>
<style lang="scss">
@import "./style/base/base.scss";
@import "./style/main.scss";

body {
  font-family: main-regular !important;
}



@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=Thasadith:ital,wght@0,700;1,400&display=swap');

.ql-font-thasadith {
  font-family: 'Thasadith', sans-serif;
}

#custom-toolbar .ql-font span[data-label="Thasadith"]::before {
  font-family: 'Thasadith', sans-serif;
}


@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=K2D:wght@300&family=Thasadith:ital,wght@0,700;1,400&display=swap');

.ql-font-k2d {
  font-family: 'K2D', sans-serif;
}

#custom-toolbar .ql-font span[data-label="K2D"]::before {
  font-family: 'K2D', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=Charm&family=K2D:wght@300&family=Thasadith:ital,wght@0,700;1,400&display=swap');

.ql-font-charm {
  font-family: 'Charm', cursive;
}

#custom-toolbar .ql-font span[data-label="charm"]::before {
  font-family: 'Charm', cursive;
}


@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');

.ql-font-kanit {
  font-family: 'Kanit', cursive;
}

#custom-toolbar .ql-font span[data-label="Kanit"]::before {
  font-family: 'Kanit', cursive;
}


@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@1,400;1,500&display=swap');

.ql-font-prompt {
  font-family: 'prompt';
}

#custom-toolbar .ql-font span[data-label="Prompt"]::before {
  font-family: 'prompt';
}

.wrapper-detail {
  background-color: white;
  margin: 10px;
  border-radius: 3px;
}



.loading_ {
  width: 150px;
  height: 24px;
  background: #eeee;
  margin-bottom: 10px;
  animation: bg-sk 0.5s infinite;
  border-radius: 5px;
}

@keyframes bg-sk {
  0% {
    background: #eee;
  }

  50% {
    background: #eaeaea;
  }

  100% {
    background: #eee;
  }
}


.wrapper-load {


  .title {
    width: 100px;
    height: 24px;
    background: #eeee;
    margin-bottom: 8px;
    animation: bg-sk 0.5s infinite;
    border-radius: 5px;

  }

  .select {
    width: 150px;
    height: 24px;
    background: #eeee;
    margin-bottom: 10px;
    animation: bg-sk 0.5s infinite;
    border-radius: 5px;
  }

  @keyframes bg-sk {
    0% {
      background: #eee;
    }

    50% {
      background: #eaeaea;
    }

    100% {
      background: #eee;
    }
  }
}

.img-profile {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 3px solid #e6d494;
  object-fit: cover;
}

.border-bottom-product {
  border-bottom: 1px solid rgb(128 128 128 / 50%);

}

.c-gray {
  color: gray;
  font-style: italic;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 40px !important;
}

.ant-avatar-lg {
  width: 100px !important;
  height: 100px !important;
  line-height: 75px !important;
}


.squiggle {
  background: linear-gradient(to bottom, #0087ca 0%, #0087ca 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  color: inherit;
  text-decoration: none;
}

.squiggle:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-15px);}}%3C/style%3E%3Cpath fill='none' stroke='%230087ca' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-size: auto 4px;
}

// .content-scan {
//   color: black;
//   font-size: 24px;
//   line-height: normal;
//   white-space: pre-line;

//   .content-code {
//     font-size: 24px;
//     line-height: normal;
//   } 
// }

.numcode {
  font-size: 18px;
  color: black;
  line-height: normal;
}

.ant-table-thead-zoom {
  font-size: 20px !important;
}

.ant-table-cell-zoom {
  font-size: 24px !important;
}


.centerindiv {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.p-select {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .card {
    text-align: center;
  }

  .btn_select {
    font-size: 20px;
    width: 200px;
    display: block;
    text-align: center;
  }
}

.table-striped {
  background-color: #fafafa;
}
</style>


