<template>
    <div class="m-auto text-center pb-4">
        {{ recordedChunks }}
        <video :class="{ 'isstart': __is_start_record }"
            style="width: 50%;border: 2px solid black;margin: auto;margin-bottom: 20px;" id="video" autoplay></video>
        <a-button class="m-3" @click="recordvideo">เปิดกล้อง</a-button>
        <a-button class="m-3" @click="startRecording">อัด </a-button>
        <a-button class="m-3" @click="stopcam">หยุด </a-button>
    </div>
</template>
<script>
import { createVNode, defineComponent, ref, watch } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { async } from "q";
export default defineComponent({
    props: {
        shipping: {
            type: Object,
        },
        slip: {
            type: Object,
        },
        sellorder: {
            type: String,
        },
        sp: {
            type: String,
        },
        stop: {
            type: Boolean,
        },
        is_record: {
            type: Boolean,
        }
    },
    components: {},
    emits: ["updatedata"],
    setup(props, ctx) {
        const __is_record = ref(props.is_record)
        const __is_start_record = ref(false)
        const __is_stop = ref(props.stop)


        // watch(
        //     () => __is_stop.value,
        //     () => {
        //          
        //         stopcam()
        //         setTimeout(() => {
        //             stopRecording()
        //         }, 2000);

        //     },

        // );




        const visible = ref(false);
        const size = ref("default");
        const afterVisibleChange = (bool) => {
            console.log("visible", bool);
        };

        const showDrawer = (val) => {
            size.value = val;
            visible.value = true;
        };
        const updatedata = () => {
            ctx.emit("updatedata", {
                updatedata: true,
            });
        };

        const showConfirm = () => {
            Modal.confirm({
                title: "คุณต้องการอัพเดทสถานะจัดส่งสินค้าเรียบร้อย?",
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode("div", {
                    style: "color:red;",
                }),

                onOk() {
                    console.log("OK");
                },

                onCancel() {
                    console.log("Cancel");
                },

                class: "test",
            });
        };


        var mediaRecorder;
        var recordedChunks = ref([]);
        var videoElement = null
        const recordvideo = () => {
            navigator.mediaDevices.getUserMedia({ video: true, audio: false })
                .then(function (stream) {
                    videoElement = document.getElementById('video');
                    videoElement.srcObject = stream;

                    if (props.is_record) {
                        startRecording()
                    }
                })
                .catch(function (error) {
                    console.error('Error accessing camera: ', error);
                });
        }
         
        if (props.is_record && !props.is_record) {
             
            recordvideo()
        }


        const startRecording = async () => {
             
            __is_record.value = true
            __is_start_record.value = true
            var videoElement = document.getElementById('video');
            mediaRecorder = new MediaRecorder(videoElement.srcObject);

            mediaRecorder.ondataavailable = async function (event) {
                // recordedChunks.value.push(event.data);

                // recordedChunks.value = await convertBlobToBase64(event.data)
                 
                recordedChunks.value = event.data
            };

            mediaRecorder.start();
        }

        const convertBlobToBase64 = (blob) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(blob);
            });
        }
        const stopRecording = async () => {
            // mediaRecorder.stop();

            setTimeout(async () => {
                if (recordedChunks.value) {
                     
                    var uploadparam = {};
                    uploadparam = {
                        filename: props.sp, 
                        file: recordedChunks.value,
                        folder: "trackvideo"
                    };
                    await BzbsUser.apiuploadtypefile(uploadparam)
                        .then(res => {
                            console.log(res.data);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

            }, 3000);

        }


        const disableCamera = () => {
            // Disable the video tracks in the stream
            // if (videoElement) {
            //     const videoTracks = videoElement.getVideoTracks();
            //     videoTracks.forEach(track => {
            //         track.enabled = false;
            //     });
            // }

            if (videoElement) {
                const videoTrack = videoElement.getVideoTracks()[0];
                if (videoTrack) {
                    videoTrack.enabled = false;
                }
            }
        }

        // Function to enable the camera
        const enableCamera = () => {
            // Enable the video tracks in the stream
            if (videoElement) {
                const videoTracks = videoElement.getVideoTracks();
                videoTracks.forEach(track => {
                    track.enabled = true;
                });
            }
        }

        const stopcam = () => {
            mediaRecorder.stop();
            videoElement.srcObject.getTracks().forEach(track => track.stop());
            __is_record.value = false
            __is_start_record.value = false
        }

        if (__is_stop.value) {
             
            stopcam()
            // setTimeout(() => {
            //     stopRecording()
            // }, 2000);
        }

        // if (__is_record.value) {
        //     startRecording()
        // }
        return {
            __is_record,
            stopcam,
            recordedChunks,
            recordvideo,
            startRecording,
            stopRecording,
            visible,
            afterVisibleChange,
            showDrawer,
            size,
            showConfirm,
            updatedata,
            __is_start_record,
        };
    },
});
</script>

<style lang="scss">
.isstart {
    border: 2px solid red !important;
}
</style>
  