<template>
    <a-form
        ref="formRef"
        name="dynamic_form_nest_item"
        :model="dynamicValidateForm"
        @finish="onFinish"
    >
        <a-form-item
            name="area"
            label="เลือกประเภทสินค้า"
            :rules="[{ required: true, message: 'Missing area' }]"
        >
            <a-select
                v-model:value="dynamicValidateForm.area"
                :options="producttype"
                style="width: 150px"
            />
        </a-form-item>
        <!-- {{dynamicValidateForm}} -->
        <a-space
            v-for="(sight, index) in dynamicValidateForm.sights"
            :key="sight.id"
            style="display: flex; margin-bottom: 8px"
            align="baseline"
        >
            <a-form-item
                :name="['sights', index, 'value']"
                label="ขนาด/สินค้า"
                :rules="{
                    required: true,
                    message: 'Missing sight'
                }"
            >
                <a-select
                    v-model:value="sight.value"
                    :disabled="!dynamicValidateForm.area"
                    @change="onsizechange(index)"
                    :options="
                        (sights[dynamicValidateForm.area] || []).map(a => ({
                            value: a
                        }))
                    "
                    style="width: 100px"
                ></a-select>
            </a-form-item>
            <a-form-item
                v-if="dynamicValidateForm.area == 'ยันต์'"
                label="วันเกิดสมาชิก"
                :name="['sights', index, 'price']"
            >
                <span
                    class="p-4 pb-4"
                    v-for="(item, indexDateBird) in 10"
                    :key="item"
                >
                    <DateBird
                        :idxsizechange="index"
                        :idx="indexDateBird"
                        :key="updateDateBird"
                        :datelist="datelist"
                        @date-updated="onDateBird"
                    ></DateBird>
                </span>
                <!-- <DateBird></DateBird> -->
                <!-- {{dynamicValidateForm}} -->
                <!-- <DateBird></DateBird> -->
                <!-- <DateBird></DateBird>
                <DateBird></DateBird>
                <DateBird></DateBird>
                <DateBird></DateBird>
                <DateBird></DateBird> -->
                <!-- <a-input v-model:value="sight.price" style="width: 100px" /> -->
            </a-form-item>
            <MinusCircleOutlined @click="removeSight(sight)" />
        </a-space>
        <a-form-item>
            <a-button type="dashed" block @click="addSight">
                <PlusOutlined />
                เพิ่มสินค้า
            </a-button>
        </a-form-item>
        <a-form-item>
            <a-button type="dash" danger html-type="submit">ตกลง</a-button>
        </a-form-item>
    </a-form>
    <!-- producttype: {{ producttype }} -->
</template>
<script>
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, reactive, ref, watch, inject } from "vue";
import DateBird from "../form/DateBird.vue";
export default defineComponent({
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        DateBird
    },
    props: ["producttype", "indexitem"],
    setup(props) {
        const Storedata = inject("Storedata");
        const updateDateBird = ref(1);
        const areas = [
            {
                label: "ยันต์",
                value: "Beijing"
            },
            {
                label: "สินค้าทั้วไป",
                value: "Shanghai"
            }
        ];
        const datelist = ref([]);
        const sights = {
            ยันต์: ["5x5", "10x10"],
            สินค้าทั่วไป: [
                "item 1",
                "item 2",
                "item 3",
                "item 4",
                "item 5",
                "item 6",
                "item 7",
                "item 8",
                "item 9",
                "item 10",
                "item 11"
            ]
        };
        const formRef = ref();
        const dynamicValidateForm = reactive({
            sights: [],
            area: undefined
        });
        watch(
            () => dynamicValidateForm.area,
            () => {
                dynamicValidateForm.sights = [];
            }
        );
        watch(dynamicValidateForm, newX => {
            console.log("newX", newX);
            Storedata.methods.setproduct(newX);
        });
        const removeSight = item => {
            let index = dynamicValidateForm.sights.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.sights.splice(index, 1);
            }
        };

        const onsizechange = index => {
            console.log("events :: ", index);
            // dynamicValidateForm.sights[index].birddatelist = [];
            // datelist.value = dynamicValidateForm.sights[index];
        };

        const addSight = () => {
            dynamicValidateForm.sights.push({
                value: undefined,
                price: undefined,
                id: Date.now(),
                birddatelist: []
            });
        };

        const onFinish = values => {
            Storedata.methods.setallproduct(Storedata.state.createproduct);
            console.log("Received values of form:", values);
            console.log("dynamicValidateForm:", dynamicValidateForm);
            successmessage(values);
        };
        const successmessage = values => {
            message.success("บันทึกรายการ สินค้า " + JSON.stringify(values.area), 5);
        };
        const onDateBird = values => {
            console.log("props.indexitem :: ", props.indexitem);
            if (values.date) {
                dynamicValidateForm.sights[
                    values.idxsizechange
                ].birddatelist.push(values.date);
                //  updateDateBird.value++;
            } else if (values.date == "") {
                dynamicValidateForm.sights[
                    values.idxsizechange
                ].birddatelist.splice(values.idx, 1);
                //  updateDateBird.value++;
            }
        };

        return {
            formRef,
            dynamicValidateForm,
            onFinish,
            removeSight,
            addSight,
            areas,
            sights,
            onDateBird,
            onsizechange,
            datelist,
            updateDateBird
        };
    },
    // watch: {
    //     dynamicValidateForm: function(val) {
    //         console.log("change ::", val);
    //     }
    // },
    mounted: function() {
        this.dynamicValidateForm.area = this.producttype[0].label;
    }
});
</script>
