<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        
        <!-- dataSources :  <pre>{{dataSources}}</pre> -->
        <Search v-if="!rowselecteddetail.ishowdetail" @dataSources="dataSources = $event"></Search>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table v-if="dataSources && !rowselecteddetail.ishowdetail" size="middle" :scroll="{ x: 1300, y: 1000 }"
                :columns="columns" :data-source="dataSources" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <!-- <template v-if="false" #expandedRowRender="{ record }"> -->

                <!-- <Drawer :sellorder="record.SellOrder" :shipping="JSON.parse(record.Productshipping)"
                        @updatedata="updatedata"></Drawer> -->
                <!-- <div class="mt-1">
                        <Previewslip :slip="JSON.parse(record.SlipUrl)"></Previewslip>
                    </div> -->
                <!-- </template> -->


                <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="padding: 8px">
                        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'OrderDate'">
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button v-if="idx == 0" type="text">
                                <a-typography-text strong v-if="Item.OrderDate">{{ formatdate(Item.OrderDate)
                                }}</a-typography-text>
                                <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text>
                            </a-button>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'ProductSellOrder'">

                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-button type="text">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <ul v-for="items in JSON.parse(
                                            Item.ProductDetail
                                        )" :key="items">
                                            <li>{{ items }}</li>
                                        </ul>
                                    </template>
                                    <a-typography-title :level="5" copyable>{{
                                        Item.ProductSellOrder
                                    }}</a-typography-title>
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Product'">
                        <div class="pb-2 border-bottom-product" v-for="(Item, idx) of record" :key="`item-${idx}`">


                            <div>{{ Item.CustomerProductsType }}</div>
                            <div>{{ Item.CustomerProductsSize }}</div>
                            <div>{{ Item.HeartYanName }}</div>
                            <div>{{ Item.ProductCode }}</div>



                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                    </template>

                                    <div class="text-center" v-for="(Item, idx) of record" :key="`item-${idx}`"
                                        @click="rowselected(record)">
                                        <div v-if="idx == 0" class="pt-2" style="font-size=12px;    white-space: nowrap;">
                                            <u>ชื่อลูกค้า : {{ Item.ContactName }}</u>
                                        </div>
                                    </div>
                                </a-tooltip>
                            </a-space>
                        </div>
                        <div>
                            <pre v-if="record[0]" style="font-size=12px">รหัสลูกค้า : {{ record[0]?.CustomerOrder }}</pre>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'Create'">
                        <div>
                            <a-space :size="30">

                                <div class="text-center" v-for="(Item, idx) of record" :key="`item-${idx}`"
                                    @click="rowselected(record)">
                                    <div v-if="idx == 0" class="pt-2" style="font-size=12px;    white-space: nowrap;">
                                        <!-- <u>ชื่อลูกค้า : {{ Item.ContactName }}</u> -->
                                        <a-button type="primary">สร้างค่าส่ง</a-button>
                                    </div>
                                </div>
                            </a-space>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template> -->
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span>
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined :style="{ color: 'red', fontSize: '14px' }" />
                            <span :style="{ color: 'red', fontSize: '14px' }">ยังไม่ได้จัดส่ง</span>
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Status'">
                        <div>
                            <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">
                                <div v-if="idx == 0">
                                    <div v-if="Item.DeliveryStatus == 'packed'">
                                        <a-typography-text strong :style="{ color: 'green', fontSize: '14px' }">
                                            แพ็คสินค้าแล้ว
                                        </a-typography-text>
                                    </div>
                                    <div v-else-if="Item.DeliveryStatus == 'prepare_pack'">
                                        <a-typography-text strong :style="{ color: 'orange', fontSize: '14px' }">
                                            เตรียมแพ็คสินค้่า
                                        </a-typography-text>

                                    </div>
                                    <div v-else-if="Item.PaymentMethod">
                                        <a-typography-text strong :style="{ color: 'green', fontSize: '14px' }">
                                            รอเช็คเงิน
                                        </a-typography-text>
                                    </div>

                                    <div v-else-if="Item.PaymentMethod == ''">
                                        <a-typography-text strong :style="{ color: 'red', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-solid fa-xmark" />
                                            ยังไม่ได้ชำระเงิน
                                        </a-typography-text>
                                    </div>
                                    <div v-else>
                                        <a-typography-text strong :style="{ color: 'red', fontSize: '14px' }">
                                            <font-awesome-icon icon="fa-solid fa-xmark" />
                                            ข้อมูลยังไม่ครบ
                                        </a-typography-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div class="pb-2" v-for="(Item, idx) of record" :key="`item-${idx}`">

                                <div v-if="Item.ProfessorType && idx == 0">
                                    <!-- <a-typography-text mark strong v-if="isshowProductYan(Item.CustomerProductsType) && JSON.parse(Item.ProfessorType)?.includes(
                                        '2'
                                    )
                                        ">*รอคุยกับอาจารย์</a-typography-text> -->
                                    <a-typography-text type="danger" strong v-if="Item.ProductStatus == 'cancel'">
                                        ยกเลิกรายการ</a-typography-text>
                                    <!-- <a-typography-text mark type="success" strong v-else-if="isshowProductYan(Item.CustomerProductsType) &&  JSON.parse(
                                        Item.ProfessorType
                                    )?.includes('1')
                                        ">อาจารย์เลือกให้</a-typography-text> -->
                                    <a-typography-text type="danger" strong
                                        v-else-if="!Item.SystemYanDate && isshowProductYan(Item.CustomerProductsType)">
                                        ยังไม่ได้ลงระบบ</a-typography-text>
                                    <a-typography-text type="danger" strong
                                        v-else-if="!Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)">
                                        ยังไม่ได้ลงระบบคิวยันต์</a-typography-text>
                                    <a-typography-text type="success" strong
                                        v-else-if="(Item.Payment && Util.jsonParse(Item.Payment)[0].Price) && Item.ContactName && Item.CustomerProductsType">
                                        ข้อมูลครบ(บัญชีตรวจสอบแล้ว)</a-typography-text>
                                    <a-typography-text type="success" strong
                                        v-else-if="(Item.ProfessorType && Util.jsonParse(Item.ProfessorType).includes('9'))">
                                        เก็บเงินปลายทาง</a-typography-text>
                                    <a-typography-text type="danger" strong v-else>
                                        <font-awesome-icon icon="fa-solid fa-xmark" />
                                        ข้อมูลไม่ครบ
                                    </a-typography-text>
                                </div>

                            </div>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'Customertel'">
                        <div>
                            <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                                <!-- {{Item.ProductResponsiblePerson}} -->
                                <div v-if="Item.ProductResponsiblePerson && idx == 0" class="flex items-start">
                                    <div class="flex-auto">
                                        <div class="text-xs text-gray-1-light">{{
                                            JSON.parse(Item.ProductResponsiblePerson).login?.shortedName }} | {{
        JSON.parse(Item.ProductResponsiblePerson).login?.role }}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>

        </div>
        <div>
            <FromCreateSell v-if="rowselecteddetail.ishowdetail" :rowselected="rowselecteddetail"></FromCreateSell>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import Search from "@/components/Search/Search.vue"
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";
import { Modal } from 'ant-design-vue';
import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility.js";
import { SearchOutlined } from '@ant-design/icons-vue';
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import { useRoute } from "vue-router";



const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "10%"
    },
    {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerOrder",
        width: "30%"
    },

    {
        title: "รายการขาย",
        dataIndex: "ProductSellOrder", 
        width: "28%"
    },
    {
        title: "สินค้า",
        dataIndex: "Product",
        width: "25%"
    },
    {
        title: "วันที่สั่งสินค้า",
        dataIndex: "OrderDate",
        sorter: true,
        width: "20%"
    },
    // {
    //     title: "สถานะ",
    //     dataIndex: "Status",
    //     width: "20%"
    // },
    {
        title: "สถานะข้อมูล(1.1)",
        dataIndex: "StatusData",
        width: "25%"
    },
    {
        title: "สร้างค่าส่งเพิ่ม",
        dataIndex: "Create",
        width: "13%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        SearchOutlined,
        // BarcodeGenerator,
        // Drawer,
        CloseOutlined,
        // CheckOutlined,
        // Previewslip,
        FromCreateSell,
        Search
    },

    setup() {

        const route = useRoute();

        const customercode = ref(route.query.c)
        const productsellorder = ref(route.query.so)
        const additionaltage = ref(route.query.atag)

        // http://localhost:8080/delivery?c=&so=&atag=#no-back-button
        const state = reactive({
            searchText: '',
            searchedColumn: '',
        });
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการจัดส่ง(4.1.2)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            // querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apicustomerproduct(null)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });

                    var temparray = []
                    temparray = groupBy(res.data[0], "CustomerOrder");

                    //(Item.Payment && Util.jsonParse(Item.Payment)[0].Price) && Item.ContactName && Item.CustomerAddress && Item.CustomerProductsType


                    var finishfilter = []
                    temparray.forEach((element, index) => {
                        var itemfilter = []
                        element.forEach((item, subindex) => {
                             
                            if (isshowProductYan(item.CustomerProductsType) && item.SystemYanDate != '' && item.QueYanDate != '' && item.SystemCheckTabActive_1) {
                                if ((item.Payment && Util.jsonParse(item.Payment)[0].Price)
                                    && Util.jsonParse(item.Payment)[0].Checking == 'checked'
                                    && item.ContactName && item.CustomerProductsType && item.ProductStatus != 'cancel') {
                                    itemfilter.push(item)
                                }
                            }
                            else if (item.ProfessorType && JSON.parse(item.ProfessorType).includes('9')) {
                                itemfilter.push(item)
                            }
                            else {
                                if (!isshowProductYan(item.CustomerProductsType) && (item.Payment && Util.jsonParse(item.Payment)[0].Price)
                                    && Util.jsonParse(item.Payment)[0].Checking == 'checked'
                                    && item.ContactName && item.CustomerProductsType && item.ProductStatus != 'cancel') {
                                    itemfilter.push(item)
                                }
                            }
                        });
                        if (itemfilter.length > 0)
                            finishfilter.push(itemfilter)

                    });
                    dataSources.value = finishfilter
                    
                    // dataSources.value = groupBy(res.data[0], "CustomerOrder");
                    // // dataSources.value.forEach((element, index) => {
                    // //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // // });

                   
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const warning = (title, content) => {
            Modal.warning({
                title: title,
                content: content,
            });
        };
        const rowselected = record => {
            console.log("packed")
            if (record[0].DeliveryStatus == 'packed') {

                warning('แพ็คสินค้าแล้ว', '')

                return
            }
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };


        setTimeout(() => {

            if (customercode.value) {
                rowselecteddetail.rowselected = customercode.value;
                rowselecteddetail.ishowdetail = true;
            }

        }, 1000);

        const groupBySellOrder = (arraySellOrder) => {

            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }

        const isshowProductYan = (type) => {
            var array_name = ['ยันต์ปลด/คิวด่วน', 'ยันต์เขียนสด']
            if (array_name.includes(type)) {
                return true
            }
            else {
                return false
            }

        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        return {
            ...toRefs(state),
            searchInput,
            handleSearch,
            handleReset,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            Util,
            isshowProductYan
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
