<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="Video" @ok="handleOk" @cancel="handleCancel">
      <UploadVideo :sizeupimage="1" @fileList="onchangeimages($event)"></UploadVideo>
      <template #footer>
        <a-button key="back" @click="handleCancel">ยกเลิก</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">ตกลง</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import UploadVideo from "@/module/Salepage/SalepageCreate/component/UploadVideo.vue";
import { value } from '@/store/store'
export default defineComponent({
  components: { UploadVideo },

  setup(props, { emit }) {
    const loading = ref(false);
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    const formState = reactive({
      user: {
        facebookpixel: "",
        images: [],
        video: []
      }
    });
    showModal()
    const handleOk = e => {
      loading.value = true
      // visible.value = false
      //  emit('onvideo',{value:formState.user.video,type:'video'})
      console.log(e);
      // visible.value = false;
    };
    const onchangeimages = e => {
      formState.user.video = e;
      emit('onvideo', { value: formState.user.video, type: 'video' })
      console.log(formState.user.images);

    };

    const handleCancel = () => {
      value.value = ["เลือก"]
      formState.user.video = ['1']
      visible.value = false
      // previewVisible.value = false;
      // previewTitle.value = "";
      // context.emit("fileList",'cancel');
    };
    return {
      loading,
      handleCancel,
      visible,
      showModal,
      handleOk,
      onchangeimages, formState
    };
  },

});
</script>