import Account from "@/helper/AccountHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import BzbsApi from '@/core/Http/BzbsApi.js';
import { AddressListModel } from "@/core/Address/model/AddressListModel.js";
import { map } from "rxjs/operators";
/**
 * @method apiGetAddressList
 * @method apiAddAddress
 * @method apiUpdateAddress
 * @method apiDeleteAddress
 * @method apiGetTaxAddressList
 * @method apiGetCurrentAddress
 * @method apiGetTaxAddressList
 * @method apiSaveTaxAddress
 * @method apiDeleteTaxAddress
 */
export default {
    /**
     * @return {Array} Array of AddressListModel /core/Address/model/AddressListModel.js
     */
    apiGetAddressList: function () {
        const token = Account.getToken();
        const strUrl = ConfigManager.getApiUrl() + '/profile/me/addresses';
        return BzbsApi.connectBzbsGet(strUrl, token)
            .pipe(map((res) => {
                var addressList = [];
                for (var i of res.data) {
                    var addr = new AddressListModel();
                    addr.setValueFormApi(i);
                    addressList.push(addr);
                }
                return addressList;
            }));
    },

    /**
     * @param {AddressListModel} params /core/Address/model/AddressListModel.js
     */
    apiAddAddress: function (params) {
        const token = Account.getToken();
        params.app_id = ConfigManager.getAppId();

        const url = ConfigManager.getApiUrl() + '/profile/me/address';
        return BzbsApi.connectBzbsPost(url, token, params).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * @param {AddressListModel} params /core/Address/model/AddressListModel.js
     */
    apiUpdateAddress: function (params) {
        const token = Account.getToken();
        params.app_id = ConfigManager.getAppId();

        const strUrl = ConfigManager.getApiUrl() + '/profile/me/address';
        return BzbsApi.connectBzbsPost(strUrl, token, params).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * @param {string} key address RowKey
     */
    apiDeleteAddress: function (key) {
        let token = Account.getToken();
        var params = {
            app_id: ConfigManager.getAppId(),
            key: key,
        };

        var strUrl = ConfigManager.getApiUrl() + '/profile/me/address';
        return BzbsApi.connectBzbsDelete(strUrl, token, params).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * Not sure this function gonna work!
     * @param {number} lat
     * @param {number} lng
     */
    apiGetCurrentAddress: function (lat, lng) {
        const token = Account.getToken();
        var params = {
            app_id: ConfigManager.getAppId(),
            latitude: lat,
            longitude: lng
        };

        var strUrl = ConfigManager.getApiUrl() + '/cart/userinfo';
        return BzbsApi.connectBzbsGet(strUrl, token, params).pipe(map((res) => {
            return res.data;
        }));
    },

    apiGetTaxAddressList: function () {
        const token = Account.getToken();
        const params = {
            type: 'invoice',
        };

        const strUrl = ConfigManager.getApiUrl() + '/profile/me/taxes';
        return BzbsApi.connectBzbsGet(strUrl, token, params)
            .pipe(map((res) => {
                var addressList = [];
                for (var i of res.data) {
                    var addr = new AddressListModel();
                    addr.setValueFormApi(i);
                    addressList.push(addr);
                }
                return addressList;
            }));
    },

    /**
     * @param {AddressListModel} params /core/Address/model/AddressListModel.js
     */
    apiSaveTaxAddress: function (params) {
        const token = Account.getToken();
        params.app_id = ConfigManager.getAppId();

        const url = ConfigManager.getApiUrl() + '/profile/me/tax';
        return BzbsApi.connectBzbsPost(url, token, params, null, null).pipe(map(res => res.data));
    },

    /**
     * @param {strgin} strBzbsToken
     * @param {string} key address RowKey
     */
    apiDeleteTaxAddress: function (key) {
        const token = Account.getToken();
        const params = {
            app_id: ConfigManager.getAppId(),
            key: key,
        };

        const url = ConfigManager.getApiUrl() + '/profile/me/tax';
        return BzbsApi.connectBzbsDelete(url, token, params).pipe(map(res => res.data));
    },
}
