<template>
 <div>
    <div v-if="!rowselecteddetail.ishowdetail && !isshowspin" class="pb-3 pt-3">
                <a-collapse
                    v-model:activeKey="activeKey"
                    :expand-icon-position="'right'"
                >
                    <a-collapse-panel key="1" header="ค้นหา">
                        <a-form
                            :model="criteria"
                            name="basic"
                            :label-col="{ span: 8 }"
                            :wrapper-col="{ span: 16 }"
                            autocomplete="off"
                            @finish="onFinishSearch"
                            @finishFailed="onFinishFailedSearch"
                        >
                            <a-row>
                                <a-col :span="8">
                                    <a-form-item
                                        label="ลำดับงาน"
                                        name="OrderTransaction"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.OrderTransaction
                                            "
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        label="รหัสลูกค้า"
                                        name="CustomerOrder"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.CustomerOrder
                                            "
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        label="รายการขาย"
                                        name="ProductSellOrder"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.ProductSellOrder
                                            "
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-form-item
                                        label="ช่วง-ลำดับงาน"
                                        name="RangOrderTransaction"
                                    >
                                        <a-input-group compact>
                                            <a-input
                                                v-model:value="
                                                    criteria
                                                        .RangOrderTransaction[0]
                                                "
                                                type="number"
                                                style="width: 100px; text-align: center"
                                                placeholder="Minimum"
                                                :disabled="
                                                    !!criteria.OrderTransaction
                                                "
                                            />
                                            <a-input
                                                style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                                                placeholder="~"
                                                disabled
                                            />
                                            <a-input
                                            type="number"
                                                v-model:value="
                                                    criteria
                                                        .RangOrderTransaction[1]
                                                "
                                                style="width: 100px; text-align: center; border-left: 0"
                                                placeholder="Maximum"
                                                :disabled="
                                                    !!criteria.OrderTransaction
                                                "
                                            />
                                        </a-input-group>

                                        <a-slider
                                            v-model:value="
                                                criteria.RangOrderTransaction
                                            "
                                            range
                                            :disabled="
                                                !!criteria.OrderTransaction
                                            "
                                            :max="8000"
                                            :marks="marks"
                                        >
                                            <template #mark="{ label, point }">
                                                <template v-if="point === 100">
                                                    <strong>{{ label }}</strong>
                                                </template>
                                                <template v-else>{{
                                                    label
                                                }}</template>
                                            </template>
                                        </a-slider>
                                    </a-form-item> 
                                </a-col>
                            </a-row>

                            <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                                <a-space :size="10">
                                    <a-button
                                        type="primary"
                                        @click="resetCriteria"
                                        >เครียร์</a-button
                                    >
                                    <a-button type="primary" html-type="submit"
                                        >ค้นหา</a-button
                                    >
                                    <a-tag v-if="isshowspin" color="processing">
                                        <template #icon>
                                            <sync-outlined :spin="true" />
                                        </template>
                                        processing
                                    </a-tag>
                                </a-space>
                            </a-form-item>
                        </a-form>
                    </a-collapse-panel>
                </a-collapse>
            </div>
 </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"; 
import { usePagination } from "vue-request";
import {
    CloseOutlined,
    FileExclamationOutlined,
    SyncOutlined,
    SettingOutlined
} from "@ant-design/icons-vue";

import {
    computed,
    defineComponent,
    onMounted,
    watch,
    ref,
    reactive
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser"; 
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work 
 

import Util from "@/helper/Utility.js";
import _ from "lodash";
import { useRouter, useRoute } from "vue-router";
import Storedata from "@/store/Storedata.js";

const searchInput = ref();

 
const isgetDataSearch = ref(false); 
const dataSources = ref(null);
export default defineComponent({
    components: {
        TableLoad,  
        CloseOutlined,     
        FileExclamationOutlined,
        SyncOutlined,
        SettingOutlined
    }, 
    props: ['visiblemodal', 'isloading', 'percentmodal'],
    emits: ["dataSources"],
    setup(props,{emit}) { 
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const handleClickCollapse = event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        };

        const criteria = reactive({
            OrderTransaction: "",
            CustomerOrder: "",
            ProductSellOrder: "",
            RangOrderTransaction: []
        });

        const isshowspin = ref(false);
        const onFinishSearch = values => { 
            dataSources.value = [];
            isshowspin.value = true;
   
            var params = {
                OrderTransaction: values.OrderTransaction
                    ? parseInt(values.OrderTransaction)
                    : null,
                RangOrderTransaction: values.RangOrderTransaction || [],
                CustomerOrder: values.CustomerOrder || null,
                ProductSellOrder: values.ProductSellOrder || null
            };
            get_search_criteria_customerproduct(params);

            console.log("Success:", values);
        };
        const onFinishFailedSearch = errorInfo => {
            console.log("Failed:", errorInfo);
        };
        const resetCriteria = () => {
            // Assign default values to the properties
            criteria.OrderTransaction = "";
            criteria.CustomerOrder = "";
            criteria.ProductSellOrder = "";
            criteria.RangOrderTransaction = [];
            isgetDataSearch.value = false;
            querycustomer();
        };

        onMounted(() => {
            querycustomer();
        });

        const pagination = reactive({
            currentPage: 1,
            pageSize: 20,
            totalItems: 0
        });

        // Function to fetch data based on pagination
        const fetchData = () => {
            // Make API request to fetch data based on pagination.currentPage and pagination.pageSize
            // Update 'data' and 'pagination.totalItems' accordingly
        };

        // Watch for changes in pagination and fetch data accordingly
        watch(
            [() => pagination.currentPage, () => pagination.pageSize],
            fetchData,
            { immediate: true }
        );
        watch(
            () =>  criteria.CustomerOrder,
            () => {
                console.log("b:",criteria.CustomerOrder.length) 
                criteria.CustomerOrder =  criteria.CustomerOrder?.trim()
                console.log("AF:",criteria.CustomerOrder.length)
            }
        );
        watch(
            () => criteria.OrderTransaction,
            () => { 
                criteria.OrderTransaction =  criteria.OrderTransaction?.trim() 
            }
        );
        watch(
            () => criteria.ProductSellOrder,
            () => { 
                criteria.ProductSellOrder =  criteria.ProductSellOrder?.trim() 
            }
        );
       

        
        // Function to handle pagination change
        const handlePaginationChange = (page, pageSize) => {
   
            pagination.currentPage = page;
            pagination.pageSize = pageSize;

            querycustomer();
        };

        const isclickPre = () => {
            console.log("isclickPre");
        };
        const isclicknext = () => {
            console.log("isclicknext");
        };

        const activeKey = ref([]);
        const marks = ref({
            0: "เริ่มต้น",
            8000: "สิ้นสุด"
        });

        const querycustomer = () => { 
            isshowspin.value = true;
            // return BzbsUser.apicustomerproduct(null)
            var params = {
                page: pagination.currentPage || 1,
                limit: pagination.pageSize || 80
            };
            console.log("querycustomer 1")
  
            return BzbsUser.customerproductpagination(params)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0];
                    result = generateStatusTags(result);
                    dataSources.value = groupBy(result, "OrderTransaction");
                    emit("dataSources", dataSources.value);
                    isshowspin.value = false;
                })
                .catch(error => {
                    console.log(error);
                });
        }; 
        const get_search_criteria_customerproduct = params => {
            var search = {
                OrderTransaction: params.OrderTransaction,
                CustomerOrder: params.CustomerOrder,
                ProductSellOrder: params.ProductSellOrder
            };
            isgetDataSearch.value = true;
            return BzbsUser.search_criteria_customerproduct(params)
                .then(res => {
                     
                    dataSources.value = [];
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0];
                    result = generateStatusTags(result);
                    dataSources.value = groupBy(result, "OrderTransaction");
                    emit("dataSources", dataSources.value);
                    setTimeout(() => {
                        isshowspin.value = false;
                        activeKey.value = [];
                    }, 100);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        function generateStatusTags(record) {
            const result = [];

            for (let idx = 0; idx < record.length; idx++) {
                const Item = record[idx];
                let status = "";
                let color = "";
                console.log("idx : ", idx);
                var lastPaymentPrice = 0;
                var lastPaymentPriceChecking = "";
                if (Item.Payment != null) {
                    lastPaymentPrice = Util.jsonParse(Item.Payment)[
                        Util.jsonParse(Item.Payment)?.length - 1
                    ]?.Price;
                    lastPaymentPriceChecking = Util.jsonParse(Item.Payment)[
                        Util.jsonParse(Item.Payment)?.length - 1
                    ]?.Checking;
                }

                if (
                    Item.Productdelivery &&
                    Util.jsonParse(Item.Productdelivery)[0]?.DeliveryStatus ==
                        "shipped"
                ) {
                    status = "จัดส่งแล้ว";
                    color = "green";
                } else {
                    if (isshowProductYan(Item.CustomerProductsType)) {
                        if (Item.ProductStatus == "cancel") {
                            status = "ยกเลิก";
                            color = "red";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("11")
                        ) {
                            status = "เพิ่มขนาดไม่ทัน";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("12")
                        ) {
                            status = "เพิ่มขนาดโดยใช้แทร็คใหม่";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("13")
                        ) {
                            status = "ย้ายสินค้าเข้าสต๊อก";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("14")
                        ) {
                            status = "อาจารย์ออกแบบโลโก้";
                            color = "#FF6F1D";
                        } else if (
                            Item.HeartYanName ==
                            "เพิ่มขนาดยังไม่ได้ลงระบบ/ยันต์มัดจำปรึกษาอาจารย์"
                        ) {
                            status = "เพิ่มขนาดยังไม่ลงระบบ";
                            color = "#E820FF";
                        } else if (
                            Item.IncreaseSizeOrder != "" &&
                            Item.OrderTransaction != Item.IncreaseSizeOrder
                        ) {
                            status = "ผืนเก่าเพิ่มขนาด";
                            color = "red";
                        } else if (
                            Item.Payment &&
                            (lastPaymentPrice == null || lastPaymentPrice <= 0)
                        ) {
                            status = "รอลูกค้าชำระเงิน";
                            color = "#108ee9";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("10")
                        ) {
                            if (Item.ProfessorType) {
                                status = "มัดจำแล้วรอคุยกับอาจารย์";
                                color = "blue";
                            }
                        } else if (
                            Item.Payment &&
                            lastPaymentPrice >= 0 &&
                            _.isEmpty(lastPaymentPriceChecking)
                        ) {
                            status = "รอบัญชีตรวจสอบเงิน";
                            color = "#108ee9";
                        } else if (
                            isshowProductYan(Item.CustomerProductsType) &&
                            Item.SystemYanDate != "" &&
                            Item.QueYanDate == "" &&
                            Item.SystemCheckTabActive_1 == null
                        ) {
                            status = "รอบัญชีตรวจลงระบบ";
                            color = "#108ee9";
                        } else if (Item.HasAddress <= 0) {
                            status = "รอลูกค้าแจ้งที่อยู่จัดส่ง";
                            color = "#108ee9";
                        } else if (Item.RemindNotpayDelivery == "paid") {
                            status = "รอลูกค้าชำระค่าส่ง";
                            color = "#108ee9";
                        } else if (
                            !Item.SystemYanDate &&
                            isshowProductYan(Item.CustomerProductsType)
                        ) {
                            status = "รอลงระบบ";
                            color = "#108ee9";
                        } else if (
                            !Item.QueYanDate &&
                            isshowProductYan(Item.CustomerProductsType)
                        ) {
                            status = "รอลงคิวเขียน";
                            color = "#108ee9";
                        } else if (
                            !Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == ""
                        ) {
                            status = "รอยันต์มา";
                            color = "#108ee9";
                        } else if (
                            Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == "confirm"
                        ) {
                            status = "เตรียมสร้าง SP ได้";
                            color = "blue";
                        } else if (
                            Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == ""
                        ) {
                            status = "ลูกค้า(ยังไม่ยืนยันที่อยู่)";
                            color = "#108ee9";
                        } else {
                            status = "-";
                            color = "-";
                        }
                    } else {
                        if (Util.jsonParse(Item.ProfessorType).includes("9")) {
                            if (Item.ContactName) {
                                status = "เตรียมสร้าง SP ได้";
                                color = "blue";
                            }
                        } else {
                            if (
                                Item.Payment &&
                                (lastPaymentPrice == null ||
                                    lastPaymentPrice <= 0)
                            ) {
                                status = "รอลูกค้าชำระเงิน";
                                color = "#108ee9";
                            } else if (
                                Item.Payment &&
                                lastPaymentPrice > 0 &&
                                lastPaymentPrice != null &&
                                Item.ContactName &&
                                checkbirthdayofyan(Item) &&
                                lastPaymentPriceChecking != "checked"
                            ) {
                                status = "รอบัญชีตรวจสอบ";
                                color = "#108ee9";
                            } else if (
                                Item.Payment &&
                                lastPaymentPrice != null &&
                                Item.ContactName &&
                                checkbirthdayofyan(Item) &&
                                lastPaymentPriceChecking == "checked"
                            ) {
                                status = "เตรียมสร้าง SP ได้";
                                color = "blue";
                            } else {
                                status = "-";
                                color = "-";
                            }
                        }
                    }
                }

                if (status !== "") {
                    result.push({ ...Item, StatusAll: status, Color: color });
                }
            }

            return result;
        }

        const isshowProductYan = type => {
            var array_name = ["ยันต์ปลด/คิวด่วน", "ยันต์เขียนสด"];
            if (array_name.includes(type)) {
                return true;
            } else {
                return false;
            }
        };

        const checkbirthdayofyan = item => { 
            var countbirthday = 0;
            countbirthday = jsonParse(item.ProductBirthday)?.length;
            if (item.CustomerProductsType.includes("ยันต์") && countbirthday) {
                var nodata = true;
                jsonParse(item.ProductBirthday)?.forEach(element => {
                    if (element.Birthday == "" && element.YearBirthday == "") {
                        nodata = false;
                    } else {
                        nodata = true;
                    }
                });
                return nodata == false ? nodata : true;
            } else if (!item.CustomerProductsType.includes("ยันต์")) {
                return true;
            } else {
                return false;
            }
        };

        const isEmptyStr = str => {
            return str === null || str === undefined || str === "";
        };
        const jsonParse = jsonStr => {
            if (!isEmptyStr(jsonStr)) {
                try {
                    var data = JSON.parse(jsonStr);
                    return data;
                } catch (e) {
                    return null;
                }
            }
            return null;
        };
        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        return { 
            isgetDataSearch,
            marks,
            activeKey,
            isclickPre,
            isclicknext,
            pagination,
            handlePaginationChange,
            resetCriteria,
            onFinishSearch,
            onFinishFailedSearch,
            criteria,
            handleClickCollapse,
            rowselecteddetail,
            isshowspin
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}

.ant-tooltip-content {
    width: 500px;
}

.dot-red {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #000;
}

.dot-yellow {
    height: 20px;
    width: 20px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #000;
}

.wrapper-pagination {
    padding: 10px;
    display: flex;
    justify-content: right;
}

#components-slider-demo-mark h4 {
    margin: 0 0 16px;
}
#components-slider-demo-mark .ant-slider-with-marks {
    margin-bottom: 44px;
}
.ant-slider-mark-text {
    white-space: nowrap;
}
</style>
