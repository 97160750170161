<template>
    <div>
        <a-modal v-model:visible="visible" title="ตั้งชื่อ subdomain" @cancel="oncancel()" :maskClosable="false">
            <a-input v-model:value.trim="shopname" addon-before="https://"
                :addon-after="'.' + config_namahayant.salepageurl" v-on:keypress="isLetter($event)" />
            <div class="pt-3">
                <a-alert v-if="hassuccess" message="สำเร็จสามารถใช้ชื่อร้านค้านี้ได้" type="success" />
                <a-alert v-if="haserror" message="มีร้านค้าชื่อนี้ในระบบแล้ว" type="error" />
            </div>
            <template #footer>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                    ตรวจสอบ</a-button>
                <a-button v-if="hassuccess" key="submit" type="primary" @click="startcreate()"> {{ type == 'edit' ?
                        'แก้ไข' : 'เริ่มสร้าง'
                }}</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { shopname } from '@/store/store'
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { config_namahayant } from '@/store/store'
export default defineComponent({
    props: ['type', 'subdomainname', 'salepageid'],
    components: {
    },
    setup(props, { emit }) {
        const visible = ref(true);
        const haserror = ref(false);
        const hassuccess = ref(false);
        const loading = ref(false);
        const showModal = () => {
            visible.value = true;
        };

        const subdomainname = ref(props.subdomainname);
        if (props.type == 'edit') {

            console.log("1");
            shopname.value = subdomainname.value
        }

        const handleOk = () => {

            console.log(shopname.value);
            querycustomer(shopname.value);
            // visible.value = false;
        };

        const oncancel = () => {
            window.location = '/subdomainlist'
        };

        const querycustomer = (shopname) => {
            if (!shopname) {
                return
            }
            loading.value = true;
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;

            return axios.get(path + "/api/subdomain/" + shopname.toLowerCase()).then(res => {
                loading.value = false;
                if (res.data.length == 0) {
                    hassuccess.value = true;
                    emit('onshopname', shopname)
                    console.log(res.data[0]);
                }
                else {
                    haserror.value = true;
                    hassuccess.value = false;
                    //มีชื่อนี้แล้ว 
                }
                setTimeout(() => {
                    haserror.value = false;
                }, 5000);
            });
        };
        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
        watch(shopname, () => {
            hassuccess.value = false;
        })
        const startcreate = () => {

            if (props.type == "edit") {
                insertsalepage('UPDATE');
            }
            else {
                insertsalepage('CREATE');
            }

        };

        const insertsalepage = async (type) => {

            const param = {
                SubDomainName: shopname.value.toLowerCase(),
                OldSubDomainName: props.subdomainname,
                SalePageID: props.salepageid,
                TYPE: type
            };


            await BzbsUser.apicreatesubdomain(param)
                .then(res => {
                    console.log(res)
                    visible.value = false;
                    window.location = '/subdomainlist'
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            isLetter,
            visible,
            showModal,
            handleOk, shopname, querycustomer, haserror, hassuccess,
            loading,
            startcreate, oncancel, config_namahayant

        };
    }
});
</script>
