<template>
    <!-- <a-button type="primary" @click="openNotification">Open the notification box</a-button> -->
</template>
<script>
import { SmileOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { defineComponent, h } from 'vue';
export default defineComponent({
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
    },
    setup(props) {

        const openNotification = () => {
            notification.open({
                message: props.title,
                description: props.message,
                icon: () => h(SmileOutlined, {
                    style: 'color: #108ee9',
                }),
            });
        };
        openNotification()
        return {
            openNotification,
        };
    },
});
</script>