export default {
    data: function () {
        return {
            shown: false,
            hidden: false
        };
    },
    watch: {
        isOpen: function (val) {
            this.updateUI(val);
        },
    },
    mounted: function () {
        this.updateUI(this.isOpen);
    },
    methods: {
        updateUI: function (val) {
            if (val) {
                setTimeout(() => {
                    this.shown = val;
                }, 500);
            } else {
                this.shown = false;
                setTimeout(() => {
                    this.hidden = true
                }, 500);
            }
        },
    }
}
