<template>
    <div>
        <div
            v-if="list.length > 0"
            class="overflow-hidden rounded wrapper-drag-mobile scroll-touch"
            style="max-width: 450px;"
        >
            <draggable
                class="list-group"
                tag="ul"
                :list="list"
                v-bind="dragOptions"
                @start="isDragging = true"
                @end="isDragging = false"
                @change="log"
            >
                <transition-group type="transition" name="flip-list">
                    <li v-for="(element, index) in list" :key="element?.order">
                        <div
                            v-if="element?.detail"
                            class="p-1 m-1 bg-gray-200 rounded-md list-group-item"
                        >
                            <div v-if="element != null">
                                <div>
                                    <div class="order-items">
                                        <div>( {{ setnum() }})</div>
                                    </div>
                                </div>
                            </div>

                            <a-row style="justify-content: end;">
                                <div
                                    class="text-right pr-3"
                                    @click="up(element, index)"
                                >
                                    <up-outlined />
                                </div>
                                <div
                                    class="text-right pr-3"
                                    @click="down(element, index)"
                                >
                                    <down-outlined />
                                </div>

                                <div
                                    class="text-right pr-3"
                                    @click="edit(element, index)"
                                >
                                    <setting-outlined />
                                </div>
                                <div
                                    class="text-right"
                                    @click="
                                        onremove(element?.detail.type, index)
                                    "
                                >
                                    <close-outlined />
                                </div>
                            </a-row>

                            <i
                                :class="
                                    element.fixed
                                        ? 'fa fa-anchor'
                                        : 'glyphicon glyphicon-pushpin'
                                "
                                @click="element.fixed = !element.fixed"
                                aria-hidden="true"
                            ></i>

                            <div
                                v-if="element.detail.type == 'youtube'"
                                class="bg-gray-200 rounded-md list-group-item"
                            >
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://live.staticflickr.com/7132/7749081576_f256dba668_w.jpg"
                                        />
                                    </div>
                                </div>
                                <iframe
                                    v-if="element.detail?.value"
                                    width="100%"
                                    height="250px"
                                    :src="
                                        'https://www.youtube.com/embed/' +
                                            element.detail.value
                                    "
                                >
                                </iframe>
                            </div>
                            <div
                                v-if="element.detail.type == 'timer'"
                                class="bg-gray-200 rounded-md list-group-item"
                            >
                                <div class="text-center">
                                    <h1>วันหมดเวลาโปรโมชั่น countdown</h1>
                                    <div class="card p-4">
                                        <h1>หัวข้อ -เริ่ม</h1>
                                        <pre>{{
                                            element.detail.value.startmessage
                                        }}</pre>
                                        <h1>หัวข้อ -จบ</h1>
                                        <pre>{{
                                            element.detail.value.endmessage
                                        }}</pre>
                                        <FieldTimeOutlined />
                                        {{
                                            dateformat(
                                                element.detail.value?.timer
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="element.detail.type == 'track'"
                                class="bg-gray-200 rounded-md list-group-item"
                            >
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <img
                                            :width="20"
                                            src="../../../../../assets/icons/btn/track_img.png"
                                        />
                                    </div>
                                </div>
                                <div class="relative">
                                    <div>
                                        <img
                                            src="../../../../../assets/icons/btn/track.webp"
                                            alt
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="element?.detail.type == 'image'">
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl2AsDP9Vp7qnSymCWlVG2bCKXzsurHeAU4A-fi6qCKRLXH7y9__xu-EuLfyjbEw9x26I&usqp=CAU"
                                        />
                                    </div>
                                </div>
                                <img
                                    v-if="element.detail.value"
                                    :src="cdnpath(element.detail.value)"
                                    class="w-full m-auto"
                                />
                            </div>
                            <div v-if="element.detail.type == 'addbutton'">
                                <div v-if="element.detail.value.lineid?.id">
                                    <div class="relative">
                                        <div
                                            class="absolute left-0"
                                            style="top: -20px;"
                                        >
                                            <a-image
                                                :width="20"
                                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                            />
                                        </div>
                                    </div>

                                    <img
                                        src=" https://namahayan.blob.core.windows.net/assets/Line_btn.gif"
                                        class="rounded-md w-80 m-auto"
                                    />
                                </div>

                                <div
                                    v-if="
                                        element.detail.value
                                            .SalePageFacebookMessenger
                                    "
                                >
                                    <div class="relative">
                                        <div
                                            class="absolute left-0"
                                            style="top: -20px;"
                                        >
                                            <a-image
                                                :width="20"
                                                src="https://namahayan.blob.core.windows.net/assets/FB.jpeg"
                                            />
                                        </div>
                                    </div>

                                    <img
                                        src="https://namahayan.blob.core.windows.net/assets/FB_btn.gif"
                                        class="rounded-md w-80 m-auto"
                                    />
                                </div>
                                <div v-if="element.detail.value.tel">
                                    <div class="relative">
                                        <div
                                            class="absolute left-0"
                                            style="top: -20px;"
                                        >
                                            <a-image
                                                :width="20"
                                                src=" https://namahayan.blob.core.windows.net/assets/phone_PNG49006.png"
                                            />
                                        </div>
                                    </div>
                                    <img
                                        src=" https://namahayan.blob.core.windows.net/assets/tel-button.gif"
                                        class="rounded-md w-80 m-auto"
                                    />
                                </div>
                            </div>

                            <div v-if="element?.detail.type == 'slideimage'">
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://i.stack.imgur.com/OHOoU.png"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <carousel :items-to-show="1">
                                        <slide
                                            v-for="slide in element.detail
                                                .value"
                                            :key="slide"
                                        >
                                            <img
                                                :src="slide.value"
                                                class="w-80 m-auto p-1"
                                            />
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                            <pagination />
                                        </template>
                                    </carousel>
                                    <carousel :items-to-show="3.5">
                                        <slide
                                            v-for="slide in element.detail
                                                .value"
                                            :key="slide"
                                        >
                                            <img
                                                :src="slide.value"
                                                class="w-80 m-auto p-1"
                                            />
                                        </slide>

                                        <template #addons>
                                            <!-- <navigation /> -->
                                            <!-- <pagination /> -->
                                        </template>
                                    </carousel>
                                </div>
                            </div>
                            <div v-if="element?.detail.type == 'video'">
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://cdn0.iconfinder.com/data/icons/social-media-2415/64/37_Upload_video-512.png"
                                        />
                                    </div>
                                </div>
                                <video
                                    width="320"
                                    height="240"
                                    controls
                                    loop
                                    autoplay
                                    class="m-auto"
                                >
                                    <source :src="element.detail.value" />
                                    <source :src="element.detail.value" />
                                </video>
                            </div>
                            <div
                                v-if="element?.detail.type == 'message'"
                                class="w-50"
                            >
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -30px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFJnDjfGRyZDQ-w7G6J8Us37Enxocn2Hy-b5VvUtSAlLxb2bbXcc4506SaWM-2t3H9vIE&usqp=CAU"
                                        />
                                    </div>
                                </div>
                                <p
                                    class="wrapper-detail p-5"
                                    v-html="element.detail.value"
                                ></p>
                            </div>
                            <div v-if="element.detail.type == 'form'">
                                <div class="text-center"> 
                                    <svg viewBox="0 0 1024 1024" class="icon" width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M276.5 529.5l499.4 1.8-25.7 148.4h-433.3z" fill="#8CAAFF"></path><path d="M222.7 356.9L852.3 359.2l-32.4 181.4h-546.3z" fill="#FFFFFF"></path><path d="M399.4 314.9h55.4v347.3h-55.4zM593.4 314.9h55.4v347.3h-55.4z" fill="#333333"></path><path d="M254.3 544.3v-53.1h538.1v53.1z" fill="#333333"></path><path d="M207.8 221.1l128.7 463.2c3.8 13.9-4.3 28.2-18.2 32-0.1 0-0.1 1-0.2 1v-1c-14 3.8-28.4-4.5-32.3-18.5L157.1 234.6c-3.8-13.9 4.3-28.2 18.2-32h0.2c14-3.8 28.4 4.5 32.3 18.5z" fill="#333333"></path><path d="M75.6 201.6h107.2c14.7 0 26.6 11.9 26.6 26.6v2.5c0 14.7-11.9 26.6-26.6 26.6h-107.2c-14.7 0-26.6-11.9-26.6-26.6v-2.5c0.1-14.7 11.9-26.6 26.6-26.6z" fill="#333333"></path><path d="M301.4 792.8a51.2 48.7 0 1 0 102.4 0 51.2 48.7 0 1 0-102.4 0Z" fill="#333333"></path><path d="M635.2 792.8a51.2 48.7 0 1 0 102.4 0 51.2 48.7 0 1 0-102.4 0Z" fill="#333333"></path><path d="M315.6 662.2h429.3c14.7 0 26.8 11.4 27.8 26.1l0.1 1.7c0.9 14.4-10.1 26.9-24.5 27.8-0.5 0-1.1 0.1-1.6 0.1h-432.8c-14.5 0-26.2-11.7-26.2-26.2 0-0.5 0-1.1 0.1-1.6l0.1-1.7c0.9-14.8 13.1-26.2 27.7-26.2zM235.4 314.9H845.7c14.7 0 26.6 11.9 26.6 26.6V344c0 14.7-11.9 26.6-26.6 26.6h-610.3c-14.7 0-26.6-11.9-26.6-26.6v-2.5c0.1-14.7 12-26.6 26.6-26.6z" fill="#333333"></path><path d="M849.2 317.2l0.8 0.2c15.4 4.1 24.5 19.7 20.4 34.9v0.2l-95.6 343.9c-4.2 15.1-20 24-35.3 20l-0.8-0.2c-15.4-4.1-24.5-19.7-20.4-34.9v-0.2l95.6-343.9c4.2-15.2 20-24.1 35.3-20z" fill="#333333"></path></g></svg>
                                </div>
                                <div class="text-center">FLOW SHOPPING</div>
                            </div>
                            <div
                                v-if="false && element.detail.type == 'form'"
                                class="w-50"
                            >
                                <div class="relative">
                                    <div
                                        class="absolute left-0"
                                        style="top: -20px;"
                                    >
                                        <a-image
                                            :width="20"
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukNsPtR7DuF9CrL_Ww_ZSnjvOG9I0ibcNTzoVS5RNZYrTC8w8iqwQmF_CU9d2QH8XGMg&usqp=CAU"
                                        />
                                    </div>
                                </div>
                                <div class="text-center pt-2 pr-2 pl-2">
                                    {{ element.detail.value.title }}
                                </div>
                                <div
                                    v-for="items in element.detail.value.option
                                        .items"
                                    :key="items"
                                >
                                    <a-radio-group
                                        v-if="items.type == 1"
                                        v-model:value="value"
                                    >
                                        <a-radio
                                            :style="radioStyle"
                                            :value="items"
                                            >{{ items.first }}({{
                                                parseInt(
                                                    items.last
                                                )?.toLocaleString()
                                            }})
                                        </a-radio>
                                    </a-radio-group>
                                </div>
                                <div>
                                    <div
                                        v-for="items in element?.detail.value
                                            .option.items"
                                        :key="items"
                                    >
                                        <div v-if="items.type == 2" class="p-2">
                                            <h1>ชื่อนามสกุล</h1>
                                            <div>
                                                <a-input
                                                    :disabled="true"
                                                    placeholder="ชื่อนามสกุล"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 3" class="p-2">
                                            <h1>อีเมลย์</h1>
                                            <div>
                                                <a-input
                                                    :disabled="true"
                                                    placeholder="อีเมลย์"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 4" class="p-2">
                                            <h1>เบอร์โทร</h1>
                                            <div>
                                                <a-input
                                                    :disabled="true"
                                                    placeholder="เบอร์โทร"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 5" class="p-2">
                                            <h1>LINE</h1>
                                            <div>
                                                <a-input
                                                    :disabled="true"
                                                    placeholder="LINE"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 6" class="p-2">
                                            <h1>ที่อยู่จัดส่งสินค้า</h1>
                                            <div>
                                                <a-textarea
                                                    :disabled="true"
                                                    placeholder="ที่อยู่จัดส่งสินค้า"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 7" class="p-2">
                                            <h1>ยอดชำระ</h1>
                                            <div>
                                                <a-textarea
                                                    :disabled="true"
                                                    placeholder="ยอดชำระ"
                                                />
                                            </div>
                                        </div>
                                        <div v-if="items.type == 8" class="p-2">
                                            <h1>{{ items.custom }}</h1>
                                            <div>
                                                <a-textarea
                                                    :disabled="true"
                                                    :placeholder="items.custom"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-3">
                                    <div
                                        v-if="element.detail.value.btnname"
                                        class="wrapper-btnname"
                                    >
                                        {{ element.detail.value.btnname }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <rawDisplayer :value="list" class="w-100" v-if="false" />
        <!-- <pre>{{ list }}</pre> -->
    </div>
</template>

<script>
import Util from "@/helper/Utility.js";
import {
    ExclamationCircleOutlined,
    UpOutlined,
    DownOutlined
} from "@ant-design/icons-vue";
import { VueDraggableNext } from "vue-draggable-next";
import rawDisplayer from "./rawDisplay.vue";
import { reactive, ref, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import {
    CloseOutlined,
    SettingOutlined,
    FieldTimeOutlined
} from "@ant-design/icons-vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import {
    objsalepage,
    objsalepagethank,
    objsalepagethankform,
    current_tab,
    onEdit
} from "@/store/store";
// import AddContent from "../../component/modal/AddContent.vue";
var list = [];
var number = 1;

// var tempdown = {}
export default {
    name: "transition-example",
    display: "Transition",
    order: 6,
    components: {
        UpOutlined,
        DownOutlined,
        draggable: VueDraggableNext,
        CloseOutlined,
        SettingOutlined,
        rawDisplayer,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        FieldTimeOutlined
    },
    data() {
        const use = ref();
        console.log(current_tab);
        if (current_tab.value == "2") {
            use.value = objsalepagethank;
        } else if (current_tab.value == "1") {
            use.value = objsalepage;
        } else if (current_tab.value == "3") {
            use.value = objsalepagethankform;
            console.log("objsalepagethankform :", objsalepagethankform);
        }

        console.log(onEdit);

        const value = ref(1);
        const radioStyle = reactive({
            display: "flex",
            height: "30px",
            lineHeight: "30px"
        });

        console.log(objsalepagethank);
        list = use.value;
        console.log("List", list);

        this.$emit("onlist", list);
        return {
            radioStyle,
            value,
            list,
            number,
            onEdit,
            factontime: ref(false),
            current_tab
        };
    },
    mounted() {
        console.log(this.list);
    },
    watch: {
        list(newQuestion, oldQuestion) {
            if (newQuestion.indexOf("?") > -1) {
                console.log(oldQuestion);
                console.log("list", this.list);
                this.$emit("onlist", this.list);
            }
        }
    },
    methods: {
        dateformat(dateTimeString) {
            const dateTime = new Date(dateTimeString);

            // Extract the date components
            const year = dateTime.getFullYear();
            const month = String(dateTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
            const day = String(dateTime.getDate()).padStart(2, "0");

            // Extract the time components
            const hours = String(dateTime.getHours()).padStart(2, "0");
            const minutes = String(dateTime.getMinutes()).padStart(2, "0");
            const seconds = String(dateTime.getSeconds()).padStart(2, "0");

            // Create the formatted date and time string
            const formattedDateTime = `${year}-${month}-${day} เวลา ${hours}:${minutes}:${seconds}`;

            return formattedDateTime;
        },
        setnum() {
            return number++;
        },
        cdnpath(path) {
            return Util.replacecdn(path);
        },
        addvalue(e) {
            this.$emit("onlist", this.list);

            console.log(e);
        },
        edit(element, index) {
            element.order = index + 1;

            console.log(index);

            onEdit.value = {
                type: element?.detail.type,
                isshowedit: true,
                editobj: element
            };
        },
        up(element, index) {
            var tempup = null;
            if (index == 0) return;
            console.log(element);
            console.log(index);

            tempup = this.list[index - 1];
            this.list[index - 1] = element;
            this.list[index] = tempup;
            console.log(this.list);
        },
        down(element, index) {
            if (index + 1 == this.list.length) return;
            var tempdown = null;
            console.log(element);
            console.log(index);
            console.log(list);

            tempdown = this.list[index + 1];
            this.list[index + 1] = element;
            this.list[index] = tempdown;
            console.log(this.list);
        },
        onremove(type, index) {
            const that = this;
            Modal.confirm({
                title: "คุณต้องการลบ?",
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode(
                    "div",
                    {
                        style: "color:red;"
                    },
                    ""
                ),

                onOk() {
                    that.list.splice(index, 1);
                    that.use?.value[type]?.splice(index, 1);
                    that.$emit("onlist", that.list);
                },

                onCancel() {
                    console.log("Cancel");
                },
                class: "test"
            });
        },
        sort() {
            this.list = this.list.sort((a, b) => a.order - b.order);
        },
        log(event) {
            console.log(event);
            console.log("list", this.list);
            this.$emit("onlist", this.list);
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    }
};
</script>

<style>
.button {
    margin-top: 35px;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.btn {
    @apply font-bold py-2 px-4 rounded;
}

.btn-blue {
    @apply bg-blue-500 text-white;
}

.btn-blue:hover {
    @apply bg-blue-700;
}

.carousel__next,
.carousel__prev {
    background: radial-gradient(circle, #e1c724 10%, #e1c724 90%);
    margin: 0px 20px;
}

.carousel__pagination-button {
    background-color: #e1c724;
}

.carousel__pagination-button--active {
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .wrapper-drag-mobile {
        padding: 0.25rem !important;
    }
}

.order-items {
    position: absolute;
    display: flex;
    align-items: center;
    height: auto;
    z-index: 1;
    text-indent: 30px;
}

.ql-align-center {
    text-align: center;
}

.wrapper-btnname {
    background: #e4ca98;
    min-height: 10px;
    width: 100%;
    font-size: 20px;
    padding: 5px;
    text-align: center;
    border-radius: 25px;
    border: 1.5px solid #d2af66;
    -webkit-animation: fontcolor 1s infinite;
    animation: fontcolor 1s infinite;
    font-weight: 500;
}

@keyframes fontcolor {
    from {
        bottom: 0;
        color: #72523d;
        opacity: 1;
    }

    to {
        bottom: -60px;
        color: #fe1417;
        opacity: 1;
    }
}
</style>
