<template>
    <div>
        <p v-html="msg"></p>
    </div>
</template>
<script>
import Util from '@/helper/Utility.js';
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: 'DefaultConfirmRedeem',
    mixins: [LocaleMixin],
    props: {
        campaign: Object
    },
    data: function () {
        return {
            msg: ''
        };
    },
    created: function () {
        var minutes = this.campaign.minutesValidAfterUsed;
        if (minutes) {
            var days = '';
            var hours = '';
            var result = '';
            if (minutes >= 60) {
                hours = Math.floor(minutes / 60);
                if (hours >= 24) {
                    days = Math.floor(hours / 24);
                }
            }

            if (days) {
                result += days + ' ' + this.lbl.date.days + ' ';
            }
            if (hours) {
                result += hours + ' ' + this.lbl.date.hours + ' ';
            }
            if (minutes / 60 > 0) {
                result += (minutes % 60) + ' ' + this.lbl.date.minutes_short;
            }

            this.msg = Util.stringFormat(this.lbl.confirm_redeem.defualt_have_time, result);
        } else {
            this.msg = this.lbl.confirm_redeem.default;
        }
    }
}
</script>
