<template>
    <!-- <pre>{{ list.listaddress }}</pre> -->

    <a-button
        id="title_address_id"
        type="primary"
        class="mb-2"
        @click="showModal"
    >
        <template #icon>
            <PlusCircleOutlined />
        </template>
        {{ textbtn }}
    </a-button>
    <!-- //modal สร้าง -->
    <a-modal
        v-model:visible="visible"
        width="1000px"
        title="ที่อยู่"
        @ok="fromchange"
    >
        <a-form :model="formState" name="basic" autocomplete="off">
            <a-row>
                <div>
                    เลือกที่อยู่ที่เคยกรอกไว้
                </div>
            </a-row>
            <a-row>
                <div
                    class="p-4"
                    v-show="
                        formState.user.ProvinceName ||
                            formState.user.BorderName ||
                            formState.user.DistrictName ||
                            formState.user.PostCode ||
                            formState.user.provincepostinter ||
                            formState.user.citypostinter ||
                            formState.user.postcodepostinter
                    "
                >
                    <a-checkbox
                        v-model:checked="selectaddress"
                        @change="settingaddress(selectaddress)"
                    >
                        <span
                            v-if="
                                formState.user.customeraddressType == '1' &&
                                    (formState.user.ProvinceName ||
                                        formState.user.BorderName ||
                                        formState.user.DistrictName ||
                                        formState.user.PostCode)
                            "
                        >
                            {{ formState.user.customername }}
                            {{ formState.user.customerlastname }},{{
                                formState.user.ProvinceName
                            }},{{ formState.user.BorderName }},{{
                                formState.user.DistrictName
                            }},{{ formState.user.PostCode }}
                        </span>

                        <span
                            v-if="
                                formState.user.customeraddressType == '2' &&
                                    (formState.user.provincepostinter ||
                                        formState.user.citypostinter ||
                                        formState.user.postcodepostinter)
                            "
                        >
                            {{ formState.user.customername }}
                            {{ formState.user.customerlastname }},
                            {{ formState.user.selectcountry.selectcountry }},
                            {{ formState.user.provincepostinter }},
                            {{ formState.user.citypostinter }},
                            {{ formState.user.postcodepostinter }},

                            {{ formState.user.addressdesc }},
                            {{ formState.user.customeremail }},
                            {{ formState.user.customerphone }}
                        </span>
                    </a-checkbox>
                </div>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1" :order="1">
                    <a-form-item
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-radio-group
                            v-model:value="formState.useraddress.typeaddress"
                        >
                            <a-radio :style="radioStyle" :value="'1'"
                                >ในประเทศ</a-radio
                            >
                            <a-radio :style="radioStyle" :value="'2'">
                                ต่างประเทศ
                            </a-radio>
                            <a-radio :style="radioStyle" :value="'3'">
                                รับสินค้าเอง/จัดส่งทางไลน์
                            </a-radio>
                        </a-radio-group>
                        <span class="mt-2">
                            <SelectOption
                                v-if="formState.useraddress.typeaddress == '2'"
                                :key="updatecomponent"
                                v-model:value="
                                    formState.useraddress.selectcountry
                                "
                                :formStates="formState"
                            ></SelectOption>
                        </span>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex">
                <a-col class="p-1" flex="1 1 300px" :order="1">
                    <a-form-item
                        label="ชื่อ"
                        :name="['useraddress', 'name']"
                        :rules="{
                            required: false,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input v-model:value="formState.useraddress.name" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 300px" :order="2">
                    <a-form-item
                        label="นามสกุล"
                        :name="['useraddress', 'lastname']"
                        :rules="{
                            required: false,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input
                            v-model:value="formState.useraddress.lastname"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="ที่อยู่"
                        :name="['useraddress', 'address']"
                        :rules="{
                            required: false,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-textarea
                            :auto-size="{ minRows: 2, maxRows: 10 }"
                            v-model:value="formState.useraddress.address"
                            show-count
                            placeholder="ที่อยู่"
                            :maxlength="400"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <div v-if="formState.useraddress.typeaddress == '2'">
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="ประเทศ"
                            :name="['useraddress', 'address']"
                        >
                            <a-textarea
                                :disabled="true"
                                v-model:value="
                                    formState.useraddress.CountryName
                                "
                                show-count
                                placeholder="-"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="เมือง (ผู้รับภาษาอังกฤษ)0"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="formState.useraddress.BorderName"
                                show-count
                                placeholder="เมือง"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="จังหวัด (ผู้รับภาษาอังกฤษ)"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="
                                    formState.useraddress.ProvinceName
                                "
                                show-count
                                placeholder="จังหวัด"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="รหัสไปรษณีย์ (ผู้รับภาษาอังกฤษ)"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="formState.useraddress.PostCode"
                                show-count
                                placeholder="รหัสไปรษณีย์"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="โทร" :name="['useraddress', 'phone']">
                        <InputContactNumber
                            v-if="
                                formState.useraddress.typeaddress == '1' ||
                                    formState.useraddress.typeaddress == '3'
                            "
                            :key="updatecomponent"
                            v-model:inputValue="formState.useraddress.phone"
                            :errorInfo="errorInfo[3]"
                            :isFirstSave="false"
                            :isShowErrorLbl="false"
                            :inputLbl="lbl.shipping.contactnumber"
                            :minLength="12"
                            :maxLength="12"
                            delimeter="-"
                        >
                        </InputContactNumber>

                        <div v-else>
                            <div class="p-2">
                                <a-switch
                                    v-model:checked="
                                        formState.useraddress
                                            .switchnothasphonecode
                                    "
                                    checked-children="ไม่มี-รหัสประเทศ"
                                    un-checked-children="มี-รหัสประเทศ"
                                />
                            </div>
                            <!-- <pre>formState.useraddress.phone : {{formState.useraddress.phone}}</pre> -->
                            <div
                                v-if="
                                    formState.useraddress.switchnothasphonecode
                                "
                            >
                                <a-input
                                    style="width: 50%"
                                    v-model:value="formState.useraddress.phone"
                                />
                            </div>
                            <div
                                v-else-if="
                                    !formState.useraddress.switchnothasphonecode
                                "
                            >
                                <a-input-group compact>
                                    <a-select
                                        show-search
                                        placeholder="รหัสโทร"
                                        style="width: 100px"
                                        v-model:value="
                                            formState.user.selectcountry
                                                .selectcountrycode
                                        "
                                        :options="listcodephone"
                                        :filter-option="filterOption"
                                        @focus="handleFocus"
                                        @blur="handleBlur"
                                        @change="handleChange"
                                    ></a-select>
                                    <a-input
                                        style="width: 50%"
                                        v-model:value="
                                            formState.useraddress.phone
                                        "
                                    />
                                </a-input-group>
                            </div>
                        </div>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="Email" :name="['useraddress', 'email']">
                        <a-input v-model:value="formState.useraddress.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row v-if="formState.useraddress.typeaddress == '1'">
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="จังหวัด"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <Address
                            v-if="!selectaddress"
                            @province="onprovince($event)"
                            @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)"
                            @postcode="onpostcode($event)"
                        ></Address>
                        <Address
                            v-if="selectaddress"
                            :setprovince="formState.user.ProvinceName"
                            :setdistricts="formState.user.BorderName"
                            :setsubdistricts="formState.user.DistrictName"
                            :setpostcode="formState.user.PostCode"
                            @province="onprovince($event)"
                            @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)"
                            @postcode="onpostcode($event)"
                        ></Address>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1"> </a-col>
            </a-row>
            <a-row v-if="false">
                <a-row>
                    รายการสินค้าที่จัดส่ง
                    <br />

                    <a-col class="pl-5" flex="1 1 " :order="2">
                        <div>
                            <a-checkbox
                                v-model:checked="checkAll"
                                @change="onCheckAllChange"
                            >
                                เลือกทั้งหมด
                            </a-checkbox>
                        </div>
                        <a-divider />
                        <!-- productchecksend1 : {{productchecksend}} -->
                        <!-- <a-checkbox-group v-model:value="checkedList" :options="plainOptions" /> -->
                        <a-checkbox-group
                            v-model:value="formState.useraddress.checkedsend"
                            name="checkboxgroup"
                            :options="productchecksend"
                        />
                    </a-col>
                </a-row>
            </a-row>
            <a-row>
                <!-- <pre>{{ formState.useraddress }}</pre> -->
            </a-row>
            <a-button @click="printContent" type="primary" :loading="loading">Print</a-button>
        </a-form>
    </a-modal>
    <!-- //modal สร้าง -->
    <!-- //modal แก้ไข -->
    <a-modal
        v-model:visible="visibleEdit"
        width="1000px"
        title="ที่อยู่"
        @ok="fromchange"
    >
        <!-- formState :
        <pre>{{formState}}</pre> -->
        <a-form :model="formState" name="basic" autocomplete="off">
            <a-row>
                <a-col class="p-1" flex="1 1" :order="1">
                    <a-form-item
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-radio-group
                            v-if="false"
                            v-model:value="formState.useraddress.typeaddress"
                        >
                            <a-radio :style="radioStyle" :value="'1'"
                                >ในประเทศ</a-radio
                            >
                            <a-radio :style="radioStyle" :value="'2'">
                                ต่างประเทศ
                            </a-radio>
                        </a-radio-group>
                        <span class="mt-2">
                            <!-- <pre>{{formState.useraddress}}</pre> -->
                            <SelectOption
                                v-if="formState.useraddress.typeaddress == '2'"
                                :key="updatecomponent"
                                :formStates="formState"
                            >
                            </SelectOption>
                        </span>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex">
                <a-col class="p-1" flex="1 1 300px" :order="1">
                    <a-form-item
                        label="ชื่อ"
                        :name="['useraddress', 'name']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input v-model:value="formState.useraddress.name" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 300px" :order="2">
                    <a-form-item
                        label="นามสกุล"
                        :name="['useraddress', 'lastname']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input
                            v-model:value="formState.useraddress.lastname"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="ที่อยู่"
                        :name="['useraddress', 'address']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-textarea
                            v-model:value="formState.useraddress.address"
                            show-count
                            placeholder="ที่อยู่"
                            :maxlength="400"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <div v-if="formState.useraddress.typeaddress == '2'">
                <!-- {{formState.useraddress}}

                 -->
                <!-- <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item label="ประเทศ" :name="['useraddress', 'address']">
                            <a-textarea :disabled="true" v-model:value="formState.useraddress.CountryName" show-count
                                placeholder="จังหวัด" :maxlength="400" />
                        </a-form-item>
                    </a-col>
                </a-row> -->

                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="เมือง (ผู้รับภาษาอังกฤษ)1"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="formState.useraddress.BorderName"
                                show-count
                                placeholder="เมือง"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="จังหวัด (ผู้รับภาษาอังกฤษ)"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="
                                    formState.useraddress.ProvinceName
                                "
                                show-count
                                placeholder="จังหวัด"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col class="p-1" flex="1 1 " :order="1">
                        <a-form-item
                            label="รหัสไปรษณีย์ (ผู้รับภาษาอังกฤษ)"
                            :name="['useraddress', 'address']"
                            :rules="{
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }"
                        >
                            <a-textarea
                                v-model:value="formState.useraddress.PostCode"
                                show-count
                                placeholder="รหัสไปรษณีย์"
                                :maxlength="400"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="โทร"
                        :name="['useraddress', 'phone']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <!-- <pre>{{
                            formState.useraddress.switchnothasphonecode
                        }}</pre> -->
                        <div class="p-2">
                            <a-switch
                                v-model:checked="
                                    formState.useraddress.switchnothasphonecode
                                "
                                checked-children="ไม่มี-รหัสประเทศ"
                                un-checked-children="มี-รหัสประเทศ"
                            />
                        </div>
                        <div v-if="formState.useraddress.switchnothasphonecode">
                            <a-input
                                v-model:value="formState.useraddress.phone"
                                style="width: 50%"
                            ></a-input>
                        </div>
                        <div
                            v-else-if="
                                !formState.useraddress.switchnothasphonecode
                            "
                        >
                            <a-input
                                v-if="formState.useraddress.typeaddress == '1'"
                                v-model:value="formState.useraddress.phone"
                            />
                            <a-input-group compact v-else>
                                <a-select
                                    show-search
                                    placeholder="รหัสโทร"
                                    style="width: 100px"
                                    v-model:value="
                                        formState.user.selectcountry
                                            .selectcountrycode
                                    "
                                    :options="listcodephone"
                                    :filter-option="filterOption"
                                    @focus="handleFocus"
                                    @blur="handleBlur"
                                    @change="handleChange"
                                ></a-select>
                                <a-input
                                    style="width: 50%"
                                    v-model:value="formState.useraddress.phone"
                                />
                            </a-input-group>
                        </div>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="Email" :name="['useraddress', 'email']">
                        <a-input v-model:value="formState.useraddress.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row v-if="formState.useraddress.typeaddress == '1'">
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="จังหวัด"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <Address
                            :setprovince="formState.useraddress.ProvinceName"
                            :setdistricts="formState.useraddress.BorderName"
                            :setsubdistricts="
                                formState.useraddress.DistrictName
                            "
                            :setpostcode="formState.useraddress.PostCode"
                            @province="onprovince($event)"
                            @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)"
                            @postcode="onpostcode($event)"
                            :key="updatecomponent"
                        ></Address>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1"> </a-col>
            </a-row>
            <a-row v-if="false">
                <a-row>
                    รายการสินค้าที่จัดส่ง
                    <br />
                    <!-- formState.useraddress.checkedsend : {{formState.useraddress.checkedsend}}
                    productchecksend :{{productchecksend}} -->
                    <a-col class="pl-5" flex="1 1 " :order="2">
                        <div>
                            <a-checkbox
                                v-model:checked="checkAll"
                                @change="onCheckAllChange"
                            >
                                เลือกทั้งหมด
                            </a-checkbox>
                        </div>
                        <a-divider />
                        <!-- productchecksend2 : {{productchecksend}} -->
                        <a-checkbox-group
                            v-model:value="formState.useraddress.checkedsend"
                            name="checkboxgroup"
                            :options="productchecksend"
                        />
                    </a-col>
                    <!-- <a-col class="pl-5" flex="1 1 " :order="3">
                        <a-button type="primary" @click="getsentaddress">อัพเดท(สินค้าที่จัดส่ง)</a-button>
                    </a-col> -->
                </a-row>
            </a-row>
        </a-form>
        <a-button @click="printContent" type="primary" :loading="loading">Print Address</a-button>
        <a-modal
            v-model:visible="visibleprint"
            title="รอ.."
            :ok-button-props="{ disabled: true }"
            :cancel-button-props="{ disabled: true }"
        >
            <p>รอสักครู่...</p>
        </a-modal>
    </a-modal>
    <!-- //modal แก้ไข -->
    <!-- <pre>{{dataSource}}</pre> -->
    <a-divider orientation="left">ที่อยู่(1.1)</a-divider>

    <!-- <pre>{{ dataSource }}</pre> -->
    <a-table
        :columns="columns"
        :data-source="dataSource"
        bordered
        :scroll="{ x: 0 }"
        :pagination="{ pageSize: 50, hideOnSinglePage: true }"
        :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
    >
        <template #bodyCell="{ column, record, index }">
            <!-- <pre>{{record}}</pre> -->
            <template v-if="column.dataIndex === 'key'">
                <div>รายการที่ {{ index + 1 }}</div>
            </template>
            <template v-if="column.dataIndex == 'address'">
                <div v-show="record.typeaddress == '1'">
                    <font-awesome-icon icon="fa-solid fa-home" />
                    <ul>
                        <li>
                            <a-typography-text>{{
                                record.Address || record.address
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.ProvinceName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.BorderName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.DistrictName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.PostCode
                            }}</a-typography-text>
                        </li>
                    </ul>
                </div>
                <div v-show="record.typeaddress == '2'">
                    <font-awesome-icon
                        icon="fa-solid fa-solid fa-earth-americas"
                    />
                    <!-- {{formState.user.selectcountry.selectcountry}} -->
                    <ul>
                        <li>
                            <a-typography-text>{{
                                record.CountryName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.address
                            }}</a-typography-text>
                        </li>
                    </ul>
                </div>
                <div v-show="record.typeaddress == '3'">
                    <a-typography-text>รับสินค้าเอง</a-typography-text>
                </div>
            </template>
            <!-- <template v-if="column.dataIndex == 'productcheckedsend'">
                <div v-if="record.productcheckedsend">
                    <ul v-for="(bcItem, idx) of formatproduct(record.productcheckedsend)" :key="`bc-item-${idx}`">
                        <li>{{ bcItem }}</li>
                    </ul>
                </div>
            </template> -->
            <template v-if="column.dataIndex == 'sent'">
                <div
                    v-if="formStateStore.userproduct.addresskey"
                    class="text-center"
                >
                    <font-awesome-icon
                        v-show="
                            record.addresskey ==
                                formStateStore.userproduct.addresskey
                        "
                        icon="fa-solid fa-check"
                        center
                    />
                </div>
                <div v-else-if="record.SetDefault == '1'" class="text-center">
                    <font-awesome-icon icon="fa-solid fa-check" center />
                </div>
            </template>
            <template
                v-if="
                    column.dataIndex == 'setup' &&
                        Storedata.methods.checkgrantccountonly()
                "
            >
                <div class="text-center">
                    <span class="m-2">
                        <!-- <pre>{{record}}</pre> -->
                        <a-popconfirm
                            title="คุณต้องการตั้งค่าที่อยู่นี้?"
                            @confirm="onSetDefault(record)"
                        >
                            <setting-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>{{ setaddressmesssage }}</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
            <template
                v-else-if="
                    column.dataIndex === 'operation' &&
                        Storedata.methods.checkgrantccountonly()
                "
            >
                <div class="editable-row-operations">
                    <span class="m-2">
                        <a-popconfirm
                            title="คุณต้องการแก้ไข?"
                            @confirm="onEdit(record)"
                        >
                            <setting-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>แก้ไข</a>
                        </a-popconfirm>
                    </span>
                    <span class="m-2">
                        <a-popconfirm
                            title="คุณต้องการลบ?"
                            @confirm="onDelete(record)"
                        >
                            <delete-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>ลบ</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
        </template>
    </a-table>
</template>
<script>
// import { PlusCircleOutlined } from "@ant-design/icons-vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import InputEmail from "@/components/Input/label/InputEmail.vue";
import { defineComponent, reactive, ref, watch, onMounted, toRefs } from "vue";
import SelectOption from "../form/SelectOption.vue";
import Address from "../../component/Address.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
import { formStateStore } from "@/store/storebackoffice";
import { SettingOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { FormHelper } from "@/helper/FormHelper.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import Storedata from "@/store/Storedata.js";
import { useRoute } from "vue-router";
import { ProductDeliveryAddressModel } from "@/core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
var columns = [
    {
        title: "ลำดับ",
        dataIndex: "key",
        width: "4%"
    },
    {
        title: "ที่อยู่",
        dataIndex: "address",
        width: "10%"
    },
    {
        title: "ตั้งค่า",
        dataIndex: "sent",
        fixed: "right",
        width: "3%"
    },
    {
        title: "จัดการ",
        dataIndex: "operation",
        fixed: "right",
        width: "8%"
    }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        InputEmail,
        InputContactNumber,
        DeleteOutlined,
        SettingOutlined,
        SelectOption,
        Address
        // PlusCircleOutlined
    },
    props: {
        textbtn: null,
        formStates: null,
        isshowadd: true
    },
    mixins: [LocaleMixin],
    setup(props, { emit }) {
        const setaddressmesssage = ref("");
        const route = useRoute();
        var iscreate = false;
        // iscreate = window.location.pathname == '/sellcreate' ? true : false
        var ispagegreenreport = false;
        if (window.location.pathname == "/createdeliverylist") {
            iscreate =
                window.location.pathname == "/createdeliverylist"
                    ? true
                    : false;
            if (iscreate) {
                setaddressmesssage.value = "เลือกใช้ที่อยู่นี้";
            }
        } else if (window.location.pathname == "/sellcreate") {
            iscreate = window.location.pathname == "/sellcreate" ? true : false;
        } else if (window.location.pathname == "/selllist") {
            setaddressmesssage.value = "เลือกใช้ที่อยู่นี้แสดงในใบเขียว";
            ispagegreenreport = true;
        }

        if (iscreate) {
            columns = [
                {
                    title: "ลำดับ",
                    dataIndex: "key",
                    width: "4%"
                },
                {
                    title: "ที่อยู่",
                    dataIndex: "address",
                    width: "10%"
                },
                {
                    title: "ใช้ที่อยู่นี้",
                    dataIndex: "sent",
                    fixed: "right",
                    width: "3%"
                },
                {
                    title: "ตั้งค่า",
                    dataIndex: "setup",
                    fixed: "right",
                    width: "5%"
                },
                {
                    title: "จัดการ",
                    dataIndex: "operation",
                    fixed: "right",
                    width: "8%"
                }
            ];
        } else if (ispagegreenreport) {
            columns = [
                {
                    title: "ลำดับ",
                    dataIndex: "key",
                    width: "4%"
                },
                {
                    title: "ที่อยู่",
                    dataIndex: "address",
                    width: "10%"
                },
                {
                    title: "ใช้ที่อยู่นี้",
                    dataIndex: "sent",
                    fixed: "right",
                    width: "3%"
                },
                {
                    title: "ตั้งค่า",
                    dataIndex: "setup",
                    fixed: "right",
                    width: "5%"
                },
                {
                    title: "จัดการ",
                    dataIndex: "operation",
                    fixed: "right",
                    width: "8%"
                }
            ];
        } else if (formStateStore.is_edit_product != "edit") {
            columns = [
                {
                    title: "ลำดับ",
                    dataIndex: "key",
                    width: "4%"
                },
                {
                    title: "ที่อยู่",
                    dataIndex: "address",
                    width: "10%"
                },
                {
                    title: "ใช้ที่อยู่นี้",
                    dataIndex: "sent",
                    fixed: "right",
                    width: "3%"
                },
                {
                    title: "ตั้งค่า",
                    dataIndex: "setup",
                    fixed: "right",
                    width: "5%"
                },
                {
                    title: "จัดการ",
                    dataIndex: "operation",
                    fixed: "right",
                    width: "8%"
                }
            ];
        } else {
            columns = [
                {
                    title: "ลำดับ",
                    dataIndex: "key",
                    width: "4%"
                },
                {
                    title: "ที่อยู่",
                    dataIndex: "address",
                    width: "10%"
                },
                {
                    title: "ใช้ที่อยู่นี้",
                    dataIndex: "sent",
                    fixed: "right",
                    width: "3%"
                },
                {
                    title: "ตั้งค่า",
                    dataIndex: "setup",
                    fixed: "right",
                    width: "5%"
                },
                {
                    title: "จัดการ",
                    dataIndex: "operation",
                    fixed: "right",
                    width: "8%"
                }
            ];
        }

        const selectaddress = ref(false);
        const updatecomponent = ref(0);
        const isclieckOnEdit = ref(false);
        const listselectsent = ref([]);
        const checkedsend = ref([]);
        const visible = ref(false);
        const visibleEdit = ref(false);
        const formState = reactive(props.formStates);
        const isshowadd = reactive(props.isshowadd == false ? false : true);
        const productchecksend = ref([]);
        var listoptions = ref([]);
        const initapicountries = () => {
            BzbsUser.apicountries()
                .then(res => {
                    Object.keys(res.data).map(function(element) {
                        listoptions.value.push({
                            value: [
                                res.data[element].CountryNameTh,
                                res.data[element].CountryCode
                            ],
                            label: res.data[element].CountryNameTh
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        //         const setcountries = (p) => {
        // return
        //         }

        const showModal = () => {
            formState.useraddress = {
                name: "",
                lastname: "",
                address: "",
                phone: "",
                phonecode: "",
                email: "",
                key: 0,
                typeaddress: "1",
                selectcountry: "",
                selectcountrycode: "",
                ProvinceName: "",
                ProvinceID: "",
                BorderName: "",
                BorderID: "",
                DistrictName: "",
                PostCode: "",
                provincepostinter: "",
                citypostinter: "",
                postcodepostinter: ""
            };

            productchecksend.value = [];

            formState.listproduct.forEach((element, index) => {
                element = _.transform(element, function(result, val, key) {
                    result[key.toLowerCase()] = val;
                });
                let obj = {};
                obj.label =
                    "รายการที่ " +
                    (index + 1) +
                    "   " +
                    element.customerproductstype +
                    "," +
                    element.customerproductssize +
                    "," +
                    element.heartyanname +
                    "," +
                    element.productcode;
                obj.value = obj.label;
                obj.detail = element;
                productchecksend.value.push(obj);
            });
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const radioStyle = reactive({});

        const list = reactive({
            listaddress: []
        });

        const getsentaddress = () => {
            console.log("formState.listproduct", formState.listproduct);
            productchecksend.value = [];

            formState.listproduct.forEach((element, index) => {
                let obj = {};
                obj.label =
                    "รายการที่ " +
                    (index + 1) +
                    "   " +
                    element.customerproductstype +
                    "," +
                    element.customerproductssize +
                    "," +
                    element.heartyanname +
                    "," +
                    element.productcode;
                obj.value = obj.label;
                obj.detail = element;
                productchecksend.value.push(obj);
            });
            // visible.value = true;
        };
        watch(
            () => formState.user.selectcountry.selectcountrycode,
            () => {
                formState.useraddress.phonecode = listcodephone.value.filter(
                    word =>
                        word.value ==
                        formState.user.selectcountry.selectcountrycode
                );
            }
        );
        // watch(
        //     () => formState.useraddress.selectcountry.selectcountry,
        //     () => {
        //         try {
        //             if (
        //                 listcodephone.value.find(
        //                     word =>
        //                         word.value ==
        //                         formState.useraddress.selectcountry
        //                 )
        //             ) {
        //                 formState.useraddress.phonecode = listcodephone.value.filter(
        //                     word =>
        //                         word.value ==
        //                         formState.useraddress.selectcountry.selectcountry
        //                 );
        //             }
        //         } catch (error) {

        //         }
        //     }
        // );

        // watch(
        //     () => formState.useraddress.selectcountry,
        //     () => {

        //
        //         formState.useraddress.CountryName = formState.useraddress.selectcountry
        //

        //     }
        // );

        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            console.log("editIndex.value", editIndex.value);
            console.log("edit formState.useraddress", formState.useraddress);
            isclieckOnEdit.value = false;

            formState.useraddress.productcheckedsend =
                formState.useraddress.checkedsend;
            if (visibleEdit.value) {
                formState.listaddress[editIndex.value] = Object.assign(
                    {},
                    formState.useraddress
                );
            } else {
                let obj = {};
                obj = Object.assign({}, formState.useraddress);
                obj.key =
                    list.listaddress.length == 0 ? 0 : list.listaddress.length;

                list.listaddress[obj.key] = Object.assign({}, obj);
                console.log("list.listaddress", list.listaddress);
            }
            // emit("update:value", Object.assign({}, formState));
            if (editIndex.value) {
                formState.useraddress.CountryName =
                    formState.useraddress.selectcountry;
                dataSource.value[editIndex.value.key] = formState.useraddress;
            } else {
                var max_addresskey = Math.max(
                    ...formState.user.addressalllist?.map(o =>
                        parseInt(o.addresskey)
                    )
                );
                formState.useraddress.addresskey = (
                    max_addresskey + 1
                )?.toString();
                dataSource.value.push(formState.useraddress);
                if (dataSource.value.length == 1) {
                    onSetDefault(dataSource.value[0]);
                }
            }
            emit("update:value", dataSource.value);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;

            // if (route.query.p != 'create') {
            //     setTimeout(() => {
            //         insertproductdeliveryaddress()
            //     }, 1000);
            // }
            insertproductdeliveryaddress();

            // setTimeout(() => {
            //     getproductdeliveryaddress();
            // }, 1000);
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        onMounted(() => {
            initapicountries();
            getproductdeliveryaddress();
        });
        const getproductdeliveryaddress = () => {
            console.log("getproductdeliveryaddress:");
            formState.user.addressfrom = [];
            dataSource.value = [];

            var type =
                window.location.pathname == "/sellcreate" ? "all" : "one";
            // var type = 'all'
            if (route.query.p == "create") type = "one";

            BzbsUser.apigetproductdeliveryaddress(
                formState.user.customercode,
                formState.user.sellorderTransaction ||
                    formState.productsellorder,
                type
            )
                .then(res => {
                    console.log("res :", res);

                    res.data.forEach((element, index) => {
                        var addressinselected = false;

                        // if (formStateStore.userproduct.addresskey) {
                        //     if (element.AddressKey == formStateStore.userproduct.addresskey) {
                        //         addressinselected = true
                        //     }
                        // }
                        // else
                        if (element.SetDefault == "1") {
                            addressinselected = true;
                        }

                        //                         <div v-if="formStateStore.userproduct.addresskey" class="text-center">

                        // <font-awesome-icon v-show="record.addresskey == formStateStore.userproduct.addresskey"
                        //     icon="fa-solid fa-check" center />
                        // </div>
                        // <div v-else-if="record.SetDefault == '1'" class="text-center">

                        // <font-awesome-icon icon="fa-solid fa-check" center />
                        // </div>

                        var param = {
                            key: index,
                            addresskey: element.AddressKey,
                            productaddressdeliveryorder:
                                element.ProductAddressDeliveryOrder,
                            productcheckedsend: element.CheckedSend,
                            productsellorder: element.ProductSellOrder,
                            checkedsend: element.CheckedSend,
                            name: element.Name,
                            lastname: element.Lastname,
                            address: element.Address,
                            phone: element.Phone,
                            phonecode: element.PhoneCode,
                            email: element.Email,
                            typeaddress: element.TypeAddress,
                            selectcountry: element.SelectCountry,
                            CountryName: element.CountryName,
                            ProvinceName: element.ProvinceName,
                            ProvinceID: element.ProvinceID,
                            BorderName: element.BorderName,
                            BorderID: element.BorderID,
                            DistrictName: element.DistrictName,
                            PostCode: element.PostCode,
                            SetDefault: element.SetDefault,
                            addressinselected: addressinselected,
                            switchnothasphonecode: element.SwitchNotHasPhoneCode
                        };

                        // if (param.phonecode) formState.user.selectcountry.selectcountrycode = param.phonecode
                        if (element.SetDefault == "1") {
                            formState.useraddress = param;
                        }
                        if (
                            window.location.pathname == "/sellcreate" ||
                            formState.is_edit_product == "increasesize"
                        ) {
                            var set_all_for_first_cretae = param;
                            set_all_for_first_cretae.SetDefault = undefined;
                            formState.user.addressfrom.push(
                                set_all_for_first_cretae
                            );
                        }
                        dataSource.value.push(param);
                    });

                    formState.user.addressalllist = dataSource.value;

                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };

        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
            console.log(formState.useraddress.phonecode);
        };
        const dataSource = ref(list.listaddress);
        const editableData = reactive({});
        const editIndex = ref();
        const options = [
            {
                label: "Apple",
                value: "Apple"
            },
            {
                label: "Pear",
                value: "Pear"
            },
            {
                label: "Orange",
                value: "Orange"
            }
        ];

        const onSetDefault = record => {
            formStateStore.userproduct.addresskey = record.addresskey;
            if (record.SetDefault == undefined)
                dataSource.value.map(item => (item.SetDefault = "0"));
            record.SetDefault = "1";

            console.log(record);
            var param = {};
            param.productkey = formState.userproduct.productkey;
            param.customerorder = formState.user.customercode;
            param.productSellorder = record?.productsellorder;
            param.productaddressdeliveryorder =
                record?.productaddressdeliveryorder;
            param.addresskey = record?.addresskey.toString();

            console.log("update address : ", param);

            BzbsUser.apiupdatedefaultproductdeliveryaddresstbycustomerorder(
                param
            )
                .then(res => {
                    console.log(res);

                    if (route.query.p != "create") {
                        setTimeout(() => {
                            getproductdeliveryaddress();
                        }, 500);
                    }
                    emit("onaddresschange", true);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const onEdit = key => {
            formState.user.selectcountry.selectcountry = key.CountryName;
            editIndex.value = key;
            console.log("editIndex.value", editIndex.value);

            productchecksend.value = [];
            try {
                formState.useraddress = key;
                console.log("productchecksend :", productchecksend);
                // getCustomerProductlist()

                formStateStore.listproduct.forEach((items, index) => {
                    var result = _.transform(items, function(result, val, key) {
                        result[key.toLowerCase()] = val;
                    });
                    console.log("result >", result.productdetail);

                    let obj = {};
                    obj.label =
                        "รายการที่ " +
                        (index + 1) +
                        "   " +
                        result.customerproductstype +
                        "," +
                        result.customerproductssize +
                        "," +
                        result.heartyanname +
                        "," +
                        result.productcode;
                    obj.value = obj.label;
                    obj.detail = items.ProductJson;
                    productchecksend.value.push(obj);
                });

                visibleEdit.value = true;
                updatecomponent.value++;
                checkstatusall();
            } catch (error) {
                visibleEdit.value = true;
            }
        };

        const save = key => {
            Object.assign(
                dataSource.value.filter(item => key === item.key)[0],
                editableData[key]
            );
            delete editableData[key];
        };

        const cancel = key => {
            delete editableData[key];
        };

        const onDelete = key => {
            // var index = dataSource.value.findIndex(e => e.key === key)

            deleteaddress(key.addresskey);
            dataSource.value.splice(
                dataSource.value.findIndex(e => e.key === key),
                1
            );

            emit("update:value", dataSource.value);

            // formState.listproduct = dataSource.value
        };
        const deleteaddress = addresskey => {
            console.log("remove product:", addresskey);
            var param_del = {
                CustomerOrder: formState.user.customercode,
                ProductSellOrder: formState.user.sellorderTransaction,
                AddressKey: addresskey
            };
            console.log("param_del : ", param_del);

            BzbsUser.apideleteproductdeliveryaddress(param_del)
                .then(res => {
                    openNotification("ลบรายการเรียบร้อย");
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                    window.location.reload();
                });
        };
        const openNotification = massage => {
            notification["success"]({
                message: massage
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        };
        const formatproduct = product => {
            try {
                console.log("case1");
                if (_.isArray(product)) {
                    return product;
                } else {
                    return JSON.parse(product);
                }
            } catch (error) {
                console.log("case2");
                return product
                    .toString()
                    ?.replace("[", "")
                    .replace("]", "");
            }
        };
        const state = reactive({
            indeterminate: true,
            checkAll: false,
            checkedList: []
        });

        const onCheckAllChange = e => {
            console.log(productchecksend.value);
            Object.assign(state, {
                indeterminate: false
            });
            formState.useraddress.checkedsend = [];
            productchecksend.value.forEach(element => {
                formState.useraddress.checkedsend.push(element.label);
            });
            if (e.target.checked) {
                state.checkedList = formState.useraddress.checkedsend;
            } else {
                state.checkedList = [];
                formState.useraddress.checkedsend = [];
            }
        };
        watch(
            () => state.checkedList,
            val => {
                state.indeterminate =
                    !!val.length && val.length < productchecksend.value.length;
                state.checkAll = val.length === productchecksend.value.length;
            }
        );

        watch(
            () => formState.useraddress.typeaddress,
            val => {}
        );

        const checkstatusall = () => {
            if (
                formState.useraddress?.checkedsend &&
                JSON.parse(formState.useraddress?.checkedsend).length ==
                    productchecksend.value.length
            ) {
                state.checkAll = true;
            }
        };
        const settingaddress = isselect => {
            console.log("settingaddress");
            if (
                (isselect && formState.user.customeraddressType == "1") ||
                formState.user.customeraddressType == undefined
            ) {
                formState.useraddress.typeaddress =
                    formState.user.customeraddressType;
                formState.useraddress.name = formState.user.customername;
                formState.useraddress.lastname =
                    formState.user.customerlastname;
                formState.useraddress.address = formState.user.addressdesc;
                formState.useraddress.phone = formState.user.customerphonethai;
                formState.useraddress.email = formState.user.customeremail;
            } else if (isselect && formState.user.customeraddressType == "2") {
                formState.useraddress.typeaddress =
                    formState.user.customeraddressType;
                formState.useraddress.CountryName =
                    formState.user.selectcountry.selectcountry;
                formState.useraddress.ProvinceName =
                    formState.user.provincepostinter;
                formState.useraddress.BorderName = formState.user.citypostinter;
                formState.useraddress.PostCode =
                    formState.user.postcodepostinter;

                formState.useraddress.name = formState.user.customername;
                formState.useraddress.lastname =
                    formState.user.customerlastname;
                formState.useraddress.address = formState.user.addressdesc;
                formState.useraddress.email = formState.user.customeremail;
                formState.useraddress.phone = formState.user.customerphone;
            } else {
                formState.useraddress.name = "";
                formState.useraddress.lastname = "";
                formState.useraddress.address = "";
                formState.useraddress.phone = "";
                formState.useraddress.email = "";
            }
            updatecomponent.value++;

            // formState.useraddress.email = formState.user.customername

            // { { formState.user.customername } }
            // { { formState.user.customerlastname } }
            // { { formState.user.ProvinceName } }
            // { { formState.user.BorderName } }
            // { { formState.user.DistrictName } }
            // { { formState.user.DistrictName } }
            // { { formState.user.PostCode } }
        };

        const insertproductdeliveryaddress = async () => {
            for await (let listproductdeliveryaddress_items of formState.user
                .addressfrom) {
                listproductdeliveryaddress_items.productsellorder =
                    formState.user.sellorderTransaction ||
                    formState.productsellorder;

                listproductdeliveryaddress_items.customerorder =
                    formState.user.customercode;

                var phonecode = "";
                if (listproductdeliveryaddress_items?.phonecode) {
                    phonecode = listproductdeliveryaddress_items?.phonecode;
                } else if (
                    listproductdeliveryaddress_items.phonecode &&
                    listproductdeliveryaddress_items.phonecode[0].label
                ) {
                    phonecode =
                        listproductdeliveryaddress_items.phonecode[0].label;
                }
                if (phonecode) {
                    listproductdeliveryaddress_items.phonecode = phonecode;
                }
                let params = new ProductDeliveryAddressModel(
                    listproductdeliveryaddress_items
                );

                console.log("param", params);

                await BzbsUser.apiupdateproductdelivery(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            // if (route.query.p != "create") {
            //     setTimeout(() => {
            //         getproductdeliveryaddress();
            //     }, 2000);
            // }
        };

        const visibleprint = ref(false);
        const loading = ref(false);

        
        const printContent = async () => {  
            loading.value = true
            if (formState.useraddress.address?.length > 0) {
                visibleprint.value = true;
                var code_phone = listcodephone.value.find(
                    item =>
                        item.value ==
                        formState.user.selectcountry.selectcountrycode
                );

                var params = {
                    name: formState.useraddress.name,
                    lastname: formState.useraddress.lastname,
                    address: formState.useraddress.address?.replaceAll(
                        "\n",
                        " "
                    ),
                    countryname: formState.useraddress.CountryNameEn,
                    bordername: formState.useraddress.BorderName?.replaceAll(
                        "\n",
                        " "
                    ),
                    districtname: formState.useraddress.DistrictName?.replaceAll(
                        "\n",
                        " "
                    ),
                    provincename: formState.useraddress.ProvinceName?.replaceAll(
                        "\n",
                        " "
                    ),
                    postcode: formState.useraddress.PostCode?.replaceAll(
                        "\n",
                        " "
                    ),
                    selectcountrycode : formState.useraddress.typeaddress== "2" ?code_phone?.label:'',
                    phone: formState.useraddress.phone,
                    email: formState.useraddress.email
                };
                console.log("params : ",params)

                await BzbsUser.apiexportaddress(params)
                    .then(res => {
                        visibleprint.value = false;
                        window.open(res.data.path, "_blank").focus();
                        loading.value = false
                    })
                    .catch(error => {
                        loading.value = false
                        console.log(error);
                    });
            }
        };

        return {
            visibleprint,
            printContent,
            settingaddress,
            checkstatusall,
            ...toRefs(state),
            onCheckAllChange,
            onSetDefault,
            formatproduct,
            onEdit,
            onDelete,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            save,
            cancel,
            editIndex,
            list,
            productchecksend,
            checkedsend,
            watch,
            listselectsent,
            options,
            getsentaddress,
            formStateStore,
            updatecomponent,
            errorInfo: FormHelper.genErrorInfo(7),
            Storedata,
            selectaddress,
            isshowadd,
            setaddressmesssage,
            loading
        };
    }
});
</script>
