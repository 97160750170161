<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
 
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table size="middle" v-if="dataSources && !rowselecteddetail.ishowdetail" :scroll="{ x: 0 }"
                :columns="columns" :data-source="dataSources" @change="handleTableChange" @expandFixed="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">

                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'ioss'">
                        {{ record.iossnumber }}
                    </template>
                    <template v-if="column.dataIndex === 'updatetime'">
                        {{ formatdate(record.createddate) }}
                    </template>
                    <template v-if="column.dataIndex === 'filename'">
                      
                        <div>
                            <font-awesome-icon icon="fa-solid fa-file-pdf" class="pr-2" />
                            <a :href="record.filename" target="_blank">ไฟล์ใบปะหน้า</a>
                        </div>
                        <div class="mt-2">
                            <font-awesome-icon icon="fa-solid fa-address-card" class="pr-2" />
                            <a :href="'/consumermenu?track=' + record.barcode+'&inter=true'" target="_blank"><u>รายละเอียด</u></a>

                        </div>
                    </template>

                    <template v-else-if="column.dataIndex === 'status'">
                        <span>
                            <a-tag
                                :color="record.status === '0' ? 'ดำเนินการ' : record.status.length > 5 ? 'geekblue' : 'green'">
                                {{ record.status === 0 ? 'ดำเนินการ' : record.status.length > 5 ? 'geekblue' :
                                    'green' }}
                            </a-tag>
                        </span>
                    </template>
                </template>
                <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                    <div style="padding: 8px">
                        <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
                        <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            Reset
                        </a-button>
                    </div>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad
    },

    setup() {
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "บาร์โค้ด",
                dataIndex: "barcode",
                width: "15%", customFilterDropdown: true,
                onFilter: (value, record) => record.barcode.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                            searchInput.value.focus();
                        }, 100);
                    }
                },
            },
            {
                title: "ioss",
                dataIndex: "ioss",
            },
            {
                title: "item customer id",
                dataIndex: "itemCusId",
            },
            {
                title: "ผู้รับ",
                dataIndex: "receiver",
                width: "10%"
            },
            {
                title: "บริการ",
                dataIndex: "service"
            },
            {
                title: "เบอร์โทรศัพท์",
                dataIndex: "tell"
            },
            {
                title: "ประเทศ",
                dataIndex: "country"
            },
            {
                title: "สถานะ",
                dataIndex: "status"
            },
            {
                title: "วันที่",
                dataIndex: "updatetime",
                width: "15%"
            },
            {
                title: "จ่าหน้า",
                dataIndex: "filename",
                width: "10%"
            }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสร้างไปรษณีย์ไทย ต่างประเทศ",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const querycustomer = () => {

            return BzbsUser.apigetcreatedinter()
                .then(res => {

                    dataSources.value = res.data.items
                 
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: '',
            searchedColumn: '',
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        return {
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
