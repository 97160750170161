<template>
    <div class="relative p-4">
        <div class="card p-4">
            <a-steps :current="statecurrent">
                <a-step @click="statecurrent = 0">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>สร้างลูกค้า</template>
                    <!-- <template #description>
                        <span>สร้างรหัสลูกค้าใหม่</span>
                    </template> -->
                </a-step>
                <a-step @click="statecurrent = 1">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>สร้างรายการขาย</template>
                    <template #description>
                        <span
                            v-if="!formState.user.ordertransaction"
                            style="color:red"
                            >{{ messageorder }}</span
                        >
                    </template>
                </a-step>
                <a-step @click="statecurrent = 2">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>การชำระเงิน</template>
                    <!-- <template #description>
                        <span>สร้างรหัสลูกค้าใหม่</span>
                    </template> -->
                </a-step>
                <a-step @click="statecurrent = 3">
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>สินค้า</template>
                    <template #description>
                        <span
                            v-if="formState.listproduct.length == 0"
                            style="color:red"
                            >*กรอกรายการสินค้า</span
                        >
                    </template>
                </a-step>
                <a-step
                    v-show="formState.listproduct.length > 0"
                    @click="statecurrent = 4"
                >
                    <!-- <span slot="title">Finished</span> -->
                    <template #title>ที่อยู่</template>
                    <!-- <template #description>
                        <span>สร้างรหัสลูกค้าใหม่</span>
                    </template> -->
                </a-step>
            </a-steps>
        </div>
        <template v-if="title != null">
            <div class="m-4 text-lg font-bold text-gray-500">
                {{ title[statecurrent] }}
            </div>
        </template>

        <a-form
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >
            <div v-show="statecurrent == 0" class="p-5 mb-5 card">
                <a-divider orientation="left">ข้อมูลลูกค้า</a-divider>
                <a-row type="flex">
                    <a-col flex="1 1 500px" :order="1">
                        <user-outlined />
                        <a-form-item
                            :name="['user', 'customercode']"
                            label="รหัสลูกค้า"
                            :rules="[{ required: false, message: 'กรุณากรอก' }]"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                style="background: #80808038"
                                v-model:value="formState.user.customercode"
                                @onChange="countpress(e)"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col
                        flex="1 1 500px"
                        :order="2"
                        class="p-2 wrapper_createnew"
                    >
                        <create-customerfrom
                            @customercode="gencodecustomer($event)"
                        ></create-customerfrom>
                    </a-col>
                    <a-col flex="1 1 500px" :order="3"> </a-col>
                </a-row>
                <a-row type="flex">
                    <!-- formState.user.contactList :: -->
                    <a-col flex="1" :order="1">
                        <!-- formState : {{formState}} -->
                        <Contact
                            :key="formState.updatecomponent"
                            :textbtn="'เพิ่มช่องทางการติดต่อ'"
                            :formStates="formState"
                            v-model:value="formState.user.contactList"
                        ></Contact>
                    </a-col>
                </a-row>
                <!-- formState.user.contactlist:{{ formState.user.getcontactlist }} -->

                <div class="p-4">
                    <a-switch v-model:checked="isshowcustomerdesc" />
                    รายละเอียดลูกค้า
                </div>
                <div v-show="isshowcustomerdesc">
                    <a-divider orientation="left">รายละเอียด-ลูกค้า</a-divider>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customername']"
                                label="ชื่อ"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="formState.user.customername"
                                    placeholder="ชื่อ"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                :name="['user', 'customerlastname']"
                                label="นามสกุล"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="
                                        formState.user.customerlastname
                                    "
                                    placeholder="นามสกุล"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customernickname']"
                                label="ชื่อเล่น"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="
                                        formState.user.customernickname
                                    "
                                    placeholder="ชื่อเล่น"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                :name="['user', 'customergender']"
                                label="เพศ*"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-radio-group
                                    v-model:value="
                                        formState.user.customergender
                                    "
                                    name="radioGroup"
                                >
                                    <a-radio value="man">ชาย</a-radio>
                                    <a-radio value="female">หญิง</a-radio>
                                    <a-radio value="notspecified"
                                        >ไม่ระบุ</a-radio
                                    >
                                </a-radio-group>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customerbirthday']"
                                label="วันเกิด"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-radio-group
                                    v-model:value="
                                        formState.user.customerbirthdaytype
                                    "
                                    name="radioGroup"
                                >
                                    <a-radio value="1">ยังไม่แจ้ง</a-radio>
                                    <a-radio value="2">พ.ศ.</a-radio>
                                    <a-radio value="3">ค.ศ</a-radio>
                                </a-radio-group>
                                <a-date-picker
                                    v-if="
                                        formState.user.customerbirthdaytype == 3
                                    "
                                    @change="
                                        calculateAge(
                                            formState.user.customerbirthday
                                        )
                                    "
                                    v-model:value="
                                        formState.user.customerbirthday
                                    "
                                    value-format="YYYY-MM-DD"
                                />
                                <DatePickerDropdown
                                    v-if="
                                        formState.user.customerbirthdaytype == 2
                                    "
                                    v-model:inputValue="
                                        formState.user.customerbirthday
                                    "
                                    :errorInfo="errorInfo[6]"
                                    :isFirstSave="true"
                                    :isShowErrorLbl="true"
                                    :inputLbl="lbl.profile.birthdate"
                                    :isISO_8601="true"
                                    @onDateChange="
                                        calculateAge(
                                            formState.user.customerbirthday
                                        )
                                    "
                                >
                                </DatePickerDropdown>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                class=""
                                :name="['user', 'sellorderTransaction']"
                                label="อายุ"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input-number
                                    :style="{ color: 'green' }"
                                    id="inputNumber"
                                    v-model:value="formState.user.customerage"
                                    :min="1"
                                    :max="100"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                ref=""
                                :name="['user', 'customercareer']"
                                label="อาชีพ"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-textarea
                                    v-model:value="
                                        formState.user.customercareer
                                    "
                                    show-count
                                    placeholder="กรอกอาชีพ"
                                    :maxlength="100"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2"></a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customerlineid']"
                                label="LINE ID"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="
                                        formState.user.customerlineid
                                    "
                                    placeholder="LINE ID"
                                >
                                    <template #prefix>
                                        <img
                                            class="w-5"
                                            src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                        />
                                    </template>
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                :name="['user', 'customerfacebook']"
                                label="FACEBOOK"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="
                                        formState.user.customerfacebook
                                    "
                                    placeholder="FACEBOOK"
                                >
                                    <template #prefix>
                                        <img
                                            class="w-5"
                                            src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg"
                                        />
                                    </template>
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customerfacebook']"
                                label="เลขที่อยู่"
                            >
                                <a-textarea
                                    v-model:value="formState.user.addressdesc"
                                    show-count
                                    placeholder="ที่อยู่"
                                    :maxlength="100"
                                />
                            </a-form-item>
                            <a-form-item
                                class=""
                                :name="['user', 'customercountry']"
                                label="ประเทศ"
                            >
                                <a-radio-group
                                    v-model:value="
                                        formState.user.customeraddressType
                                    "
                                    name="radioGroup"
                                >
                                    <a-radio value="1">ไทย</a-radio>
                                    <a-radio value="2">ต่างประเทศ</a-radio>
                                </a-radio-group>
                                <div class="pt-2">
                                    <Address
                                        v-if="
                                            formState.user
                                                .customeraddressType == '1'
                                        "
                                        :key="update"
                                        :setprovince="
                                            formState.user.ProvinceName
                                        "
                                        :setdistricts="
                                            formState.user.BorderName
                                        "
                                        :setsubdistricts="
                                            formState.user.DistrictName
                                        "
                                        :setpostcode="formState.user.PostCode"
                                        @province="onprovince($event)"
                                        @districts="ondistricts($event)"
                                        @subdistricts="onsubdistricts($event)"
                                        @postcode="onpostcode($event)"
                                    ></Address>
                                    <SelectOption
                                        :formStates="formState"
                                        v-if="
                                            formState.user
                                                .customeraddressType == '2'
                                        "
                                        v-model:value="
                                            formState.user.selectcountry
                                        "
                                    ></SelectOption>
                                </div>
                                <div
                                    v-show="
                                        formState.user.customeraddressType ==
                                            '2'
                                    "
                                >
                                    <a-form-item
                                        :name="['user', 'customerfacebook']"
                                        label="เมือง (ต่างประเทศ)"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                formState.user.citypostinter
                                            "
                                            show-count
                                            placeholder="เมือง"
                                            :maxlength="100"
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        :name="['user', 'customerfacebook']"
                                        label="จังหวัด (ต่างประเทศ)"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                formState.user.provincepostinter
                                            "
                                            show-count
                                            placeholder="จังหวัด"
                                            :maxlength="100"
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        :name="['user', 'customerfacebook']"
                                        label="รหัสไปรษณีย์ (ต่างประเทศ)"
                                    >
                                        <a-textarea
                                            v-model:value="
                                                formState.user.postcodepostinter
                                            "
                                            show-count
                                            placeholder="รหัสไปรษณีย์"
                                            :maxlength="100"
                                        />
                                    </a-form-item>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2"> </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customerphonethai']"
                                label="เบอร์ติดต่อ"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <InputContactNumber
                                    :key="update"
                                    v-model:inputValue="
                                        formState.user.customerphonethai
                                    "
                                    :errorInfo="errorInfo[3]"
                                    :isFirstSave="false"
                                    :isShowErrorLbl="false"
                                    :inputLbl="lbl.shipping.contactnumber"
                                    :minLength="12"
                                    :maxLength="12"
                                    delimeter="-"
                                >
                                </InputContactNumber>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                :name="['user', 'customerphoneemergency']"
                                label="เบอร์ติดต่อ(ฉุกเฉิน)"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <!-- <InputContactNumber
                                    :key="update"
                                    v-model:inputValue="
                                        formState.user.customerphoneemergency
                                    "
                                    :errorInfo="errorInfo[3]"
                                    :isFirstSave="false"
                                    :isShowErrorLbl="false"
                                    :inputLbl="lbl.shipping.contactnumber"
                                    :minLength="12"
                                    :maxLength="12"
                                    delimeter="-"
                                >
                                </InputContactNumber> -->
                                <a-input
                                    v-model:value="
                                        formState.user.customerphoneemergency
                                    "
                                    placeholder="เบอร์ฉุกเฉิน"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                    <a-row type="flex">
                        <a-col flex="1 1 500px" :order="1">
                            <a-form-item
                                :name="['user', 'customerphone']"
                                label="เบอร์ติดต่อ(ต่างประเทศ)"
                                :rules="[
                                    { required: false, message: 'กรุณากรอก' }
                                ]"
                            >
                                <a-input-group compact>
                                    <a-select
                                        show-search
                                        v-model:value="
                                            formState.user.selectcountry
                                                .selectcountrycode
                                        "
                                        placeholder="รหัสโทร"
                                        style="width: 100px"
                                        :options="listcodephone"
                                        :filter-option="filterOption"
                                        @focus="handleFocus"
                                        @blur="handleBlur"
                                        @change="handleChange"
                                    ></a-select>
                                    <a-input
                                        :style="{ color: 'green' }"
                                        v-model:value="
                                            formState.user.customerphone
                                        "
                                        style="width: 50%"
                                    />
                                </a-input-group>
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="2">
                            <a-form-item
                                :name="['user', 'customeremail']"
                                label="E-Mail"
                            >
                                <a-input
                                    :style="{ color: 'green' }"
                                    v-model:value="formState.user.customeremail"
                                    placeholder="E-Mail"
                                />
                            </a-form-item>
                        </a-col>
                        <a-col flex="1 1 500px" :order="3"> </a-col>
                    </a-row>
                </div>
            </div>

            <div v-show="statecurrent == 1" class="p-5 mb-5 card">
                <div class="date_create">วันที่ {{ datecreatetoday }}</div>

                <a-divider orientation="left">หมายเลขการสั่งซื้อ</a-divider>
                <a-row type="flex">
                    <a-col flex="1 1 500px" :order="1">
                        <a-form-item
                            class="pointer-events-none"
                            :name="['user', 'sellorderTransaction']"
                            :rules="[{ required: true, message: 'กรุณากรอก' }]"
                            label="หมายเลขการสั่งซื้อ"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                style="background: #80808038"
                                v-model:value="
                                    formState.user.sellorderTransaction
                                "
                            />
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" :order="2">
                        <a-form-item
                            :name="['user', 'orderdate']"
                            label="วันที่สั่ง"
                            :rules="[{ required: true, message: 'กรุณากรอก' }]"
                        >
                            <a-date-picker
                                :style="{ color: 'green' }"
                                v-model:value="formState.user.orderdate"
                                value-format="YYYY-MM-DD"
                                :showToday="false"
                                ref="datePickerRef"
                            >
                                <template #renderExtraFooter>
                                    <div class="text-center">
                                        <a-row>
                                            <a-col :span="12">
                                                <a-button
                                                    :size="'small'"
                                                    @click="setYesterday"
                                                    >Yesterday</a-button
                                                ></a-col
                                            >
                                            <a-col :span="12">
                                                <a-button
                                                    :size="'small'"
                                                    @click="setToday"
                                                    >Today</a-button
                                                ></a-col
                                            >
                                        </a-row>
                                    </div>
                                </template>
                            </a-date-picker>
                            <div
                                v-if="!formState.user.orderdate"
                                style="color:red"
                            >
                                {{ messageorderdate }}
                            </div>
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 300px" :order="3">
                        <a-form-item
                            :name="['user', 'ordertransaction']"
                            label="ลำดับ"
                            :rules="[{ required: false, message: 'กรุณากรอก' }]"
                        >
                            <a-input
                                :style="{ color: 'green' }"
                                v-model:value="formState.user.ordertransaction"
                            />
                            <span
                                v-if="!formState.user.ordertransaction"
                                style="color:red"
                                >{{ messageorder }}</span
                            >
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>
            <!-- ================================================ข้อมูลลูกค้า -->
            <div>
                <div v-show="statecurrent == 3" class="p-5 mb-5 card">
                    <a-divider orientation="left">สินค้า</a-divider>
                    <barcode-outlined />
                    <a-row type="flex">
                        <a-col flex="1" :order="1">
                            <!-- <pre>{{formState.listproduct.length}}</pre> -->
                            <!-- <ProductFrom :textbtn="'เพิ่มสินค้า'" :formStates="formState"
                                v-model:value="formState.listproduct"></ProductFrom> -->

                            <ProductFrom
                                :textbtn="'เพิ่มสินค้า'"
                                :formStates="formState"
                                v-model:value="formState.listproduct"
                                :key="update"
                            >
                            </ProductFrom>
                            <div v-if="false">
                                <ListCreateproduct
                                    v-if="formState != undefined"
                                    :formStates="formState"
                                    v-model:value="formState.user.productfrom"
                                ></ListCreateproduct>
                            </div>
                        </a-col>
                    </a-row>
                    <!-- 
                 formState.user.test: {{formState.user.test}} -->
                    <!-- formState.user.productfrom : {{formState.user.productfrom}} -->
                </div>
                <div v-show="statecurrent == 4" class="p-5 mb-5 card">
                    <!-- <a-divider orientation="left">ที่อยู่(1.2)</a-divider> -->
                    <pushpin-outlined class="pb-2" />
                    <a-col flex="1" :order="1">
                        <AddressFrom
                            v-if="statecurrent == 4"
                            :key="formState.updatecomponent"
                            :textbtn="'เพิ่มที่อยู่'"
                            :formStates="formState"
                            v-model:value="formState.user.addressfrom"
                        ></AddressFrom>
                    </a-col>
                </div>
                <div v-if="false">
                    <a-divider orientation="left">ค่าส่งเพิ่มเติม</a-divider>
                    <div class="p-5 mb-5 card">
                        <sisternode-outlined />
                        <a-row type="flex">
                            <a-col flex="1 1 500px" :order="1">
                                <a-form-item
                                    :name="['user', 'sellorderTransaction']"
                                >
                                    <PaidDeliveryMore></PaidDeliveryMore>
                                </a-form-item>
                            </a-col>
                            <a-col flex="1 1 500px" :order="2"> </a-col>
                        </a-row>
                    </div>
                </div>
                <div v-show="statecurrent == 2" class="p-5 mb-5 card">
                    <a-divider orientation="left">การชำระเงิน</a-divider>
                    <dollar-outlined />
                    <a-row type="flex">
                        <a-col flex="1" :order="1">
                            <a-form-item
                                :name="['user', 'sellorderTransaction']"
                            >
                                <Pay
                                    :formStates="formState"
                                    v-model:value="formState.pay"
                                ></Pay>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </div>
            </div>

            <a-form-item>
                <a-row>
                    <a-col :span="2"
                        ><a-button
                            v-show="statecurrent > 0"
                            style="float: right"
                            type="primary"
                            @click="statecurrent--"
                            >กลับ</a-button
                        ></a-col
                    >
                    <a-col :span="21">
                        <a-button
                            :disabled="isordererror"
                            v-if="
                                statecurrent >= 0 &&
                                    statecurrent == 3 &&
                                    formStateStoreCreate.listproduct.length > 0
                            "
                            style="float: right"
                            type="primary"
                            @click="statecurrent++"
                            >ถัดไป</a-button
                        >
                        <a-button
                            :disabled="isordererror"
                            v-else-if="statecurrent >= 0 && statecurrent < 3"
                            style="float: right"
                            type="primary"
                            @click="statecurrent++"
                            >ถัดไป</a-button
                        >
                        <a-button
                            :disabled="isordererror"
                            v-else-if="
                                statecurrent == 4 &&
                                    formStateStoreCreate.listproduct.length > 0
                            "
                            style="float: right"
                            type="primary"
                            html-type="submit"
                            >บันทึก</a-button
                        >
                    </a-col>
                </a-row>
            </a-form-item>
            <!-- <a-form-item :wrapper-col="{ offset: 8, span: 24 }">
                <a-button style="float: right" type="primary" html-type="submit">บันทึก</a-button>
            </a-form-item> -->
        </a-form>
        <!-- เรียกกัน error -->
        <ProductItems v-if="flase"></ProductItems>
        <Address v-if="flase"></Address>
        <!-- เรียกกัน error -->
    </div>
</template>
<script>
import dayjs from "dayjs";
import { FormHelper } from "@/helper/FormHelper.js";
import DatePickerDropdown from "@/components/Input/label/DatePickerDropdown.vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    computed,
    watch
} from "vue";
import { formStateStoreCreate } from "@/store/storebackoffice";
import ProductItems from "../component/ProductItems.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import axios from "axios";
import Storedata from "@/store/Storedata.js";
import { Modal } from "ant-design-vue";
// import Uploadfile from "../component/form/Uploadfile.vue";
import Address from "../component/Address.vue";
import { notification } from "ant-design-vue";
// import Inputnumber from "@/module/Sell/SellCreate/component/form/Inputnumber.vue";
import {
    UserOutlined,
    BarcodeOutlined,
    PushpinOutlined,
    // NumberOutlined,
    SisternodeOutlined,
    DollarOutlined
} from "@ant-design/icons-vue";
import SelectOption from "@/module/CustomerOrder/SellCreate/component/form/SelectOption.vue";
import ListCreateproduct from "@/module/CustomerOrder/SellCreate/component/form/ListCreateproduct.vue";
import AddressFrom from "@/module/CustomerOrder/SellCreate/component/form/AddressFrom.vue";
import PaidDeliveryMore from "@/module/CustomerOrder/SellCreate/component/form/PaidDeliveryMore.vue";

import Pay from "@/module/CustomerOrder/SellCreate/component/form/Pay.vue";
import ProductFrom from "@/module/CustomerOrder/SellCreate/component/form/ProductFrom.vue";
import CreateCustomerfrom from "./form/CreateCustomerfrom.vue";
// import Baseinput from "./form/Baseinput.vue";
import _ from "lodash";
import Contact from "./form/Contact.vue";
import { formStateStore } from "../../../../store/storebackoffice";
import Account from "@/helper/AccountHelper.js";
import {
    ContactModel,
    GetContactModel,
    CustomerModel,
    ProductModel,
    ProductDeliveryAddressModel,
    PaymentModel,
    ProductBirthday
} from "../../../../core/Create/model/CreateModel";
export default defineComponent({
    name: "FromCreate",
    provide: {
        Storedata: Storedata
    },
    mixins: [LocaleMixin],
    components: {
        InputContactNumber,
        DatePickerDropdown,
        Contact,
        ProductItems,
        // Uploadfile,
        Address,
        // Inputnumber,
        UserOutlined,
        SelectOption,
        ListCreateproduct,
        AddressFrom,
        PaidDeliveryMore,
        Pay,
        BarcodeOutlined,
        PushpinOutlined,
        // NumberOutlined,
        SisternodeOutlined,
        DollarOutlined,
        ProductFrom,
        CreateCustomerfrom
        // Baseinput,
    },
    setup() {
        const statecurrent = ref(0);
        const isshowcustomerdesc = ref(false);
        const isordererror = ref(false);

        // window.location.reload()
        const title = [
            "สร้างลูกค้า",
            "สร้างรายการขาย",
            "การชำระเงิน",
            "สินค้า",
            "ที่อยู่"
        ];
        const producttype = ref([]);
        // const gender = ref("1");
        const typelocal = ref("1");
        const update = ref(0);

        const messageorder = ref("*กรอกลำดับงาน");
        const messageorderdate = ref("*กรอกวันที่สั่ง");
        const age = ref(18);
        moment.locale("th");
        const datecreatetoday = moment()
            .add(543, "year")
            .format("LL");
        const layout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 12
            }
        };
        const validateMessages = {
            required: "${label} is required!",
            types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!"
            },
            number: {
                range: "${label} must be between ${min} and ${max}"
            }
        };

        const calculateAge = birthday => {
            if (birthday == null) return (formState.user.customerage = 0);
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.user.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
        };

        const calculateAge_setting = birthday => {
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        const onModalAddressChagne = values => {
            console.log(values);
        };
        onModalAddressChagne;
        const onselectchange = value => {
            formState.user.customername =
                value[0].item.CustomerName +
                " " +
                value[0].item.CustomerLastName;
            formState.user.customertel = value[0].item.CustomerContractNumber;
            formState.user.customeremail = value[0].item.CustomerEmail;
            formState.user.lineid = value[0].item.lineid;
            formState.user.customerAddress = value[0].item.CustomerAddress;
            formState.user.customerbirthday = value[0].item.CustomerBirthDay;
            formState.user.customerintroduction = value[0].item.CustomerDesc;
            formState.user.customerage = calculateAge_setting(
                value[0].item.CustomerBirthDay
            );
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });

        const formState = reactive(formStateStore);
        // const formState = reactive({
        //     updatecomponent: 0,
        //     isshowjason: true,
        //     inside: [],
        //     outside: [],
        //     area: undefined,
        //     userproduct: {
        //         queyan: "1",
        //         professortype: [],
        //         inside: [],
        //         outside: []
        //     },
        //     listproduct: [],
        //     pay: {},
        //     getcontactlist: [],
        //     user: {
        //         isgencode: false,
        //         addressfrom: [],
        //         contactList: [],
        //         customerproductstype: "1",
        //         selectcountry: { selectcountry: "Thailand" },
        //         ordertransaction: "",
        //         customeraddressType: "1"
        //     },
        //     useraddress: {
        //         key: 0,
        //         typeaddress: "1",
        //         selectcountry: "Thailand"
        //     },
        //     listaddress: []
        // });

        const checkhascustomer = () => {
            debugger;
            const searchcustomercode = formState.user.customercode?.trim();
            console.log(searchcustomercode);
            BzbsUser.apigetcheckhascustomer(searchcustomercode)
                .then(res => {
                    if (res.data.has_customercode == true) {
                        openNotification(
                            `มี ${formState.user.customercode} ในระบบแล้ว`
                        );
                        isordererror.value = true;
                    } else {
                        openNotificationsuccess(
                            `${formState.user.customercode} ใช้ได้`
                        );
                        isordererror.value = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const openNotificationsuccess = massage => {
            notification["success"]({
                message: massage
            });
        };
        const openNotification = massage => {
            notification["warning"]({
                message: massage,
                onClose: close
            });
        };
        const openNotificationCustomer = (massage, description) => {
            notification.open({
                message: massage,
                description: description,
                duration: 0
            });
        };
        const close = () => {
            formState.user.ordertransaction = "";
            isordererror.value = false;
        };
        let timeoutId = null; // Variable to store timeout ID

        const is_get_usercustomer = ref(false);
        watch(
            () => formState.user.customercode,
            () => {
                debugger;
                if (formState.user.customercode.length == 6) {
                    BzbsUser.apigetcheckhascustomer(formState.user.customercode)
                        .then(res => {
                            debugger;
                            if (
                                res.data.has_customercode == true &&
                                !is_get_usercustomer.value
                            ) {
                                openNotificationCustomer(
                                    `มี ${formState.user.customercode} ในระบบแล้ว`,
                                    'กรุณา "ค้นหาสมาชิก" >> รหัสลูกค้า >>แล้วเลือกใช้รายการ '
                                );
                            } else {
                                openNotificationsuccess(
                                    `${formState.user.customercode} ใช้ได้`
                                );
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            },
            () => formState.user.ordertransaction,
            () => {
                if (formState.user.ordertransaction) {
                    // console.log(formState.user.customercode);

                    // let debounce_fun = _.debounce(function() {
                    //     if (formState.user.customercode.length >= 6)
                    //         checkhascustomer();
                    // }, 2500);
                    // debounce_fun();
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(() => {
                        if (formState.user.ordertransaction.length >= 4)
                            check_ordertransaction();
                    }, 1000);
                }
            },
            () => formState.user.orderdate,
            () => {
                if (formState.user.orderdate) {
                    messageorderdate.value = "";
                }
            },
            () => formState.user.customeraddressType,
            () => {
                if (formState.user.customeraddressType == "1") {
                    formState.user.selectcountry.selectcountry = "TH";
                }
            },
            () => formState.user.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    )
                ) {
                    formState.user.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    );
                }
            }
        );
        // setTimeout(() => {
        //     formState.user.orderdate = moment().format("YYYY-MM-DD");
        // }, 100);
        onMounted(() => {
            SellOrderTransaction();
            getCustomer();
            queryProductType();
        });
        const SellOrderTransaction = () => {
            BzbsUser.apiSellOrderTransaction(null)
                .then(res => {
                    return (formState.user.sellorderTransaction =
                        res.data[0].SellOrderTransactionID);
                })

                .catch(error => {
                    console.log(error);
                });
        };

        const imageslip = imglist => {
            if (imglist) {
                formState.user.fileList = imglist;
            }
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    window.location = `/selllist?c=${formState.user.customercode}&so=${formState.user.sellorderTransaction}&p=${formState.user.sellorderTransaction}`;
                }
            });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const options = ref([]);
        const handleChange = value => {
            onselectchange(options.value.filter(item => item.value == value));
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const queryProductType = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/producttype").then(res => {
                res?.data[0]?.map(item => {
                    let obj = {};
                    obj.label = item.ProductName;
                    obj.value = item.ProductName;
                    producttype.value.push(obj);
                });
            });
        };

        const getCustomer = () => {
            BzbsUser.apiGetCustomer(null)
                .then(res => {
                    res.data[0].forEach(element => {
                        options.value.push({
                            value:
                                element.CustomerName +
                                " " +
                                element.CustomerLastName,
                            labe:
                                element.CustomerName +
                                " " +
                                element.CustomerLastName,
                            item: element
                        });
                    });
                    return res.data[0];
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.user.ProvinceName = values.provinceName;
            formState.user.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.user.BorderName = values.districtsName;
            formState.user.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.user.DistrictID = values.subdistrictsID;
            formState.user.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.user.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const initcontact = CustomerCode => {
            BzbsUser.apicontact(CustomerCode)
                .then(res => {
                    debugger;
                    const temp_obj = [];
                    res.data?.forEach(element => {
                        element.sellorderTransaction =
                            formState.user.sellorderTransaction;
                        element.customercode = formState.user.customercode;
                        var obj = new GetContactModel(element);
                        console.log("obj", obj);
                        temp_obj.push(obj);
                    });

                    formState.getcontactlist = temp_obj;
                    formState.user.contactList = temp_obj;
                    debugger;
                    calculateAge(formState.user.customerbirthday);
                    console.log(
                        "formState.user.contactlist :",
                        formState.getcontactlist
                    );
                    formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const gencodecustomer = e => {
            console.log("Lucky");
            if (e.CustomerCode != undefined) {
                is_get_usercustomer.value = true;
                initcontact(e.CustomerCode);
            }
            if (e == "") {
                return;
            } else if (e.isgencode) {
                formState.user.isgencode = e.isgencode;
                clearfil(e);
                return;
            }

            formState.user.citypostinter = e.CityPostinter;
            formState.user.provincepostinter = e.ProvincePostinter;
            formState.user.postcodepostinter = e.PostcodePostinter;
            formState.user.contact = e.Contact;
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = e.CustomerName;
            formState.user.customerlastname = e.CustomerLastName;
            formState.user.customerphone = e.CustomerContractNumber;
            formState.user.customerbirthday = e.CustomerBirthDay;
            formState.user.customeremail = e.CustomerEmail;
            formState.user.addressdesc = e.CustomerAddress;
            formState.user.introduction = e.CustomerDesc;
            formState.user.ProvinceID = e.ProvinceID;
            formState.user.ProvinceName = e.ProvinceName;
            formState.user.BorderID = e.BorderID;
            formState.user.BorderName = e.BorderName;
            formState.user.BorderID = e.DistrictId;
            formState.user.DistrictName = e.DistrictName;
            formState.user.DistrictID = e.DistrictID;
            formState.user.PostCode = e.PostCode;
            formState.user.customerlineid = e.LineID;
            formState.user.customerfacebook = e.Facebook;
            formState.user.contactname = e.ContactName;
            formState.user.contact = e.Contact;
            // ImageURL: "",
            formState.user.customergender =
                e.CustomerGender != undefined
                    ? e.CustomerGender.replace(/\s/g, "")
                    : "notspecified";
            formState.user.customernickname = e.CustomerNickName;
            formState.user.customerbirthdaytype = e.CustomerBirthDayType;
            formState.user.customercareer = e.CustomerCareer;
            formState.user.customeraddressType = e.CustomerAddressType;
            formState.user.selectcountry.selectcountry = e.SelectCountry;
            formState.user.customerphoneemergency = e.CustomerPhoneEmergency;
            formState.user.customerphonethai = e.CustomerPhoneThai;
            formState.user.contactimageurl = e.ContactImageUrl;

            update.value = update.value + 1;
            console.log(e);
        };

        const clearfil = e => {
            formState.user.contact = "";
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = "";
            formState.user.customerlastname = "";
            formState.user.customerphone = "";
            formState.user.customerbirthday = "";
            formState.user.customeremail = "";
            formState.user.addressdesc = "";
            formState.user.introduction = "";
            formState.user.ProvinceID = "";
            formState.user.ProvinceName = "";
            formState.user.BorderID = "";
            formState.user.BorderName = "";
            formState.user.BorderID = "";
            formState.user.DistrictID = "";
            formState.user.DistrictName = "";
            formState.user.PostCode = "";
            formState.user.customerlineid = "";
            formState.user.customerfacebook = "";
            formState.user.contactname = "";
            formState.user.contact = "";
            // ImageURL: "",
            formState.user.customergender = "notspecified";
            formState.user.customernickname = "";
            formState.user.customerbirthdaytype = "";
            formState.user.customercareer = "";
            formState.user.customeraddressType = "1";
            formState.user.customerphoneemergency = "";
            formState.user.customerphonethai = "";
            // formState.user.selectcountry.selectcountry = "";
            update.value = update.value + 1;
        };

        const onFinish = async values => {
            // progress.value = true;
            insertdatabase();
            console.log("DATA >> ", values);
            console.log("Success:", values);
        };
        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const checkcondition = () => {
            return true;
            // const result = formState.user.contactList.some(
            //     item => item.contactstartdefault == "true"
            // );
            // if (result) {
            //     return true;
            // } else {
            //     // warning("กรุณาตั้งค่าเริ่มต้นช่องทางการติดต่อ");
            //     // return false;
            // }
        };
        var haserror = ref(false);
        const insertdatabase = async () => {
            const formStatecreate = ref(formStateStoreCreate);
            formState.listproduct = formStatecreate.value.listproduct;
            if (checkcondition()) {
                Storedata.state.loading.ishow = true;
                if (!haserror.value) {
                    await insertcontract();
                    Storedata.state.loading.percent += 14.28;
                }
                if (!haserror.value) {
                    await insertcustomer();
                    Storedata.state.loading.percent += 14.28;
                }
                if (!haserror.value) {
                    await insertcustomeruserproduct();
                    Storedata.state.loading.percent += 14.28;
                }

                if (!haserror.value) {
                    await insertproductbirthday();
                    Storedata.state.loading.percent += 14.28;
                }

                if (!haserror.value) {
                    await insertdeliveryinside();
                    Storedata.state.loading.percent += 14.28;
                }

                if (!haserror.value) {
                    await insertdeliveryinsidecod();
                    Storedata.state.loading.percent += 14.28;
                }
                if (!haserror.value) {
                    await insertdeliveryoutside();
                    Storedata.state.loading.percent += 14.28;
                }
                if (!haserror.value) {
                    await insertproduct();
                    Storedata.state.loading.percent += 14.28;
                }
                // if (!haserror.value) {

                //     await insertproductdeliveryaddress();
                //     Storedata.state.loading.percent += 14.28;
                // }
                if (!haserror.value) {
                    await insertpayment();
                    Storedata.state.loading.percent += 14.28;
                }
                // await this.insertcreatecustomer();
                // await this.insertcreateproduct();
                // await this.insertcreatedeliveryaddress();
                // await this.insertcreatepayment();
                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                    if (!haserror.value) {
                        success();
                    }
                    setTimeout(() => {
                        haserror.value = false;
                    }, 1000);
                }, 2000);
            }
        };

        const insertproductbirthday = async () => {
            try {
                console.log("IN CREATE ");
                console.log(
                    "formStateStore?.listbirstname?.sights :",
                    formStateStore?.listbirstname?.sights
                );

                formState.listproduct.forEach(async (itemlist, index) => {
                    for await (let element of itemlist.listbirstname?.sights) {
                        console.log("element :: ", element);
                        element.productsellorder =
                            formState.user.sellorderTransaction;
                        element.customerorder = formState.user.customercode;
                        element.productkey = index?.toString();

                        let params = new ProductBirthday(element);

                        console.log("param", params);

                        await BzbsUser.apiupdateproductbirthday(params)
                            .then(res => {
                                console.log(res);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };

        const insertcontract = async () => {
            try {
                let params = [];
                await formState.user.contactList.forEach((element, index) => {
                    element.productsellorder =
                        formState.user.sellorderTransaction;
                    element.customerorder = formState.user.customercode;
                    element.key = (index + 1).toString();
                    var obj = new ContactModel(element);
                    console.log("obj", obj);
                    params.push(obj);
                });
                console.log("param", params);

                // let slip_url = [];
                var index_uploadimage = 0;
                for await (let items of params) {
                    if (
                        items.ContactImage &&
                        items.ContactImage[0].preview != undefined
                    ) {
                        var uploadparam = {};
                        uploadparam = {
                            filename:
                                formState.user.customercode +
                                "_" +
                                index_uploadimage +
                                ".jpg",
                            base64: items.ContactImage[0].preview,
                            folder: "contact"
                        };
                        await BzbsUser.apiuploadimage(uploadparam)
                            .then(res => {
                                params[index_uploadimage]["ContactImage"] = "";
                                params[index_uploadimage]["ContactImageUrl"] =
                                    "";
                                params[index_uploadimage]["ContactImage"] =
                                    uploadparam.filename;
                                params[index_uploadimage]["ContactImageUrl"] =
                                    res.data.url;
                                console.log(res.data);
                                index_uploadimage++;
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    } else {
                        index_uploadimage++;
                    }
                }
                for await (let obj of params) {
                    await BzbsUser.apiupdatecontact(obj)
                        .then(res => {
                            console.log(res);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };
        const insertcustomer = async () => {
            try {
                if (
                    formState.user.isgencode ||
                    formState.user?.customercode?.length > 0
                ) {
                    let params = new CustomerModel(formState.user);

                    console.log("param", params);

                    await BzbsUser.apiPostCreateCustomer(params)
                        .then(res => {
                            if (res.data.status == 403) {
                                warning(res.data.message);
                                haserror.value = true;
                                Storedata.state.loading.ishow = false;
                            }
                            console.log(res);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };
        const insertcustomeruserproduct = async () => {
            try {
                if (formState.user.isgencode) {
                    formState.userproduct.sellorderTransaction =
                        formState.user.sellorderTransaction;
                    let params = new CustomerModel(formState.userproduct);

                    console.log("param", params);

                    await BzbsUser.apiPostCreateCustomerUserProduct(params)
                        .then(res => {
                            if (res.data.status == 403) {
                                warning(res.data.message);
                                haserror.value = true;
                                Storedata.state.loading.ishow = false;
                            }
                            console.log(res);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };

        const insertdeliveryinside = async () => {
            try {
                //insert inside จัดส่ง ในประเทศ
                if (formState.inside.length <= 0) return;
                // var inside = formState.inside;
                // var index_inside = 0;
                // for await (let items of inside) {
                //     if (items.slipimage[0].preview != undefined) {
                //         var uploadparam_inside = {};
                //         uploadparam_inside = {
                //             filename:
                //                 formState.user.customercode +
                //                 "_" +
                //                 index_inside +
                //                 ".jpg",
                //             base64: items.slipimage[0].preview,
                //             folder: "inside"
                //         };
                //         await BzbsUser.apiuploadimage(uploadparam_inside)
                //             .then(res => {
                //                 formState.inside[index_inside]["slipiurl"] = "";
                //                 formState.inside[index_inside]["slipiurl"] =
                //                     res.data.url;
                //                 console.log(res.data);
                //                 index_inside++;
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             });
                //     }
                // }
                const index = ref(0);
                for await (let inside_obj of formState.listproduct) {
                    if (inside_obj.inside != undefined) {
                        for await (let items of inside_obj.inside) {
                            if (items.price == null) return;
                            var param_inside = {
                                ProductSellOrder:
                                    formState.user.sellorderTransaction,
                                CustomerOrder: formState.user.customercode,
                                DeliveryType: "inside",
                                Price: items.price,
                                Id: _.toString(items.id),
                                Introduction: items.introduction,
                                SlipImageURL: items.slipiurl,
                                ProductKey: index.value?.toString()
                            };
                            index.value++;
                            await BzbsUser.apicreatedelivery(param_inside)
                                .then(res => {
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        const insertdeliveryinsidecod = async () => {
            try {
                //insert inside จัดส่ง ในประเทศ
                if (formState.insidecod.length <= 0) return;
                // var inside = formState.inside;
                // var index_inside = 0;
                // for await (let items of inside) {
                //     if (items.slipimage[0].preview != undefined) {
                //         var uploadparam_inside = {};
                //         uploadparam_inside = {
                //             filename:
                //                 formState.user.customercode +
                //                 "_" +
                //                 index_inside +
                //                 ".jpg",
                //             base64: items.slipimage[0].preview,
                //             folder: "inside"
                //         };
                //         await BzbsUser.apiuploadimage(uploadparam_inside)
                //             .then(res => {
                //                 formState.inside[index_inside]["slipiurl"] = "";
                //                 formState.inside[index_inside]["slipiurl"] =
                //                     res.data.url;
                //                 console.log(res.data);
                //                 index_inside++;
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             });
                //     }
                // }
                const index = ref(0);
                for await (let inside_obj of formState.listproduct) {
                    if (inside_obj.insidecod != undefined) {
                        for await (let items of inside_obj.insidecod) {
                            if (items.price == null) return;
                            var param_insidecod = {
                                ProductSellOrder:
                                    formState.user.sellorderTransaction,
                                CustomerOrder: formState.user.customercode,
                                DeliveryType: "insidecod",
                                Price: items.price,
                                Id: _.toString(items.id),
                                Introduction: items.introduction,
                                SlipImageURL: items.slipiurl,
                                ProductKey: index.value?.toString()
                            };
                            index.value++;
                            await BzbsUser.apicreatedelivery(param_insidecod)
                                .then(res => {
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        const insertdeliveryoutside = async () => {
            try {
                //insert outside จัดส่ง ในประเทศ
                if (formState.outside.length <= 0) return;
                // var outside = formState.outside;
                // var index_outside = 0;
                // for await (let items of outside) {
                //     if (items.slipimage[0].preview != undefined) {
                //         var uploadparam_outside = {};
                //         uploadparam_outside = {
                //             filename:
                //                 formState.user.customercode +
                //                 "_" +
                //                 index_outside +
                //                 ".jpg",
                //             base64: items.slipimage[0].preview,
                //             folder: "outside"
                //         };
                //         await BzbsUser.apiuploadimage(uploadparam_outside)
                //             .then(res => {
                //                 formState.outside[index_outside]["slipiurl"] = "";
                //                 formState.outside[index_outside]["slipiurl"] =
                //                     res.data.url;
                //                 console.log(res.data);
                //                 index_outside++;
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             });
                //     }
                // }
                const index = ref(0);
                for await (let outside_obj of formState.listproduct) {
                    if (outside_obj.inside != undefined) {
                        for await (let items of outside_obj.outside) {
                            if (items.price == null) return;
                            var param_outside = {
                                ProductSellOrder:
                                    formState.user.sellorderTransaction,
                                CustomerOrder: formState.user.customercode,
                                DeliveryType: "outside",
                                Price: items.price,
                                Id: _.toString(items.id),
                                Introduction: items.introduction,
                                SlipImageURL: items.slipiurl,
                                ProductKey: index.value?.toString()
                            };
                            // if(param_outside.ProductKey == null) return
                            index.value++;
                            await BzbsUser.apicreatedelivery(param_outside)
                                .then(res => {
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        const insertproduct = async () => {
            try {
                const formStatecreate = formStateStoreCreate;
                var index = 0;

                for await (let listproduct_items of formState.listproduct) {
                    if (listproduct_items.productyanimage != undefined) {
                        var queyanimage = listproduct_items.productyanimage;
                        for await (let items of queyanimage) {
                            if (items.preview != undefined) {
                                var uploadparam = {};
                                uploadparam = {
                                    filename:
                                        formState.user.customercode +
                                        "_" +
                                        Math.floor(Date.now() / 1000) +
                                        ".jpg",
                                    base64: items.preview,
                                    folder: "product"
                                };
                                await BzbsUser.apiuploadimage(uploadparam)
                                    .then(res => {
                                        listproduct_items["productyanimage"] =
                                            "";
                                        listproduct_items["productyanimage"] =
                                            res.data.url;
                                        console.log(res.data);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        }
                    }

                    if (listproduct_items.queyanimage != undefined) {
                        var queyanimage = listproduct_items.queyanimage;
                        for await (let items of queyanimage) {
                            if (items.preview != undefined) {
                                var uploadparam = {};
                                uploadparam = {
                                    filename:
                                        formState.user.customercode +
                                        "_" +
                                        Math.floor(Date.now() / 1000) +
                                        ".jpg",
                                    base64: items.preview,
                                    folder: "queyan"
                                };
                                await BzbsUser.apiuploadimage(uploadparam)
                                    .then(res => {
                                        listproduct_items["queyanimage"] = "";
                                        listproduct_items["queyanimage"] =
                                            res.data.url;
                                        console.log(res.data);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        }
                    }
                    if (listproduct_items.productlogoimage?.length > 0) {
                        for await (let element of listproduct_items.productlogoimage) {
                            // listproduct_items["productlogoimage"] = [];
                            var items = element;

                            if (items.preview != undefined) {
                                var uploadparam = {};
                                uploadparam = {
                                    filename:
                                        formState.user.customercode +
                                        "_" +
                                        Math.floor(Date.now() / 1000) +
                                        ".jpg",
                                    base64: items.preview,
                                    folder: "productlogoimage"
                                };

                                await BzbsUser.apiuploadimage(uploadparam)
                                    .then(async res => {
                                        listproduct_items.productlogoimage = await replaceItemByUUID(
                                            listproduct_items.productlogoimage,
                                            element.uid,
                                            { url: res.data.url }
                                        );
                                        console.log(res.data);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        }
                    } else if (listproduct_items.productlogoimage == "") {
                        listproduct_items["productlogoimage"] = [];
                    }

                    listproduct_items.productsellorder =
                        formState.user.sellorderTransaction;
                    listproduct_items.customerorder =
                        formState.user.customercode;
                    listproduct_items.orderdate = formState.user.orderdate;
                    listproduct_items.ordertransaction =
                        formState.user.ordertransaction;

                    listproduct_items.productjson = JSON.stringify(
                        formState.listproduct
                    );
                    listproduct_items.productkey = index;
                    listproduct_items.productresponsibleperson = JSON.stringify(
                        Account.getCacheUserBackoffice()
                    );

                    listproduct_items.customerlineid =
                        listproduct_items.customerlineid;
                    listproduct_items.customerfacebook =
                        listproduct_items.customerfacebook;
                    listproduct_items.customerphone =
                        listproduct_items.customerphone;
                    listproduct_items.customerphoneemergency =
                        listproduct_items.customerphoneemergency ||
                        listproduct_items.p_customerphoneemergency;
                    listproduct_items.customerdesc =
                        listproduct_items.customerdesc ||
                        listproduct_items.p_customerdesc;
                    listproduct_items.customeraddressType =
                        listproduct_items.customeraddresstype ||
                        listproduct_items.p_customeraddresstype ||
                        listproduct_items.customeraddressType;
                    listproduct_items.CustomerSelectCountry = listproduct_items.selectcountry
                        ? listproduct_items.selectcountry.selectcountry
                        : "" || listproduct_items.customerselectcountry;
                    listproduct_items.CustomerSelectcountryCode = listproduct_items.selectcountry
                        ? listproduct_items.selectcountry.selectcountrycode
                        : "" || listproduct_items.customerselectcountrycode;
                    listproduct_items.CustomerInternationalPhone =
                        listproduct_items.customerinternationalphone;

                    if (listproduct_items.stockuuid) {
                        //update stock

                        var stock_params = {
                            Stockuuid: listproduct_items.stockuuid,
                            StockStatus: "used",
                            StockUsedBy:
                                listproduct_items.productresponsibleperson,
                            UsedByProductSellOrder:
                                listproduct_items.productsellorder,
                            UsedByCustomerOrder:
                                listproduct_items.customerorder,
                            UsedByOrderTransaction:
                                listproduct_items.ordertransaction,
                            UsedByProductKey: listproduct_items.productkey
                        };

                        console.log("stock_params ", stock_params);

                        await BzbsUser.apiupdatestock(stock_params)
                            .then(async res => {
                                console.log(res);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                    let params = new ProductModel(listproduct_items);

                    console.log("param", params);

                    await BzbsUser.apiproduct(params)
                        .then(res => {
                            index++;
                            console.log(res);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                // var listproduct_items = {}
                // listproduct_items.productsellorder =
                //     formState.user.sellorderTransaction;
                // listproduct_items.customerorder = formState.user.customercode;
                // listproduct_items.orderdate = formState.user.orderdate;
                // listproduct_items.ordertransaction = formState.user.ordertransaction;

                // // listproduct_items.productjson = JSON.stringify(formState.listproduct);
                // listproduct_items.productkey = "0"

                // let params = new ProductModel(listproduct_items);

                // console.log("param", params);

                // await BzbsUser.apiproduct(params)
                //     .then(res => {
                //         index++
                //         console.log(res);
                //     })
                //     .catch(error => {
                //         console.log(error);
                //     });
            } catch (error) {
                console.log(error);
            }
        };
        const insertproductdeliveryaddress = async () => {
            try {
                var index = 0;

                for await (let listproductdeliveryaddress_items of formState
                    .user.addressfrom) {
                    listproductdeliveryaddress_items.addresskey = index?.toString();
                    listproductdeliveryaddress_items.productsellorder =
                        formState.user.sellorderTransaction;
                    listproductdeliveryaddress_items.customerorder =
                        formState.user.customercode;

                    var phonecode = "";
                    if (listproductdeliveryaddress_items?.phonecode) {
                        phonecode = listproductdeliveryaddress_items?.phonecode;
                    } else if (
                        listproductdeliveryaddress_items.phonecode &&
                        listproductdeliveryaddress_items.phonecode[0].label
                    ) {
                        phonecode =
                            listproductdeliveryaddress_items.phonecode[0].label;
                    }
                    if (phonecode) {
                        listproductdeliveryaddress_items.phonecode = phonecode;
                    }
                    let params = new ProductDeliveryAddressModel(
                        listproductdeliveryaddress_items
                    );

                    console.log("param", params);

                    await BzbsUser.apiproductdelivery(params)
                        .then(res => {
                            index++;
                            console.log(res);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };
        //หน้าสร้าง
        const insertpayment = async () => {
            console.log("formState.pay", formState.pay);
            var indeximage = 0;

            await Promise.all(
                formState.pay?.listpay?.map(async element => {
                    if (element.slipimagearray != undefined) {
                        element["slipimageurl"] = [];
                        for await (let items of element.slipimagearray) {
                            indeximage++;
                            if (items.preview != undefined) {
                                var uploadparam = {
                                    filename:
                                        formState.user.customercode +
                                        "_" +
                                        indeximage +
                                        "_" +
                                        Math.floor(Date.now() / 1000) +
                                        ".jpg",
                                    base64: items.preview,
                                    folder: "payment"
                                };
                                const res = await BzbsUser.apiuploadimage(
                                    uploadparam
                                );
                                element["slipimageurl"].push(res.data.url);
                                console.log(res.data);
                            } else {
                                if (items.url) {
                                    element["slipimageurl"].push(items.url);
                                }
                            }
                        }
                    }
                })
            );

            formState.pay?.listpay?.forEach(async (element, index) => {
                element.productsellorder = formState.user.sellorderTransaction;

                element.customerorder = formState.user.customercode;

                element["slipimageurl"] = JSON.stringify(
                    element["slipimageurl"]
                );

                let params = new PaymentModel(element);
                if (params.SelectCountry) {
                    params.SelectCountry = JSON.stringify(params.SelectCountry);
                }
                if (params.SlipImage) {
                    params.SlipImage = JSON.stringify(params.SlipImage);
                }

                params.ProductKey = index;

                await BzbsUser.apiupdatepayment(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        };
        // const insertpayment = async () => {
        //     try {

        //         var indeximage = 0
        //         formState.pay["slipimageurl"] = []
        //         if (formState.pay.slipimagearray != undefined) {
        //             for await (let items of formState.pay.slipimagearray) {
        //                 if (items.preview != undefined) {
        //                     var uploadparam = {};
        //                     indeximage++
        //                     uploadparam = {
        //                         filename: formState.user.customercode + '_' + indeximage + '_' + Math.floor(Date.now() / 1000) + ".jpg",
        //                         base64: items.preview,
        //                         folder: "payment"
        //                     };
        //                     await BzbsUser.apiuploadimage(uploadparam)
        //                         .then(res => {

        //                             formState.pay["slipimageurl"].push(res.data.url);
        //                             console.log(res.data);
        //                         })
        //                         .catch(error => {
        //                             console.log(error);
        //                         });
        //                 }
        //             }
        //         }
        //         formState.pay.productsellorder =
        //             formState.user.sellorderTransaction;
        //         formState.pay.customerorder = formState.user.customercode;

        //         let params = new PaymentModel(formState.pay);
        //         if (params.SlipImage) {
        //             params.SlipImage = JSON.stringify(params.SlipImage)
        //         }
        //         console.log("param", params);
        //         if (params.SelectCountry) {

        //             params.SelectCountry = JSON.stringify(params.SelectCountry)
        //         }
        //         await BzbsUser.apipayment(params)
        //             .then(res => {
        //                 console.log(res);
        //             })
        //             .catch(error => {
        //                 console.log(error);
        //             });
        //     } catch (error) {
        //         console.log(error)
        //     }
        // };
        const timer = ref(null);
        const check_ordertransaction = () => {
            if (formState.user.ordertransaction?.trim()?.length == 0) {
                messageorder.value = "";
                return;
            }
            clearTimeout(timer.value); // Clear any existing timer

            timer.value = setTimeout(async () => {
                // Call your API here
                var param = {
                    ordertransaction: formState.user.ordertransaction?.trim()
                };
                if (param.ordertransaction == "") return;
                await BzbsUser.apicheckordertransaction(param)
                    .then(res => {
                        if (res.data.count >= 1) {
                            openNotification("ลำดับงานนี้มีแล้วในระบบ");
                            messageorder.value = "*ลำดับงานนี้มีแล้วในระบบ";
                            isordererror.value = true;
                        } else {
                            openNotificationsuccess(
                                `${formState.user.ordertransaction} ใช้ได้`
                            );
                            messageorder.value = "";
                            isordererror.value = false;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }, 1000); // Delay the API call for 2 seconds (adjust as needed)
        };
        const datePickerRef = ref(null);
        const setYesterday = () => {
            const yesterday = dayjs().subtract(1, "day");
            formState.user.orderdate = yesterday;
        };
        const setToday = () => {
            const today = dayjs();
            formState.user.orderdate = today;
        };

        return {
            datePickerRef,
            insertproduct,
            insertproductdeliveryaddress,
            gencodecustomer,
            title,
            formState,
            onFinish,
            layout,
            validateMessages,
            SellOrderTransaction,
            onselectchange,
            calculateAge,
            options,
            listcodephone,
            handleChange,
            handleBlur,
            handleFocus,
            filterOption,
            onFinishFailed,
            queryProductType,
            producttype,
            onModalAddressChagne,
            publishedBooksMessage,
            getallproduct,
            success,
            imageslip,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            calculateAge_setting,
            datecreatetoday,
            typelocal,
            age,
            update,
            insertdatabase,
            insertcontract,
            insertcustomer,
            errorInfo: FormHelper.genErrorInfo(7),
            statecurrent,
            isshowcustomerdesc,
            check_ordertransaction,
            messageorder,
            isordererror,
            messageorderdate,
            formStateStoreCreate,
            setYesterday,
            setToday
        };
    }
    // computed: {
    //     createproduct: function() {
    //         return Storedata.state.createproduct;
    //     }
    // }
});
</script>
<style lang="scss">
.ant-row {
    flex-direction: row;
}

.date_create {
    display: flex;
    flex-flow: row-reverse;
}

.wrapper_createnew {
    display: flex;
    align-items: center;
}

.wrapper-icon-create {
    position: relative;
    top: 26px;
    left: 30px;
}

// .ant-steps-item-icon {
//     background: #b99041 !important;
// }
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #b99041 !important;
}

.ant-steps-item-icon {
    background-color: #fff;
    border-color: #b99041 !important;
}
</style>
