<template>
    <div class="">
        <div class="overflow-hidden rounded wrapper-drag-mobile" style="max-width: 450px;">
            <draggable class="list-group" tag="ul" :list="list" v-bind="dragOptions" @start="isDragging = true"
                @end="isDragging = false" @change="log">
                <transition-group type="transition" name="flip-list">
                    <li class="p-1 m-1 bg-gray-200 rounded-md list-group-item" v-for="(element, index) in list"
                        :key="element.order">
                        <div class="order-items">
                            ({{ index + 1 }})
                        </div>
                        <a-row style="justify-content: end;">

                            <div v-if="false" :disable="true" class="text-right pr-3"
                                @click="edit(element?.detail.type, index)">
                                <setting-outlined />
                            </div>
                            <div class="text-right" @click="onremove(element?.detail.type, index)">
                                <close-outlined />
                            </div>
                        </a-row>
                        <i :class="
                            element.fixed
                                ? 'fa fa-anchor'
                                : 'glyphicon glyphicon-pushpin'
                        " @click="element.fixed = !element.fixed" aria-hidden="true"></i>

                        <div v-if="element.detail.type == 'youtube'" class="bg-gray-200 rounded-md list-group-item">
                            <div class="relative">
                                <div class="absolute left-0" style="top: -20px;">
                                    <a-image :width="20"
                                        src="https://live.staticflickr.com/7132/7749081576_f256dba668_w.jpg" />
                                </div>
                            </div>
                            <iframe v-if="element.detail?.value" width="100%" height="250px" :src="
                                'https://www.youtube.com/embed/' +
                                element.detail.value
                            ">
                            </iframe>
                        </div>
                        <!-- {{element.detail.type }} -->
                        <div v-if="element.detail.type == 'image'">
                            <!-- {{element.detail.value}} -->
                            <div class="relative">
                                <div class="absolute left-0" style="top: -20px;">
                                    <a-image :width="20"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl2AsDP9Vp7qnSymCWlVG2bCKXzsurHeAU4A-fi6qCKRLXH7y9__xu-EuLfyjbEw9x26I&usqp=CAU" />
                                </div>
                            </div>
                            <img :src="element.detail.value" class="w-80 m-auto" />
                        </div>
                        <div v-if="element.detail.type == 'addbutton'"> 
                            <div v-if="element.detail.value.lineid?.id">
                                <div class="relative">
                                    <div class="absolute left-0" style="top: -20px;">
                                        <a-image :width="20"
                                            src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                                    </div>
                                </div>

                                <img src=" https://namahayan.blob.core.windows.net/assets/Line_btn.gif"
                                    class="rounded-md w-80 m-auto" />

                            </div>
                            <div v-if="element.detail.value.SalePageFacebookMessenger">
                                <div class="relative">
                                    <div class="absolute left-0" style="top: -20px;">
                                        <a-image :width="20"
                                            src="https://namahayan.blob.core.windows.net/assets/FB." />
                                    </div>
                                </div>

                                <img src="https://namahayan.blob.core.windows.net/assets/FB_btn.gif"
                                    class="rounded-md w-80 m-auto" />

                            </div>
                            <div v-if="element.detail.value.tel">
                                <div class="relative">
                                    <div class="absolute left-0" style="top: -20px;">
                                        <a-image :width="20"
                                            src=" https://namahayan.blob.core.windows.net/assets/phone_PNG49006.png" />
                                    </div>
                                </div>
                                <img src=" https://namahayan.blob.core.windows.net/assets/tel-button.gif"
                                    class="rounded-md w-80 m-auto" />

                            </div>
                        </div>
                        <div v-if="element.detail.type == 'slideimage'">
                            <div class="relative">
                                <div class="absolute left-0" style="top: -20px;">
                                    <a-image :width="20" src="https://i.stack.imgur.com/OHOoU.png" />
                                </div>
                            </div>
                            <div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in element.detail.value" :key="slide">
                                        <img :src="slide.value" class="w-80 m-auto p-1" />
                                    </slide>

                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                        </div>
                        <div v-if="element.detail.type == 'video'">

                            <div class="relative">
                                <div class="absolute left-0" style="top: -20px;">
                                    <a-image :width="20"
                                        src="https://cdn0.iconfinder.com/data/icons/social-media-2415/64/37_Upload_video-512.png" />
                                </div>
                            </div>
                            <video width="320" height="240" controls loop autoplay class="m-auto">
                                <source :src="element.detail.value">
                                <source :src="element.detail.value">
                            </video>
                        </div>
                        <div v-if="element.detail.type == 'message'" class="w-50">
                            <div class="relative">
                                <div class="absolute left-0" style="top: -30px;">
                                    <a-image :width="20"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFJnDjfGRyZDQ-w7G6J8Us37Enxocn2Hy-b5VvUtSAlLxb2bbXcc4506SaWM-2t3H9vIE&usqp=CAU" />
                                </div>
                            </div>
                            <p class="wrapper-detail p-5" v-html="element.detail.value"></p>
                        </div>
                        <div v-if="element.detail.type == 'form'" class="w-50">
                            <div class="relative">
                                <div class="absolute left-0" style="top: -20px;">
                                    <a-image :width="20"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukNsPtR7DuF9CrL_Ww_ZSnjvOG9I0ibcNTzoVS5RNZYrTC8w8iqwQmF_CU9d2QH8XGMg&usqp=CAU" />
                                </div>
                            </div>
                            <div class="text-center pt-2 pr-2 pl-2">{{ element.detail.value.title }}</div>
                            <div v-for="items in element.detail.value.option.items" :key="items">
                                <a-radio-group v-if="items.type == 1" v-model:value="value">
                                    <a-radio :style="radioStyle" :value="items">{{ items.first
                                    }}({{ items.last }})
                                    </a-radio>
                                </a-radio-group>
                            </div>
                            <div>
                                <div v-for="items in element.detail.value.option.items" :key="items">
                                    <div v-if="items.type == 2" class="p-2">
                                        <h1>ชื่อนามสกุล</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="ชื่อนามสกุล" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 3" class="p-2">
                                        <h1>อีเมลย์</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="อีเมลย์" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 4" class="p-2">
                                        <h1>เบอร์โทร</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="เบอร์โทร" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 5" class="p-2">
                                        <h1>LINE</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="LINE" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 6" class="p-2">
                                        <h1>ที่อยู่จัดส่งสินค้า</h1>
                                        <div>
                                            <a-textarea :disabled="true" placeholder="ที่อยู่จัดส่งสินค้า" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 7" class="p-2">
                                        <h1>ยอดชำระ</h1>
                                        <div>
                                            <a-textarea :disabled="true" placeholder="ยอดชำระ" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 8" class="p-2">
                                        <h1>{{ items.custom }}</h1>
                                        <div>
                                            <a-textarea :disabled="true" :placeholder="items.custom" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <rawDisplayer :value="list" class="w-100" v-if="false" />
        <!-- <pre>{{ list }}</pre> -->
    </div>
</template>

<script>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { VueDraggableNext } from "vue-draggable-next";
import rawDisplayer from "./rawDisplay.vue";
import { reactive, ref, createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import {
    CloseOutlined,
    SettingOutlined,
} from "@ant-design/icons-vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { objsalepage, objsalepagethank, objsalepagethankform, current_tab } from '@/store/store'

export default {
    name: "transition-example",
    display: "Transition",
    order: 6,
    components: {
        draggable: VueDraggableNext,
        CloseOutlined,
        SettingOutlined,
        rawDisplayer,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        const use = ref()

        console.log(current_tab)
        if (current_tab.value == '2') {
            use.value = objsalepagethank
        } else if (current_tab.value == '1') {
            use.value = objsalepage
        } else if (current_tab.value == '3') {
            use.value = objsalepagethankform
        }



        const value = ref(1)
        const radioStyle = reactive({
            display: 'flex',
            height: '30px',
            lineHeight: '30px',
        });
        var list = []


        console.log(objsalepagethank)
        list = use.value
        console.log("List", list)

        this.$emit("onlist", list)
        return {
            radioStyle,
            value,
            list
        };
    },
    mounted() {
        console.log(this.list);
    },
    watch: {
        list(newQuestion, oldQuestion) {
            if (newQuestion.indexOf('?') > -1) {
                console.log(oldQuestion)
                console.log("list", this.list)
                this.$emit("onlist", this.list)
            }
        }
    },
    methods: {
        onremove(type, index) {
            const that = this
            Modal.confirm({
                title: 'คุณต้องการลบ?',
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode('div', {
                    style: 'color:red;',
                }, ''),

                onOk() {
                    that.list.splice(index, 1);
                    that.use?.value[type]?.splice(index, 1);
                    that.$emit("onlist", that.list)
                },

                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        },
        sort() {
            this.list = this.list.sort((a, b) => a.order - b.order);
        },
        log(event) {
            console.log(event)
            console.log("list", this.list)
            this.$emit("onlist", this.list)

        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
};
</script>

<style>
.button {
    margin-top: 35px;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.btn {
    @apply font-bold py-2 px-4 rounded;
}

.btn-blue {
    @apply bg-blue-500 text-white;
}

.btn-blue:hover {
    @apply bg-blue-700;
}

.carousel__next,
.carousel__prev {
    background: radial-gradient(circle, #e1c724 10%, #e1c724 90%);
    margin: 0px 20px;
}

.carousel__pagination-button {
    background-color: #e1c724;
}

.carousel__pagination-button--active {
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .wrapper-drag-mobile {
        padding: 0.25rem !important;
    }
}

.order-items {
    position: absolute;
    display: flex;
    align-items: center;
    height: auto;
    z-index: 1;
    text-indent: 30px;
}

.ql-align-center {
    text-align: center;
}
</style>
