<template>
    <div>
        <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
        <a-modal
            v-model:visible="visible"
            title="เพิ่มแบบฟอร์ม"
            width="1400px"
            @ok="handleOk"
            @cancel="close()"
        >
            <a-form
                :layout="formState.layout"
                :model="formState"
                v-bind="formItemLayout"
            >
                <!-- <pre>
        {{ onEdit }}
      </pre>
        =========
        <pre>{{ dynamicValidateForm.items }}</pre> -->
                <a-form-item label="หัวข้อ">
                    <a-input
                        v-model:value="formState.title"
                        placeholder="input placeholder"
                    />
                </a-form-item>
                <!-- <a-button type="primary">{{formState.btnname}}</a-button> -->
                <a-form-item label="ชื่อปุ่ม (ยืนยัน)">
                    <a-input
                        v-model:value="formState.btnname"
                        placeholder="ชื่อปุ่ม submit"
                    >
                        <template #addonBefore>
                            <PlayCircleOutlined />
                        </template>
                    </a-input>
                    <div class="p-2">
                        <a-switch
                            v-model:checked="formState.isbtnpayment"
                            checked-children="เป็นปุ่มชำระเงิน (payment)"
                            un-checked-children="ไม่เป็นปุ่มชำระเงิน"
                        />
                    </div>
                    <a-card
                        v-show="formState.isbtnpayment"
                        title="ตั้งค่า Beam"
                    >
                        <template #extra
                            ><a
                                href="https://lighthouse.beamcheckout.com/organization/namahayanb/transactions"
                                target="_blank"
                                >https://lighthouse.beamcheckout.com</a
                            ></template
                        >
                        <div class="p-2">
                            <a-form-item
                                label="ลิ้งขากลับหลังจากลูกค้าชำระเงิน"
                            >
                                <a-input
                                    v-model:value="formState.redirecturlpayment"
                                    placeholder="ลิ้งขากลับหลังจากลูกค้าชำระเงิน"
                                >
                                    <template #addonBefore>
                                        <LinkOutlined />
                                    </template>
                                </a-input>
                            </a-form-item>
                        </div>
                        <div class="p-2">
                            <a-form-item
                                label="รายละเอียดแสดงในระบบ Beam (ช่อง Notes)"
                            >
                                <a-textarea
                                    v-model:value="formState.descriptionpayment"
                                    placeholder="รายละเอียดแสดงในระบบ Beam"
                                    allow-clear
                                />
                            </a-form-item>
                        </div>
                        <div class="p-2">
                            <a-form-item label="Line Token ในระบบ Beam">
                                <a-textarea
                                    v-model:value="formState.linetokenbeam"
                                    placeholder="line token"
                                    allow-clear
                                />
                            </a-form-item>
                        </div>
                    </a-card>
                </a-form-item> 
                <a-card 
                    title="ช่องทางการชำระเงิน (salepage)"
                >
                    <div class="p-2">
                        <a-checkbox-group
                            v-model:value="formState.type_of_payment"
                            :options="plainOptions"
                        />
                        <a-divider />
                    </div>
                    <div v-if="formState.type_of_payment?.includes('cod')">
                        <span class="p-1">
                            ค่าบริการจัดส่ง (เก็บเงินปลายทาง)</span
                        >
                        <a-input-number
                            v-model:value="formState.pricecod"
                            :formatter="
                                value =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                            "
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                        <span class="p-1">บาท</span>
                    </div>
                    <div class="p-2">
                        <a-select
                            v-model:value="formState.transfermoney"
                            mode="multiple"
                            style="width: 100%"
                            placeholder="เลือกช่องทางการชำระ"
                            :max-tag-text-length="maxTagTextLength"
                            :options="options"
                        ></a-select>
                    </div>
                </a-card> 
                <a-space
                    v-for="(user, index) in dynamicValidateForm.items"
                    :key="user.id"
                    style="display: flex; margin-bottom: 8px"
                    align="baseline"
                >
                    <!-- <pre>{{user}}</pre> -->

                    <a-space v-if="user.type == 1" class="pt-3">
                        <a-card
                            class="bg-campaign"
                            style="width: 1200px;
                                    margin: 50px;"
                        >
                            <a-divider>รายละเอียดแคมเปญ</a-divider>
                            <a-form-item
                                :label="'SKU ที่ ' + parseInt(index + 1)"
                                :name="['items', index, 'sku']"
                                :rules="{
                                    required: true,
                                    message: 'sku'
                                }"
                            >
                                <a-textarea
                                    v-model:value="user.sku"
                                    placeholder="SKU ห้ามซ้ำกัน"
                                />
                            </a-form-item>
                            <a-form-item
                                :label="'หัวข้อที่ ' + parseInt(index + 1)"
                                :name="['items', index, 'title']"
                                :rules="{
                                    required: true,
                                    message: 'หัวข้อ'
                                }"
                            >
                                <a-textarea
                                    v-model:value="user.title"
                                    placeholder="หัวข้อ"
                                />
                            </a-form-item>
                            <a-form-item
                                v-if="false"
                                :label="'เนื้อหาที่  ' + parseInt(index + 1)"
                                :name="['items', index, 'detail']"
                                :rules="{
                                    required: true,
                                    message: 'เนื้อหา'
                                }"
                            >
                                <a-textarea
                                    v-model:value="user.detail"
                                    placeholder="เนื้อหา"
                                />
                            </a-form-item>
                            <a-form-item
                                :label="'ราคาที่  ' + parseInt(index + 1)"
                                :name="['items', index, 'price']"
                                :rules="{
                                    required: true,
                                    message: 'กรอกราคา'
                                }"
                            >
                                <a-input
                                    v-model:value="user.price"
                                    placeholder="ราคา"
                                />
                            </a-form-item>
                            <!-- <pre>{{ user.fileList }}</pre> -->
                            <UploadFile
                                :sku="user.sku"
                                :initimage="user.fileList"
                                :sizeupimage="1"
                                @fileList="onchangeimages($event, index)"
                                @deletefile="ondeletefile($event, index)"
                            ></UploadFile>
                            <a-divider
                                >ช่องให้ลูกค้ากรอกข้อมูล(ex.แผ่นทอง)</a-divider
                            >
                            <a-form-item
                                :name="['items', index, 'fillin']"
                                :rules="{
                                    required: false,
                                    message: 'fillin'
                                }"
                            >
                                <a-checkbox-group
                                    v-model:value="user.fillin"
                                    name="checkboxgroup"
                                    :options="plainOptionsfillin"
                                />
                                <!-- <div class="pt-2">
                                    สินค้าเริ่มต้น :
                                    <a-input-number
                                        v-model:value="user.fillin_items_qty_start"
                                        :min="1"
                                        :max="100"
                                    />
                                    ต่อครั้ง
                                </div> -->
                                <div class="pt-2">
                                    สั่งได้ไม่เกิน :
                                    <a-input-number
                                        v-model:value="user.fillin_items_qty"
                                        :min="1"
                                        :max="100"
                                    />
                                    ต่อครั้ง
                                </div>
                             
                                <div class="pt-2">
                                    หัวข้อ(อธิบายลูกค้า) :
                                    <a-textarea
                                        v-model:value="user.fillin_desc"
                                        placeholder="เนื้อหา"
                                    />
                                </div>
                            </a-form-item>
                        </a-card>
                        <a-collapse v-if="false" v-model:activeKey="activeKey">
                            <a-collapse-panel key="1" header="ใส่ลิ้งรูปภาพ">
                                <a-form-item
                                    :label="'ลิ้งรูปที่ 1 (ภาพหลัก)'"
                                    :name="['items', index, 'imageurl']"
                                    :rules="{
                                        required: true,
                                        message: 'url รูป'
                                    }"
                                >
                                    <a-input
                                        v-model:value="user.imageurl_1"
                                        placeholder="รูป"
                                    />
                                </a-form-item>
                                <a-form-item
                                    :label="'ลิ้งรูปที่ 2'"
                                    :name="['items', index, 'imageurl']"
                                    :rules="{
                                        required: true,
                                        message: 'url รูป'
                                    }"
                                >
                                    <a-input
                                        v-model:value="user.imageurl_2"
                                        placeholder="รูป"
                                    />
                                </a-form-item>
                                <a-form-item
                                    :label="'ลิ้งรูปที่ 3'"
                                    :name="['items', index, 'imageurl']"
                                    :rules="{
                                        required: true,
                                        message: 'url รูป'
                                    }"
                                >
                                    <a-input
                                        v-model:value="user.imageurl_3"
                                        placeholder="รูป"
                                    />
                                </a-form-item>
                                <a-form-item
                                    :label="'ลิ้งรูปที่ 4'"
                                    :name="['items', index, 'imageurl']"
                                    :rules="{
                                        required: true,
                                        message: 'url รูป'
                                    }"
                                >
                                    <a-input
                                        v-model:value="user.imageurl_4"
                                        placeholder="รูป"
                                    />
                                </a-form-item>
                                <a-form-item
                                    :label="'ลิ้งรูปที่ 5'"
                                    :name="['items', index, 'imageurl']"
                                    :rules="{
                                        required: true,
                                        message: 'url รูป'
                                    }"
                                >
                                    <a-input
                                        v-model:value="user.imageurl_5"
                                        placeholder="รูป"
                                    />
                                </a-form-item>
                            </a-collapse-panel>
                        </a-collapse>

                        <!-- <a-form-item :label="'ตัวเลือกที่ ' + parseInt(index + 1)" :name="['items', index, 'first']" :rules="{
              required: true,
              message: 'กรอกตัวเลือก',
            }">
              <a-input v-model:value="user.first" placeholder="ตัวเลือก" />
            </a-form-item> -->
                        <!-- <a-form-item :label="'ราคาที่  ' + parseInt(index + 1)" :name="['items', index, 'last']" :rules="{
              required: true,
              message: 'กรอกราคา',
            }">
              <a-input v-model:value="user.last" placeholder="ราคา" />
            </a-form-item> -->
                    </a-space>

                    <a-space v-if="user.type == 2">
                        <a-form-item
                            :label="'ชื่อนามสกุล '"
                            :name="['items', index, 'namelastname']"
                            :rules="{
                                required: true,
                                message: 'ชื่อ-นามสกุล'
                            }"
                        >
                            <a-input
                                :disabled="true"
                                v-model:value="user.namelastname"
                                placeholder="ชื่อ-นามสกุล"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 3">
                        <a-form-item
                            :label="'อีเมลย์ '"
                            :name="['items', index, 'email']"
                            :rules="{
                                required: true,
                                message: 'อีเมลย์'
                            }"
                        >
                            <a-input
                                :disabled="true"
                                v-model:value="user.email"
                                placeholder="อีเมลย์"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 4">
                        <a-form-item
                            :label="'เบอร์โทร '"
                            :name="['items', index, 'tel']"
                            :rules="{
                                required: true,
                                message: 'เบอร์โทร'
                            }"
                        >
                            <a-input
                                :disabled="true"
                                v-model:value="user.tel"
                                placeholder="เบอร์โทร"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 5">
                        <a-form-item
                            :label="'LINE '"
                            :name="['items', index, 'line']"
                            :rules="{
                                required: true,
                                message: 'LINE'
                            }"
                        >
                            <a-input
                                :disabled="true"
                                v-model:value="user.line"
                                placeholder="Line"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 6">
                        <a-form-item
                            :label="'ที่อยู่จัดส่งสินค้า '"
                            :name="['items', index, 'address']"
                            :rules="{
                                required: true,
                                message: 'ที่อยู่จัดส่งสินค้า'
                            }"
                        >
                            <a-textarea
                                :disabled="true"
                                v-model:value="user.line"
                                placeholder="ที่อยู่จัดส่งสินค้า"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 7">
                        <a-form-item
                            :label="'ยอดที่ชำระ '"
                            :name="['items', index, 'payprice']"
                            :rules="{
                                required: true,
                                message: 'ยอดที่ชำระ'
                            }"
                        >
                            <a-input
                                :disabled="true"
                                v-model:value="user.custom"
                                placeholder="ยอดที่ชำระ"
                            />
                        </a-form-item>
                    </a-space>
                    <a-space v-else-if="user.type == 8">
                        <a-form-item
                            :label="'กำหนดเอง (ชื่อ Input) '"
                            :name="['items', index, 'custom']"
                            :rules="{
                                required: true,
                                message: 'กำหนดเอง (ชื่อ Input)'
                            }"
                        >
                            <a-input
                                v-model:value="user.custom"
                                placeholder="กำหนดเอง"
                            />
                        </a-form-item>
                    </a-space>
                    <!-- <a-space v-else-if="user.type == 8">
            <a-form-item :label="'ช่องทางการชำระเงิน '" :name="['items', index, 'ช่องทางการชำระเงิน']" :rules="{
              required: true,
              message: 'ช่องทางการชำระเงิน',
            }">
              <a-input v-model:value="user.payment" placeholder="ช่องทางการชำระเงิน" />
            </a-form-item>
          </a-space> -->

                    <MinusCircleOutlined @click="removeUser(user)" />
                </a-space>
                <!-- <a-form-item>
          <a-button type="dashed" block @click="addUser('product')">
            <PlusOutlined />
            เพิ่ม
          </a-button>
        </a-form-item> -->
                <a-form-item>
                    <a-dropdown>
                        <template #overlay>
                            <a-menu @click="handleMenuClick">
                                <a-menu-item :key="['1', 'product']"
                                    >สินค้า</a-menu-item
                                >
                                <a-menu-item :key="['2', 'namelastname']"
                                    >ชื่อ-นามสกุล</a-menu-item
                                >
                                <a-menu-item :key="['3', 'email']"
                                    >อีเมล</a-menu-item
                                >
                                <a-menu-item :key="['4', 'tel']"
                                    >เบอร์โทร</a-menu-item
                                >
                                <a-menu-item :key="['5', 'line']"
                                    >Line</a-menu-item
                                >
                                <a-menu-item :key="['6', 'address']"
                                    >ที่อยู่จัดส่งสินค้า</a-menu-item
                                >
                                <a-menu-item :key="['7', 'payprice']"
                                    >ยอดชำระ</a-menu-item
                                >
                                <a-menu-item :key="['8', 'custom']"
                                    >กำหนดเอง</a-menu-item
                                >
                                <!-- <a-menu-item :key="['8', 'pay']">ช่องทางการชำระเงิน</a-menu-item> -->
                            </a-menu>
                        </template>
                        <a-button>
                            ตัวจัดการ
                            <DownOutlined />
                        </a-button>
                    </a-dropdown>
                </a-form-item>
                <!-- <a-form-item :wrapper-col="buttonItemLayout.wrapperCol">
            <a-button type="primary" html-type="submit">ยืนยัน</a-button>
        </a-form-item> -->
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { computed, defineComponent, reactive, ref } from "vue";
import {
    MinusCircleOutlined,
    PlayCircleOutlined,
    LinkOutlined
} from "@ant-design/icons-vue";
import { onEdit, value, current_tab } from "@/store/store";
import UploadFile from "@/module/Salepage/SalepageCreate/component/Uploadfile.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    components: {
        MinusCircleOutlined,
        PlayCircleOutlined,
        LinkOutlined,
        UploadFile
    },
    emits: ["onform"],
    setup(props, { emit }) {
        const plainOptionsfillin = [
            "ชื่อ",
            "วันเกิด",
            "เลขบัตรประชาชน",
            "หมายเหตุ",
            "ซ่อนสินค้า",
            "ยอดทำบุญ",
        ];
        const visible = ref(false);
        const formState = reactive({
            layout: "vertical",
            title: "",
            btnname: "",
            linetoken: "",
            isbtnpayment: "",
            redirecturlpayment: "",
            descriptionpayment: "",
            linetokenbeam: "",
            transfermoney: "",
            type_of_payment: [],
            pricecod: "",
            transfermoney_detail: []
        });
        if (current_tab.value == "1") {
            formState.btnname = "ยืนยันให้ติดต่อกลับ";
        } else {
            formState.btnname = "ยืนยัน";
        }
        const dynamicValidateForm = reactive({
            items: []
        });
        if (onEdit.value.isshowedit) {
            formState.title = onEdit.value.editobj.detail.value.title;
            formState.btnname = onEdit.value.editobj.detail.value.btnname;
            formState.isbtnpayment =
                onEdit.value.editobj.detail.value.isbtnpayment;
            formState.redirecturlpayment =
                onEdit.value.editobj.detail.value.redirecturlpayment;
            formState.descriptionpayment =
                onEdit.value.editobj.detail.value.descriptionpayment;
            formState.linetokenbeam =
                onEdit.value.editobj.detail.value.linetokenbeam;
            formState.transfermoney =
                onEdit.value.editobj.detail.value.transfermoney;
            formState.type_of_payment =
                onEdit.value.editobj.detail.value.type_of_payment;
            formState.pricecod = onEdit.value.editobj.detail.value.pricecod;

            dynamicValidateForm.items =
                onEdit.value.editobj.detail.value.option.items;
            console.log("editform", onEdit.value);
        }
        const showModal = () => {
            visible.value = true;
        };
        showModal();
        const close = () => {
            visible.value = false;
            value.value = ["เลือก"];
            onEdit.value.isshowedit = false;
        };
        const handleOk = e => {
            set_obj_tranfermoney();
            // onEdit.value.isshowedit = false
            visible.value = false;
            formState.option = dynamicValidateForm;
            if (formState.option.items.findIndex(e => e.type == 7) == 1) {
                formState.btnname = "กดปุ่มนี้เพื่อยืนยันการชำระ";
            }
            emit("onform", { value: formState, type: "form" });
            onEdit.value.isshowedit = false;
            console.log(e);
            visible.value = false;
        };

        const set_obj_tranfermoney = () => {
            const inputArray = formState.transfermoney;

            const matchingObjects = data_bank.filter(obj =>
                inputArray?.includes(obj["เลขบัญชี"])
            );

            formState.transfermoney_detail = matchingObjects;
        };

        const formItemLayout = computed(() => {
            const { layout } = formState;
            return layout === "horizontal"
                ? {
                      labelCol: {
                          span: 4
                      },
                      wrapperCol: {
                          span: 14
                      }
                  }
                : {};
        });
        const buttonItemLayout = computed(() => {
            const { layout } = formState;
            return layout === "horizontal"
                ? {
                      wrapperCol: {
                          span: 14,
                          offset: 4
                      }
                  }
                : {};
        });

        const formRef = ref();

        const removeUser = item => {
            let index = dynamicValidateForm.items.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.items.splice(index, 1);
            }
        };

        const addUser = type => {
            dynamicValidateForm.items.push({
                first: "",
                last: "",
                namelastname: "",
                email: "",
                tel: "",
                line: "",
                address: "",
                custom: "",
                pay: "",
                type: type,
                id: Date.now(),
                imagelisturl: [],
                order: indexorder.value++
            });
        };

        const onFinish = values => {
            console.log("Received values of form:", values);
            console.log(
                "dynamicValidateForm.items:",
                dynamicValidateForm.items
            );
        };
        const indexorder = ref(0);
        const handleMenuClick = e => {
            if (e.key[0] == 1) {
                addUser(e.key[0]);
            } else if (e.key[0] == 2) {
                addUser(e.key[0]);
            } else if (e.key[0] == 3) {
                addUser(e.key[0]);
            } else if (e.key[0] == 4) {
                addUser(e.key[0]);
            } else if (e.key[0] == 5) {
                addUser(e.key[0]);
            } else if (e.key[0] == 6) {
                addUser(e.key[0]);
            } else if (e.key[0] == 7) {
                addUser(e.key[0]);
            } else if (e.key[0] == 8) {
                addUser(e.key[0]);
            }
        };

        const ondeletefile = (e, indeximage) => {
            dynamicValidateForm.items[indeximage].fileList = e.fileList;
        };
        const activeKey = ref(["1"]);
        var startupload = ref(false);
        const onchangeimages = async (e, indeximage) => {
            console.log(e);
            console.log(" imagesss : ", e, indeximage);
            var listimageloop = e;

            listimageloop.forEach(items => {
                if (items.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename: indeximage + "_" + uuidv4() + ".jpeg",
                        base64: items.preview,
                        folder: "salepage"
                    };
                    startupload.value = true;
                    setTimeout(() => {
                        if (startupload.value) {
                            updateimageshoping(uploadparam, items, indeximage);
                            console.log("success");
                        }
                        startupload.value = false;
                    }, 1000);
                }
            });
        };
        const updateimageshoping = async (uploadparam, items, indeximage) => {
            await BzbsUser.apiuploadimage(uploadparam)
                .then(res => {
                    console.log(res.data.url);
                    if (
                        indeximage >= 0 &&
                        indeximage < dynamicValidateForm?.items?.length
                    ) {
                        if (
                            dynamicValidateForm.items[indeximage].fileList ==
                            undefined
                        ) {
                            dynamicValidateForm.items[indeximage].fileList = [];
                        }

                        var obj = {
                            uid: items.uid,
                            name: uploadparam.filename,
                            status: "done",
                            url: res.data.url
                        };
                        dynamicValidateForm.items[indeximage].fileList.push(
                            obj
                        );
                    } else {
                        console.error("Invalid index.");
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const plainOptions = ref([
            {
                label: "แสดงช่องกรอก (รายละเอียดจัดส่ง)",
                value: "show-delivery"
            },
            { label: "เก็บเงินปลายทาง", value: "cod" },
            { label: "โอนเงิน", value: "transfer-money" },
            { label: "บัตรเครดิต", value: "credit" }
        ]);
        var data_bank = [
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "ธนาคาร(SCB)กระแสรายวัน",
                เลขบัญชี: "468-118-657-4",
                name: "scb"
            },
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "ธนาคารกสิกรไทย (Kbank)",
                เลขบัญชี: "054-2-86856-2",
                name: "kbank"
            },
            {
                ชื่อบัญชี: "บริษัท นะมะหายัน กรุ๊ป จำกัด",
                ธนาคาร: "ธนาคารไทยพาณิชย์ (SCB)",
                เลขบัญชี: "171-427644-4",
                name: "scb"
            },
            {
                ชื่อบัญชี: "น.ส.นภัสนันท์ พิชยะพัฒนกุล",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-406-7878"
            },
            {
                ชื่อบัญชี: "น.ส. พชรมน ธนภรณ์ทิชากร",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-0-85231-5",
                name: "bbl"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "171-419225-0",
                name: "scb"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(Kbank)ธนาคารกสิกรไทย",
                เลขบัญชี: "103-1-34585-1",
                name: "kbank"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "093-7-31775-8",
                name: "bbl"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-3-01403-7",
                name: "ktb"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "467-1-87200-4",
                name: "bay"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "020-3-6487926-0",
                name: "gsb"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "800-184-871-2",
                name: "uob"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(KKP)ธนาคารเกียรตินาคินภัทร",
                เลขบัญชี: "20-0304168-0",
                name: "kkp"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(CIMBT)ธนาคารซีไอเอ็มบีไทย",
                เลขบัญชี: "701-1-99798-8",
                name: "cimbt"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(LH Bank)ธนาคารแลนด์ แอนด์ เฮ้าส์",
                เลขบัญชี: "890-2-18543-3",
                name: "lhbank"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "(BAAC)ธนาคาร ธกส",
                เลขบัญชี: "020-197-622-099",
                name: "baac"
            },
            {
                ชื่อบัญชี: "นาย กัมปนาท  มาลาขาว",
                ธนาคาร: "ธนาคารทีเอ็มบีธนชาต",
                เลขบัญชี: "230-7-08723-5",
                name: ""
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "409-624877-5",
                name: "scb"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-4-02110-7",
                name: "bbl"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-3-18782-9",
                name: "ktb"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "788-1-14773-3",
                name: "bay"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "204-0-927915-3",
                name: "gsb"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "800-188-830-7",
                name: "uob"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(KKP)ธนาคารเกียรตินาคินภัทร",
                เลขบัญชี: "20-1239698-8",
                name: "kkp"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "(CIMBT)ธนาคารซีไอเอ็มบีไทย",
                เลขบัญชี: "70-1305407-7",
                name: "cimbt"
            },
            {
                ชื่อบัญชี: "น.ส.ทิพย์รัตน์ โชติพันธ์",
                ธนาคาร: "ธนาคารทีเอ็มบีธนชาต",
                เลขบัญชี: "230-7-20496-2",
                name: ""
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(SCB)ธนาคารไทยพาณิชย์",
                เลขบัญชี: "171-4-00257-8",
                name: "scb"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(BBL)ธนาคารกรุงเทพ",
                เลขบัญชี: "865-0-82807-5",
                name: "bbl"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(KTB)ธนาคารกรุงไทย",
                เลขบัญชี: "488-0-82789-4",
                name: "ktb"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(Bay)ธนาคารกรุงศรีอยุธยา",
                เลขบัญชี: "467-1-77083-6",
                name: "bay"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(GSB)ธนาคารออมสิน",
                เลขบัญชี: "020-2-8283266-4",
                name: "gsb"
            },
            {
                ชื่อบัญชี: "น.ส. เอื้อมพร คิดชอบ",
                ธนาคาร: "(UOB)ธนาคารยูโอบี",
                เลขบัญชี: "230-2-73574-7",
                name: "uob"
            },
            {
                ชื่อบัญชี: "BAOTHONG XAYYAVONG MS",
                ธนาคาร: "Bcel bank",
                เลขบัญชี: "010-12-00-00889322-001",
                name: "bcelbank"
            }
        ];

        const options = ref([]);
        for (let i = 1; i < data_bank.length; i++) {
            const value = data_bank[i]["เลขบัญชี"];
            options.value.push({
                label: `${data_bank[i]["ธนาคาร"]} ======= ${data_bank[i]["เลขบัญชี"]}`,
                value
            });
        }
        const maxTagCount = ref(2);
        const maxTagTextLength = ref(20);
        return {
            ondeletefile,
            onchangeimages,
            activeKey,
            handleMenuClick,
            close,
            visible,
            showModal,
            handleOk,
            formState,
            formItemLayout,
            buttonItemLayout,
            formRef,
            dynamicValidateForm,
            onFinish,
            removeUser,
            addUser,
            onEdit,
            current_tab,
            value: ref([]),
            options,
            maxTagCount,
            maxTagTextLength,
            plainOptions,
            plainOptionsfillin
        };
    }
});
</script>

<style>
.bg-campaign {
    box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px,
        rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px,
        rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px;
}
</style>
