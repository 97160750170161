<template>
    <div class="page max-w-full overflow-x-hidden w-full h-full flex flex-col">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar
                    @clickexport="modalController.exportModal = true"
                ></Toolbar>
            </template>
        </TopBar>
        <div>
            <Table></Table>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import Table from "../component/Table.vue";
export default {
    components: { TopBar, Footer, Table },
    computed: {
        bcItems: function() {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "สร้างข้อมูลลูกค้า"
                }
            ];
        }
    }
};
</script>

<style></style>
