<template>
    <div class="pt-16">
        <div class="relative mb-2">
            <div class="absolute w-full flex justify-center bottom-0">
                <div class="w-32 h-32 campaign-image rounded-md overflow-hidden">
                    <div :style="{'background-image': `url('${serialModel.campaignImgUrl}')`}"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="text-center text-lg text-purple-700 font-bold px-4">
                {{serialModel.campaignName}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SerialDetail",
    props: {
        serialModel: Object,
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/image.scss";
    .campaign-image {
        div {
            @include boxRatio(100%);
        }
    }
</style>
