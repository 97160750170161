import mitt from "mitt";
import Util from "@/helper/Utility.js";
/**
 * setting
 * @method setTitle
 * @method setMsg
 * @method setBtnOk
 * @method clearModal
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onClickOk
 * event
 * @event sendCallbackOk
 */
export function ListAddressModalController () {
    this.busModal = mitt();

    this.notShowModalAfterUpdate = false;

    this.alertModal = null;
    this.confirmModal = null;
    this.loadingModal = null;
    this.addAddressModal = null;
    this.editAddressModal = null;

    this.callbackOk = null;
    this.callbackHidden = null;
    this.callbackAddSuccess = null;
    this.callbackEditSuccess = null;
    this.callbackUpdateSuccess = null;

    //#region setter
    this.setAlertModal = function(alertModal) {
        this.alertModal = alertModal;
    };
    this.setConfirmModal = function(confirmModal) {
        this.confirmModal = confirmModal;
    };
    this.setLoadingModal = function(loadingModal) {
        this.loadingModal = loadingModal;
    };
    this.setAddAddressModal = function(addAddressModal) {
        this.addAddressModal = addAddressModal;
    };
    this.setEditAddressModal = function(editAddressModal) {
        this.editAddressModal = editAddressModal;
    };
    this.clearModal = function() {
        this.callbackOk = null;
        this.callbackHidden = null;
        this.callbackAddSuccess = null;
        this.callbackEditSuccess = null;
        this.callbackUpdateSuccess = null;
        this.notShowModalAfterUpdate = false;
        return this;
    };
    this.setNotShowModalAfterUpdate = function() {
        this.notShowModalAfterUpdate = true;
        return this;
    };
    //#endregion setter

    //#region method
    this.show = function() {
        this.busModal.emit('show');
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event
    this.onClickOk = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackOk = callback;
        }
    },
    this.sendCallbackOk = function(data) {
        if (Util.isFunction(this.callbackOk)) {
            this.callbackOk(data);
        }
    };

    this.onHidden = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackHidden = callback;
        }
    };
    this.sendCallbackHidden = function(data) {
        if (Util.isFunction(this.callbackHidden)) {
            this.callbackHidden(data);
        }
    };

    this.onAddSuccess = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackAddSuccess = callback;
        }
    };
    this.sendCallbackAddSuccess = function(data) {
        if (Util.isFunction(this.callbackAddSuccess)) {
            this.callbackAddSuccess(data);
        }
    };

    this.onEditSuccess = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackEditSuccess = callback;
        }
    };
    this.sendCallbackEditSuccess = function(data) {
        if (Util.isFunction(this.callbackEditSuccess)) {
            this.callbackEditSuccess(data);
        }
    };

    this.onUpdateSuccess = function(callback) {
        if (Util.isFunction(callback)) {
            this.callbackUpdateSuccess = callback;
        }
    };
    this.sendCallbackUpdateSuccess = function(data) {
        if (Util.isFunction(this.callbackUpdateSuccess)) {
            this.callbackUpdateSuccess(data);
        }
    };
    //#endregion event
}
