 
<template>
    <a-button type="primary" @click="showModal">อัพเดท user/pass ไปรษณีย์ไทย</a-button>
    <a-modal v-model:visible="visible" title="ระบบไปรษณีย์ไทย" @ok="handleOk">
        <!-- init : {{init}} -->
        <a-divider orientation="left">ระบบ prepost <a
                href="https://prepost.thailandpost.com/">(https://prepost.thailandpost.com)</a></a-divider>

        <a-form :model="formState" name="basic" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off"
            @finish="onFinish" @finishFailed="onFinishFailed">
            <a-form-item label="Username" name="userprepost"
                :rules="[{ required: true, message: 'Please input your username!' }]">
                <a-input v-model:value="formState.userprepost" />
            </a-form-item>

            <a-form-item label="Password" name="userprepostpassword"
                :rules="[{ required: true, message: 'Please input your password!' }]">
                <a-input-password v-model:value="formState.userprepostpassword" />
                <span v-show="haserror.prepost">
                    user/password ไม่ถูกต้อง
                </span>
            </a-form-item>


            <a-divider orientation="left">ระบบ dpostinter <a
                    href="https://dpostinter.thailandpost.com/Login">(https://dpostinter.thailandpost.com/Login)</a>
            </a-divider>


            <a-form-item label="Username" name="userdpostinter"
                :rules="[{ required: true, message: 'Please input your username!' }]">
                <a-input v-model:value="formState.userdpostinter" />
            </a-form-item>

            <a-form-item label="Password" name="passworddpostinter"
                :rules="[{ required: true, message: 'Please input your password!' }]">
                <a-input-password v-model:value="formState.passworddpostinter" />
                <span v-show="haserror.dpostinter">
                    user/password ไม่ถูกต้อง
                </span>
            </a-form-item>

            <a-alert v-show="issuccess" message="บันทึกสำเร็จ" type="success" />


            <!-- <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                <a-button type="primary" html-type="submit">บันทึก</a-button>
            </a-form-item> -->
        </a-form>
    </a-modal>

</template>
<script>
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";

export default defineComponent({
    setup() {
        const init = ref([]);
        onMounted(async () => {
            init.value = await apigetuserthailandpost()
            await setinit(init.value)

            console.log("init 99 :", init)
        });


        const issuccess = ref(false)
        const haserror = ref({
            prepost: false,
            dpostinter: false,

        })
        const formState = reactive({
            userprepost: '',
            userprepostpassword: '',
            userdpostinter: '',
            passworddpostinter: '',
            remember: true,
        });

        const setinit = () => {
            console.log("init.value.length", init.value.length)
            console.log("init.value", init.value)
            if (init.value) {
       
                init.value.forEach(element => {
                    if (element.Type == 'prepost') {
                        formState.userprepost = element.Username
                        formState.userprepostpassword = element.Password
                    }
                    else if (element.Type == 'dpostinter') {
                        formState.userdpostinter = element.Username
                        formState.passworddpostinter = element.Password
                    }
                });
                // setTimeout(() => {
                //     handleOk()
                // }, 3000);

            }
        }
        const onFinish = values => {
            console.log('Success:', values);
        };
        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };
        const visible = ref(false);
        const showModal = () => {
            visible.value = true;
        };
        const handleOk = async (e) => {
            formStateStore.userpasspost = formState
            var param = [{
                "Username": formState.userprepost,
                "Password": formState.userprepostpassword,
                "Type": "prepost",
            }, {
                "Username": formState.userdpostinter,
                "Password": formState.passworddpostinter,
                "Type": "dpostinter",
            }
            ]

            param.forEach(async (element) => {
                if (!element.Username || !element.Password) return
                let result = await updateuserpassword(element).then(res => {
                    console.log(res);
                    if (element.Type == 'prepost' && res.data?.status == 401) {
                        haserror.value.prepost = true;
                    }
                    if (element.Type == 'dpostinter' && res.data?.status == 401) {
                        haserror.value.dpostinter = true;
                    }
                })
                    .catch(error => {
                        console.log(error);
                    });
                setTimeout(() => {
                    haserror.value.prepost = false;
                    haserror.value.dpostinter = false;
                }, 2000);
                console.log("handleOk ", result)
            });

            console.log(e);

            setTimeout(() => {
                if (!haserror.value.prepost && !haserror.value.dpostinter) {
                    issuccess.value = true
                    haserror.value.prepost = false;
                    haserror.value.dpostinter = false;
                    setTimeout(() => {
                        visible.value = false;
                        issuccess.value = false
                    }, 2000);
                }
            }, 2000);


        };


        const apigetuserthailandpost = () => {
            return BzbsUser.apigetuserthailandpost()
                .then(res => {
                    return res.data
                })
                .catch(error => {
                    return error
                });
        }

        const updateuserpassword = async (param) => {
            return BzbsUser.apiadduserthailandpost(param)
                .then(res => {
                    return res
                })
                .catch(error => {
                    return error
                });
        }
        return {
            visible,
            showModal,
            handleOk,
            formState,
            onFinish,
            onFinishFailed,
            formStateStore,
            haserror,
            issuccess,
            init
        };
    },
});
</script>