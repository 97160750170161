<template>
    <div> 
        <!-- <pre>{{ formStateStore.listcreatedelivery }}</pre> -->
        <!-- formStateStore:


        mockData:
        <pre>{{mockData}}</pre> -->
        <!-- <pre>modifier : {{formStateStore.modifier}}</pre> -->
        <!-- <pre>{{mockData}}</pre> -->
        <a-divider orientation="left">Tracking No(1.1)</a-divider>
        <!-- <pre>{{ mockData }}</pre>
        <pre>{{targetKeys}}</pre> -->
        <a-transfer v-model:target-keys="targetKeys" :titles="[' (Tracking No.)', ' (Tracking No.)']"
            :data-source="mockData" :disabled="disabled"
            :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1" :show-select-all="false"
            v-model:selected-keys="selectedKeys" @change="onChange" @selectChange="handleSelectChange"
            @scroll="handleScroll" :list-style="{
                width: '100%',
                height: 'auto',
            }">
            <template #render="item">
                <a-row type="flex" style="padding: 10px;">
                    <a-col flex="1 1 200px">

                        <div v-if="item.title == null">
                            ไม่พบ Barcode
                        </div>
                        <div v-else>
                            <vue-barcode :value="item.title" :options="{ displayValue: true }" style="width:200px">
                            </vue-barcode>
                        </div>
                    </a-col>
                    <a-col flex="1 1 200px">
                        <div v-if="item.title" class="numcode">
                            เลขพัสดุ
                        </div>
                        <div class="numcode">
                            {{ item.title }}
                        </div>
                        <div>{{ item.status }}</div>
                    </a-col>
                </a-row>
            </template>
        </a-transfer>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import VueBarcode from '@chenfengyuan/vue-barcode';
export default defineComponent({
    props: {
        isdisable: null,
        typeDelivery: null,

    },
    components: {
        VueBarcode
    },
    setup(props) {
        const selectedKeys = ref(['0']);

        const mockData = ref([]);
        var disabledtranfer = ref(props.isdisable);
        // formStateStore?.listcreatedelivery.forEach((element, index) => {
        //     var content = element.PD_ThailandPostTrackingPath;
        //     mockData.value.push({
        //         key: index.toString(),
        //         title: element.PD_ThailandPostTrackingNo,
        //         description: content,
        //         disabled: true,
        //     });
        // });

        // const originTargetKeys = mockData.value.filter(item => +item.key % 3 > 1).map(item => item.key);
        const leftTableColumns = [{
            dataIndex: 'title',
            title: 'Tracking No.',
        }, {
            dataIndex: 'status',
            title: 'status',
        }];
        const rightTableColumns = [{
            dataIndex: 'title',
            title: 'Tracking No.',
        }, {
            dataIndex: 'status',
            title: 'status',
        }];
  
        const targetKeys = ref([]);
        const disabled = ref(false);
        const showSearch = ref(true);
        const leftColumns = ref(leftTableColumns);
        const rightColumns = ref(rightTableColumns);
        const onChange = nextTargetKeys => {
            console.log('nextTargetKeys', nextTargetKeys);
        };

        const inittranfer = () => {

            console.log("formStateStore.rowselecteddetail.rowselected : ", formStateStore.rowselecteddetail)
            var sp = formStateStore.rowselecteddetail

            return BzbsUser.getcreatedelivery(sp)
                .then(res => { 
                    res.data.forEach((element, index) => {
                        res.data[index].key = index;
                    });
                    formStateStore.listcreatedelivery = res.data
                    console.log("formStateStore.listcreatedelivery : ", formStateStore.listcreatedelivery)


                    
                    mockData.value.push({
                        key: 0,
                        title: res.data[0].PD_TrackingNo,
                        description: res.data[0].PD_TrackingNo,
                        status: res.data[0].PD_DeliveryStatus,
                        disabled: disabledtranfer,
                        chosen: true,
                    });
                    //แบบเก่า 
                    // BzbsUser.apigetcreatedinter()
                    //     .then(res => {

                    

                    //         res.data.items.forEach((element, index) => {

                    //             console.log("element.id" + index)
                    //             console.log("formStateStore?.listcreatedelivery" + formStateStore?.listcreatedelivery)


                    //             const found = formStateStore?.listcreatedelivery.find(elementlist => element.filename.replace('https://dpostinter.thailandpost.com/api/media/label/preload?id=', '') == elementlist.PD_ThailandPostTrackingPath.replace('https://dpostinter.thailandpost.com/api/media/label/preload?id=', ''));
                    //             console.log("found", found);
                    //             var content = found?.filename;
                    //             if (found.PD_TrackingNo != null) {
                    //                 mockData.value.push({
                    //                     key: index.toString(),
                    //                     title: found.PD_TrackingNo,
                    //                     description: content,
                    //                     status: found.PD_DeliveryStatus,
                    //                     disabled: disabledtranfer,
                    //                     chosen: true,
                    //                 });
                    //             } 
                    //         });

                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });

                })
                .catch(error => {
                    console.log(error);
                });
        };
        const inittranferprivate = () => {

            console.log("formStateStore.rowselecteddetail.rowselected : ", formStateStore.rowselecteddetail)
            var sp = formStateStore.rowselecteddetail

            return BzbsUser.getcreatedelivery(sp)
                .then(res => {
                    res.data.forEach((element, index) => {
                        res.data[index].key = index;
                    });

                    formStateStore.listcreatedelivery = res.data

   




                    formStateStore?.listcreatedelivery.forEach((element, index) => {
                        var content = element.PD_ThailandPostTrackingPath;
                        // alert(element.PD_TrackingNo)
                        if (element.PD_TrackingNo != null && mockData.value.length == 0) {
                            mockData.value.push({
                                key: index.toString(),
                                title: element.PD_TrackingNo,
                                description: content,
                                disabled: true,
                            });
                        }

                    });

                    // targetKeys.value.push(index.toString())





                    // formStateStore?.listcreatedelivery.forEach((element, index) => {
                    //     var content = element.PD_ThailandPostTrackingPath;
                    //     mockData.value.push({
                    //         key: index.toString(),
                    //         title: element.PD_ThailandPostTrackingNo,
                    //         description: content,
                    //         disabled: true,
                    //     });
                    // });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        // console.log("formStateStore888", formStateStore.user.customeraddressType)

        if (formStateStore.user.customeraddressType == "1") { //"public"

 
            inittranferprivate();
        }
        else if (formStateStore.user.customeraddressType == "2") { //"private"
       
            inittranfer();
        }
        // else {
        //     // inittranfer();
        //     // inittranferprivate();
        // }
        const getRowSelection = ({
            disabled,
            selectedKeys,
            onItemSelectAll,
            onItemSelect,
        }) => {
            return {
                getCheckboxProps: item => ({
                    disabled: disabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({
                        key,
                    }) => key);
                    onItemSelectAll(treeSelectedKeys, selected);
                },
                onSelect({
                    key,
                }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: selectedKeys,
            };
        };
        const handleChange = (nextTargetKeys, direction, moveKeys) => {
            console.log('targetKeys: ', nextTargetKeys);
            console.log('direction: ', direction);
            console.log('moveKeys: ', moveKeys);
        };
        const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
            console.log('sourceSelectedKeys: ', sourceSelectedKeys);
            console.log('targetSelectedKeys: ', targetSelectedKeys);
        };
        const handleScroll = (direction, e) => {
            console.log('direction:', direction);
            console.log('target:', e.target);
        };

        watch(
            () => formStateStore.scanpacking,
            () => {
                console.log("formStateStore", formStateStore)
                if (formStateStore.scanpacking.length == 13) {
                    isshowpacking.value = false
                    apiscantracking()
                    updatepacking.value++
                }
                else {
                    isshowpacking.value = false
                    updatepacking.value++
                }
            },
        );
        const isshowpacking = ref(false)
        const updatepacking = ref(0)
        const apiscantracking = () => {

            var tracking = formStateStore.scanpacking;
            var param = {
                "track": tracking
            }
            formStateStore.user.finishpacked_scantracking = false
            BzbsUser.apiscantracking(param)
                .then(res => {
 
                    if (res.data.isfound) {
                        targetKeys.value.push("0")//res.data.items[0]?.ProductKey
                        isshowpacking.value = true
                        formStateStore.scanpacking = ''
                    }

                    if (targetKeys.value.length == mockData.value.length) {
                        formStateStore.user.finishpacked_scantracking = true
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            handleChange,
            handleSelectChange,
            handleScroll,
            selectedKeys,
            inittranfer,
            mockData,
            targetKeys,
            disabled,
            showSearch,
            leftColumns,
            rightColumns,
            onChange,
            getRowSelection,
            formStateStore,
            options: {
                lineColor: '#ff7069',
                fontSize: 32,
                font: 'Courier',
                width: 3,
                height: 60,
                marginBottom: 60,
                format: 'MSI',
                background: '#ccffff'
            },
        };
    },
});
</script>