<template>
    <div class="flex cursor-pointer">
        <div class="arrow-left" :style="arrowStyle"></div>
        <div class="pl-2 pr-3 flex items-center" :class="tagClass" :style="contentStyle">
            <div class="w-full">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: "VTag",
    props: {
        bgColor: {
            type: String,
            default: "#DAE9FF"
        },
        textColor: {
            type: String,
            default: "text-gray-600"
        },
        height: {
            type: [String, Number],
            default: "28px"
        },
        border: {
            type: String,
            default: "border"
        },
        rounded: {
            type: String,
            default: "rounded"
        },
        arrowOffset: {
            type: Number,
            default: 5
        }
    },
    computed: {
        tagClass: function () {
            return ["text-sm"]
        },
        contentStyle: function () {
            let size = PropsHelper.getBoxUnit(this.height)
            return {
                height: size,
                backgroundColor: this.bgColor
            }
        },
        arrowStyle: function () {
            let borderSize = PropsHelper.extractBoxUnit(this.height)
            let borderSizeHalf = `${borderSize.value / 2}${borderSize.unit}`
            let borderRight = `${(borderSize.value / 2) - this.arrowOffset}${borderSize.unit}`
            return {
                borderTopWidth: borderSizeHalf,
                borderBottomWidth: borderSizeHalf,
                borderRightWidth: borderRight,
                borderRightColor: this.bgColor

            }
        }
    }
}
</script>

<style lang="scss" scoped>
.arrow-left {
    width: 0;
    height: 0;
    border-top-style: solid;
    border-top-color: transparent;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    border-right-style: solid;
}
</style>
