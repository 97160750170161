<template>
    <div>
        <a-modal v-model:visible="visible" title="ตั้งชื่อร้านค้า" @cancel="oncancel()" :maskClosable="false">
            <a-input v-model:value.trim="shopname" addon-before="ชื่อเซลเพจ" addon-after=""
                v-on:keypress="isLetter($event)" />
            <div class="pt-3">
                <a-alert v-if="hassuccess" message="สำเร็จสามารถใช้ชื่อร้านค้านี้ได้" type="success" />
                <a-alert v-if="haserror" message="มีร้านค้าชื่อนี้ในระบบแล้ว" type="error" />
            </div>
            <template #footer>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">ตรวจสอบ</a-button>
                <a-button v-if="hassuccess" key="submit" type="primary" @click="startcreate()">เริ่มสร้าง</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { shopname } from '@/store/store'
import { useRoute } from "vue-router";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    props: ['type'],
    components: {
    },
    setup(props, { emit }) {
        const route = useRoute();
        const visible = ref(true);
        const haserror = ref(false);
        const hassuccess = ref(false);
        const loading = ref(false);
        const showModal = () => {
            visible.value = true;
        };

        const handleOk = () => {
            console.log(shopname.value);
            querycustomer(shopname.value);
            // visible.value = false;
        };

        const oncancel = () => {
            history.back()
        };

        const querycustomer = (shopname) => {
            if (!shopname) {
                return
            }
            loading.value = true;
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;

            return axios.get(path + "/api/salepageid_bof/" + route.query.subdomain + "/" + shopname.toLowerCase()).then(res => {
                loading.value = false;
                if (res.data.length == 0) {
                    hassuccess.value = true;
                    emit('onshopname', shopname)
                    console.log(res.data[0]);
                }
                else {
                    haserror.value = true;
                    hassuccess.value = false;
                    //มีชื่อนี้แล้ว 
                }
                setTimeout(() => {
                    haserror.value = false;
                }, 5000);
            });
        };
        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
        watch(shopname, () => {
            hassuccess.value = false;
        })
        const startcreate = async () => {

            visible.value = false;
            const param = {
                SalePageID: shopname.value,
                Subdomain: route.query.subdomain,
                TYPE: 'CREATE'
            };
            await BzbsUser.apicreatesalepage(param)
                .then(res => {
                    setTimeout(() => {  
                        window.location = "/salepageedit?subdomainsalepage=" + route.query.subdomain + "&id=" + shopname.value
                    }, 1000);
                    // listimages = [];
                    // listimages.push(res.data.url);
                    console.log(res.data);
                    // console.log("listimages", listimages);
                    // success();
                    // index_uploadimage++;
                })
                .catch(error => {
                    // openNotification(error.response.data)
                    console.log(error);
                });
        };
        return {
            isLetter,
            visible,
            showModal,
            handleOk, shopname, querycustomer, haserror, hassuccess,
            loading,
            startcreate, oncancel

        };
    }
});
</script>
