<template>
    <a-form-item label="ค่าส่ง*">
        <a-radio-group v-model:value="is_paid_delivery" name="radioGroup">
            <a-radio value="1">ยังไม่ชำระค่าจัดส่ง</a-radio>
            <a-radio value="2">ชำระค่าจัดส่งแล้ว</a-radio>
        </a-radio-group>
    </a-form-item>
    <div v-if="is_paid_delivery == 2">
        <a-divider orientation="left" v-if="is_paid_delivery == 2"><i><b>จัดส่งในประเทศ</b></i></a-divider>

        <a-form-item name="area" label="จัดส่งในประเทศ">
            <!-- <a-select v-model:value="dynamicValidateForm.area" :options="areas" /> -->
        </a-form-item>
        <a-form ref="formRef" name="formState" :model="formState">
            <a-space v-for="(inside, index) in formState.inside" :key="inside.id"
                style="display: flex; margin-bottom: 8px" align="baseline">
                <a-form-item :name="['inside', index, 'price']" label="ราคา" :rules="{
                    required: true,
                    message: 'กรุณากรอก'
                }">
                    <a-input v-model:value="inside.price" @change="onFinish" />
                </a-form-item>
                <a-form-item label="หมายเหตุ" :name="['inside', index, 'introduction']" :rules="{
                    required: true,
                    message: 'กรุณากรอก'
                }">
                    <a-input v-model:value="inside.introduction" @change="onFinish" />
                </a-form-item>
                <MinusCircleOutlined @click="removeinside(inside)" />
            </a-space>
            <!-- <a-form-item>
                <a-button style="width: 20%" type="dashed" block @click="addinside">
                    <PlusOutlined />
                    เพิ่มรายการ
                </a-button>
            </a-form-item> -->
            <a-divider orientation="left" v-if="is_paid_delivery == 2"><i><b>จัดส่งต่างประเทศ</b></i></a-divider>

            <a-form-item name="area" label="จัดส่งต่างประเทศ">
                <SelectOption :formStates="formState" v-model:value="formState.area"></SelectOption>
            </a-form-item>
            <a-space v-for="(outside, index) in formState.outside" :key="outside.id"
                style="display: flex; margin-bottom: 8px" align="baseline">
                <a-form-item :name="['outside', index, 'price']" label="ราคา" :rules="{
                    required: true,
                    message: 'กรุณากรอก'
                }">
                    <a-input v-model:value="outside.price" @change="onFinish" />
                </a-form-item>
                <a-form-item label="หมายเหตุ" :name="['outside', index, 'introduction']" :rules="{
                    required: true,
                    message: 'กรุณากรอก'
                }">
                    <a-input v-model:value="outside.introduction" @change="onFinish" />
                </a-form-item>

                <MinusCircleOutlined @click="removeoutside(outside)" />
            </a-space>
            <!-- <a-form-item>
                <a-button style="width: 20%" type="dashed" block @click="addoutside">
                    <PlusOutlined />
                    เพิ่มรายการ
                </a-button>
            </a-form-item> -->
            <!-- <pre v-if="formState.isshowjason">{{ formState }}</pre> -->
            <!-- <a-form-item>
                <a-button type="primary" html-type="submit">บันทึกค่าส่ง</a-button>
            </a-form-item> -->
        </a-form>
    </div>
</template>
<script>
import SelectOption from "../form/SelectOption.vue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch } from "vue";
// import Uploadfile from "../form/Uploadfile.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    components: {
        SelectOption,
        MinusCircleOutlined,
        PlusOutlined,
        // Uploadfile
    },
    emits: ["update:value"],
    props: {
        formStates: null
    },
    setup(props, { emit }) {
        const formItemContext = Form.useInjectFormItemContext();
        const is_paid_delivery = ref("1");
        const formState = reactive(props.formStates);
        formState.inside.length > 0 || formState.outside.length > 0 ? is_paid_delivery.value = 2 : is_paid_delivery.value = 1
        const areas = reactive([
            {
                label: "Beijing",
                value: "Beijing"
            },
            {
                label: "Shanghai",
                value: "Shanghai"
            }
        ]);
        BzbsUser.apicountries()
            .then(res => {
                Object.keys(res.data).map(function (element) {
                    areas.push({
                        label: res.data[element],
                        value: element
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const sights = {
            Beijing: ["Tiananmen", "Great Wall"],
            Shanghai: ["Oriental Pearl", "The Bund"]
        };
        const formRef = ref();
        // const formState = reactive({
        //   inside: [],
        //   outside: [],
        //   area: undefined,
        // });
        watch(
            () => formState.area,
            () => {
                formState.sights = [];
            }
        );

        const removeinside = item => {
            let index = formState.inside.indexOf(item);

            if (index !== -1) {
                formState.inside.splice(index, 1);
            }
        };
        const removeoutside = item => {
            let index = formState.outside.indexOf(item);

            if (index !== -1) {
                formState.outside.splice(index, 1);
            }
        };

        // const removeSight = (item) => {
        //   let index = dynamicValidateForm.sights.indexOf(item);

        //   if (index !== -1) {
        //     dynamicValidateForm.sights.splice(index, 1);
        //   }
        // };
        const productkey_count_inside = ref(0)
        const addinside = () => {
            formState.inside.push({
                productkey: productkey_count_inside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_inside.value++
        };
        addinside()

        const productkey_count_insidecod = ref(0)
        const addinsidecod = () => {
            formState.insidecod.push({
                productkey: productkey_count_insidecod.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_inside.value++
        };
        addinsidecod()
        const productkey_count_outside = ref(0)
        const addoutside = () => {
            formState.outside.push({
                productkey: productkey_count_outside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_outside.value++
        };
        addoutside()

        const onFinish = values => {

            console.log("Received values of form:", values);
            console.log("formState.inside:", formState.inside);
            emit("update:value", { outside: formState.outside, inside: formState.inside,insidecod: formState.insidecod });
            formItemContext.onFieldChange();
        };
        // const onNumberChange = (e) => {
        //   console.log("EE ", e.target.value);
        //   emit("update:value", { aa: e.target.value });
        //   formItemContext.onFieldChange();
        // };
        return {
            formRef,
            onFinish,
            removeinside,
            removeoutside,
            addinside,
            addoutside,
            areas,
            sights,
            is_paid_delivery,
            formState
        };
    }
});
</script>
<style lang="scss" scoped>

</style>
