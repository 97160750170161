<template>
    <div>
        <!-- loadding:{{loadding}} -->
        <div v-if="loadding" class="wrapper-load">
            <div>
                <a-row class="load">
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                    <a-col class="p-4">
                        <div class="title"></div>
                        <div class="select"></div>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive } from "vue";

export default defineComponent({
    props: {
        isload: null,
    },
    setup(props) {
        const loadding = reactive(props.isload);
        return {
            loadding, props

        };
    }
});
</script>
<style lang="scss">
.wrapper-load {
    // padding-bottom: 20px;

    .load {
        border: 1px solid #eee;
        border-radius: 5px;
    }

    .title {
        width: 100px;
        height: 24px;
        background: #eeee;
        margin-bottom: 8px;
        animation: bg-sk 0.5s infinite;
        border-radius: 5px;

    }

    .select {
        width: 150px;
        height: 18px;
        background: #eeee;
        margin-bottom: 10px;
        animation: bg-sk 0.5s infinite;
        border-radius: 5px;
    }

    @keyframes bg-sk {
        0% {
            background: #eee;
        }

        50% {
            background: #eaeaea;
        }

        100% {
            background: #eee;
        }
    }
}
</style>
