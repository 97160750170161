
<template>
    <div class="page max-w-full overflow-x-hidden w-full h-full flex flex-col card">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar @clickexport="modalController.exportModal = true"></Toolbar>
            </template>
        </TopBar>
        <!-- formState.masterproduct.mastertype.isshowsteps: {{ formState.masterproduct.isshowsteps }} -->
        <Step></Step>
        <div class="centerindiv">
            <div class="pb-4">
                {{ !isinsertnewtype ? 'เลือกประเภท' : '' }}
            </div>
            <a-row v-show="!isinsertnewtype" type="flex" justify="space-around" align="middle">
                <a-select v-model:value="value" show-search placeholder="เลือก" style="width: 200px"
                    :options="options" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
                    @change="handleChange"></a-select>
            </a-row>
            <div>
                <div v-show="isinsertnewtype">
                    <div class="pb-4">
                        ชื่อ(เพิ่มใหม่)
                    </div>
                    <a-textarea v-model:value="formState.masterproduct.createnew_type.name" placeholder=""
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                <!-- <div class="pb-4">
                        เพิ่มประเภทใหม่
                    </div>
                    <a-textarea v-model:value="formState.masterproduct.createnew_type.type" placeholder=""
                                                                                                                                                                                                :auto-size="{ minRows: 2, maxRows: 5 }" /> -->
                    <div class="pb-4">
                        ProductCode(01)
                    </div>
                    <a-textarea v-model:value="formState.masterproduct.createnew_type.productcode" placeholder=""
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                    <div class="pb-4">
                        ประเภทกลุ่ม(yan)
                    </div>
                    <a-textarea v-model:value="formState.masterproduct.createnew_type.type" placeholder=""
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                <!-- <div class="pb-4">
                        TypeCode(1)
                    </div>
                    <a-textarea v-model:value="formState.masterproduct.createnew_type.typecode" placeholder=""
                            :auto-size="{ minRows: 2, maxRows: 5 }" /> -->

                </div>
                <!-- <pre>{{ formState.masterproduct }}</pre> -->
            </div>
            <div class="mt-6 text-center">
                <a-button class=" " v-if="!isinsertnewtype" type="primary"
                    @click="insertnewtype('เพิ่ม')">เพิ่มประเภทใหม่</a-button>
                <a-button class=" " v-if="isinsertnewtype" type="primary"
                    @click="insertnewtype('เพิ่มเสร็จ')">ตกลง</a-button>
            </div>
        </div>

    </div>
</template>
<script>
import Step from "@/module/Product/CustomerList/component/Step.vue";
import { defineComponent, ref, reactive } from 'vue';
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import { useRouter } from "vue-router";
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { Modal } from "ant-design-vue";
export default defineComponent({

    components: {
        TopBar,
        Step
    },
    setup() {

        const router = useRouter();

        // router.push({ path: '/insertdetailproduct' })
        const formState = reactive(formStateStore)
        formState.masterproduct.createnew_type = {
            name: "",
            productcode: "",
            type: "",
            typecode: ""
        }

        formState.masterproduct.items = {
            type: {},
            detail: {}
        }
        // formState.masterproduct = {
        //     item: {}
        // }
        const isinsertnewtype = ref(false)

        // if (route.path != "/product") {
        //     formState.masterproduct.isshowsteps = true
        // }

        formState.masterproduct.status = 1
        formState.masterproduct.isshowsteps = true

        const productData = ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน", "วัตถุมงคล", "เคส/ภาพพื้นหลัง"];
        const options = ref([]);
        const handleChange = value => {
            
            formState.masterproduct.items.typename = value[0]
            formState.masterproduct.items.type = value[1]
            // window.location = '/insertdetailproduct'
            //this.$router.push({ path: '/insertdetailproduct' })
            // router.push({ path: '/insertdetailproduct', hash: '#team' })

            setTimeout(() => {
               
                router.push("/editproductdetail?type=" + formState.masterproduct.items.type);
            }, 100);
            console.log(`selected ${value}`);
        };
        const handleBlur = () => {
            console.log('blur');
        };
        const handleFocus = () => {
            console.log('focus');
        };
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const insertnewtype = (txt) => {

            if (txt == "เพิ่ม") {
                isinsertnewtype.value = true

            }
            else {
                isinsertnewtype.value = false
                apiinsertnewtype()
            }

        };



        const apiinsertnewtype = () => {
    
            var param = {
                "Name": formState.masterproduct.createnew_type.name,
                "ProductCode": formState.masterproduct.createnew_type.productcode,
                "Type": formState.masterproduct.createnew_type.type,
                "TypeCode": formState.masterproduct.createnew_type.typecode
            }
            BzbsUser.apiupdatemastertype(param)
                .then(res => {
                    console.log(res.data)
                    apigettype()
                    success()
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const apigettype = () => {
    
            BzbsUser.apigetmastertype()
                .then(res => {
                    console.log(res.data)

                    res.data?.forEach(element => {
                        options.value.push({
                            value: [element.Name, element.Type],
                            label: element.Name,
                        })

                    });

                })
                .catch(error => {
                    console.log(error);
                });
        };
        apigettype()


        return {
            apigettype,
            value: ref(undefined),
            filterOption,
            handleBlur,
            handleFocus,
            handleChange,
            options,
            formState,
            isinsertnewtype,
            insertnewtype
        };
    },
});
</script>