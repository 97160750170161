<template>
  <div class="relative p-4">
    <template v-if="title != null">
      <div class="m-4 text-lg font-bold text-gray-500">{{ title }}</div>
    </template>
    <a-form :model="formState" v-bind="layout" name="nest-messages" :validate-messages="validateMessages"
      @finish="onFinish" @finishFailed="onFinishFailed">
      <a-divider orientation="left">ข้อมูลลูกค้า</a-divider>
      <div class="p-5 mb-5 card">
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <user-outlined />
            <a-form-item :name="['user', 'customercode']" label="รหัสลูกค้า"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input style="background: #80808038" v-model:value="formState.user.customercode" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2" class="p-2 wrapper_createnew">
            <!-- <create-customerfrom
              @customercode="gencodecustomer($event)"
            ></create-customerfrom> -->
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'contact']" label="ช่องทางการติดต่อ*"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <!-- <a-input
                placeholder="ช่องทางการติดต่อ"
                v-model:value="formState.user.contact"
              /> -->
              <a-form-item name="select" has-feedback :rules="[
                { required: true, message: 'กรุณากรอก' },
              ]">
                <a-select v-model:value="formState.user.contact" placeholder="ช่องทางการติดต่อ">
                  <a-select-option value="Line">Line</a-select-option>
                  <a-select-option value="Facebook">Facebook</a-select-option>
                  <a-select-option value="Instargram">Instargram</a-select-option>
                  <a-select-option value="Tiktok">Tiktok</a-select-option>
                  <a-select-option value="Phone">Phone</a-select-option>
                </a-select>
              </a-form-item>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'contactname']" label="ชื่อการติดต่อ*"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.contactname" placeholder="ชื่อการติดต่อ" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'imageslip']" label="แนบรูปภาพ*">
              <Uploadfile @fileList="imageslip($event)"></Uploadfile>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'introduction']" label="หมายเหตุ"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-textarea v-model:value="formState.user.introduction" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <!-- <a-row type="flex">
          <a-col flex="1" :order="1">
            <a-form-item
              label="เพิ่มช่องทางการติดต่อ"
              :rules="[{ required: true, message: 'กรุณากรอก' }]"
            >
              <plus-circle-outlined class="pl-2" /> </a-form-item
          ></a-col>
          <a-col flex="1" :order="2"> </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row> -->
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customername']" label="ชื่อ"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customername" placeholder="ชื่อ" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'customerlastname']" label="นามสกุล"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customerlastname" placeholder="นามสกุล" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customernickname']" label="ชื่อเล่น"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customernickname" placeholder="ชื่อเล่น" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'customergender']" label="เพศ*"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-radio-group v-model:value="formState.user.customergender" name="radioGroup">
                <a-radio value="man">ชาย</a-radio>
                <a-radio value="female">หญิง</a-radio>
                <a-radio value="notspecified">ไม่ระบุ</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customerbirthday']" label="วันเกิด"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-radio-group v-model:value="formState.user.customerbirthdaytype" name="radioGroup">
                <a-radio value="1">ยังไม่แจ้ง</a-radio>
                <a-radio value="2">พ.ศ.</a-radio>
                <a-radio value="3">ค.ศ</a-radio>
              </a-radio-group>
              <a-date-picker v-if="formState.user.customerbirthdaytype != 1"
                @change="calculateAge(formState.user.customerbirthday)" v-model:value="formState.user.customerbirthday"
                value-format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item class="" :name="['user', 'sellorderTransaction']" label="อายุ"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-input-number id="inputNumber" v-model:value="age" :min="1" :max="100" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item ref="" :name="['user', 'customercareer']" label="อาชีพ"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customercareer" placeholder="กรอกอาชีพ" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2"></a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customerlineid']" label="LINE ID">
              <a-input v-model:value="formState.user.customerlineid" placeholder="LINE ID">
                <template #prefix>
                  <img class="w-5"
                    src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'customerfacebook']" label="FACEBOOK">
              <a-input v-model:value="formState.user.customerfacebook" placeholder="FACEBOOK">
                <template #prefix>
                  <img class="w-5" src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg" />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item class="" :name="['user', 'customercountry']" label="ประเทศ">
              <a-radio-group v-model:value="formState.user.CustomerAddressType" name="radioGroup">
                <a-radio value="1">ไทย</a-radio>
                <a-radio value="2">ต่างประเทศ</a-radio>
              </a-radio-group>
              <Address v-if="formState.user.CustomerAddressType == 1" @province="onprovince($event)"
                @districts="ondistricts($event)" @subdistricts="onsubdistricts($event)" @postcode="onpostcode($event)">
              </Address>
              <SelectOption v-if="formState.user.CustomerAddressType == 2" v-model:value="formState.user.selectcountry">
              </SelectOption>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'addressdesc']" label="ที่อยู่"
              :rules="[{ required: true, message: 'กรุณากรอก' }]">
              <a-textarea v-model:value="formState.user.addressdesc" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customerphone']" label="เบอร์ติดต่อ"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-input-group compact>
                <a-select show-search v-model:value="formState.user.selectcountry.selectcountry" placeholder="รหัสโทร"
                  style="width: 100px" :options="listcodephone" :filter-option="filterOption" @focus="handleFocus"
                  @blur="handleBlur" @change="handleChange"></a-select>
                <a-input v-model:value="formState.user.customerphone" style="width: 50%" />
              </a-input-group>
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'customerphoneemergency']" label="เบอร์ติดต่อ(ฉุกเฉิน)"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customerphoneemergency" placeholder="เบอร์ฉุกเฉิน" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
        <a-row type="flex">
          <a-col flex="1 1 500px" :order="1">
            <a-form-item :name="['user', 'customerphonethai']" label="เบอร์ติดต่อที่ไทย"
              :rules="[{ required: false, message: 'กรุณากรอก' }]">
              <a-input v-model:value="formState.user.customerphonethai" placeholder="เบอร์ติดต่อที่ไทย" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="2">
            <a-form-item :name="['user', 'customeremail']" label="E-Mail">
              <a-input v-model:value="formState.user.customeremail" placeholder="E-Mail" />
            </a-form-item>
          </a-col>
          <a-col flex="1 1 500px" :order="3"> </a-col>
        </a-row>
      </div>
      <a-card style="width: 600px">
        <pre v-if="formState.isshowjason">{{ formState }}</pre>
      </a-card>

      <!-- //old -->
      <!-- <a-row class="card" style="flex-direction: row;">
                <a-col :span="12">
                    <div
                        :style="{
                            height: heightComputed,
                            maxHeight: heightComputed
                        }"
                    >
                        <div class="p-3 text-xl text-center text-gray-500">
                            ลูกค้า
                        </div>

                        <a-form-item
                            :name="['user', 'customername']"
                            label="ชื่อลูกค้า"
                            :rules="[
                                {
                                    required: true,
                                    message: 'กรุณากรอก ชื่อลูกค้า'
                                }
                            ]"
                        >
                            <a-auto-complete
                                v-model:value="formState.user.customername"
                                :options="options"
                                style="width: 200px"
                                placeholder="Select a person"
                                @blur="handleBlur"
                                @change="handleChange"
                            />
                        </a-form-item>
                        <a-form-item
                            :name="['user', 'customeremail']"
                            label="อีเมลย์"
                            :rules="[
                                { type: 'email', message: 'กรุณากรอก อีเมลย์' }
                            ]"
                        >
                            <a-input
                                v-model:value="formState.user.customeremail"
                            />
                        </a-form-item>
                        <a-form-item
                            :name="['user', 'CustomerBirthDay']"
                            label="วันเกิด"
                            :rules="[
                                { required: true, message: 'กรุณากรอก วันเกิด' }
                            ]"
                            v-bind="config"
                        >
                            <a-date-picker
                                v-model:value="formState.user.CustomerBirthDay"
                                value-format="YYYY-MM-DD"
                                @change="handleCustomerBirthDayChange"
                            />
                        </a-form-item>
                        <a-form-item
                            class="pointer-events-none"
                            :name="['user', 'customerage']"
                            label="Age"
                            :rules="[{ type: 'number', min: 0, max: 99 }]"
                        >
                            <a-input-number
                                style="background: #80808038;"
                                v-model:value="formState.user.customerage"
                            />
                        </a-form-item>
                        <a-form-item
                            :name="['user', 'customertel']"
                            label="เบอร์โทรศัพท์ลูกค้า"
                        >
                            <a-input
                                v-model:value="formState.user.customertel"
                            />
                        </a-form-item>

                        <a-form-item :name="['user', 'lineid']">
                            <template v-slot:label>
                                LineID&nbsp;
                                <a-tooltip title="LineId ไม่ต้องมี @">
                                    <img
                                        class="w-4"
                                        src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                                    />
                                </a-tooltip>
                            </template>
                            <a-input v-model:value="formState.user.lineid" />
                        </a-form-item>
                        <a-form-item :name="['user', 'facebook']">
                            <template v-slot:label>
                                Facebook&nbsp;
                                <a-tooltip title="Facebook URI">
                                    <img
                                        class="w-4"
                                        src="https://cdn-icons-png.flaticon.com/512/124/124010.png"
                                    />
                                </a-tooltip>
                            </template>
                            <a-input v-model:value="formState.user.facebook" />
                        </a-form-item>
                        <a-form-item
                            :name="['user', 'CustomerAddress']"
                            label="ที่อยู่"
                        >
                            <a-textarea
                                v-model:value="formState.user.CustomerAddress"
                            />
                        </a-form-item>
                        <a-form-item
                            :name="['user', 'CustomerAddress']"
                            label="ที่อยู่"
                        >
                            <Address
                                @province="onprovince($event)"
                                @districts="ondistricts($event)"
                                @subdistricts="onsubdistricts($event)"
                                @postcode="onpostcode($event)"
                            ></Address>
                        </a-form-item>

                        <a-form-item
                            :name="['user', 'customerintroduction']"
                            label="รายละเอียด"
                        >
                            <a-textarea
                                v-model:value="
                                    formState.user.customerintroduction
                                "
                            />
                        </a-form-item></div
                ></a-col>
            </a-row> -->
      <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 12 }">
        <a-button type="dash" danger html-type="submit">ทำรายการ</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted, ref, watch } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import Address from "../../../Sell/SellCreate/component/Address.vue";
import SelectOption from "@/module/Sell/SellCreate/component/form/SelectOption.vue";
import moment from "moment";
import { Modal } from "ant-design-vue";
import Uploadfile from "../../../Sell/SellCreate/component/form/Uploadfile.vue";
export default defineComponent({
  name: "FromCreate",
  components: {
    Address,
    Uploadfile,
    SelectOption,
  },
  setup() {
    const title = "สร้างข้อมูลลูกค้า";
    const producttype = ref([]);
    const gender = ref("1");
    // const typebirthday = ref("1"); 
    const typelocal = ref("1");
    const listcodephone = ref([]);
    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 12,
      },
    };
    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };

    const calculateAge = (birthday) => {
      // birthday is a date
      var ageDifMs = Date.now() - new Date(birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    const onselectchange = (value) => {
      formState.user.customertel = value[0].item.CustomerContractNumber;
      formState.user.customeremail = value[0].item.CustomerEmail;
      formState.user.CustomerAddress = value[0].item.CustomerAddress;
      formState.user.CustomerBirthDay = value[0].item.CustomerBirthDay;
      formState.user.customerintroduction = value[0].item.CustomerDesc;

      handleCustomerBirthDayChange(formState.user.CustomerBirthDay);

      formState.user.lineid = value[0].item.LineID;
      formState.user.facebook = value[0].item.Facebook;
    };
    const formState = reactive({
      isshowjason: true,
      user: {
        sellorderTransaction: "",
        orderdate: null,
        name: "",
        age: undefined,
        email: "",
        website: "",
        tel: "",
        introduction: "",
        customername: "",
        customerage: undefined,
        customeremail: "",
        customerwebsite: "",
        customertel: "",
        customerintroduction: "",
        lineid: "",
        facebook: "",
        ProvinceID: "",
        ProvinceName: "",
        BorderID: "",
        BorderName: "",
        DistrictId: "",
        DistrictName: "",
        PostCode: "",
        customerproductstype: "1",
        selectcountry: { selectcountry: "TH" },
        customercode: "C-" + moment(new Date()).format("YYYYMMDDHHmmss"),
        customerbirthdaytype: "1"
      },
    });
    // setTimeout(() => {
    //     formState.user.orderdate = moment().format("YYYY-MM-DD");
    //   console.log(formState.user.orderdate);
    // }, 100);
    onMounted(() => {
      SellOrderTransaction();
    });
    const SellOrderTransaction = () => {
      BzbsUser.apiSellOrderTransaction(null)
        .then((res) => {
          return (formState.user.sellorderTransaction =
            res.data[0].SellOrderTransactionID);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onFinish = (values) => {
      createCustomer();
  
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    const options = ref([]);
    const handleChange = (value) => {
      onselectchange(options.value.filter((item) => item.value == value));

      console.log(` ${value}`);
    };

    const handleCustomerBirthDayChange = (value) => {
      formState.user.customerage = calculateAge(value);

      console.log(` ${value}`);
    };

    const handleBlur = () => {
      console.log("blur");
    };

    const handleFocus = () => {
      console.log("focus");
    };

    const filterOption = (input, option) => {
       
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    setTimeout(() => {
      getCustomer();
    }, 100);

    const getCustomer = () => {
      BzbsUser.apiGetCustomer(null)
        .then((res) => {
          res.data[0].forEach((element) => {
            console.log(element);

            options.value.push({
              value: element.CustomerName + " " + element.CustomerLastName,
              labe: element.CustomerName + " " + element.CustomerLastName,
              item: element,
            });
          });
          return res.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const success = () => {
      Modal.success({
        title: "ทำรายการสำเร็จ",
      });
    };
    const error = (message) => {
      Modal.error({
        title: message,
      });
    };

    watch(
      () => formState.user.CustomerAddressType,
      () => {
        if (formState.user.CustomerAddressType == "1") { 
          formState.user.selectcountry.selectcountry = "TH";
        }
      },
      () => formState.user.selectcountry.selectcountry,
      () => {
        if (
          listcodephone.value.find(
            (word) => word.value == formState.user.selectcountry.selectcountry
          )
        ) {
      
          formState.user.phonecode = listcodephone.value.filter(
            (word) => word.value == formState.user.selectcountry.selectcountry
          );
        }
      }
    );
    BzbsUser.apicountriesphone()
      .then((res) => {
        Object.keys(res.data).map(function (element) {
          listcodephone.value.push({
            value: element,
            label: res.data[element],
          });
        });
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
    const createCustomer = () => {
      //   var name = user.customername.split(" ")[0];
      //   var lastname = user.customername.split(" ")[1];
      var params = {
        // CustomerOrder: user.CustomerOrder,
        CustomerName: formState.user.customername,
        CustomerLastName: formState.user.customerlastname,
        CustomerCode: formState.user.customercode,
        CustomerContractNumber: formState.user.customerphone,
        CustomerBirthDay: formState.user.customerbirthday,
        CustomerEmail: formState.user.customeremail,
        CustomerAddress: formState.user.addressdesc,
        CustomerDesc: formState.user.introduction,
        ProvinceID: formState.user.ProvinceID,
        ProvinceName: formState.user.ProvinceName,
        BorderID: formState.user.BorderID,
        BorderName: formState.user.BorderName,
        DistrictId: formState.user.BorderID,
        DistrictID: formState.user.DistrictID,
        DistrictName: formState.user.DistrictName,
        PostCode: formState.user.PostCode,
        LineID: formState.user.customerlineid,
        Facebook: formState.user.customerfacebook,
        ContactName: formState.user.contactname,
        Contact: formState.user.contact,
        ImageURL: "",
        CustomerGender: formState.user.customergender,
        CustomerNickName: formState.user.customernickname,
        CustomerBirthDayType: formState.user.customerbirthdaytype,
        CustomerCareer: formState.user.customercareer,
        CustomerAddressType: formState.user.customeraddresstype,
        CustomerPhoneEmergency: formState.user.customerphoneemergency,
        CustomerPhoneThai: formState.user.customerphonethai,
      };
      console.log("params : ", params);
      BzbsUser.apiPostCreateCustomer(params)
        .then((res) => {
          if (res.data.error == "samephone") {
            error(res.data.error);
          } else {
            success();
            console.log(res.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onprovince = (values) => {
      console.log("onprovince", values);
      formState.user.ProvinceName = values.provinceName;
      formState.user.ProvinceID = values.provinceID;
    };
    const ondistricts = (values) => {
      console.log("ondistricts", values);
      formState.user.BorderName = values.districtsName;
      formState.user.BorderID = values.districtsID;
    };
    const onsubdistricts = (values) => {
      console.log("onsubdistricts", values);
      formState.user.DistrictID = values.subdistrictsID;
      formState.user.DistrictName = values.subdistrictsName;
    };
    const onpostcode = (values) => {
      console.log("onpostcode", values);
      formState.user.PostCode = values;
    };
    return {
      title,
      formState,
      onFinish,
      layout,
      validateMessages,
      SellOrderTransaction,
      onselectchange,
      calculateAge,
      onFinishFailed,
      options,
      handleChange,
      handleBlur,
      handleFocus,
      filterOption,
      createCustomer,
      handleCustomerBirthDayChange,
      success,
      onprovince,
      ondistricts,
      onsubdistricts,
      onpostcode,
      producttype,
      typelocal,
      gender,
      listcodephone,
    };
  },
});
</script>
<style lang="scss" scoped>
.ant-row {
  flex-direction: row;
}
</style>
