<template> 
    <a-button v-if="type == 'add' && layout == 'drag'" :size="'large'" block type="dashed" @click="showModal">
        <template #icon>
            <plus-outlined />
        </template>
        เพิ่ม content
    </a-button>
    <a-button v-else-if="type == 'add'" :size="'large'" type="primary" @click="showModal">
        <template #icon>
            <plus-outlined />
        </template>
        เพิ่ม content
    </a-button>
    <div v-if="type == 'footer'">
        <SelectImagefooter :type="'footer'" @onobjsalepageaddcontent="addvalue($event)"></SelectImagefooter>
    </div>
    <div v-if="!onEdit.isshowedit">

        <div v-if="type == 'add'">
            <a-modal v-model:visible="visible" title="เลือก" @ok="handleOk" :footer="null">
                <SelectImageVue :type="'add'" @onobjsalepageaddcontent="addvalue($event)"></SelectImageVue>
            </a-modal>
        </div>
    </div>
    <div v-else-if="onEdit.isshowedit && type == 'edit'">
        <SelectImageVue @onobjsalepageaddcontent="addvalue($event)"></SelectImageVue>
        <SelectImagefooter :type="'footer'" @onobjsalepageaddcontent="addvalue($event)"></SelectImagefooter>
    </div> 
</template>
<script>
import { defineComponent, ref } from "vue";
import SelectImageVue from "./SelectImage.vue";
import SelectImagefooter from "./SelectImagefooter.vue";
import { objsalepage, objsalepagethank } from '@/store/store'
import { value, onEdit, componentcount, isclickaddcontent, swapaddcontent } from '@/store/store'
import { PlusOutlined } from '@ant-design/icons-vue';
export default defineComponent({
    props: ['type', 'edit', 'layout'],
    components: {
        SelectImageVue,
        SelectImagefooter,
        PlusOutlined
    },
    setup(props, { emit }) {
        const istypefooter = ref(false)
        const visible = ref(false);
        istypefooter.value = props.type == 'footer' ? true : false;
        if (props.type == 'edit') {
            console.log(onEdit)
            const selected = ref(onEdit.value.type)
            value.value = selected.value
            visible.value = true;
        }
        const showModal = () => {
             
            swapaddcontent.value = false;
            visible.value = true;
            isclickaddcontent.value = true
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        // const objsalepage = ref()
        const addvalue = (e) => {

            console.log(e);
            // objsalepage.value = e
            visible.value = false;
            console.log(objsalepagethank)

            emit('onobjsalepage', objsalepage)   // emit('onobjsalepage', objsalepage)
        }

        return {
            addvalue,
            visible,
            showModal,
            handleOk, onEdit, componentcount,
            istypefooter, objsalepage
        };
    }
});
</script>
