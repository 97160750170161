<template>
    <div>
        <a-button type="dash" danger @click="visible = true">ข้อมูลจัดส่ง</a-button>
        <a-modal
            v-model:visible="visible"
            title="เลือกข้อมูลจัดส่ง"
            ok-text="บันทึก"
            cancel-text="Cancel"
            @ok="onOk"
        >
            <a-form
                ref="formRef"
                :model="formState"
                layout="vertical"
                name="form_in_modal"
            >
                <a-form-item
                    name="title"
                    label="หัวข้อ"
                    :rules="[
                        {
                            required: true,
                            message: 'Please input the title of collection!'
                        }
                    ]"
                >
                    <a-input v-model:value="formState.title" />
                </a-form-item>
                <a-form-item name="description" label="รายละเอียด">
                    <a-textarea v-model:value="formState.description" />
                </a-form-item>
                <Address
                    v-if="formState.modifier == 'private'"
                    @province="onprovince($event)"
                    @districts="ondistricts($event)"
                    @subdistricts="onsubdistricts($event)"
                    @postcode="onpostcode($event)"
                    @prompostcode="onprompostcode($event)"
                ></Address>
                <a-form-item
                    name="modifier"
                    class="collection-create-form_last-form-item"
                >
                    <a-radio-group v-model:value="formState.modifier">
                        <a-radio value="public">ต่างประเทศ</a-radio>
                        <a-radio value="private">ในประเทศ </a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import Address from "./Address.vue";
import { defineComponent, reactive, ref, toRaw, inject } from "vue";
export default defineComponent({
    components: {
        Address
    },
    emits: ["onmodaladdresschagne"],
    setup(props, ctx) {
        const Storedata = inject("Storedata");
        const formRef = ref();
        const visible = ref(false);
        const province = ref();
        const districts = ref();
        const subdistricts = ref();
        const postcode = ref();
        const prompostcode = ref();

        const formState = reactive({
            title: "",
            description: "",
            modifier: "public"
        });

        const onprovince = value => {
            province.value = value;
        };
        const ondistricts = value => {
            districts.value = value;
        };
        const onsubdistricts = value => {
            subdistricts.value = value;
        };
        const onpostcode = value => {
            postcode.value = value;
        };
        const onprompostcode = value => {
            prompostcode.value = value;
        };

        const onOk = () => {
            formRef.value
                .validateFields()
                .then(values => {
                    console.log("Received values of form: ", values);
                    console.log("formState: ", toRaw(formState));
                    visible.value = false;
                    let address = {
                        province: province.value,
                        districts: districts.value,
                        subdistricts: subdistricts.value,
                        postcode: postcode.value,
                        prompostcode: prompostcode.value
                    };
                    values["address"] = address;
                    Storedata.state.createproduct["shipping"] = values;
                    ctx.emit("onmodaladdresschagne", values);
                    // formRef.value.resetFields();
                    console.log("reset formState: ", toRaw(formState));
                })
                .catch(info => {
                    console.log("Validate Failed:", info);
                });
        };

        return {
            formState,
            formRef,
            visible,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            onprompostcode,
            onOk
        };
    }
});
</script>
<style>
.collection-create-form_last-form-item {
    margin-bottom: 0;
}
</style>
