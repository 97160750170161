<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="เพิ่มแบบฟอร์ม" @ok="handleOk" @cancel="close()">
      <a-form :layout="formState.layout" :model="formState" v-bind="formItemLayout">
        <a-form-item label="หัวข้อ">
          <a-input v-model:value="formState.title" placeholder="input placeholder" />
        </a-form-item>
        <!-- <a-form-item label="Line Token">
          <a-input v-model:value="formState.linetoken" placeholder="input placeholder" />
        </a-form-item> -->

        <a-space v-for="(user, index) in dynamicValidateForm.items" :key="user.id"
          style="display: flex; margin-bottom: 8px" align="baseline">

          <a-space v-if="user.type == 1">
            <a-form-item :label="'ตัวเลือกที่ ' + parseInt(index + 1)" :name="['items', index, 'first']" :rules="{
              required: true,
              message: 'กรอกตัวเลือก',
            }">
              <a-input v-model:value="user.first" placeholder="ตัวเลือก" />
            </a-form-item>
            <a-form-item :label="'ราคาที่  ' + parseInt(index + 1)" :name="['items', index, 'last']" :rules="{
              required: true,
              message: 'กรอกราคา',
            }">
              <a-input v-model:value="user.last" placeholder="ราคา" />
            </a-form-item>
          </a-space>

          <a-space v-if="user.type == 2">
            <a-form-item :label="'ชื่อนามสกุล '" :name="['items', index, 'namelastname']" :rules="{
              required: true,
              message: 'ชื่อ-นามสกุล',
            }">
              <a-input :disabled="true" v-model:value="user.namelastname" placeholder="ชื่อ-นามสกุล" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 3">
            <a-form-item :label="'อีเมลย์ '" :name="['items', index, 'email']" :rules="{
              required: true,
              message: 'อีเมลย์',
            }">
              <a-input :disabled="true" v-model:value="user.email" placeholder="อีเมลย์" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 4">
            <a-form-item :label="'เบอร์โทร '" :name="['items', index, 'tel']" :rules="{
              required: true,
              message: 'เบอร์โทร',
            }">
              <a-input :disabled="true" v-model:value="user.tel" placeholder="เบอร์โทร" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 5">
            <a-form-item :label="'LINE '" :name="['items', index, 'line']" :rules="{
              required: true,
              message: 'LINE',
            }">
              <a-input :disabled="true" v-model:value="user.line" placeholder="Line" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 6">
            <a-form-item :label="'ที่อยู่จัดส่งสินค้า '" :name="['items', index, 'address']" :rules="{
              required: true,
              message: 'ที่อยู่จัดส่งสินค้า',
            }">
              <a-textarea :disabled="true" v-model:value="user.line" placeholder="ที่อยู่จัดส่งสินค้า" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 7">
            <a-form-item :label="'ยอดที่ชำระ '" :name="['items', index, 'payprice']" :rules="{
              required: true,
              message: 'ยอดที่ชำระ',
            }">
              <a-input :disabled="true" v-model:value="user.custom" placeholder="ยอดที่ชำระ" />
            </a-form-item>
          </a-space>
          <a-space v-else-if="user.type == 8">
            <a-form-item :label="'กำหนดเอง (ชื่อ Input) '" :name="['items', index, 'custom']" :rules="{
              required: true,
              message: 'กำหนดเอง (ชื่อ Input)',
            }">
              <a-input v-model:value="user.custom" placeholder="กำหนดเอง" />
            </a-form-item>
          </a-space>

          <!-- <a-space v-else-if="user.type == 8">
            <a-form-item :label="'ช่องทางการชำระเงิน '" :name="['items', index, 'ช่องทางการชำระเงิน']" :rules="{
              required: true,
              message: 'ช่องทางการชำระเงิน',
            }">
              <a-input v-model:value="user.payment" placeholder="ช่องทางการชำระเงิน" />
            </a-form-item>
          </a-space> -->


          <MinusCircleOutlined @click="removeUser(user)" />
        </a-space>
        <!-- <a-form-item>
          <a-button type="dashed" block @click="addUser('product')">
            <PlusOutlined />
            เพิ่ม
          </a-button>
        </a-form-item> -->
        <a-form-item>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item :key="['1', 'product']">สินค้า</a-menu-item>
                <a-menu-item :key="['2', 'namelastname']">ชื่อ-นามสกุล</a-menu-item>
                <a-menu-item :key="['3', 'email']">อีเมล</a-menu-item>
                <a-menu-item :key="['4', 'tel']">เบอร์โทร</a-menu-item>
                <a-menu-item :key="['5', 'line']">Line</a-menu-item>
                <a-menu-item :key="['6', 'address']">ที่อยู่จัดส่งสินค้า</a-menu-item>
                <a-menu-item :key="['7', 'payprice']">ยอดชำระ</a-menu-item>
                <a-menu-item :key="['8', 'custom']">กำหนดเอง</a-menu-item>
                <!-- <a-menu-item :key="['8', 'pay']">ช่องทางการชำระเงิน</a-menu-item> -->
              </a-menu>
            </template>
            <a-button>
              ตัวจัดการ
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </a-form-item>
        <!-- <a-form-item :wrapper-col="buttonItemLayout.wrapperCol">
            <a-button type="primary" html-type="submit">ยืนยัน</a-button>
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { MinusCircleOutlined } from '@ant-design/icons-vue';
import { value } from '@/store/store'
export default defineComponent({
  components: {
    MinusCircleOutlined,

  },
  emits: ['onform'],
  setup(props, { emit }) {
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    showModal()
    const close = () => {
      visible.value = false
      value.value = ["เลือก"]
    }
    const handleOk = e => {
      visible.value = false
      formState.option = dynamicValidateForm
      emit('onform', { value: formState, type: 'form' })
      console.log(e);
      visible.value = false;
    };
    const formState = reactive({
      layout: 'vertical',
      title: '',
      linetoken: '',
    });
    const formItemLayout = computed(() => {
      const {
        layout,
      } = formState;
      return layout === 'horizontal' ? {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      } : {};
    });
    const buttonItemLayout = computed(() => {
      const {
        layout,
      } = formState;
      return layout === 'horizontal' ? {
        wrapperCol: {
          span: 14,
          offset: 4,
        },
      } : {};
    });


    const formRef = ref();
    const dynamicValidateForm = reactive({
      items: [],
    });

    const removeUser = item => {
      let index = dynamicValidateForm.items.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.items.splice(index, 1);
      }
    };

    const addUser = (type) => {
      dynamicValidateForm.items.push({
        first: '',
        last: '',
        namelastname: '',
        email: '',
        tel: '',
        line: '',
        address: '',
        custom: '',
        pay: '',
        type: type,
        id: Date.now(),
      });
    };

    const onFinish = values => {
      console.log('Received values of form:', values);
      console.log('dynamicValidateForm.items:', dynamicValidateForm.items);
    };
    const handleMenuClick = e => {
      if (e.key[0] == 1) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 2) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 3) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 4) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 5) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 6) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 7) {
        addUser(e.key[0])
      }
      else if (e.key[0] == 8) {
        addUser(e.key[0])
      }
    };
    return {
      handleMenuClick,
      close,
      visible,
      showModal,
      handleOk,
      formState,
      formItemLayout,
      buttonItemLayout,
      formRef,
      dynamicValidateForm,
      onFinish,
      removeUser,
      addUser,
    };
  },

});
</script>