<template>
    <v-card class="p-4">
        <slot v-bind="$props">
            <div class="text-center card-content">
                <div class="label text-lg font-bold text-gray-500 mb-3">{{ label }}</div>
                <pie-chart-outlined v-if="colindex==1" :style="{
                    fontSize: '45px',
                    color: '#08c',
                    padding: '10px'
                }" />
                <bar-chart-outlined v-if="colindex==2" :style="{
                    fontSize: '45px',
                    color: '#08c',
                    padding: '10px'
                }" />
                <LineChartOutlined v-if="colindex==3" :style="{
                    fontSize: '45px',
                    color: '#08c',
                    padding: '10px'
                }" />
                <div class="value font-semibold text-3xl">
                    <!-- {{ fomatNum(value) }} -->
                    <vue3-autocounter ref='counter' :startAmount='0' :endAmount='value' :duration='3' prefix=''
                        suffix=' บาท' separator=',' decimalSeparator='.' :decimals='2' :autoinit='true' />
                </div>
                <div class="description" :class="[diff > 0 ? 'increase' : 'decrease']">
                    <!-- {{ diff > 0 ? "+" : "" }}
                    <vue3-autocounter ref='counter' :startAmount='0' :endAmount='diff' :duration='3' prefix=''
                        suffix=' %' separator=',' decimalSeparator='.' :decimals='2' :autoinit='true'
                        @finished='alert(`Counting finished!`)' /> -->
                    (

                    <vue3-autocounter ref='counter' :startAmount='0' :endAmount='percentage' :duration='3' prefix=''
                        suffix=' %' separator=',' decimalSeparator='.' :decimals='2' :autoinit='true' />
                    )
                </div>

            </div>
        </slot>
    </v-card>
</template>

<script>
import {
    PieChartOutlined,
    BarChartOutlined,
    LineChartOutlined
} from "@ant-design/icons-vue";
import Util from "@/helper/Utility";
import Vue3autocounter from 'vue3-autocounter';
export default {
    name: "DashboardDataCard",
    components: {
        PieChartOutlined,
        BarChartOutlined,
        LineChartOutlined,
        Vue3autocounter
    },
    props: {
        label: {
            type: String
        },
        colindex: {
            type: Number
        },
        value: {
            type: Number,
            default: 0
        },
        values: {
            type: Array,
            default: () => []
        },
        diff: {
            type: Number,
            default: 0
        },
        percentage: {
            type: Number,
            default: 0
        }
    },
    methods: {
        fomatNum: function (val) {
            return Util.formatNum(val, 0);
        }
    }
};
</script>

<style lang="css" scoped>
.card-content .value {
    color: #666c8b !important;
}

.card-content .description.increase {
    color: #2ec743;
}

.card-content .description.decrease {
    color: #fb7290;
}
</style>
