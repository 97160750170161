import Util from "@/helper/Utility.js";
import _ from "lodash";
export function ContactModel(Contact) {
    // ProductSellOrder= Contact.sellorderTransaction,
    // CustomerOrder= Contact.customercode,
    this.Contactorder = Contact.contactorder || "";
    this.ProductSellOrder = Contact.productsellorder || "";
    this.CustomerOrder = Contact.customerorder || "";
    this.Introduction = Contact.introduction || "";
    this.Key = _.toString(Contact.key) || "";
    this.ContactType = Contact.contacttype || "";
    this.ContactName = Contact.contactname || "";
    this.ContactImage = Contact.contactimage || "";
    this.ContactImageUrl = Contact.contactimageurl || "";
    this.ContactStartDefault = Contact.contactstartdefault || "";
    this.info1 = Contact.info1 || "";
    this.info2 = Contact.info2 || "";
    this.info3 = Contact.info3 || "";
    this.info4 = Contact.info4 || "";
}

export function GetContactModel(Contact) {
    this.contactorder = Contact.Contactorder || "";
    (this.productsellorder = Contact.ProductSellOrder),
        (this.customerorder = Contact.CustomerOrder),
        (this.introduction = Contact.Introduction || "");
    this.key = _.toString(Contact.Key) || "";
    this.contacttype = Contact.ContactType || "";
    this.contactname = Contact.ContactName || "";
    this.contactimage = Contact.ContactImage || "";
    this.contactimageurl = Contact.ContactImageUrl || "";
    this.contactstartdefault = _.toString(Contact.ContactStartDefault) || "";
    this.info1 = Contact.info1 || "";
    this.info2 = Contact.info2 || "";
    this.info3 = Contact.info3 || "";
    this.info4 = Contact.info4 || "";
}

export function GetProductBirthday(Contact) {
    this.productbirthdayOoder = Contact.ProductBirthdayOrder || "";
    this.productsellorder = Contact.ProductSellOrder;
    this.customerorder = Contact.CustomerOrder;
    this.typebirthday = Contact.TypeBirthday || "";
    this.career = Contact.Career || "";
    this.details = Contact.Details || "";
    this.id = Contact.Id || "";
    this.name = Contact.Name || "";
    this.lastname = Contact.LastName || "";
    this.nickname = Contact.NickName || "";
    this.age = Contact.Age || "";
    this.birthday = Contact.Birthday || "";
    this.yearbirthday = Contact.YearBirthday || "";
    this.birthdayname = Contact.BirthdayName || "";
    this.idcard = Contact.IdCard || "";
    this.productkey = Contact.ProductKey || "";
    this.DisplayName = Contact.DisplayName || "";
    this.PictureUrl = Contact.PictureUrl || "";
    this.UserId = Contact.UserId || "";
    this.StatusMessage = Contact.StatusMessage || "";
}
export function CustomerModel(Contact) {
    this.ProductSellOrder = Contact.sellorderTransaction || "";
    this.CustomerOrder = Contact.customerorder || "";
    this.CustomerName = Contact.customername || "";
    this.CustomerLastName = Contact.customerlastname || "";
    this.CustomerCode = Contact.customercode || "";
    this.CustomerContractNumber = Contact.customerphone || "";
    this.CustomerBirthDay = Contact.customerbirthday || null;
    this.CustomerEmail = Contact.customeremail || "";
    this.CustomerAddress = Contact.addressdesc || "";
    this.CustomerDesc = Contact.customerdesc || "";
    this.ProvinceID = Contact.ProvinceID || "";
    this.ProvinceName = Contact.ProvinceName || "";
    this.BorderID = Contact.BorderID || "";
    this.BorderName = Contact.BorderName || "";
    this.DistrictId = Contact.BorderID || "";
    this.DistrictName = Contact.DistrictName || "";
    this.PostCode = Contact.PostCode || "";
    this.LineID = Contact.customerlineid || "";
    this.Facebook = Contact.customerfacebook || "";
    this.ContactName = Contact.contactname || "";
    this.Contact = Contact.contact || "";
    (this.ImageURL = ""), (this.CustomerGender = Contact.customergender || "");
    this.CustomerNickName = Contact.customernickname || "";
    this.CustomerBirthDayType = Contact.customerbirthdaytype || "";
    this.CustomerCareer = Contact.customercareer || "";
    this.CustomerAddressType = Contact.customeraddressType || "";
    this.CustomerPhoneEmergency = Contact.customerphoneemergency || "";
    this.CustomerPhoneThai = Contact.customerphonethai || "";
    this.CustomerPhoneNoCode = Contact.customerphonenocode || ""; 
    this.SwitchNotHasPhoneCode = Contact.switchnothasphonecode || ""; 
    this.SelectCountry = Contact.selectcountry.selectcountry || "";
    this.SelectCountryCode = Contact.selectcountry.selectcountrycode?.toString() || "";
    this.ProvincePostinter = Contact.provincepostinter || "";
    this.CityPostinter = Contact.citypostinter || "";
    this.PostcodePostinter = Contact.postcodepostinter || "";
    this.Qty = Contact.qty || "";
}

export function ProductModel(Contact) { 

    Contact = _.transform(Contact, function(result, val, key) {
        result[key.toLowerCase()] = val;
    });

    if (typeof Contact.productdetail != "string") {
        Contact.productdetail = JSON.stringify(Contact.productdetail);
    }
    if (typeof Contact.professortype != "string") {
        Contact.professortype = JSON.stringify(Contact.professortype);
    }
    if (typeof Contact.productjson != "string") {
        Contact.productjson = JSON.stringify(Contact.productjson);
    }
    if (typeof Contact.usercommission != "string") {
        Contact.usercommission = JSON.stringify(Contact.usercommission);
    }
 
    if (typeof Contact.productlogoimage != "string") {
        Contact.productlogoimage = JSON.stringify(Contact.productlogoimage);
    }
    debugger
    this.Setting_IsSentNotiQueueCustomer = Contact.setting_issentnotiqueuecustomer;
    this.Setting_IsSentNotiComingYanCustomer = Contact.setting_issentnoticomingyancustomer;
    this.IsSentNotiQueueCustomer = Contact.issentnotiqueuecustomer;
    this.IsSentNotiComingYanCustomer = Contact.issentnoticomingyancustomer;
    this.ProductSellOrder = Contact.productsellorder || "";
    this.CustomerOrder = Contact.customerorder || "";
    this.OrderDate = Contact.orderdate || "";
    this.OrderTransaction = Contact.ordertransaction || "";
    this.OrderTransactionFrom = Contact.ordertransactionfrom || "";

    this.ProductKey = Contact.productkey?.toString() || "";
    this.ProductName = Contact.productname || "";
    this.ProductType = Contact.producttype || "";
    // this.Info1 = Contact.info1 || "";
    // this.Info2 = Contact.info2 || "";
    // this.Info3 = Contact.info3 || "";
    // this.Info4 = Contact.info4 || "";
    this.ProductSize = Contact.productsize || "";
    this.OriginalPrice = Contact.originalprice || "";
    this.ProductPrice = Contact.productprice
        ? Contact.productprice.toString()
        : "";
    this.LogoPrice = Contact.logoprice; 
     
    this.ProductLogoImage = Contact.productlogoimage;
    this.ProductStock = Contact.productstock || "";
    this.Queyan = Contact.queyan || "";
    this.CustomerProductsType = Contact.customerproductstype || "";
    this.ProductCode = Contact.productcode || "";
    this.CustomerProductsSize = Contact.customerproductssize || "";
    this.HeartYanName = Contact.heartyanname || "";
    this.ProductDetail = Contact.productdetail || "";
    this.IntroductionYan = Contact.introductionyan || "";
    this.ProfessorType = Contact.professortype || "";
    this.DeliveryType = Contact.deliverytype || ""; 
    this.Introduction = Contact.introduction || "";
    this.ProductYanImage = Contact.productyanimage || "";
    this.Career = Contact.career || "";
    this.IntroductionCareer = Contact.introductioncareer || "";
    this.LineId = Contact.lineid || "";
    this.Facebook = Contact.facebook || "";
    this.Phone = Contact.phone || "";
    this.PhoneEmergency = Contact.phoneemergency || "";
    this.CustomerPhoneThai = Contact.customerphonethai || "";
    this.Email = Contact.email || "";
    this.TrackYan = Contact.trackyan || "";
    this.SystemTrackYan = Contact.systemtrackyan || "";
    this.QueYanDate = Contact.queyandate || "";
    this.SystemYanDate = Contact.systemyandate || "";
    this.YanComingDate = Contact.yancomingdate || "";
    this.QueYanImage = Contact.queyanimage || "";
    this.SystemYanImage = Contact.systemyanimage || "";
    this.ProductJson = Contact.productjson || "";
    this.ProductResponsiblePerson = Contact.productresponsibleperson || "";
    this.CustomerName = Contact.customername || "";
    this.CustomerLastName = Contact.customerlastname || "";
    this.Qty = Contact.qty || 1;
    this.IncreaseSizeDesc = Contact.increasesizedesc || "";
    this.IncreaseSizeOrder = Contact.increasesizeorder || "";

    this.CustomerLineId = Contact.customerlineid || "";
    this.CustomerFacebook = Contact.customerfacebook || "";
    this.CustomerPhone = Contact.customerphone || "";
    this.CustomerPhoneemergency = Contact.customerphoneemergency || "";
    this.CustomerDesc = Contact.customerdesc || "";
    this.CustomerInternationalPhone = Contact.customerinternationalphone || "";
    this.CustomerAddressType = Contact.customeraddresstype || "";
    this.CustomerSelectCountry = Contact.customerselectcountry || "";
    this.CustomerSelectcountryCode = Contact.customerselectcountrycode || "";
    this.RemindStatus = Contact.remindstatus || "";
    this.RemindSentComingDesc = Contact.remindsentcomingdesc || "";
    this.RemindSentDesc = Contact.remindsentdesc || "";
    this.RemindNotpayDelivery = Contact.remindnotpaydelivery || "";
    this.CustomerNotConfirmAddressAlready = Contact.customernotconfirmaddressalready || "";
    this.CustomerConfirmAddressAlready = Contact.customerconfirmaddressalready || ""; 
    this.ComingYanImage = Contact.comingyanimage || ""; 
    this.Category_1 = Contact.category_1 || ""; 
    this.Category_1_Code = Contact.category_1_code || ""; 
    this.Category_2 = Contact.category_2 || ""; 
    this.Category_2_Code = Contact.category_2_code || ""; 
    this.Category_3 = Contact.category_3 || ""; 
    this.Category_3_Code = Contact.category_3_code || ""; 
    this.Category_4 = Contact.category_4 || ""; 
    this.Category_4_Code = Contact.category_4_code || "";  
    this.Shotness = Contact.shotness || "";   
    this.QuantityStock= Contact.quantitystock || "";   
    this.Stockuuid= Contact.stockuuid || "";   
    this.UserCommission= Contact.usercommission || "";   
    
  
}
export function ProductBirthday(Contact) {
    Contact = _.transform(Contact, function(result, val, key) {
        result[key.toLowerCase()] = val;
    });
    this.ProductSellOrder = Contact.productsellorder || "";
    this.CustomerOrder = Contact.customerorder || "";
    this.TypeBirthday = Contact.typebirthday || "";
    this.Career = Contact.career || "";
    this.Details = Contact.details || "";
    this.Id = Contact.id?.toString() || "";
    this.Name = Contact.name || "";
    this.LastName = Contact.lastname || "";
    this.NickName = Contact.nickname || "";
    this.Age = Contact.age?.toString() || "";
    this.Birthday = Contact.birthday || "";
    this.YearBirthday = Contact.yearbirthday || "";
    this.BirthdayName = Contact.birthdayname || "";
    this.IdCard = Contact.idcard || "";
    this.ProductKey = Contact.productkey || "";
    this.DisplayName = Contact.displayName || "";
    this.PictureUrl = Contact.pictureUrl || "";
    this.UserId = Contact.userId || "";
    this.StatusMessage = Contact.statusMessage || "";
}
export function ProductDeliveryAddressModel(Contact) {
    Contact = _.transform(Contact, function(result, val, key) {
        result[key.toLowerCase()] = val;
    });

    this.SwitchNotHasPhoneCode = Contact.switchnothasphonecode || ""; 
    this.ProductAddressDeliveryOrder =
        Contact.productaddressdeliveryorder || "";
    this.ProductSellOrder = Contact.productsellorder || "";
    this.CustomerOrder = Contact.customerorder || "";
    this.TypeAddress = Contact.typeaddress || "";
    this.SelectCountry = Contact.selectcountry || "";
    this.Name = Contact.name || "";
    this.Lastname = Contact.lastname || "";
    this.Address = Contact.address || "";
    this.PhoneCode = JSON.stringify(Contact.phonecode) || "";
    this.Phone = Contact.phone || "";
    this.Email = Contact.email || "";
    this.ProvinceName = Contact.provincename || "";
    this.CountryName = Contact.countryname || "";
    this.ProvinceID = Contact.provinceid || "";
    this.BorderName = Contact.bordername || "";
    this.BorderID = Contact.borderid || "";
    this.DistrictName = Contact.districtname || "";
    this.PostCode = Contact.postcode || "";
    this.AddressKey = Contact.addresskey || "";
    this.SetDefault = Contact.setdefault || "0";
    this.CheckedSend =
        (typeof Contact.checkedsend === "string"
            ? Contact.checkedsend
            : JSON.stringify(Contact.checkedsend)) || "";
}
export function PaymentModel(Contact) {
    Contact = _.transform(Contact, function(result, val, key) {
        result[key.toLowerCase()] = val;
    });
     
 
    this.ProductSellOrder =
        Contact.ProductSellOrder || Contact.productsellorder || "";
    this.CustomerOrder = Contact.CustomerOrder || Contact.customerorder || "";
    this.PayType = Contact.PayType || Contact.paytype || "";
    this.IsCalVat = Contact.IsCalVat || Contact.iscalvat || "";
    this.PaymentMethod = Contact.PaymentMethod || Contact.paymentmethod || "";
    this.FromBank = Contact.FromBank || Contact.frombank || "";
    this.Tobank = Contact.Tobank || Contact.tobank || "";
    this.Price = Contact.Price || Contact.price || 0;
    this.SlipImage =
        Contact.slipimageurl || Contact.SlipImage || Contact.slipimage || "";
    this.Date = Contact.Date || Contact.date || "";
    this.Time = Contact.Time || Contact.time || "";
    this.TimeText = Contact.TimeText || Contact.timetext || "";
    this.AccountName = Contact.AccountName || Contact.accountname || "";
    this.Name = Contact.Name || Contact.name || "";
    this.NumberRef = Contact.NumberRef || Contact.numberref || "";
    this.BankName = Contact.BankName || Contact.bankname || "";
    this.PayoutNumber = Contact.PayoutNumber || Contact.payoutnumber || "";
    this.SenderName = Contact.SenderName || Contact.sendername || "";
    this.RecipientName = Contact.RecipientName || Contact.recipientname || "";
    this.Address = Contact.Address || Contact.address || "";
    this.SelectCountry = Contact.SelectCountry || Contact.selectcountry || "";
    this.BillingAmount = Contact.BillingAmount || Contact.billingamount || "";
    this.NetAmount = Contact.NetAmount || Contact.netamount || "";
    this.Introduction = Contact.Introduction || Contact.introduction || "";
    this.PayMentType = Contact.PayMentType || Contact.paymenttype || "normal";
    this.ProductKey = Contact.ProductKey || Contact.productkey || "";
    this.OrderTransaction = Contact.OrderTransaction || Contact.ordertransaction || "";
    this.Checked_Pay_Extra = Contact.Checked_Pay_Extra || Contact.checked_pay_extra || "";
    
}


export function PaymentAdditionalModel(Contact) { 
     
 
    this.ProductSellOrder =
        Contact.ProductSellOrder || Contact.productsellorder || "";
    this.CustomerOrder = Contact.CustomerOrder || Contact.customerorder || "";
    this.PayType = Contact.PayType || Contact.paytype || "";
    this.PaymentMethod = Contact.PaymentMethod || Contact.paymentmethod || "";
    this.FromBank = Contact.FromBank || Contact.frombank || "";
    this.Tobank = Contact.Tobank || Contact.tobank || "";
    this.Price = Contact.Price || Contact.price || 0; 
    this.SlipImage =
        Contact.slipimageurl || Contact.SlipImage || Contact.slipimage || "";
    this.Date = Contact.Date || Contact.date || "";
    this.Time = Contact.Time || Contact.time || "";
    this.TimeText = Contact.TimeText || Contact.timetext || "";
    this.AccountName = Contact.AccountName || Contact.accountname || "";
    this.Name = Contact.Name || Contact.name || "";
    this.NumberRef = Contact.NumberRef || Contact.numberref || "";
    this.BankName = Contact.BankName || Contact.bankname || "";
    this.PayoutNumber = Contact.PayoutNumber || Contact.payoutnumber || "";
    this.SenderName = Contact.SenderName || Contact.sendername || "";
    this.RecipientName = Contact.RecipientName || Contact.recipientname || "";
    this.Address = Contact.Address || Contact.address || "";
    this.SelectCountry = Contact.SelectCountry || Contact.selectcountry || "";
    this.BillingAmount = Contact.BillingAmount || Contact.billingamount || "";
    this.NetAmount = Contact.NetAmount || Contact.netamount || "";
    this.Introduction = Contact.Introduction || Contact.introduction || "";
    this.PayMentType = Contact.PayMentType || Contact.paymenttype || "pay_additional";
    this.ProductKey = Contact.ProductKey || Contact.productkey ||0; 
    this.AdditionalTage= Contact.AdditionalTage || Contact.additionaltage || ""; 
}
