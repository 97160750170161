<template>
  <a-form-item :name="['user', 'sellorderTransaction']">
    <a-radio-group v-model:value="is_paid_delivery" name="radioGroup">
      <a-radio value="1">ยังไม่ได้ชำระ</a-radio>
      <a-radio value="2">ชำระค่าจัดส่งแล้ว</a-radio>
    </a-radio-group>
  </a-form-item>
  <a-divider orientation="left" v-if="is_paid_delivery == 2"
    ><i><b>จัดส่งในประเทศ</b></i></a-divider
  >
  <a-form
    v-if="is_paid_delivery == 2"
    ref="formRef"
    name="dynamic_form_nest_item"
    :model="dynamicValidateForm"
    @finish="onFinish"
  >
    <a-form-item
      name="area"
      label="จัดส่งในประเทศ"
      :rules="[{ required: true, message: 'Missing area' }]"
    >
      <!-- <a-select v-model:value="dynamicValidateForm.area" :options="areas" /> -->
    </a-form-item>
    <a-space
      v-for="(sight, index) in dynamicValidateForm.sights"
      :key="sight.id"
      style="display: flex; margin-bottom: 8px"
      align="baseline"
    >
      <a-form-item
        :name="['sights', index, 'value']"
        label="ราคา"
        :rules="{
          required: true,
          message: 'Missing sight',
        }"
      >
        <a-input v-model:value="sight.price" />
      </a-form-item>
      <a-form-item
        label="หมายเหตุ"
        :name="['sights', index, 'price']"
        :rules="{
          required: true,
          message: 'Missing price',
        }"
      >
        <a-input v-model:value="sight.price" />
      </a-form-item>
      <MinusCircleOutlined @click="removeSight(sight)" />
    </a-space>
    <!-- <a-form-item>
      <a-button type="dashed" block @click="addSight">
        <PlusOutlined />
        เพิ่มรายการ
      </a-button>
    </a-form-item> -->
  </a-form>

  <a-divider orientation="left" v-if="is_paid_delivery == 2"
    ><i><b>จัดส่งต่างประเทศ</b></i></a-divider
  >
  <a-form
    v-if="is_paid_delivery == 2"
    ref="formRef"
    name="dynamic_form_nest_item"
    :model="dynamicValidateForm"
    @finish="onFinish"
  >
    <a-form-item
      name="area"
      label="จัดส่งต่างประเทศ"
      :rules="[{ required: true, message: 'Missing area' }]"
    >
      <a-select v-model:value="dynamicValidateForm.area" :options="areas" />
    </a-form-item>
    <a-space
      v-for="(sight, index) in dynamicValidateForm.sights"
      :key="sight.id"
      style="display: flex; margin-bottom: 8px"
      align="baseline"
    >
      <a-form-item
        :name="['sights', index, 'value']"
        label="ราคา"
        :rules="{
          required: true,
          message: 'Missing sight',
        }"
      >
        <a-input v-model:value="sight.price" />
      </a-form-item>
      <a-form-item
        label="หมายเหตุ"
        :name="['sights', index, 'price']"
        :rules="{
          required: true,
          message: 'Missing price',
        }"
      >
        <a-input v-model:value="sight.price" />
      </a-form-item>
      <MinusCircleOutlined @click="removeSight(sight)" />
    </a-space>
    <!-- <a-form-item>
      <a-button type="dashed" block @click="addSight">
        <PlusOutlined />
        เพิ่มรายการ
      </a-button>
    </a-form-item> -->
    <a-form-item>
      <a-button type="primary" html-type="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
  components: {
    MinusCircleOutlined,
    PlusOutlined,
  },

  setup() {
    const is_paid_delivery = ref("1");
    const areas = reactive([
      {
        label: "Beijing",
        value: "Beijing",
      },
      {
        label: "Shanghai",
        value: "Shanghai",
      },
    ]);
    BzbsUser.apicountries()
      .then((res) => {
        Object.keys(res.data).map(function (element) {
          areas.push({
            label: res.data[element],
            value: element,
          }); 
        });
      })
      .catch((error) => {
        console.log(error);
      });
    const sights = {
      Beijing: ["Tiananmen", "Great Wall"],
      Shanghai: ["Oriental Pearl", "The Bund"],
    };
    const formRef = ref();
    const dynamicValidateForm = reactive({
      sights: [],
      area: undefined,
    });
    watch(
      () => dynamicValidateForm.area,
      () => {
        dynamicValidateForm.sights = [];
      }
    );

    const removeSight = (item) => {
      let index = dynamicValidateForm.sights.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.sights.splice(index, 1);
      }
    };

    const addSight = () => {
      dynamicValidateForm.sights.push({
        value: undefined,
        price: undefined,
        id: Date.now(),
      });
    };

    const onFinish = (values) => {
      console.log("Received values of form:", values);
      console.log("dynamicValidateForm:", dynamicValidateForm);
    };

    return {
      formRef,
      dynamicValidateForm,
      onFinish,
      removeSight,
      addSight,
      areas,
      sights,
      is_paid_delivery,
    };
  },
});
</script>
<style lang="scss" scoped>
</style>