<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="รูป" @ok="handleOk" @cancel="close()">
      <UploadFile :sizeupimage="sizeupimage" @fileList="onchangeimages($event)"></UploadFile>
      <ul class="pt-3">
        <li>สกุลไฟล์ที่รองรับ: <span class="text-red-500">jpg, png, gif, jpeg</span></li>
        <li>ขนาดรูปที่เเนะนำ <span class="text-red-500">800 X 700</span></li>
        <li>ขนาดของไฟล์ที่อัพโหลดได้สูงสุดคือ <span class="text-red-500">10 MB</span></li>
      </ul>
      <a-input v-if="sizeupimage == 1" v-model:value="link" @change="onchangelink" addon-before="ลิ้งรูปภาพ" />
      <template #footer>
        <a-button key="back" @click="close()">ยกเลิก</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">ตกลง</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue';
import UploadFile from "@/module/Salepage/SalepageCreate/component/Uploadfile.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { value } from '@/store/store'
export default defineComponent({
  components: { UploadFile },
  props: ['sizeupimage'],
  setup(props, { emit }) {
    const visible = ref(false);
    const link = ref("");
    const showModal = () => {
      visible.value = true;
    };
    const formState = reactive({
      user: {
        facebookpixel: "",
        images: [],
        slideimages: []

      }
    });
    const close = () => {

      visible.value = false
      value.value = ["เลือก"]
    }
    showModal()
    const loading = ref(false)
    const handleOk = async (e) => {
      var uploadparam = {}
      loading.value = true
      if (formState.user.slideimages.length > 0) {
        var listslideimage = []

        for await (let element of formState.user.slideimages) {
          console.log(element)
          if (element.preview != undefined) {

            uploadparam = {
              filename: Date.now() +
                ".jpg",
              base64: element.preview,
              folder: "salepage"
            };
            await BzbsUser.apiuploadimage(uploadparam)
              .then(res => {
                loading.value = false
                listslideimage.push({ value: res.data.url, type: 'slideimage' })
                if (listslideimage.length == formState.user.slideimages.length) {
                  emit('onimage', { value: listslideimage, type: 'slideimage' })
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        }

      }
      else {
        if (formState.user.images[0].preview != undefined) {
          uploadparam = {
            filename: Date.now() +
              ".jpg",
            base64: formState.user.images[0].preview,
            folder: "salepage"
          };
          await BzbsUser.apiuploadimage(uploadparam)
            .then(res => {
              loading.value = false
              emit('onimage', { value: res.data.url, type: 'image', link: link.value })

            })
            .catch(error => {
              console.log(error);
            });
        }
      }
      // if (formState.user.images[0].preview != undefined) {
      //   var uploadparam = {};
      //   uploadparam = {
      //     filename: Date.now() +
      //       ".jpg",
      //     base64: formState.user.images[0].preview,
      //     folder: "salepage"
      //   };
      //   await BzbsUser.apiuploadimage(uploadparam)
      //     .then(res => {
      //       loading.value = false
      //       emit('onimage', { value: res.data.url, type: 'image' })

      //     })
      //     .catch(error => {
      //       console.log(error);
      //     });
      // }
      visible.value = false
      //  emit('onimage',{value:formState,type:'image'})
      console.log(e);
      visible.value = false;
    };
    const onchangeimages = e => {
      if (props.sizeupimage > 1) {
        formState.user.slideimages = e
        console.log(formState.user.slideimages);
      }
      else if (props.sizeupimage == 1) {
        e[0].link = link.value
        formState.user.images = e;
      }
    };
    return {
      loading,
      visible,
      showModal,
      handleOk,
      onchangeimages, formState,
      close
    };
  },

});
</script>