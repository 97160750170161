<template>
    <div class="p-4">
        <a-checkbox-group v-model:value="value" style="width: 100%">
            การจัดส่ง *
            <div>
                <CreateUserPassPost></CreateUserPassPost>
            </div>

            <a-row type="flex" class="p-2">
                <a-col :span="8">
                    <a-checkbox value="thailandpost">ไปรษณีย์ไทย</a-checkbox>
                </a-col>
            </a-row>
            <a-row type="flex" class="p-2">
                <a-col :span="8">
                    <a-checkbox value="thailandpostEMS">ไปรษณีย์ไทยต่างประเทศ(แบบสแกนใบ EMS)</a-checkbox>
                </a-col>
                <a-col :span="8" v-show="value == 'thailandpostEMS'">

                    <a-input-group compact>
                        <a-form-item has-feedback :validate-status="isshowpacking ? 'success' : ''"
                            style="width: calc(100% - 60%)">

                            <a-input-search v-model:value="scanems" style="width: 400px;" placeholder="สแกนใบกระดาษ EMS"
                                enter-button="ยืนยัน" size="large" @search="onEnter" />
                        </a-form-item>
                    </a-input-group>
                </a-col>
            </a-row>
        </a-checkbox-group>
        <slot :selected="value"></slot>
    </div>
</template>
<script lang="js">
import { defineComponent, ref } from 'vue';
import CreateUserPassPost from './CreateUserPassPost.vue';
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    setup() {
        const value = ref([]);
        const scanems = ref("");

        const onEnter = () => {
            //insert api

            var param_outside = {
                AdditionalTage: formStateStore.rowselecteddetail,
                TrackingNo: scanems.value
            }
            BzbsUser.apiupdateproductdeliverythailandposttracking(param_outside)
                .then(res => {
                    console.log(res.data);
                    formStateStore.updatecomponent++
                    // updatedata();
                })
                .catch(error => {
                    console.log(error);
                });

        }
        return {
            value,
            scanems,
            onEnter,
            formStateStore

        };
    },
    components: { CreateUserPassPost }
});
</script>
  
  