<template>
    <div>
        <a-form
            :model="formState"
            name="basic"
            autocomplete="off"
            @change="fromchange"
        >
            <a-form-item
                :name="['user', 'sellorderTransaction']"
                label="ค่าส่งเพิ่ม*"
            >
                <a-radio-group v-model:value="paytype" name="radioGroup">
                    <a-radio value="1">ไม่มีค่าส่งเพิ่ม</a-radio>
                    <a-radio value="2">มีค่าส่งเพิ่ม</a-radio>
                </a-radio-group>
            </a-form-item>
            <MoreDeliveryPayForAccounting
                v-show="paytype == '2'"
            ></MoreDeliveryPayForAccounting>
        </a-form>
    </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted, watch } from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
import dayjs from "dayjs";
import { formStateStore } from "@/store/storebackoffice";
import MoreDeliveryPayForAccounting from "@/module/Delivery/component/MoreDeliveryPayForAccounting.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
export default defineComponent({
    components: {
        MoreDeliveryPayForAccounting
    },
    props: {
        formStates: null
    },
    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();
        const keyupdate = ref(0);
        const formState = reactive(formStateStore);
        setTimeout(() => {
            keyupdate.value++;
        }, 1500);
        const isinit = ref(false);
        console.log("props.formStates", props.formStates);

        const options = ref([
            {
                value: "โอนจากบัญชีไทย",
                label: "โอนจากบัญชีไทย"
            },
            {
                value: "โอนจากบัญชีต่างประเทศ",
                label: "โอนจากบัญชีต่างประเทศ"
            },
            {
                value: "บัตรเครดิต",
                label: "บัตรเครดิต"
            },
            {
                value: "moneygram",
                label: "moneygram"
            },
            {
                value: "Ria",
                label: "Ria"
            },
            {
                value: "western_union",
                label: "western union"
            },
            {
                value: "Paypal",
                label: "Paypal"
            },
            {
                value: "อื่นๆ",
                label: "อื่นๆ"
            }
        ]);
        const paytype = ref("1");
        const value = ref(undefined);
        const isinited = ref(false);
        const formRef = ref();
        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            formState.pay.paymentmethod = value.value;
            formState.pay.paytype = paytype;
            console.log("pay :", formState.pay);
            emit("update:value", formState.pay);
            formItemContext.onFieldChange();
        };

        watch(
            () => formState.pay_additional.listpay,
            () => {
         
                if (formState.pay_additional.listpay.length > 0) {
                    try {
             
                        if (parseInt(formState?.pay_additional?.listpay[0]?.price) > 0)
                            paytype.value = "2";
                    } catch (error) {

                    }
                }
            }
        );
        onMounted(() => {
            getpaymenthisory();
        });
        const getpaymenthisory = () => {
            BzbsUser.apigetpaymentdetail(
                route.query.customercode,
                "normal",
                route.query.so
            )
                .then(res => {
                    res.data[0] = _.transform(res.data[0], function(
                        result,
                        val,
                        key
                    ) {
                        result[key.toLowerCase()] = val;
                    });
                    value.value = res.data[0].paymentmethod;
                    let resultobj = Object.assign(formState.pay, res.data[0]);
                    if (res.data[0].date) {
                        resultobj.date =
                            ref(dayjs(res.data[0].date, "YYYY-MM-DD")) || "";
                    }
                    if (res.data[0].time) {
                        resultobj.time =
                            ref(dayjs(res.data[0].time, "HH:mm")) || "";
                    }
                    formState.pay = resultobj;
                    isinited.value = true;
                    return console.log(res.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            keyupdate,
            isinited,
            value,
            formRef,
            paytype,
            options,
            fromchange,
            isinit,
            formState
        };
    }
});
</script>
<style lang="scss" scoped></style>
