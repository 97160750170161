<template>
    <div class="page max-w-full overflow-x-hidden w-full min-h-full flex flex-col">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <div class="flex py-2">
                    <div class="flex-grow flex"></div>
                    <div class="flex-none items-center">
                        <div class="btn-group">
                            <v-btn color="blue-1" :outlined="true">
                                <img class="icon left" src="@/assets/icons/button/btn-chat.svg" alt />
                                Send Message
                            </v-btn>
                            <v-btn color="blue-1" :outlined="true">
                                <img class="icon left" src="@/assets/icons/button/btn-list.svg" alt />
                                + Add To List
                            </v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </TopBar>

        <div class="flex flex-auto p-4 page-body min-h-0 w-full">
            <div class="rounded bg-white shadow min-h-full w-full">
                <div class="flex min-h-full flex-col w-full">
                    <v-tab-nav
                        v-model="tab"
                        :minWidth="120"
                        :items="tabs"
                        border="border-gray-300"
                        hover="bg-gray-100"
                        :textColorNoneActive="'text-gray-400'"
                        class="flex-none"
                        :disabled="disableTabNav"
                    ></v-tab-nav>
                    <v-tab-items
                        :tab="tab"
                        id="tabs-user-detail"
                        class="flex-auto"
                        :minHeightFull="true"
                        @move="disableTabNav = true"
                        @moved="disableTabNav = false"
                    >
                        <v-tab-item :value="0">
                            <div class="flex w-full min-h-full">
                                <div class="w-3/12 min-h-full">
                                    <user-profile-left-timeline @changeTab="tab = $event"></user-profile-left-timeline>
                                </div>
                                <div class="w-9/12 min-h-0 mt-8 border-l-2">
                                    <div class="flex flex-col min-h-full w-full divide-y-2">
                                        <div class="flex-auto min-h-0">
                                            <div class="p-4 pt-1">
                                                <user-profile-form></user-profile-form>
                                            </div>
                                        </div>
                                        <div class="flex-none mx-4 py-3">
                                            <div class="flex items-center justify-between">
                                                <div class="flex items-center">
                                                    <div class="text-gray-3">This user is : &nbsp;</div>
                                                    <v-toggle
                                                        v-model="userActive"
                                                        :activeText="'Active'"
                                                        :inactiveText="'Inactive'"
                                                        @change="testFunc($event)"
                                                    ></v-toggle>
                                                </div>
                                                <div>
                                                    <button
                                                        class="btn text-red-600 hover:bg-gray-100"
                                                    >Delete this user</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item :value="1">
                            <div class="p-4">
                                <div class="text-lg">User Tags</div>
                                <v-tag>Tags</v-tag>
                            </div>
                        </v-tab-item>
                        <v-tab-item :value="2">
                            <div class="p-4">Purchase</div>
                        </v-tab-item>
                        <v-tab-item :value="3">
                            <div class="p-4">Redeem</div>
                        </v-tab-item>
                        <v-tab-item :value="4">
                            <div class="p-4">Point</div>
                        </v-tab-item>
                        <v-tab-item :value="5">
                            <div class="p-4">Level</div>
                        </v-tab-item>
                        <v-tab-item :value="6">
                            <div class="p-4">Timeline</div>
                        </v-tab-item>
                    </v-tab-items>
                </div>
            </div>
        </div>

        <Footer class="flex-none" :fixed="true"></Footer>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
//#region import components
// common components
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import VTabNav from "@/components/Tab/TabNav.vue"
import VTabItems from "@/components/Tab/TabItemsNoSplideJs.vue";
import VTabItem from "@/components/Tab/TabItemNoSplideJs.vue";
import VTag from "@/components/Tag/Tag.vue";
import VToggle from "@/components/Toggle/VToggle.vue";

// content components
import UserProfileForm from "../component/UserProfileForm.vue";
import UserProfileLeftTimeline from "../component/UserProfileLeftTimeline.vue";
//#endregion

export default {
    name: "UserProfileMain",
    components: {
        Footer,
        TopBar,
        VTabNav,
        VTabItems,
        VTabItem,
        VTag,
        VToggle,
        UserProfileForm,
        UserProfileLeftTimeline
    },
    data: function () {
        return {
            tab: 0,
            disableTabNav: false,
            userActive: true
        }
    },
    computed: {
        bcItems: function () {
            let userId = this.$route.params.id || null
            return [
                {
                    text: "User List",
                },
                {
                    text: `User ID : ${userId}`,
                },
            ];
        },
        tabs: function () {
            let items = ["Account", "Tags", "Purchase", "Redeem", "Points", "Level", "Timeline"]

            return items
        }
    },
    methods: {
        testFunc: function (val) {
            console.log(val)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/base/base.scss";
.page-body {
    margin-bottom: $footer-height;
}
</style>
