<template>
    <div style="overflow-x:scroll;">
        <div class="iphone">
            <div class="iphone-top">
                <span class="camera"></span>
                <span class="sensor"></span>
                <span class="speaker"></span>
            </div>
            <div class="top-bar"></div>
            <div class="iphone-screen">
                <li v-for="element in list" :key="element">
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'youtube'" class="list-group-item">
                            <iframe width="100%" height="250px" :src="
                                'https://www.youtube.com/embed/' + item.value
                            ">
                            </iframe>
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'image'" class="list-group-item">
                            <img :src="item.value" class="w-80 m-auto" />
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'addbutton'" class="list-group-item">
                            <img v-if="!item.value.line != ''"
                                src=" https://namahayan.blob.core.windows.net/assets/Line_btn.gif"
                                class="w-80 m-auto" />
                            <img v-if="item.value.tel != ''"
                                src=" https://namahayan.blob.core.windows.net/assets/tel-button.gif"
                                class="w-80 m-auto" />
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'slideimage'" class="list-group-item">
                            <div>
                                <carousel :items-to-show="1">
                                    <slide v-for="slide in item.value" :key="slide">
                                        <img :src="slide.value" class="w-80 m-auto" />
                                    </slide>

                                    <template #addons>
                                        <navigation />
                                        <pagination />
                                    </template>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'video'" class="list-group-item">
                            <video width="100%" height="240" controls loop autoplay class="m-auto">
                                <source :src="item.value">
                                <source :src="item.value">
                            </video>
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'message'" class="rounded-md list-group-item">
                            <p class="wrapper-detail" v-html="item.value"></p>
                        </div>
                    </div>
                    <div v-for="item in element" :key="item">
                        <div v-if="item.type == 'form'" class="list-group-item p-2">
                            <!-- <pre>{{ item.value.title }}</pre> -->
                            <div v-for="detail in item.value.option.items" :key="detail">
                                <a-radio-group v-if="detail.type == 1" v-model:value="value">
                                    <a-radio :style="radioStyle" :value="detail">{{ detail.first }} ({{ detail.last }})
                                    </a-radio>
                                </a-radio-group>
                            </div>
                            <div>
                                <div v-for="items in item.value.option.items" :key="items">
                                    <div v-if="items.type == 2" class="p-2">
                                        <h1>ชื่อ-นามสกุล</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="ชื่อ-นามสกุล" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 3" class="p-2">
                                        <h1>อีเมลย์</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="อีเมลย์" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 4" class="p-2">
                                        <h1>เบอร์โทร</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="เบอร์โทร" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 5" class="p-2">
                                        <h1>LINE</h1>
                                        <div>
                                            <a-input :disabled="true" placeholder="LINE" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 6" class="p-2">
                                        <h1>ที่อยู่จัดส่งสินค้า</h1>
                                        <div>
                                            <a-textarea :disabled="true" placeholder="ที่อยู่จัดส่งสินค้า" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 7" class="p-2">
                                        <h1>ยอดชำระ</h1>
                                        <div>
                                            <a-textarea :disabled="true" placeholder="ยอดชำระ" />
                                        </div>
                                    </div>
                                    <div v-if="items.type == 8" class="p-2">
                                        <h1>{{ items.custom }}</h1>
                                        <div>
                                            <a-textarea :disabled="true" :placeholder="items.custom" />
                                        </div>
                                    </div>
                                </div>
                                <div class="wrapper-btn-submit" v-if="item.value.option.items">
                                    <div>
                                        กดเพื่อยืนยันการชำระเงิน
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
              
            </div>
            <div class="buttons">
                <span class="on-off"></span>
                <span class="sleep"></span>
                <span class="up"></span>
                <span class="down"></span>
            </div>
            <div class="bottom-bar"></div>
            <div class="iphone-bottom">
                <span></span>
            </div>
            <div>
                <div class="footerfix"
                    v-if="(formStatestore.value.user.SalePageLineOA && formStatestore.value.user.SalePageTel)">
                    <div class="grid grid-cols-2 m-auto btn-group w-btn"><a style="text-decoration:none;"
                            target="_blank">
                            <div class="wrapper-btn-footer-iphone"><button
                                    class="w-full px-12 text-2xl btn btn-contact1" onclick="clickask()"> สอบถาม
                                </button></div>
                        </a><a style="text-decoration:none;" target="_blank">
                            <div class="wrapper-btn-footer-iphone"><button
                                    class="w-full px-12 text-2xl btn btn-contact2" onclick="clickphone()"> โทร </button>
                            </div>
                        </a></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { objsalepage, formStatestore } from '@/store/store'
export default defineComponent({
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    setup(props, { emit }) {
        const list = ref(objsalepage.value)
        const visible = ref(false);
        const contentHTML = ref("")
        const showModal = () => {
            visible.value = true;
        };
        showModal()
        const handleOk = e => {
            visible.value = false
            emit('onmessage', { value: contentHTML.value, type: 'message' })
            console.log(e);
            visible.value = false;
        };
        const livechat = ref(false);

        function clickchat() {
            livechat.value = !livechat.value
        }
        return {
            visible,
            showModal,
            handleOk,
            contentHTML,
            list, formStatestore, livechat, clickchat
        };
    },

});
</script>

<style lang="scss" scoped>
*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    background-image: url("https://assets.codepen.io/285131/colorbg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.iphone {
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #999, 0 0 30px 0px rgba(0, 0, 0, 0.7);
    border: 5px solid #d9dbdc;
    background: #f8f8f8;
    padding: 15px;
    border-radius: 50px;
    height: 877px;
    width: 423px;
    position: relative;
    margin: -40px auto;
    // Scale so that the iPhone fits in view
    transform: scale(.8);

    @media only screen and (max-width: 600px) {
        width: 100%;
        position: relative;
        left: 0px;
        height: 600px;
    }
}

.iphone-top {
    padding: 5px 110px 40px;

    @media only screen and (max-width: 600px) {
        padding: 5px 100px 40px;
    }

    position: relative;

    .speaker {
        display: block;
        width: 70px;
        height: 6px;
        margin: 0 auto;
        border-radius: 6px;
        background: #292728;
    }

    .camera {
        display: block;
        margin: 0 auto;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-bottom: 13px;
        background: #333;
    }

    .sensor {
        display: block;
        width: 15px;
        height: 15px;
        float: left;
        background: #333;
        margin-top: -5px;
        border-radius: 50%;
    }
}

.top-bar,
.bottom-bar {
    display: block;
    width: 423px;
    height: 15px;

    border: {
        left: 5px solid #BBB;
        right: 5px solid #BBB;
    }

    position: absolute;
    left: -5px;
}

.top-bar {
    top: 65px;
}

.bottom-bar {
    bottom: 65px;
}

.iphone-screen {
    padding-bottom: 100px;
    background: #eee;
    border: 1px solid #fff;
    height: 677px;
    width: 375px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: 400px;
    }

    margin: 0 auto;
    border: 2px solid rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    overflow: hidden;
    // background-image: url("https://assets.codepen.io/285131/paintbg.jpg");
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: scroll;

    img {
        width: 100%;
    }
}

.buttons {

    .on-off,
    .up,
    .down,
    .sleep {
        display: block;
        background: #CCC;
        position: absolute;
        border-radius: 2px 0px 0px 2px;
    }

    .on-off {
        height: 40px;
        width: 3px;
        top: 100px;
        left: -8px;
    }

    .up,
    .down,
    .sleep {
        height: 60px;
        width: 5px;
        left: -10px;
    }

    .up {
        top: 170px;
    }

    .down {
        top: 250px;
    }

    .sleep {
        left: auto;
        right: -10px;
        top: 170px;
        border-radius: 0px 2px 2px 0px;
    }
}

.iphone-bottom {
    padding: 10px 0 0;

    span {
        display: block;
        margin: 0 auto;
        width: 68px;
        height: 68px;
        background: #ccc;
        border-radius: 50%;
        background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 60px;
            height: 60px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            left: 4px;
            top: 4px;
        }
    }
}

.carousel__next,
.carousel__prev {
    background: radial-gradient(circle, #e1c724 10%, #e1c724 90%);
    margin: 0px 20px;
}

.list-group-item {
    background-color: white;

    ol.carousel__pagination {
        margin-bottom: 0px;
    }
}


.footerfix {
    position: fixed;
    left: 14px;
    bottom: 96px;
    width: 92%;
    text-align: center;
    background-color: #f8f8f8;
    height: 75px;
    padding: 0px 20px;
    align-items: center;
    display: flex;
    margin: 0px 0px;

    .btn-contact1 {
        background: radial-gradient(circle, #e1c724 40%, #ebcc0dd6 80%);
        color: white;

        @media only screen and (max-width: 600px) {
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1rem;
        }
    }

    .btn-contact2 {
        background: radial-gradient(circle, #b81621 0, #f01 100%);
        color: white;

        @media only screen and (max-width: 600px) {
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1rem;
        }
    }

    .wrapper-btn-footer-iphone {
        margin: auto;
        text-align: center;
    }
}

.btnfooter {
    bottom: 23%;
    position: fixed;
    right: 0px;
    z-index: 1;
}

.ic-width {
    width: 55px !important;
    left: -23px;
    position: relative;
}

.wrapper-btn-submit {
    text-align: center;
    padding: 10px;
    background-color: darkgoldenrod;
    color: wi;
    color: white;
    font-size: 19px;
    margin: 5px 49px;
    border-radius: 5px;
}
</style>