<template>
    <div class="p-4 card relative">
        <template v-if="title != null">
            <div class="text-gray-500 text-lg font-bold">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <div id="chart">
                <apexchart type="pie" width="445" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default {
    props: {
        title: {
            type: String,
            default: null
        },
    },
    created() {

        this.querycustomer();

    },
    data: function () {

        return {
            heightComputed: '300px',
            series: [44, 55, 13, 0, 0],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ["เตรียมแพ็คสินค้า", "แพ็คสินค้าแล้ว", "ยกเลิกการจัดส่ง"],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

        };
    },
    methods: {
        updateChart() {
            // const max = 90;
            // const min = 20;
            // const newData = this.series[0].data.map(() => {
            //     return Math.floor(Math.random() * (max - min + 1)) + min;
            // });

            // const colors = [
            //     "#008FFB",
            //     "#00E396",
            //     "#FEB019",
            //     "#FF4560",
            //     "#775DD0"
            // ];

            // // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
            // this.chartOptions = {
            //     colors: [colors[Math.floor(Math.random() * colors.length)]]
            // };
            // // In the same way, update the series option


            // // this.series = [
            // //     {
            // //         data: newData
            // //     }
            // // ];

        },
        querycustomer() {

            return BzbsUser.apigetadditionaldeliveryorder('all')
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var dataSources = this.groupBy(res.data[0], "CustomerOrder");
               
                    var item_1 = dataSources.filter(items => items[0].DeliveryStatus == 'prepare_pack')
                    var item_2 = dataSources.filter(items => items[0].DeliveryStatus == 'packed')
                    var item_3 = dataSources.filter(items => items[0].DeliveryStatus == 'cancel')
                    this.series = [item_1?.length, item_2?.length, item_3?.length];
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });

                    
                })
                .catch(error => {
                    console.log(error);
                });
        },
        groupBy(collection, property) {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        }


    }
};
</script>

<style>

</style>
