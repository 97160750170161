<template>
    <div>
        <template v-if="!hideLabel">
            <div class="text-sm" :class="[isError ? 'text-red-500' : 'text-gray-800']">{{ inputLbl.name }}</div>
        </template>
        <div class="relative pb-5" ref="dropdown-btn">
            <!-- Dropdown btn -->
            <a-select v-model:value="value" show-search placeholder="" :style="{ width: '100%', minWidth: '100px' }"
                :options="itemList.map((city) => ({ value: city.text }))" :filter-option="filterOption" @focus="handleFocus"
                @blur="handleBlur" @change="handleChange(value)"></a-select>

            <!-- value :{{ value }}
            inputValue:{{ inputValue }}
            itemSelected:{{ itemSelected }} -->
            <!-- <div class="w-full bg-gray-50 placeholder-gray-500 bg-white px-3 py-2 rounded focus:ring-2 focus:ring-gray-800 text-sm h-8"
                :class="[isError ? 'border border-red-500' : 'border']" @click="toggleDropdown()">
                <span v-if="itemSelected == null" class="text-gray-300" :class="[isError ? 'text-red-500' : '']">{{
                    inputLbl.placeholder }}</span>
                <span v-else>{{ itemSelected.text }}</span>
            </div> -->
            <!-- item list -->
            <div v-show="showList && itemList.length > 0" ref="ddl-list-area"
                class="absolute left-0 w-full bg-white border rounded-md overflow-hidden z-index-dropdown mt-2"
                :class="[isBottomDropdown ? 'top-10' : 'bottom-16']">
                <div class="max-h-64 overflow-y-auto hide-scrollbar">
                    <div v-for="(v) in itemList" :key="v.value"
                        class="py-2 px-4 border-b last:border-b-0 cursor-pointer hover:bg-gray-50 text-center"
                        @click="selectItem(v)">{{ v.text }}</div>
                </div>
            </div>
            <!-- error msg -->
            <div class="text-red-500 text-sm absolute bottom-0 left-0" v-if="isError">{{ getErrorMsg }}</div>
        </div>
    </div>
</template>
<script>
// import Util from "@/helper/Utility.js";

/**
 * @property {String} v-model:inputValue (required) bind value
 * @property {Array} itemList (required) list of dropdown item { key: '', value: '' }
 * @property {Object} errorInfo (required) Validate result. example : { isValid: true, msg: '',}
 * @property {Boolean} isFirstSave (optional) Show/Hide Error after save.
 * @property {Boolean} isShowErrorLbl (optional) Show/Hide Error label.
 * @property {Object} inputLbl (required) Text for component.
 */
export default {
    name: "InputDropdown",
    props: {
        //v-model
        inputValue: { type: [String, Number] },
        itemList: { type: Array, required: true },
        //props
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        //text
        inputLbl: { type: Object, required: true },
        hideLabel: { type: Boolean, default: false }
    },
    data: function () {
        return {
            content: this.inputValue,
            showList: false,
            itemSelected: null,
            isBottomDropdown: true,
            options: [{
                value: 'jack',
                label: 'Jack',
            }, {
                value: 'lucy',
                label: 'Lucy',
            }, {
                value: 'tom',
                label: 'Tom',
            }]
        };
    },
    computed: {
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
    },
    mounted: function () {
        this.setSelectedDropdown();
        this.validateInput();
    },
    methods: {
        setSelectedDropdown: function () {
            let item = this.itemList.find((obj) => {
                return obj.value == this.content;
            });
            if (item != null) {
                this.itemSelected = item;
            } 
            if (this.itemSelected) {
                this.value = this.itemSelected.text
            }

        },

        toggleDropdown: function () {
            if (this.showList) {
                this.closeDropdown();
            } else {
                this.openDropdown();
            }
        },
        openDropdown: function () {
            this.isBottomDropdown = true;
            this.showList = true;
            document.addEventListener('click', this.documentClick);

            this.$nextTick(() => {
                let elem = this.$refs['ddl-list-area'];
                let bottom = elem.getBoundingClientRect().bottom;
                let windowHeight = window.innerHeight;

                this.isBottomDropdown = bottom < windowHeight;
            });
        },
        closeDropdown: function () {
            this.showList = false;
            document.removeEventListener('click', this.documentClick);
        },
        documentClick: function (e) {
            let el = this.$refs['dropdown-btn'];
            var target = e.target;

            if (el !== target && !el.contains(target)) {
                this.closeDropdown();
            }
        },

        selectItem: function (item) {

            this.content = item.value;
            this.itemSelected = item;
            this.closeDropdown();

            this.validateInput();
            this.$emit('update:inputValue', this.content);
        },

        clearSelect: function () {
            this.content = "";
            this.itemSelected = null;
            this.closeDropdown();

            this.validateInput();
            this.$emit('update:inputValue', this.content);
        },

        validateInput: function () {
            if (!this.inputLbl) {
                return;
            }
            //Set default.
            this.errorInfo.setValid(true);
            this.errorInfo.setMsg('');
            //Required
            if (this.inputLbl.required && this.itemSelected == null) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.required)
                return;
            }
        },

        setValue: function (value) {
            this.content = value;
            this.setSelectedDropdown();
            this.validateInput();
        },
        handleChange: function (value) {


            if (this.inputLbl.defaultText == 'ปี') {
                this.content = this.itemList.find((item) => item.text == value).value;
            }
            else if (this.inputLbl.defaultText == 'เดือน') {
                this.content = this.itemList.find((item) => item.text == value).value;
            }
            else if (this.inputLbl.defaultText == 'วัน') {
                this.content = this.itemList.find((item) => item.text == value).value;
            }
            else {
                this.content = value
                // this.itemSelected = this.itemList.find((item) => item.text == value);
            }

            this.itemSelected = this.itemList.find((item) => item.text == value);

            // }
            this.closeDropdown();

            this.validateInput();
            this.$emit('update:inputValue', this.content);
        },
        handleBlur: function (value) {
            console.log('blur');
        },
        handleFocus: function (value) {
            console.log('focus');
        },
        filterOption: function (input, option) {
 
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
    },
}
</script>
<style lang="scss" scoped></style>
