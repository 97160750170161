<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div class="mt-2" :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table v-if="dataSources" :scroll="{ x: 1300, y: 1000 }" :columns="columns" :data-source="dataSources"
                @change="handleTableChange" @expandFixed="true" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">

                <template #bodyCell="{ column, record }">
                    <!-- {{record}} -->
                    <template v-if="column.dataIndex === 'Order'">
                        <a-typography-paragraph :copyable="{ text: record.Track }">
                            <a-tag style="font-weight: bold;" color="#108ee9">{{ record.Track.split('-')[0]}}</a-tag>
                        </a-typography-paragraph>
                    </template>
                    <template v-if="column.dataIndex === 'Track'">
                        <a-typography-paragraph :copyable="{ text: record.Track }">

                            <a-tag style="font-weight: bold;" color="#108ee9">{{record.Track}}</a-tag>
                        </a-typography-paragraph>
                    </template>
                    <template v-if="column.dataIndex === 'LineToken'">
                        <a-typography-paragraph style="font-size:12px;font-weight: bold;"
                            :copyable="{ text: record.LineToken }">
                            {{record.LineToken}}
                        </a-typography-paragraph>
                    </template>

                    <template v-if="column.dataIndex === 'ClientUserAgent'">
                        <a-typography-paragraph style="font-size:10px;font-weight: bold;"
                            :copyable="{ text: record.ClientUserAgent }">
                            {{record.ClientUserAgent}}
                        </a-typography-paragraph>
                        <div class="text-center">
                            <a-button type="dashed" style="width:100px" @click="success(record.ClientUserAgent)">
                                รายละเอียด..</a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'PixelTrack'">
                        <div v-show="record.PixelTrack">
                            <Pixcelview :PixelTrack="record.PixelTrack"></Pixcelview>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Message'">
                        <a-typography-paragraph style="font-size:12px;font-weight: bold;"
                            :copyable="{ text: record.Message }">
                            {{record.Message}}
                        </a-typography-paragraph>
                        <div class="text-center">
                            <a-button type="dashed" style="width:100px" @click="success(record.Message)">รายละเอียด..
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ResponseLine'">
                        <a-tag style="font-size:12px;font-weight: bold;"
                            v-if="JSON.parse(record.ResponseLine).status ==200" color="green">
                            {{JSON.parse(record.ResponseLine)?.status}}
                            {{JSON.parse(record.ResponseLine).message?.toUpperCase()
                            }}</a-tag>
                        <a-tag style="font-size:12px;font-weight: bold;"
                            v-if="JSON.parse(record.ResponseLine).status !=200" color="red">
                            {{JSON.parse(record.ResponseLine)?.status
                            }} {{JSON.parse(record.ResponseLine).message?.toUpperCase() }}</a-tag>

                    </template>
                    <template v-if="column.dataIndex === 'Url'">
                        <a-typography-paragraph style="font-size:12px;font-weight: bold;"
                            :copyable="{ text: record.Url }">
                            {{record.Url}}
                        </a-typography-paragraph>
                        <div class="text-center">
                            <a-button type="dashed" style="width:100px" @click="success(record.Url)">รายละเอียด..
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'LogCreateTime'">
                        <div style="font-size:12px;font-weight: bold;">{{formatdate(record.LogCreateTime) }} น.</div>

                    </template>
                    <template v-if="column.dataIndex === 'Type'">
                        <a-alert style="font-size:12px;font-weight: bold;" v-if="record.Type == 'success'" show-icon
                            :message="record.Type" type="success" />
                        <a-alert style="font-size:12px;font-weight: bold;" v-else show-icon :message="record.Type"
                            type="info" />
                    </template>

                    <!-- <template v-if="column.dataIndex === 'LogDate'">
                        {{formatdate(record.LogDate) }}
                    </template> -->
                </template>
            </a-table>
            <div v-if="!dataSources">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>

        </div>
        <FromCreateSell v-if="rowselecteddetail.ishowdetail" :rowselected="rowselecteddetail"></FromCreateSell>
    </div>
</template>
<script>
import { message } from 'ant-design-vue';
import TableLoad from "@/components/Skeleton/TableLoad"
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";


import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";

import moment from "moment";
import "moment/locale/th";
import Pixcelview from './Pixcelview.vue'; // without this line it didn't work



const columns = [
    {
        title: "ลำดับลูกค้า",
        dataIndex: "Order",
        width: "20%"
    },
    {
        title: "เลข Tracking",
        dataIndex: "Track",
        sorter: true,
        width: "45%"
    },
    {
        title: "ข้อความ Line",
        dataIndex: "Message",
        sorter: true,
        width: "25%"
    },
    {
        title: "Response",
        dataIndex: "ResponseLine",
        sorter: true,
        width: "15%"
    },
    {
        title: "PATH ลูกค้า ",
        dataIndex: "Url",
        width: "30%"
    },
    {
        title: "LineToken",
        dataIndex: "LineToken",
        width: "20%"
    },
    {
        title: "ข้อมูลเครื่องลูกค้า",
        dataIndex: "ClientUserAgent",
        width: "25%"
    },
    {
        title: "วันที่และเวลา",
        dataIndex: "LogCreateTime",
        width: "35%"
    },
    // {
    //     title: "Pixel Track",
    //     dataIndex: "PixelTrack",
    //     width: "20%"
    // },
    {
        title: "สถานะที่เกิดขึ้นจากเครื่องลูกค้า",
        dataIndex: "Type",
        width: "24%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        // CheckOutlined, 
        FromCreateSell,
        Pixcelview
    },

    setup() {
        const success = (txt) => {
            message.info(txt, 10);
        };
        const dataSources = ref([]);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการลูกค้ากด (หน้าขอบคุณ)",
            current,
            pageSize
        } = usePagination('', {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LLL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apigetlinelog(null)
                .then(res => {
                    // res.data[0].forEach((element, index) => {
                    //     res.data[0][index].key = index;
                    // });
                    dataSources.value = res.data
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });

                   
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = (arraySellOrder) => {

            return arraySellOrder.group(({ ProductSellOrder }) => ProductSellOrder);


        }

        return {
            groupBy,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            success
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
