<template>
    <div v-if="false" class="wrapper-footer footer w-full" :class="[fixed == true ? 'fixed bottom-0' : '']">
        <div class="flex items-center h-full bg-white">
            <div class="flex-grow pl-4 text-xs">
                <a class="no-underline text-gray-500 pr-4" href>FAQ</a>
                <a class="no-underline text-gray-500 pr-4" href>Help</a>
                <a class="no-underline text-gray-500 pr-4" href>Privacy</a>
            </div> 
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    props: {
        fixed: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/base/base.scss";
.footer {
    height: $footer-height;
    box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.1);
}
.wrapper-footer {
    .system-logo {
        width: 80px;
    }
}

</style>
