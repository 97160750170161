<template>
    <a-form-item label="ค่าส่ง*"> 
        <a-radio-group
            v-model:value="formState.userproduct.deliverytype"
            name="radioGroup"
        >
            <a-radio value="10">ยังไม่ชำระค่าจัดส่ง</a-radio>
            <a-radio value="11">ชำระค่าจัดส่งแล้ว</a-radio>
            <a-radio value="12">ส่งฟรี</a-radio>
            <a-radio value="13">เก็บเงินปลายทาง</a-radio>
            <a-radio value="14">รับเอง</a-radio>
        </a-radio-group>
    </a-form-item>
    <div v-if="formState.userproduct.deliverytype == 11">
        <a-divider
            orientation="left"
            v-if="formState.userproduct.deliverytype == 11"
            ><i><b>จัดส่งในประเทศ</b></i></a-divider
        >

        <a-form-item name="area" label="จัดส่งในประเทศ"> </a-form-item>
        <a-form ref="formRef" name="formState" :model="formState">
            <a-space
                v-for="(inside, index) in formState.inside"
                :key="inside.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-form-item
                    :name="['inside', index, 'price']"
                    label="ราคา"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="inside.price"
                        @change="onFinish('inside')"
                    />
                </a-form-item>
                <a-form-item
                    label="หมายเหตุ"
                    :name="['inside', index, 'introduction']"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="inside.introduction"
                        @change="onFinish('inside')"
                    />
                </a-form-item>
            </a-space>
            <a-divider
                orientation="left"
                v-if="formState.userproduct.deliverytype == 11"
                ><i><b>จัดส่งต่างประเทศ</b></i></a-divider
            >

            <a-form-item name="area" label="จัดส่งต่างประเทศ">
                <SelectOption
                    :formStates="formState"
                    v-model:value="formState.area"
                ></SelectOption>
            </a-form-item>
            <a-space
                v-for="(outside, index) in formState.outside"
                :key="outside.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-form-item
                    :name="['outside', index, 'price']"
                    label="ราคา"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="outside.price"
                        @change="onFinish('outside')"
                    />
                </a-form-item>
                <a-form-item
                    label="หมายเหตุ"
                    :name="['outside', index, 'introduction']"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="outside.introduction"
                        @change="onFinish('outside')"
                    />
                </a-form-item>
            </a-space>
        </a-form>
    </div>

    <div v-if="formState.userproduct.deliverytype == 13">
        <a-divider
            orientation="left"
            v-if="formState.userproduct.deliverytype == 13"
            ><i><b>เก็บเงินปลายทาง</b></i></a-divider
        >

        <a-form ref="formRef" name="formState" :model="formState">
            <a-space
                v-for="(inside, index) in formState.insidecod"
                :key="inside.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-form-item
                    :name="['inside', index, 'price']"
                    label="ราคา"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="inside.price"
                        @change="onFinish('insidecod')"
                    />
                </a-form-item>
                <a-form-item
                    label="หมายเหตุ"
                    :name="['inside', index, 'introduction']"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="inside.introduction"
                        @change="onFinish('insidecod')"
                    />
                </a-form-item>
            </a-space>
        </a-form>
    </div>
</template>
<script>
import SelectOption from "../form/SelectOption.vue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch } from "vue";
// import Uploadfile from "../form/Uploadfile.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    components: {
        SelectOption,
        MinusCircleOutlined,
        PlusOutlined
        // Uploadfile
    },
    emits: ["update:value"],
    props: {
        formStates: null,
        type: null
    },
    setup(props, { emit }) {
        // setTimeout(() => {
        //     setuppay();
        // }, 2000);
        const formItemContext = Form.useInjectFormItemContext();
        const is_paid_delivery = ref("1");
        const formState = reactive(props.formStates);
        if (props.type == "add") {
            formState.inside = [];
            formState.insidecod = [];
            formState.outside = [];
        }

        // if (_.isString(formState.listproduct[0].ProductJson)) {

        //     var ProductJson = JSON.parse(formState.listproduct[0].ProductJson)
        //     var init_inside = ProductJson[0]?.inside
        //     var init_outside = ProductJson[0]?.outside
        //     if (init_inside) {
        //         formState.inside = init_inside
        //     }
        //     if (init_outside) {
        //         formState.outside = init_outside
        //     }
        // }

        formState.inside.length > 0 || formState.outside.length > 0
            ? (is_paid_delivery.value = "2")
            : (is_paid_delivery.value = "1");

        if (formState.inside.length > 0 || formState.outside.length > 0) {
            if (
                formState.outside.length > 0 &&
                formState.outside[0].introduction == "ส่งฟรี"
            ) {
                is_paid_delivery.value = "3";
            }
            if (
                formState.outside.length > 0 &&
                formState.outside[0].introduction == "รับเอง"
            ) {
                is_paid_delivery.value = "4";
            }
        }

        const areas = reactive([
            {
                label: "Beijing",
                value: "Beijing"
            },
            {
                label: "Shanghai",
                value: "Shanghai"
            }
        ]);
        BzbsUser.apicountries()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    areas.push({
                        label: res.data[element],
                        value: element
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const sights = {
            Beijing: ["Tiananmen", "Great Wall"],
            Shanghai: ["Oriental Pearl", "The Bund"]
        };
        const formRef = ref();
        // const formState = reactive({
        //   inside: [],
        //   outside: [],
        //   area: undefined,
        // });
        watch(
            () => formState.area,
            () => {
                formState.sights = [];
            }
        );
        watch(
            () => formState.userproduct.deliverytype,
            () => { 
                setuppay();
            }
        );

        const setuppay = () => {
            if (
                formState.userproduct.deliverytype == "11" ||
                formState.userproduct.deliverytype == "10"
            ) {
                formState.inside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "",
                        price: 0,
                        id: Date.now()
                    }
                ];
                formState.outside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "",
                        price: 0,
                        id: Date.now()
                    }
                ];
                emit("update:value", {
                    outside: formState.outside,
                    inside: formState.inside
                });
            }
            if (formState.userproduct.deliverytype == "12") {
                formState.inside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "ส่งฟรี",
                        price: 0,
                        id: Date.now()
                    }
                ];
                formState.outside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "ส่งฟรี",
                        price: 0,
                        id: Date.now()
                    }
                ];
                emit("update:value", {
                    outside: formState.outside,
                    inside: formState.inside
                });
            }
            if (formState.userproduct.deliverytype == "14") {
                formState.inside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "รับเอง",
                        price: 0,
                        id: Date.now()
                    }
                ];
                formState.outside = [
                    {
                        productkey: productkey_count_inside.value.toString(),
                        value: 0,
                        introduction: "รับเอง",
                        price: 0,
                        id: Date.now()
                    }
                ];
                emit("update:value", {
                    outside: formState.outside,
                    inside: formState.inside
                });
            }
            // else{
            //     formState.inside = [{
            //         productkey: productkey_count_inside.value.toString(),
            //         value: 0,
            //         introduction: '',
            //         price: 0,
            //         id: Date.now()
            //     }]
            //     formState.outside = [{
            //         productkey: productkey_count_inside.value.toString(),
            //         value: 0,
            //         introduction: '',
            //         price: 0,
            //         id: Date.now()
            //     }]
            //     emit("update:value", { outside: formState.outside, inside: formState.inside });
            // }
        };

        watch(
            () => formState.inside,
            () => {
                try {
                    // formState.outside[0].price = 0;
                } catch (error) {}
            }
        );

        watch(
            () => formState.outside,
            () => {
                try {
                    // formState.inside[0].price = 0;
                } catch (error) {}
            }
        );

        const removeinside = item => {
            let index = formState.inside.indexOf(item);

            if (index !== -1) {
                formState.inside.splice(index, 1);
            }
        };
        const removeoutside = item => {
            let index = formState.outside.indexOf(item);

            if (index !== -1) {
                formState.outside.splice(index, 1);
            }
        };

        // const removeSight = (item) => {
        //   let index = dynamicValidateForm.sights.indexOf(item);

        //   if (index !== -1) {
        //     dynamicValidateForm.sights.splice(index, 1);
        //   }
        // };
        const productkey_count_inside = ref(0);
        const addinside = () => {
            formState.inside.push({
                productkey: productkey_count_inside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_inside.value++;
        };
        const productkey_count_insidecod = ref(0);
        const addinsidecod = () => {
            formState.insidecod.push({
                productkey: productkey_count_insidecod.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_inside.value++;
        };

        const productkey_count_outside = ref(0);
        const addoutside = () => {
            formState.outside.push({
                productkey: productkey_count_outside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_outside.value++;
        };

        if (formState?.inside?.length == 0) {
            addinside();
        }
        if (formState?.outside?.length == 0) {
            addoutside();
        }
        if (formState?.insidecod?.length == 0) {
            addinsidecod();
        }

        const onFinish = type => {
            try {
                if (type == "inside") {
                    formState.outside[0].price = null;
                    formState.outside[0].introduction = null;
                    formState.insidecod[0].price = null;
                    formState.insidecod[0].introduction = null;
                }
                if (type == "outside") {
                    formState.inside[0].price = null;
                    formState.inside[0].introduction = null;
                    formState.insidecod[0].price = null;
                    formState.insidecod[0].introduction = null;
                }
            } catch (error) {}

            console.log("formState.inside:", formState.inside);
            emit("update:value", {
                outside: formState.outside,
                inside: formState.inside,
                insidecod: formState.insidecod
            });
            formItemContext.onFieldChange();
        };
        // const onNumberChange = (e) => {
        //   console.log("EE ", e.target.value);
        //   emit("update:value", { aa: e.target.value });
        //   formItemContext.onFieldChange();
        // };
        emit("update:value", {
            outside: formState.outside,
            inside: formState.inside
        });

        return {
            formRef,
            onFinish,
            removeinside,
            removeoutside,
            addinside,
            addinsidecod,
            addoutside,
            areas,
            sights,
            is_paid_delivery,
            formState
        };
    }
});
</script>
<style lang="scss" scoped></style>
