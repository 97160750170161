/* eslint-disable */

//eslint-disable-next-line
import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
 import store from "./store";

// import { VueClipboard } from "@soerenmartius/vue3-clipboard"; //https://www.npmjs.com/package/@soerenmartius/vue3-clipboard

// import AppConfig from "@/config/vueconfig.js";
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
import Tracking from "@/plugins/Tracking.js";
import Filter from "@/plugins/Filter.js";

import "./index.css";
import "@splidejs/splide/dist/css/splide.min.css";
import VueApexCharts from "vue3-apexcharts";

//#region global component
import VButton from "@/components/Button/Button.vue";
import VSvgIcon from "@/components/Icon/SvgIcon";
import VOverlay from "@/components/Overlay/Overlay";
import VDialog from "@/components/Dialog/Dialog";
import VCard from "@/components/Card/VCard";
//#endregion
import LoadingVue from "@/components/Loading/Loading.vue";

//#region directive
// import ClickOutsideDirective from "@/plugins/directive/clickOutside"
import VueClickAway from "vue3-click-away";
//#endregion

//ant-design-vue

import "ant-design-vue/dist/antd.css";
// import 'ant-design-vue/lib/date-picker/style/css'; // for css
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
// import 'ant-design-vue/dist/antd.min.css'
// import 'ant-design-vue/dist/antd.less'

import { DatePicker } from "ant-design-vue";
import "ant-design-vue/lib/date-picker/style/css"; // for css

// import gAuthPlugin from "vue3-google-oauth2";
// let gauthClientId =
//     "770386071188-kfcmmpcjho1t4lifcuodnh76jeg1qoss.apps.googleusercontent.com";
import JsonExcel from "vue-json-excel3";
import VueClick from "vue-click";
import VueSmoothScroll from "vue3-smooth-scroll";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3Autocounter from "vue3-autocounter";
/* import specific icons */

import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

import { fab } from '@fortawesome/free-brands-svg-icons'

import {
    faUserSecret,
    faKey,
    faHome,
    faEarthAmerica,
    faXmark,
    faClock,
    faTruckFast,
    faTruckPickup,
    faListDots,
    faPlusSquare,
    faPlusCircle,
    faTruckFront,
    faHandsHolding,
    faMailBulk,
    faPlusMinus,
    faMoneyCheckDollar,
    faPerson,
    faBlog,
    faPager,
    faCartPlus,
    faFolderPlus,
    faTruckMoving,
    faBarcode,
    faCartArrowDown,
    faCancel,
    faDiagramSuccessor,
    faAllergies,
    faBoxesPacking,
    faList,
    faCube,
    faHandsHoldingChild,
    faUser,
    faAddressBook,
    faHandPointer,
    faCheck,
    faAddressCard,
    faPhone,
    faFilePdf,
    faPlus,
    faEdit
} from "@fortawesome/free-solid-svg-icons";

// import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

library.add(
    fab,
    faUserSecret,
    faKey,
    faHome,
    faEarthAmerica,
    faXmark,
    faClock,
    faTruckFast,
    faTruckPickup,
    faListDots,
    faPlusSquare,
    faPlusCircle,
    faTruckFront,
    faHandsHolding,
    faMailBulk,
    faPlusMinus,
    faMoneyCheckDollar,
    faPerson,
    faBlog,
    faPager,
    faCartPlus,
    faFolderPlus,
    faTruckMoving,
    faBarcode,
    faCartArrowDown,
    faCancel,
    faDiagramSuccessor,
    faAllergies,
    faBoxesPacking,
    faList,
    faCube,
    faHandsHoldingChild,
    faUser,
    faAddressBook,
    faHandPointer,
    faCheck,
    faAddressCard,
    faPhone,
    faFilePdf,
    faPlus,
    faEdit
);

createApp(App)
    .use(Vue3VideoPlayer, {
        lang: "en"
    })
    .use(VueSmoothScroll, {
        duration: 400,
        updateHistory: true
    })
    .use(LoadingVue)
    .use(VueClick)
    .use(store)
    .use(router)
    .use(Tracking)
    .use(Filter)
    .use(Antd)
    .use(DatePicker)
    // .use(VueClipboard)
    .use(VueClickAway)
    .use(VueApexCharts)
    // .use(gAuthPlugin, {
    //     clientId: gauthClientId,
    //     scope: "email",
    //     plugin_name: "streamy",
    //     prompt: "consent"
    // })
    .mixin(ErrorApiMixin)
    //#region registe global component
    .component("VBtn", VButton)
    .component("VSvgIcon", VSvgIcon)
    .component("VOverlay", VOverlay)
    .component("VDialog", VDialog)
    .component("VCard", VCard)
    .component("downloadExcel", JsonExcel)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("vue3-autocounter", Vue3Autocounter)
    //#endregion
    // .directive('click-outside', ClickOutsideDirective)
    .directive("focus", {
        // When the bound element is mounted into the DOM...
        mounted(el) {
            // Focus the element
            el.focus();
        }
    })
    // .use(Vue3SimpleHtml2pdf)
    .mount("#app");
