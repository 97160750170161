<template>
    <div class="p-4 card relative">
        <template v-if="title != null">
            <div class="text-gray-500 text-lg font-bold">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <div id="chart">
                <apexchart
                    type="line"
                    height="300"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
        // seriesData: {
        //     type: Object,
        //     default: null
        // },
        // height: {
        //     type: [String, Number],
        //     default: 400
        // }
    },
    data: function() {
        return {
            series: [
                {
                    name: "Session Duration",
                    data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
                },
                {
                    name: "Page Views",
                    data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
                },
                {
                    name: "Total Visits",
                    data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
                }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: "straight",
                    dashArray: [0, 8, 5]
                },
                title: {
                    text: "Page Statistics",
                    align: "left"
                },
                legend: {
                    tooltipHoverFormatter: function(val, opts) {
                        return (
                            val +
                            " - " +
                            opts.w.globals.series[opts.seriesIndex][
                                opts.dataPointIndex
                            ] +
                            ""
                        );
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: [
                        "01 Jan",
                        "02 Jan",
                        "03 Jan",
                        "04 Jan",
                        "05 Jan",
                        "06 Jan",
                        "07 Jan",
                        "08 Jan",
                        "09 Jan",
                        "10 Jan",
                        "11 Jan",
                        "12 Jan"
                    ]
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function(val) {
                                    return val + " (mins)";
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function(val) {
                                    return val + " per session";
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function(val) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: "#f1f1f1"
                }
            }
        };
    },
    methods: {
        updateChart() {
            const max = 90;
            const min = 20;
            const newData = this.series[0].data.map(() => {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            });

            const colors = [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0"
            ];

            // Make sure to update the whole options config and not just a single property to allow the Vue watch catch the change.
            this.chartOptions = {
                colors: [colors[Math.floor(Math.random() * colors.length)]]
            };
            // In the same way, update the series option
            this.series = [
                {
                    data: newData
                }
            ];
        }
    }
};
</script>

<style></style>
