<template>
    <div> 
    
        <div v-if="isShowErrorLbl" class="text-sm" :class="[!errorInfoDetail.isValid ? 'text-red-500' : 'text-gray-800']">
            {{inputLbl.name}}
        </div>
        <div class="relative pb-5">
            <input type="tel" class="w-full bg-white px-3 py-1 placeholder-gray-500"
                :class="[isShowErrorLbl &&!errorInfoDetail.isValid ? 'border border-red-500' : 'border']" v-model="content"
                :placeholder="inputLbl.placeholder" :maxlength="inputMaxLength" @input="onChange()">
            <div class="text-red-500 text-sm absolute bottom-0 left-0" v-if="isShowErrorLbl && !errorInfoDetail.isValid">
                {{errorInfoDetail.msg}}
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import RegxHelper from "@/helper/RegxHelper.js";

/**
 * @property {String} v-model:inputValue (required) bind value
 * @property {Number} minLength (optional) Minimum length.
 * @property {Number} maxLength (optional) Maximum Length.
 * @property {Object} errorInfo (required) Validate result. example : { isValid: true, msg: '',}
 * @property {Boolean} isFirstSave (optional) Show/Hide Error after save.
 * @property {Boolean} isShowErrorLbl (optional) Show/Hide Error label.
 * @property {String} delimeter (optional) Character seperate number.
 * @property {Object} inputLbl (required) Text for component.
 */
export default {
    name: "InputContactNumber",
    props: {
        //v-model
        inputValue: { type: String },
        //props
        minLength: { type: Number },
        maxLength: { type: Number, default: 0 },
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        delimeter: { type: String, default: '' },
        //text
        inputLbl: { type: Object, required: true },
    },
    computed: {
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
    },
    data: function () {
        return {
            content: this.inputValue,
            errorInfoDetail: this.errorInfo,
            inputMaxLength: null,
        };
    },
    created: function () {
        if (this.maxLength > 0) {
            this.inputMaxLength = this.maxLength;
        }
        this.onChange();
    },
    mounted: function () {
        this.validateInput();
    },
    methods: {
        validateInput: function () {
            if (!this.inputLbl) {
                return;
            }
            //Set default.
            this.errorInfo.setValid(true);
            this.errorInfo.setMsg('');
            //Required
            if (this.inputLbl.required && !this.content) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.required)
                return;
            }
            //Too short
            if (this.inputLbl.too_short && this.minLength
                && (this.content.length < this.minLength)) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(Util.stringFormat(this.inputLbl.too_short, this.minLength));
                return;
            }
        },
        onChange: function () {
            let input = Util.isEmptyStr(this.content) ? '' : this.content;
            input = RegxHelper.removeEmoji(input);
            input = input.replace(/^[^0].*/, '');
            input = RegxHelper.removeNotNumber(input);

            let tel = input;

            if (!Util.isEmptyStr(this.delimeter)) {
                let n1, n2, n3;
                if (input.length <= 3) {
                    // do nothing
                } else if (input.length <= 6) {
                    n1 = input.substr(0, 3);
                    n2 = input.substr(3, 3);
                    input = n1 + this.delimeter + n2;
                } else {
                    n1 = input.substr(0, 3);
                    n2 = input.substr(3, 3);
                    n3 = input.substr(6);
                    input = n1 + this.delimeter + n2 + this.delimeter + n3;
                }
            }

            this.content = input;
            this.validateInput();
            this.$emit('update:inputValue', tel);
        },
    },
}
</script>
<style lang="scss" scoped>

</style>
