<template>
  <div>
    <a-modal v-model:visible="visible" :title="detail.title" @cancel="close" @ok="handleOk">
      <div class="desc-calcel">{{ detail.subtitle }}</div>
      <a-form-item ref="" label="หมายเหตุ*" :rules="[{ required: true, message: 'กรุณากรอกหมายเหตุ' }]">
        <a-textarea v-model:value="ProductStatusDesc" placeholder="หมายเหตุ" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-item>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
  props: {
    formState: null,
    title: null,
    subtitle: null,
    iscancelall: null,
    cancelallboj: null
  },
  setup(props, { emit }) {
    const detail = ref({
      title: props.title,
      subtitle: props.subtitle
    })
    const ProductStatusDesc = ref("")
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };
    const handleOk = e => {
      console.log(e);

      apiupdateproductstatus()
    };
    const close = e => {
      console.log(e)
      visible.value = false;
      emit('close', false)

    }
    var iscancelall = props.iscancelall == "cancelall" ? true : false
    const apiupdateproductstatus = async () => {
      var param = {
        ProductSellOrder: props.formState.userproduct?.productsellorder,
        CustomerOrder: props.formState.userproduct?.customerorder,
        ProductStatus: "cancel",
        ProductStatusDesc: ProductStatusDesc.value,
        ProductKey: props.formState?.userproduct?.productkey,
        TypeCancelAll: iscancelall

      }

      if (iscancelall) {
        param.ProductSellOrder = props.cancelallboj.ProductSellOrder
        param.CustomerOrder = props.cancelallboj.CustomerOrder
        param.ProductKey = null 
      }
      console.log("param cancel ", param)
      await BzbsUser.apiupdateproductstatus(param)
        .then(res => {
          console.log("update status ", res)
          visible.value = false;
        })
        .catch(error => {
          console.log(error);
        });
    };
    showModal()
    return {
      ProductStatusDesc,
      detail,
      close,
      visible,
      showModal,
      handleOk,
      BzbsUser
    };
  },
});
</script>
<style lang="scss">
.desc-calcel {
  color: red;
  padding-bottom: 10px;
}
</style>