<template>
    <div>
        <p v-html="msg"></p>
    </div>
</template>
<script>
import LocaleMixin from "@/mixin/LocaleMixin.js";

export default {
    name: 'InterfaceConfirmRedeem',
    mixins: [LocaleMixin],
    props: {
        campaign: Object
    },
    data: function () {
        return {
            msg: ''
        };
    },
    created: function () {
        this.msg = this.lbl.confirm_redeem.interface;
    }
}
</script>
