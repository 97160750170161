<template>
    <div class="grid grid-cols-3 text-center font-bold cursor-pointer select-none mt-2" v-show="isShowCode">
        <div class="border-b-2 py-1"
             :class="[serialType == 'QR_CODE' ? classActiveTab : '']"
             @click="changeTab('QR_CODE')">
            {{lbl.serial.qr_code}}
        </div>
        <div class="border-b-2 py-1"
             :class="[serialType == 'CODE_128' ? classActiveTab : '']"
             @click="changeTab('CODE_128')">
            {{lbl.serial.barcode}}
        </div>
        <div class="border-b-2 py-1 "
             :class="[serialType == 'CODE' ? classActiveTab : '']"
             @click="changeTab('CODE')">
            {{lbl.serial.code}}
        </div>
    </div>
    <div class="flex justify-center items-center wrap-code">
        <div v-show="serialType == 'QR_CODE'">
            <VueQrcode :value="serial" :options="qrOption"></VueQrcode>
        </div>
        <div v-show="serialType == 'CODE_128'">
            <VueBarcode :value="serial" :options="barcodeOption"></VueBarcode>
        </div>
        <div v-show="serialType == 'CODE'">
            <div class="text-xl font-bold">{{serial}}</div>
            <!-- <button
                v-clipboard:copy="serial"
                v-clipboard:success="onCopy">Copy</button> -->
        </div>
    </div>
</template>
<script>
import LocaleMixin from "@/mixin/LocaleMixin.js";
//lib
import VueBarcode from '@chenfengyuan/vue-barcode';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    name: "Serial",
    mixins: [LocaleMixin],
    components: {
        VueBarcode,
        VueQrcode,
    },
    props: {
        serialModel: Object,
    },
    data: function() {
        return {
            serialType: 'CODE', // CODE, QR_CODE, CODE_128
            classActiveTab: 'text-blue-600 border-blue-600',

            isExpired: false,
            isLoaded: false,
            isShowCode: true,
            isTabLock: false,
            privilegeMsg: '',
            serial: '',

            qrOption: {
                width: 160,
                margin: 0,
            },
            barcodeOption: {
                height: 100,
                displayValue: false,
                lineColor: '#000000',
                width: 1,
            },
        };
    },
    created: function() {
        this.initial();
    },
    methods: {
        initial: function () {
            if (!this.serialModel.serial) {
                return;
            }

            this.isExpired = false;
            this.serial = this.serialModel.serial;
            switch (this.serialModel.barcode) {
                case 'CODE_128':
                    this.serialType = 'CODE_128';
                    break;
                case 'QR_CODE':
                    this.serialType = 'QR_CODE';
                    break;
                default:
                    this.serialType = 'CODE';
                    break;
            }

            if (this.serialModel.barcode && this.serialModel.barcode.toLowerCase() == 'none' && this.serialModel.privilegeMessage) {
                this.isShowCode = false;
            }
        },

        changeTab: function (type) {
            if (this.isTabLock) return;

            this.serialType = type;
        },

        setSerialExpire: function() {
            this.serial = "XXXXXX";
            this.isExpired = true;
            this.isTabLock = true;
            this.serialType = "CODE";
        },
    },
}
</script>
<style lang="scss" scoped>
.wrap-code {
    height: 200px;
}
</style>
