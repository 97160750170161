<template> 
    <div v-if="dataSources" class="relative p-4 wrapper-track">
        <template v-if="title != null">
            <div style="pointer-events: none;">
                <a-image :preview="{ visible: false }" class="image-center banner" alt="มหายันต์ลูกกอ"
                    src="https://namahayan.blob.core.windows.net/assets/banner.webp"></a-image>
            </div>
        </template>
        <!-- <div>
            <div class="text-lg font-bold item-menu" @click="gototyandetail()"> คิวเขียน</div>
        </div> -->
        <!-- <div>
            <div class="text-lg font-bold item-menu" v-show="track" @click="gototrack()"> ตรวจสอบสถานะ</div>
        </div> -->
        <div>
            <div class="text-lg font-bold item-menu" @click="gotohistory()">ประวัติการสั่งซื้อ</div>
        </div>
        <div>
            <div class="text-lg font-bold item-menu" v-show="track" @click="gotodelivery()"> สถานะการจัดส่ง</div>
        </div>
        <!-- <pre>{{ dataSources }}</pre> -->
        <a-image :preview="false" :width="auto" src="https://namahayan.blob.core.windows.net/salepage/1668068170807.webp" />
    </div>
</template>
<script>

import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons-vue';
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility";
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        UserOutlined,
        SolutionOutlined,
        LoadingOutlined,
        SmileOutlined,
    },

    setup() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);

        const track = ref("-")
        const cuscode = ref("-")
        const so = ref("-")
        const pk = ref("-")
        const current_status = ref(2)

        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "ชื่อผู้รับ",
                dataIndex: "ShipAddress",
                width: "20%"
            },
            {
                title: "น้ำหนักรวมกล่อง (กรัม)",
                dataIndex: "Weight",
            },
            {
                title: "ค่าบริการ (บาท)",
                dataIndex: "ServiceCost",
                width: "10%"
            },
            {
                title: "บริการ",
                dataIndex: "StoreDeliveryOption"
            },
            {
                title: "สถานะ",
                dataIndex: "Status"
            },
            {
                title: "รหัสสิ่งของฝากส่ง",
                dataIndex: "Barcode"
            },
            {
                title: "วันที่สร้าง",
                dataIndex: "CreatedDate",
                width: "15%"
            },
            {
                title: "จ่าหน้า",
                dataIndex: "filename"
            }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสร้างไปรษณีย์ไทย ในประเทศ",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        const getgreencard = ref([])

        onMounted(() => {

            if (urlParams.has('cuscode')) {
                cuscode.value = urlParams.get('cuscode');
            }
            if (urlParams.has('track')) {
                track.value = urlParams.get('track');
            }
            if (urlParams.has('so')) {
                so.value = urlParams.get('so');
            }
            if (urlParams.has('pk')) {
                pk.value = urlParams.get('pk');
            }
            if (cuscode.value) {
                querycustomerdetail(cuscode.value)
            }
            else if (track.value) {
                querycustomer()
            }
            // getimagegreen();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("L");
        };

        const querycustomer = () => {

            return BzbsUser.getconsumerhistory(track.value, cuscode.value, so.value, pk.value)
                .then(res => {

                    // dataSources.value = res.data
                    querycustomerdetail(res.data[0].CustomerOrder)


                })
                .catch(error => {
                    console.log(error);
                });
        };
        const querycustomerdetail = (customercode) => {
            return BzbsUser.getconsumer(customercode)
                .then(res => {
                    dataSources.value = res.data
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getimagegreen = () => {
            if (urlParams.has('track')) {
                track.value = urlParams.get('track');
            }
            return BzbsUser.getimagegreen(track)
                .then(res => {
                    getgreencard.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: '',
            searchedColumn: '',
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        const getdatagreen = json => {

            return JSON.parse(JSON.stringify(json));
        };

        const gototrack = () => {
            if (urlParams.has('inter')) {
                window.location = "/consumertrackinginter?track=" + track.value + "&cuscode=" + cuscode.value + "&so=" + so.value + "&pk=" + pk.value + '&inter=true'
            }
            else {

                window.location = "/consumertracking?track=" + track.value + "&cuscode=" + cuscode.value + "&so=" + so.value + "&pk=" + pk.value
            }
        }
        const gotohistory = () => {
            window.location = "/consumerhistory?cuscode=" + cuscode.value + "&so=" + so.value + "&pk=" + pk.value
        }
        const gotodelivery = () => {
            window.location = "/consumerdelivery?track=" + track.value + "&cuscode=" + cuscode.value + "&so=" + so.value + "&pk=" + pk.value
        }
        const gototyandetail = () => {
            window.location = "/consumeryandetail?track=" + track.value + "&cuscode=" + cuscode.value + "&so=" + so.value + "&pk=" + pk.value
        }

        return {
            gotodelivery,
            gototyandetail,
            gotohistory,
            gototrack,
            getgreencard,
            current_status,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            Util,
            getdatagreen,
            track
        };
    }
});
</script>
<style lang="scss">
.wrapper-track {
    width: 100%;
    max-width: 450px;
    margin: auto;

    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background: #ae8b40 !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ae8b40 !important;
    }

    .ant-steps-item-tail::after {
        background-color: #ae8b40 !important;
    }

    .green-table {
        font-family: 'Arial, Helvetica, sans-serif';
        font-size: 14px;
        color: #FFFFFF;
        border: 1px solid #CCCCCC;
        padding-left: 0px;
        text-align: center;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ccffcc), to(#99cccc));
        background: -moz-linear-gradient(top, #ccffcc, #99cccc);
        filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr=#ccffcc, EndColorStr=#99cccc);
    }

    .ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
        float: left;
        margin-right: 10px;
    }

    .namegreen {
        position: absolute;
        color: rgb(255 255 255 / 30%);
        font-size: 37px;
        top: 45%;
        left: 15px;
        text-align: center;
        font-weight: bold;
        width: 100%;
        pointer-events: none;
        -webkit-transform: rotate(-45deg);
        font-family: tahoma;
    }
}

.item-menu {
    text-align: center;
    border-radius: 15px;
    line-height: 10rem;
    padding: 10px;
    margin: 10px;
    border: 1px solid #bb9852;
    color: #bb9852;
}
</style>
