<template>
    <div class="relative">
      <div class="flex flex-wrap">
        <div class="w-full  p-1 lg:w-3/3 pr-2 mb-2 md:mb-0">
          <a-select
            v-model:value="selectedParentCategory"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
            :options="parentCategories.map((type) => ({ value: type }))"
            placeholder="เลือกหมวดหมู่"
            @change="updateParentCategory()"
          ></a-select>
        </div>
        <div v-if="selectedParentCategory !== 'อื่นๆ' && selectedParentCategory !== 'ไม่ระบุ'" class="w-full p-1 lg:w-3/3 pr-2 mb-2 md:mb-0">
          <a-select
            v-model:value="selectedChildCategory"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
            :options="filteredChildCategories.map((career) => ({ value: career }))"
            @change="updateChildCategory(selectedChildCategory)"
            placeholder="เลือกอาชีพ"
          ></a-select>
        </div>
        <div v-if="selectedParentCategory === 'อื่นๆ'" class="w-full p-1 pr-2 mb-2 md:mb-0">
          <a-textarea
            style="width: 100%"
            v-model:value="selectedChildCategory"
            placeholder="กรอกอาชีพ"
            allow-clear
            @change="updateChildCategory(selectedChildCategory)"
          ></a-textarea>
        </div>  
      </div>
      <!-- <div class="w-full md:w-1/2 lg:w-3/3 ">
          <a-button type="primary" @click="clear()" style="width: 100%">เครียร์ค่าอาชีพ</a-button>
        </div> -->
    </div>
  </template>
  
  
<script>
import { filter, forEach } from 'lodash';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default {
    props: {
        //v-model
        initcareer: { type: Object, required: false },
    },
    data() {
        return {
            selectedParentCategory: "",
            selectedChildCategory: "",
            searchQuery: '',
            categories: []
        };
    },
    mounted: function () {
        this.getcareer()
    },
    computed: {
        parentCategories() {
            return this.categories.map(category => category.parentCategory);
        },
        filteredChildCategories() {
            if (this.selectedParentCategory) {
                // If a parent category is selected, filter the child categories based on the selected parent category
                const selectedCategory = this.categories.find(category => category.parentCategory === this.selectedParentCategory);
                if (selectedCategory) {
                    return selectedCategory.childCategories.filter(childCategory => {
                        return childCategory.toLowerCase().includes(this.searchQuery.toLowerCase());
                    });
                } else {
                    return [];
                }
            } else {
                // No parent category selected, return all child categories
                const allChildCategories = this.categories.flatMap(category => category.childCategories);
                return allChildCategories.filter(childCategory => {
                    return childCategory.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
            }
        },

    },
    created: function () {


        try {
            if (this.initcareer) {
                var career = JSON.parse(this.initcareer)
                this.selectedParentCategory = career.parentCategory
                this.selectedChildCategory = career.childCategories
            }
        } catch (error) {

        }


    },
    methods: {
        updateChildCategory: function (ChildCategory) {
           

            this.categories.map(element => {
                if (element.childCategories.includes(ChildCategory)) {

                    return this.selectedParentCategory = element.parentCategory
                }
            });
        
            if(ChildCategory =="ไม่ระบุ"){
                this.selectedParentCategory = "ไม่ระบุ"
            }
          
            this.$emit('update:inputValue', JSON.stringify({ parentCategory: this.selectedParentCategory, childCategories: ChildCategory }));

        },
        updateParentCategory: function () {
            this.selectedChildCategory = ""
       
            if(this.selectedParentCategory =="ไม่ระบุ"){
                this.selectedParentCategory = "ไม่ระบุ"
                this.$emit('update:inputValue', JSON.stringify({ parentCategory: "ไม่ระบุ", childCategories: "" }));
            }
          


        },
        clear: function () {
            this.selectedChildCategory = ""
            this.selectedParentCategory = ""


        },
        getcareer: function () {

            BzbsUser.getcareer()
                .then(res => {

                    this.categories = res.data

                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
};
</script>
  
 