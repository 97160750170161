const apiConfig = {
    scopes: ['api://ad13ba21-bd15-474c-b328-429865386cb7/Access'],
  }
export const config = {
    appname: '',
    client_id: '', // App ID
    facebook_app_id: '', // Facebook App ID (Should be the same)
    debug: true, //enable console.log

    // ------------- Development Settings ----------------
    api: 'dev',
    agency_id: '',
    bzbsUrlOldSupport: 'https://dev.buzzebees.com',
    bzbsUrl: 'https://dev-bzbs-usermgr-userlist.azurewebsites.net',
    bzbsModuleUrl: 'https://dev-module-app.azurewebsites.net',
    apiShoppingCartUrl: 'https://devservice.buzzebees.com/modules/shoppingcart',
    bzbsShoppingCartUrl: 'https://dev-web-shoppingcart.azurewebsites.net/',
    bzbsBlobUrl: 'https://devstorageusermanagement.blob.core.windows.net',
    miscUrl: 'https://devmisc.buzzebees.com',
    bzbsGaId: '',
    liffId: '',
    // ------------------ End Settings ---------------------
    fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
    fbPermissions: [
        'email',
    ],

    firebase: {
        // apiKey: "AIzaSyDsKEaA8CPTEyAwcliVtxXmjwdCMfJsIzo",
        // authDomain: "testtracking-b23b5.firebaseapp.com",
        // databaseURL: "https://testtracking-b23b5.firebaseio.com",
        // projectId: "testtracking-b23b5",
        // storageBucket: "testtracking-b23b5.appspot.com",
        // messagingSenderId: "995876078913",
        // appId: "1:995876078913:web:c3298d1b004a0f6b9a4dc0",
        // measurementId: "G-M5CE0Q8SX6"
    },
    msalConfig: {
        auth: {
          clientId: '7e54e467-d9f0-455b-8187-8c8562a4caaa',
          authority:
            'https://login.microsoftonline.com/1913d0b4-9ddd-45ce-bfa6-ea0c1eef432b',
          redirectUri: window.location.origin + '/main/redirectms',
          postLogoutRedirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: 'sessionStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      },
      loginRequest: {
        scopes: ['openid', 'profile'],
        prompt: 'select_account',
      },
      tokenRequest: {
        scopes: [...apiConfig.scopes],
        forceRefresh: false,
      },
};
