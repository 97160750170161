import { ModalController } from './ModalController.js';

import { AlertModalController } from './Generic/AlertModal/AlertModalController';
import { ConfirmModalController } from './Generic/ConfirmModal/ConfirmModalController.js';
import { LoadingModalController } from './Generic/LoadingModal/LoadingModalController.js';
import { ConfirmRedeemModalController } from '@/module/Detail/modal/ConfirmRedeemModal/ConfirmRedeemModalController.js';
import { SerialModalController } from '@/module/Detail/modal/SerialModal/SerialModalController.js';
import { DrawDeliveryModalController } from '@/module/Detail/modal/DrawDeliveryModal/DrawDeliveryModalController.js';
import { AddAddressModalController } from '@/module/Address/DeliveryAddress/modal/AddAddressModal/AddAddressModalController.js';
import { EditAddressModalController } from '@/module/Address/DeliveryAddress/modal/EditAddressModal/EditAddressModalController.js';
import { ListAddressModalController } from '@/module/Address/DeliveryAddress/modal/ListAddressModal/ListAddressModalController.js';
import { AddTaxAddressModalController } from '@/module/Address/TaxAddress/modal/AddTaxAddressModal/AddTaxAddressModalController.js';
import { EditTaxAddressModalController } from '@/module/Address/TaxAddress/modal/EditTaxAddressModal/EditTaxAddressModalController.js';
import { ListTaxAddressModalController } from '@/module/Address/TaxAddress/modal/ListTaxAddressModal/ListTaxAddressModalController.js';
import { ShippingAddressModalController } from '@/module/Detail/modal/ShippingAddressModal/ShippingAddressModalController.js';
/**
 * @method getModalController
 * generic
 * @method getAlertModal
 * @method getConfirmModal
 * @method getLoadingModal
 * redeem
 * @method getConfirmRedeemModal
 * @method getSerialModal
 * @method getDrawDeliveryModal
 */
export default function ModalFactory() {
    this.modalController = new ModalController();

    //generic
    this.alertModal = null;
    this.confirmModal = null;
    this.loadingModal = null;
    //redeem
    this.confirmRedeemModal = null;
    this.serialModal = null;
    this.drawDeliveryModal = null;
    this.shippingAddressModal = null;
    //address
    this.addAddressModal = null;
    this.editAddressModal = null;
    this.listAddressModal = null;
    this.addTaxAddressModal = null;
    this.editTaxAddressModal = null;
    this.listTaxAddressModal = null;

    //#region generic
    this.getModalController = function () {
        return this.modalController;
    };
    this.getAlertModal = function () {
        this.alertModal = new AlertModalController();
        this.modalController.setAlertModal(this.alertModal);
        return this.alertModal;
    };
    this.getConfirmModal = function () {
        this.confirmModal = new ConfirmModalController();
        this.modalController.setConfirmModal(this.confirmModal);
        return this.confirmModal;
    };
    this.getLoadingModal = function () {
        this.loadingModal = new LoadingModalController();
        this.modalController.setLoadingModal(this.loadingModal);
        return this.loadingModal;
    };
    //#endregion generic

    //#region redeem
    this.getConfirmRedeemModal = function () {
        this.confirmRedeemModal = new ConfirmRedeemModalController();
        this.modalController.setConfirmRedeemModal(this.confirmRedeemModal);
        return this.confirmRedeemModal;
    };
    this.getSerialModal = function () {
        this.serialModal = new SerialModalController();
        this.modalController.setSerialModal(this.serialModal);
        return this.serialModal;
    };
    this.getDrawDeliveryModal = function () {
        this.drawDeliveryModal = new DrawDeliveryModalController();
        this.modalController.setDrawDeliveryModal(this.drawDeliveryModal);
        return this.drawDeliveryModal;
    };
    this.getShippingAddressModal = function () {
        this.shippingAddressModal = new ShippingAddressModalController();
        this.modalController.setShippingAddressModal(this.shippingAddressModal);
        return this.shippingAddressModal;
    };
    //#endregion redeem

    //#region address
    this.getAddAddressModal = function () {
        this.addAddressModal = new AddAddressModalController();

        if (this.alertModal) {
            this.addAddressModal.setAlertModal(this.alertModal);
        }
        if (this.loadingModal) {
            this.addAddressModal.setLoadingModal(this.loadingModal);
        }

        this.modalController.setAddAddressModal(this.addAddressModal);
        return this.addAddressModal;
    };
    this.getEditAddressModal = function () {
        this.editAddressModal = new EditAddressModalController();

        if (this.alertModal) {
            this.editAddressModal.setAlertModal(this.alertModal);
        }
        if (this.loadingModal) {
            this.editAddressModal.setLoadingModal(this.loadingModal);
        }

        this.modalController.setEditAddressModal(this.editAddressModal);
        return this.editAddressModal;
    };
    this.getListAddressModal = function () {
        this.listAddressModal = new ListAddressModalController();

        if (this.alertModal) {
            this.listAddressModal.setAlertModal(this.alertModal);
        }
        if (this.confirmModal) {
            this.listAddressModal.setConfirmModal(this.confirmModal);
        }
        if (this.loadingModal) {
            this.listAddressModal.setLoadingModal(this.loadingModal);
        }
        this.listAddressModal.setAddAddressModal(this.addAddressModal);
        this.listAddressModal.setEditAddressModal(this.editAddressModal);

        this.modalController.setListAddressModal(this.listAddressModal);
        return this.listAddressModal;
    };
    this.getAddTaxAddressModal = function () {
        this.addTaxAddressModal = new AddTaxAddressModalController();

        if (this.alertModal) {
            this.addTaxAddressModal.setAlertModal(this.alertModal);
        }
        if (this.loadingModal) {
            this.addTaxAddressModal.setLoadingModal(this.loadingModal);
        }

        this.modalController.setAddTaxAddressModal(this.addTaxAddressModal);
        return this.addTaxAddressModal;
    };
    this.getEditTaxAddressModal = function () {
        this.editTaxAddressModal = new EditTaxAddressModalController();

        if (this.alertModal) {
            this.editTaxAddressModal.setAlertModal(this.alertModal);
        }
        if (this.loadingModal) {
            this.editTaxAddressModal.setLoadingModal(this.loadingModal);
        }

        this.modalController.setEditTaxAddressModal(this.editTaxAddressModal);
        return this.editTaxAddressModal;
    };
    this.getListTaxAddressModal = function () {
        this.listTaxAddressModal = new ListTaxAddressModalController();

        if (this.alertModal) {
            this.listTaxAddressModal.setAlertModal(this.alertModal);
        }
        if (this.confirmModal) {
            this.listTaxAddressModal.setConfirmModal(this.confirmModal);
        }
        if (this.loadingModal) {
            this.listTaxAddressModal.setLoadingModal(this.loadingModal);
        }
        this.listTaxAddressModal.setAddAddressModal(this.addTaxAddressModal);
        this.listTaxAddressModal.setEditAddressModal(this.editTaxAddressModal);

        this.modalController.setListTaxAddressModal(this.listTaxAddressModal);
        return this.listTaxAddressModal;
    };
    //#endregion address
}
