<template>
    <a-space direction="vertical" :size="12">
        <a-date-picker
            v-model:value="date"
            @change="onChange"
            :disabled="date != '' ? true : false"
        />
    </a-space>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    props: ["datelist", "idx", "idxsizechange"],
    emits: ["date-updated"],
    setup(props, context) {
        const date = ref("");

        // watch(date, newVal => {
        //     context.emit("date-updated", { newVal });
        // });
        const onChange = (date, dateString) => {
            context.emit("date-updated", {
                date: dateString,
                idx: props.idx,
                idxsizechange: props.idxsizechange
            }); 
        };
        return {
            date,
            onChange
        };
    },
    mounted: function() {
        // if (this.cleardate) {
        //     this.date.value = "";
        // }
    }
});
</script>
