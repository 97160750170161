<template>
    <div class="relative p-4 card">
        <div>
            <a-form
                class="flex"
                :model="formSearch"
                name="basic"
                autocomplete="off"
                @finish="onFinish"
                @finishFailed="onFinishFailed"
            >
                <a-form-item
                    label="จากลำดับงาน"
                    name="ordertransaction_from"
                    :rules="[
                        {
                            required: true,
                            message: 'Please input your ordertransaction!'
                        }
                    ]"
                >
                    <a-input-number v-model:value="formSearch.ordertransaction_from" :min="1" :max="98000" />
                </a-form-item>

                <a-form-item
                    label="ถึงลำดับงาน"
                    name="ordertransaction_to"
                    :rules="[
                        {
                            required: true,
                            message: 'Please input your ordertransaction_to!'
                        }
                    ]"
                >
                    <a-input-number v-model:value="formSearch.ordertransaction_to" :min="1" :max="98000" />
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                    <a-button
                        v-if="!isshowspin"
                        type="primary"
                        html-type="submit"
                    >
                        <FileExcelOutlined :style="{ fontSize: '16px' }" />
                        Export Report</a-button
                    >
                    <div v-if="isshowspin" class="text-center pb-4">
                        <a-tag color="processing">
                            <template #icon>
                                <sync-outlined :spin="true" />
                            </template>
                            กำลังสร้างไฟล์...
                        </a-tag>
                    </div>
                </a-form-item>
            </a-form>
        </div>

        <!-- <div class="pdf_icon" @click="getexcelshopping">
            <u class="pr-2">Export Excel (รายชื่อลูกค้า)</u>
            <FileExcelOutlined :style="{ fontSize: '32px' }" />
        </div> -->
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-alert
                style="width: 200px"
                :message="
                    `เลือกวันที่ : ${selectedValue &&
                        selectedValue.format('YYYY-MM-DD')}`
                "
            />

            <a-calendar
                v-if="list_result.length > 0"
                :value="date"
                @select="onSelect"
                @panelChange="onPanelChange"
            >
                <template #dateCellRender="{ current }">
                    <ul class="events">
                        <li
                            v-for="item in getListData(current)"
                            :key="item.content"
                        >
                            <a-badge :status="item.type" :text="item.content" />
                        </li>
                    </ul>
                </template>
                <template #monthCellRender="{ current }">
                    <div v-if="getMonthData(current)" class="notes-month">
                        <section>{{ getMonthData(current) }}</section>
                        <span>Backlog number</span>
                    </div>
                </template>
            </a-calendar>

            <a-modal
                v-model:visible="visibleshoppingcart"
                width="1400px"
                style="top: 20px"
                title="รายการ"
                @ok="handleOkshoppingcart"
            >
                <div v-if="!isshowspin">
                    <!-- <div class="pdf_icon" @click="getexcelshoppingg_fill_in">
                        <u class="pr-2"
                            >Export Excel Salepage (ลูกค้ากรอกข้อมูล ex.แผ่นทอง
                            ว/ด/ป )</u
                        >
                        <FileExcelOutlined :style="{ fontSize: '32px' }" />
                    </div>
                    <div class="pdf_icon" @click="getexcelshopping">
                        <u class="pr-2">Export Excel (สินค้าที่ลูกค้าสั่ง)</u>
                        <FileExcelOutlined :style="{ fontSize: '32px' }" />
                    </div> -->
                    <div>
                        <a-alert
                            style="width: 200px"
                            :message="`รายชื่อ : ${dataSources?.length} รายการ`"
                        />
                    </div>
                </div>
                <div v-else-if="isshowspin" class="text-center pb-4">
                    <a-tag color="processing">
                        <template #icon>
                            <sync-outlined :spin="true" />
                        </template>
                        กำลังสร้างไฟล์...
                    </a-tag>
                </div>

                <a-table
                    v-if="dataSources"
                    :scroll="{ x: 1300, y: 1000 }"
                    :columns="columns"
                    :data-source="dataSources"
                    @change="handleTableChange"
                    @expandFixed="true"
                    :row-class-name="
                        (_record, index) =>
                            index % 2 === 1 ? 'table-striped' : null
                    "
                >
                    <template #bodyCell="{ column, record }">
                        <!-- {{record}} -->
                        <template v-if="column.dataIndex === 'Order'">
                        </template>
                        <template v-if="column.dataIndex === 'BirthDate'">
                            <div>
                                {{
                                    `${record.BirthDay||'-'}/${
                                        record.BirthMonth||'-'
                                    }/${record.BirthYear?record.BirthYear + 543:'-'}`
                                }}
                            </div>
                        </template>
                        <template v-if="column.dataIndex === 'CreateDate'">
                            <div v-if="record.CreateDate">
                                <div>
                                    {{ formatdate(record.CreateDate) }}
                                </div>
                            </div>
                        </template>
                    </template>
                </a-table>
            </a-modal>
        </div>
        <FromCreateSell
            v-if="rowselecteddetail.ishowdetail"
            :rowselected="rowselecteddetail"
        ></FromCreateSell>
    </div>
</template>
<script>
import dayjs from "dayjs";
import { message } from "ant-design-vue";
import TableLoad from "@/components/Skeleton/TableLoad";
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, h } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";

import moment from "moment";
import "moment/locale/th";
import Pixcelview from "./Pixcelview.vue"; // without this line it didn't work

import {
    FilePdfOutlined,
    FileExcelOutlined,
    LoadingOutlined,
    SyncOutlined,
    CreditCardOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    ClockCircleOutlined
} from "@ant-design/icons-vue";

const columns = [
    {
        title: "ลำดับงาน",
        dataIndex: "OrderTransaction",
        width: "20%"
    },
    {
        title: "ชื่อ",
        dataIndex: "Name",
        width: "20%"
    }, 
    {
        title: "วันเกิด",
        dataIndex: "BirthDate",
        width: "20%"
    },
    {
        title: "วันที่สร้างรายการ",
        dataIndex: "CreateDate",
        width: "30%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        // CheckOutlined,
        FromCreateSell,
        Pixcelview,
        FilePdfOutlined,
        FileExcelOutlined,
        LoadingOutlined,
        SyncOutlined,
        CreditCardOutlined,
        StopOutlined,
        ExclamationCircleOutlined,
        ClockCircleOutlined
    },

    setup() {
        const success = txt => {
            message.info(txt, 10);
        };
        const dataSources = ref([]);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "รายกิจกรรมลูกค้า",
            current,
            pageSize
        } = usePagination("", {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: "24px"
            },
            spin: true
        });

        const isshowspin = ref(false);

        const list_result = ref([]);
        const getcalendar = async () => {
            await BzbsUser.getshoppingcartcalendar()
                .then(res => {
                    list_result.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        onMounted(() => {
            getcalendar();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LLL");
        };

        const formatBirthDate = birthData => {
            const { BirthDay, BirthMonth, BirthYear } = birthData;
            return `${BirthDay}/${BirthMonth}/${BirthYear + 543}`; // Convert to Thai Year (พ.ศ.)
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            var param = {
                date: selectedValue.value.format("YYYY-MM-DD")
            };

            return BzbsUser.apigetcustomermission_by_date(param)
                .then(res => {
                    // res.data[0].forEach((element, index) => {
                    //     res.data[0][index].key = index;
                    // });
                    dataSources.value = res.data;
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = arraySellOrder => {
            return arraySellOrder.group(
                ({ ProductSellOrder }) => ProductSellOrder
            );
        };
        const date = ref(dayjs("2017-01-25"));
        const selectedValue = ref(dayjs("2017-01-25"));

        const onSelect = value => {
            date.value = value;
            selectedValue.value = value;
            showModalshoppingcart();
        };
        const onPanelChange = value => {
            date.value = value;
        };

        const getListData = value => {
            let listData;
            list_result.value.find(element => {
                if (element.Date == value.format("YYYY-MM-DD")) {
                    listData = [
                        {
                            type: "warning",
                            content: `มีลูกค้าสั่งซื้อ ${element.ShoppingCount} รายการ`
                        }
                    ];
                }
            });
            return listData || [];

            // switch (value.date()) {
            //     case 8:
            //         listData = [
            //             {
            //                 type: "warning",
            //                 content: "This is warning event."
            //             },
            //             {
            //                 type: "success",
            //                 content: "This is usual event."
            //             }
            //         ];
            //         break;
            //     case 10:
            //         listData = [
            //             {
            //                 type: "warning",
            //                 content: "This is warning event."
            //             },
            //             {
            //                 type: "success",
            //                 content: "This is usual event."
            //             },
            //             {
            //                 type: "error",
            //                 content: "This is error event."
            //             }
            //         ];
            //         break;
            //     case 15:
            //         listData = [
            //             {
            //                 type: "warning",
            //                 content: "This is warning event"
            //             },
            //             {
            //                 type: "success",
            //                 content: "This is very long usual event。。...."
            //             },
            //             {
            //                 type: "error",
            //                 content: "This is error event 1."
            //             },
            //             {
            //                 type: "error",
            //                 content: "This is error event 2."
            //             },
            //             {
            //                 type: "error",
            //                 content: "This is error event 3."
            //             },
            //             {
            //                 type: "error",
            //                 content: "This is error event 4."
            //             }
            //         ];
            //         break;
            //     default:
            // }
            // return listData || [];
        };
        const getMonthData = value => {
            if (value.month() === 8) {
                return 1394;
            }
        };

        const visibleshoppingcart = ref(false);
        const showModalshoppingcart = () => {
            querycustomer();
            visibleshoppingcart.value = true;
        };
        const handleOkshoppingcart = e => {
            console.log(e);
            visibleshoppingcart.value = false;
        };

        const getexcelshoppingg_fill_in = async () => {
            isshowspin.value = true;
            var param = {
                date: selectedValue.value.format("YYYY-MM-DD")
            };
            await BzbsUser.apireportgetexcelshopping_fill_in(param)
                .then(async response => {
                    const blob = await response.data;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "shopping.xlsx"; // Set the desired filename
                    link.click();
                    isshowspin.value = false;
                })
                .catch(error => {
                    isshowspin.value = false;
                    console.log(error);
                });
        };

        const formSearch = reactive({
            ordertransaction_from: "",
            ordertransaction_to: ""
        });
        const onFinish = values => {
            
            debugger;
            if (!(parseInt(values.ordertransaction_from) <
                parseInt(values.ordertransaction_to)))
                return alert("กรอกเลขผิด");
            getexcelshopping(values);
            console.log("Success:", values);
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const getexcelshopping = async param => {
            debugger;
            isshowspin.value = true;
            await BzbsUser.apireportgetcustomermission_by_ordertransaction(
                param
            )
                .then(async response => {
                    const blob = await response.data;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "customermission.xlsx"; // Set the desired filename
                    link.click();
                    isshowspin.value = false;
                })
                .catch(error => {
                    isshowspin.value = false;
                    console.log(error);
                });
        };
        return {
            formSearch,
            onFinish,
            onFinishFailed,
            showModalshoppingcart,
            handleOkshoppingcart,
            visibleshoppingcart,
            getListData,
            getMonthData,
            getListData,
            onSelect,
            selectedValue,
            onPanelChange,
            groupBy,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            formatBirthDate,
            updatedata,
            getUniqueListBy,
            success,
            list_result,
            getexcelshopping,
            getexcelshoppingg_fill_in,
            indicator,
            isshowspin
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}

.events {
    list-style: none;
    margin: 0;
    padding: 0;
}
.events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
}
.notes-month {
    text-align: center;
    font-size: 28px;
}
.notes-month section {
    font-size: 28px;
}

.pdf_icon {
    float: right;
    padding: 10px;
}
.ant-badge-status-text {
    font-size: 16px !important;
}
</style>
