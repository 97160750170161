<template>
    <div class="v-list">
        <template v-for="(item, idx) of items" :key="`v-list-item-${idx}`">
            <template v-if="idx > 0">
                <div class="border-t mx-4"></div>
            </template>
            <notification-item :item="item"></notification-item>
        </template>
    </div>
</template>

<script>
import NotificationItem from "./NotificationItem.vue"

export default {
    name: "NotificationList",
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    components: {
        NotificationItem
    }
}
</script>
