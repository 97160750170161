// import Account from "@/helper/AccountHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import BzbsApi from "@/core/Http/BzbsApi.js";
import { map } from "rxjs/operators";
import { AccountModel } from "../model/AccountModel.js";

/**
 * @method apiRequestOTP
 * @method apiLogin
 * @method apiLogout
 * @method apiLoginLine
 * @method apiLoginDevice
 * @method apiReLoginDevice
 */
export default {
    /**
     * @param {AuthLoginModel} params /core/Account/config/AuthConfigModel.js
     */
    apiLogin: function(params) {
        const url = ConfigManager.getApiUrl() + "/auth/bzbs_login";
        return BzbsApi.connectBzbsPost(url, null, params).pipe(
            map(res => {
                let accountModel = new AccountModel();
                accountModel.setDataFromApi(res.data);
                return accountModel;
            })
        );
    },

    /**
     * @param {String} token
     * @param {String} strFBToken
     * @param {String} strUUID
     */
    apiLogout: function(token, FacebookToken, uuid) {
        const params = {
            uuid: uuid,
            access_token: FacebookToken
        };

        const url = ConfigManager.getApiUrl() + "/auth/logout";
        return BzbsApi.connectBzbsPost(url, token, params).pipe(
            map(res => {
                return res.data;
            })
        );
    },

    /**
     * @param {string} lineIdToken
     */
    apiLoginLine: function(lineIdToken) {
        const params = {
            app_id: ConfigManager.getAppId(),
            id_token: lineIdToken
        };
        const url = ConfigManager.getApiUrl() + "/auth/line_login";
        return BzbsApi.connectBzbsPost(url, null, params).pipe(
            map(res => {
                return res.data;
            })
        );
    },

    /**
     * @param {AuthDeviceLoginModel} params /core/Account/model/AuthConfigModel.js
     */
    apiLoginDevice: function(params) {
        params.app_id = ConfigManager.getAppId();

        const url = ConfigManager.getApiUrl() + "/auth/device_login";
        return BzbsApi.connectBzbsPost(url, null, params).pipe(
            map(res => {
                return res.data;
            })
        );
    },

    /**
     * Resume Login
     * @param {string } token
     * @param {Object} data  // cache user
     */
    apiReLoginDevice: function(token, data) {
        const params = {
            uuid: data.uuid,
            app_id: ConfigManager.getAppId(),
            os: "web",
            platform: "web",
            mac_address: data.uuid
            // device_noti_enable: data.isDeviceNotiEnable,
            // client_version: data.strClientVersion,
        };

        const url = ConfigManager.getApiUrl() + "/auth/device_resume";
        return BzbsApi.connectBzbsPost(url, token, params).pipe(
            map(res => {
                return res.data;
            })
        );
    }
};
