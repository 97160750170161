<template>
    <div class="pt-3 px-2" v-if="refresh">
        <InputRadio
                v-model:inputValue="addressModel.personType"
                :inputLbl="lbl.tax.personType"
                :itemList="personTypeValue"
                :errorInfo="errorInfo[0]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
        ></InputRadio>
        <div v-if="addressModel.personType == 'individual'">
            <InputRadio
                    v-model:inputValue="addressModel.title"
                    :inputLbl="lbl.tax.title"
                    :itemList="prefixValue"
                    :errorInfo="errorInfo[1]"
                    :isFirstSave="isFirstSave"
                    :isShowErrorLbl="true"
            ></InputRadio>
            <InputText
                v-model:inputValue="addressModel.firstname"
                :errorInfo="errorInfo[2]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.firstname"
            ></InputText>
            <InputText
                v-model:inputValue="addressModel.lastname"
                :errorInfo="errorInfo[3]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.lastname"
            ></InputText>
        </div>
        <div v-if="addressModel.personType == 'company'">
            <InputText
                v-model:inputValue="addressModel.companyName"
                :errorInfo="errorInfo[4]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.companyName"
            ></InputText>
        </div>
        <InputNationalId
                key="input-address-taxid"
                v-model:inputValue="addressModel.taxId"
                :errorInfo="errorInfo[5]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.nationalid"
        ></InputNationalId>
        <InputContactNumber
                key="input-address-contactnumber"
                v-model:inputValue="addressModel.contactNumber"
                :errorInfo="errorInfo[6]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.contactnumber"
                :minLength="10"
                :maxLength="12"
                delimeter="-"
        ></InputContactNumber>
        <InputEmail
                key="input-address-email"
                v-model:inputValue="addressModel.email"
                :maxLength="50"
                :errorInfo="errorInfo[7]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.email"
        ></InputEmail>
        <InputText
                key="input-address-building"
                v-model:inputValue="addressModel.building"
                :errorInfo="errorInfo[8]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.building"
        ></InputText>
        <InputText
                key="input-address-room"
                v-model:inputValue="addressModel.room"
                :errorInfo="errorInfo[9]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.room"
        ></InputText>
        <InputNumber
                key="input-address-floor"
                v-model:inputValue="addressModel.floor"
                :errorInfo="errorInfo[10]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.floor"
        ></InputNumber>
        <InputText
                key="input-address-villege"
                v-model:inputValue="addressModel.village"
                :errorInfo="errorInfo[11]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.village"
        ></InputText>
        <InputText
                key="input-address-number"
                v-model:inputValue="addressModel.number"
                :errorInfo="errorInfo[12]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.number"
        ></InputText>
        <InputText
                key="input-address-moo"
                v-model:inputValue="addressModel.moo"
                :errorInfo="errorInfo[13]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.moo"
        ></InputText>
        <InputText
                key="input-address-soi"
                v-model:inputValue="addressModel.soi"
                :errorInfo="errorInfo[14]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.soi"
        ></InputText>
        <InputText
                key="input-address-road"
                v-model:inputValue="addressModel.road"
                :errorInfo="errorInfo[15]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.tax.road"
        ></InputText>
        <FormAddress
                v-model:inputValue="formAddressModel"
                :errorInfo="errorInfo[16]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping"
        ></FormAddress>
    </div>
</template>
<script>
// import Util from '@/helper/Utility.js';
import { FormHelper, DropdownListValue } from '@/helper/FormHelper.js';
//component
import InputText from '@/components/Input/label/InputText.vue';
import InputContactNumber from '@/components/Input/label/InputContactNumber.vue';
import FormAddress from '@/components/Form/FormAddress.vue';
import InputRadio from '@/components/Input/label/InputRadio.vue';
import InputNationalId from '@/components/Input/label/InputNationalId.vue';
import InputEmail from '@/components/Input/label/InputEmail.vue';
import InputNumber from '@/components/Input/label/InputNumber.vue';
//mixin
import LocaleMixin from '@/mixin/LocaleMixin.js';
//service
import { AddressModel } from '@/core/Address/model/AddressModel.js';
import { AddressListModel } from '@/core/Address/model/AddressListModel.js';

export default {
    name: 'FormTaxAddress',
    mixins: [LocaleMixin],
    components: {
        InputText,
        InputContactNumber,
        FormAddress,
        InputRadio,
        InputNationalId,
        InputEmail,
        InputNumber,
    },
    data: function() {
        return {
            personTypeValue: [],
            prefixValue: [],
            isFirstSave: true,
            errorInfo: [],
            refresh: true,

            formAddressModel: null,
            addressModel: null,
        };
    },
    created: function() {
        this.initForm(null);
        this.setupFormValue();

    },
    methods: {
        initForm: function(address) {
            this.isFirstSave = true;

            if (address != null) {
                this.addressModel = {...address};
                this.formAddressModel.setValueFromAddressListModel(this.addressModel);
            } else {
                this.addressModel = new AddressListModel();
                this.formAddressModel = new AddressModel();
            }


            this.refresh = false;
            this.$nextTick(() => {
                this.refresh = true;
            });
        },
        setupFormValue: function() {
            this.errorInfo = FormHelper.genErrorInfo(17);

            var personType = new DropdownListValue();
            personType.add("บุคคลธรรมดา", "individual");
            personType.add("นิติบุคคล", "company");
            this.personTypeValue = personType.getList();

            var prefixValue = new DropdownListValue();
            prefixValue.add("นาย", "mr");
            prefixValue.add("นางสาว", "ms");
            prefixValue.add("นาง", "mss");
            this.prefixValue = prefixValue.getList();
        },
        clearForm: function() {
            this.initForm(null);
        },
        submitForm: function() {
            this.isFirstSave = false;
            if (this.validate()) {
                this.addressModel.setValueFromAddressModel(this.formAddressModel);
                this.addressModel.setIsTax();
                return this.addressModel;
            }
            return null
        },
        validate: function() {
            // return FormHelper.validate(this.errorInfo);
             for (let i=0 ; i<17 ; i++) {
                if (this.addressModel.personType == 'individual') {
                    if (i == 4) {
                        continue;
                    }
                } else if (this.addressModel.personType == 'company') {
                    if (i >= 1 && i <= 3) {
                        continue;
                    }
                }

                if (!this.errorInfo[i].isValid) {
                    return false;
                }
            }

            return true;
        },
    },
}
</script>
