<template>
    <a-button type="primary" class="mb-2" @click="showModal">
        <template #icon>
            <PlusCircleOutlined />
        </template>
        {{ textbtn }}
    </a-button>
    <!-- //modal สร้าง -->
    <a-modal v-model:visible="visible" width="1400px" style="top: 10px" title="รายการติดต่อ" @ok="fromchange"
        :footer="null">
        <ContactList :formStates="formStates" @closemodal="handleclosemodal(e)" v-model:value="formState.contact">
        </ContactList>
    </a-modal>
    <!-- //modal สร้าง -->
    <!-- //modal แก้ไข -->
    <a-modal v-model:visible="visibleEdit" width="1000px" title="Basic Modal" @ok="fromchange">
    </a-modal>
    <!-- //modal แก้ไข -->
    <!-- //ตาราง -->
    <!-- dataSource: {{dataSource}} -->
    <a-table :columns="columns" size="middle" :data-source="dataSource" bordered :scroll="{ x: 0 }" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'key'">
                <div>รายการที่ {{ index + 1 }}</div>
            </template>
            <template v-else-if="column.dataIndex === 'contactname'">
                <div>
                    {{ record.contactname }}
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'contactimage'">
                <div v-if="record.contactimage">
                    <a-image :width="200" :src="record.contactimage[0].preview || record.contactimageurl" />
                </div>
                <!-- <div v-if="record.contactimage">
                    <a-image
                        :width="200"
                        :src="record.contactimage[0].preview"
                    />
                </div> -->
                <div v-else>
                    <a-avatar shape="square" :size="64">
                        <template #icon>
                            <UserOutlined />
                        </template>
                    </a-avatar>
                </div>
            </template>

            <template v-else-if="column.dataIndex === 'introduction'">
                <div>
                    {{ record.introduction }}
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'start'">
                <div>
                    <span class="m-2">
                        <a-popconfirm title="ตั้งค่าที่อยู่เริ่มต้น?" @confirm="setstart(record, index)">
                            <a>ตั้งค่าเริ่มต้น</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'startdefault'">
                <div :style="{ margin: 'auto', 'text-align': 'center' }">
                    <div class="text-center">
                        <font-awesome-icon v-if="record.contactstartdefault == 'true'" icon="fa-solid fa-check" center />
                    </div>
                    <div class="text-center">
                        <font-awesome-icon v-if="
                            record.contactstartdefault == undefined ||
                            record.contactstartdefault == 'false'
                        " icon="fa-solid fa-xmark" center />
                    </div>
                    <!-- <check-circle-outlined
                        v-if="record.contactstartdefault == 'true'"
                        :style="{ fontSize: '32px', color: 'green' }"
                    />
                    <minus-circle-outlined
                        v-if="
                            record.contactstartdefault == undefined ||
                                record.contactstartdefault == 'false'
                        "
                        :style="{ fontSize: '32px', color: 'red' }"
                    /> -->
                    <!-- <span class="m-2">
                        <a-popconfirm
                            title="Sure to cancel?"
                            @confirm="setstart(record.key)"
                        >
                            <a>ตั้งค่าเริ่มต้น</a>
                        </a-popconfirm>
                    </span> -->
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
                <div class="editable-row-operations">
                    <span v-if="editableData[record.key]">
                        <a-typography-link @click="save(record.key)">บันทึก</a-typography-link>
                        <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.key)">
                            <a>ยกเลิก</a>
                        </a-popconfirm>
                    </span>
                    <span v-else class="m-2">
                        <a @click="edit(record.key)">แก้ไข</a>
                    </span>
                </div>
            </template>
        </template>
    </a-table>
    <!-- formStates.getcontactlist : {{ formStates.getcontactlist }} -->
    <!-- formState.contact :
    <pre>{{ formState.contact }}</pre> -->
    <!-- //ตาราง -->
</template>
<script>
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    CheckCircleOutlined
} from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch } from "vue";
// import SelectOption from "../form/SelectOption.vue";
// import Address from "../../component/Address.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import ContactList from "./ContactList.vue";
import { UserOutlined } from "@ant-design/icons-vue";
import { ContactModel } from "../../../../../core/Create/model/CreateModel";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "key",
    },
    {
        title: "รูปแนบ",
        dataIndex: "contactimage"
    },
    {
        title: "ชื่อการติดต่อ",
        dataIndex: "contactname"
    },
    {
        title: "หมายเหตุ",
        dataIndex: "introduction"
    },
    {
        title: "ตั้งค่าเริ่มต้น",
        dataIndex: "start"
    },
    {
        title: "ตั้งค่าเริ่มต้น",
        dataIndex: "startdefault"
    },
    {
        title: "ดำเนินการ",
        dataIndex: "operation",
    }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        // SelectOption,
        // Address,
        UserOutlined,
        PlusCircleOutlined,
        MinusCircleOutlined,
        CheckCircleOutlined,
        ContactList
    },
    props: {
        textbtn: null,
        formStates: null
    },
    setup(props, { emit }) {
        const visible = ref(false);
        const visibleEdit = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            contact: {
                sights: []
            },
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "TH"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    )
                ) {
                    formState.useraddress.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    );
                }
            }
        );


        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            // if (visibleEdit.value) {
            //   formState.listproduct[editIndex.value] = formState.useraddress;
            // } else {
            //   let obj = {};
            //   obj = formState.useraddress;
            //   obj.key = list.listproduct.length == 0 ? 0 : list.listproduct.length;
            //   console.log("obj.key", obj.key);
            //   console.log("obj", obj);
            //   list.listproduct[obj.key] = obj;
            //   console.log("list.listproduct", list.listproduct);
            // }
            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function (element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };
        const dataSource = ref(props.formStates.getcontactlist);
        // if (props.formStates.getcontactlist.length > 0) {
        //     dataSource.value = props.formStates.getcontactlist;
        // }
        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            Object.assign(
                dataSource.value.filter(item => key === item.key)[0],
                editableData[key]
            );
            delete editableData[key];
        };

        const cancel = key => {
            console.log(key);
        };
        const setstart = (record, key) => {

            if (props.formStates.getcontactlist.length > 0) {
                formState.contact.sights = props.formStates.getcontactlist;
            }
            let index_select = parseInt(key);
            formState.contact.sights.forEach((element, index) => {

                if (index == index_select) {
                    formState.contact.sights[index].contactstartdefault =
                        "true";
                } else {
                    formState.contact.sights[index].contactstartdefault =
                        "false";
                }
            });
            //  updatelist(record)
        };
        const updatelist = async record => {
            let params = [];
            // formState.contact.sights.forEach(element => {
            //     var obj = new ContactModel(record);
            //     console.log("obj", obj);
            //     params.push(obj);
            // });

            console.log("param", params);

            // for await (let obj of params) {
            await BzbsUser.apiupdatecontact(new ContactModel(record))
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        var count = 1;
        const handleclosemodal = e => {
            
            // eslint-disable-next-line vue/no-mutating-props
            props.formStates.getcontactlist = formState.contact.sights;
            dataSource.value = formState.contact.sights;
            //ตั้งค่าเริ่มต้น
            if (dataSource.value.length == 1) {
                setstart(dataSource.value[0], 0)
            }

            visible.value = e;
            const product = {};
            product.key = count;
            product.inside = formState.productlist.inside;
            product.insidecod = formState.productlist.insidecod;
            product.outside = formState.productlist.outside;
            product.userproduct = formState.productlist.userproduct;
            product.sellorderTransaction =
                formState.productlist.sellorderTransaction;
            product.orderdate = formState.productlist.orderdate;

            list.listproduct.push(product);
            count = count + 1;
            emit("update:value", formState.contact.sights);
        };



        return {
            updatelist,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            setstart
        };
    }
});
</script>
