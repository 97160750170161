<template>
    <div class="p-3">
        <a-row>
            <a-col>

                <a-input ref="myinput" v-model:value="code" @change="checkbarcode" placeholder="barcode">
                    <template #prefix>
                        <barcode-outlined />
                    </template>
                </a-input>
            </a-col>
            <a-col>
                <a-button class="ml-4" @click="submitData" type="primary">
                    สแกนค้นหา
                </a-button>
            </a-col>
        </a-row>


        <!-- <pre>{{result}}</pre> -->
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import axios from "axios";
import { formStateStore } from "@/store/storebackoffice";
import {
    BarcodeOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
    emits: ["ResultScan"],
    components: {
        BarcodeOutlined
    },
    setup(props, { emit }) {


        const isclickscan = ref(false)
        const result = ref([])
        const myinput = ref(null)
        const code = ref('');
        const callproductblob = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            axios.all([axios.get(path + "/api/getproductjson/yan"),
            axios.get(path + "/api/getproductjson/yanpot"),
            axios.get(path + "/api/getproductjson/mobile"),
            axios.get(path + "/api/getproductjson/caseandwall")]
            )
                .then(axios.spread((firstResponse, secondResponse, thirdResponse, caseandwallResponse) => {

                    const yan = firstResponse.data.yan.find(element => element['รวมรหัส'] == code.value?.trim());
                    const yanpot = secondResponse.data.yanpot.find(element => element['รวมรหัส'] == code.value?.trim());
                    const mobile = thirdResponse.data.mobile.find(element => element['รหัสสินค้า'] == code.value?.trim());
                    const caseandwall = caseandwallResponse.data.caseandwall.find(element => element['บาร์โค้ด'] == code.value?.trim());

                    result.value = []
                   
                    if (yan) {
                        var obj = {}
                        obj = {
                            "heartyanname": yan["หัวใจยันต์"],
                            "customerproductstype": "ยันต์เขียนสด",
                            "customerproductssize": yan["ขนาด"],
                            "productcode": yan["รวมรหัส"],
                            "productprice": yan["ราคาขาย"],
                            "productdetail": {
                                "yan_size": yan["ขนาด"],
                                "heartyan": yan["หัวใจยันต์"],
                                "price": yan["ราคาขาย"],
                                "productcode": yan["รวมรหัส"]
                            }
                        }
                        result.value.push(obj)
                    }
                    if (yanpot) {
                        
                        var obj = {}
                        obj = {
                            "heartyanname": yanpot["หัวใจยันต์"],
                            "customerproductstype": "ยันต์ปลด/คิวด่วน",
                            "customerproductssize": yanpot["ชื่อสินค้าแสดงลูกค้า"],
                            "productcode": yanpot["รวมรหัส"],
                            "productprice": yanpot["ราคาขาย"],
                            "productdetail": {
                                "yan_size": yanpot["ชื่อสินค้าแสดงลูกค้า"],
                                "heartyan": yanpot["หัวใจยันต์"],
                                "price": yanpot["ราคาขาย"],
                                "productcode": yanpot["รวมรหัส"]
                            }
                        }
                        result.value.push(obj)
                    }
                    if (mobile) {
                  
                        var obj = {}
                        obj = {
                            "heartyanname": mobile["ชื่อสินค้า"],
                            "customerproductstype": "วัตถุมงคล",
                            "customerproductssize": mobile["หมวดหมู่ประเภทหลัก"],
                            "productcode": mobile["รหัสสินค้า"],
                            "productprice": mobile["ราคา"],
                            "productdetail": {
                                "sub_type": mobile["หมวดหมู่ประเภทย่อย"],
                                "productname": mobile["ชื่อสินค้า"],
                                "productsize": mobile["สี/ไซส์"],
                                "price": mobile["ราคา"],
                                "productcode": mobile["รหัสสินค้า"],
                                "code_type": mobile["ประเภทหลัก"],
                                "code_sub_product_type": mobile["รหัส สี/ไซส"],
                                "code_productsize": mobile["รหัสประเภทย่อย"],
                            }

                        }
                        result.value.push(obj)
                    }
                    if (caseandwall) {
                       
                        var obj = {}
                        obj = {
                            "heartyanname": caseandwall["ประเภท"],
                            "customerproductstype": "เคส/ภาพพื้นหลัง",
                            "customerproductssize": caseandwall["วัตถุมงคล"],
                            "productcode": caseandwall["บาร์โค้ด"],
                            "productprice": caseandwall["ราคา(บาท)"],
                            "productdetail": {
                                "productname": caseandwall["ประเภท"],
                                "price": caseandwall["ราคา(บาท)"],
                                "product_barcode": caseandwall["บาร์โค้ด"],
                                "productcode": caseandwall["รหัสวัตถุมงคล"]
                            }
                        }
                        result.value.push(obj)
                    }
                    

                    if (code.value.length > 0) code.value = ''
                    emit("ResultScan", result.value);
                }))
                .catch(error => console.log(error));

        }
        const checkbarcode = () => {
            if (code.value.length > 10) {
                formStateStore.userproduct = {
                    heartyanname: "",
                    queyan: "1",
                    professortype: [],
                    inside: [],
                    outside: [],
                    introductionyan: ""
                },
                    callproductblob()
            }

        }
        const submitData = () => {
          
            isclickscan.value = !isclickscan.value
            setTimeout(() => {
                myinput.value.focus()
            }, 500);
        }

        return {
            myinput,
            checkbarcode,
            result,
            isclickscan,
            code,
            submitData
        };
    },
});
</script>