<template>
    <div class="flex items-start">
        <div
            class="flex-none text-sm mb-1 px-6 mt-2"
            :class="[isError ? 'text-red-500' : 'text-gray-500', inputLbl.align != undefined ? inputLbl.align : null]"
            :style="{ width: labelWidthComputed }"
        >{{ inputLbl.name }}</div>
        <div class="flex-auto relative pb-5">
            <input
                :type="inputType"
                class="w-full bg-white px-3 py-2 placeholder-gray-500 rounded border focus:border-blue-1 text-sm"
                :class="[isError ? 'border border-red-500' : '', bgComputed != null ? bgComputed : null]"
                v-model="content"
                :placeholder="inputLbl.placeholder"
                :maxlength="inputMaxLength"
                @input="onChange()"
            />
            <div
                class="text-red-500 text-sm absolute bottom-0 left-0"
                v-if="isError"
            >{{ getErrorMsg }}</div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import RegxHelper from "@/helper/RegxHelper.js";
import PropsHelper from '@/helper/PropsHelper';

/**
 * @property {String} v-model:inputValue (required) bind value
 * @property {Number} minLength (optional) Minimum length.
 * @property {Number} maxLength (optional) Maximum Length.
 * @property {Boolean} isTextOnly (optional) Only text.
 * @property {Object} errorInfo (required) Validate result. example : { isValid: true, msg: '',}
 * @property {Boolean} isFirstSave (optional) Show/Hide Error after save.
 * @property {Boolean} isShowErrorLbl (optional) Show/Hide Error label.
 * @property {Object} inputLbl (required) Text for component.
 */
export default {
    name: "InputTextInlineLabel",
    props: {
        //v-model
        inputValue: { type: String },
        //props
        minLength: { type: Number },
        maxLength: { type: Number, default: 0 },
        isTextOnly: { type: Boolean, default: false },
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        //text
        inputLbl: { type: Object, required: true },
        labelWidth: {
            type: [Number, String],
            default: 100
        },
        bg: {
            type: String,
            default: undefined
        },
        inputType: {
            type: String,
            default: "text"
        }
    },
    data: function () {
        return {
            content: this.inputValue,
            inputMaxLength: null,
        };
    },
    computed: {
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
        labelWidthComputed: function () {
            return PropsHelper.getBoxUnit(this.labelWidth)
        },
        bgComputed: function () {
            return PropsHelper.getStringProp(this.bg)
        }
    },
    created: function () {
        if (this.maxLength > 0) {
            this.inputMaxLength = this.maxLength;
        }
    },
    mounted: function () {
        this.validateInput();
    },
    methods: {
        validateInput: function () {

            if (!this.inputLbl) {
                return;
            }
            //Set default.
            this.errorInfo.setValid(true);
            this.errorInfo.setMsg('');
            //Required
            if (this.inputLbl.required && !this.content) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.required)
                return;
            }
            //Too short
            if (this.inputLbl.too_short && this.minLength
                && (this.content.length < this.minLength)) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(Util.stringFormat(this.inputLbl.too_short, this.minLength));
                return;
            }
            //InValid format
            if (this.inputLbl.invalid) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.invalid);
                return;
            }
        },
        onChange: function () {
            var result = this.content;

            if (this.isTextOnly) {
                result = RegxHelper.removeEmoji(result);
                result = RegxHelper.removeNumber(result);
                result = RegxHelper.removeSymbol(result);
            }

            this.content = result;
            this.validateInput();
            this.$emit('update:inputValue', result);
        },
    },
}
</script>
<style lang="scss" scoped>
</style>
