<template>
    <div>
        <div
            class="avatar flex items-center justify-center"
            :class="[bgColor, rounded]"
            :style="avatarStyle"
        >
            <template v-if="img == null">
                <span
                    class="avatar-text font-semibold"
                    :class="[color]"
                    :style="{ fontSize: fontSizeComputed }"
                >{{ text }}</span>
            </template>
            <template v-else>
                <img class="avatar-img rounded-lg" :src="img" alt />
            </template>
        </div>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper";

export default {
    name: 'VAvatar',
    props: {
        img: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        bgColor: {
            type: String,
            default: "bg-gray-500",
        },
        color: {
            type: String,
            default: "text-white",
        },
        rounded: {
            type: String,
            default: 'rounded-md'
        },
        size: {
            type: [Number, String],
            default: 36
        },
        fontSize: {
            type: [Number, String],
            default: 13
        }
    },
    computed: {
        avatarStyle: function () {
            let size = PropsHelper.getBoxUnit(this.size)
            return {
                width: size,
                height: size
            }
        },
        fontSizeComputed: function () {
            return PropsHelper.getBoxUnit(this.fontSize)
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    .avatar-img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}
</style>
