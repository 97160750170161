<template>
    <YoutubeModal v-if="value == 'youtube'" @onyoutube="addvalue($event, 'youtube')"></YoutubeModal>
    <Timer v-if="value == 'timer'" @ontimer="addvalue($event, 'timer')"></Timer>
    <UploadVideo v-if="value == 'video'" @onvideo="addvalue($event, 'video')"></UploadVideo>
    <ImageModal v-if="value == 'image'" :sizeupimage="1" @onimage="addvalue($event, 'image')"></ImageModal>
    <ImageModal v-if="value == 'slideimage'" :key="update" :sizeupimage="10" @onimage="addvalue($event, 'slideimage')">
    </ImageModal>
    <AddButton v-if="value == 'addbutton'" @onaddbutton="addvalue($event, 'addbutton')"></AddButton>
    <Form v-if="value == 'form'" @onform="addvalue($event, 'form')"></Form>
    <TextEditor v-if="value == 'message'" @onmessage="addvalue($event, 'message')"></TextEditor>
    <a-space v-if="!onEdit.isshowedit && fact_type == 'add'">
        <!-- <pre>{{ objsalepage }}</pre> -->
        <a-select v-model:value="value" style="width: 200px" @change="handleChange">
            <a-select-opt-group>
                <template #label>youtube</template>
                <a-select-option value="youtube">
                    <div class="absolute ">
                        <a-image :preview="false" class="p-1" :width="28"
                            src="https://live.staticflickr.com/7132/7749081576_f256dba668_w.jpg" /> <span
                            class="absolute text-i60">
                            Youtube
                        </span>
                    </div>

                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <a-select-option value="video">
                    <div class="absolute ">
                        <a-image :preview="false" class="p-1" :width="28"
                            src="https://cdn0.iconfinder.com/data/icons/social-media-2415/64/37_Upload_video-512.png" />
                        <span class="absolute text-i60">
                            Up Video
                        </span>
                    </div>

                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <template #label> </template>
                <a-select-option value="image">
                    <div class="absolute ">
                        <a-image :preview="false" :width="20"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl2AsDP9Vp7qnSymCWlVG2bCKXzsurHeAU4A-fi6qCKRLXH7y9__xu-EuLfyjbEw9x26I&usqp=CAU" />
                        <span class="absolute text-i60">รูปภาพ</span>
                    </div>
                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <template #label> </template>
                <a-select-option value="addbutton">
                    <div class="absolute ">
                        <a-image :preview="false" class="p-1" :width="28"
                            src="https://cdn-icons-png.flaticon.com/512/1004/1004733.png" />
                        <span class="absolute text-i60">เพิ่มปุ่ม</span>
                    </div>
                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <template #label> </template>
                <a-select-option value="slideimage">
                    <div class="absolute ">
                        <a-image :preview="false" class="mt-1" :width="40" src="https://i.stack.imgur.com/OHOoU.png" />
                        <span class="absolute text-i60">สไลด์รูปภาพ</span>
                    </div>
                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <template #label> </template>
                <a-select-option value="message">
                    <div class="absolute ">
                        <a-image :preview="false" :width="30"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFJnDjfGRyZDQ-w7G6J8Us37Enxocn2Hy-b5VvUtSAlLxb2bbXcc4506SaWM-2t3H9vIE&usqp=CAU" />
                        <span class="absolute text-i60">ข้อความ</span>
                    </div>
                </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group>
                <template #label> </template>
                <a-select-option value="form">
                    <div class="absolute ">
                        <a-image :preview="false" class="p-1" :width="28"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRukNsPtR7DuF9CrL_Ww_ZSnjvOG9I0ibcNTzoVS5RNZYrTC8w8iqwQmF_CU9d2QH8XGMg&usqp=CAU" />
                        <span class="absolute text-i60">ฟอร์ม</span>
                    </div>
                </a-select-option>
            </a-select-opt-group>
        </a-select>
    </a-space>
    <!-- //test -->
</template>
<script>
import { defineComponent, ref, watch } from "vue";

import YoutubeModal from "./modalAdd/YoutubeModal.vue"
import Timer from "./modalAdd/Timer.vue"
// import Timer from "./modal/modalAdd/"
import ImageModal from "./modalAdd/ImageModal.vue"
import Form from "./modalAdd/Form.vue";
import TextEditor from "./modalAdd/TextEditor.vue";
import UploadVideo from "./modalAdd/Upvideo.vue";
import AddButton from "./modalAdd/AddButton.vue";
import { objsalepage, objsalepagethank, objsalepagethankform, current_tab, value, onEdit } from '@/store/store'

export default defineComponent({
    components: { YoutubeModal,Timer, ImageModal, Form, TextEditor, UploadVideo, AddButton },
    props: ['type'],
    setup(props, { emit }) {
        const fact_type = ref(props.type)
        const update = ref(0);
        const handleChange = value => {
            update.value++
            console.log(`selected ${value}`);
        };
        const options = ref([
            {
                label: "Youtube",
                options: [
                    {
                        value: "youtube",
                        label: "youtube"
                    },
                    {
                        value: "lucy",
                        label: "Lucy"
                    }
                ]
            },
            {
                label: "Engineer",
                options: [
                    {
                        value: "yiminghe",
                        label: "Yiminghe"
                    }
                ]
            }
        ]);
        watch(value, () => {
            // setTimeout(() => {
            //     value.value = ["เลือก"]
            // }, 1000);
        })
        const addvalue = (e, fact) => {
             
            console.log(onEdit.value)
            console.log(objsalepage.value)
            if (onEdit.value.isshowedit) {
                var indexedit = onEdit.value.editobj.order;
                if (current_tab.value == '1') {
                    // objsalepage.value.splice(indexedit - 1, 1);
                    objsalepage.value[indexedit - 1] = { detail: e, order: indexedit };
                    value.value = ["เลือก"]
                }
                else if (current_tab.value == '2') {
                    objsalepagethank.value[indexedit - 1] = { detail: e, order: indexedit };
                    value.value = ["เลือก"]
                }
                else if (current_tab.value == '3') {
                    objsalepagethankform.value[indexedit - 1] = { detail: e, order: indexedit };
                    value.value = ["เลือก"]
                }


            }
            else {
                console.log(fact)
                console.log("objsalepage.value"), objsalepage.value
                console.log("objsalepagethank.value", objsalepagethank.value)

                if (current_tab.value == '1') {
                    objsalepage.value.push({ detail: e, order: objsalepage.value.length + 1 });
                    value.value = ["เลือก"]
                }
                else if (current_tab.value == '2') {
                    objsalepagethank.value.push({ detail: e, order: objsalepagethank.value.length + 1 });
                    value.value = ["เลือก"]
                }
                else if (current_tab.value == '3') {
                    objsalepagethankform.value.push({ detail: e, order: objsalepagethankform.value.length + 1 });
                    value.value = ["เลือก"]
                }

            }
            console.log("objsalepage : ", objsalepage)
            emit('onobjsalepage', objsalepage)
            console.log(e);
            onEdit.value.isshowedit = false
        };



        const livechat = ref(false);
        const fadeout = ref(false);
        const fadebottom = ref(false);
        function clickchat() {
            if (livechat.value) {
                fadebottom.value = !fadebottom.value
                fadeout.value = !fadeout.value
                setTimeout(() => {
                    livechat.value = !livechat.value
                    fadeout.value = !fadeout.value
                }, 400);
            }
            else {
                fadebottom.value = !fadebottom.value
                livechat.value = !livechat.value
            }

        }

        function clickbtn(message) {
            console.log(message)
        }

        return {
            value,
            handleChange,
            options,
            objsalepage,
            addvalue,
            onEdit,
            update, clickchat, clickbtn, livechat,
            fact_type
        };
    }
});
</script>
<style lang="scss" scoped>
.text-i60 {
    text-indent: 20px;
}




.text-chat {
    left: auto;
    position: relative;
    text-align: right;
}

.text-chat-bottom {
    left: -210%;
    position: relative;
    text-align: left;
    top: 15px;
    width: 200px;

}

.text-inner {
    background: #d3d3d3;
    color: black;
    border-radius: 5px;
    padding: 5px;
    word-break: break-word;
}

.fade-out {
    text-decoration: none;
    position: relative;
    animation: fadeOut 0.4s;
    left: 20px !important;
}

@keyframes fadeOut {
    from {
        bottom: 0;
        opacity: 1
    }

    to {
        bottom: -300px;
        opacity: 0
    }
}

.fade-bottom {
    position: relative;
    animation: animatebottom 0.4s;
    left: 20px;
    max-width: 100px;

}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.c-chat {
    /* background-color: rgb(247, 129, 27); */
    transition: all 0.3s ease 0s;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: inline-block;
    text-decoration: none;
    float: right;
    position: relative;
}

.ic-bottom {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.is_close_popup_cookie {
    bottom: 12% !important;
}

.animate-bounce {
    display: inherit;
}

a {
    text-decoration: unset !important;
}


.btnfooter {
    background: url('../../../../../assets/imgs/logo/add.png');
    background-repeat: no-repeat;
    position: absolute;
    min-height: 79px;
    background-size: contain;
    width: 54% !important;
    right: 25% !important;
}




@media only screen and (max-width: 390px) {
    .btnfooter {
        background: url('../../../../../assets/imgs/logo/add.png');
        background-repeat: no-repeat;
        position: absolute;
        min-height: 79px;
        background-size: contain;
        width: 35px;
        right: 23%;
    }

}

.btnfooter {
    .ant-select-selector {
        height: 50px !important;
        border: 0px;
        color: transparent;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        left: -15px;
        top: -2px;
    }

    .ant-select-selection-item {
        color: transparent !important;
    }

}

.rc-virtual-list-holder-inner {
    padding-bottom: 20px;
}

.left-icon {
    margin-left: -10px;
    width: 40px;
}

.left-icon:hover {
    transform: scale(2);
}
</style>
