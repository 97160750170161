<template>
    <div>
        <!-- <pre>formState :: {{ formState }}</pre> -->

        <a-divider orientation="left"
            ><i><b>จัดส่งในประเทศ (ราคาเก็บลูกค้า)</b></i></a-divider
        >

        <a-form-item name="area" label="จัดส่งในประเทศ">
            <!-- <a-select v-model:value="dynamicValidateForm.area" :options="areas" /> -->
        </a-form-item>

        <a-form ref="formRef" name="formState" :model="formState">
            <a-space
                v-for="(remindinside, index) in formState.remindinside"
                :key="remindinside.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-form-item
                    :name="['remindinside', index, 'price']"
                    label="ราคา"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="remindinside.remindprice"
                        @change="onFinish('remindinside')"
                    />
                </a-form-item>
                <a-form-item
                    label="หมายเหตุ"
                    :name="['remindinside', index, 'introduction']"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="remindinside.remindintroduction"
                        @change="onFinish('remindinside')"
                    />
                </a-form-item>
            </a-space>
            <a-divider orientation="left"
                ><i><b>จัดส่งต่างประเทศ (ราคาเก็บลูกค้า)</b></i></a-divider
            >

            <a-form-item name="area" label="จัดส่งต่างประเทศ">
                <SelectOptionRemind
                    :formStates="formState"
                    v-model:value="formState.remindarea"
                ></SelectOptionRemind>
            </a-form-item>
            <a-space
                v-for="(remindoutside, index) in formState.remindoutside"
                :key="remindoutside.id"
                style="display: flex; margin-bottom: 8px"
                align="baseline"
            >
                <a-form-item
                    :name="['remindoutside', index, 'price']"
                    label="ราคา"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="remindoutside.remindprice"
                        @change="onFinish('remindoutside')"
                    />
                </a-form-item>
                <a-form-item
                    label="หมายเหตุ"
                    :name="['remindoutside', index, 'introduction']"
                    :rules="{
                        required: false,
                        message: 'กรุณากรอก'
                    }"
                >
                    <a-input
                        v-model:value="remindoutside.remindintroduction"
                        @change="onFinish('remindoutside')"
                    />
                </a-form-item>
            </a-space>
        </a-form>
    </div>
</template>
<script>
import SelectOptionRemind from "@/module/Sell/SellCreate/component/form/SelectOptionRemind.vue";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
// import Uploadfile from "../form/Uploadfile.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    components: {
        SelectOptionRemind,
        MinusCircleOutlined,
        PlusOutlined
        // Uploadfile
    },
    emits: ["update:value"],
    props: {
        formStates: null,
        type: null
    },

    setup(props, { emit }) {
        onMounted(() => {
            initremind();
        });
        const formItemContext = Form.useInjectFormItemContext();
        const is_paid_delivery = ref("1");
        const formState = reactive(props.formStates);
        if (props.type == "add") {
            formState.remindinside = [];
            formState.remindoutside = [];
        }

        const initremind = () => {
            BzbsUser.apigetdeliveryremind(
                props.formStates.userproduct.customerorder,
                props.formStates.userproduct.productkey
            )
                .then(res => {
                    // Object.keys(res.data).map(function (element) {
                    //     areas.push({
                    //         label: res.data[element],
                    //         value: element
                    //     });
                    // });
                    res.data.forEach(element => {
                        var detail = {
                            productkey: element.ProductKey,
                            id: element.Id,
                            remindprice: element.Price,
                            remindintroduction: element.Introduction
                        };
                        if (element.DeliveryType == "inside")
                            formState.remindinside = [detail];

                        if (element.DeliveryType == "outside") {
                            formState.reminduser = JSON.parse(
                                element.RemindUser
                            );
                            formState.remindoutside = [detail];
                        }
                    });

                    if (res.data.length == 0) {
                        var pk = props.formStates.userproduct.productkey;
                        formState.reminduser = [
                            {
                                productkey: pk,
                                id: Date.now()
                            }
                        ];

                        formState.remindoutside = [
                            {
                                productkey: pk,
                                id: Date.now()
                            }
                        ]; 

                        formState.reminduser = {
                            isgencode: false,
                            addressfrom: [],
                            contactList: [],
                            customerproductstype: "1",
                            selectcountry: {
                                selectcountry: "TH"
                            },
                            ordertransaction: "",
                            customeraddressType: "1",
                            addressalllist: []
                        };
                        debugger
                    }

                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        // if (_.isString(formState.listproduct[0].ProductJson)) {

        //     var ProductJson = JSON.parse(formState.listproduct[0].ProductJson)
        //     var init_remindinside = ProductJson[0]?.remindinside
        //     var init_remindoutside = ProductJson[0]?.remindoutside
        //     if (init_remindinside) {
        //         formState.remindinside = init_remindinside
        //     }
        //     if (init_remindoutside) {
        //         formState.remindoutside = init_remindoutside
        //     }
        // }

        // formState.remindinside.length > 0 || formState.remindoutside.length > 0 ? is_paid_delivery.value = '2' : is_paid_delivery.value = '1'

        if (
            formState.remindinside.length > 0 ||
            formState.remindoutside.length > 0
        ) {
            if (
                formState.remindoutside.length > 0 &&
                formState.remindoutside[0].introduction == "ส่งฟรี"
            ) {
                is_paid_delivery.value = "3";
            }
            if (
                formState.remindoutside.length > 0 &&
                formState.remindoutside[0].introduction == "รับเอง"
            ) {
                is_paid_delivery.value = "4";
            }
            // if(formState.remindinside[0].introduction == 'ส่งฟรี'){
            //     is_paid_delivery.value = '3'
            // }
        }

        const areas = reactive([
            {
                label: "Beijing",
                value: "Beijing"
            },
            {
                label: "Shanghai",
                value: "Shanghai"
            }
        ]);
        BzbsUser.apicountries()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    areas.push({
                        label: res.data[element],
                        value: element
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

        const sights = {
            Beijing: ["Tiananmen", "Great Wall"],
            Shanghai: ["Oriental Pearl", "The Bund"]
        };
        const formRef = ref();
        // const formState = reactive({
        //   remindinside: [],
        //   remindoutside: [],
        //   area: undefined,
        // });
        watch(
            () => formState.area,
            () => {
                formState.sights = [];
            }
        );
        watch(
            () => is_paid_delivery.value,
            () => {
                if (is_paid_delivery.value == "3") {
                    formState.remindinside = [
                        {
                            productkey: productkey_count_remindinside.value.toString(),
                            value: 0,
                            introduction: "ส่งฟรี",
                            price: 0,
                            id: Date.now()
                        }
                    ];
                    formState.remindoutside = [
                        {
                            productkey: productkey_count_remindinside.value.toString(),
                            value: 0,
                            introduction: "ส่งฟรี",
                            price: 0,
                            id: Date.now()
                        }
                    ];
                    emit("update:value", {
                        remindoutside: formState.remindoutside,
                        remindinside: formState.remindinside
                    });
                }
            },
            () => is_paid_delivery.value,
            () => {
                if (is_paid_delivery.value == "4") {
                    formState.remindinside = [
                        {
                            productkey: productkey_count_remindinside.value.toString(),
                            value: 0,
                            introduction: "รับเอง",
                            price: 0,
                            id: Date.now()
                        }
                    ];
                    formState.remindoutside = [
                        {
                            productkey: productkey_count_remindinside.value.toString(),
                            value: 0,
                            introduction: "รับเอง",
                            price: 0,
                            id: Date.now()
                        }
                    ];
                    emit("update:value", {
                        remindoutside: formState.remindoutside,
                        remindinside: formState.remindinside
                    });
                }
            }
        );

        watch(
            () => formState.remindinside,
            () => {
                try {
                    formState.remindoutside[0].price = 0;
                } catch (error) {}
            }
        );

        watch(
            () => formState.remindoutside,
            () => {
                try {
                    formState.remindinside[0].price = 0;
                } catch (error) {}
            }
        );

        const removeremindinside = item => {
            let index = formState.remindinside.indexOf(item);

            if (index !== -1) {
                formState.remindinside.splice(index, 1);
            }
        };
        const removeremindoutside = item => {
            let index = formState.remindoutside.indexOf(item);

            if (index !== -1) {
                formState.remindoutside.splice(index, 1);
            }
        };

        // const removeSight = (item) => {
        //   let index = dynamicValidateForm.sights.indexOf(item);

        //   if (index !== -1) {
        //     dynamicValidateForm.sights.splice(index, 1);
        //   }
        // };
        const productkey_count_remindinside = ref(0);
        const addremindinside = () => {
            formState.remindinside.push({
                productkey: productkey_count_remindinside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_remindinside.value++;
        };

        const productkey_count_remindoutside = ref(0);
        const addremindoutside = () => {
            formState.remindoutside.push({
                productkey: productkey_count_remindoutside.value.toString(),
                value: undefined,
                price: undefined,
                id: Date.now()
            });
            productkey_count_remindoutside.value++;
        };

        if (formState?.remindinside?.length == 0) {
            addremindinside();
        }
        if (formState?.remindoutside?.length == 0) {
            addremindoutside();
        }

        const onFinish = type => {
            try {
                if (type == "remindinside") {
                    formState.remindoutside[0].remindprice = null;
                    formState.remindoutside[0].remindintroduction = null;
                    formState.reminduser.selectcountry = {
                        selectcountry: "TH"
                    };
                }
                if (type == "remindoutside") {
                    formState.remindinside[0].remindprice = null;
                    formState.remindinside[0].remindintroduction = null;
                }
            } catch (error) {}

            console.log("formState.remindinside:", formState.remindinside);
            emit("update:value", {
                remindoutside: formState.remindoutside,
                remindinside: formState.remindinside
            });
            formItemContext.onFieldChange();
        };
        // const onNumberChange = (e) => {
        //   console.log("EE ", e.target.value);
        //   emit("update:value", { aa: e.target.value });
        //   formItemContext.onFieldChange();
        // };
        emit("update:value", {
            remindoutside: formState.remindoutside,
            remindinside: formState.remindinside
        });
        return {
            formRef,
            onFinish,
            removeremindinside,
            removeremindoutside,
            addremindinside,
            addremindoutside,
            areas,
            sights,
            is_paid_delivery,
            formState
        };
    }
});
</script>
<style lang="scss" scoped></style>
