<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop" @click="hide()"></div>
        <div class="modal-content">
            <div class="bg-black w-full rounded-xl p-3 text-center text-white bg-opacity-70">
                Loading...
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "LoadingModal",
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function() {
            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/modal.scss";
    .modal {
        @extend %modal;

        .modal-content {
            width: 200px;
        }
    }
</style>
