export function ProvinceModel(dict) {
    this.provinceCode = dict.province_code
        ? dict.province_code
        : dict.ProvinceCode;

    this.provinceName = dict.province_name
        ? dict.province_name
        : dict.ProvinceName;

    this.provinceNameEN = dict.ProvinceName_EN;
}

export function DistrictModel(dict) {
    this.districtCode = dict.district_code
        ? dict.district_code
        : dict.DistrictCode;

    this.districtName = dict.district_name
        ? dict.district_name
        : dict.DistrictName;

    this.districtNameEN = dict.district_name_EN
        ? dict.district_name_EN
        : dict.DistrictName_EN;

    this.provinceCode = dict.province_code
        ? dict.province_code
        : dict.ProvinceCode;
}

export function SubDistrictModel(dict) {
    this.subdistrictCode = dict.subdistrict_code
        ? dict.subdistrict_code
        : dict.SubDistrictCode;

    this.subdistrictName = dict.subdistrict_name
        ? dict.subdistrict_name
        : dict.SubDistrictName;

    this.provinceCode = dict.provinceCode
        ? dict.provinceCode
        : dict.ProvinceCode;

    this.districtCode = dict.districtCode
        ? dict.districtCode
        : dict.DistrictCode;

    this.subdistrictNameEN = dict.SubDistrictName_EN;
    this.city = dict.city;
    this.zipcode = dict.zip_code;
}

export function AddressModel() {
    this.address = '';
    this.provinceCode = '';
    this.provinceName = '';
    this.provinceNameEn = '';
    this.districtCode = '';
    this.districtName = '';
    this.districtNameEn = '';
    this.subDistrictCode = '';
    this.subDistrictName = '';
    this.subDistrictNameEn = '';
    this.zipcode = '';

    this.setAddressFromApi = function(data) {
        this.address = data.Address;
        this.provinceCode = data.ProvinceCode;
        this.provinceName = data.ProvinceName;
        this.provinceNameEn = data.ProvinceNameEN;
        this.districtCode = data.DistrictCode;
        this.districtName = data.DistrictName;
        this.districtNameEn = data.DistrictNameEN;
        this.subDistrictCode = data.SubDistrictCode;
        this.subDistrictName = data.SubDistrictName;
        this.subDistrictNameEn = data.SubDistrictNameEN;
        this.zipcode = data.Zipcode;
    },

    this.setAddressFromAddressListItem = function(addrItem) {
        this.address = addrItem.address;
        this.provinceCode = addrItem.provinceCode;
        this.provinceName = addrItem.provinceName;
        this.provinceNameEn = '';
        this.districtCode = addrItem.districtCode;
        this.districtName = addrItem.districtName;
        this.districtNameEn = '';
        this.subDistrictCode = addrItem.subDistrictCode;
        this.subDistrictName = addrItem.subDistrictName;
        this.subDistrictNameEn = '';
        this.zipcode = addrItem.zipcode;
    },

    this.getProvinceModel = function() {
        var province = new ProvinceModel({
            province_code: this.provinceCode,
            province_name: this.provinceName,
            ProvinceName_EN: this.provinceNameEn,
        });
        return province;
    };

    this.getDistrictModel = function () {
        var district = new DistrictModel({
            district_code: this.districtCode,
            district_name: this.districtName,
            district_name_EN: this.districtNameEn,
            province_code: this.provinceCode,
        });
        return district;
    };

    this.getSubDistrictModel = function() {
        var subDistrict = new SubDistrictModel({
            subdistrict_code: this.subDistrictCode,
            subdistrict_name: this.subDistrictName,
            SubDistrictName_EN: this.subDistrictNameEn,
            districtCode: this.districtNameEn,
            provinceCode: this.provinceName,
            zip_code: this.zipcode,
        });
        return subDistrict;
    };

    this.getObject = function() {
        return {
            address              :this.address,
            provinceCode         :this.provinceCode,
            provinceName         :this.provinceName,
            provinceNameEn       :this.provinceNameEn,
            districtCode         :this.districtCode,
            districtName         :this.districtName,
            districtNameEn       :this.districtNameEn,
            subDistrictCode      :this.subDistrictCode,
            subDistrictName      :this.subDistrictName,
            subDistrictNameEn    :this.subDistrictNameEn,
            zipcode              :this.zipcode,
        };
    };

    this.setValueFromAddressListModel = function(addressListModel) {
        this.provinceCode = addressListModel.provinceCode;
        this.provinceName = addressListModel.provinceName;
        this.districtCode = addressListModel.districtCode;
        this.districtName = addressListModel.districtName;
        this.subDistrictCode = addressListModel.subDistrictCode;
        this.subDistrictName = addressListModel.subDistrictName;
        this.zipcode = addressListModel.zipcode;
    };
}
