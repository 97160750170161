export const config = {
    appname: '',
    client_id: '', // App ID
    facebook_app_id: '', // Facebook App ID (Should be the same)
    debug: false, //enable console.log

    // ------------- Staging Settings ----------------
    api: 'stg',
    agency_id: '',
    bzbsUrlOldSupport: 'https://stg.buzzebees.com',
    bzbsUrl: 'https://stgservice.buzzebees.com',
    bzbsModuleUrl: 'https://stg-module-app.azurewebsites.net',
    apiShoppingCartUrl: 'https://stgservice.buzzebees.com/modules/shoppingcart',
    bzbsShoppingCartUrl: 'https://stg-web-shoppingcart.azurewebsites.net/',
    bzbsBlobUrl: 'https://stgstoragebuzzebees.blob.core.windows.net',
    miscUrl: 'https://stgmisc.buzzebees.com',
    bzbsGaId: '',
    liffId: '',
    // ------------------ End Settings ---------------------
    fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
    fbPermissions: [
        'email',
    ],

    firebase: {
        apiKey: "",
        authDomain: "",
        databaseURL: "",
        projectId: "",
        storageBucket: "",
        messagingSenderId: "",
        appId: "",
        measurementId: ""
    },
};
