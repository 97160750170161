<template>
    <!-- <pre>{{ dataSources }}</pre> -->

    <div class="p-3">
        <!-- <a-row class="pb-3">
            <a-col>
                <span class="p-3"> ชื่อสินค้า</span>
                <a-space>
                    <a-input
                        v-model:value="productnamesearch"
                        style="width:400px"
                    />
                </a-space>
                <span class="p-3"><i>(โปรดตรวจสอบกรอกชื่อให้ตรงกัน)</i></span>
                <span v-if="isfinish">
                    <span class="pr-2">
                        <a-spin size="small" v-if="isfinish" />
                    </span>
                    กำลังดึงข้อมูล...</span
                >
            </a-col>
        </a-row> -->
        <a-row class="pb-3">
            <a-col>
                <!-- <span class="p-3">
                    วันที่ ( สร้างรายการ )<span class="text-red"></span
                ></span> -->
                <a-space>
                    <!-- <a-range-picker v-model:value="daterang" /> -->

                    จากลำดับ
                    <a-input-number v-model:value="orderfrom" />
                    ถึง
                    <a-input-number v-model:value="orderto" />

                    <a-button class="ml-4 mr-4" @click="getinfo"
                        >ค้นหา</a-button
                    >
                    <a-button class="ml-2 mr-4" :loading="showloading"  @click="getreport"
                        >Export Report</a-button
                    >

                    <!-- <a-checkbox v-model:checked="checkedNormal"
                        >ยันต์เขียนสด</a-checkbox
                    >
                    <a-checkbox v-model:checked="checkedVIP"
                        >ยันต์ด่วน (VIP)</a-checkbox
                    >
                    <a-checkbox v-model:checked="checkedChangeSize"
                        >ยันต์เพิ่มขนาด</a-checkbox
                    > -->

                    <!-- <a-button type="primary" v-show="showloading" loading
                        >Loading</a-button
                    > -->
                </a-space>
            </a-col>
        </a-row>
        <a-row class="pb-3">
            <a-col>
                <span class="p-3">
                    ใส่วันที่สั่ง (ใน Excel)<span class="text-red"></span
                ></span>
                <a-space>
                    <a-date-picker
                        v-model:value="dateorder"
                        value-format="YYYY-MM-DD"
                    />
                </a-space>
            </a-col>
        </a-row>
    </div>

    <!-- <pre>{{dataSources}}</pre> -->
    <div class="relative p-4 card min-h-screen">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">
                <u>{{ title }}</u>
            </div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
        <!-- <pre>
            {{temp_show_dataSources}}
        </pre> -->
            <a-table
                size="middle"
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                :scroll="{ x: 0 }"
                :columns="columns"
                :data-source="temp_show_dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <pre>{{ record.ProductSellOrder }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'contactname'">
                        {{ record.CustomerOrder }}
                    </template>
                    <template v-if="column.dataIndex === 'productprice'">
                        <div v-if="record.Payment">
                            {{ JSON.parse(record.Payment)[0]?.Price }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductName'">
                        <!-- <p>{{ record.ProductName }}</p> -->
                        <div
                            v-for="(Item, idx) of record.Productdetail"
                            :key="`item-${idx}`"
                        >
                            {{ Item.HeartYanName}}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'OriginalPrice'">
                        <!-- <p>{{ record.ProductName }}</p> -->
                        <div
                            v-for="(Item, idx) of record.OriginalPrice"
                            :key="`item-${idx}`"
                        >
                            <div
                                v-for="(subItem, subidx) of Item"
                                :key="`item-${idx}`"
                            >
                                <div> 
                                    {{ subItem.OriginalPrice }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Productdetail'">
                        <font-awesome-icon
                            icon="fa-solid fa-user"
                            class="pr-2"
                        />
                        <!-- <div
                            v-for="(Item, idx) of record.Productdetail"
                            :key="`item-${idx}`"
                        >
                            {{ Item }}
                        </div> -->
                        <!-- <span v-if="checkedVIP">{{ record.HeartYanName }}</span>
                        <span v-else-if="checkedChangeSize">{{
                            record.HeartYanName
                        }}</span>
                        <span v-else>{{
                            record.ProductName || record.HeartYanName
                        }}</span> -->

                        <br />
                    </template>

                    <!-- if (element.Birthday) {
                    JSON.parse(element.Birthday)?.map((item) => {
                        if(element.ProductKey ==item.ProductKey){ 
                             
                        listbd.push(formatdateandname(item.Birthday, item.Name+' '+item.LastName))
                        }
                    })
                } -->
                    <template v-if="column.dataIndex === 'Birthday'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of getbirthday(
                                record.Birthday,
                                record.ProductKey
                            )"
                            :key="`item-${idx}`"
                        >
                            {{ Item }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'NameLastname'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of getnameofbirthday(
                                record.Birthday,
                                record.ProductKey
                            )"
                            :key="`item-${idx}`"
                        >
                            {{ Item }}
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'OrderDate'">
                        {{ formatdate(record.OrderDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Stauts'">
                        <div v-if="record.ProductStatus">
                            <a-tag
                                v-if="record.ProductStatus == 'canceltrack'"
                                color="#f50"
                                >*ยกเลิกแทรค</a-tag
                            >
                            <a-tag
                                v-if="record.ProductStatus == 'cancel'"
                                color="#f50"
                                >ยกเลิกสินค้า</a-tag
                            >
                        </div>

                        <div v-if="record.SystemCheckTabActive_1">
                            <a-tag color="#87d068">อัพเดทลงระบบแล้ว</a-tag>
                        </div>
                        <div v-else-if="!record.SystemCheckTabActive_1">
                            <a-tag color="#f50">อัพเดทลงระบบแล้ว</a-tag>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Checking'">
                        <div v-if="!record.Is_System_And_Payment">
                            <a-tag color="#f50">ยังไม่ได้สแกนชำระ</a-tag>
                        </div>
                        <div v-else-if="record.Is_System_And_Payment == 'paid'">
                            <a-tag color="#87d068">ชำระเงินแล้ว</a-tag>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && isfinish">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
            <!-- <div style="margin-bottom: 16px;padding: 10px ;">
                <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="start">
                    กดยอมชำระแล้ว
                </a-button>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                        {{ `เลือก ${selectedRowKeys.length} รายการ` }}
                    </template>
                </span>
            </div> -->
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import { usePagination } from "vue-request";
import dayjs from "dayjs";
import {
    computed,
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs,
    watch
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import Util from "@/helper/Utility";
import _ from "lodash";
import { Modal } from "ant-design-vue";
import { filter } from "rxjs/operators";
const dateFormat = "YYYY/MM/DD";
const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad
    },

    setup() {
        const productnamesearch = ref("");
        const orderfrom = ref(0);
        const orderto = ref(0);
        const isfinish = ref(false);

        const scanpickup = ref("");
        const showloading = ref(false);
        const checkedVIP = ref(false);
        const checkedNormal = ref(false);

        const checkedChangeSize = ref(false);
        const daterang = ref([]);
        const daterang_transfer_money = ref([]);
        const getCurrentDateFormatted = () => {
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const day = String(date.getDate()).padStart(2, "0");

            return `${year}/${month}/${day}`;
        };
        const dateorder = ref(dayjs(getCurrentDateFormatted(), "YYYY/MM/DD"));
        const searchInput = ref();
        const dataSources = ref(null);
        const temp_show_dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "10%"
            },
            {
                title: "ลำดับงาน",
                dataIndex: "OrderTransaction",
                width: "10%"
            },
            {
                title: "รหัสลูกค้า",
                dataIndex: "contactname",
                width: "10%"
            },
            // {
            //     title: "ชื่อสินค้า",
            //     dataIndex: "Productdetail"
            // },
            {
                title: "ชื่อสินค้า",
                dataIndex: "ProductName"
            },
            // {
            //     title: "วันเกิด",
            //     dataIndex: "BirthdayName"
            // },
            // {
            //     title: "วันที่เกิด",
            //     dataIndex: "Birthday"
            // },
            {
                title: "จำนวนเงิน",
                dataIndex: "OriginalPrice",
                width: "10%"
            }
            // {
            //     title: "สถานะ",
            //     dataIndex: "Stauts",
            // },
            // {
            //     title: "สถานะการเงิน",
            //     dataIndex: "Checking",
            // }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสินค้า",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            // dateorder.value = new Date();
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            } else {
                return "-";
            }
        };
        const formatTime = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("HH:mm:ss");
            } else {
                return "-";
            }
        };

        const formatdateandname = (date, name) => {
            if (moment(date).isValid()) {
                return (
                    moment(date)
                        .add(543, "year")
                        .format("L") +
                    " " +
                    name
                );
            } else {
                return "-";
            }
        };

        const formatdatebd = (date, TypeBirthday) => {
            if (moment(date).isValid()) {
                if (TypeBirthday == "2") {
                    return moment(date)
                        .add(543, "year")
                        .format("L");
                } else if (TypeBirthday == "3") {
                    return moment(date).format("L");
                } else {
                    if (moment(date).isValid()) {
                        return moment(date)
                            .add(543, "year")
                            .format("L");
                    } else {
                        return "-";
                    }
                }
            } else {
                return "-";
            }
        };

        watch(
            () => checkedVIP.value,
            () => {
                if (checkedVIP.value) isfinish.value = true;
                querycustomer();
            }
        );
        watch(
            () => checkedNormal.value,
            () => {
                if (checkedNormal.value) isfinish.value = true;
                querycustomer();
            }
        );
        watch(
            () => checkedChangeSize.value,
            () => {
                if (checkedChangeSize.value) isfinish.value = true;
                querycustomer();
            }
        );

        const convertDate = inputDate => {
            const date = new Date(inputDate);

            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        };
        const querycustomer = () => {
            dataSources.value = [];
            var params = {
                min: orderfrom.value,
                max: orderto.value
            };
            return BzbsUser.apiproductreport(params)
                .then(res => {
                    isfinish.value = false;
                    dataSources.value = res.data.map(item => {
                        return {
                            ...item,
                            Birthday: convertDate(item.Birthday) // Convert the Birthday field
                        };
                    });

                    console.log("dataSources.value ", dataSources.value);
                    temp_show_dataSources.value = Object.groupBy(
                        dataSources.value,
                        ({ OrderTransaction }) => OrderTransaction
                    );

                    console.log(
                        "productreport master : ",
                        temp_show_dataSources.value
                    );
                    temp_show_dataSources.value = Object.keys(
                        temp_show_dataSources.value
                    ).map(key => ({
                        OrderTransaction: key,
                        CustomerOrder:
                            temp_show_dataSources.value[key][0].CustomerOrder,
                        Productdetail: temp_show_dataSources.value[key],
                        ProductName: Object.groupBy(
                            temp_show_dataSources.value[key],
                            ({ HeartYanName }) => HeartYanName
                        ),
                        contactname:
                            temp_show_dataSources.value[key][0].contactname,
                        OriginalPrice: Object.groupBy(
                            temp_show_dataSources.value[key],
                            ({ HeartYanName }) => HeartYanName
                        ) 
                    }));

                    console.log(
                        " tem_show_dataSources2 : ",
                        temp_show_dataSources.value
                    );
                    // tem_show_dataSources.value   = tem_show_dataSources.value.map(item=>{
                    //     OrderTransaction:item.key,
                    //     Productdetail:tem_show_dataSources.value[item.key],
                    //     // contactname Productdetail BirthdayName Birthday OriginalPrice

                    // })
                    // dataSources.value = res.data.filter(
                    //     item => item.Is_System_And_Payment == null
                    // );

                    // if (orderfrom.value && orderto.value) {
                    //     dataSources.value = dataSources.value.filter(
                    //         item =>
                    //             item.OrderTransaction &&
                    //             parseInt(item.OrderTransaction) >=
                    //                 orderfrom.value &&
                    //             parseInt(item.OrderTransaction) <= orderto.value
                    //     );
                    // }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getRangeForCurrentMonth = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();

            // Get the first day of the current month
            const firstDayOfMonth = new Date(year - 10, month, 1);

            // Get the last day of the current month
            const lastDayOfMonth = new Date(year, month + 1, 0);

            return {
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth
            };
        };
        const setformat = datevalue => {
            const currentDate = new Date(datevalue);
            const year = currentDate.getFullYear() + 543;
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because it's zero-based
            const day = String(currentDate.getDate()).padStart(2, "0");

            return `${day}/${month}/${year}`;
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: "",
            searchedColumn: "",
            selectedRowKeys: [],
            loading: false
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };

        const getinfo = () => {
            dataSources.value = [];
            if (productnamesearch.value) {
                isfinish.value = true;
                querycustomer();
            } else {
                querycustomer();
            }
        };

        const getreport = () => {
            showloading.value = true;
            querygetreport();
        };

        const querygetreport = () => {
            var exportreport = {
                dateorder: setformat(dateorder.value),
                recordset: dataSources.value
            };

            BzbsUser.apigetexcelproduct(exportreport)
                .then(response => {
                    showloading.value = false;
                    window.open(
                        "https://namahayan.blob.core.windows.net/filecreate/outputexcelproductyan.xlsx"
                    );
                })
                .catch(error => {
                    showloading.value = false;
                    console.log(error);
                });
        };

        const getshotday = day => {
            const exp = [
                "จันทร์",
                "อังคาร",
                "พุธ",
                "พฤหัสบดี",
                "ศุกร์",
                "เสาร์",
                "อาทิตย์"
            ];
            const daysOfWeekAbbreviated = ["จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"];

            const searchText = day;

            let index = -1; // Initialize the index to -1 (not found)

            for (let i = 0; i < exp.length; i++) {
                if (searchText.includes(exp[i])) {
                    index = i; // Set the index when a partial match is found
                    break; // Exit the loop once a match is found
                }
            }

            if (index !== -1) {
                return `(${daysOfWeekAbbreviated[index]})`;
            } else {
                return "";
            }
        };
        const onEnter = () => {
            showConfirm("ต้องการสแกนจ่ายแล้ว");
        };

        const truncateString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            } else {
                return str.substring(0, maxLength) + "...";
            }
        };
        const showConfirm = title => {
            Modal.confirm({
                title: title,
                onOk() {
                    apiupdatereportstatus();
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test"
            });
        };

        const apiupdatereportstatus = () => {
            var param = {
                ProductCode: scanpickup.value,
                StatusReport: "paid"
            };

            BzbsUser.updatestatusreportissystemandpayment(param)
                .then(response => {
                    success();
                    querycustomer();
                })
                .catch(error => {
                    showloading.value = false;
                    console.log(error);
                });
        };
        const success = () => {
            Modal.success({
                title: "สำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };

        const hasSelected = computed(() => state.selectedRowKeys.length > 0);
        const start = () => {
            state.loading = true;
            // ajax request after empty completing
            setTimeout(() => {
                state.loading = false;
                state.selectedRowKeys = [];
                updateSystemyandate();
            }, 1000);
        };

        const listselected = ref([]);
        const onSelectChange = selectedRowKeys => {
            console.log("selectedRowKeys changed: ", selectedRowKeys);
            state.selectedRowKeys = selectedRowKeys;
            listselected.value = selectedRowKeys;
        };

        const updateSystemyandate = async () => {
            var list = [];

            console.log("listselected", listselected.value);
            listselected.value.forEach(element => {
                var found = dataSources.value.filter(
                    item => item.key == element
                );
                list.push(found);
            });

            console.log("LIST : ", list);
            for await (let item of list) {
                // var CheckMessageParam = [{
                //     tab: "1",
                //     checking: 'checked',
                //     message: 'ผ่าน(oneclick)',
                //     updatedate: Date.now().toString(),
                // }]
                for await (let detail of item) {
                    // var param = {
                    //     "Tab": "1",
                    //     "ProductSellOrder": detail.ProductSellOrder,
                    //     "CustomerOrder": detail.CustomerOrder,
                    //     "ProductKey": detail.ProductKey,
                    //     "SystemCheckTabActive": JSON.stringify(CheckMessageParam)
                    // }
                    // await BzbsUser.updatesystemchecktabactive(param)
                    //     .then(res => {

                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });

                    var param = {
                        ProductCode: detail.ProductCode,
                        StatusReport: "paid"
                    };

                    BzbsUser.updatestatusreportissystemandpayment(param)
                        .then(response => {
                            // success()
                            querycustomer();
                        })
                        .catch(error => {
                            showloading.value = false;
                            console.log(error);
                        });
                }
            }
            await querycustomer();
            // console.log("param : ", param)
        };

        const getbirthday = (Birthday, ProductKey) => {
            return Util.jsonParse(Birthday)?.map(item => {
                if (ProductKey == item.ProductKey) {
                    return formatdate(item.Birthday);
                }
            });
        };
        const getnameofbirthday = (Birthday, ProductKey) => {
            return Util.jsonParse(Birthday)?.map(item => {
                if (ProductKey == item.ProductKey) {
                    return item.Name + " " + item.LastName;
                }
            });
        };
        return {
            getbirthday,
            getnameofbirthday,
            productnamesearch,
            start,
            onSelectChange,
            scanpickup,
            onEnter,
            getinfo,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            daterang,
            getreport,
            showloading,
            scanpickup,
            checkedVIP,
            checkedNormal,
            checkedChangeSize,
            hasSelected,
            orderfrom,
            orderto,
            isfinish,
            daterang_transfer_money,
            dateFormat,
            dateorder,
            temp_show_dataSources
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
