<template>
    <div class="relative">
        <a-form
            :model="formState"
            name="basic"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 24 }"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
        >
            <div id="my-detail" class="main-content">
                <div class="p-5 mb-5 card">
                    <div>
                        <a-button type="link" @click="clickblack()">
                            <left-outlined />ย้อนกลับ(1.1)
                        </a-button>
                    </div>
                    <a-divider class="mt-5" orientation="left"
                        >ตรวจสอบค่าส่งเพิ่ม</a-divider
                    >
                    <a-row type="flex">
                        <a-col flex="1" :order="1">
                            <MoreDeliveryDetailAccountChecking
                                :textbtn="'เพิ่มสินค้า'"
                                :formStates="formState"
                                v-model:value="formState.listproduct"
                            >
                            </MoreDeliveryDetailAccountChecking>
                        </a-col>
                    </a-row>
                    <div class="pb-4">
                        <a-row>
                            <a-col :span="24">
                                <hr class="mt-3 mb-3" />
                                <u>ประวัติ</u>
                                <div
                                    v-for="(item, idx) of CheckMessage"
                                    :key="`v-list-item-${idx}`"
                                >
                                    <a-row>
                                        <a-col :span="4">
                                            สถานะ:
                                            <a-typography-text
                                                v-if="item.checking == 'reject'"
                                                type="danger"
                                                strong
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-xmark"
                                                />
                                                ไม่ผ่าน</a-typography-text
                                            >
                                            <a-typography-text
                                                v-else-if="
                                                    item.checking == 'other'
                                                "
                                                type="warning"
                                                strong
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-xmark"
                                                />
                                                ยังระบุไม่ได้</a-typography-text
                                            >
                                            <a-typography-text
                                                type="success"
                                                strong
                                                v-else
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-check"
                                                />
                                                ผ่าน</a-typography-text
                                            >
                                        </a-col>
                                        <a-col :span="8">
                                            รายละเอียด:
                                            <a-typography-text
                                                v-if="item.checking == 'reject'"
                                                type="danger"
                                                strong
                                            >
                                                {{
                                                    item.message || "-"
                                                }}</a-typography-text
                                            >
                                            <a-typography-text
                                                v-else-if="
                                                    item.checking == 'other'
                                                "
                                                type="warning"
                                                strong
                                            >
                                                {{
                                                    item.message || "-"
                                                }}</a-typography-text
                                            >
                                            <a-typography-text
                                                type="success"
                                                strong
                                                v-else
                                            >
                                                {{
                                                    item.message || "-"
                                                }}</a-typography-text
                                            >
                                        </a-col>
                                        <a-col :span="8">
                                            <div>
                                                วันที่:
                                                {{
                                                    formatdate(item.updatedate)
                                                }}
                                            </div>
                                        </a-col>
                                    </a-row>
                                </div>
                            </a-col>
                            <a-col :span="12"></a-col>
                        </a-row>

                        <div
                            style="float: right"
                            v-if="!Storedata.methods.checkgrantccountonly()"
                        >
                            <a-radio-group v-model:value="checking">
                                <a-radio :style="radioStyle" :value="'checked'"
                                    >ผ่าน</a-radio
                                >
                                <a-radio :style="radioStyle" :value="'reject'"
                                    >ไม่ผ่าน</a-radio
                                >
                                <a-radio :style="radioStyle" :value="'other'"
                                    >ยังระบุไม่ได้</a-radio
                                >
                            </a-radio-group>
                            <a-popconfirm
                                title="ต้องการอัพเดทตรวจสอบการเงิน?"
                                @confirm="confirm"
                                @cancel="cancel"
                            >
                                <a-button type="primary"
                                    >อัพเดทตรวจสอบการเงิน</a-button
                                >
                            </a-popconfirm>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <a-button v-show="Storedata.methods.checkgrantbtnsave()" style="float: right" type="primary" html-type="submit"
                :disabled="!isfinistinit">บันทึก
            </a-button> -->
        </a-form>
        <a-modal
            v-model:visible="visiblereject"
            title="กรอกรายละเอียด"
            @ok="handleOkreject"
        >
            <a-textarea
                v-model:value="descmessage"
                placeholder=""
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
        </a-modal>
    </div>
</template>
<script>
import {
    defineComponent,
    reactive,
    onMounted,
    ref,
    computed,
    watch,
    toRef
} from "vue";
import ProductItems from "../component/ProductItems.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import axios from "axios";
import Storedata from "@/store/Storedata.js";
import { Modal } from "ant-design-vue";
// import Uploadfile from "../component/form/Uploadfile.vue";
import Address from "../component/Address.vue";
import "moment/locale/th"; // without this line it didn't work

// import Inputnumber from "@/module/Sell/SellCreate/component/form/Inputnumber.vue";
import {
    UserOutlined,
    BarcodeOutlined,
    PushpinOutlined,
    // NumberOutlined,
    SisternodeOutlined,
    DollarOutlined,
    LeftOutlined,
    FilePdfOutlined
} from "@ant-design/icons-vue";

// import SelectOption from "@/module/Sell/SellCreate/component/form/SelectOption.vue";
import ListCreateproduct from "@/module/Sell/SellCreate/component/form/ListCreateproduct.vue";
import AddressFrom from "@/module/CustomerOrder/SellCreate/component/form/AddressFrom.vue";
import PaidDeliveryMore from "@/module/Sell/SellCreate/component/form/PaidDeliveryMore.vue";

import Pay from "@/module/CustomerOrder/SellCreate/component/form/Pay.vue";
import ProductFrom from "./form/ProductFrom.vue";
import CreateCustomerfrom from "./form/CreateCustomerfrom.vue";
// import Baseinput from "./form/Baseinput.vue";
import _, { forEach } from "lodash";
import Contact from "./form/Contact.vue";
import {
    ContactModel,
    GetContactModel,
    CustomerModel,
    ProductBirthday,
    ProductModel,
    ProductDeliveryAddressModel,
    PaymentModel,
    GetProductBirthday
} from "../../../../core/Create/model/CreateModel";
import { notification } from "ant-design-vue";
import { formStateStore } from "../../../../store/storebackoffice";
import MoreDeliveryDetailAccountChecking from "@/module/Delivery/component/MoreDeliveryDetailAccountChecking.vue";
import Util from "@/helper/Utility";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Account from "@/helper/AccountHelper.js";
import Utility from "@/helper/Utility";
import { mapMutations } from "vuex";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
const { parentMethod } = mapMutations(["parentMethod"]);
// import ProductListByCustomerCode from "@/module/CustomerOrder/SellCreate/component/form/ProductListByCustomerCode.vue";
export default defineComponent({
    name: "FromCreate",
    provide: {
        Storedata: Storedata
    },
    props: {
        rowselected: null,
        isshowDetailSellOrder: null
    },
    components: {
        FilePdfOutlined,
        LeftOutlined,
        Contact,
        ProductItems,
        // Uploadfile,
        Address,
        // Inputnumber,
        UserOutlined,
        // SelectOption,
        ListCreateproduct,
        AddressFrom,
        PaidDeliveryMore,
        Pay,
        BarcodeOutlined,
        PushpinOutlined,
        // NumberOutlined,
        SisternodeOutlined,
        DollarOutlined,
        ProductFrom,
        CreateCustomerfrom,
        // ProductListByCustomerCode
        // Baseinput,
        MoreDeliveryDetailAccountChecking
    },
    methods: {
        // download() {

        //     try {
        //         this.$refs.vue3SimpleHtml2pdf.download();
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
        show_hide_column(col_no, do_show, iszoom) {
            const table = document.getElementById("id_of_table");
            if (table) {
                const column = table.getElementsByTagName("col")[col_no];
                const column_td = table.getElementsByTagName("td")[col_no];
                if (column) {
                    column.style.visibility = do_show ? "" : "collapse";
                }
                if (column_td) {
                    column_td.style.visibility = do_show ? "" : "collapse";
                }
            }

            if (iszoom) {
                const thead = document.querySelectorAll(".ant-table-thead");

                for (const box of thead) {
                    box.classList.add("ant-table-thead-zoom");
                }

                const cell = document.querySelectorAll(".ant-table-cell");

                for (const box of cell) {
                    box.classList.add("ant-table-cell-zoom");
                }
                const text = document.querySelectorAll(
                    ".ant-divider-inner-text"
                );

                for (const box of text) {
                    box.classList.add("ant-table-cell-zoom");
                }
                const form_item = document.querySelectorAll(".ant-form-item");

                for (const box of form_item) {
                    box.classList.add("ant-table-cell-zoom");
                }

                const required = document.querySelectorAll(
                    ".ant-form-item-required"
                );

                for (const box of required) {
                    box.classList.add("ant-table-cell-zoom");
                }

                const content = document.querySelectorAll(
                    ".ant-form-item-control-input-content"
                );

                for (const box of content) {
                    box.classList.add("ant-table-cell-zoom");
                }

                const operations = document.querySelectorAll(
                    ".editable-row-operations"
                );

                for (const box of operations) {
                    box.style.display = do_show ? "" : "none";
                }
            }

            const scanproduct = document.getElementById("scanproduct");
            const addproduct_id = document.getElementById("addproduct_id");
            const edit_contact_id = document.getElementById("edit_contact_id");
            const contact_component_id = document.getElementById(
                "contact_component_id"
            );
            const title_customer_id = document.getElementById(
                "title_customer_id"
            );
            const title_address_id = document.getElementById(
                "title_address_id"
            );

            title_address_id.style.display = do_show ? "" : "none";
            title_customer_id.style.display = do_show ? "" : "none";
            contact_component_id.style.display = do_show ? "" : "none";
            edit_contact_id.style.display = do_show ? "" : "none";
            addproduct_id.style.display = do_show ? "" : "none";
            scanproduct.style.display = do_show ? "" : "none";

            //icon
            const user_id = document.getElementById("user_id");
            user_id.style.display = do_show ? "" : "none";

            const title_report_green = document.getElementById(
                "title_report_green"
            );
            title_report_green.style.display = do_show ? "none" : "";
            //icon
        },
        downloadStockNAW() {
            this.reportstocknaw();
            return;
        },
        download() {
            this.reportsetting();
            return;

            Storedata.state.loading.percent += 50;
            Storedata.state.loading.ishow = true;
            this.isclickdownload = true;
            this.show_hide_column(1, false, true);
            // this.show_hide_column(8, false)

            const element = document.querySelector("#my-detail");

            html2canvas(element).then(canvas => {
                const classcontent = document.querySelectorAll(
                    ".ant-table-cell-zoom"
                );
                for (const box of classcontent) {
                    box.className = "";
                }
                classcontent.className = "";

                const thead = document.querySelectorAll(
                    ".ant-table-thead-zoom"
                );
                for (const box of thead) {
                    box.classList.remove("ant-table-thead-zoom");
                }

                // this.show_hide_column(8, true)
                const imgWidth = 203;
                const pageHeight = 295;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                var heightLeft = imgHeight;
                const contentDataURL = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "mm", "a4"); // A4 size page of PDF
                var base64String =
                    "https://www.namahayan.com/_nuxt/NAMAHAYAN.e3c1cff0.webp";
                pdf.addImage(base64String, "PNG", 70, 5, 60, 12);
                var position = 18;
                pdf.addImage(
                    contentDataURL,
                    "PNG",
                    5,
                    position,
                    imgWidth,
                    imgHeight
                );
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position += heightLeft - imgHeight; // top padding for other pages
                    pdf.addPage();
                    pdf.addImage(
                        contentDataURL,
                        "PNG",
                        5,
                        position,
                        imgWidth,
                        imgHeight
                    );
                    heightLeft -= pageHeight;
                }
                pdf.save("ใบรายการขาย.pdf"); // Generated PDF
                this.show_hide_column(1, true, false);
                Storedata.state.loading.percent += 100;
                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                }, 1500);
            });
        }
    },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        Util.blockback();
        const visiblereject = ref(false);
        const checking = ref("checked");
        const CheckMessage = ref([]);
        const descmessage = ref("");
        const isfinistinit = ref(false);
        const rowselecteddetail = reactive({
            rowselected: route.query.customercode
        });
        const sellorder = ref(""); //ref('SO-20230811164257')
        const title = "ลูกค้า";
        const producttype = ref([]);
        // const gender = ref("1");
        const typelocal = ref("1");
        const update = ref(0);
        const orderyan = ref("");

        const age = ref(18);
        moment.locale("th");
        const datecreatetoday = ref("");
        const layout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 12
            }
        };
        const validateMessages = {
            required: "${label} is required!",
            types: {
                email: "${label} is not a valid email!",
                number: "${label} is not a valid number!"
            },
            number: {
                range: "${label} must be between ${min} and ${max}"
            }
        };

        const calculateAge = birthday => {
            if (birthday == null) return (formState.user.customerage = 0);
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            formState.user.customerage = Math.abs(
                ageDate.getUTCFullYear() - 1970
            );
        };

        const calculateAge_setting = birthday => {
            // birthday is a date
            var ageDifMs = Date.now() - new Date(birthday).getTime();
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        const onModalAddressChagne = values => {
            console.log(values);
        };
        const onselectchange = value => {
            formState.user.customername =
                value[0].item.CustomerName +
                " " +
                value[0].item.CustomerLastName;
            formState.user.customertel = value[0].item.CustomerContractNumber;
            formState.user.customeremail = value[0].item.CustomerEmail;
            formState.user.lineid = value[0].item.lineid;
            formState.user.customerAddress = value[0].item.CustomerAddress;
            formState.user.customerbirthday = value[0].item.CustomerBirthDay;
            formState.user.customerintroduction = value[0].item.CustomerDesc;
            formState.user.customerage = calculateAge_setting(
                value[0].item.CustomerBirthDay
            );
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });
        const formState = reactive(formStateStore);
     
        formState.user.customercode = rowselecteddetail.rowselected;
        formState.user.sellordertransaction = sellorder;

        // const formState = reactive({
        //     updatecomponent: 0,
        //     isshowjason: true,
        //     inside: [],
        //     outside: [],
        //     area: undefined,
        //     userproduct: {
        //         queyan: "1",
        //         professortype: [],
        //         inside: [],
        //         outside: []
        //     },
        //     listproduct: [],
        //     pay: {},
        //     getcontactlist: [],
        //     user: {
        //         customercode: rowselecteddetail.rowselected,
        //         addressfrom: [],
        //         contactList: [],
        //         customerproductstype: "1",
        //         selectcountry: { selectcountry: "TH" },
        //         sellordertransaction: props.isshowDetailSellOrder.sellorder,
        //         customeraddressType: "1"
        //     },
        //     useraddress: {
        //         key: 0,
        //         typeaddress: "1",
        //         selectcountry: "TH"
        //     },
        //     listaddress: []
        // });

        watch(
            () => Storedata.state.startcallsave,
            () => {
                onFinish();
            },
            () => formState.user.customeraddressType,
            () => {
                if (formState.user.customeraddressType == "1") {
                    formState.user.selectcountry.selectcountry = "TH";
                }
            },
            () => formState.user.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    )
                ) {
                    formState.user.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.user.selectcountry.selectcountry
                    );
                }
            },
            () => formState.listproduct,
            () => {
                orderyan.value = formState?.listproduct[0]?.OrderTransaction;
            }
        );
        watch(
            () => formState.userproduct.customeraddressType,
            () => {
                if (formState.userproduct.customeraddressType == "1") {
                    formState.userproduct.selectcountry.selectcountry = "TH";
                }
            },
            () => formState.userproduct.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.userproductser.selectcountry.selectcountry
                    )
                ) {
                    formState.userproduct.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.userproduct.selectcountry.selectcountry
                    );
                }
            },
            () => formState.listproduct,
            () => {
                orderyan.value = formState?.listproduct[0]?.OrderTransaction;
            }
        );
        setTimeout(() => {
            // formState.user.orderdate = moment().format("YYYY-MM-DD");
            datecreatetoday.value = moment(formState.user.orderdate)
                .add(543, "year")
                .format("LL");
        }, 100);
        onMounted(() => {
            gencodecustomer();
            // getproductbyid();
            // getproductlist();
        });
        const SellOrderTransaction = () => {
            BzbsUser.apiSellOrderTransaction(null)
                .then(res => {
                    // formState.user.customercode =
                    //   "C-" + moment(new Date()).format("YYYYMMDDHHmmss");
                    return (formState.user.sellorderTransaction =
                        res.data[0].SellOrderTransactionID);
                })

                .catch(error => {
                    console.log(error);
                });
        };

        const getproductbyid = () => {
            BzbsUser.apigetproductbyid(props.isshowDetailSellOrder.sellorder)
                .then(res => {
                    console.log("getproductbyid", res.data[0]);
                })

                .catch(error => {
                    console.log(error);
                });
        };
        const getproductlist = () => {
            BzbsUser.apigetproductlist(formState.user.customercode)
                .then(res => {
                    // formState.userproduct.trackyan = res.data[0].TrackYan
                    // formState.userproduct.queyan = res.data[0].Queyan
                    // formState.userproduct.queyanimage = res.data[0].QueYanImage
                    // console.log("99productlist TrackYan", res.data[0].TrackYan);
                    // console.log("99productlist Queyan", res.data[0].Queyan);
                    // console.log("99productlist QueYanImage", res.data[0].QueYanImage);
                    // console.log("99productlist", res.data[0]);
                    console.log("99productlist", res.data[0]);
                })

                .catch(error => {
                    console.log(error);
                });
        };
        const imageslip = imglist => {
            if (imglist) {
                formState.user.fileList = imglist;
            }
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const onFinishFailed = errorInfo => {
            console.log("Failed:", errorInfo);
        };

        const options = ref([]);
        const handleChange = value => {
            onselectchange(options.value.filter(item => item.value == value));
        };

        const handleBlur = () => {
            console.log("blur");
        };

        const handleFocus = () => {
            console.log("focus");
        };

        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const queryProductType = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/producttype").then(res => {
                res?.data[0]?.map(item => {
                    let obj = {};
                    obj.label = item.ProductName;
                    obj.value = item.ProductName;
                    producttype.value.push(obj);
                });
            });
        };

        const getCustomer = async customercode => {
            await BzbsUser.apiGetCustomercustomercode(customercode)
                .then(res => {
                    if (res.data[0]) {
                        // return initfil(res.data[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            formState.user.ProvinceName = values.provinceName;
            formState.user.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            formState.user.BorderName = values.districtsName;
            formState.user.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            formState.user.DistrictID = values.subdistrictsID;
            formState.user.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            formState.user.PostCode = values;
        };

        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const initcontact = async CustomerCode => {
            BzbsUser.apicontact(CustomerCode)
                .then(res => {
                    const temp_obj = [];
                    res.data?.forEach(element => {
                        element.sellorderTransaction =
                            formState.user.sellorderTransaction;
                        element.customercode = formState.user.customercode;
                        var obj = new GetContactModel(element);

                        temp_obj.push(obj);
                    });

                    formState.getcontactlist = temp_obj;
                    formState.user.contactList = temp_obj;
                    console.log(
                        "formState.user.contactlist :",
                        formState.getcontactlist
                    );
                    formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const initproductbirthday = async CustomerCode => {
            BzbsUser.apigetproductbirthday(CustomerCode)
                .then(res => {
                    const temp_obj = [];
                    res.data.forEach(element => {
                        var obj = new GetProductBirthday(element);

                        temp_obj.push(obj);
                    });

                    formStateStore.listbirstname = temp_obj;

                    // formState.getcontactlist = temp_obj;
                    // formState.user.contactList = temp_obj;
                    // console.log(
                    //     "formState.user.contactlist :",
                    //     formState.getcontactlist
                    // );
                    // formState.updatecomponent++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const gencodecustomer = async () => {
              
            var e = {};
            if (rowselecteddetail.rowselected != undefined) {
                await initcontact(rowselecteddetail.rowselected);
                // await initproductbirthday(rowselecteddetail.rowselected);
                await getCustomer(rowselecteddetail.rowselected);
                setTimeout(() => {
                    isfinistinit.value = true;
                }, 1000);
            }
            if (e == "") {
                return;
            }
        };
        const initfil = async e => {
            formState.user.contact = e.Contact;
            formState.user.customercode = e.CustomerCode;
            formState.user.customername = e.CustomerName;
            formState.user.customerlastname = e.CustomerLastName;
            formState.user.customerphone = e.CustomerContractNumber;
            formState.user.customerbirthday = e.CustomerBirthDay;
            formState.user.customeremail = e.CustomerEmail;
            formState.user.addressdesc = e.CustomerAddress;
            formState.user.introduction = e.CustomerDesc;
            formState.user.ProvinceID = e.ProvinceID;
            formState.user.ProvinceName = e.ProvinceName;
            formState.user.BorderID = e.BorderID;
            formState.user.BorderName = e.BorderName;
            formState.user.BorderID = e.DistrictId;
            formState.user.DistrictID = e.DistrictID;
            formState.user.DistrictName = e.DistrictName;
            formState.user.PostCode = e.PostCode;
            formState.user.customerlineid = e.LineID;
            formState.user.customerfacebook = e.Facebook;
            formState.user.contactname = e.ContactName;
            formState.user.contact = e.Contact;
            formState.user.customergender =
                e.CustomerGender != undefined
                    ? e.CustomerGender.replace(/\s/g, "")
                    : "notspecified";
            formState.user.customernickname = e.CustomerNickName;
            formState.user.customerbirthdaytype = e.CustomerBirthDayType;
            formState.user.customercareer = e.CustomerCareer;
            formState.user.customeraddressType = e.CustomerAddressType;
            formState.user.selectcountry.selectcountry = e.SelectCountry;
            formState.user.customerphoneemergency = e.CustomerPhoneEmergency;
            formState.user.customerphonethai = e.CustomerPhoneThai;
            formState.user.contactimageurl = e.ContactImageUrl;

            update.value = update.value + 1;
            console.log(e);
        };
        // const clearfil = e => {
        //     formState.user.contact = "";
        //     formState.user.customercode = e.CustomerCode;
        //     formState.user.customername = "";
        //     formState.user.customerlastname = "";
        //     formState.user.customerphone = "";
        //     formState.user.customerbirthday = "";
        //     formState.user.customeremail = "";
        //     formState.user.addressdesc = "";
        //     formState.user.introduction = "";
        //     formState.user.ProvinceID = "";
        //     formState.user.ProvinceName = "";
        //     formState.user.BorderID = "";
        //     formState.user.BorderName = "";
        //     formState.user.BorderID = "";
        //     formState.user.DistrictName = "";
        //     formState.user.PostCode = "";
        //     formState.user.customerlineid = "";
        //     formState.user.customerfacebook = "";
        //     formState.user.contactname = "";
        //     formState.user.contact = "";
        //     // ImageURL: "",
        //     formState.user.customergender = "notspecified";
        //     formState.user.customernickname = "";
        //     formState.user.customerbirthdaytype = "";
        //     formState.user.customercareer = "";
        //     formState.user.customeraddressType = "1";
        //     formState.user.customerphoneemergency = "";
        //     formState.user.customerphonethai = "";
        //     formState.user.selectcountry.selectcountry = "";
        //     update.value = update.value + 1;
        // };

        const onFinish = async values => {
            // progress.value = true;
            insertdatabase();

            parentMethod();
            console.log("DATA >> ", values);
            console.log("Success:", values);
        };

        const warning = message => {
            notification["warning"]({
                message: message
            });
        };
        const checkcondition = async () => {
            console.log("checkcondition", formState.user.contactList);
            const result = formState.user.contactList.some(
                item => item.contactstartdefault == "true"
            );

            if (result) {
                return true;
            } else {
                warning("กรุณาตั้งค่าเริ่มต้นช่องทางการติดต่อ");
                return false;
            }
        };

        const insertdatabase = async () => {
            if (checkcondition()) {
                Storedata.state.loading.ishow = true;
                await insertcontract();
                Storedata.state.loading.percent += 12.5;
                await insertcustomer();
                Storedata.state.loading.percent += 12.5;
                await insertcustomeruserproduct();
                Storedata.state.loading.percent += 12.5;
                await insertdeliveryinside();
                Storedata.state.loading.percent += 12.5;
                await insertdeliveryinsidecod();
                Storedata.state.loading.percent += 12.5;
                await insertdeliveryoutside();
                Storedata.state.loading.percent += 12.5;
                await insertdeliveryinsideremind();
                Storedata.state.loading.percent += 12.5;
                await insertdeliveryoutsideremind();
                Storedata.state.loading.percent += 12.5;
                await insertproduct();
                Storedata.state.loading.percent += 12.5;
                await insertproductdeliveryaddress();
                Storedata.state.loading.percent += 12.5;
                await insertpayment();
                Storedata.state.loading.percent += 12.5;
                await insertproductbirthday();
                Storedata.state.loading.percent += 12.5;
                // await this.insertcreatecustomer();
                // await this.insertcreateproduct();
                // await this.insertcreatedeliveryaddress();
                // await this.insertcreatepayment();
                setTimeout(() => {
                    Storedata.state.loading.ishow = false;
                    update.value = update.value + 1;
                    success();
                }, 2000);
            }
        };
        const insertcontract = async () => {
            let params = [];
            await formState.user.contactList.forEach((element, index) => {
                element.productsellorder = formState.user.sellorderTransaction;
                element.customerorder = formState.user.customercode;
                element.key = (index + 1).toString();

                var obj = new ContactModel(element);
                console.log("obj", obj);
                params.push(obj);
            });
            console.log("param", params);

            // let slip_url = [];
            var index_uploadimage = 0;
            for await (let items of params) {
                if (items.ContactImage[0]?.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename:
                            formState.user.customercode +
                            "_" +
                            index_uploadimage +
                            ".jpg",
                        base64: items.ContactImage[0].preview,
                        folder: "contact"
                    };
                    await BzbsUser.apiuploadimage(uploadparam)
                        .then(res => {
                            params[index_uploadimage]["ContactImage"] = "";
                            params[index_uploadimage]["ContactImageUrl"] = "";
                            params[index_uploadimage]["ContactImage"] =
                                uploadparam.filename;
                            params[index_uploadimage]["ContactImageUrl"] =
                                res.data.url;
                            console.log(res.data);
                            index_uploadimage++;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    index_uploadimage++;
                }
            }

            for await (let obj of formState.user.contactList) {
                var param = {
                    Contactorder: obj.contactorder,
                    ProductSellOrder: obj.productsellOrder,
                    CustomerOrder: obj.customerorder,
                    Introduction: obj.introduction,
                    Key: obj.key,
                    ContactType: obj.contacttype,
                    ContactName: obj.contactname,
                    ContactStartDefault: obj.contactstartdefault,
                    // ContactImageUrl: obj.contactImageUrl,
                    // ContactImage: obj.contactImage,
                    info1: obj.info1,
                    info2: obj.info2,
                    info3: obj.info3,
                    info4: obj.info4
                };

                await BzbsUser.apiupdatecontact(param)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertcustomer = async () => {
     
            let params = new CustomerModel(formState.user);

            console.log("param", params);

            await BzbsUser.apiUpdateCustomer(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        const insertcustomeruserproduct = async () => {
            formState.userproduct.sellorderTransaction =
                formState.user.sellorderTransaction;

            let params = new CustomerModel(formState.userproduct);

            console.log("param", params);

            await BzbsUser.apiUpdateCustomerUserProduct(params)
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const insertproductbirthday = async () => {
            try {
                console.log("IN CREATE ");
                console.log(
                    "formStateStore?.listbirstname?.sights :",
                    formStateStore?.listbirstname?.sights
                );

                formState.listproduct.forEach(async (itemlist, index) => {
                    for await (let element of itemlist.listbirstname?.sights) {
                        console.log("element :: ", element);
                        element.productsellorder =
                            formState.user.sellorderTransaction;
                        element.customerorder = formState.user.customercode;
                        element.productkey = itemlist.productkey?.toString();

                        let params = new ProductBirthday(element);

                        console.log("param", params);

                        await BzbsUser.apiupdateproductbirthday(params)
                            .then(res => {
                                console.log(res);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        };

        const insertdeliveryinside = async () => {
            //insert inside จัดส่ง ในประเทศ

            if (formState?.inside?.length <= 0) return;
            for await (let inside_obj of formState.listproduct) {
                if (inside_obj?.inside && inside_obj?.inside != undefined) {
                    for await (let items of inside_obj.inside) {
                        if (items.price == null) return;
                        var param_inside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "inside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: inside_obj.productkey
                        };

                        await BzbsUser.apicreatedelivery(param_inside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertdeliveryinsidecod = async () => {
            //insert inside จัดส่ง ในประเทศ

            if (formState?.insidecod?.length <= 0) return;
            for await (let inside_obj of formState.listproduct) {
                if (
                    inside_obj?.insidecod &&
                    inside_obj?.insidecod != undefined
                ) {
                    for await (let items of inside_obj.insidecod) {
                        if (items.price == null) return;
                        var param_insidecod = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "insidecod",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: inside_obj.productkey
                        };
                        await BzbsUser.apicreatedelivery(param_insidecod)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };
        const insertdeliveryoutside = async () => {
            //insert outside จัดส่ง ในประเทศ
            if (formState.outside.length <= 0) return;
            for await (let outside_obj of formState.listproduct) {
                if (outside_obj?.outside && outside_obj.outside != undefined && outside_obj?.deliverytype!="13") {
                    for await (let items of outside_obj.outside) {
                        if (items.price == null) return;
                        var param_outside = {
                            ProductSellOrder:
                                formState.user.sellorderTransaction,
                            CustomerOrder: formState.user.customercode,
                            DeliveryType: "outside",
                            Price: items.price,
                            Id: _.toString(items.id),
                            Introduction: items.introduction,
                            SlipImageURL: items.slipiurl,
                            ProductKey: outside_obj.productkey
                        };
                        await BzbsUser.apicreatedelivery(param_outside)
                            .then(res => {
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            }
        };

        const insertdeliveryinsideremind = async () => {
            //insert inside จัดส่ง ในประเทศ

            if (formState.remindinside.length <= 0) return;
            for await (let inside_obj of formState.remindinside) {
                var param_inside = {
                    ProductSellOrder: formState.user.sellorderTransaction,
                    CustomerOrder: formState.user.customercode,
                    DeliveryType: "inside",
                    Price: parseFloat(inside_obj.remindprice || 0),
                    Id: _.toString(inside_obj.id),
                    Introduction: inside_obj.remindintroduction,
                    // SlipImageURL: items.slipiurl,
                    ProductKey: inside_obj.productkey
                };

                await BzbsUser.apicreatedeliveryremind(param_inside)
                    .then(res => {
                        console.log(res.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertdeliveryoutsideremind = async () => {
            //insert outside จัดส่ง ในประเทศ
            if (formState.remindoutside.length <= 0) return;
            for await (let outside_obj of formState.remindoutside) {
                var param_outside = {
                    ProductSellOrder: formState.user.sellorderTransaction,
                    CustomerOrder: formState.user.customercode,
                    DeliveryType: "outside",
                    Price: parseFloat(outside_obj.remindprice),
                    Id: _.toString(outside_obj.id),
                    Introduction: outside_obj.remindintroduction,
                    RemindUser: JSON.stringify(formState.reminduser),
                    ProductKey: outside_obj.productkey
                };
                await BzbsUser.apicreatedeliveryremind(param_outside)
                    .then(res => {
                        console.log(res.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const insertproduct = async () => {
            var index = 0;
            var orderdate = "";
            console.log("999formState.listproduct", formState.listproduct);

            for await (let listproduct_items of formState.listproduct) {
                listproduct_items = _.transform(listproduct_items, function(
                    result,
                    val,
                    key
                ) {
                    result[key.toLowerCase()] = val;
                });

                if (listproduct_items.productyanimage?.length > 0) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "product"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                } else if (listproduct_items.productyanimage == "") {
                    listproduct_items["productyanimage"] = "";
                }

                if (listproduct_items.queyanimage?.length > 0) {
                    var queyanimage = listproduct_items.queyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["queyanimage"] = "";
                                    listproduct_items["queyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                } else if (listproduct_items.queyanimage == "") {
                    listproduct_items["queyanimage"] = "";
                }

                if (listproduct_items.systemyanimage?.length > 0) {
                    var systemyanimage = listproduct_items.systemyanimage;
                    for await (let items of systemyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["systemyanimage"] = "";
                                    listproduct_items["systemyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                } else if (listproduct_items.systemyanimage == "") {
                    listproduct_items["systemyanimage"] = "";
                }

                if (listproduct_items.comingyanimage?.length > 0) {
                    var comingyanimage = listproduct_items.comingyanimage;
                    for await (let items of comingyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "comingyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["comingyanimage"] = "";
                                    listproduct_items["comingyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                } else if (listproduct_items.comingyanimage == "") {
                    listproduct_items["comingyanimage"] = "";
                }

                listproduct_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;
                // listproduct_items.IncreaseSizeDesc = formState.userproduct.increasesizedesc;
                // listproduct_items.IncreaseSizeOrder = formState.userproduct.increasesizeorder;

                listproduct_items.productkey = listproduct_items.productkey;

                console.log("listproduct_items :", listproduct_items);

                listproduct_items.productjson = JSON.stringify(
                    formState.listproduct[index]
                );
                listproduct_items.productresponsibleperson = JSON.stringify(
                    Account.getCacheUserBackoffice()
                );

                if (
                    formState.user.ordertransaction ||
                    formState.userproduct.ordertransaction
                ) {
                    listproduct_items.ordertransaction =
                        formState.user.ordertransaction ||
                        formState.userproduct.ordertransaction;
                }
                // listproduct_items.ordertransaction = formState.user.ordertransaction || formState.userproduct.ordertransaction;

                console.log(
                    "listproduct_items.customerdesc:::",
                    listproduct_items.customerdesc
                );
                console.log(
                    "listproduct_items.p_customerdesc:::",
                    listproduct_items.p_customerdesc
                );
                listproduct_items.customerlineid =
                    listproduct_items.customerlineid;
                listproduct_items.customerfacebook =
                    listproduct_items.customerfacebook;
                listproduct_items.customerphone =
                    listproduct_items.customerphone;
                listproduct_items.customerphoneemergency =
                    listproduct_items.p_customerphoneemergency;
                listproduct_items.customerdesc =
                    listproduct_items.p_customerdesc;
                listproduct_items.customeraddressType =
                    listproduct_items.customeraddresstype ||
                    listproduct_items.p_customeraddresstype;
                listproduct_items.CustomerSelectCountry = listproduct_items
                    ?.selectcountry?.selectcountry
                    ? listproduct_items.selectcountry.selectcountry
                    : "" || listproduct_items.customerselectcountry;
                listproduct_items.CustomerSelectcountryCode = listproduct_items
                    ?.selectcountry?.selectcountrycode
                    ? listproduct_items.selectcountry.selectcountrycode
                    : "" || listproduct_items.customerselectcountrycode;
                listproduct_items.CustomerInternationalPhone =
                    listproduct_items.customerinternationalphone;

                listproduct_items.CustomerNotConfirmAddressAlready =
                    listproduct_items.customernotconfirmaddressalready;
                listproduct_items.career = listproduct_items.career;

                listproduct_items.OrderDate =
                    listproduct_items.orderdate || orderdate;

                listproduct_items.Shotness = listproduct_items.shotness;

                if (listproduct_items.orderdate) {
                    orderdate = listproduct_items.orderdate;
                }

                let params = new ProductModel(listproduct_items);

                console.log("apiupdateproduct", params);

                await BzbsUser.apiupdateproduct(params)
                    .then(async res => {
                        index++;
                        console.log(res);
                        // if (formState.listproductlogchange.length > 0) {
                        //     await insertlogproductchange()

                        // }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const insertlogproductchange = async () => {
            var index = 0;
            for await (let listproduct_items of formState.listproductlogchange) {
                if (listproduct_items.productyanimage != undefined) {
                    var queyanimage = listproduct_items.productyanimage;
                    for await (let items of queyanimage) {
                        if (items.preview != undefined) {
                            var uploadparam = {};
                            uploadparam = {
                                filename:
                                    formState.user.customercode +
                                    "_" +
                                    Math.floor(Date.now() / 1000) +
                                    ".jpg",
                                base64: items.preview,
                                folder: "queyan"
                            };
                            await BzbsUser.apiuploadimage(uploadparam)
                                .then(res => {
                                    listproduct_items["productyanimage"] = "";
                                    listproduct_items["productyanimage"] =
                                        res.data.url;
                                    console.log(res.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    }
                }

                listproduct_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproduct_items.customerorder = formState.user.customercode;
                listproduct_items.OrderDate = formState.user.orderdate;
                listproduct_items.productkey = index;

                listproduct_items.userproduct.customerlineid =
                    formState.userproduct.customerlineid;
                listproduct_items.userproduct.customerfacebook =
                    formState.userproduct.customerfacebook;
                listproduct_items.userproduct.customerphone =
                    formState.userproduct.customerphone;
                listproduct_items.userproduct.customerphoneemergency =
                    formState.userproduct.customerphoneemergency;
                listproduct_items.userproduct.customerdesc =
                    formState.userproduct.customerdesc;
                listproduct_items.userproduct.customeraddressType =
                    formState.userproduct.customeraddresstype;
                listproduct_items.userproduct.CustomerSelectCountry =
                    formState.userproduct.selectcountry.selectcountry;
                listproduct_items.userproduct.CustomerSelectcountryCode =
                    formState.userproduct.selectcountry.selectcountrycode;
                listproduct_items.userproduct.CustomerInternationalPhone =
                    formState.userproduct.customerinternationalphone;
                // listproduct_items.userproduct.originalprice = formState.userproduct.originalprice
                let params = new ProductModel(listproduct_items?.userproduct);
                console.log("apiupdateproduct", params);

                await BzbsUser.apiupdateproductlogchenge(params)
                    .then(res => {
                        index++;
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };
        const insertproductdeliveryaddress = async () => {
            for await (let listproductdeliveryaddress_items of formState.user
                .addressfrom) {
                // if (listproduct_items.productyanimage != undefined) {
                //     var queyanimage = listproduct_items.productyanimage;
                //     for await (let items of queyanimage) {
                //         if (items.preview != undefined) {
                //             var uploadparam = {};
                //             uploadparam = {
                //                 filename: formState.user.customercode + ".jpg",
                //                 base64: items.preview,
                //                 folder: "queyan"
                //             };
                //             await BzbsUser.apiuploadimage(uploadparam)
                //                 .then(res => {
                //                     listproduct_items["productyanimage"] = "";
                //                     listproduct_items["productyanimage"] =
                //                         res.data.url;
                //                     console.log(res.data);
                //                 })
                //                 .catch(error => {
                //                     console.log(error);
                //                 });
                //         }
                //     }
                // }

                listproductdeliveryaddress_items.productsellorder =
                    formState.user.sellorderTransaction;
                listproductdeliveryaddress_items.customerorder =
                    formState.user.customercode;

                var phonecode = "";
                if (listproductdeliveryaddress_items?.phonecode) {
                    phonecode = listproductdeliveryaddress_items?.phonecode;
                } else if (
                    listproductdeliveryaddress_items.phonecode &&
                    listproductdeliveryaddress_items.phonecode[0].label
                ) {
                    phonecode =
                        listproductdeliveryaddress_items.phonecode[0].label;
                }
                if (phonecode) {
                    listproductdeliveryaddress_items.phonecode = phonecode;
                }

                let params = new ProductDeliveryAddressModel(
                    listproductdeliveryaddress_items
                );

                console.log("param", params);

                await BzbsUser.apiupdateproductdelivery(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const insertpayment = async () => {
            if (!formState.pay?.listpay) return;
            var indeximage = 0;

            await Promise.all(
                formState.pay?.listpay?.map(async element => {
                    if (element.slipimagearray != undefined) {
                        element["slipimageurl"] = [];
                        for await (let items of element.slipimagearray) {
                            indeximage++;
                            if (items.preview != undefined) {
                                var uploadparam = {
                                    filename:
                                        formState.user.customercode +
                                        "_" +
                                        indeximage +
                                        "_" +
                                        Math.floor(Date.now() / 1000) +
                                        ".jpg",
                                    base64: items.preview,
                                    folder: "payment"
                                };
                                const res = await BzbsUser.apiuploadimage(
                                    uploadparam
                                );
                                element["slipimageurl"].push(res.data.url);
                                console.log(res.data);
                            } else {
                                if (items.url) {
                                    element["slipimageurl"].push(items.url);
                                }
                            }
                        }
                    }
                })
            );

            await formState.pay?.listpay?.forEach(async (element, index) => {
                element.productsellorder = formState.user.sellorderTransaction;

                element.customerorder = formState.user.customercode;

                element["slipimageurl"] = JSON.stringify(
                    element["slipimageurl"]
                );

                let params = new PaymentModel(element);
                if (params.SelectCountry) {
                    params.SelectCountry = JSON.stringify(params.SelectCountry);
                }
                if (params.SlipImage) {
                    params.SlipImage = JSON.stringify(params.SlipImage);
                }

                params.ProductKey = index;

                await BzbsUser.apiupdatepayment(params)
                    .then(res => {
                        console.log(res);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            });
        };

        // const insertpayment = async () => {
        //     console.log("formState.pay", formState.pay)
        //     var indeximage = 0

        //     formState.pay.listpay.forEach(async (element) => {
        //         if (element.slipimagearray != undefined) {
        //             element["slipimageurl"] = [];
        //             for await (let items of element.slipimagearray) {
        //                 indeximage++
        //                 if (items.preview != undefined) {
        //                     var uploadparam = {};
        //                     uploadparam = {
        //                         filename: formState.user.customercode + '_' + indeximage + '_' + Math.floor(Date.now() / 1000) + ".jpg",
        //                         base64: items.preview,
        //                         folder: "payment"
        //                     };
        //                     await BzbsUser.apiuploadimage(uploadparam)
        //                         .then(res => {

        //                             element["slipimageurl"].push(res.data.url)
        //                             console.log(res.data);
        //                         })
        //                         .catch(error => {
        //                             console.log(error);
        //                         });
        //                 }
        //                 else {
        //                     if (items.url) {
        //                         element["slipimageurl"].push(items.url)
        //                     }
        //                 }
        //             }
        //         }
        //     });

        //     formState.pay.listpay.forEach(async (element) => {

        //         formState.pay.productsellorder =
        //             formState.user.sellorderTransaction;

        //         formState.pay.customerorder = formState.user.customercode;

        //         formState.pay["slipimageurl"] = JSON.stringify(formState.pay["slipimageurl"])

        //         let params = new PaymentModel(formState.pay);
        //         // if (params.SelectCountry) {
        //         //     params.SelectCountry = JSON.stringify(params.SelectCountry)
        //         // }
        //         console.log("param", params);
        //         await BzbsUser.apiupdatepayment(params)
        //             .then(res => {
        //                 console.log(res);
        //             })
        //             .catch(error => {
        //                 console.log(error);
        //             });
        //     });

        // };

        const isshowdetailsellorder = reactive(props.isshowDetailSellOrder);
        const clickBack = () => {
            console.log("clickBack", isshowdetailsellorder.isshow);
            isshowdetailsellorder.isshow = false;
        };

        const confirm = e => {
            console.log(e);
            return new Promise(resolve => {
                visiblereject.value = true;
            });
        };
        const cancel = e => {
            console.log(e);
            message.error("Click on No");
        };
        const updatepaymentchecking = () => {
            var CheckMessageParam = [];
            if (CheckMessage.value) {
                CheckMessageParam = [
                    ...CheckMessage.value,
                    {
                        checking: checking.value,
                        message: descmessage.value,
                        updatedate: Date.now().toString()
                    }
                ];
            } else {
                CheckMessageParam = [
                    {
                        checking: checking.value,
                        message: descmessage.value,
                        updatedate: Date.now().toString()
                    }
                ];
            }

            if (checking) {
                var param = {
                    ProductSellOrder: route.query.so,
                    CustomerOrder: route.query.customercode,
                    Checking: checking.value,
                    CheckMessage: JSON.stringify(CheckMessageParam),
                    AdditionalTage:route.query.sp,
                };

             
                BzbsUser.updatepaymentcheckingaddtional(param)
                    .then(res => {
                        setTimeout(() => {
                            initredio();
                        }, 500);

                        success();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        };

        const initredio = () => {
            var param = {
                AdditionalTage: route.query.sp,
                CustomerOrder: route.query.customercode
            };

            BzbsUser.getcheckingaddtional(param)
                .then(res => {
                    checking.value = res.data[0].Checking;
                    CheckMessage.value = Util.jsonParse(
                        res.data[0].CheckMessage
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        };
        setTimeout(() => {
            initredio();
        }, 500);

        const handleOkreject = e => {
            console.log(e);
            visiblereject.value = false;
            updatepaymentchecking();
        };

        const formatdate = date => {
            if (date) {
                moment.locale("th");
                return moment(parseInt(date))
                    .add(543, "year")
                    .format("LLLL");
            } else {
                return "-";
            }
        };

        const reportsetting = () => {
            var userdetail = Account?.getCacheUserBackoffice()
            var list_products = [];
            var birtdaydetail = [];

            formState.listproduct.forEach((element, index) => {
                birtdaydetail = [];
                if (element.Birthday) {
                    var list_birtday = Utility.jsonParse(element.Birthday);
                    list_birtday.forEach(element => {
                        if (element.ProductKey == index) {
                            var d = "";

                            if (element.TypeBirthday == "3") {
                                d = moment(element.Birthday).format("l");
                            } else if (element.TypeBirthday == "2") {
                                d = moment(element.Birthday)
                                    .add(543, "year")
                                    .format("l");
                            } else if (element.TypeBirthday == "4") {
                                d = element.YearBirthday;
                            }

                            if (d != "Invalid date")
                                birtdaydetail.push("(" + d + ")");
                        }
                    });
                }

                var logchangesize = [];
                var log_changesize_obj = {};
                if (element.ProductChangeLog) {
                    logchangesize = JSON.parse(element.ProductChangeLog);

                    log_changesize_obj.CustomerProductsType =
                        logchangesize[0].CustomerProductsType;
                    log_changesize_obj.CustomerProductsSize =
                        logchangesize[0].CustomerProductsSize;
                    log_changesize_obj.HeartYanName =
                        logchangesize[0].HeartYanName;
                    log_changesize_obj.PD_Price = logchangesize[0].ProductPrice;
                    log_changesize_obj.totalPrice =
                        logchangesize[0].ProductPrice *
                        (logchangesize[0].Qty || 1);
                    log_changesize_obj.ProductPrice =
                        logchangesize[0].ProductPrice;

                    //มีการเปลี่ยนขนาด จึงต้อง แคลราคา ในใบเขียวใหม่
                    element.ProductPrice =
                        parseFloat(element.ProductPrice || 0) -
                        parseFloat(log_changesize_obj.ProductPrice || 0);
                }

                var listdesc = [];
                if (
                    element.ProfessorType &&
                    JSON.parse(element.ProfessorType)?.length > 0
                ) {
                    if (JSON.parse(element.ProfessorType).includes("1")) {
                        listdesc.push("อาจารย์เลือกให้");
                    }
                    if (JSON.parse(element.ProfessorType).includes("2")) {
                        listdesc.push("รอคุยกับอาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("4")) {
                        listdesc.push("ส่งเลย");
                    }
                    if (JSON.parse(element.ProfessorType).includes("6")) {
                        listdesc.push("จัดส่งพร้อมยันต์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("7")) {
                        listdesc.push("จัดส่งทางไลน์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("8")) {
                        listdesc.push("รับเอง");
                    }
                    if (JSON.parse(element.ProfessorType).includes("9")) {
                        listdesc.push("เก็บเงินปลายทาง");
                    }
                    if (JSON.parse(element.ProfessorType).includes("3")) {
                        listdesc.push("พรีออเดอร์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("5")) {
                        listdesc.push("ขอรูปคู่อาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("10")) {
                        listdesc.push("มัดจำแล้วรอคุยกับอาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("11")) {
                        listdesc.push("เพิ่มขนาดไม่ทัน");
                    }
                    if (JSON.parse(element.ProfessorType).includes("12")) {
                        listdesc.push("เพิ่มขนาดโดยใช้แทร็คใหม่");
                    }
                    if (JSON.parse(element.ProfessorType).includes("13")) {
                        listdesc.push("ย้ายสินค้าเข้าสต๊อก");
                    }
                    if (JSON.parse(element.ProfessorType).includes("14")) {
                        listdesc.push("อาจารย์ออกแบบโลโก้");
                    }
                }
                var products = {
                    id: index + 1,
                    name:
                        element.CustomerProductsType +
                        " " +
                        element.CustomerProductsSize +
                        " " +
                        element.HeartYanName +
                        " " +
                        element.IntroductionYan,
                    birthday: birtdaydetail?.toString(),
                    company: element.CustomerProductsType,
                    unitPrice: element.ProductPrice,
                    totalPrice:
                        element.ProductPrice * (element.QTYProduct || 1) +
                        parseFloat(element.PD_Price || 0),
                    PD_Price: element.PD_Price || 0,
                    PD_DeliveryType: element.PD_DeliveryType,
                    DeliveryType: element.DeliveryType,
                    qty: element.QTYProduct || 1,
                    logchangesize:
                        log_changesize_obj.CustomerProductsType +
                        " " +
                        log_changesize_obj.CustomerProductsSize +
                        " " +
                        log_changesize_obj.HeartYanName,
                    Paid_Change_Size:
                        parseFloat(element.ProductPrice || 0) -
                        parseFloat(log_changesize_obj.ProductPrice || 0),
                    log_change_old_unitprice: parseFloat(
                        log_changesize_obj.ProductPrice || 0
                    ),
                    increasesizedesc: element.IncreaseSizeDesc,
                    increasesizeorder: element.IncreaseSizeOrder,
                    introduction: element.introduction,
                    desc: listdesc
                };
                list_products.push(products);
            });

            var result_list_products = [];
            list_products.forEach(function(a) {
   
                if (["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(a.company)) {
                    this[a.name] = {
                        id: a.id,
                        logchangesize: a.logchangesize,
                        name: a.name,
                        birthday: a.birthday,
                        company: a.company,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        PD_DeliveryType: a.PD_DeliveryType,
                        DeliveryType: a.DeliveryType,
                        Paid_Change_Size: a.Paid_Change_Size,
                        increasesizedesc: a.increasesizedesc,
                        increasesizeorder: a.increasesizeorder,
                        log_change_old_unitprice: a.log_change_old_unitprice,
                        desc: a.desc,
                        introduction: a.introduction
                    };
                    result_list_products.push(this[a.name]);
                } else if (a.introduction) {
                    this[a.name] = {
                        id: a.id,
                        logchangesize: a.logchangesize,
                        name: a.name,
                        birthday: "",
                        company: a.company,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        PD_DeliveryType: a.PD_DeliveryType,
                        DeliveryType: a.DeliveryType,
                        Paid_Change_Size: a.Paid_Change_Size,
                        increasesizedesc: a.increasesizedesc,
                        increasesizeorder: a.increasesizeorder,
                        log_change_old_unitprice: a.log_change_old_unitprice,
                        checked_pay_extra_price: a.checked_pay_extra_price,
                        arrearage: a.arrearage,
                        desc: a.desc,
                        list_paid: a.list_paid,
                        introduction: a.introduction
                    };
                    result_list_products.push(this[a.name]);
                } else {
                    if (!this[a.name]) {
                        this[a.name] = {
                            id: a.id,
                            logchangesize: a.logchangesize,
                            name: a.name,
                            birthday: a.birthday,
                            company: a.company,
                            unitPrice: a.unitPrice,
                            totalPrice: 0,
                            qty: 0,
                            PD_Price: a.PD_Price,
                            PD_DeliveryType: a.PD_DeliveryType,
                            DeliveryType: a.DeliveryType,
                            Paid_Change_Size: a.Paid_Change_Size,
                            increasesizedesc: a.increasesizedesc,
                            increasesizeorder: a.increasesizeorder,
                            log_change_old_unitprice:
                                a.log_change_old_unitprice,
                            desc: a.desc,
                            introduction: a.introduction
                        };
                        result_list_products.push(this[a.name]);
                    }
                    this[a.name].totalPrice += a.totalPrice;
                    this[a.name].qty += a.qty;
                }
            }, Object.create(null));

            var trackyan = [];
            formState.listproduct.forEach(element => {
                if (element.TrackYan) trackyan.push(element.TrackYan);
            });

            let customerInfo = {
                customerCode: formState.user.customercode,
                ContactName: formState.user.contactList?.map(item => {
                    if (item.contactstartdefault == "true") {
                        return item.contactname?.toString();
                    }
                }),
                customerName:
                    (clearSquareSymbols(formState.user.customername) || "") +
                    " " +
                    (clearSquareSymbols(formState.user.customerlastname) || ""),
                address: formState.useraddress?.address
                    ?.trim()
                    ?.replace(/\n/g, " "),
                city: formState.useraddress?.ProvinceName,
                state:
                    formState.useraddress?.DistrictName +
                    formState.useraddress?.BorderName,
                pincode: formState.useraddress?.PostCode,
                country: formState.useraddress.CountryName
                    ? formState.useraddress.CountryName
                    : "",
                contactNo: formState.useraddress?.phone
                    ? formState.useraddress?.phone
                    : "",
                trackyanlist: trackyan?.toString(),
                typeaddress: formState.useraddress?.typeaddress,
                addresslist: formState.user.addressalllist,
                switchnothasphonecode:
                    formState.useraddress.switchnothasphonecode
            };

            console.log(
                "formState.user.addressalllist ",
                formState.user.addressalllist
            );

      
            var bankarray = [];
            var listfrombank = [];
            formState.pay?.forEach(element => {
                if (element.frombank) listfrombank.push(element.frombank);
                else if (element.bankname) listfrombank.push(element.bankname);

                var itembank = { ...element };

                bankarray.push(itembank);
            });
            var tobank = [];
            formState.pay?.forEach(element => {
                if (element.tobank) tobank.push(element.tobank);
            });

            let orderInfo = {
                orderNo: formState.listproduct[0].IncreaseSizeOrder
                    ? formState.listproduct[0].IncreaseSizeOrder +
                      " *** (เพิ่มขนาด)"
                    : formState.listproduct[0].OrderTransaction,
                orderDate: moment(formState.listproduct[0].OrderDate)
                    .add(543, "year")
                    .format("l"),
                accountname: "",
                products: Utility.toJsonStr(result_list_products),
                totalValue: 0,
                bankarray: bankarray
            };
            var exportreport = {
                customerInfo: customerInfo,
                orderInfo: orderInfo,
                userdetail:userdetail,
            };

            console.log(exportreport);

            // var param = {
            //     "ProductSellOrder": formState.user.sellorderTransaction,
            //     "CustomerOrder": formState.user.customercode
            // }

            BzbsUser.apireportgreen(exportreport)
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "ใบรายการขาย.pdf";
                    link.click();
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const reportstocknaw = () => {
            console.log("formState.listproduct ::: ", formState.listproduct);

            const ListProductNotYan = formState.listproduct.filter(
                item => !item.ProductCode.includes("YAN", "VIP")
            );

            var list_products = [];
            var birtdaydetail = [];

            formState.listproduct.forEach((element, index) => {
                birtdaydetail = [];
                if (element.Birthday) {
                    var list_birtday = Utility.jsonParse(element.Birthday);
                    list_birtday.forEach(element => {
                        var d = "";
                        if (element.TypeBirthday == "3") {
                            d = moment(element.Birthday).format("l");
                        } else if (element.TypeBirthday == "2") {
                            d = moment(element.Birthday)
                                .add(543, "year")
                                .format("l");
                        } else if (element.TypeBirthday == "4") {
                            d = element.YearBirthday;
                        }
                        if (d != "Invalid date")
                            birtdaydetail.push("(" + d + ")");
                    });
                }

                var logchangesize = [];
                var log_changesize_obj = {};
                if (element.ProductChangeLog) {
                    logchangesize = JSON.parse(element.ProductChangeLog);

                    log_changesize_obj.CustomerProductsType =
                        logchangesize[0].CustomerProductsType;
                    log_changesize_obj.CustomerProductsSize =
                        logchangesize[0].CustomerProductsSize;
                    log_changesize_obj.HeartYanName =
                        logchangesize[0].HeartYanName;
                    log_changesize_obj.PD_Price = logchangesize[0].ProductPrice;
                    log_changesize_obj.totalPrice =
                        logchangesize[0].ProductPrice *
                        (logchangesize[0].Qty || 1);
                    log_changesize_obj.ProductPrice =
                        logchangesize[0].ProductPrice;

                    //มีการเปลี่ยนขนาด จึงต้อง แคลราคา ในใบเขียวใหม่
                    element.ProductPrice =
                        parseFloat(element.ProductPrice || 0) -
                        parseFloat(log_changesize_obj.ProductPrice || 0);
                }

                console.log("element ::", element);
                var listdesc = [];
                if (
                    element.ProfessorType &&
                    JSON.parse(element.ProfessorType)?.length > 0
                ) {
                    if (JSON.parse(element.ProfessorType).includes("1")) {
                        listdesc.push("อาจารย์เลือกให้");
                    }
                    if (JSON.parse(element.ProfessorType).includes("2")) {
                        listdesc.push("รอคุยกับอาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("4")) {
                        listdesc.push("ส่งเลย");
                    }
                    if (JSON.parse(element.ProfessorType).includes("6")) {
                        listdesc.push("จัดส่งพร้อมยันต์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("7")) {
                        listdesc.push("จัดส่งทางไลน์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("8")) {
                        listdesc.push("รับเอง");
                    }
                    if (JSON.parse(element.ProfessorType).includes("9")) {
                        listdesc.push("เก็บเงินปลายทาง");
                    }
                    if (JSON.parse(element.ProfessorType).includes("3")) {
                        listdesc.push("พรีออเดอร์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("5")) {
                        listdesc.push("ขอรูปคู่อาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("10")) {
                        listdesc.push("มัดจำแล้วรอคุยกับอาจารย์");
                    }
                    if (JSON.parse(element.ProfessorType).includes("11")) {
                        listdesc.push("เพิ่มขนาดไม่ทัน");
                    }
                    if (JSON.parse(element.ProfessorType).includes("12")) {
                        listdesc.push("เพิ่มขนาดโดยใช้แทร็คใหม่");
                    }
                    if (JSON.parse(element.ProfessorType).includes("13")) {
                        listdesc.push("ย้ายสินค้าเข้าสต๊อก");
                    }
                    if (JSON.parse(element.ProfessorType).includes("14")) {
                        listdesc.push("อาจารย์ออกแบบโลโก้ให้");
                    }
                }
                var products = {
                    id: index + 1,
                    name:
                        element.CustomerProductsType +
                        " " +
                        element.CustomerProductsSize +
                        " " +
                        element.HeartYanName +
                        " " +
                        element.IntroductionYan,
                    birthday: birtdaydetail?.toString(),
                    company: element.CustomerProductsType,
                    unitPrice: element.ProductPrice,
                    totalPrice:
                        element.ProductPrice * (element.QTYProduct || 1) +
                        parseFloat(element.PD_Price || 0),
                    PD_Price: element.PD_Price || 0,
                    PD_DeliveryType: element.PD_DeliveryType,
                    DeliveryType: element.DeliveryType,
                    qty: element.QTYProduct || 1,
                    logchangesize:
                        log_changesize_obj.CustomerProductsType +
                        " " +
                        log_changesize_obj.CustomerProductsSize +
                        " " +
                        log_changesize_obj.HeartYanName,
                    Paid_Change_Size:
                        parseFloat(element.ProductPrice || 0) -
                        parseFloat(log_changesize_obj.ProductPrice || 0),
                    log_change_old_unitprice: parseFloat(
                        log_changesize_obj.ProductPrice || 0
                    ),
                    increasesizedesc: element.IncreaseSizeDesc,
                    increasesizeorder: element.IncreaseSizeOrder,
                    introduction: element.introduction,
                    desc: listdesc
                };
                list_products.push(products);
            });

            var result_list_products = [];
            list_products.forEach(function(a) {
                var space_track = "";
                if (a.birthday) {
                    space_track = index == 0 ? "" : "";
                }

                if (["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(a.company)) {
                    this[a.name] = {
                        id: a.id,
                        logchangesize: a.logchangesize,
                        name: a.name,
                        birthday: a.birthday,
                        company: a.company,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        PD_DeliveryType: a.PD_DeliveryType,
                        DeliveryType: a.DeliveryType,
                        Paid_Change_Size: a.Paid_Change_Size,
                        increasesizedesc: a.increasesizedesc,
                        increasesizeorder: a.increasesizeorder,
                        log_change_old_unitprice: a.log_change_old_unitprice,
                        desc: a.desc,
                        introduction: a.introduction
                    };
                    result_list_products.push(this[a.name]);
                } else if (a.introduction) {
                    this[a.name] = {
                        id: a.id,
                        logchangesize: a.logchangesize,
                        name: a.name,
                        birthday: "",
                        company: a.company,
                        unitPrice: a.unitPrice,
                        totalPrice: a.totalPrice,
                        qty: a.qty,
                        PD_Price: a.PD_Price,
                        PD_DeliveryType: a.PD_DeliveryType,
                        DeliveryType: a.DeliveryType,
                        Paid_Change_Size: a.Paid_Change_Size,
                        increasesizedesc: a.increasesizedesc,
                        increasesizeorder: a.increasesizeorder,
                        log_change_old_unitprice: a.log_change_old_unitprice,
                        checked_pay_extra_price: a.checked_pay_extra_price,
                        arrearage: a.arrearage,
                        desc: a.desc,
                        list_paid: a.list_paid,
                        introduction: a.introduction
                    };
                    result_list_products.push(this[a.name]);
                    this[a.name].birthday += space_track + a.birthday;
                } else {
                    if (!this[a.name]) {
                        this[a.name] = {
                            id: a.id,
                            logchangesize: a.logchangesize,
                            name: a.name,
                            birthday: a.birthday,
                            company: a.company,
                            unitPrice: a.unitPrice,
                            totalPrice: 0,
                            qty: 0,
                            PD_Price: a.PD_Price,
                            PD_DeliveryType: a.PD_DeliveryType,
                            DeliveryType: a.DeliveryType,
                            Paid_Change_Size: a.Paid_Change_Size,
                            increasesizedesc: a.increasesizedesc,
                            increasesizeorder: a.increasesizeorder,
                            log_change_old_unitprice:
                                a.log_change_old_unitprice,
                            desc: a.desc,
                            introduction: a.introduction
                        };
                        result_list_products.push(this[a.name]);
                    }
                    this[a.name].totalPrice += a.totalPrice;
                    this[a.name].qty += a.qty;
                    this[a.name].birthday += space_track + a.birthday;
                }
            }, Object.create(null));

            var trackyan = [];
            formState.listproduct.forEach(element => {
                if (element.TrackYan) trackyan.push(element.TrackYan);
            });

            let customerInfo = {
                customerCode: formState.user.customercode,
                ContactName: formState.user.contactList?.map(item => {
                    if (item.contactstartdefault == "true") {
                        return item.contactname?.toString();
                    }
                }),
                customerName:
                    (clearSquareSymbols(formState.user.customername) || "") +
                    " " +
                    (clearSquareSymbols(formState.user.customerlastname) || ""),
                address: formState.useraddress?.address
                    ?.trim()
                    ?.replace(/\n/g, " "),
                city: formState.useraddress?.ProvinceName,
                state:
                    formState.useraddress?.DistrictName +
                    formState.useraddress?.BorderName,
                pincode: formState.useraddress?.PostCode,
                country: formState.useraddress.CountryName
                    ? formState.useraddress.CountryName
                    : "",
                contactNo: formState.useraddress?.phone
                    ? formState.useraddress?.phone
                    : "",
                trackyanlist: trackyan?.toString(),
                typeaddress: formState.useraddress?.typeaddress,
                addresslist: formState.user.addressalllist,
                switchnothasphonecode:
                    formState.useraddress.switchnothasphonecode
            };

            console.log(
                "formState.user.addressalllist ",
                formState.user.addressalllist
            );

            var bankarray = [];
            var listfrombank = [];
            formState.pay?.forEach(element => {
                if (element.frombank) listfrombank.push(element.frombank);
                else if (element.bankname) listfrombank.push(element.bankname);

             
                var itembank = {
                    frombank: element.frombank,
                    tobank: element.tobank,
                    bankname: element.bankname,
                    paymenttype: element.paymenttype,
                    numberref: element.numberref,
                };
      
                bankarray.push(itembank);
            });
            var tobank = [];
            formState.pay?.forEach(element => {
                if (element.tobank) tobank.push(element.tobank);
            });

            let orderInfo = {
                orderNo: formState.listproduct[0].IncreaseSizeOrder
                    ? formState.listproduct[0].IncreaseSizeOrder +
                      " *** (เพิ่มขนาด)"
                    : formState.listproduct[0].OrderTransaction,
                orderDate: moment()
                    .add(543, "year")
                    .format("l"),
                accountname: "",
                products: Utility.toJsonStr(result_list_products),
                totalValue: 0,
                bankarray: bankarray
            };
            var exportreport = {
                customerInfo: customerInfo,
                orderInfo: orderInfo,
                userdetail:userdetail,
            };

            console.log(exportreport);

            // var param = {
            //     "ProductSellOrder": formState.user.sellorderTransaction,
            //     "CustomerOrder": formState.user.customercode
            // }

            BzbsUser.apireportstocknaw(exportreport)
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: "application/pdf"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "ใบรายการขาย.pdf";
                    link.click();
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const disabledtransactionorder = ref(true);
        const toggle = () => {
            disabledtransactionorder.value = !disabledtransactionorder.value;
        };

        const checkCountNAW = () => {
            var result = formState.listproduct?.filter(
                item => !item.Category_1?.includes("ยันต์")
            );
            return result.length > 0;
        };

        const clearSquareSymbols = word => {
            const regex = /[^a-zA-Z0-9@#*\u0E00-\u0E7F/.]/g;
            return word.replace(regex, " ");
        };

        const clickblack =()=>{
            window.location = '/additionalorderforaccounting'
        }
        return {
            clickblack,
            clearSquareSymbols,
            checkCountNAW,
            disabledtransactionorder,
            toggle,
            reportsetting,
            formatdate,
            CheckMessage,
            descmessage,
            visiblereject,
            handleOkreject,
            confirm,
            cancel,
            isshowdetailsellorder,
            clickBack,
            insertproduct,
            insertproductdeliveryaddress,
            gencodecustomer,
            title,
            formState,
            onFinish,
            layout,
            validateMessages,
            SellOrderTransaction,
            onselectchange,
            calculateAge,
            options,
            listcodephone,
            handleChange,
            handleBlur,
            handleFocus,
            filterOption,
            onFinishFailed,
            queryProductType,
            producttype,
            onModalAddressChagne,
            publishedBooksMessage,
            getallproduct,
            success,
            imageslip,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            calculateAge_setting,
            datecreatetoday,
            typelocal,
            age,
            update,
            insertdatabase,
            insertcontract,
            insertcustomer,
            rowselecteddetail,
            getCustomer,
            formStateStore,
            Storedata,
            orderyan,
            ProductBirthday,
            insertlogproductchange,
            isfinistinit,
            checking,
            moment,
            reportstocknaw
        };
    }
    // computed: {
    //     createproduct: function() {
    //         return Storedata.state.createproduct;
    //     }
    // }
});
</script>
<style lang="scss" scoped>
.ant-row {
    flex-direction: row;
}

.date_create {
    display: flex;
    flex-flow: row-reverse;
}

.wrapper_createnew {
    display: flex;
    align-items: center;
}

.wrapper-icon-create {
    position: relative;
    top: 26px;
    left: 30px;
}
</style>
