<template>
    <div>
        <a-button v-if="type != 'footer'" type="primary" @click="showModal">เพิ่ม content</a-button>
        <!-- <a-button v-if="type == 'footer'" @click="showModal" style="border: 0; background: transparent;height: auto;">
            <img src="../../../../../assets/imgs/logo/add.png" width="50" height="50" alt="submit" />
        </a-button> -->
        <SelectImageVue v-if="type == 'footer'" @onobjsalepage="addvalue($event)"></SelectImageVue>
        <a-modal v-model:visible="visible" title="เลือก" @ok="handleOk" :footer="null">
            <SelectImageVue :type="'add'" @onobjsalepage="addvalue($event)"></SelectImageVue>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import SelectImageVue from "./SelectImage.vue";
import { objsalepage, objsalepagethank } from '@/store/store'
export default defineComponent({
    props: ['type'],
    components: {
        SelectImageVue
    },
    setup(props, { emit }) {
        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        // const objsalepage = ref()
        const addvalue = (e) => {
            console.log(e);
            // objsalepage.value = e
            visible.value = false;
            console.log(objsalepagethank)
            emit('onobjsalepage', objsalepage)
        }

        return {
            addvalue,
            visible,
            showModal,
            handleOk
        };
    }
});
</script>
