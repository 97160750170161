import CacheHelper from "@/helper/CacheHelper.js";
// import Config from "@/config/ConfigManager.js";
import ConfigManager from "@/config/ConfigManager.js";

import { th } from '@/helper/locale/th.1054.js';
import { en } from '@/helper/locale/en.1033.js';

/**
 * @function getLocale
 * @function getLocaleCode
 * @function getLbl
 * @function getLocaleShort
 * @function setLocale
 */
export default {
    KEY_COOKIE_LOCALE: 'BzbsLocale',
    defaultLocale: {
        code: 1054,
        short: 'th',
    },

    getLocale: function () {
        // var locale = Vue.$cookies.get(this.KEY_COOKIE_LOCALE);
        // var locale = Account.getCacheCustom(this.KEY_COOKIE_LOCALE);
        var cache = new CacheHelper(ConfigManager.getCacheType());
        var locale = cache.getCache(this.KEY_COOKIE_LOCALE);
        if (locale == null) {
            locale = this.defaultLocale;
        }
        return locale;
    },
    getLocaleCode: function () {
        var localeObj = this.getLocale();
        return localeObj.code;
    },
    getLbl: function () {
        if (this.getLocaleCode() == 1033) {
            return en;
        } else {
            return th;
        }
    },
    getLocaleShort: function () {
        var localeObj = this.getLocale();
        return localeObj.short;
    },
    setLocale: function (localeCode) {
        var localeObj = {
            code: 1054,
            short: 'th',
        };
        if (localeCode == 1033) {
            localeObj = {
                code: 1033,
                short: 'en',
            };
        }

        var cache = new CacheHelper(ConfigManager.getCacheType());
        cache.setCache(this.KEY_COOKIE_LOCALE, localeObj);
    },
}
