<template> 
    <a-button type="primary" @click="showDrawer" :disabled="true">
        <template #icon><PlusOutlined /></template>
        แจ้งพัสดุลูกค้า
    </a-button>
    <a-drawer
        title="ส่ง Tracking Email"
        :width="720"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
    >
        <a-form :model="form" :rules="rules" layout="vertical">
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item label="ชื่อลูกค้า" name="name">
                        <a-input
                            :disabled="true"
                            v-model:value="form.name"
                            placeholder="Please enter user name"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="รหัส Order Shopify" name="ordershopify">
                        <a-input
                            :disabled="true"
                            v-model:value="form.shopifyorderid"
                            placeholder="Please enter user ordershopify"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item label="Tracking" name="tracking">
                        <a-input
                            :disabled="true"
                            v-model:value="form.tracking"
                            placeholder="Please enter Tracking"
                        />
                    </a-form-item>
                </a-col>
                <!-- <a-col :span="12">
            <a-form-item label="Type" name="type">
              <a-select v-model:value="form.type" placeholder="Please choose the type">
                <a-select-option value="private">Private</a-select-option>
                <a-select-option value="public">Public</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
            </a-row>
            <!-- <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Approver" name="approver">
              <a-select v-model:value="form.approver" placeholder="Please choose the approver">
                <a-select-option value="jack">Jack Ma</a-select-option>
                <a-select-option value="tom">Tom Liu</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="DateTime" name="dateTime">
              <a-date-picker
                v-model:value="form.dateTime"
                style="width: 100%"
                :get-popup-container="trigger => trigger.parentElement"
              />
            </a-form-item>
          </a-col>
        </a-row> -->

            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item label="ที่อยู่ที่ลูกค้ากรอก" name="address">
                        <a-textarea
                            :disabled="true"
                            v-model:value="form.address"
                            :rows="4"
                            placeholder="please enter url description"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <!-- <a-col :span="24">
                    <a-form-item label="Description" name="description">
                        <a-textarea
                            v-model:value="form.description"
                            :rows="4"
                            placeholder="please enter url description"
                        />
                    </a-form-item>
                </a-col> -->
                <a-col :span="24">
                    <h3>รายการสินค้า:</h3>

                    <ul>
                        <li
                            v-for="item in form.listproduct[0]?.line_items"
                            :key="item.id"
                        >
                            {{ item.name }} - ราคา: ฿{{ item.price }} - จำนวน:
                            {{ item.quantity }}
                        </li>
                    </ul>
                    <a-divider>ข้อมูลสถานะ</a-divider>
                    <ul>
                        <li v-for="item in form.listproduct" :key="item.id">
                            <h2>รายการจัดส่ง #{{ item.name }}</h2>
                            <p>บริษัทจัดส่ง: {{ item.tracking_company }}</p>
                            <p>
                                สถานะ:
                                <span
                                    :style="{
                                        color:
                                            item.status === 'success'
                                                ? 'green'
                                                : 'black'
                                    }"
                                >
                                    {{
                                        item.status === "success"
                                            ? "สำเร็จ"
                                            : "รอจัดส่ง"
                                    }}
                                </span>
                            </p>
                            <p>
                                หมายเลขติดตาม(Tracking):
                                {{ item.tracking_number }}
                            </p>
                            <p>
                                อัปเดตล่าสุด: {{ formatDate(item.updated_at) }}
                            </p>
                        </li>
                    </ul>
                </a-col>
            </a-row>
        </a-form>
        <template #extra>
            <a-space>
                <a-button @click="onClose">Cancel</a-button>
                <a-button type="primary">
                    <a-popconfirm
                        title="ต้องการส่ง แจ้ง Tracking ให้ลูกค้า?"
                        @confirm="clicksentconfirm"
                        @cancel="onClose"
                    >
                        Sent Tracking Email
                    </a-popconfirm>
                </a-button>
            </a-space>
        </template> 
        <a-alert
            v-if="visiblealert"
            message="success"
            type="success"
            closable
            :after-close="handleClose"
        />
    </a-drawer>
    <!-- <pre>{{form.listproduct[0]?.line_items}}</pre> -->
</template>
<script>
import { formStateStore } from "@/store/storebackoffice";
import { PlusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    props: {
        formState: null
    },
    components: {
        PlusOutlined
    },
    setup(props, { emit }) {
        const formState = reactive(formStateStore);

        const form = reactive({
            tracking: "",
            listproduct: "",
            shopifyorderid: "",
            name: "",
            url: "",
            owner: "",
            type: "",
            approver: "",
            dateTime: null,
            description: ""
        });

        setTimeout(() => {
            debugger;

            var shippingaddresses = JSON.parse(
                formState.listcreatedelivery[0]?.ShippingAddresses
            )[0];
            var listproduct = JSON.parse(
                formState.listcreatedelivery[0]?.FulFillments
            );
            form.listproduct = listproduct;
            form.tracking = formState.listcreatedelivery[0]?.PD_TrackingNo;
            form.shopifyorderid =
                formState.listcreatedelivery[0]?.FulFillmentsOrderID;
            form.name =
                shippingaddresses.firstName + " " + shippingaddresses.firstName;
            form.address = shippingaddresses.address;
            console.log(
                "formStateformStateformState ",
                formState.listcreatedelivery
            );
            console.log("form ::: ", form.value);
        }, 2000);
        const rules = {
            name: [
                {
                    required: true,
                    message: "Please enter user name"
                }
            ],
            url: [
                {
                    required: true,
                    message: "please enter url"
                }
            ],
            owner: [
                {
                    required: true,
                    message: "Please select an owner"
                }
            ],
            type: [
                {
                    required: true,
                    message: "Please choose the type"
                }
            ],
            approver: [
                {
                    required: true,
                    message: "Please choose the approver"
                }
            ],
            dateTime: [
                {
                    required: true,
                    message: "Please choose the dateTime",
                    type: "object"
                }
            ],
            description: [
                {
                    required: true,
                    message: "Please enter url description"
                }
            ]
        };
        const visible = ref(false);
        const visiblealert = ref(false);
        const showDrawer = () => {
            visible.value = true;
        };
        const onClose = () => {
            visible.value = false;
        };
        const clicksentconfirm = async () => {
            await apiupdateproductstatus();
        };

        const formatDate = dateString => {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit"
            };
            return new Date(dateString).toLocaleDateString("th-TH", options);
        };

        const apiupdateproductstatus = async e => {
            var param = {
                fulfillment_order_id: 6572066308179,
                tracking: form.tracking
            };

            await BzbsUser.apisentemailtracking(param)
                .then(res => {
                    console.log("update status ", res);
                    visiblealert.value = true;
                })
                .catch(error => {
                    if (error.response) {
                        error.response?.data?.error?.errors.forEach(element => {
                            alert(element);
                        });
                    } else if (error.request) {
                        console.log("No response received:", error.request); // Request made, no response received
                    } else {
                        console.log("Error message:", error.message); // Other error messages
                    }
                    console.log("Error config:", error.config); // Configuration used in the request
                });
        };
        const handleClose = () => {
            visiblealert.value = false;
        };
        return {
            clicksentconfirm,
            formatDate,
            form,
            rules,
            visible,
            visiblealert,
            showDrawer,
            onClose,
            formState,
            handleClose
        };
    }
});
</script>
