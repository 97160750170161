/* eslint-disable */
//eslint-disable-next-line
import { createRouter, createWebHistory } from "vue-router";

// import Default from '@/module/Home/page/Default.vue';
import Notfound from "@/module/Static/Notfound/page/Notfound.vue";
import LayoutLeftMenu from "@/module/Layout/LeftMenu/page/LayoutLeftMenu.vue";
import DastboardInfo from "@/module/DashboardInfo/page/DashboardInfo.vue";
import DastboardDelivery from "@/module/Delivery/page/DashboardInfo.vue";
import AdditionalAccountChecking from "@/module/CustomerOrder/SellCreate/component/AdditionalAccountChecking.vue";

import AdditionalorderDelivery from "@/module/Delivery/page/AdditionalDashboardInfo.vue";
import AdditionalorderDeliveryForAccounting from "@/module/Delivery/page/AdditionalDashboardInfoForAccounting.vue";
import Createdeliverylist from "@/module/Delivery/page/DashboardInfoCreatedeliverylist.vue";
import ManageOrder from "@/module/Delivery/page/DashboardInfoManageOrder.vue";
import DashboardInfoThailandPostInter from "@/module/Delivery/page/DashboardInfoThailandPost.vue";
import GetYan from "@/module/Delivery/page/DashboardInfoGetYan.vue";
import GetYanStock from "@/module/Delivery/page/DashboardInfoGetYanStock.vue";
import GetYanVIP from "@/module/Delivery/page/DashboardInfoGetYanVIP.vue";
import GetYanChangeSize from "@/module/Delivery/page/DashboardInfoGetYanChangeSize.vue";
import GetAcceptSystemYanDate from "@/module/Delivery/page/DashboardInfoGetAcceptSystemYanDate.vue";
import ReportNotPay from "@/module/Delivery/page/ReportNotPay.vue";
import ReportProduct from "@/module/Delivery/page/ReportProduct.vue";
import PayYan from "@/module/Delivery/page/PayYan.vue";
import ReportCommission from "@/module/Delivery/page/ReportCommission.vue";
import ExcelContact from "@/module/Delivery/page/DashboardInfoGetExcelContact.vue";

import DashboardInfoThailandPostPrepost from "@/module/Delivery/page/DashboardInfoThailandPost.vue";

import DashboardInfoThailandPostThai from "@/module/Delivery/page/DashboardInfoThailandPost.vue";
import DashboardInfoPack from "@/module/Delivery/page/DashboardInfoPack.vue";
import ViewDetail from "@/module/DashboardInfo/page/ViewDetail.vue";
// import UserList from "@/module/User/UserList/page/UserList.vue";
import Selllist from "@/module/Sell/SellList/page/SellList.vue";
import Checkmoney from "@/module/Sell/Checkmoney/page/SellList.vue";
import CustomerOrder from "@/module/CustomerOrder/SellList/page/SellList.vue";
import CustomerOrderMember from "@/module/CustomerOrder/SellList/page/SellListCustomer.vue";
import Product from "@/module/Product/CustomerList/page/CustomerList.vue";
import InsertProduct from "@/module/Product/CustomerList/component/insert/InsertProduct.vue";
import InsertDetailProduct from "@/module/Product/CustomerList/component/insert/InsertDetailProduct.vue";
import InsertDetailScript from "@/module/Product/CustomerList/component/insert/InsertDetailScript.vue";

import EditProduct from "@/module/Product/CustomerList/component/edit/EditProduct.vue";
import EditProductDetail from "@/module/Product/CustomerList/component/edit/EditProductDetail.vue";

import LogLinePay from "@/module/LogLinePay/page/DashboardInfo.vue";
import ShoppingCart from "@/module/LogLinePay/page/ShoppingCart.vue";
import CustomerMission from "@/module/LogLinePay/page/CustomerMission.vue";
import LineUser from "@/module/LogLinePay/page/DashboardLineUserInfo.vue";
import Sellcreate from "@/module/Sell/SellCreate/page/SellCreate.vue";
//  import Home from "@/module/Home/page/Home";

import CustomerList from "@/module/Customer/CustomerList/page/CustomerList.vue";
import CustomerCreate from "@/module/Customer/CustomerCreate/page/CustomerCreate.vue";
import CreateSalePage from "@/module/Salepage/SalepageCreate/page/SalepageCreate.vue";
import SalePageList from "@/module/Salepage/SalepageList/page/SalePageList.vue";
import SubdomainCreate from "@/module/Salepage/SalepageList/page/SubdomainCreate.vue";
import SubdomainList from "@/module/Salepage/SalepageList/page/SubdomainList.vue";
import SubdomainSalepage from "@/module/Salepage/SalepageList/page/SubdomainSalepage.vue";
import SalePageEdit from "@/module/Salepage/SalepageList/page/SalepageEdit.vue";

// import UserLevel from "@/module/User/UserLevel/page/UserLevel.vue";
// import UserTag from "@/module/User/UserTag/page/UserTag.vue";
import UserProfile from "@/module/User/UserProfile/page/UserProfileMain.vue";

//#region login
import LoginAd from "@/module/Login/page/LoginAd.vue";
import LogoutAd from "@/module/Login/page/LogoutAd.vue";
import MsClearLogout from "@/module/Login/page/MsClearLogout.vue";

import ConsumerMenu from "@/module/Consumer/component/Menu.vue";
import CheckTracking from "@/module/Consumer/component/TableThailandPostPrepostForChecking.vue";
import ConsumerDelivery from "@/module/Consumer/component/ConsumerDelivery.vue";
import ConsumerDeliveryDetail from "@/module/Consumer/component/ConsumerDeliveryDetail.vue";
import ConsumerBirthDay from "@/module/Consumer/component/ConsumerBirthDay.vue";
import ConsumerHistoryConfirm from "@/module/Consumer/component/ConsumerHistoryConfirm.vue";
import CheckTracking_Inter from "@/module/Consumer/component/TableThailandPostPrepostForCheckingInter.vue";
import ConsumerHistory from "@/module/Consumer/component/ConsumerHistory.vue";
import ConsumerYandetail from "@/module/Consumer/component/ConsumerYandetail.vue";
import ConsumerYandetailConfirm from "@/module/Consumer/component/ConsumerYandetailConfirm.vue";
import ConsumerYandetailBrithDay from "@/module/Consumer/component/ConsumerYandetailBrithDay.vue";
import ConsumerLogin from "@/module/Consumer/component/ConsumerLogin.vue";
import CheckTrackingInter from "@/module/Delivery/component/TableThailandPostInterForCheckTrack.vue";
// import RedirectMS from "@/module/Auth/pages/RedirectMS";
//#endregion login

// import CacheHelper from "@/helper/CacheHelper.js";
// import ConfigManager from "@/config/ConfigManager.js";

const routes = [
    {
        path: "/consumertracking",
        name: "tracking",
        component: CheckTracking
    },
    {
        path: "/consumertrackinginter",
        name: "trackinginter",
        component: CheckTracking_Inter
    },
    {
        path: "/consumermenu",
        name: "consumermenu",
        component: ConsumerMenu
    },
    {
        path: "/consumerhistory",
        name: "consumerhistory",
        component: ConsumerHistory
    },
    {
        path: "/consumerdelivery",
        name: "consumerdelivery",
        component: ConsumerDelivery
    },
    {
        path: "/consumerdeliverydetail",
        name: "consumerdeliverydetail",
        component: ConsumerDeliveryDetail
    },
    {
        path: "/ConsumerHistoryConfirm",
        name: "ConsumerHistoryConfirm",
        component: ConsumerHistoryConfirm
    },
    {
        path: "/ConsumerBirthDay",
        name: "ConsumerBirthDay",
        component: ConsumerBirthDay
    },
    {
        path: "/consumeryandetail",
        name: "consumeryandetail",
        component: ConsumerYandetail
    },
    {
        path: "/consumeryandetailconfirm",
        name: "consumeryandetailconfirm",
        component: ConsumerYandetailConfirm
    },
    {
        path: "/consumeryandetailbrithDay",
        name: "consumeryandetailbrithDay",
        component: ConsumerYandetailBrithDay
    },
    {
        path: "/consumerlogin",
        name: "consumerlogin",
        component: ConsumerLogin
    }, 
    {
        path: "/tracking_inter",
        name: "tracking_inter",
        component: CheckTrackingInter
    },

    {
        path: "/" + encodeURI("อุอากะสะ"),
        name: "login_ad",
        component: LoginAd
    },
    {
        path: "/logoutad",
        name: "logout_ad",
        component: LogoutAd,
        meta: {
            layout: "login"
        }
    },
    {
        path: "/logout-ms",
        name: "logout_clear_ms",
        component: MsClearLogout,
        meta: {
            layout: "login"
        }
    },
    {
        path: "/viewdetail",
        name: "viewdetail",
        component: ViewDetail
    },
    {
        path: "/" + encodeURI("อุอากะสะ"),
        name: "layout_left_menu",
        component: LayoutLeftMenu,
        children: [
            {
                path: "/dashboard",
                name: "dashboard_info",
                component: DastboardInfo
            },
            {
                path: "/sellcreate",
                name: "sell_create",
                component: Sellcreate
            },
            {
                path: "/selllist",
                name: "sell_list",
                component: Selllist
            },
            {
                path: "/checkmoneyselllist",
                name: "check_sell_list",
                component: Checkmoney
            },

            {
                path: "/customerorder",
                name: "customer_order",
                component: CustomerOrder
            },
            {
                path: "/customermember",
                name: "customer_member",
                component: CustomerOrderMember
            },
            {
                path: "/product",
                name: "customer_product",
                component: Product
            },
            {
                path: "/insertproduct",
                name: "customer_insertproduct",
                component: InsertProduct
            },
            {
                path: "/insertdetailproduct",
                name: "customer_insertdetailproduct",
                component: InsertDetailProduct
            },
            {
                path: "/insertdetailscript",
                name: "customer_insertdetailscript",
                component: InsertDetailScript
            },

            {
                path: "/editproduct",
                name: "customer_editproduct",
                component: EditProduct
            },
            {
                path: "/editproductdetail",
                name: "customer_editproductdetail",
                component: EditProductDetail
            },
            // {
            //     path: "/main/user/level",
            //     name: "user_level",
            //     component: UserLevel
            // },
            // {
            //     path: "/main/user/tag",
            //     name: "user_tag",
            //     component: UserTag
            // },
            {
                path: "/main/user/detail/:id",
                name: "user_profile",
                component: UserProfile
            },
            {
                path: "/customercreate",
                name: "customer_create",
                component: CustomerCreate
            },
            {
                path: "/salepagecreate",
                name: "salepage_create",
                component: CreateSalePage
            },
            {
                path: "/salepagelist",
                name: "salepage_list",
                component: SalePageList
            },

            {
                path: "/subdomaincreate",
                name: "subdomain_create",
                component: SubdomainCreate
            },
            {
                path: "/subdomainlist",
                name: "subdomain_list",
                component: SubdomainList
            },
            {
                path: "/subdomainsalepage",
                name: "SubdomainSalepage_list",
                component: SubdomainSalepage
            },

            {
                path: "/salepageedit",
                name: "salepage_edit",
                component: SalePageEdit
            },
            {
                path: "/customerlist",
                name: "customer_list",
                component: CustomerList
            },
            {
                path: "/delivery",
                name: "dashboard_Delivery",
                component: DastboardDelivery
            },
            {
                path: "/additionalaccountchecking",
                name: "dashboard_AdditionalAccountChecking",
                component: AdditionalAccountChecking
            },
            
            {
                path: "/additionalorder",
                name: "dashboard_additionalorder",
                component: AdditionalorderDelivery
            },
            {
                path: "/additionalorderforaccounting",
                name: "dashboard_additionalorder",
                component: AdditionalorderDeliveryForAccounting
            },
            
            {
                path: "/createdeliverylist",
                name: "dashboard_createdeliverylist",
                component: Createdeliverylist
            },
            {
                path: "/manageorder",
                name: "dashboard_manageorder",
                component: ManageOrder
            },
            {
                path: "/thailandpost_inter",
                name: "dashboard_thailandpost_inter",
                component: DashboardInfoThailandPostInter
            },
            {
                path: "/getyan",
                name: "getyan",
                component: GetYan
            },
            {
                path: "/getyanstock",
                name: "getyanstock",
                component: GetYanStock
            },
            {
                path: "/getyanvip",
                name: "getyanvip",
                component: GetYanVIP
            },
            {
                path: "/getacceptsystemyandate",
                name: "getacceptsystemyandate",
                component: GetAcceptSystemYanDate
            },
            {
                path: "/getreportnotpay",
                name: "getreportnotpay",
                component: ReportNotPay
            },
            {
                path: "/payyan",
                name: "payyan",
                component: PayYan
            },
            {
                path: "/getreportproduct",
                name: "getreportproduct",
                component: ReportProduct
            },
            
            {
                path: "/getreportcommission",
                name: "getreportcommission",
                component: ReportCommission
            },
            
            {
                path: "/getyanchangesize",
                name: "getyanchangesize",
                component: GetYanChangeSize
            },
            {
                path: "/getexcelcontact",
                name: "getexcelcontact",
                component: ExcelContact
            },
            {
                path: "/thailandpost_prepost",
                name: "dashboard_thailandpost_prepost",
                component: DashboardInfoThailandPostPrepost
            },
            {
                path: "/thailandpost_thai",
                name: "dashboard_thailandpost_thai",
                component: DashboardInfoThailandPostThai
            },
            {
                path: "/packproduct",
                name: "dashboard_packproduct",
                component: DashboardInfoPack
            },
            {
                path: "/loglinepay",
                name: "customer_loglinepay",
                component: LogLinePay
            },
            
            {
                path: "/customermission",
                name: "customer_customermission",
                component: CustomerMission
            },
            {
                path: "/shoppingcart",
                name: "customer_shoppingcart",
                component: ShoppingCart
            },
            {
                path: "/lineuser",
                name: "customer_lineuser",
                component: LineUser
            }
        ]
    },
    // {
    //     path: "/",
    //     name: "layout_left_menu",
    //     component: LayoutLeftMenu,
    //     beforeEnter: requireAuth,
    //     children: [
    //         {
    //             path: "/",
    //             name: "dashboard_info",
    //             component: DastboardInfo
    //         },
    //         {
    //             path: "/main/user/list",
    //             name: "user_list",
    //             component: UserList
    //         },
    //         {
    //             path: "/main/user/level",
    //             name: "user_level",
    //             component: UserLevel
    //         },
    //         {
    //             path: "/main/user/tag",
    //             name: "user_tag",
    //             component: UserTag
    //         },
    //         {
    //             path: "/main/user/detail/:id",
    //             name: "user_profile",
    //             component: UserProfile
    //         },
    //         {
    //             path: "/main/download",
    //             name: "download",
    //             component: DownloadMange
    //         }
    //     ]
    // },

    {
        path: "/:catchAll(.*)",
        component: Notfound
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// function requireAuth(to, from, next) {
//     next();
//     // var Cache = new CacheHelper(ConfigManager.getCacheType());
//     // Cache.getCache("msToken") == undefined ? next("/main/loginad") : next();
// }
export default router;
