import { reactive } from "vue";
export const loading = reactive({
    title: "",
    ishow: false,
    percent: 0
});

export const formStateStoreCreate = reactive({
    listproduct: [],
    listbirstname:[]

})

export const formStateStore = reactive({
    noti: {
        count: 0,
        detail: [],
        listnoti: []
    },
    mediaRecorder: null,
    videoElement: null,
    isshowpacking: false,
    updatecomponent: 0,
    isshowjason: true,
    inside: [],
    insidecod: [],
    outside: [], 
    remindinside: [],
    remindoutside: [],
    area: undefined,
    userproduct: {
        heartyanname: "",
        queyan: "1",
        professortype: [],
        deliverytype: [],
        inside: [],
        outside: [],
        insidecod: [], 
        introductionyan: "",
        isgencode: false,
        addressfrom: [],
        contactList: [],
        customerproductstype: "",
        selectcountry: { selectcountry: "TH" },
        ordertransaction: "",
        customeraddressType: "1",
        stockuuid: "",
        productsellorder_for_main_payment: "",
    },
    userproductscan: {},
    listproduct: [],
    listproductcreate: [],
    listproductlogchange: [],
    listproductstock: [],
    listcreatedelivery: [],
    pay: {
        listpay: [],
        sumaryprice: "",
        date: "",
        time: ""
    },
    pay_additional: {
        productsellorder: "",
        customerorder: "",
        date: "",
        time: ""
    },
    getcontactlist: [],
    user: {
        isgencode: false,
        addressfrom: [],
        contactList: [],
        customerproductstype: "1",
        selectcountry: { selectcountry: "TH" },
        ordertransaction: "",
        customeraddressType: "1",
        addressalllist: []
    },
    reminduser: {
        isgencode: false,
        addressfrom: [],
        contactList: [],
        customerproductstype: "1",
        selectcountry: { selectcountry: "TH" },
        ordertransaction: "",
        customeraddressType: "1",
        addressalllist: []
    },
    useraddress: {
        name: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
        key: 0,
        typeaddress: "1",
        selectcountry: "",
        selectcountrycode: "",
        ProvinceName: "",
        ProvinceID: "",
        BorderName: "",
        BorderID: "",
        DistrictName: "",
        PostCode: ""
    },
    listaddress: [],
    rowselecteddetail: null,
    btnsubmit: "",
    reportred: {},
    masterproduct: {
        isshowsteps: false
    },
    is_edit_product: ""
});
