<template>
    <div>
        <a-button type="dash" danger @click="visible = true"
            >สร้าง Tracking No.</a-button
        >
        <a-modal
            style="top: 20px"
            width="1000px"
            v-model:visible="visible"
            title="เลือกข้อมูลจัดส่ง (ลงระบบพร้อมโพส)(1.1)"
            ok-text="บันทึก"
            cancel-text="Cancel"
            @cancel="handleCancel"
        >
            <template #footer>
                <a-button key="back" @click="handleCancel">ปิด</a-button>
                <!-- <a-button v-show="!is_finish" key="submit" type="primary" :loading="loading" @click="onOk">สร้าง
                </a-button> -->
            </template>
            <!-- <pre>{{ formStateStore.reportred.mockData }}</pre> -->
            <!-- <div v-if="formStateStore?.descshot"> {{ formStateStore?.descshot }}</div> -->
            <!-- formStateStore:
            <pre>{{formStateStore.user.selectcountry.selectcountry}}</pre>
            formState:{{formState}} -->
            <!-- formState:
            <pre>{{formState}}  </pre> -->
            <a-divider orientation="left">
                <node-collapse-outlined /> ผู้รับ
            </a-divider>
            <a-form
                ref="formRef"
                :model="formState"
                layout="vertical"
                name="form_in_modal"
            >
                <a-form-item
                    name="modifier"
                    class="collection-create-form_last-form-item"
                >
                    <a-radio-group v-model:value="formState.modifier">
                        <a-radio value="private" :disabled="true"
                            >ในประเทศ
                        </a-radio>
                        <a-radio value="public" :disabled="true"
                            >ต่างประเทศ</a-radio
                        >
                    </a-radio-group>
                </a-form-item>
                <div v-show="formState.modifier == 'private'">
                    <a-form-item
                        name="FullName"
                        label="ชื่อ-นามสกุล(ผู้รับ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-input
                            :disabled="true"
                            v-model:value="formState.FullName"
                        />
                    </a-form-item>

                    <!-- <a-form-item name="Name" label="ชื่อ" :rules="[
                        {
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }
                    ]">
                        <a-input v-model:value="formState.Name" />
                    </a-form-item> -->

                    <a-form-item
                        name="Phone"
                        label="เบอร์ติดต่อ(ผู้รับ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-input
                            :disabled="true"
                            type="tel"
                            v-model:value="formState.Phone"
                        />
                    </a-form-item>
                    <a-form-item
                        name="Remark"
                        label="รายละเอียด(ผู้รับ)"
                        :rules="[
                            {
                                required: false,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-textarea
                            v-model:value="formState.Remark"
                            show-count
                            :maxlength="100"
                        />
                    </a-form-item>
                    <a-form-item
                        name="Address"
                        label="ที่อยู่(ผู้รับ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-textarea
                            :disabled="true"
                            v-model:value="formState.Address"
                        />
                    </a-form-item>
                </div>
                <div v-show="formState.modifier == 'public'">
                    <div class="p-2">
                        <a
                            href="https://dpostinter.thailandpost.com/#/label/form"
                            target="_blank"
                            >สร้างเองไปรษณีย์ไทย</a
                        >
                    </div>
                    <SelectOption
                        :formStates="formState"
                        v-model:value="formStateStore.useraddress.selectcountry"
                    >
                    </SelectOption>
                    <a-form-item
                        name="FullName"
                        label="ชื่อ-นามสกุล(ผู้รับภาษาอังกฤษ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-input v-model:value="formState.FullName" />
                    </a-form-item>

                    <a-form-item
                        name="Name"
                        label="ชื่อ"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-input v-model:value="formState.Name" />
                    </a-form-item>

                    <a-form-item
                        name="Phone"
                        label="เบอร์ติดต่อ(ผู้รับภาษาอังกฤษ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-input type="tel" v-model:value="formState.Phone" />
                    </a-form-item>
                    <a-form-item
                        name="Remark"
                        label="รายละเอียด(ผู้รับภาษาอังกฤษ)"
                        :rules="[
                            {
                                required: false,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-textarea
                            v-model:value="formState.Remark"
                            show-count
                            :maxlength="100"
                        />
                    </a-form-item>
                </div>
                <div v-show="formState.modifier == 'public'">
                    <!-- formStateStore:{{formStateStore}} -->

                    <a-form-item :name="['user', 'customerfacebook']">
                        <SelectOption
                            :formStates="formStateStore"
                            v-model:value="
                                formStateStore.useraddress.selectcountry
                            "
                        >
                        </SelectOption>
                    </a-form-item>
                    <!-- <pre>{{formStateStore}}</pre> -->
                    <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="น้ำหนัก(ตัวเลขเท่านั้น)"
                    >
                        <a-textarea
                            v-model:value="formState.weight"
                            show-count
                            placeholder="น้ำหนัก"
                            :maxlength="100"
                            @change="getapiinterweight()"
                        />
                    </a-form-item>
                    <a-form-item
                        v-if="sevice.length > 0"
                        :name="['selectcountry']"
                        label="บริการ*"
                        :rules="[{ required: true, message: 'กรุณาเลือก' }]"
                    >
                        <a-select
                            show-search
                            v-model:value="
                                formStateStore.user.selectcountry.service
                            "
                            placeholder="บริการ"
                            :options="
                                sevice.map(pro => ({
                                    value: [pro.ServiceThaiName],
                                    label: pro.ServiceThaiName
                                }))
                            "
                            @change="handleChange"
                        >
                        </a-select>
                    </a-form-item>

                    <a-form-item
                        v-if="sevice.length > 0"
                        :name="['selectcountry']"
                        label="ประเภทสิ่งของ"
                        :rules="[{ required: true, message: 'กรุณาเลือก' }]"
                    >
                        <a-select
                            ref="select"
                            v-model:value="
                                formStateStore.user.selectcountry.categoryitem
                            "
                            @focus="focus"
                            @change="handleChange"
                        >
                            <a-select-option value="31">Gift</a-select-option>
                            <a-select-option value="91"
                                >Document</a-select-option
                            >
                            <a-select-option value="21"
                                >Returned goods</a-select-option
                            >
                            <a-select-option value="32"
                                >Comercial Sample</a-select-option
                            >
                            <a-select-option value="11"
                                >Merchandise</a-select-option
                            >
                            <a-select-option value="991">Other</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item
                        name="Address"
                        label="ที่อยู่(ผู้รับภาษาอังกฤษ)"
                        :rules="[
                            {
                                required: true,
                                message: 'กรุณากรอกข้อมูล'
                            }
                        ]"
                    >
                        <a-textarea v-model:value="formState.Address" />
                    </a-form-item>

                    <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="เมือง (ผู้รับภาษาอังกฤษ)2"
                    >
                        <a-textarea
                            v-model:value="formState.citypostinter"
                            show-count
                            placeholder="เมือง"
                            :maxlength="100"
                        />
                    </a-form-item>
                    <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="จังหวัด (ผู้รับภาษาอังกฤษ)"
                    >
                        <a-textarea
                            v-model:value="formState.provincepostinter"
                            show-count
                            placeholder="จังหวัด"
                            :maxlength="100"
                        />
                    </a-form-item>
                    <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="รหัสไปรษณีย์ (ผู้รับภาษาอังกฤษ)"
                    >
                        <a-textarea
                            v-model:value="formState.postcodepostinter"
                            show-count
                            placeholder="รหัสไปรษณีย์"
                            :maxlength="100"
                        />
                    </a-form-item>
                    <a-divider orientation="left">
                        <node-expand-outlined /> ผู้ส่ง
                    </a-divider>
                    <a-form-item
                        :name="['user', 'customerfacebook']"
                        label=" เลขรหัส IOSS/VAT ID"
                    >
                        <a-textarea
                            v-model:value="formState.ioss_vatid"
                            show-count
                            placeholder="เลขรหัส IOSS/VAT ID"
                            :maxlength="100"
                        />
                    </a-form-item>

                    <a-form-item
                        :name="['selectcountry']"
                        label="ค่าเงิน"
                        :rules="[{ required: true, message: 'กรุณาเลือก' }]"
                    >
                        <a-select
                            ref="select"
                            v-model:value="
                                formStateStore.user.selectcountry.currency
                            "
                            @focus="focus"
                            @change="handleChange"
                        >
                            <a-select-option value="THB">THB</a-select-option>
                            <a-select-option value="USD">USD</a-select-option>
                            <a-select-option value="EUR">EUR</a-select-option>
                            <a-select-option value="JPY">JPY</a-select-option>
                            <a-select-option value="GBP">GBP</a-select-option>
                            <a-select-option value="AUD">AUD</a-select-option>
                            <a-select-option value="CNY">CNY</a-select-option>
                            <a-select-option value="SGD">SGD</a-select-option>
                            <a-select-option value="HKD">HKD</a-select-option>
                            <a-select-option value="CAD">CAD</a-select-option>
                            <a-select-option value="NZD">NZD</a-select-option>
                        </a-select>
                    </a-form-item>
                    <!-- <pre>
สิ่งของภายใน "หากต้องการระบุรายละเอียดสิ่งบรรจุภายในและพิก้ดศุลกากร (HS Code)</pre
                    > -->

                    <div>
                        <h4><u>ราคาสินค้า แจ้งขนส่ง</u></h4>
                    </div>
                    <div
                        class="pb-4"
                        v-for="(item, idx) in formStateStore.listcreatedelivery"
                        :key="`tab-${idx}`"
                    > 
                        <a-space direction="vertical">
                            <a-card :title="item.HeartYanName">
                                <div class="m-1">
                                    <a-select
                                        style="width:500px"
                                        show-search
                                        v-model:value="item.type_hs"
                                        placeholder="ประเภทพัสดุ"
                                        :filter-option="filterOption_hs"
                                        :options="options_hs"
                                        @change="
                                            value =>
                                                handleChange_hs(value, item)
                                        "
                                    />
                                </div>
                                <div class="m-1">
                                    <h1>ราคา</h1>
                                    <a-input
                                        type="number"
                                        v-model:value="item.input_amount"
                                    />
                                </div>
                                <div class="m-1">
                                    <h1>น้ำหนัก</h1>
                                    <a-input
                                        type="number"
                                        v-model:value="item.netWeight"
                                    />
                                </div>
                            </a-card>
                        </a-space>

                        <!-- <div style="color:green">
                            {{ idx + 1 }}.{{ item.HeartYanName }}
                        </div> -->
                        <!-- <h1>ราคา</h1> -->
                        <!-- <a-input
                            type="number"
                            v-model:value="item.input_amount"
                        />
                        <h1>น้ำหนัก</h1>
                        <a-input type="number" v-model:value="item.netWeight" /> -->
                    </div>
                    <a-form-item
                        v-if="servicethailandpost.length > 0"
                        :name="['selectcountry']"
                        label="สินค้า"
                        :rules="[{ required: true, message: 'กรุณาเลือก' }]"
                    >
                        <a-select
                            show-search
                            v-model:value="
                                formStateStore.user.selectcountry
                                    .servicethailandpost
                            "
                            placeholder="บริการ"
                            :options="
                                servicethailandpost.map(pro => ({
                                    value: [
                                        pro.VALUE,
                                        pro.CODE,
                                        pro.DESCRIPTION,
                                        pro.HSCODE_ID
                                    ],
                                    label: pro.VALUE
                                }))
                            "
                            @change="handleChange"
                        >
                        </a-select>
                    </a-form-item>
                    <!-- <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="จำนวน(ตัวเลขเท่านั้น)"
                    >
                        <a-textarea
                            v-model:value="
                                formStateStore.user.selectcountry.unit
                            "
                            show-count
                            placeholder="จำนวน"
                            :maxlength="100"
                        />
                    </a-form-item> -->
                    <!-- <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="น้ำหนัก(ตัวเลขเท่านั้น)"
                    >
                        <a-textarea
                            v-model:value="
                                formStateStore.user.selectcountry.netweight
                            "
                            show-count
                            placeholder="น้ำหนัก"
                            :maxlength="100"
                        />
                    </a-form-item> -->
                    <!-- <a-form-item
                        :name="['user', 'customerfacebook']"
                        label="ราคาสิ่งของ(ตัวเลขเท่านั้น)"
                    >
                        <a-textarea
                            v-model:value="
                                formStateStore.user.selectcountry.amount
                            "
                            show-count
                            placeholder="ราคาสิ่งของ"
                            :maxlength="100"
                        />
                    </a-form-item> -->
                    <div v-show="formState.modifier == 'public'">
                        <a-button
                            type="dash"
                            danger
                            :loading="startloading"
                            @click="onOk()"
                        >
                            <template #icon>
                                <PoweroffOutlined />
                            </template>
                            ขอ Tracking ต่างประเทศ
                        </a-button>
                    </div>
                    <div class="p-2">
                        <a-switch v-model:checked="checkedprompostcode" />
                    </div>
                    <div>
                        <a-input
                            :disabled="checkedprompostcode"
                            v-model:value="formState.prompostcode_inter"
                        />
                    </div>
                    <div v-if="posturl?.length > 0" class="pt-2">
                        <PDFviewer
                            :typepost="'public'"
                            :posturl="posturl"
                            :barcode="formState.prompostcode_inter"
                            :storeid="formState.StoreId"
                        >
                        </PDFviewer>
                    </div>
                </div>
                <Address
                    v-if="formState.modifier == 'private'"
                    :setprovince="formStateStore.user.ProvinceName"
                    :setdistricts="formStateStore.user.BorderName"
                    :setsubdistricts="formStateStore.user.DistrictName"
                    :setpostcode="formStateStore.user.PostCode"
                    @province="onprovince($event)"
                    @districts="ondistricts($event)"
                    @subdistricts="onsubdistricts($event)"
                    @postcode="onpostcode($event)"
                    :isshowinput="false"
                ></Address>
                <div v-show="formState.modifier == 'private'">
                    <a-row>
                        <a-button
                            type="dash"
                            danger
                            :loading="startloading"
                            @click="enterIconLoading"
                        >
                            <template #icon>
                                <PoweroffOutlined />
                            </template>
                            ขอ Tracking Prompost (1)
                        </a-button>
                    </a-row>
                    <div class="pt-2 mb-2">
                        <label>Tracking Prompost</label>

                        <a-typography-paragraph
                            style="float:right;"
                            class="p-2"
                            v-if="temp_prompostcode"
                            copyable
                            >{{ temp_prompostcode }}</a-typography-paragraph
                        >
                    </div>
                    <div class="pb-2">
                        <a-switch v-model:checked="checkedprompostcode" />
                    </div>
                    <div>
                        <a-input
                            :disabled="checkedprompostcode"
                            v-model:value="formState.prompostcode"
                        />
                    </div>

                    <div class="pt-2">
                        <PDFviewer
                            v-if="formState.prompostcode != null"
                            :prompostcode="formState.prompostcode"
                            :storeid="formState.StoreId"
                            :typepost="'private'"
                        >
                        </PDFviewer>
                    </div>
                </div>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import Address from "@/module/CustomerOrder/SellCreate/component/Address.vue";
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import {
    PoweroffOutlined,
    NodeExpandOutlined,
    NodeCollapseOutlined
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import PDFviewer from "@/module/Delivery/component/PDFviewer.vue";
import { formStateStore } from "@/store/storebackoffice";
import SelectOption from "@/module/Sell/SellCreate/component/form/SelectOption.vue";
import { notification } from "ant-design-vue";
import moment from "moment";
import RegxHelper from "@/helper/RegxHelper.js";
export default defineComponent({
    components: {
        NodeExpandOutlined,
        NodeCollapseOutlined,
        Address,
        PoweroffOutlined,
        PDFviewer,
        SelectOption
    },
    props: {
        sellorder: {
            type: String
        },
        tracking: {
            type: String
        }
    },
    emits: ["updatedata"],
    setup(props, ctx) {
        const checkedprompostcode = ref(false);
        moment.locale("th");
        const formatdate = birthday => {
            return moment(birthday)
                .add(543, "year")
                .format("l");
        };
        const iconLoading = ref(false);
        const formRef = ref();
        const visible = ref(false);
        const province = ref();
        const districts = ref();
        const subdistricts = ref();
        const postcode = ref();
        const prompostcode = ref();
        const formStatedetail = reactive(formStateStore);
        var productdesc = [];
        formStateStore.descshot = [];
        formStateStore.reportred.mockData.forEach(element => {
            console.log("element", element);

            var text_shot = "";
            if (
                ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน"].includes(
                    element.Category_1
                )
            ) {
                text_shot = element.shot?.replace(/\)/g, `)`);
            } else {
                text_shot = element.shot?.replace(/\)/g, `.${element.qty})`);
            }
            var detail = {
                shot: text_shot,
                birthdate: [],
                birthStr: ""
            };
            element.detail.forEach(element => {
                detail.birthdate.push(formatdate(element.birthday));
            });
            detail.birthStr = detail?.birthdate
                ?.map(dateStr => `(${dateStr})`)
                ?.toString();

            formStateStore.descshot.push(detail);
        });

        BzbsUser.apigetservicethailandpost()
            .then(res => {
                (formStateStore.user.selectcountry.currency = ""),
                    (formStateStore.user.selectcountry.servicethailandpost =
                        ""),
                    (servicethailandpost.value = res.data);
                console.log(
                    "servicethailandpost.value",
                    servicethailandpost.value
                );
            })
            .catch(error => {
                console.log(error);
            });

        const options_hs = ref([]);
        BzbsUser.apigeths()
            .then(res => {
                options_hs.value = res.data.map(item => ({
                    value: item.HSCODE_ID, // Set the value to the HSCODE_ID
                    label: `${item.DESCRIPTION}`, // Format the label as desired
                    labelEng: item.VALUE,
                    code: item.CODE
                }));
            })
            .catch(error => {
                console.log(error);
            });

        const filterOption_hs = (input, option) => {
            return option.label.toLowerCase().includes(input.toLowerCase());
        };

        setTimeout(async () => {
            console.log("formStateStore : ", formStateStore);
            var temp_name = formState.FullName;
            formState.FullName =
                formStateStore.descshot
                    .map((item, index) => {
                        const size_not_show_birthdate = [
                            "(789)",
                            "(88)",
                            "(100)"
                        ];
                        if (size_not_show_birthdate.includes(item.shot)) {
                            return `${item.shot}`;
                        } else {
                            var one_birthdate = "";
                            if (item.birthdate.length > 0) {
                                one_birthdate = `(${item.birthdate?.toString()})`;
                            }
                            if (item.birthdate?.length) {
                                return `${item.shot}${one_birthdate}`;
                            } else {
                                return `${item.shot}`;
                            }
                        }
                    })
                    ?.toString() +
                " " +
                formState.FullName;

            if (
                formState.FullName.length > 50 ||
                formStateStore.useraddress.typeaddress == "2"
            ) {
                formState.FullName = temp_name;
            }
        }, 2000);

        console.log(formStatedetail);

        const init_service_product = () => {
            BzbsUser.apigetservicethailandpost()
                .then(res => {
                    (formStateStore.user.selectcountry.currency = ""),
                        (formStateStore.user.selectcountry.servicethailandpost =
                            ""),
                        (servicethailandpost.value = res.data);
                    console.log(
                        "servicethailandpost.value",
                        servicethailandpost.value
                    );
                    // if (sevice.value.length == 0) {
                    //     var param = {
                    //         title: 'ระบบไปรษณีย์มีปัญหา',
                    //         message: 'เนื่องจาก ไม่ส่งการเลือกบริการ กรุณาลองใช้เว็บระบบไปรษณีย์',
                    //     }
                    //     openNotification(param)
                    // }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const formState = reactive({
            title: "",
            description: "",
            modifier: "private",
            // Id: "",
            StoreId: "",
            UserId: "",
            Id: "",
            // StoreId: "61fa8fbb28f7922ff832ed6b",
            // UserId: "61fa8f7928f7922ff832ed5b",
            FullName:
                formStateStore.user.customername +
                " " +
                formStateStore.user.customerlastname,
            Name: formStateStore.user.customernickname,
            Phone: formStateStore.user.customerphonethai,
            Address: formStateStore.user.addressdesc,
            District: formStateStore.user.District,
            DistrictName: formStateStore.user.DistrictName,
            Area: formStateStore.user.Area,
            BorderName: formStateStore.user.BorderName,
            Province: formStateStore.user.Province,
            Postcode: formStateStore.user.Postcode,
            ProvinceName: formStateStore.user.ProvinceName,
            Remark: formStateStore.user.Remark,
            prompostcode: formStateStore.user.prompostcode,
            provincepostinter: formStateStore.user.ProvinceName,
            citypostinter: formStateStore.user.BorderName,
            postcodepostinter: formStateStore.user.PostCode,
            weight: ""
        });

        const getinfo = async () => {
            return new Promise(resolve => {
                BzbsUser.apiprompostuserinfo().then(res => {
                    // formState.Id = res.data.Id
                    formState.StoreId = res.data.StoreId;
                    formState.UserId = res.data.Id;
                    resolve();
                });
            });
        };
        getinfo();

        if (formStatedetail.user.customeraddressType == "1") {
            formState.modifier = "private";
        } else if (formStatedetail.user.customeraddressType == "2") {
            formState.modifier = "public";
            init_service_product();
        }

        console.log("param :", formState);

        const servicethailandpost = ref([]);

        const onprovince = value => {
            province.value = value;
            console.log("province.value  : ", province.value);
        };
        const ondistricts = value => {
            districts.value = value;
            console.log("districts.value  : ", districts.value);
        };
        const onsubdistricts = value => {
            subdistricts.value = value;
            console.log("subdistricts.value  : ", subdistricts.value);
        };
        const onpostcode = value => {
            postcode.value = value;
            console.log("postcode.value  : ", postcode.value);
        };
        const onprompostcode = value => {
            prompostcode.value = value;
            console.log("prompostcode.value   : ", postcode.value);
        };
        const is_finish = ref(false);
        const updatedata = () => {
            ctx.emit("updatedata", {
                updatedata: true,
                deliverystatus: "prepare_pack"
            });
        };
        const startloading = ref(false);
        const onOk = () => {
            if (formState.modifier == "private") {
                startloading.value = true;
                formStateStore.modifier = "private";
                system_private();
            } else if (formState.modifier == "public") {
                startloading.value = true;
                formStateStore.modifier = "public";
                system_public();
            }
        };
        const posturl = ref("");
        const system_public = () => {
            var list_contentPieces = [];
            console.log(
                "formStateStore.listcreatedelivery :",
                formStateStore.listcreatedelivery
            );
            formStateStore.listcreatedelivery.forEach(element => {
                console.log(" formStateStore.user :", formStateStore.user);
                debugger;
                if (element.CustomerProductsType.includes("ยันต์")) {
                    list_contentPieces.push({
                        desc: element.type_hs,
                        tariff: element.code,
                        currency: "THB",
                        origin: "TH",
                        unit: 1,
                        netWeight: element.netWeight || 0,
                        amount: element.input_amount || 0,
                        originalLocation: "TH"
                    });
                } else {
                    list_contentPieces.push({
                        desc: element.type_hs,
                        tariff: element.code,
                        currency: "THB",
                        origin: "TH",
                        unit: 1,
                        netWeight: element.netWeight || 0,
                        amount: element.input_amount || 0,
                        originalLocation: "TH"
                    });
                }
            });
            console.log("list_contentPieces :", list_contentPieces);
            debugger;
            is_finish.value = true;

            var param = {
                item: {
                    currency: formStateStore.user.selectcountry.currency,
                    sender: {
                        address: {
                            country: "Thailand",
                            premises: "100/26 Moo1",
                            street: "Liapwari Rd Soi 59",
                            subDistrict: "KhokFaet",
                            district: "NONGCHOK",
                            province: "BANGKOK",
                            postalCode: "10530",
                            countryCode: "TH"
                        },
                        name: "NAPATSANAN PICHAYAPATTANAKUL",
                        ioss_number: formState.ioss_vatid,
                        tel: "0826652408",
                        email: "info@namahayangroup.com"
                    },
                    receiver: {
                        address: {
                            country:
                                formStateStore.user.selectcountry.selectcountry,
                            premises: formState.Address,
                            state: formState.provincepostinter,
                            city: formState.citypostinter,
                            postalCode: formState.postcodepostinter,
                            countryCode:
                                formStateStore.user.selectcountry
                                    .selectcountrycode
                        },
                        name: formState.FullName,
                        tel: RegxHelper.removeNotNumber(formState.Phone),
                        email: formStateStore.user.customeremail
                    },
                    contentPieces: list_contentPieces,
                    // contentPieces: [
                    //     {
                    //         hs: {
                    //             HSCODE_ID: parseInt(
                    //                 formStateStore.user.selectcountry
                    //                     .servicethailandpost[3]
                    //             ),
                    //             VALUE:
                    //                 formStateStore.user.selectcountry
                    //                     .servicethailandpost[0],
                    //             CODE:
                    //                 formStateStore.user.selectcountry
                    //                     .servicethailandpost[1],
                    //             DESCRIPTION:
                    //                 formStateStore.user.selectcountry
                    //                     .servicethailandpost[2]
                    //         },
                    //         origin: "TH",
                    //         unit: parseInt(
                    //             formStateStore.user.selectcountry.unit
                    //         ),
                    //         netWeight: parseInt(
                    //             formStateStore.user.selectcountry.netweight
                    //         ),
                    //         amount: formStateStore.user.selectcountry.amount,
                    //         desc:
                    //             formStateStore.user.selectcountry
                    //                 .servicethailandpost[0],
                    //         tariff:
                    //             formStateStore.user.selectcountry
                    //                 .servicethailandpost[1],
                    //         currency:
                    //             formStateStore.user.selectcountry.currency,
                    //         originalLocation: "TH"
                    //     }
                    // ],
                    weight: parseInt(formState.weight),
                    price: 100,
                    categoryItem: formStateStore.user.selectcountry.categoryitem
                },
                service: "2573"
            };
            console.log(param);
            BzbsUser.apigetprompostinter(param)
                .then(res => {
                    posturl.value = res.data[0]?.filename;
                    // const params = new URL(posturl.value).searchParams;
                    var param_outside = {
                        ThailandPostTrackingNo: res.data[0]?.barcode,
                        ThailandPostTrackingPath: posturl.value,
                        AdditionalTage: formStateStore.rowselecteddetail,
                        TrackingNo: res.data[0]?.barcode
                    };

                    console.log("param_outside : ", param_outside);
                    if (res.data != "") {
                        BzbsUser.apiupdateproductdeliverythailandposttracking(
                            param_outside
                        )
                            .then(res => {
                                startloading.value = false;
                                console.log(res.data);
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const system_private = () => {
            // formRef.value
            //     .validateFields()
            //     .then(values => {
            console.log("formStateStore", formStateStore);

            let shipping = {
                title:
                    formState.FullName +
                    " " +
                    formState.Phone +
                    " " +
                    formState.Remark,
                description: formState.Address,
                modifier: formState.modifier,
                address: {
                    province: {
                        provinceName: province.value.provinceName,
                        provinceID: province.value.provinceID
                    },
                    districts: {
                        districtsName: districts.value.districtsName,
                        districtsID: districts.value.districtsID
                    },
                    subdistricts: {
                        subdistrictsName: subdistricts.value.subdistrictsName,
                        subdistrictsID: subdistricts.value.subdistrictsID
                    },
                    postcode: postcode.value,
                    prompostcode: prompostcode.value
                }
            };
            let params = {
                ProductSellOrder: formStateStore.rowselected_productsellorder,
                Productshipping: JSON.stringify(shipping)
            };

            BzbsUser.updateshippingsellorder(params)
                .then(res => {
                    startloading.value = false;
                    console.log(res);
                    updatedata();
                })
                .catch(error => {
                    console.log(error);
                });

            visible.value = false;
            formRef.value.resetFields();
            // })
            // .catch(info => {
            //     visible.value = false;
            //     formRef.value.resetFields();
            //     console.log("Validate Failed:", info);
            // });
        };

        const temp_prompostcode = ref("");
        const enterIconLoading = () => {
            debugger;
            iconLoading.value = {
                delay: 1000
            };
            // http://localhost:8090/api/getprompost
            let ShipAddress = {
                Id: formState.Id,
                StoreId: formState.StoreId,
                UserId: formState.UserId,
                FullName: formState.FullName,
                Name: formState.Name,
                Phone: formState.Phone,
                Address: formState.Address,
                District: districts.value.districtsID,
                DistrictName: subdistricts.value.subdistrictsName,
                Area: subdistricts.value.subdistrictsID,
                BorderName: districts.value.districtsName,
                Province: province.value.provinceID,
                Postcode: postcode.value,
                ProvinceName: province.value.provinceName,
                Remark: formState.Remark
            };
            let propmpost_detail = {
                prompostcode:
                    props.tracking != undefined ? props.tracking : null
            };
            console.log("propmpost_detail : ", propmpost_detail);
            let params_obj = { ...ShipAddress, ...propmpost_detail };
            console.log("apigetprompost ", params_obj);
            BzbsUser.apigetprompost(params_obj)
                .then(res => {
                    temp_prompostcode.value = "";
                    formState.prompostcode = res.data[0].barcode;
                    temp_prompostcode.value = res.data[0].barcode;
                    success(res.data[0].barcode);

                    var param_inside = {
                        ThailandPostTrackingNo: res.data[0].barcode,
                        ThailandPostTrackingPath:
                            "https://promptpost.thailandpost.com/backend/api/stores/" +
                            formState.StoreId +
                            "/printmailings?mailingBarcode=" +
                            res.data[0].barcode +
                            "&LabelOption=10",
                        AdditionalTage: formStateStore.rowselecteddetail,
                        TrackingNo: res.data[0].barcode
                    };
                    BzbsUser.apiupdateproductdeliverythailandposttracking(
                        param_inside
                    )
                        .then(res => {
                            console.log(res.data);
                            // updatedata();
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const success = Tracking => {
            console.log("1");
            message.success("ได้รับ Tracking " + Tracking, 10);

            setTimeout(() => {
                iconLoading.value = false;
            }, 1000);
        };

        const updatedata_show_track = () => {
            ctx.emit("updatedata", {
                updatedata: true
            });
        };
        const sevice = ref([]);

        const getapiinterweight = () => {
            console.log(
                "formStateStore ",
                formStateStore.user.selectcountry.selectcountrycode
            );

            var params = {
                countrycode:
                    formStateStore.user.selectcountry.selectcountrycode,
                weight: formState.weight
            };

            BzbsUser.apiinterweight(params.countrycode, params.weight)
                .then(res => {
                    (formStateStore.user.selectcountry.service = ""),
                        (formStateStore.user.selectcountry.categoryitem = ""),
                        (sevice.value = res.data);
                    if (sevice.value.length == 0) {
                        var param = {
                            title: "ระบบไปรษณีย์มีปัญหา",
                            message:
                                "เนื่องจาก ไม่ส่งการเลือกบริการ กรุณาลองใช้เว็บระบบไปรษณีย์"
                        };
                        openNotification(param);
                    }
                    // console.log(res.data)
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const openNotification = param => {
            notification.open({
                message: param.title,
                description: param.message,
                onClick: () => {
                    console.log("Notification Clicked!");
                }
            });
        };
        watch(
            () => formState.modifier,
            () => {
                if (formState.modifier == "public") {
                    init_service_product();
                }
            }
        );
        watch(
            () => formState.prompostcode_inter,
            () => {
                if (formState.prompostcode_inter.length == 13) {
                    debugger;
                    init_get_path_inter();
                }
            }
        );

        const init_get_path_inter = () => {
            var track = formState.prompostcode_inter;
            BzbsUser.apigetpathinter(track)
                .then(res => {
                    posturl.value = res.data[0]?.ThailandPostTrackingPath;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const handleCancel = () => {
            updatedata_show_track();
            // updatedata()
            is_finish.value = false;
            visible.value = false;
        };

        onMounted(() => {
            formStateStore.listcreatedelivery.forEach(item => {
                item.input_amount = ""; // Initialize input value
                item.type_hs = "";
                item.netWeight = "";
            });

            formStateStore.listcreatedelivery.forEach(item => {
                if (item.CustomerProductsType === "ยันต์เขียนสด") {
                    item.type_hs = "Picture Frame";
                }
            });
        });

        const handleChange_hs = (value, item) => {
            debugger;
            var find = options_hs.value?.find(i => i.value == value);
            if (find) {
                item.code = find.code;
                item.type_hs = find.labelEng; // Update the item's type_hs with the selected VALUE
            }
        };
        return {
            handleChange_hs,
            filterOption_hs,
            handleCancel,
            posturl,
            servicethailandpost,
            init_service_product,
            sevice,
            getapiinterweight,
            formStateStore,
            formStatedetail,
            formState,
            formRef,
            visible,
            onOk,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            onprompostcode,
            updatedata,
            enterIconLoading,
            is_finish,
            checkedprompostcode,
            temp_prompostcode,
            startloading,
            options_hs
        };
    }
});
</script>
<style>
.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.ant-descriptions-item-label,
.ant-drawer-title {
    font-size: 14px !important;
    font-weight: 800 !important;
}
</style>
