<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="Youtube" @ok="handleOk"  @cancel="close()">
      <a-input v-model:value="value1" addon-before="Youtube URL :" />
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { value } from '@/store/store'
export default defineComponent({
  emits: ['onyoutube'],
  setup(props, { emit }) {
    const value1 = ref()

    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    showModal()
      const close =()=>{ 
      visible.value = false
      value.value = ["เลือก"]
    }
    const handleOk = e => {
      visible.value = false
      emit('onyoutube', {value:constgetId(value1.value),type:'youtube'})
      console.log(e);
      visible.value = false;
    };
  const constgetId = url => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    }
    return {
      close,
      visible,
      showModal,
      handleOk,
      value1
    };
  },

});
</script>