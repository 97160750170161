<template>
    <div>
        <a-divider orientation="left">ค่าส่งเพิ่ม(1.1)</a-divider>
        <div>
            <SearchInsertSO
                @customerselected="itemsoinsert($event)"
            ></SearchInsertSO>
        </div>
        <!-- <pre>{{ dataSource }}</pre> -->
        <!-- <pre>{{ formStateStore.user.selectedRowKeys }}</pre> -->
        <div v-if="dataSource.length != 0">
            <a-table
                :columns="columns"
                size="small"
                :data-source="dataSource"
                :key="updatetable"
                bordered
                :scroll="{ x: 0 }"
                :row-selection="rowSelection"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'key'">
                        <div>
                            รายการที่ {{ index + 1 }}
                            <span v-if="record.newaddso">
                                <a-typography-text type="success">{{
                                    record.newaddso
                                        ? "(เพิ่ม " + record.newaddso + ")"
                                        : ""
                                }}</a-typography-text>
                            </span>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'productorder'">
                        <div>
                            <a-typography-link>
                                {{ record.ProductSellOrder }}
                            </a-typography-link>
                        </div>
                    </template>
                    <template
                        v-else-if="false && column.dataIndex === 'dateorder'"
                    >
                        <div>
                            {{ formatdate(record.OrderDate) }}
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'ProductStatus'">
                        <div>
                            <a-typography-link type="danger">
                                {{ record.ProductStatus }}
                            </a-typography-link>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'statusdata'">
                        <div>
                            <a-typography-text type="danger" strong>
                                <font-awesome-icon icon="fa-solid fa-xmark" />
                                ข้อมูลไม่ครบ
                            </a-typography-text>
                        </div>
                    </template>

                    <template
                        v-else-if="false && column.dataIndex === 'contactimage'"
                    >
                        <div v-if="record.contactimage">
                            <a-image
                                :width="200"
                                :src="
                                    record.contactimage[0].preview ||
                                        record.contactimageurl
                                "
                            />
                        </div>
                        <div v-else>
                            <a-avatar shape="square" :size="64">
                                <template #icon>
                                    <UserOutlined />
                                </template>
                            </a-avatar>
                        </div>
                    </template>

                    <template
                        v-else-if="false && column.dataIndex === 'introduction'"
                    >
                        <div>
                            {{ record.introduction }}
                        </div>
                    </template>
                    <template v-else-if="false && column.dataIndex === 'start'">
                        <div>
                            <span class="m-2">
                                <a-popconfirm
                                    title="ตั้งค่าที่อยู่เริ่มต้น?"
                                    @confirm="setstart(record, index)"
                                >
                                    <a>ตั้งค่าเริ่มต้น</a>
                                </a-popconfirm>
                            </span>
                        </div>
                    </template>
                    <template
                        v-else-if="false && column.dataIndex === 'startdefault'"
                    >
                        <div
                            :style="{ margin: 'auto', 'text-align': 'center' }"
                        >
                            <!-- <check-circle-outlined v-if="record.contactstartdefault == 'true'"
                                :style="{ fontSize: '32px', color: 'green' }" />
                            <minus-circle-outlined v-if="
                                record.contactstartdefault == undefined ||
                                    record.contactstartdefault == 'false'
                            " :style="{ fontSize: '32px', color: 'red' }" /> -->
                            <div class="text-center">
                                <font-awesome-icon
                                    v-if="record.contactstartdefault == 'true'"
                                    icon="fa-solid fa-check"
                                    center
                                />
                            </div>
                            <div class="text-center">
                                <font-awesome-icon
                                    v-if="
                                        record.contactstartdefault ==
                                            undefined ||
                                            record.contactstartdefault ==
                                                'false'
                                    "
                                    icon="fa-solid fa-xmark"
                                    center
                                />
                            </div>
                        </div>
                    </template>
                    <template
                        v-else-if="false && column.dataIndex === 'operation'"
                    >
                        <div class="editable-row-operations">
                            <span v-if="editableData[record.key]">
                                <a-typography-link @click="save(record.key)"
                                    >บันทึก</a-typography-link
                                >
                                <a-popconfirm
                                    title="Sure to cancel?"
                                    @confirm="cancel(record.key)"
                                >
                                    <a>ยกเลิก</a>
                                </a-popconfirm>
                            </span>
                            <span v-else class="m-2">
                                <setting-outlined
                                    :style="{
                                        fontSize: '18px',
                                        color: '#08c',
                                        padding: '10px'
                                    }"
                                />
                                <a @click="edit(record.key)">แก้ไข</a>
                            </span>
                        </div>
                    </template>
                </template>
            </a-table>
            <a-button
                type="primary"
                :disabled="!hasSelected"
                :loading="loading"
                @click="start"
            >
                ถัดไป
            </a-button>
        </div>
        <TableLoad v-else :isload="true"></TableLoad>
        <!-- //ตาราง -->
    </div>
</template>
<script>
import {
    MinusCircleOutlined,
    CheckCircleOutlined,
    SettingOutlined
} from "@ant-design/icons-vue";
import {
    defineComponent,
    reactive,
    ref,
    watch,
    onMounted,
    toRefs,
    computed
} from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { UserOutlined } from "@ant-design/icons-vue";
import { ContactModel } from "@/core/Create/model/CreateModel";
import moment from "moment";

import TableLoad from "@/components/Skeleton/TableLoad";
import "moment/locale/th";
import { formStateStore } from "@/store/storebackoffice.js";
import SearchInsertSO from "@/module/Sell/SellCreate/component/form/SearchInsertSO.vue";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "key"
    },
    {
        title: "รายการขาย",
        dataIndex: "productorder"
    },
    // {
    //     title: "วันที่สั่งสินค้า",
    //     dataIndex: "dateorder"
    // },

    {
        title: "สถานะ",
        dataIndex: "ProductStatus"
    }
    // {
    //     title: "สถานะข้อมูล(1.2)",
    //     dataIndex: "statusdata"
    // },
    // {
    //     title: "ผู้รับผิดชอบ",
    //     dataIndex: "staff"
    // }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        SettingOutlined,
        TableLoad,
        // SelectOption,
        // Address,
        UserOutlined,
        MinusCircleOutlined,
        CheckCircleOutlined,
        SearchInsertSO
    },
    props: {
        textbtn: null,
        formStates: null,
        rowselected: null
    },
    setup(props, { emit }) {
        const updatetable = ref(0);
        const rowselecteddetail = reactive(props.rowselected);
        const visible = ref(false);
        const visibleEdit = ref(false);

        const showModal = () => {
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const radioStyle = reactive({});
        const productfrom = reactive({});
        const formState = reactive({
            contact: {
                sights: []
            },
            useraddress: {
                key: 0,
                typeaddress: "1",
                selectcountry: "Thailand"
            },
            productlist: [],
            listproduct: []
        });
        const list = reactive({
            listproduct: []
        });
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    )
                ) {
                    formState.useraddress.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    );
                }
            }
        );
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            // if (visibleEdit.value) {
            //   formState.listproduct[editIndex.value] = formState.useraddress;
            // } else {
            //   let obj = {};
            //   obj = formState.useraddress;
            //   obj.key = list.listproduct.length == 0 ? 0 : list.listproduct.length;
            //   console.log("obj.key", obj.key);
            //   console.log("obj", obj);
            //   list.listproduct[obj.key] = obj;
            //   console.log("list.listproduct", list.listproduct);
            // }
            emit("update:value", formState);
            formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };
        const dataSource = ref([]);
        // if (props.formStates.getcontactlist.length > 0) {
        //     dataSource.value = props.formStates.getcontactlist;
        // }
        const editableData = reactive({});
        const editIndex = ref();
        const edit = key => {
            editIndex.value = key;
            // visibleEdit.value = true;
            showModal();
        };

        const save = key => {
            Object.assign(
                dataSource.value.filter(item => key === item.key)[0],
                editableData[key]
            );
            delete editableData[key];
        };

        const cancel = key => {
            console.log(key);
        };
        const setstart = (record, key) => {
            if (props.formStates.getcontactlist.length > 0) {
                formState.contact.sights = props.formStates.getcontactlist;
            }
            let index_select = parseInt(key);
            formState.contact.sights.forEach((element, index) => {
                if (index == index_select) {
                    formState.contact.sights[index].contactstartdefault =
                        "true";
                } else {
                    formState.contact.sights[index].contactstartdefault =
                        "false";
                }
            });
            //  updatelist(record)
        };
        const updatelist = async record => {
            let params = [];
            // formState.contact.sights.forEach(element => {
            //     var obj = new ContactModel(record);
            //     console.log("obj", obj);
            //     params.push(obj);
            // });

            console.log("param", params);

            // for await (let obj of params) {
            await BzbsUser.apiupdatecontact(new ContactModel(record))
                .then(res => {
                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
            // }
        };
        var count = 1;
        const handleclosemodal = e => {
            // eslint-disable-next-line vue/no-mutating-props
            props.formStates.getcontactlist = formState.contact.sights;
            dataSource.value = formState.contact.sights;
            visible.value = e;
            const product = {};
            product.key = count;
            product.inside = formState.productlist.inside;
            product.insidecod = formState.productlist.insidecod;
            product.outside = formState.productlist.outside;
            product.userproduct = formState.productlist.userproduct;
            product.sellorderTransaction =
                formState.productlist.sellorderTransaction;
            product.orderdate = formState.productlist.orderdate;

            list.listproduct.push(product);
            count = count + 1;
            emit("update:value", formState.contact.sights);
        };

        onMounted(() => {
            getCustomerProductlist();
        });

        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const getCustomerProductlist = async () => {
            console.log("getCustomerProductlist");
            await BzbsUser.apiGetCustomerProductbyCustomercode(
                rowselecteddetail.rowselected
            )
                .then(res => {
                    console.log("CustomerProductbyCustomercode", res.data[0]);

                    const arr1 = getUniqueListBy(
                        res.data[0],
                        "ProductSellOrder"
                    );
                    arr1.forEach(function(element, index) {
                        element.key = index;
                    });
                    console.log("arr1 : ", arr1);

                    return (dataSource.value = arr1?.reverse());
                })
                .catch(error => {
                    console.log(error);
                });
        };
        // ลบยันต์เก่า เอาแต่ เพิ่มขนาด เท่านั้น ถ้ามีการเพิ่มขนาดจะกรองเอาแต่ยันเพิ่มขนาด
        const removeNormalFromIncreateYanByOrderTransaction = arr => {
     
            // Iterate through the array
            for (let i = 0; i < arr.length; i++) {
                const currentObj = arr[i];

                if (currentObj.OrderTransactionFrom) {
                    const currentOrderTransactionFrom =
                        currentObj.OrderTransactionFrom;

                    // Check if OrderTransactionFrom matches OrderTransaction in any other object
                    var indexdelete = null;
                    const hasMatchingTransaction = arr.some((obj, index) => {

                        if(index !== i &&
                            obj.OrderTransaction !== currentOrderTransactionFrom){ 
                            indexdelete = index
                        }
                        return (
                            index !== i &&
                            obj.OrderTransaction !== currentOrderTransactionFrom
                        );
                    });

                    // console.log("i",i)
                    // If a matching transaction is found, remove the current object
                    if (hasMatchingTransaction) {
                        arr.splice(indexdelete, 1);
                        // Adjust the loop counter to account for the removed object
                        i--;
                    }
                }
            }

            return arr;
        };
        const AddSOCustomerProductlist = async cusid => {
            console.log("getCustomerProductlist");
            await BzbsUser.apiGetCustomerProductbyCustomercode(cusid)
                .then(res => {
                    console.log("CustomerProductbyCustomercode", res.data[0]);
                    const arr1 = getUniqueListBy(
                        res.data[0],
                        "ProductSellOrder"
                    );
                    arr1.forEach(function(element, index) {
                        element.key = index;
                    });
                    // arr1[0].key = dataSource.value.length
                    // arr1[0].newaddso = arr1[0].ContactName
                    var list_size = dataSource.value.length;
                    arr1.forEach((element, index) => {
                        element.key = list_size + index;
                        element.newaddso = element.ContactName;
                        dataSource.value.push(element);
                    });
                    // formStateStore.user.selectedRowKeys.push(arr1[0].ProductSellOrder)

                    updatetable.value++;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const clickSO = so => {
            emit("selectedsellorder", so);
        };
        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const state = reactive({
            selectedRowKeys: [],
            // Check here to configure the default column
            loading: false
        });
        const hasSelected = ref(false);
        const start = () => {
            state.loading = true;
            // ajax request after empty completing
            setTimeout(() => {
                state.loading = false;
                // state.selectedRowKeys = [];
                clickSO();
            }, 1000);
        };
        // const onSelectChange = (selectedRowKeys, record) => {
        //
        //     console.log('selectedRowKeys changed: ', selectedRowKeys);
        //     state.selectedRowKeys = selectedRowKeys;

        //     emit("selectedRowKeys", state.selectedRowKeys);
        //     console.log("recode : ", record)
        //     formStateStore.user.selectedRowKeys = []
        //     record.forEach(element => {
        //         formStateStore.user.selectedRowKeys.push(element.ProductSellOrder)
        //     });

        // };
        const rowSelection = {
            onSelectChange: selectedRowKeys => {
                console.log("selectedRowKeys changed: ", selectedRowKeys);
                state.selectedRowKeys = selectedRowKeys;
            },
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(
                    `selectedRowKeys: ${selectedRowKeys}`,
                    "selectedRows: ",
                    selectedRows
                );

                // emit("selectedRowKeys", state.selectedRowKeys);
                console.log("recode : ", selectedRows);
                formStateStore.user.selectedRowKeys = [];
                selectedRows.forEach(element => {
                    formStateStore.user.selectedRowKeys.push(
                        element.ProductSellOrder
                    );
                });
                if (selectedRowKeys.length > 0) {
                    state.selectedRowKeys = selectedRowKeys;
                    hasSelected.value = true;
                }
            },
            getCheckboxProps: record => ({
                disabled: record.ProductStatus === "cancel",
                // Column configuration not to be checked
                name: record.ProductStatus
            })
        };

        const itemsoinsert = e => {
            // formStateStore.user.selectedRowKeys.push(e.CustomerCode)

            AddSOCustomerProductlist(e.CustomerCode);
        };

        return {
            itemsoinsert,
            rowSelection,
            formStateStore,
            formatdate,
            clickSO,
            getCustomerProductlist,
            updatelist,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            edit,
            save,
            cancel,
            editIndex,
            list,
            productfrom,
            handleclosemodal,
            setstart,
            rowselecteddetail,
            hasSelected,
            ...toRefs(state),
            start,
            computed,
            updatetable
            // onSelectChange,
        };
    }
});
</script>
