<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="Timer" @ok="handleOk" @cancel="close()">
      <div class="text-center">
        <div class="pb-4">
          จบกิจกรรม วันที่-เวลา
        </div>
        <div>{{ resultdatefinish }}</div>
        <a-space>
          <div class="pt-3">
            <h1>ข้อความหัวข้อ -เริ่ม</h1>
            <a-textarea v-model:value="startmessage" placeholder="ข้อความ ตอนรัน Promotion" allow-clear />
          </div>
          <div class="pt-3">
            <h1>ข้อความหัวข้อ -จบ</h1>
            <a-textarea v-model:value="endmessage" placeholder="ข้อความ ตอนจบ Promotion" allow-clear />
          </div>
        </a-space>

        <div class="pt-3">
          <h1>วันที่ จบ</h1>
          <a-date-picker v-model:value="enddate" :format="'YYYY-MM-DD'" />
        </div>

        <div class="pt-3">
          <h1>เวลา จบ</h1>
          <a-time-picker v-model:value="endtime" v-model:open="open" @openChange="handleOpenChange">
          </a-time-picker>
        </div>
      </div>

      <!-- <a-input v-model:value="value1" @change="onchangelink" addon-before="Youtube URL :" /> -->
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { onEdit, value } from '@/store/store'
import dayjs from "dayjs";
export default defineComponent({
  emits: ['onyoutube'],
  setup(props, { emit }) {
    const resultdatefinish = ref('');
    const selectedDate = ref(null);
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
    const value1 = ref()
    const enddate = ref()
    const endtime = ref()
    const startmessage = ref("")
    const endmessage = ref("")
    if (onEdit.value.isshowedit) {
      console.log("onEdit : ", onEdit)

      // ref(dayjs(res.data[0].date, "YYYY-MM-DD")) || '';
      startmessage.value = onEdit.value.editobj.detail.value.startmessage;
      endmessage.value = onEdit.value.editobj.detail.value.endmessage;
      enddate.value = dayjs(new Date(onEdit.value.editobj.detail.value.timer));
      endtime.value = dayjs(new Date(onEdit.value.editobj.detail.value.timer));
      // value1.value = 'https://www.youtube.com/embed/' + onEdit.value.editobj.detail.value
    }
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };
    showModal()
    const close = () => {
      visible.value = false
      value.value = ["เลือก"]
      onEdit.value.isshowedit = false
    }
    const handleOk = e => {
       
      resultdate()

      // if (enddate.value == null) {
      //   alert("กรอกไม่ครบ")
      //   return
      // }
      // if (endtime.value == null) {
      //   alert("กรอกไม่ครบ")
      //   return
      // }
      if (resultdatefinish.value == '') {
        alert("กรอกไม่ครบ")
        return
      }
       

      if (onEdit.value.isshowedit) {
        onEdit.value.editobj.detail.value = { timer: resultdatefinish.value, startmessage: startmessage.value, endmessage: endmessage.value }

        value.value = ["เลือก"]
        onEdit.value.isshowedit = false

        visible.value = false
        // emit('onyoutube', { value: constgetId(value1.value), type: 'youtube' })
        // value.value = ["เลือก"] 
        // var code = constgetId(value1.value);
        // try {
        //   emit('onyoutube', { value: code, type: 'youtube' })
        // } catch (error) {
        //   console.log(error)
        // } 
      } else {
        visible.value = false
        onEdit.value.isshowedit = false
        emit('ontimer', { value: { timer: resultdatefinish.value, startmessage: startmessage.value, endmessage: endmessage.value }, type: 'timer' })
        console.log(e);
      }

    };
    const constgetId = url => {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;
    }
    const onchangelink = () => {

      onEdit.value.editobj.detail.value = constgetId(value1.value)

    };


    const resultdate = () => {
      const dateA = new Date(enddate.value);
      const dateB = new Date(endtime.value);

      // Format date A with only the date part (YYYY-MM-DD)
      const formattedDateA = dateA.toISOString().slice(0, 10);

      // Format date B with only the time part (HH:mm:ss)
      const formattedTimeB = dateB.toTimeString().slice(0, 8);

      // Combine date A and time B in the 'YYYY-MM-DDTHH:mm:ss' format
      const result = `${formattedDateA}T${formattedTimeB}`;

      resultdatefinish.value = result

    }
    const open = ref(false);
    const handleClose = () => {

      open.value = false;
      resultdate()
    };
    const handleOpenChange = openStatus => {
      open.value = openStatus;
    };
    return {
      handleOpenChange,
      open,
      handleClose,
      onchangelink,
      close,
      visible,
      showModal,
      handleOk,
      value1,
      onEdit,
      enddate,
      endtime,
      selectedDate,
      dateFormat,
      resultdatefinish,
      startmessage,
      endmessage
    };
  },

});
</script>