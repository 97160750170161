<template>
    <div>
      <div class="text-sm" :class="[isError ? 'text-red-500' : 'text-gray-800']">
        {{ inputLbl.name }}
      </div>
  
      <div class="relative">
        <div class="flex flex-col sm:flex-row"> <!-- Use flex-col for mobile, and flex-row for larger screens -->
          <div class="pr-2">
            <InputDropdown ref="dropdown-day" v-model:inputValue="dayInput" :itemList="dayList"
                           :errorInfo="errorInfoDate[0]" :isFirstSave="isFirstSave" :isShowErrorLbl="isShowErrorLbl"
                           :inputLbl="inputLbl.date" @update:inputValue="onValueUpdate()"></InputDropdown>
          </div>
          <div class="pr-2">
            <InputDropdown ref="dropdown-month" v-model:inputValue="monthInput" :itemList="monthList"
                           :errorInfo="errorInfoDate[1]" :isFirstSave="isFirstSave" :isShowErrorLbl="isShowErrorLbl"
                           :inputLbl="inputLbl.month" @update:inputValue="onValueUpdate()"></InputDropdown>
          </div>
          <div  class="pr-2">
            <InputDropdown ref="dropdown-year" v-model:inputValue="yearInput" :itemList="yearList"
                           :errorInfo="errorInfoDate[2]" :isFirstSave="isFirstSave" :isShowErrorLbl="isShowErrorLbl"
                           :inputLbl="inputLbl.year" @update:inputValue="onValueUpdate()"></InputDropdown>
          </div>
        </div>
        <!-- error msg -->
        <div class="text-red-500 text-sm absolute bottom-0 left-0" v-if="isError">
          {{ getErrorMsg }}
        </div>
      </div>
    </div>
  </template>
<script>
import moment from "moment"
import Util from "@/helper/Utility.js";
import Locale from "@/helper/locale/Locale.js";
import { FormHelper, DropdownListValue } from "@/helper/FormHelper.js";
import DatetimeHelper from "@/helper/DatetimeHelper.js";
//components
import InputDropdown from "./InputDropdown.vue";

/**
 * @property {String} v-model:inputValue (required) bind value
 * @property {Object} errorInfo (required) Validate result. example : { isValid: true, msg: '',}
 * @property {Boolean} isFirstSave (optional) Show/Hide Error after save.
 * @property {Boolean} isShowErrorLbl (optional) Show/Hide Error label.
 * @property {Object} inputLbl (required) Text for component.
 */
export default {
    name: "DatePickerDropdown",
    components: {
        InputDropdown,
    },
    props: {
        //v-model
        inputValue: { type: [Date, String, Number] },
        //props
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        isISO_8601: { type: Boolean, default: false },
        //text
        inputLbl: { type: Object, required: true },
    },
    data: function () {
        return {
            dayInput: null,
            monthInput: null,
            yearInput: null,

            dayList: [],
            monthList: [],
            yearList: [],

            errorInfoDate: FormHelper.genErrorInfo(3),
        };
    },
    computed: {
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
    },
    created: function () {
        this.setDayList();
        this.setMonthList();
        this.setYearList();
    },
    mounted: function () {
        this.setDateInitial();
        this.onValueUpdate();
    },
    methods: {
        //#region setup
        setDayList: function () {
            let dayListItem = new DropdownListValue();

            for (let i = 1; i <= 31; i++) {
                let d = (i < 10) ? '0' + i : '' + i;
                dayListItem.add(d, i + '');
            }
            this.dayList = dayListItem.getList();
        },
        setMonthList: function () {
            let monthListItem = new DropdownListValue();

            let lbl = Locale.getLbl();
            let months = lbl.date.month_full;
            for (let i in months) {
                let index = parseInt(i, 10) + 1 + '';
                // let index = parseInt(i, 10) + '';
                monthListItem.add(months[i], index);
            }
            this.monthList = monthListItem.getList();
        },
        setYearList: function () {
            let yearListItem = new DropdownListValue();

            let currentYear = parseInt(new Date().getFullYear()+10, 10);
            let yearText = currentYear;
            let localeCode = Locale.getLocaleCode();

            if (localeCode == 1054) {
                yearText = yearText + 543;
            }

            for (let i = 0; i < 100; i++) { 
                var yearEN= (yearText - i)-543 
                yearListItem.add(yearText - i + '' +'    (ค.ศ.'+yearEN+')', currentYear - i + '');
            }
            this.yearList = yearListItem.getList();
        },
        setDateInitial: function () {
            if (Util.isEmptyStr(this.inputValue)) {
                return;
            }
          

            let date = null;
            let checkDate = this.inputValue.toString();
            var formats = [
                moment.ISO_8601,
                "MM/DD/YYYY  :)  HH*mm*ss"
            ];
            if (this.inputValue instanceof Date) {
                date = this.inputValue;
            } else if (checkDate.match(/^[0-9]+$/)) {
                date = DatetimeHelper.unixTimestampToDate(this.inputValue);
            }
            else if (moment(this.inputValue, formats, true).isValid()) {
                
     
                date = new Date(moment(this.inputValue).format('YYYY-MM-DD'));
            }
            else {
                date = DatetimeHelper.stringToDate(this.inputValue, 'YYYY-MM-DD');
            }

            let d = date.getDate();
            let m = date.getMonth() + 1;
            let y = date.getFullYear();

            this.dayInput = d + '';
            this.monthInput = m == 0 ? '0' : m + '';
            this.yearInput = y + '';

            this.$refs['dropdown-day'].setValue(this.dayInput);
            this.$refs['dropdown-month'].setValue(this.monthInput);
            this.$refs['dropdown-year'].setValue(this.yearInput);

            this.validateInput();
        },
        //#endregion setup

        validateInput: function () {
            let dateValidate = FormHelper.validate(this.errorInfoDate);
         

            this.errorInfo.setValid(dateValidate);
            this.errorInfo.setMsg(this.inputLbl.required);
            return dateValidate;
        },

        onValueUpdate: function () { 
            if (this.validateInput()) {
                this.checkDateCorrect();

                let inputDate = DatetimeHelper.getDateByValue(this.yearInput, this.monthInput, this.dayInput);
                let timestamp = DatetimeHelper.convertDateToTimeStampUnix(inputDate);
                let formated = DatetimeHelper.dateFullFormat(inputDate);
                let value = {
                    date: inputDate,
                    timestamp: timestamp,
                    formated: formated,
                };
                if (this.isISO_8601) {
                    this.$emit("update:inputValue", moment(inputDate).format());
                    this.$emit("onDateChange", value);
                }
                else {
                    this.$emit("update:inputValue", timestamp);
                    this.$emit("onDateChange", value);
                }

            }
        },
        checkDateCorrect: function () {
            if (!DatetimeHelper.isDatetimeValid(this.yearInput, this.monthInput, this.dayInput)) {
                let correctDay = DatetimeHelper.getEndOfMonthDay(this.yearInput, this.monthInput);
                this.dayInput = correctDay;
                this.$refs['dropdown-day'].setValue(correctDay);
            }
        },
        
    },
}
</script>
<style lang="scss" scoped>

</style>
