<template>
    <div>
        <TaxAddressItem
                v-for="(address, k) in addressList"
                :address="address"
                :key="k"
                @onDeleteAddress="onDeleteAddress($event)"
                @onEditAddress="onEditAddress($event)"
        ></TaxAddressItem>

        <div v-if="isLoaded && addressList.length == 0">
            <div class="text-lg font-bold text-gray-300 text-center py-5">
                No Address
            </div>
        </div>
    </div>
</template>
<script>
import ErrorApiMixin from "@/mixin/ErrorApiMixin.js";
// import AddressListItem from "./AddressListItem.vue";
import TaxAddressItem from "./TaxAddressItem.vue";
import BzbsAddressList from "@/core/Address/service/BzbsAddressList.js";

/**
 * @event onDeleteAddress
 * @event onEditAddress
 */
export default {
    name: "TaxAddressList",
    mixins: [ErrorApiMixin],
    components: {
        TaxAddressItem,
    },
    data: function() {
        return {
            addressList: [],
            isLoaded: false,
        };
    },
    mounted: function() {
        this.getAddressList();
    },
    methods: {
        getAddressList: function() {
            BzbsAddressList.apiGetTaxAddressList().subscribe((addressList) => {
                console.log(addressList);
                this.addressList = addressList;
                this.isLoaded = true;
            }, (err) => {
                this.isLoaded = true;
                this.errorApi(err);
            });
        },

        onDeleteAddress: function(address) {
            this.$emit('onDeleteAddress', address);
        },
        onEditAddress: function(address) {
            this.$emit('onEditAddress', address);
        },
    },
}
</script>
