import _ from 'lodash'
import moment from 'moment'
import CacheHelper from '@/helper/CacheHelper.js';
import ConfigManager from '@/config/ConfigManager.js';
export default {
  data() {
    return {
      pageTitle: 'Buzzebees BackOffice',
      lbl: null,
    }
  },
  computed: {
    code: {
      get() {
        return this.$store.state.localecode
      },
    },
  },
  watch: {
    // code(newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     this.getlocalStorage()
    //   }
    // },
  },
  created() {
    this.getlocalStorage()
  },
  methods: {
    getlocalStorage() {
        var cacheHelper = new CacheHelper(ConfigManager.getCacheType());
      var locale = cacheHelper.getCache('BzbsLocale')
      if (localStorage.getItem('setJsonLocal_' + locale)) {
        this.lbl = JSON.parse(localStorage.getItem('setJsonLocal_' + locale))
      }
    },
    truncate(num, places) {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places)
    },
    errorResponse(objerror, isPopup, router) {
      var error_message = this.lbl.main.something_went_wrong
      var texterror = []
      if (_.has(objerror, 'response.data.error')) {
        if (
          objerror.response.data.error !== null &&
          objerror.response.data.status_code != 999
        ) {
          const h = this.$createElement
          for (var i = 0; i < objerror.response.data.error.length; i++) {
            texterror.push(
              h(
                'p',
                ' ( ID: ' +
                  objerror.response.data.error[i].id +
                  ' ) ' +
                  objerror.response.data.error[i].message,
              ),
            )
          }
          error_message = texterror
        }
      }
      if (typeof objerror === 'string') {
        error_message = objerror
      }
      if (isPopup) {
        this.handleLoading(false)
        if (router) {
          this.$error({
            title: this.lbl.main.pls_try_agian,
            content: error_message,
            centered: true,
            autoFocusButton: null,
            okText: this.lbl.btn.ok,
            onOk: () => {
              this.$router.push(router)
              this.handleLoading(true)
            },
          })
        } else {
          this.$error({
            title: this.lbl.main.pls_try_agian,
            content: error_message,
            centered: true,
            autoFocusButton: null,
            okText: this.lbl.btn.ok,
            onOk: () => {},
          })
        }
      } else {
        this.$message.error(error_message)
      }
      return error_message
    },
    handleLoading(value) {
      this.$store.commit('Loading', value)
    },
    // for ant select search option
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    formatDate(timestamp, format = 'DD-MMM-YYYY') {
      return moment.unix(timestamp).format(format)
    },
    formatDateString(dateString, format = 'DD-MMM-YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formHasError(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field])
    },
    successResponse(text, router) {
      this.handleLoading(false)
      var texttitle = ''
      if (text) {
        texttitle = text
      } else {
        texttitle = this.lbl.main.success_popup
      }
      this.$success({
        title: texttitle,
        content: '',
        centered: true,
        autoFocusButton: null,
        closable: true,
        onOk: () => {
          if (typeof router !== 'undefined') {
            if (!(typeof router === 'boolean')) {
              this.$router.push(router)
              this.handleLoading(true)
            }
          } else {
            this.$router.go()
            this.handleLoading(true)
          }
        },
      })
    },
    parseInt(value) {
      if (typeof value === 'string') {
        return parseInt(value)
      } else if (typeof value === 'number') {
        return value
      }
    },
  },
}
