import Account from '@/helper/AccountHelper.js';
import ConfigManager from '@/config/ConfigManager.js';
import BzbsApi from '@/core/Http/BzbsApi.js';
import {
    // ProfileParamsBuilder,
    AddressParamsBuilder,
    ShippingAddressParamsBuilder,
    ProfileModel,
    ProfileAddressModel,
    ProfileShippingAddressModel
} from '@/core/Account/model/AccountModel.js';
import { map } from 'rxjs/operators';

/**
 * @method getProfile
 * @method getPoint
 * @method updateProfile
 * @method updateShippingAddress
 * @method registerUser
 * @method changePassword
 * @method registerLine
 */
export default {
    /**
     * Get user profile.
     */
    // TODO: Revise
    getProfile: function () {
        const token = Account.getToken();
        const url = ConfigManager.getApiUrl() + '/profile/me';
        return BzbsApi.connectBzbsGet(url, token)
            .pipe(map((res) => {
                const profile = new ProfileModel(res.data);
                const profileAddress = new ProfileAddressModel(res.data);
                const profileShippingAddress = new ProfileShippingAddressModel(res.data);

                const profileData = {
                    profile: profile,
                    address: profileAddress,
                    shippingAddress: profileShippingAddress,
                };

                return profileData;
            }));
    },

    /**
     * Get point.
     */
    // TODO: Revise
    getPoint: function () {
        const token = Account.getToken();
        const url = ConfigManager.getApiUrl() + '/profile/me/updated_points';
        return BzbsApi.connectBzbsGet(url, token, null)
            .pipe(map((res) => {
                var pointObject = res.data;
                return pointObject;
            }));
    },

    /**
     * Update user profile.
     * @param {object} objProfileInfo
     * @param {object} objProfileAdress
     * @param {string} strExtendJson
     * @param {string} strUploadKey
     * @param {object} objUpload
     */
    // TODO: Revise
    updateProfile: function (profile, profileAddress, strExtendJson, strUploadKey, objUpload) {
        const token = Account.getToken();
        // var profileParams = new ProfileParamsBuilder();
        // var params = profileParams.getParams(profile);
        var params = profile.getParams();
        if (profileAddress) {
            var addressParams = new AddressParamsBuilder();
            var addr = addressParams.getParams(profileAddress)
            params = { ...params, ...addr };
        }

        if (strExtendJson)
            params.extendjsonstring = strExtendJson;

        const url = ConfigManager.getApiUrl() + '/profile/me';
        return BzbsApi.connectBzbsPost(url, token, params, strUploadKey, objUpload).pipe(map((res) => {
            const profile = new ProfileModel(res.data);
            const profileAddress = new ProfileAddressModel(res.data);
            const profileShippingAddress = new ProfileShippingAddressModel(res.data);

            const profileData = {
                profile: profile,
                address: profileAddress,
                shippingAddress: profileShippingAddress
            };

            return profileData;
        }));
    },

    /**
     * Update user shipping address.
     * @param {object} profileShippingAddress
     */
    updateShippingAddress: function (profileShippingAddress) {
        const token = Account.getToken();
        const shippingAddressBuilder = new ShippingAddressParamsBuilder();
        var params = shippingAddressBuilder.getParams(profileShippingAddress);

        const url = ConfigManager.getApiUrl() + '/profile/me/shipping';
        return BzbsApi.connectBzbsPost(url, token, params).pipe(map((res) => {
            return res.data;
        }));
    },

    // TODO: Relocate
    registerUserWithParams: function (params) {
        params.app_id = ConfigManager.getAppId();

        const url = ConfigManager.getApiUrl() + '/auth/register';
        return BzbsApi.connectBzbsPost(url, null, params, null, null).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * Change bzbs password
     * @param {string} strCurrent Current password
     * @param {string} strChange New password
     */
    changePassword: function (strCurrent, strChange) {
        const token = Account.getToken();
        const params = {
            current: strCurrent,
            change: strChange,
        };

        const url = ConfigManager.getApiUrl() + '/profile/me/change_password';
        return BzbsApi.connectBzbsPost(url, token, params, null, null).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * @param {ProfileParamsBuilder} params (required) /core/Account/model/AccountModel.js
     * @param {string} lineIdToken (required)
     * @param {string} refcode (optional)
     * @param {string|number} otp (optional)
     */
    // TODO: Relocate
    registerLine: function (lineIdToken, refcode, otp) {
        var params = {};
        if (lineIdToken) {
            params.id_token = lineIdToken;
        }
        if (refcode && otp) {
            params.refcode = refcode;
            params.otp = otp;
        }
        const url = ConfigManager.getApiUrl() + '/line/register';
        return BzbsApi.connectBzbsPost(url, null, params, null, null).pipe(map((res) => {
            return res.data;
        }));
    },

    /**
     * Change Contact number
     * @param {string} contact_number New contact number
     * @param {string} refcode
     * @param {string} otp
     */
    changeContactNumber: function (contact_number, refcode, otp) {
        const token = Account.getToken();
        const params = {
            contact_number: contact_number,
            refcode: refcode,
            otp: otp
        };

        const url = ConfigManager.getApiUrl() + '/profile/me/contact_number';
        return BzbsApi.connectBzbsPost(url, token, params, null, null).pipe(map((res) => {
            return res.data;
        }));
    },
}

