<template>
    <pre>{{dataSources}}</pre>
    <div v-if="dataSources" class="relative p-4 wrapper-track">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <a-steps direction="vertical" :current="current_status">
            <a-step title="ชื่อผู้รับ">
                <template #description>
                    <div>

                        <font-awesome-icon icon="fa-solid fa-user" class="pr-2" /> Tracking : {{
                        dataSources[0].Barcode }}
                        <br>
                        <font-awesome-icon icon="fa-solid fa-user" class="pr-2" /> ชื่อคุณ {{
                        dataSources[0].ShipAddress.FullName }}
                        <br>
                        <font-awesome-icon icon="fa-solid fa-phone" class="pr-2" />เบอร์โทร {{
                        dataSources[0].ShipAddress.Phone }}
                        <hr />
                        <br>
                        <ul>
                            <font-awesome-icon icon="fa-solid fa-address-card" class="pr-2" />ที่อยู่
                            <li v-show="dataSources[0]?.ShipAddress.ProvinceName">จ.{{
                            dataSources[0]?.ShipAddress.ProvinceName }}</li>
                            <li v-show="dataSources[0]?.ShipAddress.BorderName">อ.{{
                            dataSources[0]?.ShipAddress.BorderName }}</li>
                            <li v-show="dataSources[0]?.ShipAddress.DistrictName">ต.{{
                            dataSources[0]?.ShipAddress.DistrictName }}</li>
                            <li v-show="dataSources[0]?.ShipAddress.Postcode">{{ dataSources[0].ShipAddress.Postcode }}
                            </li>
                        </ul>
                    </div>

                </template>
            </a-step>
            <a-step title="จัดส่งวันที่" :description="formatdate(dataSources[0]?.CreatedDate)" />
            <a-step title="สถานะ" :description="dataSources[0]?.Status">
                <template #description>
                    <a-tag
                        :color="dataSources[0].status === '0' ? 'ดำเนินการ' : dataSources[0].status.length > 5 ? 'geekblue' : 'green'">
                        {{dataSources[0].status === 0 ? 'ดำเนินการ' : dataSources[0].status.length > 5 ? 'geekblue' :
                        'green'}}
                    </a-tag>
                </template>
            </a-step>

            <a-step title="นำจ่ายสำเร็จ" description="สำเร็จ" />
            <!-- <a-img src="https://namahayan.blob.core.windows.net/salepage/1668068170807.webp" class="w-full m-auto">
            </a-img> -->
            <a-image :preview="{ visible: false }" :width="auto"
                src="https://namahayan.blob.core.windows.net/salepage/1668068170807.webp" @click="visible = true" />
        </a-steps>


    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad
    },

    setup() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        var track = "";
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "บาร์โค้ด",
                dataIndex: "barcode",
                width: "15%", customFilterDropdown: true,
                onFilter: (value, record) => record.barcode.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: visible => {
                    if (visible) {
                        setTimeout(() => {
                            searchInput.value.focus();
                        }, 100);
                    }
                },
            },
            {
                title: "ioss",
                dataIndex: "ioss",
            },
            {
                title: "item customer id",
                dataIndex: "itemCusId",
            },
            {
                title: "ผู้รับ",
                dataIndex: "receiver",
                width: "10%"
            },
            {
                title: "บริการ",
                dataIndex: "service"
            },
            {
                title: "เบอร์โทรศัพท์",
                dataIndex: "tell"
            },
            {
                title: "ประเทศ",
                dataIndex: "country"
            },
            {
                title: "สถานะ",
                dataIndex: "status"
            },
            {
                title: "วันที่",
                dataIndex: "updatetime",
                width: "15%"
            },
            {
                title: "จ่าหน้า",
                dataIndex: "filename"
            }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสร้างไปรษณีย์ไทย ต่างประเทศ",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const querycustomer = () => {
            if (urlParams.has('track')) {
                track = urlParams.get('track');
            }
    
            return BzbsUser.apigetcreatedinter()
                .then(res => {
   
                    dataSources.value = res.data.items.filter(item => item.barcode == track)
                    
                    if (dataSources[0].Status.Name?.includes("สำเร็จ")) {
                        current_status.value = 3
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: '',
            searchedColumn: '',
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true,
            });
            state.searchText = '';
        };
        return {
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
