<template>
    <a-form
        ref="formRef"
        name="dynamic_form_nest_item"
        :model="dynamicValidateForm"
        @finish="onFinish"
    >
        <a-space
            v-for="(user, index) in dynamicValidateForm.users"
            :key="user.id"
            style="display: flex; margin-bottom: 8px"
            align="baseline"
        >
            <a-form-item
                :name="['users', index, 'first']"
                :rules="{
                    message: 'ข้อมูลจัดส่ง'
                }"
            >
                <DetailSent
                    @onmodaladdresschagne="onModalAddressChagne($event)"
                ></DetailSent>
                <!-- **รายละเอียดรายการ จัดส่ง :: {{sentdetail}} -->
            </a-form-item>
            <a-form-item
                :name="['users', index, 'first']"
                :rules="{
                    required: true,
                    message: 'Missing first name'
                }"
            >
                <a-input
                    style="width:150px"
                    v-model:value="user.first"
                    placeholder="รหัส"
                />
            </a-form-item>
            <DropDown :indexitem="index" :producttype="producttype"></DropDown>
            <!-- <a-form-item :name="['users', index, 'count']">
                <a-input
                    v-if="publishedBooksMessage != null"
                    style="width:200px"
                    v-model:value="publishedBooksMessage.sights.length"
                    placeholder="จำนวน"
                />
            </a-form-item> -->
            <!-- <a-statistic
                title="Active Users"
                :value="112893"
                style="margin-right: 50px"
            /> -->
            <!-- <span v-if="!isEmpty(publishedBooksMessage)">
                {{ publishedBooksMessage.sights.length }}</span
            > -->
            <!-- <spa v-if="!isEmpty(publishedBooksMessage)">
                <a-statistic
                    title="จำนวน"
                    :value="publishedBooksMessage.sights.length"
                    style="margin-right: 50px"
                />
                <a-statistic title="ยอดรวม" :precision="2" :value="112893" />
            </spa> -->

            <!-- <a-form-item
                :name="['users', index, 'last']"
                :rules="{
                    required: true,
                    message: 'Missing last name'
                }"
            >
                <a-input
                    style="width:200px"
                    v-model:value="user.last"
                    placeholder="มูลค่าต่อหน่วย"
                />
            </a-form-item>
            <a-form-item
                :name="['users', index, 'last']"
                :rules="{
                    required: true,
                    message: 'Missing last name'
                }"
            >
                <a-input
                    v-model:value="user.last"
                    placeholder="ส่วนลดต่อหน่วย	"
                />
            </a-form-item> -->
            <!-- <a-form-item :name="['users', index, 'last']">
                <a-input v-model:value="user.last" placeholder="รวม" />
            </a-form-item> -->
            <MinusCircleOutlined @click="removeUser(user)" />
        </a-space>


        <a-form-item>
            <a-button type="dashed" @click="addUser">
                <PlusOutlined />
                เพิ่มรายการ
            </a-button>
        </a-form-item>
        <a-row>
            <a-col v-if="!isEmpty(getallproduct)" :span="12">
                <a-statistic
                    title="จำนวน"
                    :value="setcount(getallproduct)"
                    style="margin-right: 50px"
                />
            </a-col>
            <!-- <a-col v-if="!isEmpty(publishedBooksMessage)" :span="12">
                <a-statistic
                    title="ยอดรวม"
                    :precision="2"
                    style="margin-right: 50px"
                    :value="publishedBooksMessage.sights.length"
                />
            </a-col> -->
        </a-row>
        <a-form-item>
            <a-button type="dash" danger html-type="submit">Submit</a-button>
        </a-form-item>
            <div class="pb-3">
            <a-button type="dashed" danger
                >*** เตรียมข้อมูล JSON สำหรับระบบ(ใช้ดูค่าสำหรับ developer)</a-button
            >
        </div>
        <div >
            *** จำรองข้อมูล รายการ ปัจจุบัน
            {{ publishedBooksMessage }}
        </div>
        <div>*** รายการ ทั้งหมด {{ getallproduct }}</div>
    </a-form>
</template>
<script>
import { MinusCircleOutlined, PlusOutlined  } from "@ant-design/icons-vue";
import { message } from 'ant-design-vue';
import DropDown from "./form/DropDownProduct.vue";
import { defineComponent, reactive, ref, computed } from "vue";
import DetailSent from "../component/DetailSent.vue";
import Storedata from "@/store/Storedata";
export default defineComponent({
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        DetailSent,
        DropDown
    },
    provide: {
        Storedata: Storedata
    },
    props: ["producttype"],
    setup() {
        const formRef = ref();
        const sentdetail = ref();
        const dynamicValidateForm = reactive({
            users: []
        });
        dynamicValidateForm.users.push({
            first: "",
            last: "",
            count: 0,
            id: Date.now()
        });

        const removeUser = item => {
            let index = dynamicValidateForm.users.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.users.splice(index, 1);
                Storedata.state.allproduct.splice(index, 1);
            }
        };
        const isEmpty = obj => {
            return Object.keys(obj).length === 0;
        };
        const publishedBooksMessage = computed(() => {
            return Storedata.state.createproduct;
        });
        const addUser = () => {

            dynamicValidateForm.users.push({
                first: "",
                last: "",
                count: 0,
                id: Date.now()
            });
            successmessage()

            // Storedata.methods.setallproduct(publishedBooksMessage.value);
        };
   const successmessage = () => {
      message.success('เพิ่มรายการ', 2);
    };

        const onFinish = values => {
            console.log("Received values of form:", values);
        };

        const getallproduct = computed(() => {
            return Storedata.state.allproduct;
        });

        const onModalAddressChagne = values => {
            sentdetail.value = values;
            console.log("value :: modal Address : ", values);
        };

        const setcount = values => {
            let sum = 0;
            values.forEach(element => {
                sum += element.sights.length;
            });
            return sum;
            // if (values) return values.sights.length;
            // else return 0;
        };
        return {
            formRef,
            dynamicValidateForm,
            onFinish,
            removeUser,
            addUser,
            successmessage,
            publishedBooksMessage,
            isEmpty,
            setcount,
            getallproduct,
            sentdetail,
            onModalAddressChagne
        };
    }
});
</script>
