<template>
    <div class="tab-item" :class="{ 'h-full': fullHeight, 'min-h-full': minHeightFull }">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "VTabItem",
    computed: {
        tab: function () {
            return this.$parent.tab
        },
        fullHeight: function () {
            return this.$parent.fullHeight
        },
        minHeightFull: function () {
            return this.$parent.minHeightFull
        }
    }
}
</script>

<style lang="scss" scoped>
.tab-item {
    flex: 0 0 100%;
}
</style>
