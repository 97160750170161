<template>
    <div>
        <!-- <pre v-if="!fact_hasSelected">สร้าง sp สร้างรายการค่าส่งเพิ่มแล้ว</pre> -->
        <a-form
            :model="formState"
            name="basic"
            autocomplete="off"
            @change="fromchange"
        >
            <a-form-item
                :name="['user', 'sellorderTransaction']"
                label="ค่าส่งเพิ่ม*"
            >
                <a-radio-group 
                    v-model:value="paytype"
                    name="radioGroup"
                    :disabled="!fact_hasSelected"
                >
                    <a-radio value="1">ไม่มีค่าส่งเพิ่ม</a-radio>
                    <a-radio value="2">มีค่าส่งเพิ่ม</a-radio>
                </a-radio-group>
            </a-form-item>
            <MoreDeliveryPay v-show="paytype == '2'"></MoreDeliveryPay>
        </a-form>
    </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted, watch } from "vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
import dayjs from "dayjs";
import { formStateStore } from "@/store/storebackoffice";
import MoreDeliveryPay from "@/module/Delivery/component/MoreDeliveryPay.vue";
export default defineComponent({
    components: {
        MoreDeliveryPay
    },
    props: {
        formStates: null,
        hasSelected: null
    },
    setup(props, { emit }) {
        const keyupdate = ref(0);
        const formState = reactive(formStateStore);
        const fact_hasSelected = reactive(props.hasSelected);
        setTimeout(() => {
            keyupdate.value++;
        }, 1500);
        const isinit = ref(false);
        console.log("props.formStates", props.formStates);

        const options = ref([
            {
                value: "โอนจากบัญชีไทย",
                label: "โอนจากบัญชีไทย"
            },
            {
                value: "โอนจากบัญชีต่างประเทศ",
                label: "โอนจากบัญชีต่างประเทศ"
            },
            {
                value: "บัตรเครดิต",
                label: "บัตรเครดิต"
            },
            {
                value: "moneygram",
                label: "moneygram"
            },
            {
                value: "Ria",
                label: "Ria"
            },
            {
                value: "western_union",
                label: "western union"
            },
            {
                value: "Paypal",
                label: "Paypal"
            },
            {
                value: "อื่นๆ",
                label: "อื่นๆ"
            }
        ]);
        const paytype = ref("1");

        const value = ref(undefined);
        const isinited = ref(false);
        const formRef = ref();
        const formItemContext = Form.useInjectFormItemContext();
        const fromchange = () => {
            formState.pay.paymentmethod = value.value;
            formState.pay.paytype = paytype;
            console.log("pay :", formState.pay);
            emit("update:value", formState.pay);
            formItemContext.onFieldChange();
        };

        // watch(
        //     () => formState.pay_additional.listpay,
        //     () => {
 
        //         if (formState.pay_additional.listpay.length > 0) {
        //             if(formState.pay_additional.listpay[0].price && parseInt(formState.pay_additional.listpay[0].price)>0)
        //             paytype.value = "2";
        //         }
        //     }
        // );
        onMounted(() => {
            getpaymenthisory();
      
            getpaymentdetailadditional();
        });

        const getpaymentdetailadditional = () => {
            console.log(formState);
        

            var so = formState.user.selectedRowKeys[0];
            BzbsUser.apigetpaymentdetailadditional(
                formState.user.customercode,
                "pay_additional",
                so
            )
                .then(res => {
             

                    if (res.data.length > 0) {
                         
                        setTimeout(() => {
             
                                if (
                                    res.data[0].Price &&
                                    parseInt(res.data[0].Price) > 0
                                ){

                                    paytype.value = "2";
                                }else{

                                    paytype.value = "1";
                                }
                      
                        }, 100);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getpaymenthisory = () => {
            BzbsUser.apigetpaymentdetail(formState.user.customercode, "normal")
                .then(res => {
      
                    res.data[0] = _.transform(res.data[0], function(
                        result,
                        val,
                        key
                    ) {
                        result[key.toLowerCase()] = val;
                    });
                    value.value = res.data[0].paymentmethod;
                    let resultobj = Object.assign(formState.pay, res.data[0]);
                    if (res.data[0].date) {
                        resultobj.date =
                            ref(dayjs(res.data[0].date, "YYYY-MM-DD")) || "";
                    }
                    if (res.data[0].time) {
                        resultobj.time =
                            ref(dayjs(res.data[0].time, "HH:mm")) || "";
                    }
                    formState.pay = resultobj;
                    isinited.value = true;
                    return console.log(res.data[0]);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            keyupdate,
            isinited,
            value,
            formRef,
            paytype,
            options,
            fromchange,
            isinit,
            formState,
            fact_hasSelected
        };
    }
});
</script>
<style lang="scss" scoped></style>
