<template>
    <div class="relative p-4 card wrapper-box-mobile">
        <template v-if="title != null">
            <div class="m-4 text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <AddShopname @onshopname="setshopname"></AddShopname>
        <a-divider orientation="left">ข้อมูล เซลเพจ</a-divider>
        <div class="card-container">
            <a-tabs v-model:activeKey="activeKey" class="p-4" type="card">
                <a-tab-pane key="1">
                    <template #tab>
                        <span>
                            <chrome-outlined />
                            หน้าหลัก
                        </span>
                    </template>
                    <h1>สร้างหน้าหลัก</h1>
                    <div class="p-1">
                        <a-form class="" :model="formState" v-bind="layout" name="nest-messages" @finish="onFinish"
                            :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="btn-footer">
                                <a-button html-type="submit" style="border: 0; background: transparent;height: auto;">
                                    <img src="../../../../assets/imgs/logo/save.png" width="50" height="50"
                                        alt="submit" />
                                </a-button>

                                <!-- <AddContent v-if="activeKey == '1'"  :type="'footer'" @onobjsalepage="addvalue($event)"></AddContent> -->
                            </div>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageID']" label="SalePageID"
                                :rules="[{ required: false }]">
                                <a-input :disabled="true" v-model:value="formState.user.SalePageID"
                                    placeholder="SalePageID" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageDetail']" label="หมายเหตุ"
                                :rules="[{ required: false }]">
                                <a-textarea v-model:value="salepagedetail.desc" placeholder="หมายเหตุ"
                                    :auto-size="{ minRows: 3, maxRows: 5 }" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageID']" label="FullURL"
                                :rules="[{ required: false }]">
                                <a-input-group compact class="border-link">
                                    <a-typography-title :level="5"
                                        style="padding: 0px;margin:0px;word-break: break-word;" copyable>
                                        https://{{ formState.user.SubDomainName }}.{{ config_namahayant.salepageurl }}/{{
                                                formState.user.SalePageID
                                        }}</a-typography-title>
                                </a-input-group>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageID']" label="สร้าง"
                                :rules="[{ required: false }]">
                                <AddContent @onobjsalepagefrom="addvalue($event)"></AddContent>
                            </a-form-item>
                            <a-form-item class="mt-3 justify-center">
                                <div class="flex justify-center">
                                    <a-tabs v-model:activeKeypreview="activeKeypreview" class="wrapper-mobile">
                                        <a-tab-pane key="8" tab="สร้าง" force-render>
                                            <!-- objsalepage :<pre>{{objsalepage}}</pre> -->
                                            <Drag :tab="'main'" :key="update" @onlist="draglist($event)">
                                            </Drag>
                                        </a-tab-pane>
                                        <a-tab-pane key="9" tab="ดูตัวอย่าง(BETA)">
                                            <Iphone :key="update_mock_phone">
                                            </Iphone>
                                        </a-tab-pane>
                                    </a-tabs>
                                </div>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageLineNotify']"
                                label="Line Token(แจ้งเตือน)" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageLineNotify" placeholder="Line token" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'Pixel']" label="Pixel">
                                <Addpixcel :facebookpixel="formState.user.facebookpixel" :key="update"
                                    @onfacebook="onfacebook($event)"></Addpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'TiktokPixel']" label="Tiktok Pixel">
                                <AddTiktokpixcel :tiktokpixel="formState.user.tiktokpixel" :key="update"
                                    @ontiktok="ontiktok($event)"></AddTiktokpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageFontMain']" label="ฟอนต์หลัก"
                                :rules="[{ required: false }]">
                                <a-dropdown-button @click="handleButtonClick">
                                    {{ formState.user.SalePageFontMain.fontname }}
                                    <template #overlay>
                                        <a-menu @click="handleMenuClick">
                                            <a-menu-item key="thasadith" class="ql-font-thasadith">
                                                <UserOutlined />
                                                Thasadith
                                            </a-menu-item>
                                            <a-menu-item key="k2d" class="ql-font-k2d">
                                                <UserOutlined />
                                                k2d
                                            </a-menu-item>
                                            <a-menu-item key="charm" class="ql-font-charm">
                                                <UserOutlined />
                                                charm
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                    <template #icon>
                                        <DownOutlined />
                                    </template>
                                </a-dropdown-button>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'MetaTagDomain']" label="MetaKey(ยืนยันโดเมน)"
                                :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.MetaTagDomain" placeholder="MetaTagKey" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'GoogleSEO']" label="Google SEO"
                                :rules="[{ required: false }]">
                                <a-form-item class="p-4 mt-3" :name="['user', 'SalePageGoogleSEO']" label="หัวข้อ"
                                    :rules="[{ required: false }]">
                                    <a-input v-model:value="formState.user.SalePageGoogleSEO.title"
                                        placeholder="หัวข้อ" />
                                </a-form-item>
                                <a-form-item class="p-4 mt-3" :name="['user', 'SalePageGoogleSEO']" label="คำอธิบาย"
                                    :rules="[{ required: false }]">
                                    <a-input v-model:value="formState.user.SalePageGoogleSEO.desc"
                                        placeholder="คำอธิบาย" />
                                </a-form-item>
                                <a-form-item class="p-4 mt-3" :name="['user', 'Tags']" label="Tags"
                                    :rules="[{ required: false }]">
                                    <Tag :googleseotags="formState.user.SalePageGoogleSEO.tags"
                                        @ontags="settags($event)" :key="update"></Tag>
                                </a-form-item>

                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'GoogleTagManager']"
                                label="Google Tag Manager" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.GoogleTagManager"
                                    placeholder="GoogleTagManager" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'GoogleAnalytic']" label="GoogleAnalytic"
                                :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.GoogleAnalytic" placeholder="GoogleAnalytic" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageLineOA']" label="Line"
                                :rules="[{ required: false }]" has-feedback :validate-status="LineStatus">
                                <a-input v-model:value="LineInput" @change="checklinecondition()" placeholder="Line">
                                    <template #addonBefore>
                                        <a-select v-model:value="LineType" @change="lineselectchange()"
                                            style="width: 120px">
                                            <a-select-option value="line_oa">Line @</a-select-option>
                                            <a-select-option value="line_personal">Line ส่วนตัว</a-select-option>
                                        </a-select>
                                    </template>
                                </a-input>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageFacebookMessenger']"
                                label="FB Messenger" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageFacebookMessenger"
                                    addon-before="https://m.me/" placeholder="Facebook Messenger" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageWhatsApp']" label="WhatsApp"
                                :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageWhatsApp" addon-before=""
                                    placeholder="WhatsApp" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageLineNotify']"
                                label="Line Token(แจ้งเตือน)" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageLineNotify" placeholder="Line token" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageTel']" label="Tel."
                                :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageTel" placeholder="Basic usage" />
                            </a-form-item>
                            <a-form-item v-if="false" class="p-4 mt-3" label="Upload file"
                                :rules="[{ required: false }]">
                                <UploadFile :sizeupimage="1" @fileList="onchangeimages($event)"></UploadFile>
                            </a-form-item>

                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageConfig']"
                                label="แสดงปุ่มลอย(ด้านขวา)" :rules="[{ required: false }]">
                                <a-checkbox-group v-model:value="configrigth" style="width: 100%">
                                    <a-row>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="1"><img class="image-text" width="35"
                                                    src="../../../../assets/logo/phone.png" />
                                            </a-checkbox>
                                            <div class="wrapper-input-chat">
                                                <a-input v-model:value="configrigth_message_rigth[0]" class="m-5"
                                                    placeholder="ข้อความปุ่ม" />
                                            </div>
                                        </a-col>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="2"><img class="image-text" width="35"
                                                    src="../../../../assets/logo/whatsapp.png" />
                                            </a-checkbox>
                                            <div class="wrapper-input-chat">
                                                <a-input v-model:value="configrigth_message_rigth[1]" class="m-5"
                                                    placeholder="ข้อความปุ่ม" />
                                            </div>
                                        </a-col>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="3"><img class="image-text" width="35"
                                                    src="../../../../assets/logo/FB.png" />
                                            </a-checkbox>
                                            <div class="wrapper-input-chat">
                                                <a-input v-model:value="configrigth_message_rigth[2]" class="m-5"
                                                    placeholder="ข้อความปุ่ม" />
                                            </div>
                                        </a-col>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="4"><img class="image-text" width="35"
                                                    src="../../../../assets/logo/line.png" />
                                            </a-checkbox>
                                            <div class="wrapper-input-chat">
                                                <a-input v-model:value="configrigth_message_rigth[3]" class="m-5"
                                                    placeholder="ข้อความปุ่ม" />
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-checkbox-group>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageConfig']" label="การแสดงปุ่ม(footer)"
                                :rules="[{ required: false }]">
                                <a-checkbox-group v-model:value="configfooter" style="width: 100%">
                                    <a-row>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="1">
                                                <div class="footerfix_check_left">
                                                    <button class="btn btn-footer-sp"> สอบถาม
                                                    </button>
                                                </div>

                                            </a-checkbox>
                                        </a-col>
                                        <a-col :flex="1" :lg="{ span: 6 }">
                                            <a-checkbox value="2">
                                                <div class="footerfix_check_rigth">
                                                    <button class="btn btn-footer-sp"> โทร
                                                    </button>
                                                </div>
                                            </a-checkbox>
                                        </a-col>
                                    </a-row>
                                </a-checkbox-group>
                            </a-form-item>

                        </a-form>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" @click="update_thank++" force-render>

                    <template #tab>
                        <span>
                            <codepen-outlined />
                            หน้าขอบคุณ
                        </span>
                    </template>
                    <h1>สร้างหน้าขอบคุณ</h1>
                    <!-- objsalepagethank :
                    <pre>{{ objsalepagethank }}</pre> -->
                    <div class="">
                        <a-form class="" :model="formState" v-bind="layout" name="nest-messages" @finish="onFinish"
                            :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="btn-footer">
                                <a-button html-type="submit" style="border: 0; background: transparent;height: auto;">
                                    <img src="../../../../assets/imgs/logo/save.png" width="50" height="50"
                                        alt="submit" />
                                </a-button>

                                <AddContent :type="'footer'" @onobjsalepage="addvalue($event)"></AddContent>
                            </div>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageID']" label="ลิ้งหน้าขอบคุณ"
                                :rules="[{ required: false }]">
                                <h1 class="text-indigo-500">หน้าขอบคุณ</h1>
                                <a-input-group compact class="border-link">
                                    <a-typography-title :level="5"
                                        style="padding: 0px;margin:0px;word-break: break-word;" copyable>
                                        https://{{ formState.user.SubDomainName
                                        }}.{{ config_namahayant.salepageurl }}/THANKYOU/{{
        formState.user.SalePageID ?
            formState.user.SalePageID : '{salepageid}'
}}/ลำดับ/ราคา
                                    </a-typography-title>
                                </a-input-group>
                            </a-form-item>


                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageLineNotify']"
                                label="Line Token(แจ้งเตือน)" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageLineNotify" placeholder="Line token" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'Pixel']" label="Pixel ThankYou">
                                <Addpixcel :facebookpixel="formState.user.facebookpixelthankyouform" :key="update"
                                    @onfacebook="onfacebookthankyouform($event)"></Addpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'TiktokPixel']" label="Tiktok Pixel">
                                <AddTiktokpixcel :tiktokpixel="formState.user.tiktokpixelthankyouform" :key="update"
                                    @ontiktok="ontiktokthankyouform($event)"></AddTiktokpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['userthank', 'SalePageID']" label="สร้าง"
                                :rules="[{ required: false }]">
                                <AddContent @onobjsalepage="addvaluethank($event)"></AddContent>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3 justify-center">
                                <div class="flex justify-center min-h-screen">
                                    <Drag :tab="'thank'" :key="update_thank" @onlist="draglistformpage($event)">
                                    </Drag>
                                </div>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" @click="update_thank++" force-render>
                    <template #tab>
                        <span>
                            <audit-outlined />
                            OrderComplete
                        </span>
                    </template>
                    <h1>สร้างหน้า OrderComplete</h1>
                    <!-- objsalepagethank :
                    <pre>{{ objsalepagethank }}</pre> -->
                    <div class="">
                        <a-form class="" :model="formState" v-bind="layout" name="nest-messages" @finish="onFinish"
                            :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="btn-footer">
                                <a-button html-type="submit" style="border: 0; background: transparent;height: auto;">
                                    <img src="../../../../assets/imgs/logo/save.png" width="50" height="50"
                                        alt="submit" />
                                </a-button>

                                <AddContent :type="'footer'" @onobjsalepage="addvalue($event)"></AddContent>
                            </div>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageID']" label="ลิ้งหน้าขอบคุณ"
                                :rules="[{ required: false }]">
                                <h1 class="text-indigo-500">หน้า OrderComplete</h1>
                                <a-input-group compact class="border-link">
                                    <a-typography-title :level="5"
                                        style="padding: 0px;margin:0px;word-break: break-word;" copyable>
                                        https://{{ formState.user.SubDomainName
                                        }}.{{ config_namahayant.salepageurl }}/OrderComplete/{{
        formState.user.SalePageID ?
            formState.user.SalePageID : '{salepageid}'
}}
                                    </a-typography-title>
                                </a-input-group>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'SalePageLineNotify']"
                                label="Line Token(แจ้งเตือน)" :rules="[{ required: false }]">
                                <a-input v-model:value="formState.user.SalePageLineNotify" placeholder="Line token" />
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'Pixel']" label="Pixel">
                                <Addpixcel :facebookpixel="formState.user.facebookpixelthankyou" :key="update"
                                    @onfacebook="onfacebookthankyou($event)"></Addpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['user', 'TiktokPixel']" label="Tiktok Pixel">
                                <AddTiktokpixcel :tiktokpixel="formState.user.tiktokpixelthankyou" :key="update"
                                    @ontiktok="ontiktokthankyou($event)"></AddTiktokpixcel>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3" :name="['userthank', 'SalePageID']" label="สร้าง"
                                :rules="[{ required: false }]">
                                <AddContent @onobjsalepage="addvaluethank($event)"></AddContent>
                            </a-form-item>
                            <a-form-item class="p-4 mt-3 justify-center">
                                <div class="flex justify-center min-h-screen">
                                    <Drag :tab="'thank'" :key="update_thank" @onlist="draglistthank($event)">
                                    </Drag>
                                </div>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-tab-pane>

            </a-tabs>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, ref, createVNode, onMounted, watch } from "vue";
// import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import UploadFile from "@/module/Salepage/SalepageCreate/component/Uploadfile.vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import AddContent from "./modal/AddContent.vue";
import Drag from "./modal/Drag.vue";
import _ from "lodash";
import Addpixcel from "./modal/Addpixcel.vue";
import AddTiktokpixcel from "./modal/AddTiktokpixcel.vue";
import Iphone from "./modal/modalAdd/Iphone.vue";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined, ChromeOutlined, CodepenOutlined, AuditOutlined, DownOutlined } from '@ant-design/icons-vue';
// import axios from 'axios'
import { useRoute } from "vue-router";
import { notification } from 'ant-design-vue';
import { objsalepage, objsalepagethank, objsalepagethankform, current_tab, formStatestore, config_namahayant } from '@/store/store'
import AddShopname from "./modal/AddShopName.vue";
import Tag from "../component/form/Tag.vue";
export default defineComponent({
    components: { UploadFile, AddContent, Drag, Addpixcel, Iphone, AddShopname, AddTiktokpixcel, Tag, ChromeOutlined, CodepenOutlined, AuditOutlined, DownOutlined },
    setup() {

        objsalepage.value = JSON.parse('[]')
        objsalepagethank.value = JSON.parse('[]')
        objsalepagethankform.value = JSON.parse('[]')
        const LineType = ref("line_oa");
        const LineInput = ref("");
        const LineStatus = ref("");
        const top = ref(750);

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            top.value = 500;
        }
        const update = ref(0);
        const update_thank = ref(0);
        const layout = {
            labelCol: {
                span: 8
            },
            wrapperCol: {
                span: 16
            }
        };
        const configfooter = ref([]);
        const configrigth = ref([]);
        var configrigth_message_rigth = ref([]);
        configrigth_message_rigth.value = ["", "", "", ""]
        var salepagedetail = ref({
            desc: ""
        });
        const formState = reactive({
            user: {
                SalePageDetail: { desc: "" },
                SalePageConfig: {
                    footer: {
                        active: []
                    },
                    btnrigth: {
                        active: [],
                        btn_message_rigth: []
                    },
                    Linetype: ""
                },
                SalePageFontMain: { fontclass: "", fontname: "ฟอนต์" },
                SalePageID: "",
                facebookpixel: "",
                tiktokpixel: "",
                SalePageGoogleSEO: {
                    title: "",
                    desc: "",
                    tags: []
                },
                images: []
            },
            userthank: {}
        });
        const route = useRoute();
        formState.user.SubDomainName = route.query.subdomain || 'namahayant';

        onMounted(() => {
            // querycustomer();
        });
        // const route = useRoute();
        const callfinish = ref(false)
        // const querycustomer = () => {
        //     let path = window.location.host.includes("localhost")
        //         ? "http://localhost:8090"
        //         : process.env.VUE_APP_API_BASE_URL;

        //     return axios.get(path + "/api/salepageid/" + route.query.id).then(res => {

        //         console.log(res.data[0]);
        //         objsalepage.value = JSON.parse(res.data[0].SalePageJson || '[]')
        //         temp_objsalepage.value = objsalepage.value
        //         objsalepagethank.value = JSON.parse(res.data[0].SalePageThankYouJson || '[]')
        //         formState.user.facebookpixel = JSON.parse(res.data[0].SalePageFacebookPixel || null)
        //         formState.user.GoogleTagManager = res.data[0].GoogleTagManager
        //         formState.user.SalePageID = route.query.cloneto != undefined ? route.query.cloneto : res.data[0].SalePageID
        //         formState.user.SalePageYoutube1 = res.data[0].SalePageYoutube1
        //         formState.user.SalePageYoutube2 = res.data[0].SalePageYoutube2
        //         formState.user.SalePageYoutube3 = res.data[0].SalePageYoutube3
        //         formState.user.SalePageYoutube4 = res.data[0].SalePageYoutube4
        //         formState.user.SalePageYoutube5 = res.data[0].SalePageYoutube5
        //         formState.user.SalePageLine = res.data[0].SalePageLine
        //         formState.user.SalePageLineOA = res.data[0].SalePageLineOA
        //         formState.user.SalePageTel = res.data[0].SalePageTel
        //         formState.user.SalePageDetail = res.data[0].SalePageDetail
        //         formState.user.SalePageJson = res.data[0].SalePageJson
        //         formState.user.MetaTagDomain = res.data[0].MetaTagDomain
        //         formState.userthank.SalePageThankYouJson = res.data[0].SalePageThankYouJson

        //         update.value++
        //         update_thank.value++
        //         callfinish.value = true;
        //     });
        // };

        const onFinish = values => {
            Modal.confirm({
                title: 'คุณต้องบันทึก?',
                icon: createVNode(ExclamationCircleOutlined),
                content: createVNode('div', {
                    style: 'color:red;',
                }, ''),

                onOk() {
                    if (!_.isEmpty(formState.user.SalePageID)) {
                        console.log("Success:", values);
                        insertsalepage();
                    }
                    else {
                        warning()
                    }
                },

                onCancel() {
                    console.log('Cancel');
                },
                class: 'test',
            });
        };
        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() { 
                    window.location = "/salepageedit?subdomainsalepage=" + route.query.subdomain + "&id=" + formState.user.SalePageID
                }
            });
        };

        const onfacebook = (e) => {
            console.log("facebook :", e)
            formState.user.facebookpixel = JSON.stringify(e)

        }
        const onfacebookthankyou = (e) => {
            console.log("facebook :", e)
            formState.user.facebookpixelthankyou = JSON.stringify(e)

        }
        const onfacebookthankyouform = (e) => {
            console.log("facebook :", e)
            formState.user.facebookpixelthankyouform = JSON.stringify(e)

        }



        const ontiktok = (e) => {
            console.log("tiktok :", e)
            formState.user.tiktokpixel = JSON.stringify(e)

        }
        const ontiktokthankyou = (e) => {
            console.log("tiktok :", e)
            formState.user.tiktokpixelthankyou = JSON.stringify(e)

        }
        const ontiktokthankyouform = (e) => {
            console.log("tiktok :", e)
            formState.user.tiktokpixelthankyouform = JSON.stringify(e)

        }
        const insertsalepage = async () => {
            var index_uploadimage = 0;
            var listimages = [];
            for await (let items of formState.user.images) {
                if (items.preview != undefined) {
                    var uploadparam = {};
                    uploadparam = {
                        filename:
                            formState.user.SalePageID +
                            "_" +
                            index_uploadimage +
                            ".gif",
                        base64: items.preview,
                        folder: "salepage"
                    };
                    await BzbsUser.apiuploadimage(uploadparam)
                        .then(res => {
                            listimages.push(res.data.url);
                            console.log(res.data);
                            console.log("listimages", listimages);
                            index_uploadimage++;
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    index_uploadimage++;
                }
            }

            let seo = JSON.stringify({ title: formState.user.SalePageGoogleSEO.title, desc: formState.user.SalePageGoogleSEO.desc, tags: formState.user.SalePageGoogleSEO.tags });

            formState.user.SalePageConfig.btnrigth.btn_message_rigth = configrigth_message_rigth.value

            formState.user.SalePageDetail = { desc: "" }

            formState.user.SalePageDetail.desc = salepagedetail.value.desc

            const param = {
                SalePageFacebookPixel: formState.user.facebookpixel || '[]',
                SalePageFacebookPixelThankYou: formState.user.facebookpixelthankyou || '[]',
                SalePageFacebookPixelThankYouForm: formState.user.facebookpixelthankyouform || '[]',
                SalePageTiktokPixel: formState.user.tiktokpixel || '[]',
                SalePageTiktokPixelThankYou: formState.user.tiktokpixelthankyou || '[]',
                SalePageTiktokPixelThankYouForm: formState.user.tiktokpixelthankyouform || '[]',
                GoogleTagManager: formState.user.GoogleTagManager,
                SalePageImages: "",
                SalePageID: formState.user.SalePageID,
                SalePageYoutube1: formState.user.SalePageYoutube1,
                SalePageYoutube2: formState.user.SalePageYoutube2,
                SalePageYoutube3: formState.user.SalePageYoutube3,
                SalePageYoutube4: formState.user.SalePageYoutube4,
                SalePageYoutube5: formState.user.SalePageYoutube5,
                SalePageLine: formState.user.SalePageLine,
                SalePageLineOA: formState.user.SalePageLineOA,
                SalePageFacebookMessenger: formState.user.SalePageFacebookMessenger,
                SalePageWhatsApp: formState.user.SalePageWhatsApp,
                SalePageTel: formState.user.SalePageTel,
                SalePageDetail: JSON.stringify(formState.user.SalePageDetail || '[]'),
                SalePageJson: JSON.stringify(objsalepage.value || '[]'),
                MetaTagDomain: formState.user.MetaTagDomain,
                SalePageGoogleSEO: seo,
                GoogleAnalytic: formState.user.GoogleAnalytic,
                SalePageThankYouJson: JSON.stringify(objsalepagethank.value || '[]'),
                SalePageThankYouFormJson: JSON.stringify(objsalepagethankform.value || '[]'),
                SalePageLineNotify: formState.user.SalePageLineNotify,
                Subdomain: formState.user.SubDomainName,
                SalePageFontMain: JSON.stringify(formState.user.SalePageFontMain || '[]'),
                SalePageConfig: JSON.stringify(formState.user.SalePageConfig || '[]'),
                TYPE: 'CREATE'
            };

            await BzbsUser.apicreatesalepage(param)
                .then(res => {
                    listimages = [];
                    listimages.push(res.data.url);
                    console.log(res.data);
                    console.log("listimages", listimages);
                    success();
                    index_uploadimage++;
                })
                .catch(error => {
                    openNotification(error.response.data)
                    console.log(error);
                });
        };

        const openNotification = (res) => {
            // var url = "<a href=/salepageedit?id="+res.salepageid+">แก้ไขคลิก</a>"
            notification.open({
                message: res.error,
                // description: ,
                style: {
                    width: '600px',
                    marginLeft: `${335 - 600}px`,
                },
                class: 'notification-custom-class',
            });
        };
        const onchangeimages = e => {
            formState.user.images = e;
            console.log(formState.user.images);
        };
        const log = e => {
            console.log(e);
        };
        // const temp_objsalepage = ref({
        //     youtube: [],
        //     image: [],
        //     form: [],
        //     message: [],
        //     video: []
        // });

        // var list = []
        // var listthankyou = []

        const update_mock_phone = ref(0);
        const addvalue = (e) => {
            console.log(e)
            // list = []
            // objsalepage.value = []
            // Object.entries(e?.value?.value || {}).forEach(element => {
            //     element[1]?.map((detail, index) => {
            //         return list.push({ detail, order: index + 1 });
            //     })
            // })
            // list.forEach(element => {
            //     objsalepage.value.push(element)
            // });
            // objsalepage.value = [...temp_objsalepage.value, objsalepage.value]
            // formState.user.SalePageJson = JSON.stringify(objsalepage.value)
            update.value++
        }

        const draglist = (e) => {
            // objsalepage.value = []

            // if (Array.isArray(e)) {
            //     objsalepage.value = e
            // }
            // Object.entries(e?.value?.value || {}).forEach(element => {
            //     element[1]?.map((detail, index) => {
            //         return list.push({ detail, order: index + 1 });
            //     })
            // })
            // list.forEach(element => {
            //     objsalepage.value.push(element)
            // });
            // update_mock_phone.value++
            formState.user.SalePageJson = JSON.stringify(e)
            console.log("drag change : ", e)
        }

        const addvaluethank = (e) => {
            console.log(e)
            // listthankyou = []
            // objsalepagethank.value = []
            // Object.entries(e?.value?.value || {}).forEach(element => {
            //     element[1]?.map((detail, index) => {
            //         return listthankyou.push({ detail, order: index + 1 });
            //     })
            // })
            // listthankyou.forEach(element => {
            //     objsalepagethank.value.push(element)
            // });
            // // objsalepagethank.value = e
            update_thank.value++
        }

        const draglistthank = (e) => {

            formState.userthank.SalePageThankYouJson = JSON.stringify(e)
            console.log("drag change : ", e)
        }

        const draglistformpage = (e) => {

            formState.userthank.SalePageThankYouFormJson = JSON.stringify(e)
            console.log("drag change : ", e)
        }
        const warning = () => {
            Modal.warning({
                title: 'กรุณากรอก SalePage ID',
            });
        };
        const activeKey = ref('1')
        watch(activeKey, (newX) => {
            current_tab.value = newX
            update_thank.value++
            update.value++
        })
        watch(formState, (newX) => {
            formStatestore.value = newX
            checkempty(newX)
        })
        watch(configfooter, (newX) => {
            checkerrorfooter(newX)
            formState.user.SalePageConfig.footer.active = newX
        })
        watch(configrigth, (newX) => {
            checkerrorrigth(newX)
            formState.user.SalePageConfig.btnrigth.active = newX
        })


        const checkerrorrigth = (e) => {
            if (e?.includes("1")) {
                if (!formState.user.SalePageTel) {
                    Modal.warning({
                        title: 'กรอกเบอร์โทรก่อน',
                    });
                    configrigth.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }
            if (e?.includes("2")) {
                if (!formState.user.SalePageWhatsApp) {
                    Modal.warning({
                        title: 'กรอก Whats App',
                    });
                    configrigth.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }
            if (e?.includes("3")) {
                if (!formState.user.SalePageFacebookMessenger) {
                    Modal.warning({
                        title: 'กรอก Facebook Messenger',
                    });
                    configrigth.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }
            if (e?.includes("4")) {
                if (!formState.user.SalePageLineOA) {
                    Modal.warning({
                        title: 'กรอก Line OA',
                    });
                    configrigth.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }
        }

        const checkerrorfooter = (e) => {
            if (e?.includes("1")) {
                if (!formState.user.SalePageLineOA) {
                    Modal.warning({
                        title: 'กรอก Line OA',
                    });
                    configfooter.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }
            if (e?.includes("2")) {
                if (!formState.user.SalePageTel) {
                    Modal.warning({
                        title: 'กรอกเบอร์โทรก่อน',
                    });
                    configfooter.value.splice(configrigth.value.indexOf('1'), 1);
                }
            }

        }

        const checkempty = (e) => {
            if (!e.user.SalePageTel) {
                remove(configrigth.value, '1')
                remove(configfooter.value, '2')
            }
            if (!e.user.SalePageWhatsApp) {
                remove(configrigth.value, '2')
            }
            if (!e.user.SalePageFacebookMessenger) {
                remove(configrigth.value, '3')
            }
            if (!e.user.SalePageLineOA) {
                remove(configrigth.value, '4')
                remove(configfooter.value, '1')
            }

        }

        function remove(arr, what) {
            var found = arr.indexOf(what);

            while (found !== -1) {
                arr.splice(found, 1);
                found = arr.indexOf(what);
            }
        }

        const setshopname = (e) => {
            formState.user.SalePageID = e
        };
        const settags = (e) => {
            formState.user.SalePageGoogleSEO.tags = e

        }
        const handleButtonClick = e => {
            console.log('click left button', e);
        };

        const handleMenuClick = e => {
            formState.user.SalePageFontMain.fontclass = "ql-font-" + e.key
            formState.user.SalePageFontMain.fontname = e.key
            console.log('click', e);
        };

        const checklinecondition = () => {
            formState.user.SalePageConfig.Linetype = LineType.value
            formState.user.SalePageLineOA = LineInput.value
            if (LineType.value == 'กรุณาเลือก') {
                // Modal.warning({
                //     title: 'กรุณาเลือกประเภทไลน์ก่อน',
                // });
                notification['warning']({
                    message: 'แจ้งเตือน',
                    description: 'กรุณาเลือกประเภทไลน์ก่อน',
                });
                formState.user.SalePageLineOA = ""
                LineInput.value = ""
                LineStatus.value = "warning";
            }
            if (LineType.value == "line_oa") {
                if (LineInput.value.charAt(0) != "@") {
                    // Modal.warning({
                    //     title: 'กรุณาเพิ่ม "@" ก่อน LineID เช่น @abc1234',
                    // });
                    notification['warning']({
                        message: 'แจ้งเตือน',
                        description: 'กรุณาเพิ่ม "@" ก่อน LineID เช่น @abc1234',
                    });
                    LineInput.value = ""
                    LineStatus.value = "warning";
                } else if (LineInput.value.charAt(0) == "@") {
                    formState.user.SalePageLineOA = LineInput.value
                    LineStatus.value = "success";

                }
            }
            else if (LineType.value == "line_personal") {
                if (LineInput.value.charAt(0) == "@") {

                    // Modal.warning({
                    //     title: 'กรุณาลบ "@" หน้า Line ID เช่น abc1234',
                    // });
                    notification['warning']({
                        message: 'แจ้งเตือน',
                        description: 'กรุณาลบ "@" หน้า Line ID เช่น abc1234',
                    });
                    LineInput.value = ""
                    LineStatus.value = "warning";
                    formState.user.SalePageLineOA = LineInput.value
                }
                else if (LineInput.value.charAt(0) != "@") {
                    formState.user.SalePageLineOA = LineInput.value
                    LineStatus.value = "success";
                }


                formStatestore.user.LineType = LineType.value

            }
            // console.log(formState.user.SalePageLineOA)

        };

        const lineselectchange = () => {
            LineInput.value = '';
            LineStatus.value = 'warning';

        }
        return {
            checklinecondition,
            handleButtonClick,
            handleMenuClick,
            draglistformpage,
            top,
            labelCol: { style: { width: '150px' } },
            wrapperCol: { span: 14 },
            activeKeypreview: ref('1'),
            activeKey,
            current_tab,
            warning,
            draglist,
            draglistthank,
            update,
            update_thank,
            update_mock_phone,
            objsalepage,
            objsalepagethank,
            objsalepagethankform,
            addvalue,
            addvaluethank,
            log,
            onchangeimages,
            formState,
            onFinish,
            layout,
            enabled: true,
            list: [
                { name: "John", id: 1, type: "youtube" },
                { name: "Joao", id: 2, type: "message" },
                { name: "Jean", id: 3, type: "image" }
            ],
            dragging: false,
            onfacebook,
            onfacebookthankyou,
            onfacebookthankyouform,
            ontiktok,
            ontiktokthankyou,
            ontiktokthankyouform,
            callfinish,
            setshopname, settags,
            configfooter,
            configrigth,
            configrigth_message_rigth,
            salepagedetail,
            config_namahayant,
            LineType,
            LineInput,
            LineStatus, lineselectchange
        };
    }
});
</script>
<style lang="scss" scoped>
.ant-row {
    flex-direction: row;
}

.ant-tabs-tab-btn {
    padding: 5px 20px !important;
}

.border-link {
    border: 1px solid #80808073;
    border-radius: 5px;
    padding: 3px;
    text-indent: 10px;
}

.btn-footer {
    position: fixed;
    right: 25px;
    bottom: 100px;
    width: 80px;
    color: white;
    text-align: center;
    z-index: 1;
}

@media only screen and (max-width: 600px) {
    .ant-typography {
        font-size: 12px;
    }

    .btn-footer {
        bottom: 80px;
        width: 60px;
    }

    .wrapper-mobile {
        width: 100%;
    }

    .wrapper-box-mobile {
        padding: 0.25rem;
    }

    .ant-tabs-mobile {
        padding: 0rem !important;
    }

    .ant-btn {
        height: auto !important;
    }
}


.image-text {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0px;
    left: 25px;
}

.footerfix_check_left {
    background: radial-gradient(circle, #e1c724 40%, #ebcc0dd6 80%);
    border-radius: 5px;

    button {
        color: white;
        min-width: 100px;
    }

}

.footerfix_check_rigth {
    background: radial-gradient(circle, #b81621 0, #f01 100%);
    border-radius: 5px;

    button {
        color: white;
        min-width: 100px;
    }
}

.wrapper-input-chat {
    margin: 25px 10px;
}

.btn-footer-sp {
    min-width: 100px;
    text-align: center;
}
</style>
