<template>
    <div class="sidebar-menu-item" :class="itemClass" @click="clickItem()">
        <div class="flex items-center">
            <div class="flex-none icon px-2">
                <!-- {{active}} :::{{to}} -->
                <template v-if="active">
                    <img src="../../../../assets/imgs/logo/Logo_NA-Black.png" alt />
                </template>
                <template v-else>
                    <img src="../../../../assets/imgs/logo/Logo_NA-Black.png" alt />
                </template>
            </div>
            <div v-show="isShown" class="flex-grow title text-sm" :class="[active ? 'text-black' : 'text-black']">
                {{ text }}
            </div>
            <template v-if="isShown && badge != null">
                <div class="flex-none pr-3">
                    <span class="bg-red-1 text-white text-xs px-1 rounded-full leading-none">{{ badge }}</span>
                </div>
            </template>
            <template v-if="isShown && children != null">
                <div class="flex-none pr-3">
                    <img src="@/assets/icons/arrow/arrow-chevron-down.svg" alt
                        class="transition duration-500 ease-in-out" :class="{ 'transform rotate-180': showChildren }" />
                </div>
            </template>
        </div>
    </div>
    <template v-if="isShown && showChildren">
        <div class="flex items-center sidebar-menu-chil" :class="itemClass" v-for="(childMenu, idx) of children"
            :key="`menu-child-${idx}`" @click="clickChildItem(childMenu)">

            <!-- {{childMenu.ic}} -->

            <div class="flex-none icon pl-8 pr-2 text-lg">
                <div class="" :class="[
                    childActiveIndex == idx ? 'bg-white' : 'bg-gray-500'
                ]"></div>
            </div>
            <div class="flex-grow title text-sm" :class="[
                childActiveIndex == idx ? 'text-white' : 'text-black-1'
            ]">
                <font-awesome-icon :icon="'fa-solid '+childMenu.ic" /> 
                <span class="pl-2">{{ childMenu.text }}</span>
            </div>
        </div>
    </template>
</template>

<script>
export default {
    name: "SidebarMenuItem",
    props: {
        text: {
            type: String
        },
        badge: {
            type: String
        },
        to: {
            type: [String, Object]
        },
        children: {
            type: Array,
            default: null
        },
        autoHideSubMenu: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            active: false,
            showChildren: false,
            childActiveIndex: -1
        };
    },
    watch: {
        $route() {
            this.updateState();
        }
    },
    computed: {
        itemClass: function () {
            let itemClass = ["py-2", "cursor-pointer", "hover_gold"];
            return itemClass;
        },
        isOpen: function () {
            return this.$parent.isOpen;
        },
        isShown: function () {
            // console.log(this.$parent.shown);
            return this.$parent.shown;
        },
        isHidden: function () {
            return this.$parent.hidden;
        }
    },
    mounted: function () {
        // console.log(this.children);
        this.updateState();
    },
    methods: {
        clickItem: function () {
            if (this.children == null) {
                this.$router.push(this.to);
            } else {
                // show children
                this.showChildren = !this.showChildren;
            }
        },
        clickChildItem: function (childMenu) {
             
            window.location = childMenu.to
            // this.$router.push(childMenu.to);
        },
        getRouteObject: function (item = null) {
            let data = this.to;
            if (item != null) {
                data = item;
            }
            return this.$router.resolve(data);
        },
        updateState: function () {
            let route = this.getRouteObject();
            if (this.children == null) {
                if (route && this.$route.name == route.name) {
                    this.active = true;
                } else {
                    this.showChildren = false;
                    this.active = false;
                }
            } else {
                // show children
                if (this.$route.path.startsWith(this.to)) {
                    // find active child
                    for (let i = 0; i < this.children.length; i++) {
                        let childRoute = this.getRouteObject(
                            this.children[i].to
                        );
                        if (childRoute && childRoute.name == this.$route.name) {
                            // this.childActiveIndex = i;
                            break;
                        }
                    }
                    this.showChildren = true;
                    this.active = true;
                } else {
                    if (this.autoHideSubMenu) {
                        this.showChildren = false;
                    }
                    this.childActiveIndex = -1;
                    this.active = false;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dot {
    height: 4px;
    width: 4px;
    border-radius: 50%;
}

.sidebar-menu-item {
    .icon {
        img {
            width: 24px;
            height: 24px;
        }
    }
}

.sidebar-menu-item:hover,
.sidebar-menu-chil:hover {
    --tw-bg-opacity: 1;
    background-color: #fbf5b7 !important;
}
</style>
