import { ref, reactive } from "vue"; 
export const objsalepage = ref({
    youtube: [],
    timer: [],
    image: [],
    slideimage: [],
    form: [],
    message: [],
    video: [],
    addbutton: []
});
export const objsalepagethank = ref({
    youtube: [],
    timer: [],
    image: [],
    slideimage: [],
    form: [],
    message: [],
    video: [],
    addbutton: []
});

export const objsalepagethankform = ref({
    youtube: [],
    timer: [],
    image: [],
    slideimage: [],
    form: [],
    message: [],
    video: [],
    addbutton: []
});
export const shopname = ref("");

export const current_tab = ref("1");

export const value = ref(["เลือก"]);
export const componentcount = ref(0);

export const onEdit = ref({
    type: null,
    editobj: null,
    isshowedit: false
});

export const formStatestore = reactive({});
export const account_profile = ref({
    type: null,
    detail: null,
    google: {
        email: "",
        name: "",
        imageurl: "",
        fullname: "",
        id: "",
        islogin: false
    },
    line: {
        UserId: "",
        displayName: "",
        pictureUrl: "",
        islogin: false
    }
});

export const config_namahayant = ref({
    salepageurl: process.env.VUE_APP_SALEPAGE_URL
});
export const swapaddcontent = ref(false);
export const isclickaddcontent = ref(false);
