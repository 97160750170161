import mitt from "mitt";
import Util from "@/helper/Utility.js";
/**
 * setting
 * @method setTitle
 * @method setMsg
 * @method setBtnOk
 * @method setBtnCancel
 * @method clearModal
 * action
 * @method show
 * @method hide
 * set event callback
 * @method onClickOk
 * event
 * @event sendCallbackOk
 */
export function ConfirmModalController () {
    this.title = '';
    this.msg = '';
    this.btnOk = '';
    this.btnCancel = '';
    this.busModal = mitt();

    this.callbackOk = null;
    this.callbackCancel = null;

    //#region setter
    this.setTitle = function(title) {
        this.title = title;
        return this;
    };
    this.setMsg = function(msg) {
        this.msg = msg;
        return this;
    };
    this.setBtnOk = function(btnOk) {
        this.btnOk = btnOk;
        return this;
    };
    this.setBtnCancel = function (btnCancel) {
        this.btnCancel = btnCancel;
        return this;
    };
    this.clearModal = function() {
        this.title = '';
        this.msg = '';
        this.btnOk = '';
        this.btnCancel = '';
        this.callbackOk = null;
        this.callbackCancel = null;
        return this;
    };
    //#endregion setter

    //#region method
    this.show = function() {
        var config = {
            title: this.title,
            msg: this.msg,
            btnOk: this.btnOk,
            btnCancel: this.btnCancel,
        };
        this.busModal.emit('show', config);
    };
    this.hide = function() {
        this.busModal.emit('hide');
    };
    //#endregion method

    //#region event
    this.onClickOk = function (callback) {
        if (Util.isFunction(callback)) {
            this.callbackOk = callback;
        }
    };
    this.sendCallbackOk = function(data) {
        if (Util.isFunction(this.callbackOk)) {
            this.callbackOk(data);
        }
    };

    this.onClickCancel = function (callback) {
        if (Util.isFunction(callback)) {
            this.callbackCancel = callback;
        }
    };
    this.sendCallbackCancel = function (data) {
        if (Util.isFunction(this.callbackCancel)) {
            this.callbackCancel(data);
        }
    };
    //#endregion event
}
