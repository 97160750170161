<template>
    <div class="page">
        <TopBar>
            <template v-slot:toolbar>
                <!-- <div class="flex py-2 items-center"> -->
                <!-- <div class="flex-auto"></div>
                    <div class="flex-none"></div> -->
                <!-- <div class="flex-none">
                        <div class="flex items-center text-sm text-gray-600">
                            <div class="px-4 cursor-pointer pr-0">
                                <v-date-range-picker
                                    v-model="dtFilter"
                                    :confirmMode="true"
                                    :hasPresets="false"
                                    :max="maxDate"
                                >
                                    <template v-slot:activator="{ text, on }">
                                        <div
                                            class="flex items-center text-center rounded bg-gray-100 py-1 px-2 text-sm ring-1 ring-gray-200 ring-opacity-80"
                                            v-on="on"
                                        >
                                            <div
                                                class="inline-flex items-center icon left"
                                            >
                                                <svg
                                                    class="h-6 w-6 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                    />
                                                </svg>
                                            </div>
                                            <span class="text-green-500">{{
                                                text
                                            }}</span>
                                        </div>
                                    </template>
                                </v-date-range-picker>
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </template>
        </TopBar>
        <div class="pb-10 mx-6">
            <div v-if="false">


                <div class="flex-container">
                    <div class="group-left">
                        <div class="flex-group-container">
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="1" v-bind="cards.dailysales"></dashboard-data-card>
                            </div>
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="2" v-bind="cards.monthlysales"></dashboard-data-card>
                            </div>
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="3" v-bind="cards.yearsales"></dashboard-data-card>
                            </div>
                            <!-- <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.year1ActiveUser"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.topLuckyDraw"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.topSpender"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.dropOff"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.potentialBuyer"
                            ></dashboard-data-card>
                        </div> -->
                        </div>
                    </div>
                    <!-- <div class="group-right">
                    <dashboard-data-card
                        class="h-full px-6"
                        v-bind="userActivities"
                    >
                        <template v-slot:default="props">
                            <div class="text-center card-content">
                                <div class="label text-gray-500 mb-3">
                                    {{ props.label }}
                                </div>
                                <div class="v-list">
                                    <template
                                        v-for="(item, idx) of props.values"
                                        :key="`uact-${idx}`"
                                    >
                                        <div class="v-list-item">
                                            <div
                                                class="flex justify-between w-full"
                                            >
                                                <div class="text-gray-500">
                                                    {{ item.text }}
                                                </div>
                                                <div
                                                    class="text-gray-600 font-semibold"
                                                >
                                                    {{ item.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </dashboard-data-card>
                </div> -->
                </div>
                <div class="flex-container">
                    <div class="items__dashboard">
                        <trend-graph-line :seriesData="dashboardSummaryStates.earnData" title="ยอดขายรวม"
                            :isTimeSeries="true"></trend-graph-line>
                    </div>
                    <div class="items__dashboard">
                        <trend-graph :seriesData="dashboardSummaryStates.burnData" title="มูลค่าสินค้าคงเหลือรายคลัง"
                            :isTimeSeries="true"></trend-graph>
                    </div>
                </div>
            </div>
            <div class="my-6">
                <Table :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม"></Table>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import DashboardDataCard from "../component/DashboardDataCard.vue";
import TrendGraph from "../component/TrendGraph.vue";
import TrendGraphLine from "../component/TrendGraphLine.vue";
import Table from "../component/Table.vue";
// import VDateRangePicker from "@/components/Form/DatePicker/DateRangePicker.vue";
// helper
import DatetimeHelper from "@/helper/DatetimeHelper";
// composable
import useDashboardSummaryComposable from "@/composables/dashboard/DashboardSummaryComposable";
import liff from '@line/liff';
import { account_profile } from '@/store/store'
export default {
    name: "DashboardInfo",
    components: {
        TopBar,
        Footer,
        DashboardDataCard,
        TrendGraph,
        TrendGraphLine,
        Table
        // VDateRangePicker
    },
    data: function () {
        return {
            cards: {},
            userActivities: {},
            trendGraphData: null,
            earnGraphData: null,
            burnGraphData: null,
            dtFilter: null
        };
    },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "User Management Dashboard"
                }
            ];
        },
        today: function () {
            let now = new Date();
            return DatetimeHelper.formatJSDate(now, "ccc, dd LLL yy");
        },
        maxDate: function () {
            // for test
            // let d = DateTime.fromJSDate(new Date())
            // d = d.plus({
            //     days: 3
            // })
            // return d.toJSDate()
            return new Date();
        }
    },
    setup: function () {
        const { dashboardSummaryStates } = useDashboardSummaryComposable();

        return {
            dashboardSummaryStates
        };
    },
    mounted: function () {
        // this.handleSignIn()
        // config date picker
        // this.dtFilter = DatetimeHelper.JSDateToISODate(new Date())

        this.cards = {
            dailysales: {
                label: "ยอดขายวันนี้ (บาท)",
                value: 3300,
                diff: 157,
                percentage: 8
            },
            monthlysales: {
                label: "ยอดขายเดือนนี้ (ก.พ./2565) (บาท)",
                value: 300080,
                diff: -679,
                percentage: 4
            },
            yearsales: {
                label: "ยอดขายรวมทั้งปี (บาท)",
                value: 5000,
                diff: 37,
                percentage: 2
            },
            year1ActiveUser: {
                label: "+1 Year User",
                value: 1000300,
                diff: 157,
                percentage: 11
            },
            topLuckyDraw: {
                label: "Top Lucky Draw",
                value: 84300,
                diff: 157,
                percentage: 8
            },
            topSpender: {
                label: "Top Spender",
                value: 20040,
                diff: 37,
                percentage: 2
            },
            dropOff: {
                label: "Drop Off",
                value: 2567,
                diff: -40,
                percentage: 4
            },
            potentialBuyer: {
                label: "Potential Buyer",
                value: 8565,
                diff: 4777,
                percentage: 11
            }
        };

        this.userActivities = {
            label: "User Activity",
            values: [
                {
                    text: "Visit",
                    value: 1466
                },
                {
                    text: "Login",
                    value: 1267
                },
                {
                    text: "Engage",
                    value: 890
                },
                {
                    text: "Buy",
                    value: 434
                }
            ]
        };
    },
    methods: {
        async handleSignIn() {
            try {
                await liff.init({ liffId: '1657398122-PDgNMpj0' });
                if (!liff.isLoggedIn()) {
                    liff.login();
                }
                else {
                    var lineprofile = await liff.getProfile()
                    account_profile.value.line = {
                        UserId: lineprofile?.UserId,
                        displayName: lineprofile?.displayName,
                        pictureUrl: lineprofile?.pictureUrl,
                        role: 'admin',
                        islogin: true

                    }
                    Cache.setCache("profile", account_profile.value);
                    // alert(JSON.stringify(await liff.getProfile(), null, 4))
                }
            } catch (error) {
                console.log(error);
                return null;
            }

        },
    }
};
</script>
<style lang="scss" scoped>
.flex-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 800px) {
    .flex-container {
        flex-direction: row;
        margin: 20px 0px;
    }

    .items__dashboard {
        flex-grow: 1;
        margin: 0px 10px;
    }

    .group-left {
        flex-grow: 1;
    }

    .group-right {
        flex-grow: 1;
        width: 50%;
        margin: 15px;
    }

    .flex-group-container {
        display: flex;
        flex-wrap: wrap;

        .item__dashboard {
            flex-grow: 1;
            width: 20%;
            margin: 15px;
        }
    }
}
</style>
