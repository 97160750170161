import Util from "@/helper/Utility.js";

/**
 * @export AddressListModel
 * @export AddressListModelBuilder
 */
export function AddressListModel() {
    this.lat = null;
    this.lng = null;
    this.name = null;
    this.contactNumber = null;
    this.addressName = null;
    this.building = null;
    this.village = null;
    this.address = null;
    this.number = null;
    this.soi = null;
    this.moo = null;
    this.room = null;
    this.floor = null;
    this.road = null;
    this.landmark = null;
    this.remark = null;
    this.subDistrictCode = null;
    this.subDistrictName = null;
    this.districtCode = null;
    this.districtName = null;
    this.provinceCode = null;
    this.provinceName = null;
    this.zipcode = null;
    this.isDefault = false;
    //tax
    this.isDefaultTax = null;
    this.isTax = false;
    this.taxName = null;
    this.taxAddress = null;
    this.taxBuilding = null;
    this.taxNumber = null;
    this.taxMoo = null;
    this.taxRoom = null;
    this.taxFloor = null;
    this.taxSoi = null;
    this.taxRoad = null;
    this.taxType = null; // 'individual' : 'company'
    this.firstname = null;
    this.lastname = null;
    this.taxZipcode = null;
    this.taxProvinceCode = null;
    this.taxProvinceName = null;
    this.taxDistrictCode = null;
    this.taxDistrictName = null;
    this.taxSubDistrictCode = null;
    this.taxSubDistrictName = null;
    this.taxId = null;
    this.countryId = null;
    this.countryCode = null;
    this.countryName = null;
    this.email = null;
    this.homeContactNumber = null;
    this.companyName = null;
    this.idCard = null;
    this.title = null; // prefix name
    this.personType = null;
    // database
    this.rowKey = null;
    this.type = null;
    this.partitionKey = null;

    this.setIsTax = function() {
        this.isTax = true;
        this.type = 'invoice';
    };

    this.getParams = function() {
        var params = {
            key: this.rowKey,
            isdefault: this.isDefault,
            latitude: this.lat,
            longtitude: this.lng,
            name: this.name,
            addressname: this.addressName,
            firstname: this.firstname,
            lastname: this.lastname,
            building: this.building,
            contact_number: this.contactNumber,
            type: this.type,
            address: this.address,
            village: this.village,
            number: this.number,
            moo: this.moo,
            room: this.room,
            floor: this.floor,
            soi: this.soi,
            road: this.road,
            remark: this.remark,
            landmark: this.landmark,
            subdistrict_code: this.subDistrictCode,
            subdistrict_name: this.subDistrictName,
            district_code: this.districtCode,
            district_name: this.districtName,
            province_code: this.provinceCode,
            province_name: this.provinceName,
            zipcode: this.zipcode,
            tax: this.tax,
            taxname: this.taxName,
            taxid: this.taxId,
            taxaddress: this.taxAddress,
            taxbuilding: this.taxBuilding,
            taxnumber: this.taxNumber,
            taxmoo: this.taxMoo,
            taxroom: this.taxRoom,
            taxfloor: this.taxFloor,
            taxsoi: this.taxSoi,
            taxroad: this.taxRoad,
            taxsubdistrict_code: this.taxSubDistrictCode,
            taxsubdistrict_name: this.taxSubDistrictName,
            taxdistrict_code: this.taxDistrictCode,
            taxdistrict_name: this.taxDistrictName,
            taxprovince_code: this.taxProvinceCode,
            taxprovince_name: this.taxProvinceName,
            taxzipcode: this.taxZipcode,
            countryId: this.countryId,
            conutry_code: this.conutryCode,
            country_name: this.countryName,
            email: this.email,
            isdefaulttax: this.isDefaultTax,
            home_contact_number: this.homeContactNumber,
            companyname: this.companyName,
            idcard: this.idCard,
            title: this.title,
            istax: this.isTax,
            persontype: this.personType,
        };

        return Util.cleanObjectEmptyStr(params);
    };

    this.setValueFormApi = function(data) {
        if (data == null) {
            data = {};
        }
        this.address = data.Address;
        this.addressName = data.AddressName;
        this.building = data.Building;
        this.companyName = data.CompanyName;
        this.contactNumber = data.ContactNumber;
        this.countryCode = data.CountryCode;
        this.countryId = data.CountryId;
        this.countryName = data.CountryName;
        this.districtCode = data.DistrictCode;
        this.districtName = data.DistrictName;
        this.email = data.Email;
        this.firstname = data.FirstName;
        this.floor = data.Floor;
        this.homeContactNumber = data.HomeContactNumber;
        this.idCard = data.IdCard;
        this.isDefault = data.IsDefault;
        this.isDefaultTax = data.IsDefaultTax;
        this.landmark = data.Landmark;
        this.lastname = data.LastName;
        this.lat = data.Latitude;
        this.lng = data.Longitude;
        this.moo = data.Moo;
        this.name = data.Name;
        this.number = data.Number;
        this.partitionKey = data.PartitionKey;
        this.provinceCode = data.ProvinceCode;
        this.provinceName = data.ProvinceName;
        this.remark = data.Remark;
        this.road = data.Road;
        this.room = data.Room;
        this.rowKey = data.RowKey;
        this.soi = data.Soi;
        this.subDistrictCode = data.SubDistrictCode;
        this.subDistrictName = data.SubDistrictName;
        this.taxAddress = data.TaxAddressName;
        this.taxDistrictCode = data.TaxDistrictCode;
        this.taxDistrictName = data.TaxDistrictName;
        this.taxId = data.TaxId;
        this.taxName = data.TaxName;
        this.taxNumber = data.TaxNumber;
        this.taxProvinceCode = data.TaxProvinceCode;
        this.taxProvinceName = data.TaxProvinceName;
        this.taxSoi = data.TaxSoi;
        this.taxSubDistrictCode = data.TaxSubDistrictCode;
        this.taxSubDistrictName = data.TaxSubDistrictName;
        this.taxZipcode = data.TaxZipcode;
        this.type = data.Type;
        this.village = data.Village;
        this.zipcode = data.Zipcode;
        this.title = data.Title;
        this.personType = data.PersonType;
    };

    this.setValueFromAddressModel = function(addressModel) {
        this.zipcode = addressModel.zipcode;
        this.provinceCode = addressModel.provinceCode;
        this.provinceName = addressModel.provinceName;
        this.districtCode = addressModel.districtCode;
        this.districtName = addressModel.districtName;
        this.subDistrictCode = addressModel.subDistrictCode;
        this.subDistrictName = addressModel.subDistrictName;
    };
}

export function AddressListModelBuilder() {
    this.addressListModel = new AddressListModel();

    this.create = function() {
        return this.addressListModel;
    };

    this.setValueFromAddressModel = function(addressModel) {
        this.setZipcode(addressModel.zipcode);
        this.setProvinceCode(addressModel.provinceCode);
        this.setProvinceName(addressModel.provinceName);
        this.setDistrictCode(addressModel.districtCode);
        this.setDistrictName(addressModel.districtName);
        this.setSubDistrictCode(addressModel.subDistrictCode);
        this.setSubDistrictName(addressModel.subDistrictName);
        return this;
    };

    this.setLat = function(lat) {
        this.addressListModel.lat = lat;
        return this;
    };
    this.setLng = function(lng) {
        this.addressListModel.lng = lng;
        return this;
    };
    this.setName = function(name) {
        this.addressListModel.name = name;
        return this;
    };
    this.setContactNumber = function(contactNumber) {
        this.addressListModel.contactNumber = contactNumber;
        return this;
    };
    this.setAddressName = function(addressName) {
        this.addressListModel.addressName = addressName;
        return this;
    };
    this.setBuilding = function(building) {
        this.addressListModel.building = building;
        return this;
    };
    this.setVillage = function(village) {
        this.addressListModel.village = village;
        return this;
    };
    this.setAddress = function(address) {
        this.addressListModel.address = address;
        return this;
    };
    this.setNumber = function(number) {
        this.addressListModel.number = number;
        return this;
    };
    this.setSoi = function(soi) {
        this.addressListModel.soi = soi;
        return this;
    };
    this.setMoo = function(moo) {
        this.addressListModel.moo = moo;
        return this;
    };
    this.setRoom = function(room) {
        this.addressListModel.room = room;
        return this;
    };
    this.setFloor = function(floor) {
        this.addressListModel.floor = floor;
        return this;
    };
    this.setRoad = function(road) {
        this.addressListModel.road = road;
        return this;
    };
    this.setLandmark = function(landmark) {
        this.addressListModel.landmark = landmark;
        return this;
    };
    this.setRemark = function(remark) {
        this.addressListModel.remark = remark;
        return this;
    };
    this.setSubDistrictCode = function(subDistrictCode) {
        this.addressListModel.subDistrictCode = subDistrictCode;
        return this;
    };
    this.setSubDistrictName = function(subDistrictName) {
        this.addressListModel.subDistrictName = subDistrictName;
        return this;
    };
    this.setDistrictCode = function(districtCode) {
        this.addressListModel.districtCode = districtCode;
        return this;
    };
    this.setDistrictName = function(districtName) {
        this.addressListModel.districtName = districtName;
        return this;
    };
    this.setProvinceCode = function(provinceCode) {
        this.addressListModel.provinceCode = provinceCode;
        return this;
    };
    this.setProvinceName = function(provinceName) {
        this.addressListModel.provinceName = provinceName;
        return this;
    };
    this.setZipcode = function(zipcode) {
        this.addressListModel.zipcode = zipcode;
        return this;
    };
    this.setIsDefault = function(isDefault) {
        this.addressListModel.isDefault = isDefault;
        return this;
    };
    this.setIsDefault = function(isDefault) {
        this.addressListModel.isDefault = isDefault;
        return this;
    };
    this.setIsTax = function(isTax) {
        this.addressListModel.isTax = isTax;
        return this;
    };
    this.setTaxName = function(taxName) {
        this.addressListModel.taxName = taxName;
        return this;
    };
    this.setTaxAddress = function(taxAddress) {
        this.addressListModel.taxAddress = taxAddress;
        return this;
    };
    this.setTaxBuilding = function(taxBuilding) {
        this.addressListModel.taxBuilding = taxBuilding;
        return this;
    };
    this.setTaxNumber = function(taxNumber) {
        this.addressListModel.taxNumber = taxNumber;
        return this;
    };
    this.setTaxMoo = function(taxMoo) {
        this.addressListModel.taxMoo = taxMoo;
        return this;
    };
    this.setTaxRoom = function(taxRoom) {
        this.addressListModel.taxRoom = taxRoom;
        return this;
    };
    this.setTaxFloor = function(taxFloor) {
        this.addressListModel.taxFloor = taxFloor;
        return this;
    };
    this.setTaxSoi = function(taxSoi) {
        this.addressListModel.taxSoi = taxSoi;
        return this;
    };
    this.setTaxRoad = function(taxRoad) {
        this.addressListModel.taxRoad = taxRoad;
        return this;
    };
    /**
     * @param {string} taxType  // 'individual' : 'company'
     */
    this.setTaxType = function(taxType) {
        this.addressListModel.taxType = taxType;
        return this;
    };
    this.setFirstname = function(firstname) {
        this.addressListModel.firstname = firstname;
        return this;
    };
    this.setLastname = function(lastname) {
        this.addressListModel.lastname = lastname;
        return this;
    };
    this.setTaxZipcode = function(taxZipcode) {
        this.addressListModel.taxZipcode = taxZipcode;
        return this;
    };
    this.setTaxProvinceCode = function(taxProvinceCode) {
        this.addressListModel.taxProvinceCode = taxProvinceCode;
        return this;
    };
    this.setTaxProvinceName = function(taxProvinceName) {
        this.addressListModel.taxProvinceName = taxProvinceName;
        return this;
    };
    this.setTaxDistrictCode = function(taxDistrictCode) {
        this.addressListModel.taxDistrictCode = taxDistrictCode;
        return this;
    };
    this.setTaxDistrictName = function(taxDistrictName) {
        this.addressListModel.taxDistrictName = taxDistrictName;
        return this;
    };
    this.setTaxSubDistrictCode = function(taxSubDistrictCode) {
        this.addressListModel.taxSubDistrictCode = taxSubDistrictCode;
        return this;
    };
    this.setTaxSubDistrictName = function(taxSubDistrictName) {
        this.addressListModel.taxSubDistrictName = taxSubDistrictName;
        return this;
    };
    this.setTaxId = function(taxId) {
        this.addressListModel.taxId = taxId;
        return this;
    };
    this.setCountryId = function(countryId) {
        this.addressListModel.countryId = countryId;
        return this;
    };
    this.setCountryCode = function(countryCode) {
        this.addressListModel.countryCode = countryCode;
        return this;
    };
    this.setCountryName = function(countryName) {
        this.addressListModel.countryName = countryName;
        return this;
    };
    this.setEmail = function(email) {
        this.addressListModel.email = email;
        return this;
    };
    this.setHomeContactNumber = function(homeContactNumber) {
        this.addressListModel.homeContactNumber = homeContactNumber;
        return this;
    };
    this.setCompanyName = function(companyName) {
        this.addressListModel.companyName = companyName;
        return this;
    };
    this.setIdCard = function(idCard) {
        this.addressListModel.idCard = idCard;
        return this;
    };
    this.setRowKey = function(rowKey) {
        this.addressListModel.rowKey = rowKey;
        return this;
    };
    this.setType = function(type) {
        this.addressListModel.type = type;
        return this;
    };
}
