// import Account from "@/helper/AccountHelper.js";
import Locale from "@/helper/locale/Locale.js";
//service
import BzbsProfile from "@/core/Account/service/BzbsProfile.js";

export default {
    inject: ["AccountStore"],
    data: function() {
        return {
            lbl: Locale.getLbl(),
            localeCode: Locale.getLocaleCode(),
        };
    },
    methods: {
        switchLang: function (locale) {
            if (this.localeCode == locale) {
                return;
            }

            Locale.setLocale(locale);
            this.lbl = Locale.getLbl();
            this.localeCode = Locale.getLocaleCode();

            if (this.AccountStore.state.isLogin) {
                let profile = this.AccountStore.state.profile
                profile.locale = this.localeCode;

                BzbsProfile.updateProfile(profile, null, null, null, null).subscribe((data) => {
                    console.log(data);
                    // let newProfile = data.profile;
                    // this.AccountStore.methods.setProfile(newProfile);

                    window.location.reload();
                }, (err) => {
                    this.errorApi(err);
                });
            } else {
                console.log('not login');
                // window.location.reload();
            }
        }
    },
};
