<template>
    <div class="pt-3 px-2" v-if="refresh">
        <InputText
                v-model:inputValue="addressModel.addressName"
                :errorInfo="errorInfo[0]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.addressName"
        ></InputText>
        <InputText
                v-model:inputValue="addressModel.firstname"
                :errorInfo="errorInfo[1]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.firstname"
        ></InputText>
        <InputText
                v-model:inputValue="addressModel.lastname"
                :errorInfo="errorInfo[2]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.lastname"
        ></InputText>
        <InputContactNumber
                v-model:inputValue="addressModel.contactNumber"
                :errorInfo="errorInfo[3]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.contactnumber"
                :minLength="10"
                :maxLength="12"
                delimeter="-"
        ></InputContactNumber>
        <InputText
                v-model:inputValue="addressModel.address"
                :errorInfo="errorInfo[4]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping.address"
        ></InputText>
        <FormAddress
                v-model:inputValue="formAddressModel"
                :errorInfo="errorInfo[5]"
                :isFirstSave="isFirstSave"
                :isShowErrorLbl="true"
                :inputLbl="lbl.shipping"
        ></FormAddress>
        <Checkbox
                v-model:check="addressModel.isDefault"
                :text="lbl.shipping.isDefaultAddress"
        ></Checkbox>
    </div>
</template>
<script>
// import Util from "@/helper/Utility.js";
import { FormHelper } from "@/helper/FormHelper.js";
//component
import InputText from "@/components/Input/label/InputText.vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import Checkbox from "@/components/Input/simple/Checkbox.vue";
import FormAddress from "@/components/Form/FormAddress.vue";
//mixin
import LocaleMixin from "@/mixin/LocaleMixin.js";
//service
import { AddressModel } from "@/core/Address/model/AddressModel.js";
import { AddressListModel } from "@/core/Address/model/AddressListModel.js";

export default {
    name: "FormDeliveryAddress",
    mixins: [LocaleMixin],
    components: {
        InputText,
        InputContactNumber,
        Checkbox,
        FormAddress,
    },
    data: function() {
        return {
            isFirstSave: true,
            errorInfo: [],
            refresh: true,

            formAddressModel: null,
            addressModel: null,
        };
    },
    created: function() {
        this.initForm(null);
        this.errorInfo = FormHelper.genErrorInfo(6);
    },
    methods: {
        initForm: function(address) {
            this.isFirstSave = true;

            if (address != null) {
                this.addressModel = {...address};
                this.formAddressModel.setValueFromAddressListModel(this.addressModel);
            } else {
                this.addressModel = new AddressListModel();
                this.formAddressModel = new AddressModel();
            }

            this.refresh = false;
            this.$nextTick(() => {
                this.refresh = true;
            });
        },
        clearForm: function() {
            this.initForm(null);
        },
        submitForm: function() {
            this.isFirstSave = false;
            if (this.validate()) {
                this.addressModel.setValueFromAddressModel(this.formAddressModel);
                return this.addressModel;
            }
            return null
        },
        validate: function() {
            return FormHelper.validate(this.errorInfo);
        },
    },
}
</script>
