/**
 * @function genErrorInfo
 * @function validate
 * @class ItemListValue
 */
 export const FormHelper = {
    genErrorInfo: function(formCount) {
        var errorInfo = [];
         for (var i = 0; i < formCount; i++) {
            let info = new ErrorInfo();
            errorInfo.push(info);
            // errorInfo.push({
            //     isValid: true,
            //     msg: '',
            // });
        }
        return errorInfo;
    },
    validate: function(errorInfo) {
        for (var i of errorInfo) {
            if (!i.isValid) {
                return false;
            }
        }
        return true;
    },
};

export function ErrorInfo() {
    this.isValid = true;
    this.msg = '';

    this.setValid = function (valid) {
        this.isValid = valid;
    };

    this.setMsg = function (msg) {
        this.msg = msg
    };
}


export function DropdownListValue() {
    this.list = [];

    /**
     * @param {String} text
     * @param {String} value
     */
    this.add = function(text, value) {
        this.list.push({
            text: text,
            value: value,
        });
    };

    this.getList = function() {
        return this.list;
    };
}
