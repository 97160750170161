import Config from "./vueconfig.js";
/**
 * environment
 * @function getAppId
 * @function getAppName
 * @function getApiUrl
 * @function getApiModuleUrl
 * @function getBlobUrl
 * @function getMiscUrl
 * @function getShoppingCartUrl
 * api config str
 * @function getCampaignConfig
 * @function getDashboardConfig
 * @function getCategoryConfig
 * @function getHistoryConfig
 * setting
 * @function getCacheType
 * custom
 * @function getCampaignListDevApp
 * @function getCategoryMenuDev
 */
export default {

    //#region normal environment
    getAppId: function () {
        return Config().client_id;
    },
    getAppName: function () {
        return Config().appname;
    },
    getApiUrl: function() {
        return Config().bzbsUrl;
    },
    getApiModuleUrl: function () {
        return Config().bzbsModuleUrl;
    },
    getBlobUrl: function() {
        return Config().bzbsBlobUrl;
    },
    getMiscUrl: function() {
        return Config().miscUrl;
    },
    getShoppingCartUrl: function() {
        return Config().bzbsShoppingCartUrl;
    },
    getApiShoppingCartUrl: function () {
        return Config().apiShoppingCartUrl;
    },
    getApi: function () {
        return Config().api;
    },
    //#endregion normal environment

    //#region config str
    getCampaignConfig: function() {
        return Config().campaignConfig;
    },
    getDashboardConfig: function() {
        return Config().dashboardConfig;
    },
    getCategoryConfig: function() {
        return Config().categoryConfig;
    },
    getHistoryConfig: function() {
        return Config().historyConfig;
    },
    //#endregion config str

    //#region setting
    getCacheType: function() {
        return Config().setting.cache;
    },
    getAddressType: function() {
        return Config().setting.addressType;
    },
    getFirebaseSetup: function () {
        return Config().firebase;
    },
    //#endregion setting

    //#region custom
    getCampaignListDevApp: function() {
        return this.getCampaignConfig().campaignListConfig;
    },
    getCategoryMenuDev: function() {
        return this.getCategoryConfig().menuCategory;
    },
    getHistoryPurchaseList: function() {
        return this.getHistoryConfig().purchase;
    },
    //#endregion custom
}
