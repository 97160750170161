<template>
    <div class="relative p-4 card">
    
        <template v-if="title != null">

            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
            
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <CheckOrderTransaction></CheckOrderTransaction>

            <div
                v-if="!rowselecteddetail.ishowdetail && !isshowspin"
                class="pb-3 pt-3"
            >
                <a-collapse
                    v-model:activeKey="activeKey"
                    :expand-icon-position="'right'"
                >
                    <a-collapse-panel key="1" header="ค้นหา">
                        <a-form
                            :model="criteria"
                            name="basic"
                            :label-col="{ span: 8 }"
                            :wrapper-col="{ span: 16 }"
                            autocomplete="off"
                            @finish="onFinishSearch"
                            @finishFailed="onFinishFailedSearch"
                        >
                            <a-row>
                                <a-col :span="8">
                                    <a-form-item
                                        label="ลำดับงาน"
                                        name="OrderTransaction"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.OrderTransaction
                                            "
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        label="รหัสลูกค้า"
                                        name="CustomerOrder"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.CustomerOrder
                                            "
                                        />
                                    </a-form-item>
                                    <a-form-item
                                        label="รายการขาย"
                                        name="ProductSellOrder"
                                    >
                                        <a-input
                                            v-model:value="
                                                criteria.ProductSellOrder
                                            "
                                        />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="8">
                                    <a-form-item
                                        label="ช่วง-ลำดับงาน"
                                        name="RangOrderTransaction"
                                    >
                                        <a-input-group compact>
                                            <a-input
                                                v-model:value="
                                                    criteria
                                                        .RangOrderTransaction[0]
                                                "
                                                type="number"
                                                style="width: 100px; text-align: center"
                                                placeholder="Minimum"
                                                :disabled="
                                                    !!criteria.OrderTransaction
                                                "
                                            />
                                            <a-input
                                                style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                                                placeholder="~"
                                                disabled
                                            />
                                            <a-input
                                                type="number"
                                                v-model:value="
                                                    criteria
                                                        .RangOrderTransaction[1]
                                                "
                                                style="width: 100px; text-align: center; border-left: 0"
                                                placeholder="Maximum"
                                                :disabled="
                                                    !!criteria.OrderTransaction
                                                "
                                            />
                                        </a-input-group>

                                        <a-slider
                                            v-model:value="
                                                criteria.RangOrderTransaction
                                            "
                                            range
                                            :disabled="
                                                !!criteria.OrderTransaction
                                            "
                                            :max="8000"
                                            :marks="marks"
                                        >
                                            <template #mark="{ label, point }">
                                                <template v-if="point === 100">
                                                    <strong>{{ label }}</strong>
                                                </template>
                                                <template v-else>{{
                                                    label
                                                }}</template>
                                            </template>
                                        </a-slider>
                                    </a-form-item>
                                    <a-form-item
                                        label="ชื่อ"
                                        name="name_search"
                                    >
                                        <a-row gutter="16">
                                            <a-col :span="12">
                                                <a-input
                                                placeholder="ชื่อ"
                                                    v-model:value="
                                                        criteria.Name_Search
                                                    "
                                                />
                                            </a-col>
                                            <a-col :span="12">
                                                <a-input
                                                placeholder="นามสกุล"
                                                    v-model:value="
                                                        criteria.LastName_Search
                                                    "
                                                />
                                            </a-col>
                                        </a-row>
                                    </a-form-item>
                                </a-col>
                            </a-row>

                            <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
                                <a-space :size="10">
                                    <a-button
                                        type="primary"
                                        @click="resetCriteria"
                                        >เครียร์</a-button
                                    >
                                    <a-button type="primary" html-type="submit"
                                        >ค้นหา</a-button
                                    >
                                    <a-tag v-if="isshowspin" color="processing">
                                        <template #icon>
                                            <sync-outlined :spin="true" />
                                        </template>
                                        processing
                                    </a-tag>
                                </a-space>
                            </a-form-item>
                        </a-form>
                    </a-collapse-panel>
                </a-collapse>
            </div>

            <a-table
                class="ant-table-striped"
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                size="middle"
                :scroll="{ x: 1300, y: 1000 }"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :pagination="false"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template
                    #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                >
                    <div style="padding: 8px">
                        <a-input
                            ref="searchInput"
                            :placeholder="`Search ${column.dataIndex}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="
                                e =>
                                    setSelectedKeys(
                                        e.target.value ? [e.target.value] : []
                                    )
                            "
                            @pressEnter="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        />
                        <a-button
                            type="primary"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        >
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button
                            size="small"
                            style="width: 90px"
                            @click="handleReset(clearFilters)"
                        >
                            Reset
                        </a-button>
                    </div>
                </template>

                <template v-if="false" #expandedRowRender="{ record }">
                    <Drawer
                        :sellorder="record.SellOrder"
                        :shipping="JSON.parse(record.Productshipping)"
                        @updatedata="updatedata"
                    ></Drawer>
                    <div class="mt-1">
                        <Previewslip
                            :slip="JSON.parse(record.SlipUrl)"
                        ></Previewslip>
                    </div>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'Order'">
                        <div @click="rowselected(record)">
                            <u>{{ index + 1 }}</u>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'OrderDate'">
                        <div
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                            style="text-align: center;"
                        >
                            <div style="font-size:10px" v-if="Item.OrderDate">
                                {{ formatdate(Item.OrderDate) }}
                            </div>
                            <div v-else>ไม่พบข้อมูล</div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'OrderTransaction'">
                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div v-if="idx == 0" @click="rowselected(record)">
                                <router-link
                                    :to="
                                        '/selllist?c=' +
                                            Item.CustomerOrder +
                                            '&n=' +
                                            Item.ContactName +
                                            '&ot=' +
                                            Item.OrderTransaction +
                                            '&so=' +
                                            Item.ProductSellOrder
                                    "
                                >
                                    <u> {{ Item.OrderTransaction }}</u>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <!-- {{groupBySellOrder(Item.ProductSellOrder)}} -->
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div v-if="idx == 0" style="font-size:12px">
                                {{ Item.CustomerOrder }}{{ Item.ContactName }}
                                <div class="pt-1">
                                    <font-awesome-icon
                                        icon="fa-solid fa-user"
                                        class="pr-2"
                                    />
                                    <a
                                        :href="
                                            '/consumermenu?cuscode=' +
                                                Item.CustomerOrder
                                        "
                                        target="_blank"
                                        ><u> Link ลูกค้า</u></a
                                    >
                                </div>
                                <div class="pt-1">
                                    <font-awesome-icon
                                        icon="fa-solid fa-check"
                                        class="pr-2"
                                    />
                                    <a
                                        :href="
                                            '/consumerhistoryconfirm?cuscode=' +
                                                Item.CustomerOrder
                                        "
                                        target="_blank"
                                        ><u> Link (ตรวจสอบ)</u></a
                                    >
                                </div>
                                <div class="pt-1">
                                    <font-awesome-icon
                                        icon="fa-solid fa-check"
                                        class="pr-2"
                                    />
                                    <a
                                        :href="
                                            '/consumerbirthday?cuscode=' +
                                                Item.CustomerOrder +
                                                '&so=' +
                                                Item.ProductSellOrder
                                        "
                                        target="_blank"
                                        ><u> Link (ลงวันเกิด)</u></a
                                    >
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <div
                            v-for="(Item, idx) of _.sortedUniq(
                                record.map(items => items.ProductSellOrder)
                            )"
                            :key="`item-${idx}`"
                        >
                            <a-button type="txt">
                                <a-tooltip placement="right">
                                    <a-typography-title
                                        :level="5"
                                        copyable
                                        style="font-size:12px"
                                        >{{ Item }}</a-typography-title
                                    >
                                </a-tooltip>
                            </a-button>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'ContactType'">
                        <div
                            v-if="
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == 'true'
                                    )
                                    ?.ContactType?.toUpperCase() == 'FACEBOOK'
                            "
                        >
                            <font-awesome-icon icon="fa-brands fa-facebook" />
                        </div>
                        <div
                            v-if="
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == 'true'
                                    )
                                    ?.ContactType?.toUpperCase() == 'WHATSAPP'
                            "
                        >
                            <font-awesome-icon icon="fa-brands fa-whatsapp" />
                        </div>
                        <div
                            v-if="
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == 'true'
                                    )
                                    ?.ContactType?.toUpperCase() == 'LINE'
                            "
                        >
                            <font-awesome-icon icon="fa-brands fa-line" />
                        </div>
                        <div
                            v-if="
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == 'true'
                                    )
                                    ?.ContactType?.toUpperCase() == 'INSTARGRAM'
                            "
                        >
                            <font-awesome-icon icon="fa-brands fa-instagram" />
                        </div>
                        <div
                            v-if="
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == 'true'
                                    )
                                    ?.ContactType?.toUpperCase() == 'TIKTOK'
                            "
                        >
                            <font-awesome-icon icon="fa-brands fa-tiktok" />
                        </div>
                        <div style="font-size:10px">
                            {{
                                record
                                    .find(
                                        item =>
                                            item.ContactStartDefault == "true"
                                    )
                                    ?.ContactType?.toUpperCase()
                            }}
                        </div>
                        <!-- <font-awesome-icon icon="fa-brands fa-whatsapp" />
                        <font-awesome-icon icon="fa-brands fa-facebook" />
                        <font-awesome-icon icon="fa-brands fa-line" />
                        <font-awesome-icon icon="fa-brands fa-instagram" /> -->
                        <!-- <pre>{{record}}</pre> -->
                        <div>
                            <a-space :size="30">
                                <a-tooltip v-if="false" placement="right">
                                    <div
                                        class="text-center pt-2"
                                        v-for="(Item, idx) of record"
                                        :key="`item-${idx}`"
                                        @click="rowselected(record)"
                                    >
                                        <div v-if="idx == 0">
                                            <U>ชื่อ :{{ Item.ContactName }}</U>
                                        </div>
                                    </div>
                                </a-tooltip>

                                <!-- <a-tag :color="
                                    record
                                        .find(
                                            item =>
                                                item.ContactStartDefault ==
                                                'true'
                                        )?.ContactType?.toUpperCase() ==
                                        'FACEBOOK'
                                        ? 'geekblue'
                                        : 'green'
                                ">
                                    {{
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    "true"
                                            )?.ContactType?.toUpperCase()
                                    }}
                                </a-tag> -->
                            </a-space>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator
                            :value="record.SellOrder"
                            :format="'CODE39'"
                            :lineColor="'black'"
                            :width="10"
                            :height="40"
                            :elementTag="'svg'"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img
                                class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                            />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span
                            >
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined
                                :style="{ color: 'red', fontSize: '14px' }"
                            />
                            <span :style="{ color: 'red', fontSize: '14px' }"
                                >ยังไม่ได้จัดส่ง</span
                            >
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Status'">
                        <div>
                            <div
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div v-if="idx == 0">
                                    <div v-if="Item.DeliveryStatus == 'packed'">
                                        <a-typography-text
                                            strong
                                            :style="{
                                                color: 'green',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <font-awesome-icon
                                                icon="fa-sharp fa-solid fa-truck-fast"
                                            />

                                            <span style="font-size:12px"
                                                >แพ็คสินค้าแล้ว</span
                                            >
                                        </a-typography-text>
                                    </div>
                                    <div
                                        v-else-if="
                                            Item.DeliveryStatus ==
                                                'prepare_pack'
                                        "
                                    >
                                        <a-typography-text
                                            strong
                                            :style="{
                                                color: 'orange',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-truck-pickup"
                                            />

                                            <span style="font-size:12px"
                                                >เตรียมแพ็คสินค้่า</span
                                            >
                                        </a-typography-text>
                                    </div>
                                    <div v-else-if="Item.PaymentMethod">
                                        <a-typography-text
                                            strong
                                            :style="{
                                                color: 'green',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <font-awesome-icon
                                                icon="fa-sharp fa-solid fa-clock"
                                            />

                                            <span style="font-size:12px"
                                                >รอเช็คเงิน</span
                                            >
                                        </a-typography-text>
                                    </div>

                                    <div v-else-if="Item.PaymentMethod == ''">
                                        <a-typography-text
                                            strong
                                            :style="{
                                                color: 'red',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-xmark"
                                            />

                                            <span style="font-size:12px"
                                                >ยังไม่ได้ชำระเงิน</span
                                            >
                                        </a-typography-text>
                                    </div>
                                    <div v-else>
                                        <a-typography-text
                                            strong
                                            :style="{
                                                color: 'red',
                                                fontSize: '14px'
                                            }"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-xmark"
                                            />
                                            <span style="font-size:14px"
                                                >ข้อมูลยังไม่ครบ</span
                                            >
                                        </a-typography-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Customertel'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <!-- {{Item.ProductResponsiblePerson}} -->
                            <div
                                v-if="Item.ProductResponsiblePerson && idx == 0"
                                class="flex items-start"
                            >
                                <div class="flex-auto">
                                    <div class="text-xs text-gray-1-light">
                                        {{
                                            JSON.parse(
                                                Item.ProductResponsiblePerson
                                            ).login?.shortedName
                                        }}
                                        |
                                        {{
                                            JSON.parse(
                                                Item.ProductResponsiblePerson
                                            ).login?.role
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'SystemYanDate'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div
                                v-if="
                                    Item.SystemYanDate &&
                                        isshowProductYan(
                                            Item.CustomerProductsType
                                        )
                                "
                                style="font-size:10px"
                            >
                                - วันที่ {{ formatdate(Item.SystemYanDate) }}
                            </div>
                            <div v-else>
                                -
                            </div>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'StatusAll'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-tag :color="Item.Color">{{
                                Item.StatusAll
                            }}</a-tag>
                        </div>

                        <div
                            v-if="false"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div
                                v-if="
                                    Item.Productdelivery &&
                                        Util.jsonParse(Item.Productdelivery)[0]
                                            ?.DeliveryStatus == 'shipped'
                                "
                            >
                                <a-tag color="green">จัดส่งแล้ว</a-tag>
                            </div>
                            <div v-else>
                                <div
                                    :style="{ fontSize: '12px' }"
                                    v-if="
                                        isshowProductYan(
                                            Item.CustomerProductsType
                                        )
                                    "
                                >
                                    <!-- Item.CustomerNotConfirmAddressAlready: {{ Item.CustomerNotConfirmAddressAlready }} -->
                                    <div
                                        v-if="
                                            Item.Payment &&
                                                !Util.jsonParse(Item.Payment)[
                                                    idx
                                                ]?.Checking
                                        "
                                        :style="{ fontSize: '12px' }"
                                        v-show="
                                            (Item.Payment &&
                                                Util.jsonParse(Item.Payment)[
                                                    idx
                                                ]?.Price) != null
                                        "
                                    >
                                        <u>-รอบัญชีตรวจสอบ</u>
                                    </div>
                                    <div
                                        v-show="
                                            Item.CustomerNotConfirmAddressAlready !=
                                                'confirm' &&
                                                (Item.CustomerNotConfirmAddressAlready ==
                                                    'notconfirm' ||
                                                    Item.CustomerNotConfirmAddressAlready ==
                                                        '')
                                        "
                                    >
                                        <u>-รอลูกค้ายืนยันที่อยุ่</u>
                                    </div>
                                    <div
                                        v-show="
                                            Item.CustomerNotConfirmAddressAlready ==
                                                'donotconfirm'
                                        "
                                    >
                                        <u>-รอลูกค้ายืนยันที่อยุ่</u>
                                    </div>
                                    <div
                                        v-show="
                                            Item.CustomerNotConfirmAddressAlready ==
                                                'confirm'
                                        "
                                    >
                                        <u>-เตรียมสร้าง SP ได้</u>
                                    </div>
                                    <div v-show="Item.HasAddress <= 0">
                                        <u>-รอลูกค้าแจ้งที่อยู่จัดส่ง</u>
                                    </div>
                                    <div
                                        v-show="
                                            Item.RemindNotpayDelivery == 'paid'
                                        "
                                    >
                                        <u>-รอลูกค้าชำระค่าส่ง</u>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        !isshowProductYan(
                                            Item.CustomerProductsType
                                        )
                                    "
                                >
                                    <div
                                        v-if="
                                            Util.jsonParse(
                                                Item.ProfessorType
                                            ).includes('9')
                                        "
                                    >
                                        <div
                                            :style="{ fontSize: '12px' }"
                                            v-show="Item.ContactName"
                                        >
                                            <u>-เตรียมสร้าง SP ได้</u>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div
                                            :style="{ fontSize: '12px' }"
                                            v-show="
                                                (Item.Payment &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[idx]?.Price) != null &&
                                                    Item.ContactName &&
                                                    checkbirthdayofyan(Item) &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[idx]?.Checking !=
                                                        'checked'
                                            "
                                        >
                                            <u>-รอบัญชีตรวจสอบ</u>
                                        </div>
                                        <div
                                            :style="{ fontSize: '12px' }"
                                            v-show="
                                                (Item.Payment &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[idx]?.Price) != null &&
                                                    Item.ContactName &&
                                                    checkbirthdayofyan(Item) &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[idx]?.Checking ==
                                                        'checked'
                                            "
                                        >
                                            <u>-เตรียมสร้าง SP ได้</u>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'QueYanDate'">
                        <div
                            v-for="(Item, idx) of _.sortedUniq(
                                record.map(items => items.Payment)
                            )"
                            :key="`item-${idx}`"
                        >
                            <div
                                class="text-center"
                                v-for="(sub, idxs) of _.groupBy(
                                    Util.jsonParse(Item),
                                    car => car.Checking
                                )"
                            >
                                <!-- <pre>{{sub[0].CheckMessage}}</pre> -->
                                <div
                                    v-if="sub[0].CheckMessage"
                                    v-for="(item, idx) of Util.jsonParse(
                                        sub[0].CheckMessage
                                    )"
                                >
                                    <div
                                        v-if="
                                            idx + 1 ==
                                                JSON.parse(sub[0].CheckMessage)
                                                    .length
                                        "
                                    >
                                        <div v-if="item.checking == 'checked'">
                                            <div
                                                v-for="(subItem, idx) of record"
                                                :key="`item-${idx}`"
                                            >
                                                <div
                                                    v-if="
                                                        subItem.SystemYanDate &&
                                                            subItem.QueYanDate &&
                                                            isshowProductYan(
                                                                subItem.CustomerProductsType
                                                            )
                                                    "
                                                    style="font-size:10px"
                                                >
                                                    - วันที่
                                                    {{
                                                        formatdate(
                                                            subItem.QueYanDate
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        subItem.SystemYanDate &&
                                                            !subItem.QueYanDate
                                                    "
                                                >
                                                    <a-tooltip>
                                                        <template #title
                                                            >สินค้าที่
                                                            {{ idx + 1 }}
                                                            (รอแจ้งใบเขียว)</template
                                                        >
                                                        <!-- <span class="dot-yellow"></span> -->
                                                        <file-exclamation-outlined
                                                            :style="{
                                                                fontSize:
                                                                    '32px',
                                                                color: 'hotpink'
                                                            }"
                                                        />
                                                    </a-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="
                                                item.checking == 'reject'
                                            "
                                        >
                                            <a-tooltip>
                                                <template #title
                                                    >(บัญชียังไม่อนุมัติ)</template
                                                >
                                                <span class="dot-red"></span>
                                            </a-tooltip>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                        </div>

                        <!-- <div v-for="( Item, idx ) of  record " :key="`item-${idx}`">
                            <div v-if="Item.SystemYanDate && Item.QueYanDate && isshowProductYan(Item.CustomerProductsType)"
                                style="font-size:12px">
                                - วันที่ {{ formatdate(Item.QueYanDate) }}
                            </div>
                            <div v-if="Item.SystemYanDate">
                                เหลือง (รอแจ้งใบเขียว)
                            </div>
                        </div> -->
                    </template>

                    <template v-if="column.dataIndex === 'PaymentStatus'">
                        <div
                            v-for="(itemrec, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div
                                v-if="
                                    itemrec?.ProfessorType &&
                                        itemrec?.Payment &&
                                        Util.jsonParse(
                                            itemrec?.ProfessorType
                                        ).includes('9') &&
                                        !(
                                            Util.jsonParse(itemrec?.Payment)[0]
                                                ?.Checking == 'checked'
                                        )
                                "
                            >
                                <a-tag color="#2db7f5"> เก็บเงินปลายทาง</a-tag>
                            </div>
                            <div v-else>
                                <div
                                    v-for="(Item, idx) of _.sortedUniq(
                                        record.map(items => items.Payment)
                                    )"
                                    :key="`item-${idx}`"
                                >
                                    <div
                                        v-for="(sub, idxs) of Util.jsonParse(
                                            Item
                                        )"
                                    >
                                        <a-typography-text
                                            style="font-size:12px"
                                            type="success"
                                            strong
                                            v-if="sub.Checking == 'checked'"
                                        >
                                            <a-tooltip>
                                                <template #title>
                                                    <div>
                                                        <u>ประวัติ</u> รายการ
                                                        <a-tag
                                                            color="#108ee9"
                                                            >{{
                                                                sub.ProductSellOrder
                                                            }}</a-tag
                                                        >
                                                        <!-- <pre>{{sub[0].CheckMessage}}</pre> -->
                                                        <div
                                                            v-for="(item,
                                                            idx) of JSON.parse(
                                                                sub.CheckMessage
                                                            )"
                                                            :key="
                                                                `v-list-item-${idx}`
                                                            "
                                                        >
                                                            <a-row type="flex">
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    สถานะ:
                                                                    <a-typography-text
                                                                        v-if="
                                                                            item.checking ==
                                                                                'reject'
                                                                        "
                                                                        type="danger"
                                                                        strong
                                                                    >
                                                                        <font-awesome-icon
                                                                            icon="fa-solid fa-xmark"
                                                                        />
                                                                        ไม่ผ่าน</a-typography-text
                                                                    >
                                                                    <a-typography-text
                                                                        type="success"
                                                                        strong
                                                                        v-else
                                                                    >
                                                                        <font-awesome-icon
                                                                            icon="fa-solid fa-check"
                                                                        />
                                                                        ผ่าน</a-typography-text
                                                                    >
                                                                </a-col>
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    รายละเอียด:
                                                                    <a-typography-text
                                                                        v-if="
                                                                            item.checking ==
                                                                                'reject'
                                                                        "
                                                                        type="danger"
                                                                        strong
                                                                    >
                                                                        {{
                                                                            item.message ||
                                                                                "-"
                                                                        }}</a-typography-text
                                                                    >
                                                                    <a-typography-text
                                                                        type="success"
                                                                        strong
                                                                        v-else
                                                                    >
                                                                        {{
                                                                            item.message ||
                                                                                "-"
                                                                        }}</a-typography-text
                                                                    >
                                                                </a-col>
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    <div>
                                                                        วันที่:
                                                                        {{
                                                                            formatdatetool(
                                                                                item.updatedate
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </a-col>
                                                            </a-row>
                                                        </div>
                                                    </div>
                                                </template>
                                                <span v-if="idxs == 0">
                                                    <font-awesome-icon
                                                        icon="fa-solid fa-check"
                                                    />เข้าแล้ว</span
                                                >
                                            </a-tooltip>
                                        </a-typography-text>
                                        <a-typography-text
                                            style="font-size:12px"
                                            type="warning"
                                            strong
                                            v-if="sub.Checking == 'reject'"
                                        >
                                            <a-tooltip>
                                                <template #title>
                                                    <div>
                                                        <u>ประวัติ</u> รายการ
                                                        <a-tag
                                                            color="#108ee9"
                                                            >{{
                                                                sub.ProductSellOrder
                                                            }}</a-tag
                                                        >
                                                        <div
                                                            v-for="(item,
                                                            idx) of JSON.parse(
                                                                sub.CheckMessage
                                                            )"
                                                            :key="
                                                                `v-list-item-${idx}`
                                                            "
                                                        >
                                                            <a-row type="flex">
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    สถานะ:
                                                                    <a-typography-text
                                                                        v-if="
                                                                            item.checking ==
                                                                                'reject'
                                                                        "
                                                                        type="danger"
                                                                        strong
                                                                    >
                                                                        <font-awesome-icon
                                                                            icon="fa-solid fa-xmark"
                                                                        />
                                                                        ไม่ผ่าน</a-typography-text
                                                                    >
                                                                    <a-typography-text
                                                                        type="success"
                                                                        strong
                                                                        v-else
                                                                    >
                                                                        <font-awesome-icon
                                                                            icon="fa-solid fa-check"
                                                                        />
                                                                        ผ่าน</a-typography-text
                                                                    >
                                                                </a-col>
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    รายละเอียด:
                                                                    <a-typography-text
                                                                        v-if="
                                                                            item.checking ==
                                                                                'reject'
                                                                        "
                                                                        type="danger"
                                                                        strong
                                                                    >
                                                                        {{
                                                                            item.message ||
                                                                                "-"
                                                                        }}</a-typography-text
                                                                    >
                                                                    <a-typography-text
                                                                        type="success"
                                                                        strong
                                                                        v-else
                                                                    >
                                                                        {{
                                                                            item.message ||
                                                                                "-"
                                                                        }}</a-typography-text
                                                                    >
                                                                </a-col>
                                                                <a-col
                                                                    :flex="1"
                                                                >
                                                                    <div>
                                                                        วันที่:
                                                                        {{
                                                                            formatdatetool(
                                                                                item.updatedate
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </a-col>
                                                            </a-row>
                                                        </div>
                                                    </div>
                                                </template>

                                                <span v-if="idxs == 0"
                                                    ><font-awesome-icon
                                                        icon="fa-solid fa-xmark"
                                                    />
                                                    ไม่ผ่าน</span
                                                >
                                            </a-tooltip>
                                        </a-typography-text>
                                        <a-typography-text
                                            type="warning"
                                            strong
                                            v-else-if="
                                                sub.PayType == '' &&
                                                    sub.Price <= 0
                                            "
                                        >
                                            <span style="font-size:12px"
                                                ><font-awesome-icon
                                                    icon="fa-solid fa-xmark"
                                                />
                                                สินค้า
                                                {{ idx + 1 }} ไม่ได้ลงข้อมูลเงิน
                                            </span>
                                        </a-typography-text>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="false"
                            v-for="(Item, idx) of record.map(
                                items => items.Payment
                            )"
                            :key="`item-${idx}`"
                        >
                            <div
                                class="text-center"
                                v-for="(sub, idxs) of _.groupBy(
                                    Util.jsonParse(Item),
                                    car => car.Checking
                                )"
                            >
                                <a-typography-text
                                    type="success"
                                    strong
                                    v-if="idxs == 'checked'"
                                >
                                    <span style="font-size:12px">
                                        <font-awesome-icon
                                            icon="fa-solid fa-check"
                                        />

                                        <a-tooltip>
                                            <template #title>
                                                <div>
                                                    <u>ประวัติ</u>
                                                    <div
                                                        v-for="(item,
                                                        idx) of JSON.parse(
                                                            sub[0].CheckMessage
                                                        )"
                                                        :key="
                                                            `v-list-item-${idx}`
                                                        "
                                                    >
                                                        <a-row type="flex">
                                                            <a-col :flex="1">
                                                                สถานะ:
                                                                <a-typography-text
                                                                    v-if="
                                                                        item.checking ==
                                                                            'reject'
                                                                    "
                                                                    type="danger"
                                                                    strong
                                                                >
                                                                    <font-awesome-icon
                                                                        icon="fa-solid fa-xmark"
                                                                    />
                                                                    ไม่ผ่าน</a-typography-text
                                                                >
                                                                <a-typography-text
                                                                    type="success"
                                                                    strong
                                                                    v-else
                                                                >
                                                                    <font-awesome-icon
                                                                        icon="fa-solid fa-check"
                                                                    />
                                                                    ผ่าน</a-typography-text
                                                                >
                                                            </a-col>
                                                            <a-col :flex="1">
                                                                รายละเอียด:
                                                                <a-typography-text
                                                                    v-if="
                                                                        item.checking ==
                                                                            'reject'
                                                                    "
                                                                    type="danger"
                                                                    strong
                                                                >
                                                                    {{
                                                                        item.message ||
                                                                            "-"
                                                                    }}</a-typography-text
                                                                >
                                                                <a-typography-text
                                                                    type="success"
                                                                    strong
                                                                    v-else
                                                                >
                                                                    {{
                                                                        item.message ||
                                                                            "-"
                                                                    }}</a-typography-text
                                                                >
                                                            </a-col>
                                                            <a-col :flex="1">
                                                                <div>
                                                                    วันที่:
                                                                    {{
                                                                        formatdatetool(
                                                                            item.updatedate
                                                                        )
                                                                    }}
                                                                </div>
                                                            </a-col>
                                                        </a-row>
                                                    </div>
                                                </div>
                                            </template>
                                            สินค้า {{ idx + 1 }} เข้าแล้ว
                                        </a-tooltip>
                                    </span>
                                    <hr />
                                </a-typography-text>
                                <a-typography-text
                                    type="warning"
                                    strong
                                    v-else-if="idxs == 'reject'"
                                >
                                    <span style="font-size:12px"
                                        ><font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                        />

                                        <a-tooltip>
                                            <template #title>
                                                <div>
                                                    <u>ประวัติ</u>
                                                    <!-- <pre>{{sub[0].CheckMessage}}</pre> -->
                                                    <div
                                                        v-for="(item,
                                                        idx) of JSON.parse(
                                                            sub[0].CheckMessage
                                                        )"
                                                        :key="
                                                            `v-list-item-${idx}`
                                                        "
                                                    >
                                                        <a-row type="flex">
                                                            <a-col :flex="1">
                                                                สถานะ:
                                                                <a-typography-text
                                                                    v-if="
                                                                        item.checking ==
                                                                            'reject'
                                                                    "
                                                                    type="danger"
                                                                    strong
                                                                >
                                                                    <font-awesome-icon
                                                                        icon="fa-solid fa-xmark"
                                                                    />
                                                                    ไม่ผ่าน</a-typography-text
                                                                >
                                                                <a-typography-text
                                                                    type="success"
                                                                    strong
                                                                    v-else
                                                                >
                                                                    <font-awesome-icon
                                                                        icon="fa-solid fa-check"
                                                                    />
                                                                    ผ่าน</a-typography-text
                                                                >
                                                            </a-col>
                                                            <a-col :flex="1">
                                                                รายละเอียด:
                                                                <a-typography-text
                                                                    v-if="
                                                                        item.checking ==
                                                                            'reject'
                                                                    "
                                                                    type="danger"
                                                                    strong
                                                                >
                                                                    {{
                                                                        item.message ||
                                                                            "-"
                                                                    }}</a-typography-text
                                                                >
                                                                <a-typography-text
                                                                    type="success"
                                                                    strong
                                                                    v-else
                                                                >
                                                                    {{
                                                                        item.message ||
                                                                            "-"
                                                                    }}</a-typography-text
                                                                >
                                                            </a-col>
                                                            <a-col :flex="1">
                                                                <div>
                                                                    วันที่:
                                                                    {{
                                                                        formatdatetool(
                                                                            item.updatedate
                                                                        )
                                                                    }}
                                                                </div>
                                                            </a-col>
                                                        </a-row>
                                                    </div>
                                                </div>
                                            </template>
                                            สินค้า {{ idx + 1 }} ยังไม่เข้า
                                        </a-tooltip>
                                    </span>
                                    <hr />
                                </a-typography-text>
                                <a-typography-text
                                    type="warning"
                                    strong
                                    v-else-if="
                                        Util.jsonParse(Item)[0].PayType == ''
                                    "
                                >
                                    <span style="font-size:12px"
                                        ><font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                        />
                                        สินค้า
                                        {{ idx + 1 }} ไม่ได้ลงข้อมูลการชำระเงิน
                                    </span>
                                </a-typography-text>
                                <!-- <a-typography-text type="warning" strong
                                    v-else-if="Util.jsonParse(Item)[0].PayType != '' && Util.jsonParse(Item)[0].Price > 0">
                                    <span style="font-size:12px"><font-awesome-icon icon="fa-solid fa-xmark" />
                                        สินค้า {{ idx + 1 }} รอการตรวจสอบการเงิน
                                    </span>
                                </a-typography-text> -->
                            </div>
                            <!-- <pre>{{ setformatmap(Util.jsonParse(Item)) }}</pre> -->

                            <!-- {{ _.sortedUniq(Util.jsonParse(Item.Payment).map(items => items.ProductSellOrder)) }} -->
                            <!-- ProductSellOrder -->
                            <!-- <div v-if="idx == 0" v-for="(sub, subidx) of setformatmap(Util.jsonParse(Item.Payment))"
                                :key="`item-${subidx}`">
                                <div v-if="subidx == 0">
                                    <a-typography-text type="success" strong
                                        v-if="(sub.Checking == 'checked') && Item.ContactName && Item.CustomerAddress">
                                        <font-awesome-icon icon="fa-solid fa-check" />
                                        <span style="font-size:12px">{{ idx + 1 }}.เข้าแล้ว(ตรวจสอบผ่าน)</span>
                                        <hr />
                                    </a-typography-text>
                                    <a-typography-text type="warning" strong v-else-if="(sub.Checking == 'reject')">
                                        <font-awesome-icon icon="fa-solid fa-xmark" />
                                        <span style="font-size:12px">{{ idx + 1 }}.ยังไม่เข้า(ตรวจแล้วไม่ผ่าน)</span>
                                        <hr />
                                    </a-typography-text>
                                    <a-typography-text type="danger" strong v-else="(sub.Checking)">
                                        <span style="font-size:12px;">{{ idx + 1 }}.ยังไม่ได้ตรวจสอบ</span>
                                        <hr />
                                    </a-typography-text>
                                </div>
                            </div>
                            <div v-if="Item.Payment == null">
                                <span style="font-size:12px;">- ยังไม่มีข้อมูล</span>
                            </div> -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <!-- <div
                                    v-if="(Item.IncreaseSizeOrder != '' && Item.OrderTransaction == Item.IncreaseSizeOrder)">
                                    <a-typography-text type="success"
                                        style="font-size:10px">(เพิ่มขนาดทัน)</a-typography-text>
                                </div> -->
                                <div
                                    v-if="
                                        Item.IncreaseSizeOrder != '' &&
                                            Item.OrderTransactionFrom != ''
                                    "
                                >
                                    <div
                                        v-if="
                                            JSON.parse(
                                                JSON.stringify(
                                                    Item.ProfessorType
                                                )
                                            ).includes('12')
                                        "
                                    >
                                        <a-typography-text
                                            type="success"
                                            style="font-size:12px;color: black;"
                                            >(เพิ่มขนาดโดยใช้แทร็คใหม่) ลำดับงาน
                                            {{
                                                Item.OrderTransactionFrom
                                            }}</a-typography-text
                                        >
                                    </div>
                                    <div v-else>
                                        <a-typography-text
                                            type="success"
                                            style="font-size:12px;color: #128F8B;"
                                            >(เพิ่มขนาดทัน) ลำดับงาน
                                            {{
                                                Item.OrderTransactionFrom
                                            }}</a-typography-text
                                        >
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        Item.HeartYanName ==
                                            'เพิ่มขนาดยังไม่ได้ลงระบบ/ยันต์มัดจำปรึกษาอาจารย์'
                                    "
                                >
                                    <a-typography-text
                                        type="success"
                                        style="font-size:12px;color: #E820FF;"
                                        >(เพิ่มขนาดยังไม่ลงระบบ)
                                        <!-- ลำดับงาน {{Item.IncreaseSizeOrder }} -->
                                    </a-typography-text>
                                </div>
                                <div class="p-1" v-if="Item.ProfessorType">
                                    <div
                                        v-if="
                                            Util.jsonParse(
                                                Item.ProfessorType
                                            ).includes('9')
                                        "
                                    >
                                        <a-tag
                                            v-if="
                                                Item.ContactName &&
                                                    checkbirthdayofyan(Item)
                                            "
                                            color="#87d068"
                                            >- สินค้า
                                            {{ idx + 1 }} ข้อมูลครบ</a-tag
                                        >
                                        <a-tag v-else color="#f50"
                                            >- สินค้า
                                            {{ idx + 1 }} ข้อมูลไม่ครบ</a-tag
                                        >
                                    </div>
                                    <div v-else>
                                        <a-tag
                                            v-if="
                                                (Item.Payment &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[0]?.Price) != null &&
                                                    Util.jsonParse(
                                                        Item.Payment
                                                    )[0]?.Price > 0 &&
                                                    Item.ContactName &&
                                                    checkbirthdayofyan(Item)
                                            "
                                            color="#87d068"
                                            >- สินค้า
                                            {{ idx + 1 }} ข้อมูลครบ</a-tag
                                        >
                                        <a-tag v-else color="#f50"
                                            >- สินค้า
                                            {{ idx + 1 }} ข้อมูลไม่ครบ</a-tag
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'Increase'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <div
                                v-if="
                                    Item.IncreaseSizeOrder != '' &&
                                        Item.OrderTransaction ==
                                            Item.IncreaseSizeOrder
                                "
                            >
                                <a-typography-text
                                    type="success"
                                    style="font-size:10px"
                                    >(พข)</a-typography-text
                                >
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusProduct'">
                        <div>
                            <!-- <pre>{{record})}}</pre> -->
                            <div
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <!-- record:<pre> {{Item.HeartYanName}} </pre> -->
                                <div v-if="Item.ProfessorType">
                                    <!-- <a-typography-text mark strong v-if="
                                        JSON.parse(Item.ProfessorType)?.includes(
                                            '2'
                                        )
                                    ">*รอคุยกับอาจารย์</a-typography-text>
                                    <a-typography-text type="danger" strong v-else-if="Item.ProductStatus == 'cancel'">
                                        ยกเลิกรายการ</a-typography-text>
                                    <a-typography-text mark type="success" strong v-else-if="
                                        JSON.parse(
                                            Item.ProfessorType
                                        )?.includes('1')
                                    ">อาจารย์เลือกให้</a-typography-text> -->
                                    <!-- <pre>{{ Item.HeartYanName }}</pre> -->
                                    <!-- <div
                                        v-if="(Item.IncreaseSizeOrder != '' && Item.OrderTransaction == Item.IncreaseSizeOrder)">
                                        <a-typography-text type="success" style="font-size:12px;color: #128F8B;">(เพิ่มขนาดทัน) ลำดับงาน {{
                                            Item.OrderTransactionFrom }}</a-typography-text>
                                    </div> -->
                                    <div
                                        v-if="
                                            JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('11')
                                        "
                                    >
                                        <a-typography-text
                                            type="success"
                                            style="color: #FF6F1D;"
                                            >(เพิ่มขนาดไม่ทัน)</a-typography-text
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            Item.Stockuuid != null &&
                                                Item.Stockuuid != ''
                                        "
                                    >
                                        <!-- <pre>{{Item}}</pre> -->
                                        <a-typography-text
                                            type="success"
                                            style="color: #FF6F1D;"
                                            ><div
                                                @click="
                                                    gotostockdetail(
                                                        Item.Stockuuid
                                                    )
                                                "
                                            >
                                                <u>(ใช้ผืนสต๊อก)</u>
                                            </div></a-typography-text
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('13')
                                        "
                                    >
                                        <!-- <pre>{{Item}}</pre> -->
                                        <a-typography-text
                                            type="success"
                                            style="color: #FF6F1D;"
                                            ><a
                                                :href="
                                                    `https://backofficestock.vercel.app/selllist??c=${Item.CustomerOrder}&n=undefined&ot=${Item.OrderTransaction}&so=${Item.ProductSellOrder}#no-back-button`
                                                "
                                                target="_blank"
                                                >(ย้ายสินค้าเข้าสต๊อก)</a
                                            ></a-typography-text
                                        >
                                    </div>
                                    <a-tag
                                        v-else-if="
                                            !Item.SystemYanDate &&
                                                isshowProductYan(
                                                    Item.CustomerProductsType
                                                )
                                        "
                                        color="#f50"
                                    >
                                        - สินค้า
                                        {{ idx + 1 }}
                                        ยันต์ยังไม่ได้ลงระบบ</a-tag
                                    >
                                    <a-tag
                                        v-else-if="
                                            !Item.SystemYanDate &&
                                                isshowProductYan(
                                                    Item.CustomerProductsType
                                                )
                                        "
                                        color="#87d068"
                                        >- สินค้า {{ idx + 1 }}{{}}
                                        ยันต์ลงระบบแล้ว</a-tag
                                    >
                                    <a-tag
                                        v-else-if="
                                            (Item.Payment &&
                                                Util.jsonParse(Item.Payment)[
                                                    idx
                                                ]?.Price) == false &&
                                                Item.SystemYanDate &&
                                                isshowProductYan(
                                                    Item.CustomerProductsType
                                                )
                                        "
                                        color="#e7bb08"
                                        >- สินค้า
                                        {{ idx + 1 }}
                                        ยันต์ลงระบบแล้วแต่ยังไม่ได้ชำระ</a-tag
                                    >
                                    <div v-else>
                                        <a-tag
                                            v-if="
                                                isshowProductYan(
                                                    Item.CustomerProductsType
                                                ) && !Item.QueYanDate
                                            "
                                            color="#108ee9"
                                            >- สินค้า
                                            {{ idx + 1 }}
                                            รอแจ้งคิวเขียน</a-tag
                                        >
                                        <div
                                            class="p-1"
                                            v-else-if="
                                                isshowProductYan(
                                                    Item.CustomerProductsType
                                                ) &&
                                                    Item.QueYanDate &&
                                                    Item.SystemYanDate
                                            "
                                        >
                                            <div
                                                v-if="
                                                    ischecked(
                                                        Item.SystemCheckTabActive_1
                                                    )
                                                "
                                            >
                                                <a-tag
                                                    style="width:150px"
                                                    color="#87d068"
                                                    >สินค้า {{ idx + 1 }} ลงระบบ
                                                    : ผ่าน</a-tag
                                                >
                                            </div>
                                            <!-- <div v-if="ischecked(Item.SystemCheckTabActive_2)">
                                                <a-tag style="width:150px" color="#87d068">สินค้า
                                                    {{ idx + 1 }} ลงคิวเขียน : ผ่าน</a-tag>
                                            </div> -->
                                            <div
                                                v-if="
                                                    isreject(
                                                        Item.SystemCheckTabActive_1
                                                    )
                                                "
                                            >
                                                <a-tag
                                                    style="width:150px"
                                                    color="#f50"
                                                    >สินค้า {{ idx + 1 }} ลงระบบ
                                                    : ไม่ผ่าน</a-tag
                                                >
                                            </div>
                                            <!-- <div v-if="isreject(Item.SystemCheckTabActive_2)">
                                                <a-tag style="width:150px" color="#f50">สินค้า
                                                    {{ idx + 1 }} ลงคิวเขียน : ไม่ผ่าน</a-tag>
                                            </div> -->
                                            <!-- <a-tag color="#87d068">ลงวันที่ระบบกับคิวแล้ว</a-tag> -->
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!-- Pagination component -->
                </template>
            </a-table>
            <div
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                class="wrapper-pagination"
            >
                <div>
                    <a-tag v-if="isshowspin" color="processing">
                        <template #icon>
                            <sync-outlined :spin="true" />
                        </template>
                        processing
                    </a-tag>
                </div>

                <a-pagination
                    :disabled="isgetDataSearch"
                    :current="pagination.currentPage"
                    :pageSize="pagination.pageSize"
                    @change="handlePaginationChange"
                    :total="500"
                    size="small"
                >
                </a-pagination>
            </div>

            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
            <Noti
                v-if="query.p"
                :title="query.p"
                :message="'สามาถสร้างข้อมูลการขายได้'"
            ></Noti>
        </div>
        <FromCreateSell
            v-if="rowselecteddetail.ishowdetail"
            :rowselected="rowselecteddetail"
        ></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import {
    CloseOutlined,
    FileExclamationOutlined,
    SyncOutlined,
    SettingOutlined
} from "@ant-design/icons-vue";

import {
    computed,
    defineComponent,
    onMounted,
    watch,
    ref,
    reactive
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import Drawer from "./Drawer.vue";
import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import BarcodeGenerator from "./BarcodeGenerator.vue";
import CheckOrderTransaction from "./CheckOrderTransaction.vue";

import Noti from "@/components/Noti/Noti.vue";

import Util from "@/helper/Utility.js";
import _ from "lodash";
import { useRouter, useRoute } from "vue-router";
import Storedata from "@/store/Storedata.js";
const isshowspin = ref(false);
const searchInput = ref();

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "15%"
    },
    {
        title: "ลำดับงาน",
        dataIndex: "OrderTransaction",
        width: "22%"
        // customFilterDropdown: true,
        // onFilter: (value, record) =>
        //     record[0].OrderTransaction.toString()
        //         .toLowerCase()
        //         .includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: visible => {
        //     if (visible) {
        //         setTimeout(() => {
        //             searchInput.value.focus();
        //         }, 100);
        //     }
        // }
    },
    {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerOrder",
        width: "30%"
        // customFilterDropdown: true,
        // onFilter: (value, record) =>
        //     (record[0].CustomerOrder.toString() + record[0].ContactName)
        //         .toLowerCase()
        //         .includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: visible => {
        //     if (visible) {
        //         setTimeout(() => {
        //             searchInput.value.focus();
        //         }, 100);
        //     }
        // }
    },
    {
        title: "รายการขาย",
        dataIndex: "ProductSellOrder",
        width: "45%"
        // customFilterDropdown: true,
        // onFilter: (value, record) =>
        //     record[0].ProductSellOrder.toString()
        //         .toLowerCase()
        //         .includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: visible => {
        //     if (visible) {
        //         setTimeout(() => {
        //             searchInput.value.focus();
        //         }, 100);
        //     }
        // }
    },
    {
        title: "วันที่สั่งสินค้า",
        dataIndex: "OrderDate",
        // sorter: true,
        width: "20%"
    },
    {
        title: "สถานะการโอน",
        dataIndex: "PaymentStatus",
        width: "35%"
    },
    {
        title: "สถานะข้อมูล",
        dataIndex: "StatusData",
        width: "40%"
    },
    {
        title: "สถานะข้อมูล",
        dataIndex: "StatusProduct",
        width: "50%"
    },

    {
        title: "วันที่ลงระบบ",
        dataIndex: "SystemYanDate",
        width: "25%"
    },
    {
        title: "วันที่ลงคิวเขียน",
        dataIndex: "QueYanDate",
        width: "25%"
    },
    {
        title: "สถานะรวม",
        dataIndex: "StatusAll",
        filters: [
            {
                text: "จัดส่งแล้ว",
                value: "จัดส่งแล้ว"
            },
            {
                text: "รอลูกค้าชำระเงิน",
                value: "รอลูกค้าชำระเงิน"
            },
            {
                text: "รอบัญชีตรวจสอบเงิน",
                value: "รอบัญชีตรวจสอบเงิน"
            },
            {
                text: "รอบัญชีตรวจลงระบบ",
                value: "รอบัญชีตรวจลงระบบ"
            },
            {
                text: "รอลูกค้าแจ้งที่อยู่จัดส่ง",
                value: "รอลูกค้าแจ้งที่อยู่จัดส่ง"
            },
            {
                text: "รอลูกค้าชำระค่าส่ง",
                value: "รอลูกค้าชำระค่าส่ง"
            },
            {
                text: "รอลงระบบ",
                value: "รอลงระบบ"
            },
            {
                text: "รอลงคิวเขียน",
                value: "รอลงคิวเขียน"
            },
            {
                text: "รอยันต์มา",
                value: "รอยันต์มา"
            },
            {
                text: "ลูกค้า(ยังไม่ยืนยันที่อยู่)",
                value: "ลูกค้า(ยังไม่ยืนยันที่อยู่)"
            },
            {
                text: "เตรียมสร้าง SP ได้",
                value: "เตรียมสร้าง SP ได้"
            },
            {
                text: "มัดจำแล้วรอคุยกับอาจารย์",
                value: "มัดจำแล้วรอคุยกับอาจารย์"
            },
            {
                text: "เพิ่มขนาดไม่ทัน",
                value: "เพิ่มขนาดไม่ทัน"
            },
            {
                text: "เพิ่มขนาดโดยใช้แทร็คใหม่",
                value: "เพิ่มขนาดโดยใช้แทร็คใหม่"
            },
            {
                text: "ย้ายสินค้าเข้าสต๊อก",
                value: "ย้ายสินค้าเข้าสต๊อก"
            },
            {
                text: "อาจารย์ออกแบบโลโก้",
                value: "อาจารย์ออกแบบโลโก้"
            },
            {
                text: "เพิ่มขนาดยังไม่ลงระบบ",
                value: "เพิ่มขนาดยังไม่ลงระบบ"
            },
            {
                text: "ยกเลิก",
                value: "ยกเลิก"
            },
            {
                text: "ผืนเก่าเพิ่มขนาด",
                value: "ผืนเก่าเพิ่มขนาด"
            }
        ],
        onFilter: (value, record) =>
            record.find(item => item.StatusAll.startsWith(value)),
        filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
        width: "30%"
    }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        BarcodeGenerator,
        Drawer,
        CloseOutlined,
        // CheckOutlined,
        Previewslip,
        FromCreateSell,
        Noti,
        CheckOrderTransaction,
        FileExclamationOutlined,
        SyncOutlined,
        SettingOutlined
    },

    setup() {
        watch(
            () => Storedata.state.callselllist,
            () => {
                querycustomer();
            }
        );
        const route = useRoute();
        const query = ref(route.query);
        const customercode = ref(route.query.customercode);
        const dataSources = ref(null);

        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: "",
            so: ""
        });

        if (customercode.value) {
            rowselecteddetail.rowselected = customercode.value;
            rowselecteddetail.ishowdetail = true;
        }
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการสั่งสินค้า(1.2)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("l");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };

        const querycustomer = () => {
            isshowspin.value = true;
            // return BzbsUser.apicustomerproduct(null)
            var params = {
                page: pagination.currentPage || 1,
                limit: pagination.pageSize || 5
            };
            return BzbsUser.customerproductpagination(params)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0];
                    result = generateStatusTags(result);
                    dataSources.value = groupBy(result, "OrderTransaction");
                    isshowspin.value = false;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const isgetDataSearch = ref(false);
        const get_search_criteria_customerproduct = params => {
            var search = {
                OrderTransaction: params.OrderTransaction,
                CustomerOrder: params.CustomerOrder,
                ProductSellOrder: params.ProductSellOrder
            };
            isgetDataSearch.value = true;
            return BzbsUser.search_criteria_customerproduct(params)
                .then(res => {
                    dataSources.value = [];
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0];
                    result = generateStatusTags(result);
                    dataSources.value = groupBy(result, "OrderTransaction");
                    setTimeout(() => {
                        isshowspin.value = false;
                        activeKey.value = [];
                    }, 100);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };

        const router = useRouter();

        if (route.query.c) {
            rowselecteddetail.rowselected = route.query.c;
            rowselecteddetail.ishowdetail = true;
        }

        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            rowselecteddetail.so = record[0].ProductSellOrder;
            console.log(record[0].CustomerOrder);
        };

        const rowselected_scanbycustomercode = record => {
            var CustomerOrder = route.query.customerorder;
            rowselecteddetail.rowselected = CustomerOrder;
            rowselecteddetail.ishowdetail = true;
        };

        const groupBySellOrder = arraySellOrder => {
            return arraySellOrder.group(
                ({ ProductSellOrder }) => ProductSellOrder
            );
        };

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };
        const isshowProductYan = type => {
            var array_name = ["ยันต์ปลด/คิวด่วน", "ยันต์เขียนสด"];
            if (array_name.includes(type)) {
                return true;
            } else {
                return false;
            }
        };

        const setformatmap = list => {
            return _.sortedUniq(list?.map(items => items.ProductSellOrder));
        };
        const formatdatetool = date => {
            if (date) {
                moment.locale("th");
                return moment(parseInt(date))
                    .add(543, "year")
                    .format("lll");
            } else {
                return "-";
            }
        };

        const isEmptyStr = str => {
            return str === null || str === undefined || str === "";
        };
        const jsonParse = jsonStr => {
            if (!isEmptyStr(jsonStr)) {
                try {
                    var data = JSON.parse(jsonStr);
                    return data;
                } catch (e) {
                    return null;
                }
            }
            return null;
        };
        const checkbirthdayofyan = item => {
            var countbirthday = 0;
            countbirthday = jsonParse(item.ProductBirthday)?.length;
            if (item.CustomerProductsType.includes("ยันต์") && countbirthday) {
                var nodata = true;
                jsonParse(item.ProductBirthday)?.forEach(element => {
                    if (element.Birthday == "" && element.YearBirthday == "") {
                        nodata = false;
                    } else {
                        nodata = true;
                    }
                });
                return nodata == false ? nodata : true;
            } else if (!item.CustomerProductsType.includes("ยันต์")) {
                return true;
            } else {
                return false;
            }
        };

        const ischecked = array => {
            if (array) {
                array = JSON.parse(array);

                return array[array.length - 1].checking == "checked";
            }
        };
        const isreject = array => {
            if (array) {
                array = JSON.parse(array);

                return array[array.length - 1].checking == "reject";
            }
        };

        function filterItemsAllStatus(record) {
            return record
                .map((Item, idx) => {
                    let status = "";
                    let color = "";

                    if (
                        Item.Productdelivery &&
                        Util.jsonParse(Item.Productdelivery)[0]
                            ?.DeliveryStatus == "shipped"
                    ) {
                        status = "จัดส่งแล้ว";
                        color = "green";
                    } else {
                        if (isshowProductYan(Item.CustomerProductsType)) {
                            if (
                                Item.Payment &&
                                Util.jsonParse(Item.Payment)[idx]?.Price == null
                            ) {
                                status = "รอลูกค้าชำระเงิน";
                                color = "#108ee9";
                            } else if (
                                Item.ProfessorType &&
                                Util.jsonParse(Item.ProfessorType).includes(
                                    "11"
                                )
                            ) {
                                status = "เพิ่มขนาดไม่ทัน";
                                color = "#FF6F1D";
                            } else if (
                                Item.ProfessorType &&
                                Util.jsonParse(Item.ProfessorType).includes(
                                    "12"
                                )
                            ) {
                                status = "เพิ่มขนาดโดยใช้แทร็คใหม่";
                                color = "#FF6F1D";
                            } else if (
                                Item.ProfessorType &&
                                Util.jsonParse(Item.ProfessorType).includes(
                                    "13"
                                )
                            ) {
                                status = "ย้ายสินค้าเข้าสต๊อก";
                                color = "#FF6F1D";
                            } else if (
                                Item.ProfessorType &&
                                Util.jsonParse(Item.ProfessorType).includes(
                                    "14"
                                )
                            ) {
                                status = "อาจารย์ออกแบบโลโก้";
                                color = "#FF6F1D";
                            } else if (
                                Item.HeartYanName ==
                                "เพิ่มขนาดยังไม่ได้ลงระบบ/ยันต์มัดจำปรึกษาอาจารย์"
                            ) {
                                status = "เพิ่มขนาดยังไม่ลงระบบ";
                                color = "#E820FF";
                            } else if (
                                Item.Payment &&
                                Util.jsonParse(Item.Payment)[idx]?.Price !=
                                    null &&
                                !Util.jsonParse(Item.Payment)[idx]?.Checking
                            ) {
                                status = "รอบัญชีตรวจสอบการเงิน";
                                color = "#108ee9";
                            } else if (
                                isshowProductYan(Item.CustomerProductsType) &&
                                Item.SystemYanDate != "" &&
                                Item.QueYanDate == "" &&
                                Item.SystemCheckTabActive_1 == null
                            ) {
                                status = `รอบัญชีตรวจลงระบบ`;
                                color = "#108ee9";
                            } else if (Item.HasAddress <= 0) {
                                status = "รอลูกค้าแจ้งที่อยู่จัดส่ง";
                                color = "#108ee9";
                            } else if (Item.RemindNotpayDelivery == "paid") {
                                status = "รอลูกค้าชำระค่าส่ง";
                                color = "#108ee9";
                            } else if (
                                Item.CustomerNotConfirmAddressAlready ==
                                "confirm"
                            ) {
                                status = "ลูกค้ายืนยันที่อยู่แล้ว";
                                color = "#108ee9";
                            } else if (
                                Item.CustomerNotConfirmAddressAlready ==
                                "donotconfirm"
                            ) {
                                status = "รอลูกค้ายืนยันที่อยู่";
                                color = "#108ee9";
                            } else if (
                                Item.CustomerNotConfirmAddressAlready == ""
                            ) {
                                status = "รอลูกค้ายืนยันที่อยู่";
                                color = "#108ee9";
                            } else if (
                                Item.CustomerNotConfirmAddressAlready ==
                                "confirm"
                            ) {
                                status = "เตรียมสร้าง SP ได้";
                                color = "blue";
                            } else if (
                                Item.ProfessorType &&
                                Util.jsonParse(Item.ProfessorType).includes(
                                    "10"
                                )
                            ) {
                                if (Item.ProfessorType) {
                                    status = "มัดจำแล้วรอคุยกับอาจารย์";
                                    color = "blue";
                                }
                            }
                        } else {
                            if (
                                Util.jsonParse(Item.ProfessorType).includes("9")
                            ) {
                                if (Item.ContactName) {
                                    status = "เตรียมสร้าง SP ได้";
                                    color = "blue";
                                }
                            } else {
                                if (
                                    (Item.Payment &&
                                        Util.jsonParse(Item.Payment)[idx]
                                            ?.Price) != null &&
                                    Item.ContactName &&
                                    checkbirthdayofyan(Item) &&
                                    Util.jsonParse(Item.Payment)[idx]
                                        ?.Checking != "checked"
                                ) {
                                    status = "รอบัญชีตรวจสอบ1";
                                    color = "#108ee9";
                                } else if (
                                    (Item.Payment &&
                                        Util.jsonParse(Item.Payment)[idx]
                                            ?.Price) != null &&
                                    Item.ContactName &&
                                    checkbirthdayofyan(Item) &&
                                    Util.jsonParse(Item.Payment)[idx]
                                        ?.Checking == "checked"
                                ) {
                                    status = "เตรียมสร้าง SP ได้";
                                    color = "blue";
                                } else {
                                    status = "เตรียมสร้าง SP ได้";
                                    color = "blue";
                                }
                            }
                        }
                    }

                    return { ...Item, StatusAll: status, Color: color };
                })
                .filter(item => item.StatusAll !== ""); // Remove items with empty StatusAll
        }

        function generateStatusTags(record) {
            const result = [];

            for (let idx = 0; idx < record.length; idx++) {
                const Item = record[idx];
                let status = "";
                let color = "";
                console.log("idx : ", idx);
                var lastPaymentPrice = 0;
                var lastPaymentPriceChecking = "";
                if (Item.Payment != null) {
                    lastPaymentPrice = Util.jsonParse(Item.Payment)[
                        Util.jsonParse(Item.Payment)?.length - 1
                    ]?.Price;
                    lastPaymentPriceChecking = Util.jsonParse(Item.Payment)[
                        Util.jsonParse(Item.Payment)?.length - 1
                    ]?.Checking;
                }

                if (
                    Item.Productdelivery &&
                    Util.jsonParse(Item.Productdelivery)[0]?.DeliveryStatus ==
                        "shipped"
                ) {
                    status = "จัดส่งแล้ว";
                    color = "green";
                } else {
                    if (isshowProductYan(Item.CustomerProductsType)) {
                        if (Item.ProductStatus == "cancel") {
                            status = "ยกเลิก";
                            color = "red";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("11")
                        ) {
                            status = "เพิ่มขนาดไม่ทัน";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("12")
                        ) {
                            status = "เพิ่มขนาดโดยใช้แทร็คใหม่";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("13")
                        ) {
                            status = "ย้ายสินค้าเข้าสต๊อก";
                            color = "#FF6F1D";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("14")
                        ) {
                            status = "อาจารย์ออกแบบโลโก้";
                            color = "#FF6F1D";
                        } else if (
                            Item.HeartYanName ==
                            "เพิ่มขนาดยังไม่ได้ลงระบบ/ยันต์มัดจำปรึกษาอาจารย์"
                        ) {
                            status = "เพิ่มขนาดยังไม่ลงระบบ";
                            color = "#E820FF";
                        } else if (
                            Item.IncreaseSizeOrder != "" &&
                            Item.OrderTransaction != Item.IncreaseSizeOrder
                        ) {
                            status = "ผืนเก่าเพิ่มขนาด";
                            color = "red";
                        } else if (
                            Item.Payment &&
                            (lastPaymentPrice == null || lastPaymentPrice <= 0)
                        ) {
                            status = "รอลูกค้าชำระเงิน";
                            color = "#108ee9";
                        } else if (
                            Item.ProfessorType &&
                            Util.jsonParse(Item.ProfessorType).includes("10")
                        ) {
                            if (Item.ProfessorType) {
                                status = "มัดจำแล้วรอคุยกับอาจารย์";
                                color = "blue";
                            }
                        } else if (
                            Item.Payment &&
                            lastPaymentPrice >= 0 &&
                            _.isEmpty(lastPaymentPriceChecking)
                        ) {
                            status = "รอบัญชีตรวจสอบเงิน";
                            color = "#108ee9";
                        } else if (
                            isshowProductYan(Item.CustomerProductsType) &&
                            Item.SystemYanDate != "" &&
                            Item.QueYanDate == "" &&
                            Item.SystemCheckTabActive_1 == null
                        ) {
                            status = "รอบัญชีตรวจลงระบบ";
                            color = "#108ee9";
                        } else if (Item.HasAddress <= 0) {
                            status = "รอลูกค้าแจ้งที่อยู่จัดส่ง";
                            color = "#108ee9";
                        } else if (Item.RemindNotpayDelivery == "paid") {
                            status = "รอลูกค้าชำระค่าส่ง";
                            color = "#108ee9";
                        } else if (
                            !Item.SystemYanDate &&
                            isshowProductYan(Item.CustomerProductsType)
                        ) {
                            status = "รอลงระบบ";
                            color = "#108ee9";
                        } else if (
                            !Item.QueYanDate &&
                            isshowProductYan(Item.CustomerProductsType)
                        ) {
                            status = "รอลงคิวเขียน";
                            color = "#108ee9";
                        } else if (
                            !Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == ""
                        ) {
                            status = "รอยันต์มา";
                            color = "#108ee9";
                        } else if (
                            Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == "confirm"
                        ) {
                            status = "เตรียมสร้าง SP ได้";
                            color = "blue";
                        } else if (
                            Item.YanComingDate &&
                            Item.CustomerNotConfirmAddressAlready == ""
                        ) {
                            status = "ลูกค้า(ยังไม่ยืนยันที่อยู่)";
                            color = "#108ee9";
                        } else {
                            status = "-";
                            color = "-";
                        }
                    } else {
                        if (Util.jsonParse(Item.ProfessorType).includes("9")) {
                            if (Item.ContactName) {
                                status = "เตรียมสร้าง SP ได้";
                                color = "blue";
                            }
                        } else {
                            if (
                                Item.Payment &&
                                (lastPaymentPrice == null ||
                                    lastPaymentPrice <= 0)
                            ) {
                                status = "รอลูกค้าชำระเงิน";
                                color = "#108ee9";
                            } else if (
                                Item.Payment &&
                                lastPaymentPrice > 0 &&
                                lastPaymentPrice != null &&
                                Item.ContactName &&
                                checkbirthdayofyan(Item) &&
                                lastPaymentPriceChecking != "checked"
                            ) {
                                status = "รอบัญชีตรวจสอบ";
                                color = "#108ee9";
                            } else if (
                                Item.Payment &&
                                lastPaymentPrice != null &&
                                Item.ContactName &&
                                checkbirthdayofyan(Item) &&
                                lastPaymentPriceChecking == "checked"
                            ) {
                                status = "เตรียมสร้าง SP ได้";
                                color = "blue";
                            } else {
                                status = "-";
                                color = "-";
                            }
                        }
                    }
                }

                if (status !== "") {
                    result.push({ ...Item, StatusAll: status, Color: color });
                }
            }

            return result;
        }

        const gotostockdetail = Stockuuid => {
            return BzbsUser.apiusestockdetail(Stockuuid)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var result = res.data[0][0];
                    window.location = `https://backofficestock.vercel.app/selllist??c=${result.CustomerOrder}&n=undefined&ot=${result.OrderTransaction}&so=${result.ProductSellOrder}#no-back-button`;
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const handleClickCollapse = event => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        };

        const criteria = reactive({
            OrderTransaction: "",
            CustomerOrder: "",
            ProductSellOrder: "",
            Name_Search: "",
            LastName_Search: "",
            RangOrderTransaction: []
        });

        const isshowspin = ref(false);
        const onFinishSearch = values => {
            dataSources.value = [];
            isshowspin.value = true;
 
            var params = {
                OrderTransaction: values.OrderTransaction
                    ? parseInt(values.OrderTransaction)
                    : null,
                RangOrderTransaction: values.RangOrderTransaction || [],
                CustomerOrder: values.CustomerOrder || null,
                ProductSellOrder: values.ProductSellOrder || null,
                Name_Search: criteria.Name_Search || null,
                LastName_Search: criteria.LastName_Search || null,
            }; 
            get_search_criteria_customerproduct(params);

            console.log("Success:", values);
        };
        const onFinishFailedSearch = errorInfo => {
            console.log("Failed:", errorInfo);
        };
        const resetCriteria = () => {
            // Assign default values to the properties
            criteria.OrderTransaction = "";
            criteria.CustomerOrder = "";
            criteria.ProductSellOrder = "";
            criteria.Name_Search = "";
            criteria.LastName_Search = "";
            criteria.RangOrderTransaction = [];
            isgetDataSearch.value = false;
            querycustomer();
        };

        const pagination = reactive({
            currentPage: 1,
            pageSize: 50,
            totalItems: 0
        });

        // Function to fetch data based on pagination
        const fetchData = () => {
            // Make API request to fetch data based on pagination.currentPage and pagination.pageSize
            // Update 'data' and 'pagination.totalItems' accordingly
        };

        // Watch for changes in pagination and fetch data accordingly
        watch(
            [() => pagination.currentPage, () => pagination.pageSize],
            fetchData,
            { immediate: true }
        );

        // Function to handle pagination change
        const handlePaginationChange = (page, pageSize) => {
            pagination.currentPage = page;
            pagination.pageSize = pageSize;

            querycustomer();
        };

        const isclickPre = () => {
            console.log("isclickPre");
        };
        const isclicknext = () => {
            console.log("isclicknext");
        };

        const activeKey = ref([]);
        const marks = ref({
            0: "เริ่มต้น",
            8000: "สิ้นสุด"
        });
        return {
            isgetDataSearch,
            marks,
            activeKey,
            isclickPre,
            isclicknext,
            pagination,
            handlePaginationChange,
            resetCriteria,
            onFinishSearch,
            onFinishFailedSearch,
            criteria,
            handleClickCollapse,
            ischecked,
            isreject,
            checkbirthdayofyan,
            formatdatetool,
            setformatmap,
            isshowProductYan,
            handleSearch,
            handleReset,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            query,
            Util,
            searchInput,
            filterItemsAllStatus,
            gotostockdetail,
            isshowspin,
            _
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}

.ant-tooltip-content {
    width: 500px;
}

.dot-red {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #000;
}

.dot-yellow {
    height: 20px;
    width: 20px;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #000;
}

.wrapper-pagination {
    padding: 10px;
    display: flex;
    justify-content: right;
}

#components-slider-demo-mark h4 {
    margin: 0 0 16px;
}
#components-slider-demo-mark .ant-slider-with-marks {
    margin-bottom: 44px;
}
.ant-slider-mark-text {
    white-space: nowrap;
}
</style>
