<template>
    <div class="p-4 card relative">
        <template v-if="title != null">
            <div class="text-gray-500 text-lg font-bold">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <div id="chartLine">
                <apexchart type="line" height="300" :options="chartOptions_Line" :series="series_Line"></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { forEach } from "lodash";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
export default {
    props: {
        title: {
            type: String,
            default: null
        }
        // seriesData: {
        //     type: Object,
        //     default: null
        // },
        // height: {
        //     type: [String, Number],
        //     default: 400
        // }
    },
    created() {
        this.querycustomer()

    },
    data: function () {
        return {
            heightComputed: '300px',
            series_Line: [
                {
                    name: "ยันต์เขียนสด",
                    data: [0]
                },
                {
                    name: "ยันต์ปลด/คิวด่วน",
                    data: [0]
                },
                {
                    name: "วัตถุมงคล",
                    data: [0]
                },
                {
                    name: "เคส/ภาพพื้นหลัง",
                    data: [0]
                }
            ],
            chartOptions_Line: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: "straight",
                    dashArray: [0, 8, 5]
                },
                title: {
                    text: "จำนวน",
                    align: "left"
                },
                legend: {
                    tooltipHoverFormatter: function (val, opts) {
                        return (
                            val +
                            " - " +
                            opts.w.globals.series[opts.seriesIndex][
                            opts.dataPointIndex
                            ] +
                            ""
                        );
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: [
                        "01 Jan",
                        "02 Jan",
                        "03 Jan",
                        "04 Jan",
                        "05 Jan",
                        "06 Jan",
                        "07 Jan",
                        "08 Jan",
                        "09 Jan",
                        "10 Jan",
                        "11 Jan",
                        "12 Jan"
                    ]
                },
                tooltip: {
                    // y: [
                    //     {
                    //         title: {
                    //             formatter: function (val) {
                    //                 return val + "ชิ้น";
                    //             }
                    //         }
                    //     },
                    //     {
                    //         title: {
                    //             formatter: function (val) {
                    //                 return val + " ชิ้น";
                    //             }
                    //         }
                    //     },
                    //     {
                    //         title: {
                    //             formatter: function (val) {
                    //                 return val + " ชิ้น";
                    //             }
                    //         }
                    //     },
                    //     {
                    //         title: {
                    //             formatter: function (val) {
                    //                 return val + " ชิ้น";
                    //             }
                    //         }
                    //     },
                    // ]
                },
                grid: {
                    borderColor: "#f1f1f1"
                }
            }
        };
    },
    methods: {
        querycustomer() {
            return BzbsUser.apicustomerproduct(null)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var dataSources = this.groupBy(res.data[0], "CustomerOrder");

                    var categories = []
                    var brfore_group = []
                    dataSources.forEach(item => {
                        item.forEach(sub_item => {
                            brfore_group.push({ date: sub_item.OrderDate, product_type: sub_item.CustomerProductsType })
                            categories.push(sub_item.OrderDate)
                        });

                    });



                    var uniqueArray = categories.filter(function (item, pos, self) {
                        return self.indexOf(item) == pos;
                    })

                    uniqueArray = uniqueArray.sort(function (a, b) {
                        return new Date(a) - new Date(b);
                    });

                    brfore_group = brfore_group.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });


                    uniqueArray = uniqueArray.map(element => {
                        return this.formatdate(element)
                    });




                    console.log("TrendGraphLine :: ", dataSources);
                    console.log("categories :: ", uniqueArray);



                    const all_datagrupby_date = brfore_group.reduce((x, y) => {
                        (x[y.date] = x[y.date] || []).push(y);
                        return x;
                    }, {});

                    // this.series[0].data.map(() => {
                    //     return Math.floor(Math.random() * (max - min + 1)) + min;
                    // });
                    // ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน", "วัตถุมงคล", "เคส/ภาพพื้นหลัง"]
                    var temp = {
                        one: [],
                        two: [],
                        three: [],
                        four: [],
                    }
                    for (const property in all_datagrupby_date) {
                        console.log(`${property}: ${all_datagrupby_date[property]}`);


                        temp.one.push(all_datagrupby_date[property].filter(item => item.product_type == 'ยันต์เขียนสด')?.length)
                        temp.two.push(all_datagrupby_date[property].filter(item => item.product_type == 'ยันต์ปลด/คิวด่วน')?.length)
                        temp.three.push(all_datagrupby_date[property].filter(item => item.product_type == 'วัตถุมงคล')?.length)
                        temp.four.push(all_datagrupby_date[property].filter(item => item.product_type == 'เคส/ภาพพื้นหลัง')?.length)
                        //temp.series[3].data.push(all_datagrupby_date[property].filter(item => item.product_type == 'เคส/ภาพพื้นหลัง'))


                    }

                    this.series_Line = [
                        {
                            name: "ยันต์เขียนสด",
                            data: temp.one
                        },
                        {
                            name: "ยันต์ปลด/คิวด่วน",
                            data: temp.two
                        },
                        {
                            name: "วัตถุมงคล",
                            data: temp.three
                        },
                        {
                            name: "เคส/ภาพพื้นหลัง",
                            data: temp.four
                        }
                    ]
                    this.chartOptions_Line = {
                        xaxis: {
                            categories: uniqueArray
                        }
                    };

                })
                .catch(error => {
                    console.log(error);
                });
        },
        groupBy(collection, property) {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },
        formatdate(date) {
            return moment(date)
                .add(543, "year")
                .format("ll");
        }

    }
};
</script>

<style>

</style>
