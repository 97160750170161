<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content">
            <div class="bg-white w-full rounded-xl" v-if="serialModel != null">
                <SerialDetail :serialModel="serialModel"></SerialDetail>
                <Serial :serialModel="serialModel" ref="serial"></Serial>
                <PrivilegeMessage :serialModel="serialModel"></PrivilegeMessage>
                <SerialTimer :serialModel="serialModel" @onSerialExpired="onSerialExpired()"></SerialTimer>
                <div class="text-center p-4">
                    <button class="bg-gray-600 text-white rounded-sm py-1 px-4" @click="clickClose()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Util from "@/helper/Utility.js";
import DatetimeHelper from "@/helper/DatetimeHelper.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
//component
import SerialDetail from "./component/SerialDetail.vue";
import Serial from "./component/Serial.vue";
import PrivilegeMessage from "./component/PrivilegeMessage.vue";
import SerialTimer from "./component/SerialTimer.vue";

export default {
    name: "SerialModal",
    mixins: [LocaleMixin],
    components: {
        SerialDetail,
        Serial,
        PrivilegeMessage,
        SerialTimer,
    },
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,

            serialModel: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function(config) {
            this.serialModel = config.serialModel;

            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickClose: function() {
            this.hide();
            this.controller.sendCloseModal();
        },

        getRedeemMessage: function () {
            let dateStr = DatetimeHelper.getDatetimeFullFormat(this.serialModel.redeemTimestamp);
            return Util.stringFormat(this.lbl.serial.redeem_on, dateStr);
        },

        onSerialExpired: function() {
            this.$refs['serial'].setSerialExpire();
            this.controller.sendSerialExpire();
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/base.scss";
    @import "@/style/modal.scss";

    .modal {
        @extend %modal;

        .modal-content {
            overflow: unset;
            width: 420px;

            @include mobile {
                width: 100%;
                padding: 16px;
            }
        }
    }
</style>
