<template>
    <div class="fixed top-0 left-0 w-full h-full bg-opacity-60 bg-black m-auto z-50" v-if="value">
        <div class="backdrop fixed top-0 left-0 w-full h-full"  @click="dismiss()"></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "VOverlay",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        lockBackdrop:{
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: function (val) {
            if (val) {
                this.disableScroll();
            } else {
                this.enableScroll();
            }
        }
    },
    methods: {
        disableScroll: function () {
            document.body.classList.add('overflow-hidden')
        },
        enableScroll: function () {
            document.body.classList.remove('overflow-hidden')
        },
        dismiss: function(){
            if(!this.lockBackdrop){
                this.$emit("dismiss")
            }
        }
    }
}
</script>
