<template>
    <div class="relative inline-block text-left flex" v-click-away="onClickAway">
        <slot name="activator" :on="handlers" :attrs="{  show  }"></slot>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                class="z-10 absolute mt-2 rounded bg-white border border-gray-300 focus:outline-none shadow-lg py-2"
                :style="{width: widthComputed}"
                :class="[origin, origin == 'origin-top-right' ? 'right-0': 'left-0']"
                v-if="show"
                @mouseenter="onMouseEnter"
                @mouseleave="onMouseLeave"
                @click="hide()"
            >
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: 'VMenu',
    props: {
        color: String,
        animation: String,
        hover: {
            type: Boolean,
            default: undefined
        },
        timeout: {
            type: Number,
            default: 3000
        },
        origin:{
            type: String,
            default: "origin-top-left"
        },
        width:{
            type: [Number, String],
            default: "100%"
        }
    },
    data: function () {
        return {
            show: false,
            handlers: {},
            timerId: null,
            hasEntered: false
        }
    },
    computed:{
        widthComputed: function(){
            return PropsHelper.getBoxUnit(this.width)
        }
    },
    created: function () {
        if (this.hover === true) {
            this.handlers = { mouseenter: this.toggle, mouseleave: this.onMouseLeave }
        } else {
            this.handlers = { click: this.toggle }
        }
    },
    methods: {
        toggle: function () {
            clearTimeout(this.timerId);
            this.show = !this.show
        },
        hide: function () {
            this.show = false
            this.hasEntered = false;
        },
        onMouseLeave: function () {
            // this.timerId = setTimeout(() => {
            //     this.hide();
            // }, this.timeout);
        },
        onMouseEnter: function () {
            this.hasEntered = true;
            // clearTimeout(this.timerId);
        },
        onClickAway: function () {
            this.hide();
        }
    }
}
</script>
