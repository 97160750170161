<template>
    <div class="flex items-stretch w-full h-screen layout-outer">
        <div
            class="flex-none h-full text-white duration-500 ease-in-out bg-black left-menu-width transform-gpu transition-width"
            :class="{ collapse: !sidebarOpen }"
        >
            <sidebar-menu
                :header="sidebarHeaderconfig"
                :menus="sidebarMenu"
                :autoHideSubMenu="false"
                @toggle="onSideBarToggle($event)"
            ></sidebar-menu>
        </div>
        <div
            class="flex-auto w-full h-full max-w-full overflow-x-hidden overflow-y-auto scroll-touch"
        >
            <router-view></router-view>
        </div>

        <div class="flyout-zone">
            <notification-panel></notification-panel>
            <user-account-panel></user-account-panel>
        </div>
    </div>
</template>
<script>
// component
import SidebarMenu from "../component/SidebarMenu.vue";
import NotificationPanel from "../component/NotificationPanel.vue";
import UserAccountPanel from "../component/UserAccountPanel.vue";
import Storedata from "@/store/Storedata.js";
export default {
    name: "LayoutLeftMenu",
    inject: ["SideMenuStore", "AccountStore", "TopBarStore"],
    components: {
        SidebarMenu,
        NotificationPanel,
        UserAccountPanel
    },
    data: function() {
        return {
            sidebarHeaderconfig: {
                logo: "@/assets/imgs/logo/Logo_NA-Black.png",
                text:
                    "บริษัท นะมะหายัน กรุ๊ป จำกัด " +
                        process.env.VUE_APP_SERVER ==
                    "dev"
                        ? "(DEV)"
                        : "" + ""
            },
            sidebarMenu: [],
            sidebarOpen: true
        };
    },
    created: function() {
        // prepare menu
        //#region menus

        if (!Storedata.methods.checkgrantccountonly()) {
            this.sidebarMenu = [
                {
                    text: "ตรวจสอบรายการขาย",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "ตรวจสอบเงินเข้า(บัญชี)",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/checkmoneyselllist"
                        },
                        {
                            text: "ตรวจสอบเงินค่าจัดส่ง(บัญชี)",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/additionalorderforaccounting"
                        },
                        {
                            text: "อนุมัติลงระบบ(oneclick)",
                            ic: "fa-mail-bulk",
                            badge: null,
                            to: "/getacceptsystemyandate"
                        },
                        {
                            text: "Excel Contact Peak",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getexcelcontact"
                        }
                    ]
                },
                {
                    text: "รายงาน",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "รายงานลงระบบที่ยังไม่ได้จ่าย",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/getreportnotpay"
                        },
                        {
                            text: "ใบจ่ายยันต์",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/payyan"
                        },
                        {
                            text: "รายงานสินค้า",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/getreportproduct"
                        },
                        {
                            text: "รายงานรับยันต์",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getyan"
                        },
                        {
                            text: "รายงานยันต์คิวด่วน",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getyanvip"
                        }
                        // {
                        //     text: "รายงานยันต์เพิ่มขนาด",
                        //     badge: null,
                        //     ic: 'fa-mail-bulk',
                        //     to: "/getyanchangesize"
                        // },
                    ]
                }
            ];
        } else if (!Storedata.methods.checkgrantsalepage()) {
            this.sidebarMenu = [
                {
                    text: "สร้าง sub-domain",
                    badge: null,
                    to: "/",
                    children: [
                        // {
                        //     text: "สร้างเซลเพจ",
                        //     badge: null,
                        //     to: "/salepagecreate?subdomain=nayant"
                        // },
                        // {
                        //     text: "รายการเซลเพจ",
                        //     badge: null,
                        //     to: "/salepagelist"
                        // },
                        {
                            text: "สร้าง sub-domain",
                            badge: null,
                            ic: "fa-folder-plus",
                            to: "/subdomaincreate"
                        },
                        {
                            text: "รายการ sub-domain",
                            badge: null,
                            ic: "fa-pager",
                            to: "/subdomainlist"
                        }
                    ]
                },
                {
                    text: "(Log ข้อมูล)",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "สั่งซื้อสินค้า",
                            badge: null,
                            ic: "fa-blog",
                            to: "/shoppingcart"
                        },
                        {
                            text: "LOG LINE PAY",
                            badge: null,
                            ic: "fa-blog",
                            to: "/loglinepay"
                        },
                        {
                            text: "ลูกค้า LINE OA",
                            badge: null,
                            ic: "fa-blog",
                            to: "/lineuser"
                        }
                    ]
                }
            ];
        } else if (!Storedata.methods.checkgrantcommission()) {
            this.sidebarMenu = [
                {
                    text: "คอมมิชชั่น",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "รายงานคอมมิชชั่น",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/getreportcommission"
                        }
                    ]
                }
            ];
        } else {
            this.sidebarMenu = [
                // {
                //     text: "Dashboard",
                //     badge: null,
                //     to: "/dashboard",
                //     children: null
                // },
                {
                    text: "รายงาน",
                    badge: null,
                    to: "/",
                    children: [
                        // {
                        //     text: "รายงานคอมมิชชั่น",
                        //     ic: "fa-list-dots",
                        //     badge: null,
                        //     to: "/getreportcommission"
                        // },
                        {
                            text: "รายงานลงระบบที่ยังไม่ได้จ่าย",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/getreportnotpay"
                        },
                        {
                            text: "ใบจ่ายยันต์",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/payyan"
                        },
                        {
                            text: "รายงานสินค้า",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/getreportproduct"
                        },
                        {
                            text: "รายงานรับยันต์",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getyan"
                        },
                        {
                            text: "รายงานยันต์สต๊อก",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getyanstock"
                        },
                        {
                            text: "รายงานยันต์คิวด่วน",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getyanvip"
                        }
                        // {
                        //     text: "รายงานยันต์เพิ่มขนาด",
                        //     badge: null,
                        //     ic: 'fa-mail-bulk',
                        //     to: "/getyanchangesize"
                        // },
                    ]
                },
                {
                    text: "ลูกค้า",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "ลูกค้า",
                            badge: null,
                            ic: "fa-person",
                            to: "/customermember"
                        }
                    ]
                },
                {
                    text: "สินค้า",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "เพิ่มสินค้า",
                            badge: null,
                            ic: "fa-person",
                            to: "/product"
                        }
                    ]
                },
                {
                    text: "รายการขาย",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "สร้างรายการขาย",
                            badge: null,
                            ic: "fa-cart-plus",
                            to: "/sellcreate"
                        },
                        {
                            text: "ดูรายการขาย",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/selllist"
                        },
                        {
                            text: "ตรวจสอบเงินเข้า(บัญชี)",
                            ic: "fa-list-dots",
                            badge: null,
                            to: "/checkmoneyselllist"
                        },
                        {
                            text: "อนุมัติลงระบบ(oneclick)",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getacceptsystemyandate"
                        }
                    ]
                },
                {
                    text: "การจัดส่ง",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "สร้างรายการค่าส่งเพิ่ม",
                            badge: null,
                            ic: "fa-plus-circle",
                            to: "/delivery"
                        },
                        {
                            text: "รายการค่าส่งเพิ่ม",
                            badge: null,
                            ic: "fa-money-check-dollar",
                            to: "/additionalorderforaccounting"
                        },
                        {
                            text: "สร้างรายการจัดส่ง",
                            badge: null,
                            ic: "fa-plus-minus",
                            to: "/createdeliverylist"
                        },
                        {
                            text: "แพ็คสินค้า",
                            badge: null,
                            ic: "fa-truck-fast",
                            to: "/packproduct"
                        },
                        {
                            text: "รายการจัดส่ง",
                            badge: null,
                            ic: "fa-truck-front",
                            to: "/manageorder"
                        },
                        // {
                        //     text: "ประวัติไปรษณีย์",
                        //     badge: null,
                        //     ic: "fa-mail-bulk",
                        //     to: "/thailandpost_inter"
                        // },

                        {
                            text: "Excel Contact Peak",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getexcelcontact"
                        }
                    ]
                },
                {
                    text: "ระบบ Peak",
                    badge: null,
                    to: "/",
                    children: [
                        {
                            text: "Excel Contact Peak",
                            badge: null,
                            ic: "fa-mail-bulk",
                            to: "/getexcelcontact"
                        }
                    ]
                }
            ];
        }
        //#endregion menus
        this.SideMenuStore.methods.open();
    },
    methods: {
        onSideBarToggle: function(val) {
            this.sidebarOpen = val;
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/style/base/base.scss";

.left-menu-width {
    // flex: 0 0 $left-menu-width;
    width: $left-menu-width;

    &.collapse {
        // width: 44px;
        width: 40px;
        // flex: 0 0 55px;
    }
}

.layout-outer {
    background-color: #e6e8f3;
}
</style>
