<template>
    <div>
        <!-- <pre>{{ detail }}</pre> -->
        <div v-for="(obj, idx) of detail" :key="`v-list-item-${idx}`">
            <div>
                <div v-for="(sub, idx) of obj.items" :key="`v-list-item-${idx}`">
                    <div>
                        <div v-for="(deep, idx) of sub" :key="`v-list-item-${idx}`"> 
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="false" class="relative p-4 wrapper-track">
            <div data-v-66da1c1e="" class="list-detail active">

                <div data-v-66da1c1e="" style="position: relative; min-height: 125px;">
                    <div data-v-66da1c1e="" class="detail-signature">
                        <div data-v-66da1c1e="" style="position: relative; z-index: 99;">
                            <p data-v-66da1c1e="" class="signature"><span data-v-66da1c1e="">ลายเซ็น / </span><span
                                    data-v-66da1c1e=""> ติดต่อเจ้าหน้าที่ </span></p><!---->
                        </div>
                    </div>
                    <ul data-v-66da1c1e="" class="events" style="min-height: auto;">
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                08/05/2566 09:21
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ต่างประเทศ (ออสเตรเลีย)
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                นำจ่ายสำเร็จ
                                <font data-v-66da1c1e=""><!---->
                                    <font data-v-66da1c1e="">(P SONGARA<!---->)</font><!---->
                                </font>
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">08/05/2566 09:21</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">นำจ่ายสำเร็จ</font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ต่างประเทศ (ออสเตรเลีย)</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                08/05/2566 08:36
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ต่างประเทศ (ออสเตรเลีย)
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                ถึงที่ทำการไปรษณีย์
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">08/05/2566 08:36</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">ถึงที่ทำการไปรษณีย์</font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ต่างประเทศ (ออสเตรเลีย)</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                07/05/2566 12:45
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ต่างประเทศ (ออสเตรเลีย)
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาเข้า
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">07/05/2566 12:45</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาเข้า
                                </font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ต่างประเทศ (ออสเตรเลีย)</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                04/05/2566 02:59
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ศป.สุวรรณภูมิ
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                ส่งออกจากที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">04/05/2566 02:59</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">
                                    ส่งออกจากที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก
                                </font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ศป.สุวรรณภูมิ</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                04/05/2566 00:48
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ศป.สุวรรณภูมิ
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">04/05/2566 00:48</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก
                                </font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ศป.สุวรรณภูมิ</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                03/05/2566 23:30
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                ศป.EMS
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                อยู่ระหว่างการขนส่ง
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">03/05/2566 23:30</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">อยู่ระหว่างการขนส่ง</font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">ศป.EMS</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                03/05/2566 19:24
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                หนองจอก
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                อยู่ระหว่างการขนส่ง
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">03/05/2566 19:24</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">อยู่ระหว่างการขนส่ง</font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">หนองจอก</font>
                            </span></li>
                        <li data-v-66da1c1e=""><label data-v-66da1c1e=""></label><span data-v-66da1c1e=""></span><span
                                data-v-66da1c1e="" class="detail-create-date">
                                03/05/2566 17:48
                                <font data-v-66da1c1e="">น.</font>
                            </span><span data-v-66da1c1e="" class="detail-outlet">
                                หนองจอก
                            </span><span data-v-66da1c1e="" class="detail-tnd" style="width: 250px;">
                                รับฝาก
                                <!----></span><span data-v-66da1c1e="" class="detail-tnd" style="display: none;">
                                <font data-v-66da1c1e="" class="detail-tnd-date">03/05/2566 17:48</font>
                                <font data-v-66da1c1e=""> น.</font>
                                <font data-v-66da1c1e="" class="detail-tnd-name">รับฝาก</font>
                                <font data-v-66da1c1e="" class="detail-tnd-outlet">หนองจอก</font>
                            </span></li>
                    </ul>
                </div>
            </div>
            <template v-if="title != null">
                <div style="pointer-events: none;">
                    <a-image :preview="{ visible: false }" class="image-center banner" alt="มหายันต์ลูกกอ"
                        src="https://namahayan.blob.core.windows.net/assets/banner.webp"></a-image>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Vue3CanvasVideoPlayer from 'vue3-canvas-video-player';
import 'vue3-canvas-video-player/dist/style.css';
import TableLoad from "@/components/Skeleton/TableLoad"
import { usePagination } from "vue-request";

import { computed, defineComponent, onMounted, ref, reactive, toRefs } from "vue";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, VideoCameraOutlined, YoutubeOutlined } from '@ant-design/icons-vue';
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import Util from "@/helper/Utility";
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 


export default defineComponent({
    components: {
    },
    props: {
        trackdetail: null,
    },
    setup(props) {
        const detail = ref(props.trackdetail)
        detail.value = {
            "response": {
                "items": {
                    "EE197283322TH": [
                        {
                            "barcode": "EE197283322TH",
                            "status": "103",
                            "status_description": "รับฝาก",
                            "status_date": "03/05/2566 17:48:08+07:00",
                            "location": "หนองจอก",
                            "postcode": "10530",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "201",
                            "status_description": "อยู่ระหว่างการขนส่ง",
                            "status_date": "03/05/2566 19:24:44+07:00",
                            "location": "หนองจอก",
                            "postcode": "10530",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "201",
                            "status_description": "อยู่ระหว่างการขนส่ง",
                            "status_date": "03/05/2566 23:30:28+07:00",
                            "location": "ศป.EMS",
                            "postcode": "10020",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "204",
                            "status_description": "ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก",
                            "status_date": "04/05/2566 00:48:14+07:00",
                            "location": "ศป.สุวรรณภูมิ",
                            "postcode": "10001",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "208",
                            "status_description": "ส่งออกจากที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก",
                            "status_date": "04/05/2566 02:59:24+07:00",
                            "location": "ศป.สุวรรณภูมิ",
                            "postcode": "10001",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "205",
                            "status_description": "ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาเข้า",
                            "status_date": "07/05/2566 12:45:00+07:00",
                            "location": "ต่างประเทศ (ออสเตรเลีย)",
                            "postcode": "99999",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "206",
                            "status_description": "ถึงที่ทำการไปรษณีย์",
                            "status_date": "08/05/2566 08:36:00+07:00",
                            "location": "ต่างประเทศ (ออสเตรเลีย)",
                            "postcode": "99999",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": null,
                            "signature": null,
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        },
                        {
                            "barcode": "EE197283322TH",
                            "status": "501",
                            "status_description": "นำจ่ายสำเร็จ",
                            "status_date": "08/05/2566 09:21:00+07:00",
                            "location": "ต่างประเทศ (ออสเตรเลีย)",
                            "postcode": "99999",
                            "delivery_status": null,
                            "delivery_description": null,
                            "delivery_datetime": null,
                            "receiver_name": "P SONGARA",
                            "signature": "https://trackimage.thailandpost.co.th/f/signature/QDgzMzIyYjVzMGx1VDMz/QGI1c0VFMGx1VDMx/QGI1czBsVEh1VDM0/QGI1czBsdTE5NzJUMzI=",
                            "delivery_officer_name": null,
                            "delivery_officer_tel": null,
                            "office_name": null,
                            "office_tel": null,
                            "call_center_tel": "1545"
                        }
                    ]
                },
                "track_count": {
                    "track_date": "15/06/2566",
                    "count_number": 16,
                    "track_count_limit": 1000
                }
            },
            "message": "successful",
            "status": true
        }

        return {
            detail

        };
    }
});
</script>
<style lang="scss">
.list-detail.active {
    width: 100%;
    max-height: 100%;
    height: auto;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: opacity .3s, max-height .25s ease-out;
    transition: opacity .3s, max-height .25s ease-out;
}

.list-detail {
    width: 100%;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity .3s, max-height .25s ease-in;
    transition: opacity .3s, max-height .25s ease-in;
    position: relative;
}

.iconStatus {
    text-align: center;
}

.iconStatus ul {
    width: 100%;
}

.iconStatus ul li img {
    width: 50%;
    margin: auto;
}
</style>
