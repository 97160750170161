<template>
  <div>
    <!-- <a-button type="primary" @click="showModal">Open Modal</a-button> -->
    <a-modal v-model:visible="visible" title="เพิ่มปุ่ม" @ok="handleOk" @cancel="close()">
      <a-form :layout="formState.layout" :model="formState" v-bind="formItemLayout">
        <div v-if="formStatestore.value.user.SalePageLineOA && formStatestore.value.user.SalePageConfig.Linetype">
          <a-checkbox v-model:checked="checkedline" @change="onselectchange()">
            <a-typography-text type="secondary">ใช้ ไลน์นี้ {{
                formStatestore.value.user.SalePageLineOA
            }}</a-typography-text>
          </a-checkbox>
        </div>
        <a-form-item label="Line" has-feedback :validate-status="LineStatus">
          <a-input v-model:value="LineInput" @change="checklinecondition()" placeholder="Line">
            <template #prefix>
              <img class="w-5"
                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
            </template>
            <template #addonBefore>
              <a-select v-model:value="LineType" @change="lineselectchange()" style="width: 120px">
                <a-select-option value="line_oa">Line @</a-select-option>
                <a-select-option value="line_personal">Line ส่วนตัว</a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <div v-if="formStatestore.value.user.SalePageTel">
          <a-checkbox v-model:checked="checkedtel" @change="onselecttel()">
            <a-typography-text type="secondary">ใช้ เบอร์นี้ {{
                formStatestore.value.user.SalePageTel
            }}</a-typography-text>
          </a-checkbox>
        </div>
        <a-form-item label="เบอร์โทร">
          <a-input v-model:value="formState.tel" placeholder="กรอกเบอร์โทร">
            <template #prefix>
              <img class="w-5" src=" https://namahayan.blob.core.windows.net/assets/phone_PNG49006.png" />
            </template>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { onEdit, value } from '@/store/store'
import { notification } from 'ant-design-vue';
import { formStatestore } from '@/store/store'
export default defineComponent({
  components: {
    // MinusCircleOutlined,
    // PlusOutlined 
  },
  emits: ['onaddbutton'],
  setup(props, { emit }) {
    const checkedline = ref(false);
    const checkedtel = ref(false);


    const visible = ref(false);
    const LineType = ref("กรุณาเลือก");
    const LineInput = ref("");
    const LineStatus = ref("");


    const showModal = () => {
      visible.value = true;
    };
    showModal()

    const close = () => {

      visible.value = false
      value.value = ["เลือก"]
      onEdit.value.isshowedit = false
    }
    const handleOk = e => {

      visible.value = false
      formState.option = dynamicValidateForm

      emit('onaddbutton', { value: formState, type: 'addbutton' })
      console.log(e);
      visible.value = false;
    };
    const formState = reactive({
      lineid: {
        id: "",
        LineType: "",
      },
      tel: "",
    });
    if (onEdit.value.isshowedit) { 
      
      console.log("formStatestore.value.user.SalePageTel ", formStatestore.value) 
      if (formStatestore.value.user.SalePageTel == onEdit.value.editobj.detail.value.tel) {
        checkedtel.value = true
        formState.tel = formStatestore.value.user.SalePageTel
      }

      if (onEdit.value.editobj.detail.value.tel != formStatestore.value.user.SalePageTel) {
        formState.tel = onEdit.value.editobj.detail.value.tel
      }

      if (onEdit.value.editobj.detail.value.lineid.id != formStatestore.value.user.SalePageLineOA) {
        checkedline.value = false
        LineInput.value = onEdit.value.editobj.detail.value.lineid.id

        if (LineInput.value.charAt(0) != "@") {
          LineType.value = 'line_personal'
        }
        else {
          LineType.value = 'line_oa'
        }
      }
      else {
        checkedline.value = true
        LineInput.value = formStatestore.value.user.SalePageLineOA
        LineType.value = formStatestore.value.user.SalePageConfig.Linetype
      }
    }
    const formItemLayout = computed(() => {
      const {
        layout,
      } = formState;
      return layout === 'horizontal' ? {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 14,
        },
      } : {};
    });

    const formRef = ref();
    const dynamicValidateForm = reactive({
      items: [],
    });


    const onFinish = values => {
      console.log('Received values of form:', values);
      console.log('dynamicValidateForm.items:', dynamicValidateForm.items);
    };

    const checklinecondition = () => {
      checkedline.value = false
      formState.lineid.LineType = LineType.value
      formState.lineid.id = LineInput.value.id
      if (LineType.value == 'กรุณาเลือก') {
        // Modal.warning({
        //     title: 'กรุณาเลือกประเภทไลน์ก่อน',
        // });
        notification['warning']({
          message: 'แจ้งเตือน',
          description: 'กรุณาเลือกประเภทไลน์ก่อน',
        });
        formState.lineid.id = ""
        LineInput.value = ""
        LineStatus.value = "warning";
      }
      if (LineType.value == "line_oa") {
        if (LineInput.value.charAt(0) != "@") {
          // Modal.warning({
          //     title: 'กรุณาเพิ่ม "@" ก่อน LineID เช่น @abc1234',
          // });
          notification['warning']({
            message: 'แจ้งเตือน',
            description: 'กรุณาเพิ่ม "@" ก่อน LineID เช่น @abc1234',
          });
          LineInput.value = ""
          LineStatus.value = "warning";
        } else if (LineInput.value.charAt(0) == "@") {
          formState.lineid.id = LineInput.value
          LineStatus.value = "success";

        }
      }
      else if (LineType.value == "line_personal") {

        if (LineInput.value.charAt(0) == "@") {

          // Modal.warning({
          //     title: 'กรุณาลบ "@" หน้า Line ID เช่น abc1234',
          // });
          notification['warning']({
            message: 'แจ้งเตือน',
            description: 'กรุณาลบ "@" หน้า Line ID เช่น abc1234',
          });
          LineInput.value = ""
          LineStatus.value = "warning";
          formState.lineid.id = LineInput.value
        }
        else if (LineInput.value.charAt(0) != "@") {
          
          formState.lineid.id = LineInput.value
          LineStatus.value = "success";
        }



      }
      // console.log(formState.user.SalePageLineOA)

    };

    const lineselectchange = () => {
      LineInput.value = '';
      LineStatus.value = 'warning';

    }
    const onselectchange = () => {
    
      if (checkedline.value) {
        LineInput.value = formStatestore.value.user.SalePageLineOA
        LineType.value = formStatestore.value.user.SalePageConfig.Linetype
        formState.lineid.id = LineInput.value
        // formStatestore.value.user.SalePageConfig.Linetype =  LineType.value 
      }
      else {
        LineInput.value = ""
        LineType.value = ""
      }
    }
    const onselecttel = () => {
     
      if (checkedtel.value) {
        formState.tel = formStatestore.value.user.SalePageTel
      }
      else {
        formState.tel = ""
      }

    }
    return {
      onselectchange,
      onselecttel,
      checklinecondition,
      close,
      visible,
      showModal,
      handleOk,
      formState,
      formItemLayout,
      formRef,
      dynamicValidateForm,
      onFinish,
      LineType,
      LineInput,
      LineStatus, lineselectchange,
      checkedline,
      checkedtel,
      formStatestore,
      onEdit
    };
  },

});
</script>