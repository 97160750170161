<template>
    <div class="sidebar h-full flex flex-col w-full">
        <sidebar-header v-bind="header" class="flex-none" :is-open="isOpen" @onClickToggle="toggleSideBar()">
        </sidebar-header>
        <div class="flex-auto overflow-y-auto sidebar-body">
            <div class="pt-3">
                <template v-for="(menuRoot, idx) of menus" :key="`menu-root-${idx}`">

                    <sidebar-menu-item v-bind="menuRoot" :autoHideSubMenu="autoHideSubMenu"></sidebar-menu-item>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
// component
import SidebarHeader from "../component/SidebarHeader.vue";
import SidebarMenuItem from "../component/SidebarMenuItem.vue";

import SidebarMixin from "../mixin/sidebar.mixin";

/**
 * @displayName SidebarMenu
 */
export default {
    name: "SidebarMenu",
    mixins: [SidebarMixin],
    inject: ["SideMenuStore"],
    props: {
        /**
         * header config
         */
        header: {
            type: Object,
        },
        /**
         * menu config items
         */
        menus: {
            type: Array,
            default: () => [],
        },
        /**
         * flag to set auto hide sub menu when click to other menu
         */
        autoHideSubMenu: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        SidebarHeader,
        SidebarMenuItem,
    },
    computed: {
        isOpen: function () {
            return this.SideMenuStore.state.active;
        },
    },
    data: function () {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.toggleSideBar()
        }
        return {};
    },
    methods: {
        toggleSideBar: function () {
            if (this.SideMenuStore.state.active == true) {
                this.SideMenuStore.methods.close();
            } else {
                this.SideMenuStore.methods.open();
            }

            this.$emit("toggle", this.SideMenuStore.state.active);
        },
    },
};
</script>

<style lang="scss" scoped>
.sidebar-body {
    background-color: white;
    background-image: url("~@/assets/imgs/bg/bg-gold.png");
    background-position: right top;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
