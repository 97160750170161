<template>
    <!-- <pre>{{ list.listaddress }}</pre> -->
    <a-button type="primary" class="mb-2" @click="showModal">
        <template #icon>
            <PlusCircleOutlined />
        </template>
        {{ textbtn }}
    </a-button>
    <!-- //modal สร้าง -->
    <a-modal
        v-model:visible="visible"
        width="1000px"
        title="ที่อยู่"
        @ok="fromchange"
    >
        <a-form :model="formState" name="basic" autocomplete="off">
            <a-row>
                เลือกที่อยู่
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1" :order="1">
                    <a-form-item
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-radio-group
                            v-model:value="formState.useraddress.typeaddress"
                        >
                            <a-radio :style="radioStyle" :value="'1'"
                                >ในประเทศ</a-radio
                            >
                            <a-radio :style="radioStyle" :value="'2'">
                                ต่างประเทศ
                            </a-radio>
                        </a-radio-group>
                        <span class="mt-2">
                            <SelectOption
                                v-if="formState.useraddress.typeaddress == '2'"
                                v-model:value="
                                    formState.useraddress.selectcountry
                                "
                                :formStates="formState"
                            ></SelectOption>
                        </span>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex">
                <a-col class="p-1" flex="1 1 300px" :order="1">
                    <a-form-item
                        label="ชื่อ"
                        :name="['useraddress', 'name']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input v-model:value="formState.useraddress.name" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 300px" :order="2">
                    <a-form-item
                        label="นามสกุล"
                        :name="['useraddress', 'lastname']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input
                            v-model:value="formState.useraddress.lastname"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="ที่อยู่"
                        :name="['useraddress', 'address']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-textarea
                            v-model:value="formState.useraddress.address"
                            show-count
                            placeholder="ที่อยู่"
                            :maxlength="400"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="โทร" :name="['useraddress', 'phone']">
                        <InputContactNumber
                            v-if="formState.useraddress.typeaddress == '1'"
                            :key="updatecomponent"
                            v-model:inputValue="formState.useraddress.phone"
                            :errorInfo="errorInfo[3]"
                            :isFirstSave="false"
                            :isShowErrorLbl="false"
                            :inputLbl="lbl.shipping.contactnumber"
                            :minLength="12"
                            :maxLength="12"
                            delimeter="-"
                        >
                        </InputContactNumber>
                        <a-input-group compact v-else>
                            <a-select
                                show-search
                                placeholder="รหัสโทร"
                                style="width: 100px"
                                v-model:value="
                                    formState.user.selectcountry
                                        .selectcountrycode
                                "
                                :options="listcodephone"
                                :filter-option="filterOption"
                                @focus="handleFocus"
                                @blur="handleBlur"
                                @change="handleChange"
                            ></a-select>
                            <a-input
                                style="width: 50%"
                                v-model:value="formState.useraddress.phone"
                            />
                        </a-input-group>

                        <!-- formState.user.selectcountry.selectcountry = {{formState.user.selectcountry.selectcountry}}
                        formState.user.selectcountry.selectcountrycode =
                        {{formState.user.selectcountry.selectcountrycode}}
                        formState.useraddress.selectcountry = {{formState.useraddress.selectcountry}}
                        formState.useraddress.selectcountrycode = {{formState.useraddress.selectcountrycode}}
                        formState.useraddress:{{formState.useraddress}}
                        <pre> {{listcodephone}}</pre> -->
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item label="Email" :name="['useraddress', 'email']">
                        <a-input v-model:value="formState.useraddress.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row v-if="formState.useraddress.typeaddress == '1'">
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="จังหวัด"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <Address
                            @province="onprovince($event)"
                            @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)"
                            @postcode="onpostcode($event)"
                        ></Address>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1"> </a-col>
            </a-row>
            <a-row>
                <a-row>
                    รายการสินค้าที่จัดส่ง
                    <br />

                    <a-col class="pl-5" flex="1 1 " :order="2">
                        <div>
                            <a-checkbox
                                v-model:checked="checkAll"
                                @change="onCheckAllChange"
                            >
                                เลือกทั้งหมด
                            </a-checkbox>
                        </div>

                        <a-divider />
                        <a-checkbox-group
                            v-model:value="formState.useraddress.checkedsend"
                            name="checkboxgroup"
                            :options="productchecksend"
                        />
                    </a-col>
                </a-row>
            </a-row>
            <a-row>
                <!-- <pre>{{ formState.useraddress }}</pre> -->
            </a-row>
        </a-form>
    </a-modal>
    <!-- //modal สร้าง -->
    <!-- //modal แก้ไข -->
    <a-modal
        v-model:visible="visibleEdit"
        width="1000px"
        title="ที่อยู่"
        @ok="fromchange"
    >
        <!-- formState :
        <pre>{{formState}}</pre> -->
        <a-form :model="formState" name="basic" autocomplete="off">
            <a-row>
                <a-col class="p-1" flex="1 1" :order="1">
                    <a-form-item
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-radio-group
                            v-model:value="formState.useraddress.typeaddress"
                        >
                            <a-radio :style="radioStyle" :value="'1'"
                                >ในประเทศ</a-radio
                            >
                            <a-radio :style="radioStyle" :value="'2'">
                                ต่างประเทศ
                            </a-radio>
                        </a-radio-group>
                        <span class="mt-2">
                            <SelectOption
                                v-if="formState.useraddress.typeaddress == '2'"
                                :formStates="formState"
                            >
                            </SelectOption>
                        </span>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex">
                <a-col class="p-1" flex="1 1 300px" :order="1">
                    <a-form-item
                        label="ชื่อ"
                        :name="['useraddress', 'name']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input v-model:value="formState.useraddress.name" />
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 300px" :order="2">
                    <a-form-item
                        label="นามสกุล"
                        :name="['useraddress', 'lastname']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input
                            v-model:value="formState.useraddress.lastname"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="ที่อยู่"
                        :name="['useraddress', 'address']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-textarea
                            v-model:value="formState.useraddress.address"
                            show-count
                            placeholder="ที่อยู่"
                            :maxlength="400"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="โทร"
                        :name="['useraddress', 'phone']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input
                            v-if="formState.useraddress.typeaddress == '1'"
                            v-model:value="formState.useraddress.phone"
                        />
                        <a-input-group compact v-else>
                            <a-select
                                show-search
                                placeholder="รหัสโทร"
                                style="width: 100px"
                                v-model:value="
                                    formState.user.selectcountry
                                        .selectcountrycode
                                "
                                :options="listcodephone"
                                :filter-option="filterOption"
                                @focus="handleFocus"
                                @blur="handleBlur"
                                @change="handleChange"
                            ></a-select>
                            <a-input
                                style="width: 50%"
                                v-model:value="formState.useraddress.phone"
                            />
                        </a-input-group>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="Email"
                        :name="['useraddress', 'email']"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <a-input v-model:value="formState.useraddress.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row v-if="formState.useraddress.typeaddress == '1'">
                <a-col class="p-1" flex="1 1 " :order="1">
                    <a-form-item
                        label="จังหวัด"
                        :rules="{
                            required: true,
                            message: 'กรุณากรอกข้อมูล'
                        }"
                    >
                        <Address
                            :setprovince="formState.useraddress.ProvinceName"
                            :setdistricts="formState.useraddress.BorderName"
                            :setsubdistricts="
                                formState.useraddress.DistrictName
                            "
                            :setpostcode="formState.useraddress.PostCode"
                            @province="onprovince($event)"
                            @districts="ondistricts($event)"
                            @subdistricts="onsubdistricts($event)"
                            @postcode="onpostcode($event)"
                            :key="updatecomponent"
                        ></Address>
                    </a-form-item>
                </a-col>
                <a-col class="p-1" flex="1 1 " :order="1"> </a-col>
            </a-row>
            <a-row>
                <a-row>
                    รายการสินค้าที่จัดส่ง
                    <br />
                    <!-- formState.useraddress.checkedsend : {{formState.useraddress.checkedsend}}
                    productchecksend :{{productchecksend}} -->
                    <a-col class="pl-5" flex="1 1 " :order="2">
                        <div>
                            <a-checkbox
                                v-model:checked="checkAll"
                                @change="onCheckAllChange"
                            >
                                เลือกทั้งหมด
                            </a-checkbox>
                        </div>

                        <a-divider />
                        <a-checkbox-group
                            v-model:value="formState.useraddress.checkedsend"
                            name="checkboxgroup"
                            :options="productchecksend"
                        />
                    </a-col>
                    <!-- <a-col class="pl-5" flex="1 1 " :order="3">
                        <a-button type="primary" @click="getsentaddress">อัพเดท(สินค้าที่จัดส่ง)</a-button>
                    </a-col> -->
                </a-row>
            </a-row>
        </a-form>
    </a-modal>
    <!-- //modal แก้ไข -->
    <a-divider orientation="left">ที่อยู่(1.3)</a-divider>
    <!-- <pre>{{dataSource}}</pre> -->
    <a-table
        :columns="columns"
        :data-source="dataSource"
        bordered
        :scroll="{ x: 0 }"
        :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
    >
        <template #bodyCell="{ column, record,index }">
            <template v-if="column.dataIndex === 'key'">
                <div>รายการที่ {{ index + 1 }}</div>
            </template>
            <template v-if="column.dataIndex == 'sent'">
                <div class="text-center">
                    <font-awesome-icon
                        v-show="record.SetDefault == '1'"
                        icon="fa-solid fa-check"
                        center
                    />
                </div>
            </template>
            <template v-if="column.dataIndex == 'address'">
                <div v-show="record.typeaddress == '1'">
                    <font-awesome-icon icon="fa-solid fa-home" />
                    <ul>
                        <li>
                            <a-typography-text>{{
                                record.Address
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.ProvinceName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.BorderName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.DistrictName
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.PostCode
                            }}</a-typography-text>
                        </li>
                    </ul>
                </div>
                <div v-show="record.typeaddress == '2'">
                    <font-awesome-icon
                        icon="fa-solid fa-solid fa-earth-americas"
                    />
                    <ul>
                        <li>
                            <a-typography-text>{{
                                record.selectcountry
                            }}</a-typography-text>
                        </li>
                        <li>
                            <a-typography-text>{{
                                record.address
                            }}</a-typography-text>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-if="column.dataIndex == 'productcheckedsend'">
                <div>
                    <ul
                        v-for="(bcItem, idx) of formatproduct(
                            record.productcheckedsend
                        )"
                        :key="`bc-item-${idx}`"
                    >
                        <li>{{ bcItem }}</li>
                    </ul>
                </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
                <div class="editable-row-operations">
                    <span class="m-2">
                        <a-popconfirm
                            title="คุณต้องการแก้ไข?"
                            @confirm="onSetDefault(record)"
                        >
                            <setting-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>ตั้งค่าเริ่มต้น</a>
                        </a-popconfirm>
                    </span>
                    <span class="m-2">
                        <a-popconfirm
                            title="คุณต้องการแก้ไข?"
                            @confirm="onEdit(record)"
                        >
                            <setting-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>แก้ไข</a>
                        </a-popconfirm>
                    </span>
                    <span class="m-2">
                        <a-popconfirm
                            title="คุณต้องการลบ?"
                            @confirm="onDelete(record.key)"
                        >
                            <delete-outlined
                                :style="{
                                    fontSize: '18px',
                                    color: '#08c',
                                    padding: '10px'
                                }"
                            />
                            <a>ลบ</a>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
        </template>
    </a-table>
</template>
<script>
// import { PlusCircleOutlined } from "@ant-design/icons-vue";
import InputContactNumber from "@/components/Input/label/InputContactNumber.vue";
import InputEmail from "@/components/Input/label/InputEmail.vue";
import { defineComponent, reactive, ref, watch, onMounted, toRefs } from "vue";
import SelectOption from "../form/SelectOption.vue";
import Address from "../../component/Address.vue";
import { Form } from "ant-design-vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
import { formStateStore } from "@/store/storebackoffice";
import { SettingOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { FormHelper } from "@/helper/FormHelper.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
import { notification } from "ant-design-vue";
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "key",
        width: "5%"
    },
    {
        title: "ที่อยู่",
        dataIndex: "address",
        width: "10%"
    },
    {
        title: "รายการสินค้า",
        dataIndex: "productcheckedsend",
        width: "15%"
    },
    {
        title: "ตั้งค่า",
        dataIndex: "sent",
        fixed: "right",
        width: "3%"
    },
    {
        title: "จัดการ",
        dataIndex: "operation",
        fixed: "right",
        width: "10%"
    }
];
const data = [];

for (let i = 0; i < 100; i++) {
    data.push({
        key: i.toString(),
        name: `Edrward ${i}`,
        age: 32,
        address: `London Park no. ${i}`
    });
}
export default defineComponent({
    components: {
        InputEmail,
        InputContactNumber,
        DeleteOutlined,
        SettingOutlined,
        SelectOption,
        Address
        // PlusCircleOutlined
    },
    props: {
        textbtn: null,
        formStates: null
    },
    mixins: [LocaleMixin],
    setup(props, { emit }) {
        const updatecomponent = ref(0);
        const isclieckOnEdit = ref(false);
        const listselectsent = ref([]);
        const checkedsend = ref([]);
        const visible = ref(false);
        const visibleEdit = ref(false);
        const formState = reactive(props.formStates);
        const productchecksend = ref([]);

        const showModal = () => {
            formState.useraddress = {
                name: "",
                lastname: "",
                address: "",
                phone: "",
                phonecode: "",
                email: "",
                key: 0,
                typeaddress: "1",
                selectcountry: "Thailand",
                selectcountrycode: "",
                ProvinceName: "",
                ProvinceID: "",
                BorderName: "",
                BorderID: "",
                DistrictName: "",
                PostCode: "",
                provincepostinter: "",
                citypostinter: "",
                postcodepostinter: ""
            };

            productchecksend.value = [];

            formState.listproduct.forEach((element, index) => {
                element = _.transform(element, function(result, val, key) {
                    result[key.toLowerCase()] = val;
                });
                let obj = {};
                obj.label =
                    "รายการที่ " +
                    (index + 1) +
                    "   " +
                    element.customerproductstype +
                    "," +
                    element.customerproductssize +
                    "," +
                    element.heartyanname +
                    "," +
                    element.productcode;
                obj.value = obj.label;
                obj.detail = element;
                productchecksend.value.push(obj);
            });
            visible.value = true;
        };

        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        const value = ref(1);
        const radioStyle = reactive({});

        const list = reactive({
            listaddress: []
        });

        const getsentaddress = () => {
            console.log("formState.listproduct", formState.listproduct);
            productchecksend.value = [];

            formState.listproduct.forEach((element, index) => {
                let obj = {};
                obj.label =
                    "รายการที่ " +
                    (index + 1) +
                    "   " +
                    element.customerproductstype +
                    "," +
                    element.customerproductssize +
                    "," +
                    element.heartyanname +
                    "," +
                    element.productcode;
                obj.value = obj.label;
                obj.detail = element;
                productchecksend.value.push(obj);
            });
            // visible.value = true;
        };
        // watch(
        //     () => formState.listproduct,
        //     () => {
        //         if (
        //             listcodephone.value.find(
        //                 word =>
        //                     word.value ==
        //                     formState.useraddress.selectcountry.selectcountry
        //             )
        //         ) {
        //             formState.useraddress.phonecode = listcodephone.value.filter(
        //                 word =>
        //                     word.value ==
        //                     formState.useraddress.selectcountry.selectcountry
        //             );
        //         }
        //     }
        // );
        watch(
            () => formState.useraddress.selectcountry.selectcountry,
            () => {
                if (
                    listcodephone.value.find(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    )
                ) {
                    formState.useraddress.phonecode = listcodephone.value.filter(
                        word =>
                            word.value ==
                            formState.useraddress.selectcountry.selectcountry
                    );
                }
            }
        );

        const onSetDefault = record => {
            if (record.SetDefault == undefined) {
                dataSource.value.map(item => (item.SetDefault = "0"));
                record.SetDefault = "1";
            }
        };
        const formItemContext = Form.useInjectFormItemContext();
        // var test= [];
        const fromchange = () => {
            console.log("editIndex.value", editIndex.value);
            console.log("edit formState.useraddress", formState.useraddress);
            isclieckOnEdit.value = false;

            formState.useraddress.productcheckedsend =
                formState.useraddress.checkedsend;
            if (visibleEdit.value) {
                formState.listaddress[editIndex.value] = Object.assign(
                    {},
                    formState.useraddress
                );
            } else {
                let obj = {};
                obj = Object.assign({}, formState.useraddress);
                obj.key =
                    list.listaddress.length == 0 ? 0 : list.listaddress.length;

                list.listaddress[obj.key] = Object.assign({}, obj);
                console.log("list.listaddress", list.listaddress);
            }
            // emit("update:value", Object.assign({}, formState));
            console.log("edit pop :", dataSource.value);
            if (editIndex.value) {
                dataSource.value[editIndex.value.key] = formState.useraddress;
            } else {
                formState.useraddress.addresskey = dataSource.value.length?.toString();
                dataSource.value.push(formState.useraddress);
            }
            emit("update:value", dataSource.value);
            // formItemContext.onFieldChange();
            visible.value = false;

            visibleEdit.value = false;
        };
        const listcodephone = ref([]);
        BzbsUser.apicountriesphone()
            .then(res => {
                Object.keys(res.data).map(function(element) {
                    listcodephone.value.push({
                        value: element,
                        label: res.data[element]
                    });
                });
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };
        onMounted(() => {
            getproductdeliveryaddress();
        });
        const getproductdeliveryaddress = () => {
            dataSource.value = [];
            BzbsUser.apigetproductdeliveryaddress(
                formState.user.customercode,
                formState.user.sellorderTransaction
            )
                .then(res => {
                    console.log("res :", res);

                    res.data.forEach((element, index) => {
                        var param = {
                            key: index,
                            productaddressdeliveryorder:
                                element.ProductAddressDeliveryOrder,
                            productcheckedsend: element.CheckedSend,
                            checkedsend: element.CheckedSend,
                            name: element.Name,
                            lastname: element.Lastname,
                            address: element.Address,
                            phone: element.Phone,
                            email: element.Email,
                            typeaddress: element.TypeAddress,
                            selectcountry: element.SelectCountry,
                            ProvinceName: element.ProvinceName,
                            ProvinceID: element.ProvinceID,
                            BorderName: element.BorderName,
                            BorderID: element.BorderID,
                            DistrictName: element.DistrictName,
                            PostCode: element.PostCode,
                            SetDefault: element.SetDefault
                        };
                        formState.useraddress = param;
                        dataSource.value.push(param);
                    });

                    console.log(res);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const onprovince = values => {
            console.log("onprovince", values);
            formState.useraddress.ProvinceName = values.provinceName;
            formState.useraddress.ProvinceID = values.provinceID;
        };
        const ondistricts = values => {
            console.log("ondistricts", values);
            formState.useraddress.BorderName = values.districtsName;
            formState.useraddress.BorderID = values.districtsID;
        };
        const onsubdistricts = values => {
            console.log("onsubdistricts", values);
            formState.useraddress.DistrictName = values.subdistrictsName;
        };
        const onpostcode = values => {
            console.log("onpostcode", values);
            formState.useraddress.PostCode = values;
        };
        const handleChange = (input, option) => {
            formState.useraddress.phonecode = option.label;
        };
        const dataSource = ref(list.listaddress);
        const editableData = reactive({});
        const editIndex = ref();
        const options = [
            {
                label: "Apple",
                value: "Apple"
            },
            {
                label: "Pear",
                value: "Pear"
            },
            {
                label: "Orange",
                value: "Orange"
            }
        ];
        const onEdit = key => {
            editIndex.value = key;
            console.log("editIndex.value", editIndex.value);

            productchecksend.value = [];
            try {
                formState.useraddress = key;
                console.log("productchecksend :", productchecksend);
                // getCustomerProductlist()

                formStateStore.listproduct.forEach((items, index) => {
                    var result = _.transform(items, function(result, val, key) {
                        result[key.toLowerCase()] = val;
                    });
                    console.log("result >", result.productdetail);

                    let obj = {};
                    obj.label =
                        "รายการที่ " +
                        (index + 1) +
                        "   " +
                        result.customerproductstype +
                        "," +
                        result.customerproductssize +
                        "," +
                        result.heartyanname +
                        "," +
                        result.productcode;
                    obj.value = obj.label;
                    obj.detail = items.ProductJson;
                    productchecksend.value.push(obj);
                });

                visibleEdit.value = true;
                updatecomponent.value++;
                checkstatusall();
            } catch (error) {
                visibleEdit.value = true;
            }
        };

        const save = key => {
            Object.assign(
                dataSource.value.filter(item => key === item.key)[0],
                editableData[key]
            );
            delete editableData[key];
        };

        const cancel = key => {
            delete editableData[key];
        };

        const onDelete = key => {
            var index = dataSource.value.findIndex(e => e.key === key);
            deleteaddress(dataSource.value[index]);
            dataSource.value.splice(
                dataSource.value.findIndex(e => e.key === key),
                1
            );

            emit("update:value", dataSource.value);

            formState.listproduct = dataSource.value;
        };
        const deleteaddress = items => {
            console.log("remove product:", items);
            var param_del = {
                CustomerOrder: formState.user.customercode,
                ProductSellOrder: formState.user.sellorderTransaction,
                AddressKey: items.key?.toString()
            };
            console.log("param_del : ", param_del);

            BzbsUser.apideleteproductdeliveryaddress(param_del)
                .then(res => {
                     
                    openNotification("ลบรายการเรียบร้อย");
                    console.log(res);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(error => {
                     
                    console.log(error);
                    window.location.reload();
                });
        };
        const openNotification = massage => {
            notification["success"]({
                message: massage
            });
        };

        const formatproduct = product => {
            try {
                console.log("case1");
                if (_.isArray(product)) {
                    return product;
                } else {
                    return JSON.parse(product);
                }
            } catch (error) {
                console.log("case2");
                return product
                    .toString()
                    ?.replace("[", "")
                    .replace("]", "");
            }
        };
        const state = reactive({
            indeterminate: true,
            checkAll: false,
            checkedList: []
        });
        const onCheckAllChange = e => {
            console.log(productchecksend.value);
            Object.assign(state, {
                indeterminate: false
            });
            formState.useraddress.checkedsend = [];
            productchecksend.value.forEach(element => {
                formState.useraddress.checkedsend.push(element.label);
            });
            if (e.target.checked) {
                state.checkedList = formState.useraddress.checkedsend;
            } else {
                state.checkedList = [];
                formState.useraddress.checkedsend = [];
            }
        };
        watch(
            () => state.checkedList,
            val => {
                state.indeterminate =
                    !!val.length && val.length < productchecksend.value.length;
                state.checkAll = val.length === productchecksend.value.length;
            }
        );

        const checkstatusall = () => {
            if (
                formState.useraddress?.checkedsend &&
                JSON.parse(formState.useraddress?.checkedsend).length ==
                    productchecksend.value.length
            ) {
                state.checkAll = true;
            }
        }
        return {
            checkstatusall,
            ...toRefs(state),
            onCheckAllChange,
            formatproduct,
            onEdit,
            onDelete,
            onprovince,
            ondistricts,
            onsubdistricts,
            onpostcode,
            value,
            radioStyle,
            formState,
            fromchange,
            filterOption,
            listcodephone,
            visible,
            visibleEdit,
            showModal,
            handleOk,
            handleChange,
            dataSource,
            columns,
            editingKey: "",
            editableData,
            save,
            cancel,
            editIndex,
            list,
            productchecksend,
            checkedsend,
            watch,
            listselectsent,
            options,
            getsentaddress,
            formStateStore,
            updatecomponent,
            errorInfo: FormHelper.genErrorInfo(7),
            onSetDefault
        };
    }
});
</script>
