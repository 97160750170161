<template>
    <div class="flex w-full flex-wrap">
        <template v-if="inline">
            <div
                class="flex-none text-sm mb-1 px-6 mt-2 text-gray-500"
                :class="[isError ? 'text-red-500' : 'text-gray-500', inputLbl.align != undefined ? inputLbl.align : 'text-left']"
                :style="{ width: labelWidthComputed }"
            >{{ inputLbl.name }}</div>
        </template>
        <template v-else>
            <div
                class="w-full text-sm mb-1 mt-2 text-gray-500"
                :class="[isError ? 'text-red-500' : 'text-gray-500', inputLbl.align != undefined ? inputLbl.align : 'text-left']"
            >{{ inputLbl.name }}</div>
        </template>

        <textarea
            v-model="content"
            class="rounded bg-gray-50 p-2 flex-auto mb-5 border text-sm outline-none  focus:border-blue-1"
            :class="[inline ? 'flex-auto' : 'w-full']"
            :rows="rows"
            :maxlength="inputMaxLength"
        ></textarea>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"
export default {
    name: "InputTextArea",
    props: {
        //v-model
        inputValue: { type: String },
        //props
        minLength: { type: Number },
        maxLength: { type: Number, default: 0 },
        isTextOnly: { type: Boolean, default: false },
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        //text
        inputLbl: { type: Object, required: true },
        inline: {
            type: Boolean,
            default: true
        },
        labelWidth: {
            type: [Number, String],
            default: 100
        },
        bg: {
            type: String,
            default: undefined
        },
        rows: {
            type: Number,
            default: 2
        }
    },
    data: function () {
        return {
            content: this.inputValue,
            inputMaxLength: null,
        };
    },
    computed: {
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
        labelWidthComputed: function () {
            return PropsHelper.getBoxUnit(this.labelWidth)
        },
        bgComputed: function () {
            return PropsHelper.getStringProp(this.bg)
        }
    },
    created: function () {
        if (this.maxLength > 0) {
            this.inputMaxLength = this.maxLength;
        }
    },
    mounted: function () {
        this.validateInput();
    },
    methods: {
        validateInput: function () {

            if (!this.inputLbl) {
                return;
            }
            //Set default.
            this.errorInfo.setValid(true);
            this.errorInfo.setMsg('');
            //Required
            if (this.inputLbl.required && !this.content) {
                this.errorInfo.setValid(false);
                this.errorInfo.setMsg(this.inputLbl.required)
                return;
            }
        },
        onChange: function () {
            var result = this.content;

            this.content = result;
            this.validateInput();
            this.$emit('update:inputValue', result);
        },
    },
}
</script>
