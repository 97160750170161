<template>
    <div class="relative p-4 card">
        <!-- <ExportPDF></ExportPDF> -->
        <div v-if="!formStateStore.isshowpacking">
            <a-switch v-model:checked="isrecord" /><span class="pl-2">AUTO อัดวีดีโอ</span>
            <a-divider orientation="left">หมายเลขการจัดส่ง</a-divider>
            <a-input-group compact>
                <a-form-item has-feedback :validate-status="isshowpacking ? 'success' : ''" style="width: calc(100% - 60%)">
                    <a-input v-focus :disabled="formStateStore.isshowpacking"
                        v-model:value="formStateStore.rowselecteddetail" :maxlength="17" />
                </a-form-item>
                <a-tooltip title="copy git url">
                    <a-button>
                        <template #icon>
                            <BarcodeOutlined />
                        </template>
                    </a-button>
                </a-tooltip>
                <a-button type="primary" @click="startscan" class="ml-4">ค้นหา</a-button>

            </a-input-group>

        </div>

        <CreatePacking v-if="formStateStore.isshowpacking" :key="updatepacking" :rowselected="scan_result"
            :isrecord="isrecord">
        </CreatePacking>
    </div>
</template>
<script>
import { Modal } from 'ant-design-vue';
import { BarcodeOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, ref, reactive, watch } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th";
import CreatePacking from './CreatePacking.vue'; // without this line it didn't work
import CreateRecord from './CreateRecord.vue'; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue"; 
import { formStateStore } from "@/store/storebackoffice";
// import ExportPDF from './ExportPDF.vue';


export default defineComponent({
    components: {
        BarcodeOutlined,
        CreatePacking,
        CreateRecord
        // ExportPDF
    },

    setup() {
        const isrecord = ref(false)
        formStateStore.rowselecteddetail = ""
        formStateStore.isshowpacking = false
        const updatepacking = ref(0)
        const scan_result = ref({ rowselected: '', customercode: "" })
        onMounted(() => {
            setTimeout(() => {
                // formStateStore.rowselecteddetail = 'SP-20221201154924';
            }, 5000);
            querycustomer();
        });
        watch(
            () => formStateStore.rowselecteddetail,
            () => {
                if (formStateStore.rowselecteddetail.length == 17) {
                    formStateStore.isshowpacking = false

                    querycustomer()
                    updatepacking.value++
                } else {
                    updatepacking.value++
                    formStateStore.isshowpacking = false
                }
            },

        );

        const startscan = () => {
            if (formStateStore.rowselecteddetail.length == 17) {
                formStateStore.isshowpacking = false

                querycustomer()
                updatepacking.value++
            } else {
                updatepacking.value++
                formStateStore.isshowpacking = false
            }

        }

        const querycustomer = () => {

            var sp = formStateStore.rowselecteddetail
            return BzbsUser.getcreatedelivery(sp)
                .then(res => { 
                    //เคสรับเอง 14 ไม่มี barcode ให้ ปุ่ม เริ่มแพ็คสินค้า ฟ้า
                    if(res?.data?.every(item => item.DeliveryType === "14")){ 
                        formStateStore.user.finishpacked_scantracking = true
                    }
                    res.data.forEach((element, index) => {
                        res.data[index].key = index;
                    });
                    if (res.data[0]?.PD_DeliveryStatus == 'cancel') {

                        warning('รายการนี้ถูกยกเลิกไปแล้ว', 'กรุณาเลือกรายการใหม่อีกครั้ง')
                    }
                    // else if (res.data[0]?.PD_DeliveryStatus == 'packed') {

                    //     warning('แพ็คจัดส่งเรียบร้อย', 'รายการนี้ scan จัดส่งเรียบร้อย')
                    // }
                    else {
                        scan_result.value.rowselected = formStateStore.rowselecteddetail
                        scan_result.value.customercode = res.data[0]?.CustomerOrder
                        formStateStore.isshowpacking = true
                    }

                })
                .catch(error => {
                    console.log(error);
                });
        };
        const warning = (title, content) => {
            Modal.warning({
                title: title,
                content: content,
            });
        };

        return {
            warning,
            updatepacking,
            formStateStore,
            scan_result,
            BzbsUser,
            reactive,
            computed,
            moment,
            axios,
            ref,
            isrecord,
            startscan
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
