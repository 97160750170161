<template>
    <div class="flex w-full items-center">
        <template v-if="inline">
            <div
                class="flex-none text-sm mb-1 px-6 mt-2 text-right text-gray-500"
                :class="[isError ? 'text-red-500' : 'text-gray-500', inputLbl.align != undefined ? inputLbl.align : null]"
                :style="{ width: labelWidthComputed }"
            >{{ inputLbl.name }}</div>
        </template>
        <template v-else>
            <div
                class="w-full text-sm mb-1 px-6 mt-2 text-right text-gray-500"
                :class="[isError ? 'text-red-500' : 'text-gray-500', inputLbl.align != undefined ? inputLbl.align : null]"
            >{{ inputLbl.name }}</div>
        </template>

        <div
            class="dropdown cursor-pointer hover:bg-gray-100 rounded relative"
            :class="[inline ? 'flex-auto' : 'w-full', isError ? 'border border-red-500' : 'border', bgComputed != null ? bgComputed : null]"
            v-click-away="onClickAway"
            ref="dropdown"
        >
            <div
                class="dropdown-wrapper flex items-center px-3 py-2"
                @click="toggle()"
                :class="{ 'z-20': show }"
            >
                <div
                    class="flex-auto w-full text-sm text-gray-700"
                >{{ selectedItem == null ? (showDefaultAll ? defaultAllText : '') : selectedItem.text }}</div>
                <div class="flex-none px-3">
                    <img
                        src="@/assets/icons/arrow/arrow-chevron-down.svg"
                        class="transition duration-100"
                        alt
                        :class="{ 'transform rotate-180': show }"
                    />
                </div>
            </div>

            <div
                class="text-red-500 text-sm absolute bottom-0 left-0"
                v-if="isError"
            >{{ getErrorMsg }}</div>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <template v-if="show">
                    <ul
                        class="absolute z-10 w-full mt-2 bg-white shadow-lg max-h-56 rounded-md py-1 text-sm overflow-auto focus:outline-none border border-black border-opacity-5"
                        tabindex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        ref="dropdown-body"
                    >
                        <template v-if="showDefaultAll">
                            <li
                                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                                role="option"
                                @click="selectItem(null)"
                            >
                                <div class="flex items-center">{{ defaultAllText }}</div>

                                <span
                                    class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                    v-if="selectedItem == null"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </li>
                        </template>

                        <template v-for="(item, idx) of items" :key="`ddl-${idx}`">
                            <li
                                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                                role="option"
                                @click="selectItem(item)"
                            >
                                <div class="flex items-center">{{ item.text }}</div>

                                <span
                                    class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                                    v-if="selectedItem != null && selectedItem.value == item.value"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </li>
                        </template>
                    </ul>
                </template>
            </transition>
        </div>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: "DropdownList",
    props: {
        //v-model
        modelValue: { type: String },
        //props
        maxLength: { type: Number, default: 300 },
        errorInfo: { type: Object, required: true },
        isFirstSave: { type: Boolean, default: true },
        isShowErrorLbl: { type: Boolean, default: false },
        //text
        inputLbl: { type: Object, required: true },
        showDefaultAll: {
            type: Boolean,
            default: false
        },
        defaultAllText: {
            type: String,
            default: "All"
        },
        items: {
            type: Array,
            default: () => []
        },
        inline: {
            type: Boolean,
            default: true
        },
        labelWidth: {
            type: [Number, String],
            default: 100
        },
        bg: {
            type: String,
            default: undefined
        }
    },
    emits: ["update:modelValue"],
    data: function () {
        return {
            show: false,
            selectedItem: null,
            overscreen: false
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue
            },
            set: function (newVal) {
                this.$emit("update:modelValue", newVal)
            }
        },
        isError: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return true;
            }
            return false;
        },
        getErrorMsg: function () {
            if (this.isShowErrorLbl && !this.isFirstSave && !this.errorInfo.isValid) {
                return this.errorInfo.msg;
            }
            return '';
        },
        labelWidthComputed: function () {
            return PropsHelper.getBoxUnit(this.labelWidth)
        },
        bgComputed: function () {
            return PropsHelper.getStringProp(this.bg)
        }
    },
    watch: {
        items: function () {
            this.setDefault();
        },
        show: function (val) {
            if (val) {
                this.reposition()
            } else {
                this.removeReposition()
            }
        }
    },
    mounted: function () {
        this.setDefault()
    },
    methods: {
        setDefault() {
            if (this.value != null) {
                let existSelectedItem = this.items.find(x => x.value == this.value)
                if (existSelectedItem != null) {
                    this.selectedItem = existSelectedItem
                }
            } else {
                if (this.items.length > 0) {
                    this.selectedItem = this.items[0]
                    this.value = this.selectedItem.value
                }
            }
        },
        selectItem: function (item) {
            this.selectedItem = item
            this.show = false

            this.value = this.selectedItem.value // send only value to v-model
        },
        onClickAway: function () {
            this.hide();
        },
        toggle: function () {
            this.show = !this.show
        },
        hide: function () {
            this.show = false
            this.hasEntered = false;
        },
        reposition: function () {
            this.$nextTick(() => {
                /**@type HTMLElement */
                let dropdown = this.$refs['dropdown']
                const { height } = dropdown.getBoundingClientRect()

                /**@type HTMLElement */
                let elem = this.$refs['dropdown-body']
                const { bottom } = elem.getBoundingClientRect()

                let windowHeight = window.innerHeight
                // console.log(bottom, windowHeight)
                if (bottom > windowHeight) {
                    elem.style.bottom = `${height}px`
                }
            });
        },
        removeReposition: function () {
            /**@type HTMLElement */
            let elem = this.$refs['dropdown-body']
            elem.style.removeProperty("bottom")
        }
    }
}
</script>
