<template>
    <button
        class="btn wrapper-btn"
        :class="buttonClass"
        :style="buttonStyle"
        :disabled="disabled"
    >
        <span class="flex items-center justify-center">
            <template v-if="hasSlot('icon-left')">
                <span class="pr-2">
                    <slot name="icon-left"></slot>
                </span>
            </template>
            <slot></slot>
            <template v-if="hasSlot('icon-right')">
                <span class="pl-2">
                    <slot name="icon-right"></slot>
                </span>
            </template>
        </span>
    </button>
</template>

<script>
export default {
    name: "VButton",
    props: {
        /**
         * set button to outlined mode
         */
        outlined: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: undefined
        },
        custom: {
            type: [String, Boolean],
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        },
        blocked: {
            type: Boolean,
            default: false
        },
        sm: {
            type: [String, Boolean],
            default: undefined
        }
    },
    computed: {
        buttonClass: function() {
            let cls = [];

            if (this.color != undefined && this.color != null) {
                cls.push("btn-" + this.color);
            } else {
                if (this.custom == undefined) {
                    cls.push("btn-default");
                } else {
                    cls.push("btn-custom");
                }
            }

            if (this.outlined) {
                cls.push("btn-outlined");
            }

            if (this.blocked) {
                cls.push("btn-blocked");
            }

            if (this.sm != undefined) {
                cls.push("btn-sm");
            }

            return cls;
        },
        buttonStyle: function() {
            let styles = {};
            return styles;
        }
    },
    methods: {
        hasSlot: function(name = "default") {
            return !!this.$slots[name];
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper-btn {
    flex-grow: 1;
    margin: 0px 5px;
    @media only screen and (max-width: 800px) {
        flex-grow: 1;
        margin: 5px 5px;
    }
}
</style>
