<template>
    <div class="flex flex-col w-full h-full max-w-full overflow-x-hidden page">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar @clickexport="modalController.exportModal = true"></Toolbar>
            </template>
        </TopBar>
        <div> 
            <Table></Table>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import Table from "../../../DashboardInfo/component/Table.vue";


export default {
    components: { TopBar, Footer, Table },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "รายการขาย"
                }
            ];
        }
    }
};
</script>

<style>

</style>
