<template>
    <div class="wrapper-footer-login footer w-full">
        <div class="flex items-flex-end h-full pb-3">
            <div class="flex-grow pl-4 text-xs">
                <a class="no-underline pr-4 text-white" href>FAQ</a>
                <a class="no-underline pr-4 text-white" href>Help</a>
                <a class="no-underline pr-4 text-white" href>Privacy</a>
            </div>
            <div class="wrapper-language">
                <div class="no-underline pr-4 text-white font-size-language">Language</div>
                <div class="pr-5">
                    <a-select :default-value="languageOption[0]" size="small">
                        <a-select-option v-for="item in languageOption" :key="item">
                            {{ item.text }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    props: {
        fixed: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            languageOption: [
                { id: 'en', text: 'ENG'},
                { id: 'th', text: 'TH'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/style/base/base.scss";
// .footer {
//     height: $footer-height;
//     box-shadow: 0px -1px 10px 1px rgba(0, 0, 0, 0.1);
// }
.wrapper-footer-login {
    background-image: url("~@/assets/imgs/bg/shady.png");
    height: 89px;
    .wrapper-language {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .font-size-language {
            font-size: 12px;
        }
    }
    .items-flex-end {
        align-items: flex-end;
    }
}

</style>