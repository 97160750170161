<template>
    <div>
        <a-button style="font-size:12px;font-weight: bold;" type="primary" @click="showModal">รายละเอียด</a-button>
        <a-modal v-model:visible="visible" width="1200px" title="Log Pixel" @ok="handleOk">

            <div v-for="items in  PixelTracking" :key="items">
                <!-- {{items.facebook_param.data}} -->
                <a-descriptions bordered :title="items.pixel_event_name" :size="'default'">
                    <a-descriptions-item style="width:200px" label="Pixel ID">{{items.facebook_param.ADS_PIXEL_ID}}
                    </a-descriptions-item>
                    <a-descriptions-item style="width:200px" label="Access Token">{{items.facebook_param.ACCESS_TOKEN}}
                    </a-descriptions-item>


                </a-descriptions>
                <div v-for="detail in  items.facebook_param.data">
                   
                    <a-descriptions bordered :size="'default'">
                        <a-descriptions-item style="width:200px" label="Event name">{{detail.event_name}}
                        </a-descriptions-item>


                        <a-descriptions-item style="width:200px" label="Ip Customer">
                            {{detail.user_data.client_ip_address}}
                        </a-descriptions-item>
                        <a-descriptions-item style="width:200px" label="Custom Parameters Sent">
                            <pre>{{detail.custom_data}}</pre>
                        </a-descriptions-item>
                        <a-descriptions-item style="width:200px" label="Fbp">{{detail.user_data.fbp||'-'}}
                        </a-descriptions-item>
                        <a-descriptions-item style="width:240px" label="Pixel Event Id">{{detail.event_id}}
                        </a-descriptions-item>

                        <a-descriptions-item label="url">
                            {{detail.event_source_url}}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
                <br />
                <br />
            </div>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
    props: {
        PixelTrack: null
    },
    setup(props) {
        const PixelTracking = ref(JSON.parse(props.PixelTrack));

        PixelTracking.value.map(element => {
            element.facebook_param = JSON.parse(element.facebook_param)
            // element.facebook_param.data = JSON.parse(element.facebook_param.data)


        });

        const visible = ref(false);
        const showModal = () => {
            visible.value = true;
        };
        const handleOk = e => {
            console.log(e);
            visible.value = false;
        };
        return {
            visible,
            showModal,
            handleOk,
            PixelTracking
        };
    },
});
</script>