<template>
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">
            <a-table :scroll="{ x: 0 }" :columns="columns" :data-source="dataSources" :loading="loading"
                @change="handleTableChange" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'LINK'">
                        <div class="">
                            <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                                <a :href="'/salepageedit?id=' + record.SalePageID"><u>{{ record.SalePageID }}</u></a>
                            </a-typography-title>

                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'EDIT'">
                        <a-dropdown>
                            <template #overlay>
                                <a-menu @click="handleMenuClick">
                                    <a-menu-item :key="['1', record.SalePageID]">ดูตัวอย่าง</a-menu-item>
                                    <a-menu-item :key="['2', record.SalePageID]">แก้ไข</a-menu-item>
                                    <a-menu-item :key="['3', record.SalePageID]" @click="onpopclone(record.SalePageID)">
                                        โคลนเซลเพจ
                                    </a-menu-item>
                                </a-menu>
                            </template>
                            <a-button>
                                ตัวจัดการ
                                <DownOutlined />
                            </a-button>
                        </a-dropdown>
                        <!-- <a-typography-title :level="5" style="padding: 0px;margin:0px;">
                            <a :href="'/salepageedit?id=' + record.SalePageID"><u>ตัวจัดการ</u></a>
                        </a-typography-title> -->
                        <a-modal v-model:visible="visible" title="โคลนเซลเพจ" @ok="handleOk">
                            <a-input v-model:value="CloneName" placeholder="ตั้งชื่อเซลเพจ" />
                        </a-modal>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
import { usePagination } from "vue-request";
import { computed, defineComponent, onMounted, ref } from "vue";
import { config_namahayant } from '@/store/store'
import axios from "axios";
const columns = [
    {
        title: "ชื่อเซลเพจ",
        dataIndex: "LINK",
        width: "5px"
    },
    {
        title: "จัดการ",
        dataIndex: "EDIT",
        width: "5px"
    },

];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    setup() {
        const dataSources = ref(null);
        const CloneName = ref("");
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการเซลเพจ ของโดเมน nayant",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const querycustomer = () => {
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;
            return axios.get(path + "/api/salepageall").then(res => {
                dataSources.value = res.data[0];
                console.log(res.data[0]);
            });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };

        const handleMenuClick = e => {
            if (e.key[0] == 1) {
                window.open("https://" + e.key[1] + "." + config_namahayant.salepageurl + "/" + e.key[1]);
            }
            else if (e.key[0] == 2) {
                window.location = "/salepageedit?id=" + e.key[1];
            }
        };
        const cloneid = ref("");
        const handleOk = e => {
            console.log(e)
            if (CloneName.value) {
        
                window.location = "/salepageedit?cloneto=" + CloneName.value.replace(/ /g, '') + '&id=' + cloneid.value;
            }
            visible.value = false;
        };
        const visible = ref(false);

        const showModal = () => {
            visible.value = true;
        };
        const onpopclone = (e) => {
            cloneid.value = e
            visible.value = true;
        };



        return {
            onpopclone,
            CloneName,
            showModal,
            handleOk,
            visible,
            handleMenuClick,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer
        };
    }
});
</script>
