<template>
    <div class="flex flex-col w-full h-full max-w-full overflow-x-hidden page">
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar
                    @clickexport="modalController.exportModal = true"
                ></Toolbar>
            </template>
        </TopBar>
        <div> 
       <SalepageFrom></SalepageFrom>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import SalepageFrom from "@/module/Salepage/SalepageCreate/component/FromCreateSell.vue";
// import Table from "../component/Table.vue"


export default {
    components: { TopBar, Footer,SalepageFrom },
    computed: {
        bcItems: function() {
            return [
                {
                    text: "ระบบหลังบ้าน"
                },
                {
                    text: "สร้างรายการขาย"
                }
            ];
        }
    }
};
</script>

<style></style>
