import {
    RedeemShippingAddressConfigModal,
    RedeemInfoConfigModel,
    RedeemLocationConfigModel,
    RedeemOtherConfigModel
} from './RedeemConfigModel.js';

export function RedeemConfigBuilder () {
    this.redeemInfoConfigBuilder = null;
    this.redeemLocationConfigBuilder = null;
    this.redeemOtherConfigBuilder = null;
    this.redeemShippingAddressConfigBuilder = null;

    this.getInfoConfigBuilder = () => {
        this.redeemInfoConfigBuilder = new RedeemInfoConfigBuilder();
        return this;
    }

    this.getRedeemOtherConfigBuilder = () => {
        this.redeemOtherConfigBuilder = new RedeemOtherConfigBuilder();
        return this;
    };

    this.getRedeemLocationConfigBuilder = () => {
        this.redeemLocationConfigBuilder = new RedeemLocationConfigBuilder();
        return this;
    };

    this.getRedeemShippingConfigBuilder = () => {
        this.redeemShippingAddressConfigBuilder = new RedeemShippingAddressConfigBuilder();
        return this;
    };

    this.getRedeemParams = () => {
        var redeemInfoParams = (this.redeemInfoConfigBuilder) ? this.redeemInfoConfigBuilder.create().getParams() : null;
        var redeemLocationParams = (this.redeemLocationConfigBuilder) ? this.redeemLocationConfigBuilder.create().getParams() : null;
        var redeemOtherParams = (this.redeemOtherConfigBuilder) ? this.redeemOtherConfigBuilder.create().getParams() : null;
        var redeemShippingParams = (this.redeemShippingAddressConfigBuilder) ? this.redeemShippingAddressConfigBuilder.create().getParams() : null;

        return {...redeemInfoParams, ...redeemLocationParams, ...redeemOtherParams, ...redeemShippingParams};
    };
}

export function RedeemInfoConfigBuilder () {
     this.redeemInfoConfigModel = new RedeemInfoConfigModel();

     this.create = () => {
        return this.redeemInfoConfigModel;
     };

     this.setInfo1 = info1 => {
         this.redeemInfoConfigModel.info1 = info1;
         return this;
     };

     this.setInfo2 = info2 => {
         this.redeemInfoConfigModel.info2 = info2;
         return this;
     }

     this.setInfo3 = info3 => {
         this.redeemInfoConfigModel.info3 = info3;
         return this;
     };

     this.setInfo4 = info4 => {
        this.redeemInfoConfigModel.info4 = info4;
        return this;
     };

     this.setInfo5 = info5 => {
         this.redeemInfoConfigModel.info5 = info5;
         return this;
     };

}

export function RedeemLocationConfigBuilder() {
    this.redeemLocationConfigModel = new RedeemLocationConfigModel();

    this.create = () => {
        return this.redeemLocationConfigModel;
    };

    this.setCenter = center => {
        this.redeemLocationConfigModel.center = center;
        return this;
    };

    this.setLatitude = latitude => {
        this.redeemLocationConfigModel.latitude = latitude;
        return this;
    };

    this.setLocationId = locationId => {
        this.redeemLocationConfigModel.locationId = locationId;
        return this;
    };

    this.setLongitude = longitude => {
        this.redeemLocationConfigModel.longitude = longitude;
        return this;
    };
}

export function RedeemOtherConfigBuilder() {
    this.redeemOtherConfigModel = new RedeemOtherConfigModel();

    this.create = () => {
        return this.redeemOtherConfigModel;
    };

    this.setGiftTo = giftTo => {
        this.redeemOtherConfigModel.giftTo = giftTo;
        return this;
    };

    this.setPromoCode = promoCode => {
        this.redeemOtherConfigModel.promoCode = promoCode;
        return this;
    };

    this.setRedeemBy = redeemBy => {
        this.redeemOtherConfigModel.redeemBy = redeemBy;
        return this;
    };

    this.setRedeemMedia = redeemMedia => {
        this.redeemOtherConfigModel.redeemMedia = redeemMedia;
        return this;
    };

    this.setReservationJson = reservationJson => {
        this.redeemOtherConfigModel.reservationJson = reservationJson;
        return this;
    };

    this.setSpBaht = spBaht => {
        this.redeemOtherConfigModel.spBaht = spBaht;
        return this;
    };

    this.setSpPoint = spPoint => {
        this.redeemOtherConfigModel.spPoint = spPoint;
        return this;
    };

    this.setWalletCard = walletCard => {
        this.redeemOtherConfigModel.walletCard = walletCard;
        return this;
    };
}

export function RedeemShippingAddressConfigBuilder () {
    this.redeemShippingAddressConfigModel = new RedeemShippingAddressConfigModal();

    this.create = () => {
        return this.redeemShippingAddressConfigModel;
    };

    this.setConfigFromDataModel = dict => {
        this.redeemShippingAddressConfigModel.setConfigFromDataModel(dict);
        return this;
    };

    this.setAddressFromDeliveryAddressModel = (deliveryAddressModel) => {
        this.redeemShippingAddressConfigModel.setAddressFromDeliveryAddressModel(deliveryAddressModel);
        return this;
    };

    this.setAddress = address => {
        this.redeemShippingAddressConfigModel.address = address;
        return this;
    };

    this.setAddressKey = addressKey => {
        this.redeemShippingAddressConfigModel.addressKey = addressKey;
        return this;
    };

    this.setContactNumber = contactNumber => {
        this.redeemShippingAddressConfigModel.contactNumber = contactNumber;
        return this;
    };

    this.setEmail = email => {
        this.redeemShippingAddressConfigModel.email = email;
        return this;
    };

    this.setFirstName = firstName => {
        this.redeemShippingAddressConfigModel.firstName = firstName;
        return this;
    };

    this.setLastName = lastName => {
        this.redeemShippingAddressConfigModel.lastName = lastName;
        return this;
    };

    this.setShippingFirstName = shippingFirstName => {
        this.redeemShippingAddressConfigModel.shippingFirstName = shippingFirstName;
        return this;
    };

    this.setShippingLastName = shippingLastName => {
        this.redeemShippingAddressConfigModel.shippingLastName = shippingLastName;
        return this;
    };

    this.setZipcode = zipcode => {
        this.redeemShippingAddressConfigModel.zipcode = zipcode;
        return this;
    };
}
