<template>
  <div>
    <a-row>
      <a-col class="p-1" flex="1 1" :order="1">
        <a-form-item has-feedback label="ขนาด" :name="['userproduct', 'customerproductssize']" :rules="{
          required: true,
          message: 'กรุณากรอก',
        }">
          <a-select v-if="sizeproduct != null" show-search :filter-option="filterOption"
            v-model:value="formState.userproduct.customerproductssize"
            :options="sizeproduct.map((city) => ({ value: city }))" @change="setheartyan()"></a-select>
          <div v-else class="loading_"></div>
        </a-form-item>
      </a-col>
      <a-col class="p-1" flex="1 1" :order="1">
        <a-form-item has-feedback label="หัวใจยันต์" :name="['userproduct', 'heartyanname']" :rules="{
          required: true,
          message: 'กรุณากรอก',
        }">
          <a-select @change="handleChange" v-if="heartyanname != null" show-search :filter-option="filterOption"
            v-model:value="formState.userproduct.heartyanname" :options="
              heartyanname.map((city) => ({
                value: city.heartyan,
                detail: city,
              }))
            "></a-select>
        </a-form-item>
      </a-col>
      <a-col flex="1 1" :order="3">
        <a-form-item has-feedback label="หมายเหตุหัวใจยันต์" :name="['userproduct', 'introductionyan']" :rules="{
          required: false,
          message: 'กรุณากรอก',
        }">
          <!-- formState : {{formState.userproduct.introductionyan}}
          formStateStore :{{formStateStore.userproduct.introductionyan}} -->
          <a-textarea v-model:value="formState.userproduct.introductionyan" @change="OnChangeEmitIntroductionyan()"
            allow-clear />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
  <!-- formStateStore
  <pre>{{formStateStore}}</pre>
  formStates
  <pre>{{formStates}}</pre> -->
</template>
<script>
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import axios from "axios";
import { formStateStore } from "../../../../../../../store/storebackoffice"
// import Baseinput from "../form/Baseinput.vue";
export default defineComponent({
  components: {},
  props: {
    formStates: null,
  },
  emits: ["ProductstypeData"],
  setup(props, { emit }) {
    const gender = ref("1");
    const queyan = ref("1");
    const typebirthday = ref("1");

    const is_paid_delivery = ref("1");

    const agegender = ref("18");
    const formState = reactive(props.formStates);

    // formState = props.formState
    const productData = ["ยันต์เขียนสด", "วัตถุมงคล"];
    // const sights = reactive();
    const sights = {
      ยันต์เขียนสด: [],
      หัวใจยัน: [],
    };
    const sizeproduct = ref(null);
    const heartyanname = ref([]);
    const productdetail = ref(null);
    const yanname = [
      {
        label: "หัวใจยันต์1",
        value: "หัวใจยันต์1",
      },
      {
        label: "หัวใจยันต์2",
        value: "หัวใจยันต์2",
      },
      {
        label: "หัวใจยันต์3",
        value: "หัวใจยันต์3",
      },
      {
        label: "หัวใจยันต์4",
        value: "หัวใจยันต์4",
      },
    ];

    // watch(
    //   () => formState.userproduct.customerproductstype,
    //   () => {
    //     if (formState.userproduct.customerproductstype == "ยันต์เขียนสด") {
    //       queryyan("yan");
    //     } else if (formState.userproduct.customerproductstype == "วัตถุมงคล") {
    //       queryyan("mobile");
    //     }
    //   }
    // );
    onMounted(() => {

      queryyan("yanpot");
    });

    const queryyan = (filename) => {
      console.log("filename name :", filename)
      let path = window.location.host.includes("localhost")
        ? "http://localhost:8090"
        : process.env.VUE_APP_API_BASE_URL;
      return axios.get(path + "/api/getproductjson/" + filename).then((res) => {
        console.log("Yan Select ", res)

        setupselectyan(res);
      });
    };

    const setupselectyan = (res) => {

      console.log("yan 1")
      res.data?.yanpot?.forEach((element) => {
        sights["ยันต์เขียนสด"].push(element["ชื่อสินค้าแสดงลูกค้า"]);
        var objheartyan = {};
        objheartyan.yan_size = element["ชื่อสินค้าแสดงลูกค้า"];
        objheartyan.heartyan = element["หัวใจยันต์"];
        objheartyan.price = element["ราคาขาย"];
        objheartyan.originalprice = element["ต้นทุน"]; 
        objheartyan.productcode = element["รวมรหัส"];
        sights["หัวใจยัน"].push(objheartyan);
      });
      let yan = sights["ยันต์เขียนสด"].filter((c, index) => {
        return sights["ยันต์เขียนสด"].indexOf(c) === index;
      });

      sizeproduct.value = yan;
    };

    const formRef = ref();
    const dynamicValidateForm = reactive({
      sights: [],
      area: undefined,
    });
    watch(
      () => dynamicValidateForm.area,
      () => {
        dynamicValidateForm.sights = [];
      }
    );

    const setheartyan = () => {
      emit("ProductstypeData", formState.userproduct);
      formState.userproduct.heartyanname = "";
      let listheartyanname = [];
      sights["หัวใจยัน"].filter((c) => {
        if (c.yan_size == formState.userproduct.customerproductssize) {
          listheartyanname.push(c);
        }
      });
      heartyanname.value = listheartyanname;
    };
    const removeSight = (item) => {
      let index = dynamicValidateForm.sights.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.sights.splice(index, 1);
      }
    };

    const addSight = () => {
      dynamicValidateForm.sights.push({
        value: undefined,
        price: undefined,
        typebirthday: "1",
        id: Date.now(),
      });
    };

    const onFinish = (values) => {
      console.log("formState :", formState);
      emit("update:value", formState);
      emit("closemodal", false);
      formItemContext.onFieldChange();
      console.log("Received values of form:", values);
      console.log("dynamicValidateForm:", dynamicValidateForm);
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const isdisabled = (sights) => {
      if (sights.typebirthday == 1) {
        delete sights.birthday;
        delete sights.age;
        return true;
      } else {
        sights.age = calculateAge(sights);
      }
    };

    const calculateAge = (sights) => {
      var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        return 0;
      }
    };
    const formItemContext = Form.useInjectFormItemContext();
    const fromchange = () => {
      console.log("formState :", formState);
      emit("update:value", formState);
      formItemContext.onFieldChange();
    };
    const OnChangeEmit = () => {
      emit("ProductstypeData", formState.userproduct);
    };
    const OnChangeEmitIntroductionyan = () => {
      emit("ProductstypeData", formState.userproduct);
    };
    const handleChange = (value) => {
      console.log("selected :: ", value)
      heartyanname.value.forEach((element) => {
        console.log("heartyan : " + element.heartyan + " value:" + value)
        if (element.heartyan == value) {
          formState.userproduct.productdetail = element;
        }
      });
    };
    const filterOption = (input, option) => {
       
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filterOption,
      OnChangeEmit,
      OnChangeEmitIntroductionyan,
      locale,
      formRef,
      dynamicValidateForm,
      onFinish,
      removeSight,
      addSight,
      sights,
      gender,
      agegender,
      queyan,
      is_paid_delivery,
      formState,
      fromchange,
      yanname,
      typebirthday,
      isdisabled,
      calculateAge,
      onFinishFailed,
      productData,
      sizeproduct,
      heartyanname,
      setheartyan,
      productdetail,
      handleChange,
      formStateStore
    };
  },
});
</script>