import ConfigManager from "@/config/ConfigManager.js";
import BzbsApi from '@/core/Http/BzbsApi.js';
import { ProvinceModel, DistrictModel, SubDistrictModel} from '../model/AddressModel.js';
import { map } from 'rxjs/operators';

/**
 * @method apiGetProvinceFilterByZipcode
 * @method apiGetProvince
 */
export default {
    /**
     * @param {number} zipcode
     */
    apiGetProvinceFilterByZipcode: function(zipcode) {
        var params = {
            zip_code: zipcode,
        };

        const url = ConfigManager.getApiUrl() + '/main/postcode';
        return BzbsApi.connectBzbsGet(url, null, params)
            .pipe(map((response) => {
                var data = response.data;
                var listProvince = [];
                var listDistrict = [];
                var listSubDistrict = [];

                for (var i = 0; i < data.length; i++) {
                    // Add List Province
                    var prov = new ProvinceModel(data[i]);
                    var isDuplicateProvince = false;
                    for (var j = 0; j < listProvince.length; j++) {
                        if (listProvince[j].provinceNameEN == prov.provinceNameEN) {
                            isDuplicateProvince = true;
                            break;
                        }
                    }

                    if (!isDuplicateProvince) {
                        listProvince.push(prov);
                    }

                    // Add List District
                    var dis = new DistrictModel(data[i]);
                    var isDuplicateDistrict = false;
                    for (var k = 0; k < listDistrict.length; k++) {
                        if (listDistrict[k].districtNameEN == dis.districtNameEN) {
                            isDuplicateDistrict = true;
                            break;
                        }
                    }

                    if (!isDuplicateDistrict) {
                        listDistrict.push(dis);
                    }

                    // Add List SubDistrict
                    var subDis = new SubDistrictModel(data[i]);
                    var isDuplicateSubDistrict = false;
                    for (var l = 0; l < listSubDistrict.length; l++) {
                        if (listSubDistrict[l].subdistrictNameEN == subDis.subdistrictNameEN) {
                            isDuplicateSubDistrict = true;
                            break;
                        }
                    }

                    if (!isDuplicateSubDistrict) {
                        listSubDistrict.push(subDis);
                    }
                }

                if (listProvince.length > 0) {
                    var allAddress = {
                        listProvince: listProvince,
                        listDistrict: listDistrict,
                        listSubDistrict: listSubDistrict,
                    };
                    return allAddress;
                } else {
                    return null; // not found
                }
            }));
    },

    apiGetProvince: function() {
        const url = ConfigManager.getApiUrl() + '/main/province';
        return BzbsApi.connectBzbsGet(url).pipe(map((res) => {
            var data = res.data;
            var provinceList = [];
            for (var i of data) {
                provinceList.push(new ProvinceModel(i));
            }
            return provinceList;
        }));
    },
}
