<template>
    <!-- <pre>{{ dataSources }}</pre> -->

    <div class="p-3">
        <!-- <pre>{{ summary }}</pre> -->
        <a-row>
            <a-col
                ><span class="p-3"> วันที่ (ลงระบบ)</span>
                <a-space>
                    <a-range-picker v-model:value="daterang" />

                    จากลำดับ
                    <a-input-number v-model:value="orderfrom" />
                    ถึง
                    <a-input-number v-model:value="orderto" />

                    <a-button class="ml-4 mr-4" @click="getinfo"
                        >ค้นหา</a-button
                    >
                    <a-button class="ml-2 mr-4" @click="getreport"
                        >Export Report</a-button
                    >
                    <a-radio-group
                        v-model:value="checkedfilter"
                        name="radioGroup"
                    >
                        <a-radio value="1">ยันต์เขียนสด</a-radio>
                        <a-radio value="2">ยันต์ด่วน (VIP)</a-radio>
                        <a-radio value="3">ยันต์เพิ่มขนาด</a-radio>
                        <a-radio value="4">วัตถุมงคล</a-radio>
                    </a-radio-group>
                    <!-- <a-checkbox v-model:checked="checkedVIP"
                        >ยันต์ด่วน (VIP)</a-checkbox
                    >
                    <a-checkbox v-model:checked="checkedChangeSize"
                        >ยันต์เพิ่มขนาด</a-checkbox
                    >
                    <a-checkbox v-model:checked="checkedNAW"
                        >วัตถุมงคล</a-checkbox
                    > -->

                    <a-button type="primary" v-show="showloading" loading
                        >Loading</a-button
                    >
                </a-space>
            </a-col>
            <!-- <a-col class="ml-4 mr-4"> <a-input-group compact>
                    <a-form-item style="width: calc(100% - 60%)">
                        <a-input-search v-model:value="scanpickup" style="width: 400px;" placeholder="สแกนชำระแล้ว Barcode"
                            enter-button="ยืนยัน" size="large" @search="onEnter" />
                    </a-form-item>
                </a-input-group></a-col> -->
        </a-row>
    </div>

    <!-- <pre>{{dataSources}}</pre> -->
    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>

        <div v-if="resultSummaries.length > 0" class="card p-4">
            <u>
                <div v-if="checkedVIP">ยันต์ด่วน</div>
                <div v-else-if="checkedNAW">วัตถุมงคล</div>
                <div v-else-if="checkedChangeSize">ยันต์เพิ่มขนาด</div>
                <div v-else>ยันต์เขียนสด</div>
            </u>
            <ul>
                <li
                    class="p-2"
                    v-for="(item, index) of initsummay(resultSummaries)"
                    :key="index"
                >
                    <span class="pr-4">
                        <a-avatar :size="'small'">
                            <template #icon
                                ><UserOutlined
                            /></template> </a-avatar
                    ></span>
                    <strong>{{ index }} </strong>
                    <u class="pl-4"
                        >จำนวน
                        <span class="pr-2 pl-2">
                            <a-badge
                                :count="item"
                                :number-style="{ backgroundColor: '#52c41a' }"
                            />
                        </span>
                        ชิ้น</u
                    >
                    <a-progress :percent="100" :steps="item" />
                </li>
            </ul>
        </div>
        <div v-else-if="resultSummaries.length == 0" class="card p-4">
            <div v-if="checkedVIP">ยันต์ด่วน</div>
            <div v-else-if="checkedNAW">วัตถุมงคล</div>
            <div v-else-if="checkedChangeSize">ยันต์เพิ่มขนาด</div>
            <div v-else>ยันต์เขียนสด</div>
            <div class="p-4">
                ไม่พบข้อมูล
            </div>
        </div>

        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-table
                size="middle"
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                :scroll="{ x: 0 }"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'order'">
                        <div class="pl-3">
                            {{ index + 1 }}
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <pre>{{ record.ProductSellOrder }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'contactname'">
                        <pre
                            >{{ record.CustomerOrder
                            }}{{ record.ContactName }}</pre
                        >
                    </template>

                    <template v-if="column.dataIndex === 'commission'">
                        <pre>{{
                            JSON.parse(record.UserCommission)?.toString()
                        }}</pre>
                    </template>
                    <template v-if="column.dataIndex === 'Productdetail'">
                        <font-awesome-icon
                            icon="fa-solid fa-user"
                            class="pr-2"
                        />
                        {{
                            record.CustomerProductsType +
                                record.CustomerProductsSize +
                                record.HeartYanName
                        }}
                        <br />
                    </template>
                    <template v-if="column.dataIndex === 'SystemYanDate'">
                        {{ formatdate(record.SystemYanDate) }}
                    </template>
                    <template v-if="column.dataIndex === 'Checking'">
                        <div v-if="!record.Is_System_And_Payment">
                            <a-tag color="#f50">ยังไม่ได้สแกนชำระ</a-tag>
                        </div>
                        <div v-else-if="record.Is_System_And_Payment == 'paid'">
                            <a-tag color="#87d068">ชำระเงินแล้ว</a-tag>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import { usePagination } from "vue-request";

import {
    computed,
    defineComponent,
    onMounted,
    ref,
    reactive,
    toRefs,
    watch
} from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import BarcodeGenerator from "./BarcodeGenerator.vue";
import Utility from "@/helper/Utility";
import _ from "lodash";
import { Modal } from "ant-design-vue";
import { filter } from "rxjs/operators";
import { UserOutlined } from "@ant-design/icons-vue";
const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        TableLoad,
        UserOutlined
    },

    setup() {
        const orderfrom = ref(0);
        const orderto = ref(0);
        const checkedfilter = ref(0);
        const scanpickup = ref("");
        const showloading = ref(false);
        const checkedVIP = ref(false);
        const checkedYAN = ref(false);
        const checkedChangeSize = ref(false);
        const checkedNAW = ref(false);
        const daterang = ref([]);
        const searchInput = ref();
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });

        const resultSummaries = ref([]);
        const columns = [
            {
                title: "ลำดับ",
                dataIndex: "order",
                width: "5%"
            },
            {
                title: "รหัสรายการสินค้า",
                dataIndex: "ProductSellOrder",
                width: "20%"
            },
            {
                title: "ชื่อสินค้า",
                dataIndex: "Productdetail",
                width: "30%"
            },
            {
                title: "รหัสลูกค้า",
                dataIndex: "contactname",
                width: "20%"
            },
            {
                title: "คอมมิชชั่น",
                dataIndex: "commission",
                width: "20%"
            }
            // {
            //     title: "วันที่ลงระบบ",
            //     dataIndex: "SystemYanDate",
            // },
            // {
            //     title: "สถานะ",
            //     dataIndex: "Stauts",
            // },
            // {
            //     title: "สถานะการเงิน",
            //     dataIndex: "Checking",
            // }
        ];
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการคอมมิชชั่น",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            if (moment(date).isValid()) {
                return moment(date)
                    .add(543, "year")
                    .format("L");
            } else {
                return "-";
            }
        };

        const formatdatebd = (date, TypeBirthday) => {
            if (moment(date).isValid()) {
                if (TypeBirthday == "2") {
                    return moment(date)
                        .add(543, "year")
                        .format("L");
                } else if (TypeBirthday == "3") {
                    return moment(date).format("L");
                } else {
                    if (moment(date).isValid()) {
                        return moment(date)
                            .add(543, "year")
                            .format("L");
                    } else {
                        return "-";
                    }
                }
            } else {
                return "-";
            }
        };

        watch(
            () => checkedfilter.value,
            () => {
                checkedYAN.value = false;
                checkedVIP.value = false;
                checkedChangeSize.value = false;
                checkedNAW.value = false;
                if (checkedfilter.value == "1") {
                    checkedYAN.value = true;
                } else if (checkedfilter.value == "2") {
                    checkedVIP.value = true;
                } else if (checkedfilter.value == "3") {
                    checkedChangeSize.value = true;
                } else if (checkedfilter.value == "4") {
                    checkedNAW.value = true;
                }

                querycustomer();
            }
        );
        // watch(
        //     () => checkedVIP.value,
        //     () => {
        //         querycustomer();
        //     }
        // );
        // watch(
        //     () => checkedChangeSize.value,
        //     () => {
        //         querycustomer();
        //     }
        // );
        // watch(
        //     () => checkedNAW.value,
        //     () => {
        //         querycustomer();
        //     }
        // );

        const querycustomer = () => {
            dataSources.value = [];
            resultSummaries.value = [];
            const { startDate, endDate } = getRangeForCurrentMonth();
            console.log("daterang : ", daterang.value);
            var startdate = setformat(daterang.value[0] || startDate);
            var enddate = setformat(daterang.value[1] || endDate);
            console.log("startdate : ", startdate);
            console.log("enddate : ", enddate);
            var isshowvip = checkedVIP.value;
            var isshowchangesize = checkedChangeSize.value;
            var isshownaw = checkedNAW.value;
             

            return BzbsUser.getreportcommission(
                startdate,
                enddate,
                isshowvip,
                isshowchangesize,
                isshownaw
            )
                .then(res => {
                    dataSources.value = [];
                    dataSources.value = res.data.filter(
                        item => item.Is_System_And_Payment == null
                    );

                    if (orderfrom.value && orderto.value) {
                        dataSources.value = dataSources.value.filter(
                            item =>
                                item.OrderTransaction &&
                                parseInt(item.OrderTransaction) >=
                                    orderfrom.value &&
                                parseInt(item.OrderTransaction) <= orderto.value
                        );
                    }

                    dataSources.value.forEach(element => {
                        initvaluesummary(element.UserCommission);
                    });

                    initsummay();
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const getRangeForCurrentMonth = () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();

            // Get the first day of the current month
            const firstDayOfMonth = new Date(year, month, 1);

            // Get the last day of the current month
            const lastDayOfMonth = new Date(year, month + 1, 0);

            return {
                startDate: firstDayOfMonth,
                endDate: lastDayOfMonth
            };
        };
        const setformat = datevalue => {
            const currentDate = new Date(datevalue);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because it's zero-based
            const day = String(currentDate.getDate()).padStart(2, "0");

            return `${year}-${month}-${day}`;
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };
        const state = reactive({
            searchText: "",
            searchedColumn: "",
            selectedRowKeys: [],
            loading: false
        });
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };

        const getinfo = () => {
            querycustomer();
        };

        const getreport = async () => {
            showloading.value = true;
            await querygetreport();
        };

        const initsummay = () => {
            var summary = {};
             
            for (const obj of resultSummaries.value) {
                for (const key in obj) {
                    summary[key] = (summary[key] || 0) + obj[key];
                }
            }
            return summary;
        };

        const querygetreport = async () => {
             
            var listreport = [];
            var totalprice = 0;
            for (const element of dataSources.value) {
                var price_original_cal_system = await BzbsUser.apigetcalpricereportchangesize(
                    element.OrderTransaction
                );

                if (
                    !element.Is_System_And_Payment &&
                    element.Is_System_And_Payment != "paid"
                ) {
                    var item = {};
                    var desc = "";

                    if (element.Category_1.includes("VIP")) {
                        element.Category_2 = element.HeartYanName;
                    }

                    desc =
                        element.Is_System_And_Payment == "paid"
                            ? "ชำระแล้ว"
                            : "ยังไม่ได้สแกนชำระ";
                    if (element.ProductStatus == "cancel") {
                        desc = "ยกเลิกสินค้า";
                    } else if (element.ProductStatus == "canceltrack") {
                        desc = "*ยกเลิกแทรค";
                    }

                    var listbd = [];
                    if (element.Birthday) {
                        JSON.parse(element.Birthday)?.map(item => {
                            if (element.ProductKey == item.ProductKey) {
                                listbd.push(
                                    formatdatebd(
                                        item.Birthday,
                                        item.TypeBirthday
                                    ) + getshotday(item.BirthdayName)
                                );
                            }
                        });
                    }
                    (item.name =
                        element.CustomerProductsType +
                        " " +
                        element.Category_2 +
                        " " +
                        element.Category_3),
                        (item.birthday = listbd),
                        (item.yancomingdate = formatdate(
                            element.YanComingDate
                        )),
                        (item.systemyandate = formatdate(
                            element.SystemYanDate
                        )),
                        (item.ordertransaction = element.OrderTransaction),
                        (item.customerproductssizeanddesc =
                            element.HeartYanName),
                        (item.customerproductssize = element.Category_2),
                        (item.productprice = element.ProductPrice),
                        (item.originalprice = element.OriginalPrice),
                        (item.productcode = element.ProductCode),
                        (item.productsellorder = element.ProductSellOrder),
                        (item.customerorder = element.CustomerOrder),
                        (item.barcode = element.ProductCode),
                        (item.contactname = element.ContactName || ""),
                        (item.statusreport = desc),
                        (item.usercommission = element.UserCommission);

                    if (
                        price_original_cal_system?.data
                            ?.list_change_size_history_detail.length
                    ) {
                        item.originalprice =
                            element.OriginalPrice -
                            price_original_cal_system?.data?.result;
                        item.list_change_size_history_detail = price_original_cal_system?.data?.list_change_size_history_detail?.toString();
                        item.queyandate = formatdate(element.QueYanDate);
                        item.trackyan = element.TrackYan;
                        item.orderdate = formatdate(element.OrderDate);
                    }
                    totalprice = totalprice + item.originalprice;

                    listreport.push(item);
                }
            }

            // let orderInfo = {
            //     products: listreport,
            // };
            var exportreport = {
                orderInfo: listreport,
                totalprice: totalprice,
                ispaid: false
            };

            console.log(exportreport);

            if (checkedChangeSize.value == true) {
                BzbsUser.apireportgetyanchangesizecommission(exportreport)
                    .then(response => {
                        showloading.value = false;
                        let blob = new Blob([response.data], {
                            type: "application/pdf"
                        });
                        let link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "ยันต์เพิ่มขนาดคอมมิชชั่น.pdf";
                        link.click();
                    })
                    .catch(error => {
                        showloading.value = false;
                        console.log(error);
                    });
            } else {
                BzbsUser.getreportpdfcommission(exportreport)
                    .then(response => {
                        showloading.value = false;
                        let blob = new Blob([response.data], {
                            type: "application/pdf"
                        });
                        let link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "รายงานยันต์คอมมิชชั่น.pdf";
                        link.click();
                    })
                    .catch(error => {
                        showloading.value = false;
                        console.log(error);
                    });
            }
        };

        const initvaluesummary = usercommission => {
            if (usercommission && usercommission != "[]") {
                 
                const result = getSummary(JSON.parse(usercommission));
                resultSummaries.value.push(result);
            }
        };

        const getSummary = arr1 => {
            const summary = {};

            for (const item of arr1) {
                summary[item] = (summary[item] || 0) + 1;
            }

            return summary;
        };

        const getshotday = day => {
            const exp = [
                "จันทร์",
                "อังคาร",
                "พุธ",
                "พฤหัสบดี",
                "ศุกร์",
                "เสาร์",
                "อาทิตย์"
            ];
            const daysOfWeekAbbreviated = ["จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"];

            const searchText = day;

            let index = -1; // Initialize the index to -1 (not found)

            for (let i = 0; i < exp.length; i++) {
                if (searchText.includes(exp[i])) {
                    index = i; // Set the index when a partial match is found
                    break; // Exit the loop once a match is found
                }
            }

            if (index !== -1) {
                return `(${daysOfWeekAbbreviated[index]})`;
            } else {
                return "";
            }
        };
        const onEnter = () => {
            showConfirm("ต้องการสแกนจ่ายแล้ว");
        };

        const truncateString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            } else {
                return str.substring(0, maxLength) + "...";
            }
        };
        const showConfirm = title => {
            Modal.confirm({
                title: title,
                onOk() {
                    apiupdatereportstatus();
                },
                onCancel() {
                    console.log("Cancel");
                },
                class: "test"
            });
        };

        const apiupdatereportstatus = () => {
            var param = {
                ProductCode: scanpickup.value,
                StatusReport: "paid"
            };

            BzbsUser.updatestatusreportissystemandpayment(param)
                .then(response => {
                    success();
                    querycustomer();
                })
                .catch(error => {
                    showloading.value = false;
                    console.log(error);
                });
        };
        const success = () => {
            Modal.success({
                title: "สำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };

        const hasSelected = computed(() => state.selectedRowKeys.length > 0);
        const start = () => {
            state.loading = true;
            // ajax request after empty completing
            setTimeout(() => {
                state.loading = false;
                state.selectedRowKeys = [];
                updateSystemyandate();
            }, 1000);
        };

        const listselected = ref([]);
        const onSelectChange = selectedRowKeys => {
            console.log("selectedRowKeys changed: ", selectedRowKeys);
            state.selectedRowKeys = selectedRowKeys;
            listselected.value = selectedRowKeys;
        };

        const updateSystemyandate = async () => {
            var list = [];

            console.log("listselected", listselected.value);
            listselected.value.forEach(element => {
                var found = dataSources.value.filter(
                    item => item.key == element
                );
                list.push(found);
            });

            console.log("LIST : ", list);
            for await (let item of list) {
                // var CheckMessageParam = [{
                //     tab: "1",
                //     checking: 'checked',
                //     message: 'ผ่าน(oneclick)',
                //     updatedate: Date.now().toString(),
                // }]
                for await (let detail of item) {
                    // var param = {
                    //     "Tab": "1",
                    //     "ProductSellOrder": detail.ProductSellOrder,
                    //     "CustomerOrder": detail.CustomerOrder,
                    //     "ProductKey": detail.ProductKey,
                    //     "SystemCheckTabActive": JSON.stringify(CheckMessageParam)
                    // }
                    // await BzbsUser.updatesystemchecktabactive(param)
                    //     .then(res => {

                    //     })
                    //     .catch(error => {
                    //         console.log(error);
                    //     });

                    var param = {
                        ProductCode: detail.ProductCode,
                        StatusReport: "paid"
                    };

                    BzbsUser.updatestatusreportissystemandpayment(param)
                        .then(response => {
                            // success()
                            querycustomer();
                        })
                        .catch(error => {
                            showloading.value = false;
                            console.log(error);
                        });
                }
            }
            await querycustomer();
            // console.log("param : ", param)
        };
        return {
            start,
            onSelectChange,
            scanpickup,
            onEnter,
            getinfo,
            handleSearch,
            handleReset,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            searchInput,
            ...toRefs(state),
            daterang,
            getreport,
            showloading,
            scanpickup,
            checkedVIP,
            checkedYAN,
            checkedChangeSize,
            hasSelected,
            orderfrom,
            orderto,
            resultSummaries,
            initsummay,
            checkedNAW,
            checkedfilter
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
