<template>
    <div class="p-4 card relative">
        <template v-if="title != null">
            <div class="text-gray-500 text-lg font-bold">{{ title }}</div>
        </template>
        <div :style="{ height: heightComputed, maxHeight: heightComputed }">

            <div id="chart">
                <apexchart type="treemap" height="450" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</template>
<script>
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { forEach } from "lodash";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    },
    created() {
        this.querycustomer()

    },
    data: function () {
        return {
            heightComputed: '450px',
            series: [
                {
                    name: 'ไทย',
                    data: []
                },
                {
                    name: 'ต่างประเทศ',
                    data: []
                }
            ],
            chartOptions: {
                legend: {
                    show: false
                },
                chart: {
                    height: 350,
                    type: 'treemap'
                },
                title: {
                    text: 'ลูกค้าในระบบหลังบ้าน',
                    align: 'center'
                },
                plotOptions: {
                    treemap: {
                        distributed: true,
                        enableShades: false
                    }
                }
            }

        };
    },
    methods: {
        querycustomer() {
            return BzbsUser.apiGetCustomer(null)
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var dataSources = this.groupBy(res.data[0], "SelectCountry");
                 
                    var thaidata = []
                    var otherdata = []
                    dataSources.forEach(element => {
                        element.forEach(item => {

                            if (item.SelectCountry == 'Thailand' || item.SelectCountry == 'TH') {

                                if (item.ProvinceName) {
                                    var param = {
                                        x: item.ProvinceName,
                                        y: 1
                                    }
                                    thaidata.push(param)
                                }


                            } else {
                                var param = {
                                    x: item.SelectCountry,
                                    y: 1
                                }
                                otherdata.push(param)
                            }
                        });
                    });


                    var initthai = []
                    var initother = []

                    thaidata = this.groupBy(thaidata, "x");
                    otherdata = this.groupBy(otherdata, "x");

                    thaidata.forEach(element => {
                        var param = {}
                        param.x = element[0].x
                        param.y = element.length
                        initthai.push(param)
                    });

                    otherdata.forEach(element => {
                        var param = {}
                        param.x = element[0].x
                        param.y = element.length
                        initother.push(param)
                    });


                    this.series = [
                        {
                            name: 'สถานที่',
                            data: [...initthai, ...initother]
                        },
                    ]

                    var categories = []
                    var brfore_group = []
                    dataSources.forEach(item => {
                        item.forEach(sub_item => {
                            brfore_group.push({ date: sub_item.OrderDate, product_type: sub_item.CustomerProductsType })
                            categories.push(sub_item.OrderDate)
                        });

                    });


                    var uniqueArray = categories.filter(function (item, pos, self) {
                        return self.indexOf(item) == pos;
                    })

                    uniqueArray = uniqueArray.sort(function (a, b) {
                        return new Date(a) - new Date(b);
                    });

                    brfore_group = brfore_group.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });


                    uniqueArray = uniqueArray.map(element => {
                        return this.formatdate(element)
                    });




                    console.log("TrendGraphLine :: ", dataSources);
                    console.log("categories :: ", uniqueArray);



                    const all_datagrupby_date = brfore_group.reduce((x, y) => {
                        (x[y.date] = x[y.date] || []).push(y);
                        return x;
                    }, {});

                    // this.series[0].data.map(() => {
                    //     return Math.floor(Math.random() * (max - min + 1)) + min;
                    // });
                    // ["ยันต์เขียนสด", "ยันต์ปลด/คิวด่วน", "วัตถุมงคล", "เคส/ภาพพื้นหลัง"]
                    var temp = {
                        one: [],
                        two: [],
                        three: [],
                        four: [],
                    }
                    for (const property in all_datagrupby_date) {
                        console.log(`${property}: ${all_datagrupby_date[property]}`);


                        temp.one.push(all_datagrupby_date[property].filter(item => item.product_type == 'ยันต์เขียนสด')?.length)
                        temp.two.push(all_datagrupby_date[property].filter(item => item.product_type == 'ยันต์ปลด/คิวด่วน')?.length)
                        temp.three.push(all_datagrupby_date[property].filter(item => item.product_type == 'วัตถุมงคล')?.length)
                        temp.four.push(all_datagrupby_date[property].filter(item => item.product_type == 'เคส/ภาพพื้นหลัง')?.length)
                        //temp.series[3].data.push(all_datagrupby_date[property].filter(item => item.product_type == 'เคส/ภาพพื้นหลัง'))


                    }

                    // this.series_Line = [
                    //     {
                    //         name: "ยันต์เขียนสด",
                    //         data: temp.one
                    //     },
                    //     {
                    //         name: "ยันต์ปลด/คิวด่วน",
                    //         data: temp.two
                    //     },
                    //     {
                    //         name: "วัตถุมงคล",
                    //         data: temp.three
                    //     },
                    //     {
                    //         name: "เคส/ภาพพื้นหลัง",
                    //         data: temp.four
                    //     }
                    // ]
                    // this.chartOptions_Line = {
                    //     xaxis: {
                    //         categories: uniqueArray
                    //     }
                    // };

                })
                .catch(error => {
                    console.log(error);
                });
        },
        groupBy(collection, property) {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },
        formatdate(date) {
            return moment(date)
                .add(543, "year")
                .format("ll");
        }

    }
};
</script>

<style>

</style>
