
import Util from '@/helper/Utility.js';

export function RedeemShippingAddressConfigModal() {
    this.address = '';
    this.addressKey = '';
    this.contactNumber = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.shippingFirstName = '';
    this.shippingLastName = '';
    this.zipcode = '';

    this.getParams = () => {
        var params = {
            address: this.address,
            address_key: this.addressKey,
            contact_number: this.contactNumber,
            email: this.email,
            firstname: this.firstName,
            lastname: this.lastName,
            shippingFirstName: this.shippingFirstName,
            shippingLastName: this.shippingLastName,
            zipcode: this.zipcode
        };

        return Util.cleanObjectEmptyStr(params);
    };

    this.setConfigFromDataModel = dict => {
        this.address = dict.address || '';
        this.addressKey = dict.addressKey || '';
        this.contactNumber = dict.contactNumber || '';
        this.email = dict.email || '';
        this.firstName = dict.firstName || '';
        this.lastName = dict.lastName || '';
        this.shippingFirstName = dict.shippingFirstName || dict.firstName || '';
        this.shippingLastName = dict.shippingLastName || dict.lastName || '';
        this.zipcode = dict.zipcode || '';
    };

    this.setAddressFromDeliveryAddressModel = (deliveryAddressModel) => {
        this.address = deliveryAddressModel.address;
        this.addressKey = deliveryAddressModel.rowKey;
        this.contactNumber = deliveryAddressModel.contactNumber;
        this.email = deliveryAddressModel.email;
        this.firstName = deliveryAddressModel.firstname;
        this.lastName = deliveryAddressModel.lastname;
        this.shippingFirstName = deliveryAddressModel.firstname;
        this.shippingLastName = deliveryAddressModel.lastname;
        this.zipcode = deliveryAddressModel.zipcode;
    };
}

export function RedeemInfoConfigModel() {
    this.info1 = '';
    this.info2 = '';
    this.info3 = '';
    this.info4 = '';
    this.info5 = '';

    this.getParams = function () {
        var params = {
            info1: this.info1,
            info2: this.info2,
            info3: this.info3,
            info4: this.info4,
            info5: this.info5
        };

        return Util.cleanObjectEmptyStr(params);
    };
}

export function RedeemLocationConfigModel() {
    this.center = '';
    this.latitude = '';
    this.locationId = '';
    this.longitude = '';

    this.getParams = () => {
        var params = {
            center: this.center,
            latitude: this.latitude,
            location_id: this.locationId,
            longitude: this.longitude
        };

        return Util.cleanObjectEmptyStr(params);
    };
}

export function RedeemOtherConfigModel() {
    this.giftTo = '';
    this.isCache = true;
    this.promoCode = '';
    this.redeemBy = '';
    this.redeemMedia = '';
    this.reservationJson = '';
    this.spBaht = '';
    this.spPoints = '';
    this.tranId = '';
    this.walletCard = '';

    this.getParams = () => {
        var params = {
            giftto: this.giftTo,
            promocode: this.promoCode,
            redeem_by: this.redeemBy,
            redeem_media: this.redeemMedia,
            reservationjson: this.reservationJson,
            sp_baht: this.spBaht,
            sp_points: this.spPoints,
            tranId: this.tranId,
            walletcard: this.walletcard
        }

        if (this.isCache == false) params.cache = this.isCache;

        return Util.cleanObjectEmptyStr(params);
    }
}
