<template>
    <div>
        <a-modal v-model:visible="visiblemodal" title="ยืนยัน" @ok="handleOk" @cancel="close">
            <a-textarea v-model:value="token" placeholder="Token" :auto-size="{ minRows: 2, maxRows: 5 }" />
            <div class="pt-4">
                <a-alert v-if="errormessage" :message="errormessage" type="error" />
            </div>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    props: {
        visible: null,
        keygen: null
    },
    setup(props, { emit }) {
      
        const token = ref("");
        const errormessage = ref("");
        const visiblemodal = ref(props.visible);
        const keyvalue = ref(props.keygen);
    

        const close = e => {
            console.log(e)
            visiblemodal.value = false;
            emit('close', false)

        }
        const handleOk = e => {
            console.log(e)
            apicheckemail()

        };
        const apicheckemail = async () => {
            const param = {
                token: token.value.trim(),
                key: keyvalue.value
            };

            await BzbsUser.apicheckemail(param)
                .then(res => {
                    console.log(res)
               
                 
                    if (res.data.status == 200) {
                        emit('checksuccess', true)
                        visiblemodal.value = false;
                    }
                    else {
                        errormessage.value = res.data.message
                        setTimeout(() => {
                            errormessage.value = ""
                        }, 3000);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            visiblemodal,
            handleOk,
            close,
            token,
            apicheckemail,
            errormessage
        };
    },
});
</script>