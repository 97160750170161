<template>
    <div>
        <a-button type="primary" @click="showModal">
            <template #icon>
                <PlusCircleOutlined />
            </template>สร้างใหม่
        </a-button>

        <a-modal v-model:visible="visible" width="1000px" title="สร้างรหัสลูกค้า" @ok="handleOk" @cancel="handleCancel">
            <template #footer>
                <a-button key="back" @click="handleCancel">กลับ</a-button>
                <a-button key="submit" type="primary" :loading="loading" @click="handleOk">ตกลง</a-button>
            </template>
            <a-form :model="formState" name="basic" autocomplete="off" @finish="onFinish" @finishFailed="onFinishFailed">
                <a-row type="flex">
                    <a-col flex="1 1 200px" :order="1">
                        <!-- <user-outlined /> -->
                        <a-form-item class="pointer-events-none" :name="['user', 'customercode']" label="รหัสลูกค้า"
                            :rules="[{ required: true, message: 'กรุณากรอก' }]">
                            <a-input style="background: #80808038" v-model:value="formState.user.customercode" />
                        </a-form-item>
                    </a-col>
                    <a-col flex="1 1 0px" :order="2">
                        <SearchCustomerVue @customerselected="setcustomerselected($event)" class="ml-4">
                        </SearchCustomerVue>
                    </a-col>
                    <a-col flex="1 1 200px" :order="3">
                        <a-button type="primary" @click="gencode()">
                            <template #icon>
                                <user-add-outlined />
                            </template>
                            ขอรหัสลูกค้าใหม่
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { UserAddOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import SearchCustomerVue from "./SearchCustomer";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
export default defineComponent({
    components: { UserAddOutlined, SearchCustomerVue },
    emits: ["customercode"],
    setup(props, { emit }) {



        const formState = reactive({
            user: {}
        });

        const visible = ref(false);
        const customerDetail = ref(true);
        const visiblesearch = ref(false);

        const setcustomerselected = e => {
            if (e != null) {
                
                formState.user.customercode = e.CustomerCode; 
                customerDetail.value = e;
            }
        };

        const showModal = () => {
            visible.value = true;
        };
        const showpopupsearchmodal = () => {
            visiblesearch.value = true;
        };

        const handleOk = e => {
            debugger
            if (customerDetail.value == true) {
                customerDetail.value = {
                    CustomerCode: formState.user.customercode,
                    isgencode: true
                };
            }
            debugger
            emit("customercode", customerDetail.value);
            console.log(e);
            visible.value = false;
        };
        const handleCancel = e => {
            emit("customercode", "");
            console.log(e);
            visible.value = false;
        };
        const gencode = () => {
            BzbsUser.gencustomercode(null)
                .then(res => {
                    // formState.user.customercode =
                    //   "C-" + moment(new Date()).format("YYYYMMDDHHmmss");
                    if (res.data?.result)
                        formState.user.customercode = res.data?.result
                }) 
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            setcustomerselected,
            showpopupsearchmodal,
            gencode,
            visible,
            showModal,
            handleOk,
            formState,
            handleCancel
        };
    }
});
</script>
