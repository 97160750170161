import { reactive } from "vue";

const state = reactive({
    active: false,
});

const methods = {
    open: function () {
        state.active = true;
    },
    close: function () {
        state.active = false;
    },
};

export default {
    state: state,
    methods: methods,
}
