
<template>
  <div>
    <a-row>
      <a-col class="p-1" flex="1 1" :order="1">
        <a-form-item has-feedback label="สินค้า" :name="['userproduct', 'customerproduct']" :rules="{
          required: true,
          message: 'กรุณากรอก',
        }">
          <a-select v-if="sizeproduct != null" show-search :filter-option="filterOption" v-model:value="formState.userproduct.customerproduct"
            :options="sizeproduct.map((city) => ({ value:city.value ,label:city.label }))" @change="settwo()">
          </a-select>
        </a-form-item>
      </a-col>
      <a-col class="p-1" flex="1 1" :order="1">
        <a-form-item has-feedback label="ชื่อสินค้า" :name="['userproduct', 'heartyanname']" :rules="{
          required: true,
          message: 'กรุณากรอก',
        }">
          <a-select @change="handleChange" show-search :filter-option="filterOption" style="width: 100%"
            v-if="sizename != null" v-model:value="formState.userproduct.sub_customerproduct" :options="
              sizename.map((city) => ({
                value: city.productcode,
                label: city.productname,
              }))
            "></a-select>
          <!-- <pre>{{sizename}}</pre> -->
        </a-form-item>
      </a-col>
      <a-col flex="1 1" :order="3">
        <a-form-item has-feedback label="หมายเหตุ" :name="['userproduct', 'introductionyan']" :rules="{
          required: true,
          message: 'กรุณากรอก',
        }">
          <a-textarea v-model:value="formState.userproduct.introductionproduct" @change="OnChangeEmitIntroductionyan()"
            allow-clear />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch, onMounted } from "vue";
import { Form } from "ant-design-vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import axios from "axios";
// import Baseinput from "../form/Baseinput.vue";
export default defineComponent({
  components: {},
  props: {
    formStates: null,
  },
  emits: ["ProductstypeData"],
  setup(props, { emit }) {

    const gender = ref("1");
    const queyan = ref("1");
    const typebirthday = ref("1");

    const is_paid_delivery = ref("1");

    const agegender = ref("18");
    const formState = reactive(props.formStates);
    formState.userproduct.customerproduct = formState.userproduct.customerproductssize
    formState.userproduct.sub_customerproduct = formState.userproduct.heartyanname


    // formState = props.formState
    const productData = ["ยันต์เขียนสด", "วัตถุมงคล"];
    // const sights = reactive();
    const sights = {
      วัตถุมงคล: [],
      สินค้า: [],
      หมวดหมู่ประเภทย่อย: [],
      ขนาด: [],
    };
    const sizeproduct = ref(null);
    const sizename = ref([]);
    const productdetail = ref(null);
    const yanname = [
      {
        label: "หัวใจยันต์1",
        value: "หัวใจยันต์1",
      },
      {
        label: "หัวใจยันต์2",
        value: "หัวใจยันต์2",
      },
      {
        label: "หัวใจยันต์3",
        value: "หัวใจยันต์3",
      },
      {
        label: "หัวใจยันต์4",
        value: "หัวใจยันต์4",
      },
    ];

    // watch(
    //   () => formState.userproduct.customerproductstype,
    //   () => {
    //     if (formState.userproduct.customerproductstype == "ยันต์เขียนสด") {
    //       queryyan("yan");
    //     } else if (formState.userproduct.customerproductstype == "วัตถุมงคล") {
    //       queryyan("mobile");
    //     }
    //   }
    // );
    onMounted(() => {

      queryyan("caseandwall");
    });

    const queryyan = (filename) => {

      let path = window.location.host.includes("localhost")
        ? "http://localhost:8090"
        : process.env.VUE_APP_API_BASE_URL;
      return axios.get(path + "/api/getproductjson/" + filename).then((res) => {

        setupselect(res);
      });
    };

    // const setupselect = (res) => {
    //   res.data.mobile.forEach((element) => {
    //     sights["สินค้า"].push(element["สินค้า"]);
    //     sights["ประเภท"].push(element["ประเภท"]);
    //     var objheartyan = {};
    //     objheartyan.productname = element["สินค้า"];
    //     objheartyan.productsize = element["รูปแบบ/สี/ไซส์"];
    //     objheartyan.price = element["ราคา(บาท)"];
    //     objheartyan.productcode = element["บาร์โค้ด"];
    //     sights["ขนาด"].push(objheartyan);
    //   });
    //   let productlist = sights["สินค้า"].filter((c, index) => {
    //     return sights["สินค้า"].indexOf(c) === index;
    //   });
    //   sizeproduct.value = productlist;
    // };
    const setupselect = (res) => {
      res.data?.caseandwall?.forEach((element) => {
        sights["วัตถุมงคล"].push({ label: element["วัตถุมงคล"], value: element["รหัสวัตถุมงคล"] });
        sights["สินค้า"].push({ label: element["สินค้า"], value: element["ประเภท"] });
        var objheartyan = {};
        objheartyan.sub_type = element["หมวดหมู่ประเภทย่อย"];
        objheartyan.productname = element["สินค้า"];
        objheartyan.productsize = element["สี/ไซส์"];
        objheartyan.price = element["ราคา(บาท)"];
        objheartyan.originalprice = element["ต้นทุน"]; 
        objheartyan.product_barcode = element["บาร์โค้ด"];
        objheartyan.price_destination = element["เก็บปลายทาง"];
        objheartyan.productcode = element["บาร์โค้ด"];
        objheartyan.code_type = element["รหัสวัตถุมงคล"];
        objheartyan.code_sub_product_type = element["รหัสประเภทย่อย"];
        objheartyan.code_productsize = element["รหัส สี/ไซส์"];
        objheartyan.code_pattern = element["รหัสรูปแบบ"];
        sights["หมวดหมู่ประเภทย่อย"].push(objheartyan);
      });
      let productlist = sights["วัตถุมงคล"].filter((c, index) => {
        return sights["วัตถุมงคล"].indexOf(c) === index;
      });

      productlist = getUniqueListBy(productlist, 'label')
      console.log(productlist)
      sizeproduct.value = productlist;
    };
    const getUniqueListBy = (array, key) => {
      return [...new Map(array.map(item => [item[key], item])).values()]
    }


    const formRef = ref();
    const dynamicValidateForm = reactive({
      sights: [],
      area: undefined,
    });
    watch(
      () => dynamicValidateForm.area,
      () => {
        dynamicValidateForm.sights = [];
      }
    );


    const settwo = () => {
 
      var filter_customerproductssize = sizeproduct.value.filter(item => item.value == formState.userproduct.customerproduct)
      if (filter_customerproductssize.length) {
        formState.userproduct.customerproductssize = filter_customerproductssize[0].label
      }

      formState.userproduct.sub_customerproduct = ""
      sizename.value = []

      emit("ProductstypeData", formState.userproduct);
      formState.userproduct.size = "";
      let listobj = [];
      sights["หมวดหมู่ประเภทย่อย"].filter((c) => {

        if (c.code_type == formState.userproduct.customerproduct) {
          listobj.push(c);
        }
      });
      sizename.value = listobj;
    };
    const sizepattern = ref([]);
    const setthree = () => {
      sizepattern.value = []

      emit("ProductstypeData", formState.userproduct);
      // formState.userproduct.size = "";
      let listobj = [];
      sights["ขนาด"].filter((c) => {

        if (c.code_sub_product_type == formState.userproduct.sub_customerproduct) {
          listobj.push(c);
        }
      });
      sizepattern.value = listobj;
    };
    const removeSight = (item) => {
      let index = dynamicValidateForm.sights.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.sights.splice(index, 1);
      }
    };

    const addSight = () => {
      dynamicValidateForm.sights.push({
        value: undefined,
        price: undefined,
        typebirthday: "1",
        id: Date.now(),
      });
    };

    const onFinish = (values) => {
      console.log("formState :", formState);
      emit("update:value", formState);
      emit("closemodal", false);
      formItemContext.onFieldChange();
      console.log("Received values of form:", values);
      console.log("dynamicValidateForm:", dynamicValidateForm);
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const isdisabled = (sights) => {
      if (sights.typebirthday == 1) {
        delete sights.birthday;
        delete sights.age;
        return true;
      } else {
        sights.age = calculateAge(sights);
      }
    };

    const calculateAge = (sights) => {
      var ageDifMs = Date.now() - new Date(sights.birthday).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      if (Math.abs(ageDate.getUTCFullYear() - 1970)) {
        return Math.abs(ageDate.getUTCFullYear() - 1970);
      } else {
        return 0;
      }
    };
    const formItemContext = Form.useInjectFormItemContext();
    const fromchange = () => {
      console.log("formState :", formState);
      emit("update:value", formState);
      formItemContext.onFieldChange();
    };
    const OnChangeEmit = () => {
      emit("ProductstypeData", formState.userproduct);
    };
    const OnChangeEmitIntroductionyan = () => {
      emit("ProductstypeData", formState.userproduct);
    };
    const handleChange = (value) => {
 
      var filter_heartyanname = sizename.value.filter(item => item.productcode == formState.userproduct.sub_customerproduct)
      if (filter_heartyanname.length) {
        formState.userproduct.heartyanname = filter_heartyanname[0].productname
      }

      sizename.value.forEach((element) => {
        if (element.productcode == value) {
          formState.userproduct.productdetail = element;
        }
      });
    };
    const filterOption = (input, option) => {
       
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      OnChangeEmit,
      OnChangeEmitIntroductionyan,
      locale,
      formRef,
      dynamicValidateForm,
      onFinish,
      removeSight,
      addSight,
      sights,
      gender,
      agegender,
      queyan,
      is_paid_delivery,
      formState,
      fromchange,
      yanname,
      typebirthday,
      isdisabled,
      calculateAge,
      onFinishFailed,
      productData,
      sizeproduct,
      sizename,
      sizepattern,
      settwo,
      setthree,
      productdetail,
      handleChange,
      filterOption
    };
  },
});
</script>