<template>
  <div>
    <a-modal v-model:visible="visible" width="1000px" title="ข้อความ" @ok="handleOk" @cancel="close()">
 
      <QuillEditor theme="snow" toolbar="#custom-toolbar" v-model:content="contentHTML" @change="onEditorChange($event)"
        contentType="html">
        <template #toolbar>
          <div id="custom-toolbar">
            <select class="ql-font">
              <option selected></option>
              <option value="serif">serif</option>
              <option value="monospace">monospace</option>
              <option value="thasadith">Thasadith</option>
              <option value="k2d">K2D</option>
              <option value="charm" class="ql-font-charm">charm</option>
              <option value="kanit" class="ql-font-kanit">kanit</option>
              <option value="prompt" class="ql-font-prompt">prompt</option>
            </select>
            <select class="ql-size">
              <option value="small"></option>
              <option selected></option>
              <option value="large"></option>
              <option value="huge"></option>
            </select>
            <select class="ql-header">
              <option :value="1"></option>
              <option :value="2"></option>
              <option :value="3"></option>
              <option :value="4"></option>
              <option :value="5"></option>
              <option :value="6"></option>
              <option selected></option>
            </select>
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>

            <button class="ql-script" value="sub"></button>
            <button class="ql-script" value="super"></button>
            <select class="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-blockquote"></button>
            <button class="ql-code-block"></button>
            <button class="ql-link"></button>
            <button class="ql-image"></button>

            <select title="Background Color" className="ql-background" defaultValue="rgb(255, 255, 255)">
              <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" />
              <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)" />
              <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)" />
              <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)" />
              <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)" />
              <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)" />
              <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)" />
              <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)" />
              <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)" />
              <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)" />
              <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)" />
              <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)" />
              <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)" />
              <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)" />
              <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)" />
              <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)" />
              <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)" />
              <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)" />
              <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)" />
              <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)" />
              <option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)" />
              <option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)" />
              <option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)" />
              <option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)" />
              <option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)" />
              <option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)" />
              <option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)" />
              <option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)" />
              <option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)" />
              <option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)" />
              <option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)" />
              <option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)" />
              <option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)" />
              <option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)" />
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)" />
            </select>
            <select title="Color" className="ql-color" defaultValue="rgb(255, 255, 255)"> 
              <option value="rgb(178, 143, 73)" label="rgb(178, 143, 73)" />
              <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" />
              <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)" />
              <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)" />
              <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)" />
              <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)" />
              <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)" />
              <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)" />
              <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)" />
              <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)" />
              <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)" />
              <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)" />
              <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)" />
              <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)" />
              <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)" />
              <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)" />
              <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)" />
              <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)" />
              <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)" />
              <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)" />
              <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)" />
              <option value="rgb(194, 133, 255)" label="rgb(194, 133, 255)" />
              <option value="rgb(136, 136, 136)" label="rgb(136, 136, 136)" />
              <option value="rgb(161, 0, 0)" label="rgb(161, 0, 0)" />
              <option value="rgb(178, 107, 0)" label="rgb(178, 107, 0)" />
              <option value="rgb(178, 178, 0)" label="rgb(178, 178, 0)" />
              <option value="rgb(0, 97, 0)" label="rgb(0, 97, 0)" />
              <option value="rgb(0, 71, 178)" label="rgb(0, 71, 178)" />
              <option value="rgb(107, 36, 178)" label="rgb(107, 36, 178)" />
              <option value="rgb(68, 68, 68)" label="rgb(68, 68, 68)" />
              <option value="rgb(92, 0, 0)" label="rgb(92, 0, 0)" />
              <option value="rgb(102, 61, 0)" label="rgb(102, 61, 0)" />
              <option value="rgb(102, 102, 0)" label="rgb(102, 102, 0)" />
              <option value="rgb(0, 55, 0)" label="rgb(0, 55, 0)" />
              <option value="rgb(0, 41, 102)" label="rgb(0, 41, 102)" />
              <option value="rgb(61, 20, 102)" label="rgb(61, 20, 102)" />
            </select>
          </div>
        </template>
      </QuillEditor>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { onEdit, value, componentcount } from '@/store/store'
import Quill from 'quill'
let Font = Quill.import('formats/font');
Font.whitelist = ['serif', 'monospace', 'thasadith', 'k2d', 'charm', 'kanit', 'prompt'];
Quill.register(Font, true);
export default defineComponent({
  components: {
    QuillEditor
  },
  setup(props, { emit }) {
    try {

      const visible = ref(false);
      const isshow = ref(false);
      const contentHTML = ref("")
      if (onEdit.value.isshowedit) {
        console.log(onEdit.value)
        contentHTML.value = onEdit.value.editobj.detail.value
        componentcount.value++
        visible.value = true;
      }
      else {
        visible.value = true;
      }
      const showModal = () => {
        visible.value = true;

      };

      const close = () => {
        componentcount.value = 0
        visible.value = false
        value.value = ["เลือก"]
        onEdit.value.isshowedit = false
      }

      const onEditorChange = (e) => {
        console.log(e)
      }

      watch(contentHTML, (newX) => {

        contentHTML.value = newX
      })
      const handleOk = e => {

        // visible.value = false
        console.log("contentHTML.value :", contentHTML.value)
        emit('onmessage', { value: contentHTML.value, type: 'message' })
        console.log(e);
        visible.value = false;
        onEdit.value.isshowedit = false
        componentcount.value = 0
      };
      return {
        close,
        visible,
        showModal,
        handleOk,
        contentHTML, componentcount,
        onEditorChange,
        isshow
      };

    } catch (error) {

      console.log(error)

    }

  },

});
</script>

<style lang="scss">
.ql-editor {
  min-height: 300px;
}
</style>