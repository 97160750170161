<template>
    <div class="page">
        <TopBar>
            <template v-slot:toolbar>
            </template>
        </TopBar>
        <div class="pb-10 mx-6">
            <div v-if="false">
                <div class="flex-container">
                    <div class="group-left">
                        <div class="flex-group-container">
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="1" v-bind="cards.dailysales"></dashboard-data-card>
                            </div>
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="2" v-bind="cards.monthlysales"></dashboard-data-card>
                            </div>
                            <div class="item__dashboard">
                                <dashboard-data-card :colindex="3" v-bind="cards.yearsales"></dashboard-data-card>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-container">
                    <div class="items__dashboard">
                        <trend-graph-line :seriesData="dashboardSummaryStates.earnData" title="ยอดขายรวม"
                            :isTimeSeries="true"></trend-graph-line>
                    </div>
                    <div class="items__dashboard">
                        <trend-graph :seriesData="dashboardSummaryStates.burnData" title="มูลค่าสินค้าคงเหลือรายคลัง"
                            :isTimeSeries="true"></trend-graph>
                    </div>
                </div>
            </div>
            <div class="my-6">
                <a-tabs v-model:activeKey="activeKey" type="card">
                    <a-tab-pane key="1">
                        <template #tab>
                            <span>
                                <apple-outlined />
                                <font-awesome-icon icon="fa-solid fa-list" />
                                <span class="pl-2">ทั้งหมด</span>
                            </span>
                        </template>
                        <TableCreatedeliverylistReport :querybystatus="'all'"
                            :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม">
                        </TableCreatedeliverylistReport>
                    </a-tab-pane>
                    <a-tab-pane key="2">
                        <template #tab>
                            <span>
                                <android-outlined />
                                <font-awesome-icon icon="fa-solid fa-hands-holding-child" />
                                <span class="pl-2">เตรียมแพ็คสินค้า</span>

                            </span>
                        </template>
                        <TableCreatedeliverylistReport :querybystatus="'prepare_pack'"
                            :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม">
                        </TableCreatedeliverylistReport>
                    </a-tab-pane>
                    <a-tab-pane key="3">
                        <template #tab>
                            <span>
                                <android-outlined />
                                <font-awesome-icon icon="fa-solid fa-cube" />
                                <span class="pl-2">แพ็คสินค้าแล้ว</span>

                            </span>
                        </template>
                        <TableCreatedeliverylistReport :querybystatus="'packed'"
                            :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม">
                        </TableCreatedeliverylistReport>
                    </a-tab-pane>
                    <a-tab-pane key="4">
                        <template #tab>
                            <span>
                                <android-outlined />
                                <font-awesome-icon icon="fa-solid fa-cancel" />
                                <span class="pl-2">ยกเลิกการจัดส่ง</span>

                            </span>
                        </template>
                        <TableCreatedeliverylistReport :querybystatus="'cancel'"
                            :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม">
                        </TableCreatedeliverylistReport>
                    </a-tab-pane>
                </a-tabs>

            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import DashboardDataCard from "../component/DashboardDataCard.vue";
import TrendGraph from "../component/TrendGraph.vue";
import TrendGraphLine from "../component/TrendGraphLine.vue";
import TableCreatedeliverylistReport from "../component/TableCreatedeliverylistReport.vue";
// import VDateRangePicker from "@/components/Form/DatePicker/DateRangePicker.vue";
// helper
import DatetimeHelper from "@/helper/DatetimeHelper";
// composable
import useDashboardSummaryComposable from "@/composables/dashboard/DashboardSummaryComposable";
import liff from '@line/liff';
import { account_profile } from '@/store/store'
export default {
    name: "DashboardInfo",
    components: {
        TopBar,
        Footer,
        DashboardDataCard,
        TrendGraph,
        TrendGraphLine,
        TableCreatedeliverylistReport
        // VDateRangePicker
    },
    data: function () {
        return {
            cards: {},
            userActivities: {},
            trendGraphData: null,
            earnGraphData: null,
            burnGraphData: null,
            dtFilter: null
        };
    },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "User Management Dashboard"
                }
            ];
        },
        today: function () {
            let now = new Date();
            return DatetimeHelper.formatJSDate(now, "ccc, dd LLL yy");
        },
        maxDate: function () {
            // for test
            // let d = DateTime.fromJSDate(new Date())
            // d = d.plus({
            //     days: 3
            // })
            // return d.toJSDate()
            return new Date();
        }
    },
    setup: function () {
        const { dashboardSummaryStates } = useDashboardSummaryComposable();

        return {
            dashboardSummaryStates
        };
    },
    mounted: function () {
        // this.handleSignIn()
        // config date picker
        // this.dtFilter = DatetimeHelper.JSDateToISODate(new Date())

        this.cards = {
            dailysales: {
                label: "ยอดขายวันนี้ (บาท)",
                value: 3300,
                diff: 157,
                percentage: 8
            },
            monthlysales: {
                label: "ยอดขายเดือนนี้ (ก.พ./2565) (บาท)",
                value: 300080,
                diff: -679,
                percentage: 4
            },
            yearsales: {
                label: "ยอดขายรวมทั้งปี (บาท)",
                value: 5000,
                diff: 37,
                percentage: 2
            },
            year1ActiveUser: {
                label: "+1 Year User",
                value: 1000300,
                diff: 157,
                percentage: 11
            },
            topLuckyDraw: {
                label: "Top Lucky Draw",
                value: 84300,
                diff: 157,
                percentage: 8
            },
            topSpender: {
                label: "Top Spender",
                value: 20040,
                diff: 37,
                percentage: 2
            },
            dropOff: {
                label: "Drop Off",
                value: 2567,
                diff: -40,
                percentage: 4
            },
            potentialBuyer: {
                label: "Potential Buyer",
                value: 8565,
                diff: 4777,
                percentage: 11
            }
        };

        this.userActivities = {
            label: "User Activity",
            values: [
                {
                    text: "Visit",
                    value: 1466
                },
                {
                    text: "Login",
                    value: 1267
                },
                {
                    text: "Engage",
                    value: 890
                },
                {
                    text: "Buy",
                    value: 434
                }
            ]
        };
    },
    methods: {
        async handleSignIn() {
            try {
                await liff.init({ liffId: '1657398122-PDgNMpj0' });
                if (!liff.isLoggedIn()) {
                    liff.login();
                }
                else {
                    var lineprofile = await liff.getProfile()
                    account_profile.value.line = {
                        UserId: lineprofile?.UserId,
                        displayName: lineprofile?.displayName,
                        pictureUrl: lineprofile?.pictureUrl,
                        role: 'admin',
                        islogin: true

                    }
                    Cache.setCache("profile", account_profile.value);
                    // alert(JSON.stringify(await liff.getProfile(), null, 4))
                }
            } catch (error) {
                console.log(error);
                return null;
            }

        },
    }
};
</script>
<style lang="scss" scoped>
.flex-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 800px) {
    .flex-container {
        flex-direction: row;
        margin: 20px 0px;
    }

    .items__dashboard {
        flex-grow: 1;
        margin: 0px 10px;
    }

    .group-left {
        flex-grow: 1;
    }

    .group-right {
        flex-grow: 1;
        width: 50%;
        margin: 15px;
    }

    .flex-group-container {
        display: flex;
        flex-wrap: wrap;

        .item__dashboard {
            flex-grow: 1;
            width: 20%;
            margin: 15px;
        }
    }
}

.my-6 {
    background: white;
}
</style>
