<template>
    <div class="page">
        <TopBar>
            <template v-slot:toolbar>
                <!-- <div class="flex py-2 items-center"> -->
                <!-- <div class="flex-auto"></div>
                    <div class="flex-none"></div> -->
                <!-- <div class="flex-none">
                        <div class="flex items-center text-sm text-gray-600">
                            <div class="px-4 cursor-pointer pr-0">
                                <v-date-range-picker
                                    v-model="dtFilter"
                                    :confirmMode="true"
                                    :hasPresets="false"
                                    :max="maxDate"
                                >
                                    <template v-slot:activator="{ text, on }">
                                        <div
                                            class="flex items-center text-center rounded bg-gray-100 py-1 px-2 text-sm ring-1 ring-gray-200 ring-opacity-80"
                                            v-on="on"
                                        >
                                            <div
                                                class="inline-flex items-center icon left"
                                            >
                                                <svg
                                                    class="h-6 w-6 text-gray-400"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                                    />
                                                </svg>
                                            </div>
                                            <span class="text-green-500">{{
                                                text
                                            }}</span>
                                        </div>
                                    </template>
                                </v-date-range-picker>
                            </div>
                        </div>
                    </div> -->
                <!-- </div> -->
            </template>
        </TopBar>
        <div class="pb-10 mx-6">
            <div>

                <div class="flex-group-container">
                    <div class="item__dashboard">
                        <dashboard-data-card :colindex="1" v-bind="cards.dailysales"></dashboard-data-card>
                    </div>
                    <div class="item__dashboard">
                        <dashboard-data-card :colindex="2" v-bind="cards.monthlysales">
                        </dashboard-data-card>
                    </div>
                    <div class="item__dashboard">
                        <dashboard-data-card :colindex="3" v-bind="cards.yearsales"></dashboard-data-card>
                    </div>
                </div>
                <div class="flex-container">
                    <div class="group-left">
                        <div class="flex-group-container">


                            <!-- <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.year1ActiveUser"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.topLuckyDraw"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.topSpender"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.dropOff"
                            ></dashboard-data-card>
                        </div>
                        <div class="item__dashboard">
                            <dashboard-data-card
                                v-bind="cards.potentialBuyer"
                            ></dashboard-data-card>
                        </div> -->
                        </div>
                    </div>
                    <!-- <div class="group-right">
                    <dashboard-data-card
                        class="h-full px-6"
                        v-bind="userActivities"
                    >
                        <template v-slot:default="props">
                            <div class="text-center card-content">
                                <div class="label text-gray-500 mb-3">
                                    {{ props.label }}
                                </div>
                                <div class="v-list">
                                    <template
                                        v-for="(item, idx) of props.values"
                                        :key="`uact-${idx}`"
                                    >
                                        <div class="v-list-item">
                                            <div
                                                class="flex justify-between w-full"
                                            >
                                                <div class="text-gray-500">
                                                    {{ item.text }}
                                                </div>
                                                <div
                                                    class="text-gray-600 font-semibold"
                                                >
                                                    {{ item.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </dashboard-data-card>
                </div> -->
                </div>
                <div class="flex-group-container">


                    <div class="items__dashboard">
                        <trend-graph-line title="รายการในระบบ" :isTimeSeries="true"></trend-graph-line>
                    </div>

                    <div class="items__dashboard">
                        <trend-graph title="รายการจัดส่ง" :isTimeSeries="true"></trend-graph>
                    </div>
                </div>
                <div class="flex-group-container pt-3">


                    <div class="items__dashboard">
                        <trend-graph-map title="ลูกค้า" :isTimeSeries="true"></trend-graph-map>
                    </div>


                </div>
            </div>
            <div v-if="false" class="my-6">
                <Table :seriesData="dashboardSummaryStates.trendingData" :title="ยอดขายรวม"></Table>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import Footer from "@/components/Layout/Footer/Footer.vue";
import DashboardDataCard from "../component/DashboardDataCard.vue";
import TrendGraph from "../component/TrendGraph.vue";
import TrendGraphLine from "../component/TrendGraphLine.vue";
import TrendGraphMap from "../component/TrendGraphMap.vue";
import Table from "../component/Table.vue";
import Util from "@/helper/Utility";
// import VDateRangePicker from "@/components/Form/DatePicker/DateRangePicker.vue";
// helper
import DatetimeHelper from "@/helper/DatetimeHelper";
// composable
import useDashboardSummaryComposable from "@/composables/dashboard/DashboardSummaryComposable";
import liff from '@line/liff';
import { account_profile } from '@/store/store'
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import _ from "lodash";
export default {
    name: "DashboardInfo",
    components: {
        TopBar,
        Footer,
        DashboardDataCard,
        TrendGraph,
        TrendGraphLine,
        Table,
        TrendGraphMap
        // VDateRangePicker
    },
    data: function () {
        return {
            cards: {},
            userActivities: {},
            trendGraphData: null,
            earnGraphData: null,
            burnGraphData: null,
            dtFilter: null
        };
    },
    computed: {
        bcItems: function () {
            return [
                {
                    text: "User Management Dashboard"
                }
            ];
        },
        today: function () {
            let now = new Date();
            return DatetimeHelper.formatJSDate(now, "ccc, dd LLL yy");
        },
        maxDate: function () {
            // for test
            // let d = DateTime.fromJSDate(new Date())
            // d = d.plus({
            //     days: 3
            // })
            // return d.toJSDate()
            return new Date();
        }
    },
    setup: function () {

        const { dashboardSummaryStates } = useDashboardSummaryComposable();

        return {
            dashboardSummaryStates
        };
    },
    mounted: function () {
        this.initdashboard()
        // this.handleSignIn()
        // config date picker
        // this.dtFilter = DatetimeHelper.JSDateToISODate(new Date())

        this.cards = {
            dailysales: {
                label: "ยอดขายวันนี้ (บาท)",
                value: 0,
                diff: 0,
                percentage: 0
            },
            monthlysales: {
                label: "ยอดขายเดือนนี้ (ก.พ./2565) (บาท)",
                value: 0,
                diff: 0,
                percentage: 0
            },
            yearsales: {
                label: "ยอดขายรวมทั้งปี (บาท)",
                value: 0,
                diff: 0,
                percentage: 0
            },
            year1ActiveUser: {
                label: "+1 Year User",
                value: 0,
                diff: 0,
                percentage: 0
            },
            topLuckyDraw: {
                label: "Top Lucky Draw",
                value: 84300,
                diff: 157,
                percentage: 8
            },
            topSpender: {
                label: "Top Spender",
                value: 20040,
                diff: 37,
                percentage: 2
            },
            dropOff: {
                label: "Drop Off",
                value: 2567,
                diff: -40,
                percentage: 4
            },
            potentialBuyer: {
                label: "Potential Buyer",
                value: 8565,
                diff: 4777,
                percentage: 11
            }
        };

        this.userActivities = {
            label: "User Activity",
            values: [
                {
                    text: "Visit",
                    value: 1466
                },
                {
                    text: "Login",
                    value: 1267
                },
                {
                    text: "Engage",
                    value: 890
                },
                {
                    text: "Buy",
                    value: 434
                }
            ]
        };
    },
    methods: {
        async handleSignIn() {
            try {
                await liff.init({ liffId: '1657398122-PDgNMpj0' });
                if (!liff.isLoggedIn()) {
                    liff.login();
                }
                else {
                    var lineprofile = await liff.getProfile()
                    account_profile.value.line = {
                        UserId: lineprofile?.UserId,
                        displayName: lineprofile?.displayName,
                        pictureUrl: lineprofile?.pictureUrl,
                        role: 'admin',
                        islogin: true

                    }
                    Cache.setCache("profile", account_profile.value);
                    // alert(JSON.stringify(await liff.getProfile(), null, 4))
                }
            } catch (error) {
                console.log(error);
                return null;
            }

        },
        initdashboard() {

            BzbsUser.apicustomerproduct()
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    var dataSources = this.groupBy(res.data[0], "CustomerOrder");

                    var result = []
                    dataSources.forEach(element => {
                        if (_.isString(element[0].ProductDetail) && element[0].ProductDetail != '') {

                            return result.push({ price: JSON.parse(element[0].ProductDetail)?.price, date: element[0].OrderDate })
                        }
                    });
                    // var result = dataSources.filter(item => _.isJSON(item.ProductJson) ? JSON.parse(item.ProductJson).price : 0)




                    result = result.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });

                    this.getcurrentday(result)
                    this.getcurrentmonth(result)
                    this.getcurrentyear(result)

                })
                .catch(error => {
                    console.log(error);
                });
        },
        getPreviousDay(date = new Date()) {
            const previous = new Date(date.getTime());
            previous.setDate(date.getDate() - 1);

            return previous;
        },
        getcurrentday(result) {
            let today = new Date();
            let dd = today.getDate();

            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();
            var getcurrentdate = yyyy + '-' + this.pad(mm) + '-' + this.pad(dd)

            let last_dd = this.getPreviousDay().getDate()
            var lastdate = yyyy + '-' + this.pad(mm) + '-' + this.pad(last_dd)

            var current_day = result.filter(item => item.date == getcurrentdate)
            var last_day = result.filter(item => item.date == lastdate)

            var sum = 0
            current_day.map(x => {
                if (x.price)
                    sum += x.price
            });


            var lastday_sum = 0
            last_day.map(x => {
                if (x.price)
                    lastday_sum += x.price
            });

            var percent = ((sum - lastday_sum) / (sum + lastday_sum) / 2) * 100



            this.cards.dailysales.value = sum
            this.cards.dailysales.diff = sum - lastday_sum
            this.cards.dailysales.percentage = percent || 0

            console.log(lastday_sum)
        },
        getcurrentmonth(result) {
            var currentMonth = '0' + (new Date().getMonth() + 1),
                currentYear = new Date().getFullYear()

            var lastMonth = '0' + (new Date().getMonth()),
                currentYear = new Date().getFullYear()

            var current_month = result.filter((e) => {

                var dateStr = currentYear + '-' + currentMonth;
                return (e.date.indexOf(dateStr) !== -1)
            });

            var last_month = result.filter((e) => {

                var dateStr = currentYear + '-' + lastMonth;
                return (e.date.indexOf(dateStr) !== -1)
            });
            var sum = 0
            current_month.map(x => {
                if (x.price)
                    sum += x.price
            });

            var sum_lastmonth = 0
            last_month.map(x => {
                if (x.price)
                    sum_lastmonth += x.price
            });


            var percent = ((sum - sum_lastmonth) / (sum + sum_lastmonth) / 2) * 100


            this.cards.monthlysales.value = sum
            this.cards.monthlysales.diff = (sum - sum_lastmonth)
            this.cards.monthlysales.percentage = percent || 0

        },
        getcurrentyear(result) {


            var currentYear = new Date().getFullYear()
            var last_Year = new Date().getFullYear() - 1


            var current_yearsales = result.filter((e) => {
                var dateStr = currentYear + '-';
                return (e.date.indexOf(dateStr) !== -1)
            });
            var last_yearsales = result.filter((e) => {
                var dateStr = last_Year + '-';
                return (e.date.indexOf(dateStr) !== -1)
            });


            var sum = 0
            current_yearsales.map(x => {
                if (x.price)
                    sum += x.price
            });
            var sum_lastyear = 0
            last_yearsales.map(x => {
                if (x.price)
                    sum_lastyear += x.price
            });



            var percent = ((sum - sum_lastyear) / (sum + sum_lastyear) / 2) * 100

            this.cards.yearsales.value = sum
            this.cards.yearsales.diff = sum - sum_lastyear
            this.cards.yearsales.percentage = percent || 0

            // result = result.filter(({ date }) =>
            //     currentYear == +date.slice(-4)
            // );

        }, pad(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
        },
        groupBy(collection, property) {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },
    }
};
</script>
<style lang="scss" scoped>
.flex-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 800px) {
    .flex-container {
        flex-direction: row;
        margin: 20px 0px;
    }

    .items__dashboard {
        flex-grow: 1;
        margin: 0px 10px;
    }

    .group-left {
        flex-grow: 1;
    }

    .group-right {
        flex-grow: 1;
        width: 50%;
        margin: 15px;
    }

    .flex-group-container {
        display: flex;
        flex-wrap: wrap;

        .item__dashboard {
            flex-grow: 1;
            width: 20%;
            margin: 15px;
        }
    }
}
</style>
