<template>
    <!-- <a-radio-group v-model:value="placement" style="margin-right: 8px">
        <a-radio value="top">top</a-radio>
        <a-radio value="right">right</a-radio>
        <a-radio value="bottom">bottom</a-radio>
        <a-radio value="left">left</a-radio>
    </a-radio-group> -->
    <!-- <a-button type="primary" @click="showDrawer">Open</a-button> -->
    <a-drawer
        :width="500"
        title="แจ้งเตือน"
        :placement="placement"
        :visible="visible"
        @close="onClose"
    >
        <template #extra>
            <div class="btn-read">
                <a-popconfirm
                    title="ต้องการอัพเดทค่าว่าอ่านทั้งหมดใช่หรือไม่"
                    @confirm="readnoti(record)"
                    @cancel="cancel"
                >
                    <a-button type="primary">
                        <template #icon>
                            <EyeOutlined />
                        </template>
                        กดยืนยันอ่านแล้วทั้งหมด
                    </a-button>
                </a-popconfirm>
            </div>
        </template>

        <div
            v-for="(Item, idx) of formStateStore.noti.listnoti"
            :key="`item-${idx}`"
        >
            <div class="mb-2">
                <a-badge-ribbon
                    v-if="Item.Type == 'systemyan'"
                    text="แจ้งเตือน(ตรวจสอบลงระบบ)"
                    color="cyan"
                >
                    <a-card
                        :class="{ noread: Item.Read == 0 }"
                        :title="Item.Title"
                        size="small"
                    >
                        <span
                            >ลำดับงาน
                            <a-typography-paragraph copyable>{{
                                Item.OrderTransaction
                            }}</a-typography-paragraph></span
                        >
                        <div class="pt-4">
                            <a @click="readacion(Item)"
                                ><u>{{ Item.Desc }}</u></a
                            >
                        </div>
                        <div class="pt-4" style="text-align: end;">
                            <i>{{ formatdate(Item.UpdateDate) }}</i>
                        </div>
                        <a-badge
                            v-if="Item.Read == 0"
                            style="float: right;"
                            status="processing"
                        />
                    </a-card>
                </a-badge-ribbon>
            </div>
            <div class="mb-2">
                <a-badge-ribbon
                    v-if="Item.Type == 'payment'"
                    text="แจ้งเตือน(ตรวจสอบการเงิน)"
                    color="pink"
                >
                    <a-card
                        :class="{ noread: Item.Read == 0 }"
                        :title="Item.Title"
                        size="small"
                    >
                        <a-typography-paragraph
                            :copyable="{ text: Item.OrderTransaction }"
                        >
                            ลำดับงาน {{ Item.OrderTransaction }}
                        </a-typography-paragraph>

                        <div class="pt-4">
                            <a @click="readacion(Item)"
                                ><u>{{ Item.Desc }}</u></a
                            >
                        </div>
                        <div class="pt-4" style="text-align: end;">
                            <i>{{ formatdate(Item.UpdateDate) }}</i>
                        </div>
                        <a-badge
                            v-if="Item.Read == 0"
                            style="float: right;"
                            status="processing"
                        />
                    </a-card>
                </a-badge-ribbon>
            </div>
            <div class="mb-2">
                <a-badge-ribbon
                    v-if="Item.Type == 'paymentaddtional'"
                    text="แจ้งเตือน(ตรวจสอบการเงิน)"
                    color="purple"
                >
                    <a-card
                        :class="{ noread: Item.Read == 0 }"
                        :title="Item.Title"
                        size="small"
                    >
                        <a-typography-paragraph
                            :copyable="{ text: Item.CustomerOrder }"
                        >
                            รหัสลูกค้า {{ Item.CustomerOrder }}
                        </a-typography-paragraph>

                        <div class="pt-4">
                            <a @click="readacion(Item)"
                                ><u>{{ Item.Desc }}</u></a
                            >
                        </div>
                        <div class="pt-4" style="text-align: end;">
                            <i>{{ formatdate(Item.UpdateDate) }}</i>
                        </div>
                        <a-badge
                            v-if="Item.Read == 0"
                            style="float: right;"
                            status="processing"
                        />
                    </a-card>
                </a-badge-ribbon>
            </div>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref } from "vue";
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons-vue";

import "moment/locale/th"; // without this line it didn't work
export default defineComponent({
    emits: ["updatedata"],
    props: {
        isshow: Boolean
    },
    components: { EyeOutlined },

    setup(props, ctx) {
        const placement = ref("left");
        const visible = ref(false);
        const showDrawer = () => {
            visible.value = true;
        };
        showDrawer();
        const onClose = () => {
            visible.value = false;
            updatedata();
        };
        const updatedata = () => {
            ctx.emit("updatedata", {
                updatedata: true
            });
        };

        const readnoti = () => {
            var param = {
                Type: "readall"
            };
            BzbsUser.apireadnotimessage(param)
                .then(res => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 400);

                })
                .catch(error => {
                    console.log(error);
                });
        };
        const readacion = Item => {
            var param = {
                NotiOrderMessage: Item.NotiOrderMessage
            };
            BzbsUser.apireadnotimessage(param)
                .then(res => {
                    setTimeout(() => {
                        window.location.href = Item.Url;
                    }, 400);
                    // console.log("apigetnotimessage  res :", res)
                    // formStateStore.noti.count = res.data.length
                    // return formStateStore.noti.listnoti = res.data
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const formatdate = date => {
            if (date) {
                moment.locale("th");
                return moment(date)
                    .add(543, "year")
                    .format("DD MMM YYYY เวลา  HH:mm:ss");
            } else {
                return "-";
            }
        };
        const cancel = e => {
            console.log(e);
            message.error("Click on No");
        };
        return {
            formatdate,
            readacion,
            formStateStore,
            updatedata,
            placement,
            visible,
            showDrawer,
            onClose,
            readnoti,
            cancel
        };
    }
});
</script>
<style lang="scss">
.noread {
    background-color: rgb(25 144 255 / 20%) !important;
    border: 1px solid #1990ff33 !important;
}
.btn-read {
    float: right;
}
</style>
