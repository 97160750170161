<template>
  <a-form layout="inline" :model="formState" @finish="handleFinish" @finishFailed="handleFinishFailed">
    <a-form-item>
      <a-input v-model:value="formState.user" placeholder="Username">
        <template #prefix>
          <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input v-model:value="formState.password" type="password" placeholder="Password">
        <template #prefix>
          <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item style="margin: auto; margin-top: 10px">
      <a-button type="primary" html-type="submit" :disabled="formState.user === '' || formState.password === ''">
        Log in
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive } from "vue";
import CommonMixin from "@/mixin/CommonMixin";
import CacheHelper from "@/helper/CacheHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import AccountStore from "@/store/AccountStore.js";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { Modal } from 'ant-design-vue';
var Cache = new CacheHelper(ConfigManager.getCacheType());
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  mixins: [CommonMixin],
  setup() {
    const formState = reactive({
      user: "",
      password: "",
    });

    const handleFinish = (values) => {
      login(formState.user, formState.password)

      console.log(values, formState);
    };
    const login = async (username, password) => {

      const param = {
        "username": username,
        "password": password
      };

 
       var objip = await BzbsUser.apigetip()
      await BzbsUser.apilogin(param)
        .then(res => {
          try {

            console.log(res)
            if (res.data == 401) {
              Modal.warning({
                title: "user หรือ password ไม่ถูกต้อง",
              });

            }
            else {
              Modal.success({
                title: 'เข้าสู่ระบบสำเร็จ',
              });
            }

            // success();
            console.log(res)
            var profileresult = res.data.profile[0]

            Cache.setCache("logined", true);
            Cache.setCache("auth", res.data);
            Cache.setCache("jsonip", objip.data);
            AccountStore.state.isLogin = true;
            var profile = {}
            profile.login = {
              shortedName: profileresult.UserId,
              email: "",
              name: profileresult.UserId,
              imgurl: "",
              fullname: profileresult.UserId,
              id: "",
              role: profileresult.Role ? profileresult.Role : "admin",
              accessToken: profileresult.AccessToken,
              refreshToken: profileresult.RefreshToken,
              islogin: true

            }
            Cache.setCache("profile", profile);
            setTimeout(() => {
               
              if (profileresult.Role == 'account') return window.location = "/checkmoneyselllist";
              if (profileresult.Role == 'salepage') return window.location = "/subdomainlist";
              if (profileresult.Role == 'commission') return window.location = "/getreportcommission";
              window.location = "/selllist";
            }, 1000);

          } catch (error) {

            console.log(error)
          }
        })
        .catch(error => {
          console.log(error);
        });
    };
    // const logintemp = (values) => {
    //   var email = googleUser.getBasicProfile().getEmail()
    //   let path = window.location.host.includes("localhost")
    //     ? "http://localhost:8090"
    //     : "https://process.env.VUE_APP_API_BASE_URLhayanbackoffice.azurewebsites.net";

    //   return axios.get(path + "/api/login/" + email).then(res => {

    //     console.log(res.data[0]);

    //     if (res.data[0][0]?.Email != googleUser.getBasicProfile().getEmail()) {
    //       Modal.warning({
    //         title: "ไม่พบ " + googleUser.getBasicProfile().getEmail() + " ในระบบ กรุณาแจ้ง admin",
    //       });
    //       AccountStore.state.isLogin = false;
    //       return null
    //     }
    //     AccountStore.state.isLogin = true;
    //     account_profile.value.google = {
    //       email: googleUser.getBasicProfile().getEmail(),
    //       name: googleUser.getBasicProfile().getName(),
    //       imgurl: googleUser.getBasicProfile().getImageUrl(),
    //       fullname: googleUser.getBasicProfile().getName(),
    //       id: googleUser.getBasicProfile().getId(),
    //       role: res.data[0][0]?.Role,
    //       islogin: true

    //     }
    //     Cache.setCache("profile", account_profile.value);
    //     console.log(account_profile.value)

    //     Cache.setCache("logined", true);
    //     this.$router.push('/dashboard/');
    //   });
    // }

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };

    return {
      formState,
      handleFinish,
      handleFinishFailed,
    };
  },
});
</script>