<template>
    <!-- dataSources :
    <pre>{{dataSources}}</pre> -->

    <div class="relative p-4 card">
        <template v-if="title != null">
            <div class="text-lg font-bold text-gray-500">{{ title }}</div>
        </template>
        <div
            class="mt-2"
            :style="{ height: heightComputed, maxHeight: heightComputed }"
        >
            <a-table
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
                v-if="dataSources && !rowselecteddetail.ishowdetail"
                size="middle"
                :columns="columns"
                :data-source="dataSources"
                @change="handleTableChange"
                @expandFixed="true"
            > 
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'Order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrderSearch'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-typography-text
                                copyable
                                strong
                                v-if="Item.CustomerOrder"
                            >
                                {{ Item.CustomerOrder }}
                            </a-typography-text>

                            <a-typography-text delete v-else
                                >ไม่พบข้อมูล</a-typography-text
                            >
                        </div>
                    </template>
                    <template
                        v-if="column.dataIndex === 'AdditionalTageChecking'"
                    >
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of findDuplicates(record)"
                            :key="`item-${idx}`"
                        >
                        <!-- <pre>{{Item.AdditionalTage}}</pre>
                        <pre>{{Item.AdditionalShipping}}</pre> -->
                            <div v-if="Item.AdditionalTage">
                                <div v-if="Item.AdditionalShipping > 0">
                                    <a-button 
                                        type="primary"
                                        @Click="
                                            gotocheck(
                                                `additionalaccountchecking?customercode=${Item.CustomerOrder}&so=${Item.ProductSellOrder}&sp=${Item.AdditionalTage}#no-back-button`
                                            )
                                        "
                                        >กดตรวจสอบอนุมัติค่าจัดส่ง</a-button
                                    >
                                </div>
                                <div v-else-if="Item.AdditionalShipping == 0">
                                    <a-button  
                                        @Click="
                                            gotocheck(
                                                `additionalaccountchecking?customercode=${Item.CustomerOrder}&so=${Item.ProductSellOrder}&sp=${Item.AdditionalTage}#no-back-button`
                                            )
                                        "
                                        >แก้ไข/อนุมัติค่าจัดส่ง</a-button
                                    >
                                </div>
                                <!-- <hr /> -->
                                <!-- <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text> -->
                            </div>
                            <div v-else>
                                ยังไม่ได้สร้างรายการจัดส่งเพิ่ม
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="column.dataIndex === 'AdditionalTageStatus'"
                    > 
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of findDuplicates(record)"
                            :key="`item-${idx}`"
                        > 
                        
                            <div v-if="Item.AdditionalTage">
                                <div>
                                    <div v-if="Item.AdditionalShipping <= 0">
                                        <a-tag color="blue"
                                            >ไม่มีค่าส่งเพิ่ม</a-tag
                                        >
                                    </div>
                                    <div v-else>
                                        <a-tag
                                            v-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'ตรวจสอบแล้วผ่าน'
                                            "
                                            color="#87d068"
                                            >ตรวจสอบแล้วผ่าน</a-tag
                                        >
                                        <a-tag
                                            v-else-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'ไม่ผ่าน'
                                            "
                                            color="#f50"
                                            >ไม่ผ่าน</a-tag
                                        >
                                        <a-tag
                                            v-else-if="
                                                isLastMessageChecked(
                                                    JSON.parse(
                                                        Item.CheckMessage
                                                    )
                                                ) == 'บัญชียังไม่ได้ตรวจสอบ'
                                            "
                                            color="#108ee9"
                                            >บัญชียังไม่ได้ตรวจสอบ</a-tag
                                        >
                                    </div>
                                </div>
                                <!-- <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text> -->
                            </div>
                            <div v-else>
                                ยังไม่ได้สร้างรายการจัดส่งเพิ่ม
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalTage'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of findDuplicates_sp(record)"
                            :key="`item-${idx}`"
                        >
                            <div v-if="Item.AdditionalTage">
                                <a-typography-text
                                    strong
                                    v-if="Item.AdditionalTage"
                                >
                                    <div>
                                        หมายเลขการจัดส่ง
                                    </div>
                                    {{ Item.AdditionalTage }}
                                </a-typography-text>

                                <!-- <a-typography-text delete v-else>ไม่พบข้อมูล</a-typography-text> -->
                            </div>
                            <div v-else>
                                ยังไม่ได้สร้างรายการจัดส่งเพิ่ม
                            </div>
                        </div>
                    </template>
                    <template v-else-if="column.dataIndex === 'ProductImage'">
                        <div v-for="(Item, idx) of record" :key="`item-${idx}`">
                            <a-image :width="100" :src="Item.SlipImageURL" />
                            <a-avatar
                                v-if="!Item.SlipImageURL"
                                shape="square"
                                size="large"
                            >
                                <template #icon>
                                    <UserOutlined />
                                </template>
                            </a-avatar>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductSellOrder'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <div v-if="Item.ProductSellOrder">
                                <a-typography-text
                                    strong
                                    v-if="Item.ProductSellOrder"
                                >
                                    <div>
                                        หมายเลขการจัดส่ง
                                    </div>
                                    {{ Item.ProductSellOrder }}
                                </a-typography-text>
                            </div>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'CustomerOrderCode'">
                        {{ record[0].CustomerOrder }}
                    </template>
                    <template v-if="column.dataIndex === 'ProductCode'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            {{ Util.jsonParse(Item.ProductDetail).category_1 }}
                            {{ Util.jsonParse(Item.ProductDetail).category_2 }}
                            {{ Util.jsonParse(Item.ProductDetail).category_3 }}
                            {{ Util.jsonParse(Item.ProductDetail).category_4 }}
                        </div>

                        <!-- <pre>{{record}}</pre> -->
                    </template>
                    <template v-if="column.dataIndex === 'CustomerOrder'">
                        <!-- <pre>{{record}}</pre> -->
                        <div>
                            <a-space :size="30">
                                <a-tooltip placement="right">
                                    <template #title>
                                        <!-- {{
                                        record.find(
                                        item =>
                                        item.ContactStartDefault ==
                                        "true"
                                        )?.ContactName
                                        }} -->
                                        <a-image
                                            :width="200"
                                            :src="
                                                record.find(
                                                    item =>
                                                        item.ContactStartDefault ==
                                                        'true'
                                                ).ContactImageUrl
                                            "
                                        />
                                    </template>
                                    <!-- <pre>{{record}}</pre> -->
                                    <div
                                        v-for="(Item, idx) of record"
                                        :key="`item-${idx}`"
                                        @click="rowselected(record)"
                                    >
                                        {{ Item.ContactName }}
                                        <!-- <div v-show="Item.ContactStartDefault=='true'">
                                            <a-typography-link underline>
                                                {{Item.ContactName}}
                                            </a-typography-link>
                                        </div> -->
                                    </div>
                                </a-tooltip>

                                <a-tag
                                    :color="
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    'true'
                                            )
                                            ?.ContactType?.toUpperCase() ==
                                        'FACEBOOK'
                                            ? 'geekblue'
                                            : 'green'
                                    "
                                >
                                    {{
                                        record
                                            .find(
                                                item =>
                                                    item.ContactStartDefault ==
                                                    "true"
                                            )
                                            ?.ContactType?.toUpperCase()
                                    }}
                                </a-tag>
                            </a-space>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'PaiddeliveryObj'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button  type="dashed">
                                <a-typography-text strong v-if="Item.Price">{{
                                    showprice(Item.Price)
                                }}</a-typography-text>
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'AdditionalShipping'">
                        <div
                            class="pb-2"
                            v-for="(Item, idx) of record"
                            :key="`item-${idx}`"
                        >
                            <a-button type="dashed">
                                <a-typography-text
                                    strong
                                    v-if="Item.AdditionalShipping"
                                    >{{
                                        showprice(Item.AdditionalShipping)
                                    }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </a-button>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'SellDate'">
                        {{ formatdate(record.SellDate) }}
                    </template>
                    <!-- <template v-if="column.dataIndex === 'Barcode'">
                        <BarcodeGenerator :value="record.SellOrder" :format="'CODE39'" :lineColor="'black'" :width="10"
                            :height="40" :elementTag="'svg'" />
                    </template> -->
                    <template v-if="column.dataIndex === 'Customereline'">
                        <div class="wrapper_line_id">
                            <img
                                class="line_ic"
                                src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png"
                            />
                            <span class="absolute text-lg left-10">
                                {{ record.Customereline }}</span
                            >
                        </div>
                    </template>
                    <template v-if="column.key === 'operation'">
                        <div>
                            <close-outlined
                                :style="{ color: 'red', fontSize: '14px' }"
                            />
                            <span :style="{ color: 'red', fontSize: '14px' }"
                                >ยังไม่ได้จัดส่ง</span
                            >
                            <!-- <check-outlined
                                :style="{ color: 'green', fontSize: '32px' }"
                            />
                            <span :style="{ color: 'green', fontSize: '20px' }"
                                >จัดส่งแล้ว</span
                            > -->
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'ProductStatus'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <a-typography-text
                                    type="danger"
                                    strong
                                    v-if="Item.ProductStatus == 'cancel'"
                                    >{{ Item.ProductStatus
                                    }}<span
                                        :style="{
                                            color: 'red',
                                            fontSize: '14px'
                                        }"
                                    >
                                        {{ Item.ProductStatusDesc }}
                                    </span>
                                </a-typography-text>
                                <a-typography-text
                                    strong
                                    v-else-if="Item.ProductStatus"
                                    >{{ Item.ProductStatus }}</a-typography-text
                                >
                                <a-typography-text delete v-else
                                    >ไม่พบข้อมูล</a-typography-text
                                >
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'StatusData'">
                        <div>
                            <div
                                class="pb-2"
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div v-if="Item.ProfessorType && idx == 0">
                                    <a-typography-text
                                        mark
                                        strong
                                        v-if="
                                            JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                        >*รอคุยกับอาจารย์</a-typography-text
                                    >

                                    <a-typography-text
                                        type="danger"
                                        strong
                                        v-if="
                                            !JSON.parse(
                                                Item.ProfessorType
                                            )?.includes('2')
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-xmark"
                                        />
                                        ข้อมูลไม่ครบ
                                    </a-typography-text>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'Customertel'">
                        <div>
                            <div
                                v-for="(Item, idx) of record"
                                :key="`item-${idx}`"
                            >
                                <div
                                    v-if="
                                        Item.ProductResponsiblePerson &&
                                            idx == 0
                                    "
                                    class="flex items-start"
                                >
                                    <div class="flex-auto">
                                        <div class="text-xs text-gray-1-light">
                                            {{
                                                JSON.parse(
                                                    Item.ProductResponsiblePerson
                                                ).login?.shortedName
                                            }}
                                            |
                                            {{
                                                JSON.parse(
                                                    Item.ProductResponsiblePerson
                                                ).login?.role
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </a-table>
            <div v-if="!dataSources && !rowselecteddetail.ishowdetail">
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
                <TableLoad :isload="true"></TableLoad>
            </div>
        </div>
        <FromCreateSell
            v-if="rowselecteddetail.ishowdetail"
            :rowselected="rowselecteddetail"
        ></FromCreateSell>
    </div>
</template>
<script>
import TableLoad from "@/components/Skeleton/TableLoad";
import FromCreateSell from "@/module/CustomerOrder/SellCreate/component/FromCreateSell.vue";
import { usePagination } from "vue-request";
import { CloseOutlined } from "@ant-design/icons-vue";

import { computed, defineComponent, onMounted, ref, reactive } from "vue";
import axios from "axios";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
// import Drawer from "./Drawer.vue";
// import Previewslip from "../component/Previewslip.vue";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
import { UserOutlined } from "@ant-design/icons-vue";
import Util from "@/helper/Utility.js";
// import BarcodeGenerator from "./BarcodeGenerator.vue";

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "Order",
        width: "5%"
    }, 
    {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerOrderSearch",
        width: "15%",
        customFilterDropdown: false,
        onFilter: (value, record) =>
            record[0].CustomerOrder.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        }
    }, 
    {
        title: "รายการสค่าส่งเพิ่ม",
        dataIndex: "AdditionalTage",
        width: "15%"
    },

    // {
    //     title: "รหัสรายการ",
    //     dataIndex: "ProductSellOrder",

    // },
    {
        title: "รหัสสินค้า",
        dataIndex: "ProductCode"
    },
    {
        title: "ค่าส่ง",
        dataIndex: "PaiddeliveryObj"
    },
    {
        title: "ค่าส่งเพิ่ม",
        dataIndex: "AdditionalShipping"
    },
    {
        title: "ตรวจสอบ(บัญชี)",
        dataIndex: "AdditionalTageStatus",
        filters: [
            {
                text: "ตรวจสอบแล้วผ่าน",
                value: "ตรวจสอบแล้วผ่าน"
            },
            {
                text: "ไม่ผ่าน",
                value: "ไม่ผ่าน"
            },
            {
                text: "บัญชียังไม่ได้ตรวจสอบ",
                value: "บัญชียังไม่ได้ตรวจสอบ"
            }
        ],
        onFilter: (value, record) =>
            record.find(item => {
                
                console.log("> : ", value);
                if (item.CheckMessage != null) {
                    var ItemArray = JSON.parse(item.CheckMessage);

                    if (ItemArray?.length > 0) {
                       
                        // Get the last element in the array
                        const lastlist = ItemArray[ItemArray.length - 1];

                        // Check if the "checking" property is set to "checked"
                        if (lastlist.checking === "checked") {
                            return "ตรวจสอบแล้วผ่าน".startsWith(value);
                        } else if (lastlist.checking === "reject") {
                            return "ไม่ผ่าน".startsWith(value);
                        }
                    } else {
                        return "บัญชียังไม่ได้ตรวจสอบ".startsWith(value);
                    }
                } else {
                    if (item.AdditionalShipping <= 0) {
                        return "ไม่มีค่าส่งเพิ่ม".startsWith(value);
                    } else {
                        return "บัญชียังไม่ได้ตรวจสอบ".startsWith(value);
                    }
                }
            }),
        filterSearch: (input, filter) => filter.value.indexOf(input) > -1,

    },
    {
        title: "บัญชีตรวจสอบ",
        dataIndex: "AdditionalTageChecking"
    }
    // {
    //     title: "",
    //     dataIndex: "Customertel",
    // }
];

const queryData = params => {
    return axios.get("https://randomuser.me/api?noinfo", {
        params
    });
};

export default defineComponent({
    components: {
        UserOutlined,
        TableLoad,
        // BarcodeGenerator,
        // Drawer,
        CloseOutlined,
        // CheckOutlined,
        // Previewslip,
        FromCreateSell
    },

    setup() {
        const dataSources = ref(null);
        const rowselecteddetail = reactive({
            ishowdetail: false,
            rowselected: ""
        });
        const {
            data: dataSource,
            run,
            loading,
            title = "รายการจัดส่ง(4.1.1)",
            current,
            pageSize
        } = usePagination(queryData, {
            formatResult: res => res.data.results,
            pagination: {
                currentKey: "page",
                pageSizeKey: "results"
            }
        });
        const pagination = computed(() => ({
            total: 200,
            current: current.value,
            pageSize: pageSize.value
        }));

        onMounted(() => {
            querycustomer();
        });

        const updatedata = event => {
            if (event.updatedata) querycustomer();
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const groupBy = (collection, property) => {
            var i = 0,
                val,
                index,
                values = [],
                result = [];
            for (; i < collection.length; i++) {
                val = collection[i][property];
                index = values.indexOf(val);
                if (index > -1) result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        };
        const getUniqueListBy = (arr, key) => {
            return [...new Map(arr.map(item => [item[key], item])).values()];
        };
        const querycustomer = () => {
            return BzbsUser.apigetadditionaldeliveryorder("all")
                .then(res => {
                    res.data[0].forEach((element, index) => {
                        res.data[0][index].key = index;
                    });
                    dataSources.value = groupBy(res.data[0], "CustomerOrder");
                    // dataSources.value.forEach((element, index) => {
                    //     dataSources.value[index] = getUniqueListBy(element, "ProductSellOrder");
                    // });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const handleTableChange = (pag, filters, sorter) => {
            run({
                results: pag.pageSize,
                page: pag?.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters
            });
        };
        const rowselected = record => {
            rowselecteddetail.rowselected = record[0].CustomerOrder;
            rowselecteddetail.ishowdetail = true;
            console.log(record[0].CustomerOrder);
        };

        const groupBySellOrder = arraySellOrder => {
            return arraySellOrder.group(
                ({ ProductSellOrder }) => ProductSellOrder
            );
        };
        const showprice = price => {
            if (typeof price == undefined || price == undefined) return;
            return parseInt(price || 0).toLocaleString() + " บาท";
        };

        const findDuplicates = arr => {
            let encountered = {};
            let distinctValues = [];

            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const additionalTage = obj.AdditionalTage;

                if (!encountered[additionalTage]) {
                    distinctValues.push(obj);
                    encountered[additionalTage] = true;
                }
            }

            return distinctValues;
        };
        const findDuplicates_sp = arr => {
            let encountered = {};
            let distinctValues = [];

            for (let i = 0; i < arr.length; i++) {
                const obj = arr[i];
                const additionalTage = obj.AdditionalTage;

                if (!encountered[additionalTage]) {
                    distinctValues.push(obj);
                    encountered[additionalTage] = true;
                }
            }

            return distinctValues;
        };
        const isLastMessageChecked = ItemArray => {
      
            // Check if the array is not empty
            if (ItemArray?.length > 0) {
                // Get the last element in the array
                const lastlist = ItemArray[ItemArray.length - 1];

                // Check if the "checking" property is set to "checked"
                if (lastlist.checking === "checked") {
                    return "ตรวจสอบแล้วผ่าน";
                } else if (lastlist.checking === "reject") {
                    return "ไม่ผ่าน";
                }
            } else {
                return "บัญชียังไม่ได้ตรวจสอบ";
            }
        };

        const gotocheck = path => {
        
            window.location = path;
        };
        return {
            gotocheck,
            isLastMessageChecked,
            findDuplicates,
            findDuplicates_sp,
            showprice,
            groupBySellOrder,
            rowselecteddetail,
            rowselected,
            dataSource,
            dataSources,
            pagination,
            loading,
            title,
            columns,
            handleTableChange,
            querycustomer,
            formatdate,
            updatedata,
            getUniqueListBy,
            Util
        };
    }
});
</script>
<style lang="scss">
.ant-image-preview-img {
    margin: auto !important;
}

.ant-image-preview-img-wrapper {
    top: -880px !important;
}

.anticon svg {
    margin-top: -5px;
}

.ant-btn-dangerous {
    color: #000000 !important;
    background: #e6d495 !important;
    border-color: #b78c3b !important;
}

.ant-image-img {
    border-radius: 5px;
}

.anticon-copy {
    color: #000000 !important;
}
</style>
