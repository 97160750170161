<template>
    <div>
        <!-- Ms clear logout -->
    </div>
</template>
<script>
export default {
    name: 'MsClearLogout',
    created: function() {
        console.log('clear logout');
        localStorage.clear(); // clear ms cache
        window.location.replace('/loginad');
    },
}
</script>
<style lang="scss" scoped>
</style>
