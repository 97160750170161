import ConfigManager from "@/config/ConfigManager.js";
import axios from "axios";
import { Observable } from "rxjs";

/**
 * @method connectBzbsGet
 * @method connectBzbsDelete
 * @method connectBzbsPost
 * @method connectBzbsPut
 * @method connectBzbsGetBlob
 */
export default {
    /**
     * Connect Buzzebees GET method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data (optional)
     * @return Observable
     */
    // connectBzbsGet: function(url, token, data) {
    //     return this.connectMethod(url, token, data, "get");
    // },

    /**
     * Connect Buzzebees DELETe method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data (optional)
     * @return Observable
     */
    connectBzbsDelete: function(url, token, data) {
        return this.connectMethod(url, token, data, "delete");
    },

    /**
     * Connect Buzzebees POST method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data parameter
     * @param {string} uploadKey (optional)
     * @param {object} uploadfile (optional)
     */
    // connectBzbsPost: function (url, token, data, uploadKey, uploadfile) {
    //     return this.connectWithBody(url, token, data, uploadKey, uploadfile, 'post');
    // },
    connectBzbsPost: function(url, token, data, uploadKey, uploadfile) {
        // data.device_app_id = this.config().client_id;
        var form = this.arrToFormData(data);
        var header = {};
        if (uploadKey) form.append(uploadKey);
        if (uploadfile) {
            for (var i in uploadfile) {
                form.append(i, uploadfile[i]);
            }
        }
        if (token != null) {
            header.Authorization = "token " + token;
        }
        return axios({
            method: "post",
            url: url,
            data: data,
            header: header
        });
    },
    connectBzbsPostFile: function(url, token, data, uploadKey, uploadfile) {
        var config = {
            method: "post",
            url: url, 
            data: data
        };

        return axios(config)
            .then(function(response) {
               return response.data
            })
            .catch(function(error) {
                return error
            });
    },
    connectBzbsPostBlob: function(url, token, data, uploadKey, uploadfile) {
        // data.device_app_id = this.config().client_id;
        var form = this.arrToFormData(data);
        var header = {};
        if (uploadKey) form.append(uploadKey);
        if (uploadfile) {
            for (var i in uploadfile) {
                form.append(i, uploadfile[i]);
            }
        }
        if (token != null) {
            header.Authorization = "token " + token;
        }
        return axios({
            method: "post",
            url: url,
            data: data,
            header: header,
            responseType: "blob"
        });
    },
    /**
     * Connect Buzzebees PUT method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data parameter
     * @param {string} uploadKey (optional)
     * @param {object} uploadfile (optional)
     */
    connectBzbsPut: function(url, token, data, uploadKey, uploadfile) {
        return this.connectWithBody(
            url,
            token,
            data,
            uploadKey,
            uploadfile,
            "put"
        );
    },

    /**
     * Connect Buzzebees with Method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data parameter
     * @param {string} uploadKey (optional)
     * @param {object} uploadfile (optional)
     * @param {string} method post, put
     */
    connectWithBody: function(url, token, data, uploadKey, uploadfile, method) {
        return new Observable(subscriber => {
            var header = {
                // "App-Id": ConfigManager.getAppId()
            };
            // data.device_app_id = this.config().client_id;
            var form = this.arrToFormData(data);
            if (uploadKey) form.append(uploadKey, uploadfile);

            if (token != null) {
                header.Authorization = "token " + token;
            }

            axios({
                method: method,
                url: url,
                data: form,
                headers: header
            })
                .then(res => {
                    // subscriber.next(res);
                    var data = this.convertResponse(res);

                    if (data.isError) {
                        subscriber.error(this.setErrorObj(res));
                    } else {
                        subscriber.next(res);
                    }
                })
                .catch(err => {
                    subscriber.error(this.setErrorObj(err));
                });
        });
    },

    /**
     * Connect Buzzebees with Method
     * @param {string} url string url
     * @param {string} token (optional)
     * @param {object} data (optional)
     * @param {string} method (required) get, delete
     * @return Observable
     */
    connectMethod: function(url, token, data, method) {
        return new Observable(subscriber => {
            var header = {
                "App-Id": ConfigManager.getAppId()
            };
            if (data == null) {
                data = {};
            }
            // data.device_app_id = this.config().client_id;
            if (token != null) {
                header.Authorization = "token " + token;
            }
            axios({
                method: method,
                url: url,
                params: data,
                headers: header
            })
                .then(res => {
                    // subscriber.next(res);
                    var data = this.convertResponse(res);

                    if (data.isError) {
                        subscriber.error(this.setErrorObj(res));
                    } else {
                        subscriber.next(res);
                    }
                })
                .catch(err => {
                    subscriber.error(this.setErrorObj(err));
                });
        });
    },

    setErrorObj: function(err) {
        var error = {
            error: err,
            id: null
        };
        if (err.response && err.response.data) {
            error = {
                id: err.response.data.error.id,
                code: err.response.data.error.code,
                message: err.response.data.error.message,
                type: err.response.data.error.type
            };
        }
        return error;
    },

    /**
     * Convert data to array of FormData
     * @param {Array|Dictionary} arr
     */
    arrToFormData: function(arr) {
        var form = new FormData();
        for (var i in arr) {
            form.append(i, arr[i]);
        }
        return form;
    },

    /**
     * Connect Buzzebees GET Blob
     * @param {string} url string url
     * @return Promise
     */
    // connectBzbsGetBlob: function(url) {
    //     return new Observable(subscriber => {
    //         axios({
    //             method: "get",
    //             url: url
    //         })
    //             .then(res => {
    //                 subscriber.next(res);
    //             })
    //             .catch(err => {
    //                 subscriber.error(this.setErrorObj(err));
    //             });
    //     });
    // },

    connectBzbsGetBlob: function(url) {
        return axios({
            method: "get",
            url: url
        });
    },
    connectBzbsGet: function(url,token) { 
         
        var header = {};
        if (token != null) {
            header.Authorization = token;
        }
        return axios({
            method: "get",
            url: url,
            headers:header
        });
    },

    convertResponse: function(res) {
        var data = res.data;
        if (data.success === true || data.success === false) {
            return {
                isError: data.success ? false : true,
                data: data
            };
        } else {
            return {
                isError: false,
                data: data
            };
        }
    }
};
