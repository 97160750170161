<template>
    <div class="modal" :class="{'open' : open}">
        <div class="modal-backdrop"></div>
        <div class="modal-content hide-scroll">
            <div class="bg-white w-full rounded-xl p-3">
                <FormTaxAddress ref="form-tax-address"></FormTaxAddress>
                <div class="text-right px-2">
                    <button class="bg-gray-300 text-white rounded-md py-1 px-4 mr-2"
                            @click="clickCancel()">
                        Cancel
                    </button>
                    <button class="bg-blue-600 text-white rounded-md py-1 px-4"
                            @click="clickOk()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Util from "@/helper/Utility.js";
import LocaleMixin from "@/mixin/LocaleMixin.js";
//component
import FormTaxAddress from "@/module/Address/TaxAddress/component/FormTaxAddress.vue";
//service
import BzbsAddressList from "@/core/Address/service/BzbsAddressList.js";

export default {
    name: "AddTaxAddressModal",
    mixins: [LocaleMixin],
    components: {
        FormTaxAddress
    },
    props: {
        controller: Object,
    },
    data: function() {
        return {
            open: false,
            busModal: null,
        };
    },
    created: function() {
        this.busModal = this.controller.busModal;
    },
    mounted: function() {
        this.busModal.on('show', this.show);
        this.busModal.on('hide', this.hide);
    },
    methods: {
        show: function() {
            this.$refs['form-tax-address'].clearForm();
            document.body.classList.add('not-scroll');
            this.open = true;
        },
        hide: function() {
            document.body.classList.remove('not-scroll');
            this.open = false;
        },
        clickCancel: function() {
            this.hide();
            this.controller.sendCallbackCancel();
        },
        clickOk: function () {
            this.saveAddress();
        },

        saveAddress: function() {
            let formData = this.$refs['form-tax-address'].submitForm();
            // Util.log('save tax', formData.getParams());
            if (formData != null) {
                let params = formData.getParams();
                this.saveAddressApi(params);
            }
        },
        saveAddressApi: function(params) {
            this.hide();
            this.controller.loadingModal.show();

            BzbsAddressList.apiSaveTaxAddress(params).subscribe(() => {
                this.controller.loadingModal.hide();
                this.controller.sendCallbackAddSuccess();
                // console.log(data);
            }, (err) => {
                this.controller.loadingModal.hide();
                console.log(err);

                let msg = err.message;
                this.controller.alertModal.clearModal()
                        .setMsg(msg)
                        .show();
                this.controller.alertModal.onClickOk(() => {
                    this.show();
                });
            });
        },
    },
}
</script>
<style lang="scss" scoped>
    @import "@/style/base/base.scss";
    @import "@/style/modal.scss";

    .modal {
        @extend %modal;

        .modal-content {
            width: 420px;

            @include mobile {
                width: 100%;
                padding: 16px;
            }
        }
    }
</style>
