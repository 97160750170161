import Util from "@/helper/Utility.js";
import DatetimeHelper from "@/helper/DatetimeHelper.js";

export default {
    install: (app) => {
        app.mixin({
            methods: {
                FilterNumberFormat: function (value) {
                    var num = parseInt(value, 10);
                    return Util.formatNum(num, 0)
                },
                FilterPhoneFormat: function (value) {
                    if (value == null) {
                        return '';
                    }
                    var str = Util.contactNumberFormat(value, '-');
                    return str;
                },
                FilterStringFormat: function (format, value) {
                    return Util.stringFormat(format, value);
                },

                FilterDateFormat: function (timestamp) {
                    // Format : DD MMMM YYYY
                    return DatetimeHelper.timestampFullFormat(timestamp);
                },
                FilterTimeFormat: function (timestamp) {
                    // Format : hh:mm:ss
                    return DatetimeHelper.timeFormat(timestamp);
                },
                FilterDateTimeFormat: function (timestamp) {
                    // Format DD MMMM YYYY - hh:mm:ss
                    return DatetimeHelper.getDatetimeFullFormat(timestamp);
                },
            },
        });
    },
};
