<template>
    <div>
        <a-modal v-model:visible="visible" title="ตั้งชื่อ เซลเพจใหม่" @cancel="oncancel()" :maskClosable="false">
            <a-input v-model:value.trim="salepageid_prop"
                :addon-before="'https://' + shopname + '.' + config_namahayant.salepageurl + '/'"
                v-on:keypress="isLetter($event)" />
            <div class="pt-3">
                <a-alert v-if="hassuccess" message="เปลี่ยนชื่อ salepage สำเร็จ" type="success" />
                <a-alert v-if="haserror" message="มีร้านค้าอื่นใช้ชื่อนี้ในระบบแล้ว" type="error" />
            </div>
            <template #footer>
                <a-button v-if="!hassuccess" key="submit" type="primary" :loading="loading" @click="handleOk">
                    ยินยัน</a-button>
                <a-button v-if="hassuccess" key="submit" type="primary" @click="oncancel()"> ปิด</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent, ref, watch } from "vue";
import axios from "axios";
import { shopname } from '@/store/store'
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { config_namahayant } from '@/store/store'
import { useRoute } from "vue-router";
export default defineComponent({
    props: ['type', 'subdomainname', 'salepageid'],
    components: {
    },
    setup(props, { emit }) {
        const salepageid_prop = ref(props.salepageid)
        const route = useRoute();
        const mainsalepage = ref(route.query.mainsalepage)
        const edit_salepage_of_main_salepage = ref({
            fact: false,
            old_name_mainsalepage: ""
        })

        if (mainsalepage.value == salepageid_prop.value) {
            edit_salepage_of_main_salepage.value.fact = true;
            edit_salepage_of_main_salepage.value.old_name_mainsalepage = salepageid_prop.value;
            //การเปลี่ยนครั้งนี้ เป็นของ salepage หลัก
            //เมื่อ ตกลง เปลี่ยน เป็น ชื่อใหม่ ได้ชื่อใหม่
            // นำชื่อใหม่ ไป update กลับ แทนที่ mainsalepage

        }
        const visible = ref(true);
        const haserror = ref(false);
        const hassuccess = ref(false);
        const loading = ref(false);
        const showModal = () => {
            visible.value = true;
        };

        const subdomainname = ref(props.subdomainname);
        if (props.type == 'edit') {
            console.log("1");
            shopname.value = subdomainname.value
        }

        const handleOk = () => {

            console.log(shopname.value);
            querycustomer(shopname.value);
            // visible.value = false;
        };

        const oncancel = () => {
            window.location.reload()
        };

        const querycustomer = (shopname) => {
            if (!shopname) {
                return
            }
            loading.value = true;
            let path = window.location.host.includes("localhost")
                ? "http://localhost:8090"
                : process.env.VUE_APP_API_BASE_URL;

            return axios.get(path + "/api/changesalepagename/" + shopname.toLowerCase() + "/" + props.salepageid + "/" + salepageid_prop.value).then(res => {

                loading.value = false;
                if (res.data.length == 0) {
                    hassuccess.value = true;
                    emit('onshopname', shopname)
                    console.log(res.data[0]);

                    //update mainsalepage

                    if (edit_salepage_of_main_salepage.value.fact) {

                        setmainsalepage(shopname.toLowerCase(), salepageid_prop.value)
                    }
                }
                else {
                    haserror.value = true;
                    hassuccess.value = false;
                    //มีชื่อนี้แล้ว 
                }
                setTimeout(() => {
                    haserror.value = false;
                }, 5000);
            }).catch(error => {
                haserror.value = true;
                loading.value = false;
                console.log(error);
                setTimeout(() => {
                    haserror.value = false;
                }, 4000);
            });
        };

        const setmainsalepage = async (subdomain, salepagename) => {

            const param = {
                SubDomainName: subdomain,
                MainSalepage: salepagename,
                TYPE: 'UPDATE'
            }; 
            await BzbsUser.apisetmainsalepage(param)
                .then(res => {

                    console.log(res)
                    window.location = "/subdomainsalepage?id=" + subdomain + '&mainsalepage=' + salepagename;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const isLetter = (e) => {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if (/^[A-Za-z0-9]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
        watch(shopname, () => {
            hassuccess.value = false;
        })
        const startcreate = () => {

            if (props.type == "edit") {
                insertsalepage('UPDATE');
            }
            else {
                insertsalepage('CREATE');
            }

        };

        const insertsalepage = async (type) => {

            const param = {
                SubDomainName: shopname.value.toLowerCase(),
                OldSubDomainName: props.subdomainname,
                SalePageID: props.salepageid,
                TYPE: type
            };


            await BzbsUser.apicreatesubdomain(param)
                .then(res => {
                    console.log(res)
                    visible.value = false;
                    window.location.reload()
                })
                .catch(error => {
                    console.log(error);
                });
        };
        return {
            isLetter,
            visible,
            showModal,
            handleOk, shopname, querycustomer, haserror, hassuccess,
            loading,
            startcreate, oncancel, config_namahayant, salepageid_prop

        };
    }
});
</script>
