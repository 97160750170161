export function FormAddressModel() {
    this.firstname = '';
    this.lastname = '';
    this.contactNumber = '';
    this.address = '';
    this.provinceName = '';
    this.districtName = '';
    this.subDistrictName = '';
    this.zipcode = '';

    this.setValueFromAddressModel = function(addressModel) {
        this.provinceName = addressModel.provinceName;
        this.districtName = addressModel.districtName;
        this.subDistrictName = addressModel.subDistrictName;
        this.zipcode = addressModel.zipcode;
    };

    this.getFullAddressText = function() {
        var str = this.address + ' '
                + this.subDistrictName + ' '
                + this.districtName + ' '
                + this.provinceName;
        return str;
    };
}
