<template>
    <div class="page bg-view" v-if="detail != null">
        <!-- <TopBar>
            <template v-slot:toolbar> </template>
        </TopBar> -->
        <div class="bland">
            <div>
                <a-row>
                    <a-col :span="6">
                        <div class="logo">
                            <img src="../../../assets/imgs/logo/Logo_NA-Black.png" />
                        </div>
                    </a-col>
                    <a-col :span="12" class="officename">
                        <div>
                            <span class="text text-black text-sm pl-2 font-semibold ">
                                บริษัท นะมะหายัน กรุ๊ป จำกัด
                            </span>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <div class="m-10 position-card ">
            <a-steps>
                <a-step status="finish" title="ลูกค้า">
                    <template #icon>
                        <user-outlined />
                    </template>
                </a-step>
                <a-step status="finish" title="กรอกข้อมูล">
                    <template #icon>
                        <solution-outlined />
                    </template>
                </a-step>
                <a-step status="finish" title="รอชำระเงิน" :sub-title="
                    detail.SlipUrl == null
                        ? 'ยังไม่ได้รับสลิป'
                        : 'ได้รับสลิปแล้วกำลังตรวจสอบ'
                " :description="'สร้างเมื่อ ' + formatdate(detail.SellDate)" style="color: #fff;">
                    <template #icon v-if="detail.SlipUrl == null ? true : false">
                        <loading-outlined />
                    </template>
                    <template v-else #icon>
                        <check-outlined />
                    </template>
                </a-step>
                <a-step status="wait" title="กำลังจัดส่ง">
                    <template #icon>
                        <check-outlined />
                    </template>
                </a-step>
                <a-step status="wait" title="สำเร็จ">
                    <template #icon>
                        <smile-outlined />
                    </template>
                </a-step>
            </a-steps>
        </div>
        <div class="demo-page-header">
            <a-page-header :ghost="false" title="รายละเอียดลูกค้า" sub-title="(เปิดรายการหลังบ้าน)">
                <a-descriptions :size="'small'" :column="1">
                    <a-descriptions-item label="เลขรายการ">{{
                            $route.query.transaction
                    }}</a-descriptions-item>
                    <a-descriptions-item label="ชื่อ">
                        {{ detail.Customername }}
                    </a-descriptions-item>
                    <a-descriptions-item label="เบอร์ลูกค้า">
                        {{ detail.Customertel }}</a-descriptions-item>
                    <a-descriptions-item label="วันที่ซื้อ">{{
                            formatdate(detail.SellDate)
                    }}</a-descriptions-item>
                    <a-descriptions-item label="ที่อยู่">
                        {{ detail.CustomerAddress }}
                    </a-descriptions-item>
                    <a-descriptions-item label="รายละเอียด">
                        {{ detail.Introduction }}
                    </a-descriptions-item>
                </a-descriptions>
            </a-page-header>
        </div>
        <div>
            <a-card style="width: 100%" title="รูปสลิป" :tab-list="tabList" :active-tab-key="key"
                @tabChange="key => onTabChange(key, 'key')">
                <template #customTab="item">
                    <span v-if="item.key === 'tab1'">
                        <home-outlined />
                        รูปสลิป
                    </span>
                    <span v-if="item.key === 'tab2'">
                        <home-outlined />
                        อื่นๆ
                    </span>
                </template>
                <!-- <template #extra>
                    <a href="#">More</a>
                </template> -->
                <!-- {{ contentList[key] }} -->
                <div v-if="key == 'tab1'">
                    <div v-for="(items, k) in JSON.parse(detail.SlipUrl)" :key="k">
                        <a-image :src="items" />
                    </div>
                </div>
                <div v-if="key == 'tab2'"></div>
            </a-card>
        </div>
    </div>
</template>
<script>
// import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import { HomeOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, onMounted } from "vue";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { useRoute } from "vue-router";
import moment from "moment";
import "moment/locale/th"; // without this line it didn't work
// import moment from "moment";
import {
    UserOutlined,
    SolutionOutlined,
    LoadingOutlined,
    SmileOutlined,
    CheckOutlined
} from "@ant-design/icons-vue";
// export default defineComponent({
//     components: {
//         UserOutlined,
//         SolutionOutlined,
//         LoadingOutlined,
//         SmileOutlined,
//         TopBar,
//         HomeOutlined
//     }
// });

export default defineComponent({
    components: {
        HomeOutlined,
        UserOutlined,
        SolutionOutlined,
        LoadingOutlined,
        SmileOutlined,
        CheckOutlined
        // TopBar
    },
    setup() {
        const tabList = [
            {
                key: "tab1",
                tab: "tab1"
            },
            {
                key: "tab2",
                tab: "tab2"
            }
        ];
        const contentList = {
            tab1: "content1",
            tab2: "content2"
        };

        const slip = [
            "https://storagenamahayan.blob.core.windows.net/image/SO-20220414212355_1649946278.jpg",
            "https://storagenamahayan.blob.core.windows.net/image/SO-20220414212355_1649946279.jpg",
            "https://storagenamahayan.blob.core.windows.net/image/SO-20220414212355_1649946280.jpg"
        ];
        const detail = ref(null);
        const tabListNoTitle = [
            {
                key: "article",
                tab: "article"
            },
            {
                key: "app",
                tab: "app"
            },
            {
                key: "project",
                tab: "project"
            }
        ];
        const key = ref("tab1");
        const noTitleKey = ref("app");

        const onTabChange = (value, type) => {
            console.log(value, type);

            if (type === "key") {
                key.value = value;
            } else if (type === "noTitleKey") {
                noTitleKey.value = value;
            }
        };

        onMounted(() => {
            getdetail();
        });
        const getdetail = () => {
            const route = useRoute();
            let params = { SellRef: route.query.transaction };
            BzbsUser.getsellorderdetail(params)
                .then(res => {
                    detail.value = res.data[0][0];
                    console.log(res.data[0][0]);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("LL");
        };

        const formatdatetime = date => {
            return moment(date)
                .add(543, "year")
                .format("lll");
        };

        return {
            tabList,
            contentList,
            tabListNoTitle,
            key,
            noTitleKey,
            onTabChange,
            slip,
            getdetail,
            detail,
            formatdate,
            formatdatetime
        };
    }
});
</script>
<style lang="scss" scoped>
.demo-page-header :deep(tr:last-child td) {
    padding-bottom: 0;
}

.demo-page-header {
    margin: 10px !important;
}

.ant-page-header-compact {
    border-radius: 15px !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.logo {
    img {
        height: auto;
        width: 80px;
    }
}

.officename {
    align-items: center;
    justify-content: center;
    display: flex;
}

.bg-view {
    background: url("../../../assets/imgs/logo/viewer.png") no-repeat;
    min-height: 100px;
    width: 100%;
    background-size: contain;
    margin-top: 60px;
}

.ant-steps-vertical {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
}

.bland {
    position: relative;
    top: -70px;
}

.position-card {
    margin-top: 135px;
    border-radius: 15px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
</style>
