<template>
  <div>
    <a-table :row-selection="rowSelection" :data-source="dataSources" :columns="columns" :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)" >
      <template #headerCell="{ column }">
        <template v-if="column.key === 'name'">
          <span>ชื่อ</span>
        </template>
      </template>
      <template #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block" @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            " @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #bodyCell="{ text, column, record }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-if="column.key == 'address'">
          <ul>
            <!-- <li>
              <div>ตำบล : {{ record.DistrictName }}</div>
              <div>อำเภอ : {{ record.BorderName }}</div>
              <div>จังหวัด : {{ record.ProvinceName }}</div>
              <div>รหัสไปรณีย์ : {{ record.PostCode }}</div>
            </li>
            <li></li>
            <li></li>
            <li></li> -->
            <li>
              <a-input v-model:value="record.LineID" placeholder="LINE" :bordered="false">
                <template #prefix>
                  <img class="w-5"
                    src="https://upload.wikimedia.org/wikipedia/commons/2/2e/LINE_New_App_Icon_%282020-12%29.png" />
                </template>
              </a-input>
            </li>
            <li>
              <a-input v-model:value="record.Facebook" placeholder="FACEBOOK" :bordered="false">
                <template #prefix>
                  <img class="w-5" src="https://i.pinimg.com/originals/af/86/da/af86da825329b7138bb7ccc42ee01e3a.jpg" />
                </template>
              </a-input>
            </li>
          </ul>
        </template>
        <!-- <template v-if="column.key == 'phone'">
          {{ record.CustomerContractNumber }}
        </template> -->
        <!-- <template v-if="column.key == 'CustomerCode'">
          <div v-if="record.CustomerCode">
            {{ record.CustomerCode.trim() }}
          </div>
        </template> -->
      </template>
    </a-table>
  </div>
</template>
<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  reactive,
  ref,
  toRefs,
  computed,
  unref,
  onMounted,
} from "vue";
import { Table } from "ant-design-vue";
import axios from "axios";

export default defineComponent({
  components: {
    SearchOutlined,
  },
  emits: ["customercodeselected"],
  setup(props, { emit }) {
    const dataSources = ref(null);
    const state = reactive({
      searchText: "",
      searchedColumn: "",
    });
    const searchInput = ref();
    const columns = [
      // {
      //   title: "ชื่อ",
      //   dataIndex: "CustomerName",
      //   key: "CustomerName",
      //   customFilterDropdown: true,
      //   onFilter: (value, record) =>
      //     record.CustomerName.toString()
      //       .toLowerCase()
      //       .includes(value.toLowerCase()),
      //   onFilterDropdownVisibleChange: (visible) => {
      //     if (visible) {
      //       setTimeout(() => {
      //         searchInput.value.focus();
      //       }, 100);
      //     }
      //   },
      // },
      {
        title: "ชื่อการติดต่อ(สำรอง)",
        dataIndex: "ContactName",
        key: "ContactName",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.ContactName.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: "ชื่อสมาชิก(สำรอง)",
        dataIndex: "CustomerName",
        key: "CustomerName",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.CustomerName.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: "ชื่อช่องทางการติดต่อ",
        dataIndex: "Name",
        key: "Name",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.Name.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      }, 
      {
        title: "รหัสลูกค้า",
        dataIndex: "CustomerCode",
        key: "CustomerCode",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.CustomerCode.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: "เบอร์โทร",
        dataIndex: "CustomerPhoneThai",
        key: "CustomerPhoneThai",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.CustomerPhoneThai.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: "เบอร์โทร สำรอง",
        dataIndex: "CustomerContractNumber",
        key: "CustomerContractNumber",
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.CustomerContractNumber.toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: "ช่องทางติดต่อ",
        dataIndex: "address",
        width: "20%",
        key: "address",
        // customFilterDropdown: true,
        // onFilter: (value, record) =>
        //   record.address.toString().toLowerCase().includes(value.toLowerCase()),
        // onFilterDropdownVisibleChange: (visible) => {
        //   if (visible) {
        //     setTimeout(() => {
        //       searchInput.value.focus();
        //     }, 100);
        //   }
        // },
      },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters({
        confirm: true,
      });
      state.searchText = "";
    };
    const selectedRowKeys = ref([]); // Check here to configure the default column

    const onSelectChange = (changableRowKeys) => {
      console.log("selectedRowKeys changed: ", changableRowKeys);
      selectedRowKeys.value = changableRowKeys;
      emit("customercodeselected", dataSources.value[changableRowKeys[0]]);
    };

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        type: "radio",
        hideDefaultSelections: true,
        selections: [
          Table.SELECTION_ALL,
          Table.SELECTION_INVERT,
          Table.SELECTION_NONE,
          {
            key: "odd",
            text: "Select Odd Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((_key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }

                return true;
              });
              selectedRowKeys.value = newSelectedRowKeys;
            },
          },
          {
            key: "even",
            text: "Select Even Row",
            onSelect: (changableRowKeys) => {
              let newSelectedRowKeys = [];
              newSelectedRowKeys = changableRowKeys.filter((_key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }

                return false;
              });
              selectedRowKeys.value = newSelectedRowKeys;
            },
          },
        ],
      };
    });

    onMounted(() => {
      querycustomer();
    });

    const querycustomer = () => {
      let path = window.location.host.includes("localhost")
        ? "http://localhost:8090"
        : process.env.VUE_APP_API_BASE_URL;
      return axios.get(path + "/api/customer").then((res) => {
        let temp_list = [];
        res.data[0].forEach((element, index) => {
          element.key = index;
          temp_list.push(element);
        });
        dataSources.value = temp_list;
         
        // dataSources.value = res.data[0];
        console.log("SEARCH ::", res.data[0]);
      });
    };
    return {
      dataSources,
      columns,
      handleSearch,
      handleReset,
      searchInput,
      ...toRefs(state),
      rowSelection,
    };
  },
});
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>