/**
 * @export TrackingService
 * @export TrackElement
 */

/**
 * @method screenName       (name)
 * @method event            (category, action, label, value)
 * @method dashboard        (category, action, dashboardItem, value)
 * @method dashboardList    (category, action, dashboardList, value)
 * @method campaign         (category, action, campaign, value)
 * @method campaignList     (category, action, campaignList, value)
 * @method category         (category, action, catObject, trackWithName)
 * @method categoryList     (category, action, catObjectList, trackWithName)
 */
 export function TrackingService(firebaseTrack) {
    this.firebaseTrack = firebaseTrack;

    /**
     * @param {string} name
     */
    this.screenName = function (name) {
        this.firebaseTrack.setCurrentScreen(name);

        try {
            window.BzbsAnalytic.trackScreen(name);
        } catch(e) {
            // console.log(e);
        }
    };

    /**
     * @param {string} category
     * @param {string} action
     * @param {string} label
     * @param {string} value optional
     */
    this.event = function(category, action, label, value) {
        this.firebaseTrack.logEvent(category, {
            action: action,
            label: label,
            value: value,
        });

        try {
            window.BzbsAnalytic.trackEvent(category, action, label, value);
        } catch(e) {
            // console.log(e);
        }
    };

    /**
     * Track dashboard
     * @param {string} category - not null
     * @param {string} action - not null
     * @param {Object} dashboardItem - Dashboard object (not null)
     * @param {string} value - optional
     */
    this.dashboard = function(category, action, dashboardItem, value) {
        var type = dashboardItem.type || dashboardItem.mode;
        switch (type) {
            case "campaign":
                if (dashboardItem.id) {
                    this.event(category, action, dashboardItem.id + "|" + dashboardItem.strGA);
                }
                break;
            case "cat":
                var _catId = dashboardItem.cat || dashboardItem.id || "";
                if (_catId) {
                    this.event(category, action, _catId + "|" + dashboardItem.strGA);
                }
                break;
            case "campaign_rotate":
                var _campaign_rotage = dashboardItem.subCampaignDetails;
                if (_campaign_rotage && _campaign_rotage.length > 0) {
                    for (var campaign of _campaign_rotage) {
                        this.event(category, action, campaign, value);
                    }
                }
                break;
            case "link":
                this.event(category, action);
                break;

            case "none":
                this.event(category, action);
                break;
            case "menu":
            case "event":
                break;
        }
    };

    /**
     * Track dashboard list
     * @param {string} category - not null
     * @param {string} action - not null
     * @param {Array} dashboardList - Dashboard list (not null)
     * @param {string} value - optional
     */
    this.dashboardList = function(category, action, dashboardList, value) {
        for (var dashboard of dashboardList) {
            this.dashboard(category, action, dashboard, value);
        }
    };

    /**
     * Track campaign item
     * @param {string} category - not null
     * @param {string} action - not null
     * @param {Object} campaign - Campaign object (not null)
     * @param {string} value - optional
     */
    this.campaign = function(category, action, campaign, value) {
        this.event(category, action, campaign.id + '|' + campaign.name, value);
    };

    /**
     * Track campaign list
     * @param {string} category - not null
     * @param {string} action - not null
     * @param {Array} campaignList - Campaign list (not null)
     * @param {string} value - optional
     */
    this.campaignList = function(category, action, campaignList, value) {
        for (var campaign of campaignList) {
            this.campaign(category, action, campaign, value);
        }
    };

    /**
     * Track category
     * @param {string} category
     * @param {string} action
     * @param {Object} catObject
     * @param {boolean} trackWithName
     */
    this.category = function(category, action, catObject, trackWithName) {
        if (trackWithName) {
            this.event(category + catObject.name, action + catObject.name, catObject.id + '|' + catObject.name);
        } else {
            this.event(category, action, catObject.id + '|' + catObject.name);
        }
    };

    /**
     * Track category list
     * @param {string} category
     * @param {string} action
     * @param {Array} catObjectList
     * @param {boolean} trackWithName
     */
    this.categoryList = function(category, action, catObjectList, trackWithName) {
        for (var cat of catObjectList) {
            this.category(category, action, cat, trackWithName);
        }
    };
}



/**
 * constructor
 * @param {TrackingService} trackingService class above
 *
 * @method registerElemTrack
 */
export function TrackElement(trackingService) {
    //#region private
    // this.vue = vue;
    this.trackingService = trackingService;

    var option = {
        // root: document.querySelector('#app'),
        threshold: [0],
    };
    this.observer = new IntersectionObserver((entries) => {
        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                var elemId = entries[i].target.id;
                console.log('Element has just become visible in screen, id : ' + elemId);
                //console.log(entries);
                this.sendTrack(entries[i]);
                this.observer.unobserve(document.querySelector('#' + elemId));
            }
        }
    }, option);

    this.sendTrack = function(entrie) {
        var category = entrie.target.attributes['bzb-track-category'].value;
        var action = entrie.target.attributes['bzb-track-action'].value;
        var label = entrie.target.attributes['bzb-track-label'].value;
        // this.vue.ga.event(this.vue, category, action, label);
        this.trackingService.event(category, action, label);
    };
    //#endregion private

    //#region public
    /**
     * @param {string} elemId Element id you need to track when render on screen.
     */
    this.registerElemTrack = function(elemId) {
        this.observer.observe(document.querySelector("#" + elemId));
    };
    //#endregion public
}
