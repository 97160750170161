<template>
    <div
        class="page max-w-full overflow-x-hidden w-full h-full flex flex-col card"
    >
        <TopBar class="flex-none" :breadcrumb="bcItems">
            <template v-slot:toolbar>
                <Toolbar
                    @clickexport="modalController.exportModal = true"
                ></Toolbar>
            </template>
        </TopBar>
        <!-- formState.masterproduct.mastertype.isshowsteps: {{ formState.masterproduct.isshowsteps }} -->
        <Step></Step>

        <!-- <div class="pb-4">
                {{ !isinsertnewtype ? 'เลือกประเภท' : '' }}
                                                                                                                                                                                                                                                                                                                                                                        </div> -->
        <!-- <a-row v-show="!isinsertnewtype" type="flex" justify="space-around" align="middle">
                <a-select v-model:value="value" show-search placeholder="Select a person" style="width: 200px"
                    :options="options" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
                    @change="handleChange"></a-select>
                                                                                                                                                                                                                                                                                                                                                                        </a-row> -->

        <!-- data : {{ data.length > 0 }} -->
        <!-- <pre>{{ formState.masterproduct }}</pre> -->
        <div class="p-4" v-if="data.length > 0">
            <a-table
                v-show="!isshowdetail"
                :columns="columns"
                :data-source="data"
                size="small"
                :pagination="{ pageSize: 20 }"
                :row-class-name="
                    (_record, index) =>
                        index % 2 === 1 ? 'table-striped' : null
                "
            >
                <template
                    #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                >
                    <div style="padding: 8px">
                        <a-input
                            ref="searchInput"
                            :placeholder="`Search ${column.dataIndex}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="
                                e =>
                                    setSelectedKeys(
                                        e.target.value ? [e.target.value] : []
                                    )
                            "
                            @pressEnter="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        />
                        <a-button
                            type="primary"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="
                                handleSearch(
                                    selectedKeys,
                                    confirm,
                                    column.dataIndex
                                )
                            "
                        >
                            <template #icon>
                                <SearchOutlined />
                            </template>
                            Search
                        </a-button>
                        <a-button
                            size="small"
                            style="width: 90px"
                            @click="handleReset(clearFilters)"
                        >
                            Reset
                        </a-button>
                    </div>
                </template>
                <template #customFilterIcon="{ filtered }">
                    <search-outlined
                        :style="{ color: filtered ? '#108ee9' : undefined }"
                    />
                </template>
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.key === 'operation'">
                        <!-- <a @click="eventdelete(text)">ลบ</a> -->
                        <a-popconfirm
                            :title="'ลบสินค้า ' + text.name"
                            @confirm="confirm(text)"
                            @cancel="cancel"
                        >
                            <a-button type="primary">ลบ</a-button>
                        </a-popconfirm>
                    </template>
                    <template v-if="column.dataIndex === 'name'">
                        <a @click="showdetail(record.productcode)">{{
                            text
                        }}</a>
                    </template>
                    <template v-if="column.dataIndex === 'detail'">
                        {{ record.category_1 }}
                        {{ record.category_2 }}
                        {{ record.category_3 }}
                        {{ record.category_4 }}
                    </template>
                </template>
            </a-table>
        </div>
        <!-- formState.masterproduct.items.detail : {{ formState.masterproduct.items.detail }}
                                                                                                                                                                                                                                             -->

        <div class="p-4" v-if="isshowdetail">
            <div>
                <a-button type="link" @click="isshowdetail = false"
                    >กลับ</a-button
                >
            </div>

            <a-form
                :model="formState"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <div class="p-4">
                    รายละเอียด(แก้ไข)
                    <a-button
                        class="ml-4"
                        type="primary"
                        @click="showModalhistory"
                        >ประวัติการแก้</a-button
                    > 
                    <DetailCatagory 
                        v-if="formState.masterproduct.items.detail.Type"
                        :detail="formState.masterproduct.items.detail"
                    ></DetailCatagory>
                </div>

                <div>
                    <a-modal
                        v-model:visible="visiblehistory"
                        title="ประวัติการแก้ไข"
                        @ok="handleOkhistory"
                    >
                        <div
                            v-if="
                                formState.masterproduct?.items?.detaillog
                                    ?.length == 0
                            "
                        >
                            ไม่พบข้อมูลการแก้ไข
                        </div>
                        <div
                            v-for="(element, index) in formState.masterproduct
                                .items.detaillog"
                            :key="`day-${index}`"
                        >
                            <div>
                                วันที่แก้ไข
                                {{ formatdate(element.timestamp_updatedate) }}
                            </div>
                            <div class="p-3">
                                <div class="p-1">
                                    <i>
                                        {{
                                            element.Category_1 +
                                                " " +
                                                element.Name +
                                                " รหัสสินค้า " +
                                                element.ProductCode
                                        }}</i
                                    >
                                </div>
                                <div class="p-1">
                                    ราคาขาย
                                    {{ element.Price?.toLocaleString() }} บาท
                                </div>
                                <div class="p-1">
                                    ราคาทุน
                                    {{ element.Cost?.toLocaleString() }} บาท
                                </div>
                                <hr />
                            </div>
                        </div>
                    </a-modal>
                </div>
                <a-form-item label="ชื่อสินค้าแสดงลูกค้า">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Name
                        "
                    />
                </a-form-item>
                <!-- <a-form-item label="ขนาด">
                    <a-input v-model:value="formState.masterproduct.items.detail.Size" />
                </a-form-item>
                <a-form-item label="รหัสขนาด">
                    <a-input v-model:value="formState.masterproduct.items.detail.SizeCode" />
                                                                                                            </a-form-item> -->
                <a-form-item label="หมวดหมู่ 1">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_1
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 1"
                        :options="options_category_1"
                        @change="handleChange_Category_1($event)"
                    ></a-select>
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 1">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_1_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <template #title>จิ้มดูรายละเอียด</template>
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 2">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_2
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 2"
                        :options="options_category_2"
                        @change="handleChange_Category_2"
                    ></a-select>
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 2">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_2_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 3">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_3
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 3"
                        :options="options_category_3"
                        @change="handleChange_Category_3"
                    ></a-select>
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 3">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_3_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <a-form-item label="หมวดหมู่ 4">
                    <a-select
                        v-model:value="
                            formState.masterproduct.items.detail.Category_4
                        "
                        mode="tags"
                        style="width: 100%"
                        placeholder="หมวดหมู่ 4"
                        :options="options_category_4"
                        @change="handleChange_Category_4"
                    ></a-select>
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 4">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Category_4_Code
                        "
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                    <a-tooltip>
                        <!-- <template #title></template> -->
                    </a-tooltip>
                </a-form-item>
                <!-- <a-form-item label="หมวดหมู่ 3">
                    <a-textarea v-model:value="formState.masterproduct.items.detail.Category_3"
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                </a-form-item>
                <a-form-item label="รหัส หมวดหมู่ 3">
                    <a-textarea v-model:value="formState.masterproduct.items.detail.Category_3_Code"
                        :auto-size="{ minRows: 2, maxRows: 5 }" />
                            </a-form-item> -->
                <a-form-item label="ตัวขั้น Hash track">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Track
                        "
                    />
                </a-form-item>
                <a-form-item label="รหัสสินค้า">
                    <a-input
                    disabled
                        v-model:value="
                            formState.masterproduct.items.detail.ProductCode
                        "
                    />
                </a-form-item>
                <a-form-item label="ราคาขาย(ตัวเลข)">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Price
                        "
                        @input="fomatNum()"
                        type="number"
                    />
                </a-form-item>
                <a-form-item label="ต้นทุน(ตัวเลข)">
                    <a-input
                        v-model:value="
                            formState.masterproduct.items.detail.Cost
                        "
                        @input="fomatNum()"
                        type="number"
                    />
                </a-form-item>

                <a-form-item label="ตัวย่อ">
                    <a-textarea
                        v-model:value="
                            formState.masterproduct.items.detail.Desc
                        "
                        placeholder="ตัวย่อ"
                        :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                </a-form-item>
                <a-form-item label="รูปสินค้า">
                    <UploadProductImage
                        :key="keyupdate"
                        :sizeupimage="1"
                        :contactimageurl="
                            formState.masterproduct.items.detail.ProductImage
                        "
                        @fileList="
                            formState.masterproduct.items.detail.ProductImage = $event
                        "
                    >
                    </UploadProductImage>

                    <!-- <pre>{{ formState.masterproduct }}</pre> -->
                </a-form-item>

                <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-space :size="80">
                        <a-button type="primary" @click="isshowdetail = false"
                            >กลับ</a-button
                        >
                        <a-popconfirm
                            :title="'บันทึก'"
                            @confirm="onSubmit()"
                            @cancel="cancel"
                        >
                            <a-button type="primary">บันทึก</a-button>
                        </a-popconfirm>
                    </a-space>
                    <!-- <a-button style="margin-left: 10px">Cancel</a-button> -->
                </a-form-item>
            </a-form>
        </div>
        <!-- <div class="mt-6 text-center">
            <a-button class="pt-4" v-if="!isinsertnewtype" type="primary"
                @click="insertnewtype('เพิ่ม')">เพิ่มประเภทใหม่</a-button>
            <a-button class="pt-4" v-if="isinsertnewtype" type="primary"
                @click="insertnewtype('เพิ่มเสร็จ')">ตกลง</a-button>
                                                                                                                                                                                                                                            </div> -->
    </div>
</template>
<script>
import DetailCatagory from "@/module/Product/CustomerList/component/DetailCatagory.vue";
import Step from "@/module/Product/CustomerList/component/Step.vue";
import { defineComponent, ref, reactive } from "vue";
import TopBar from "@/components/Layout/TopBar/TopBar.vue";
import { useRouter, useRoute } from "vue-router";
import { formStateStore } from "@/store/storebackoffice";
import BzbsUser from "@/core/UserManagement/callapi/BzbsUser";
import { message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import Util from "@/helper/Utility";
import moment from "moment";
import UploadProductImage from "../UploadProductImage";

const columns = [
    {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
        width: 80,
        customFilterDropdown: true,
        onFilter: (value, record) =>
            record.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    searchInput.value.focus();
                }, 100);
            }
        }
    },
    {
        title: "รายละเอียด",
        dataIndex: "detail",
        key: "detail",
        width: 80
    },
    {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100
    }
];

export default defineComponent({
    components: {
        TopBar,
        Step,
        SearchOutlined,
        UploadProductImage,
        DetailCatagory
    },
    setup() {
        const keyupdate = ref(0);
        const state = reactive({
            searchText: "",
            searchedColumn: ""
        });
        const searchInput = ref();

        const router = useRouter();
        const route = useRoute();
        const type_selected = ref(route.query.type);

        const data = ref([]);
        // router.push({ path: '/insertdetailproduct' })
        const formState = reactive(formStateStore);
        formState.masterproduct.createnew_type = {
            name: "",
            productcode: "",
            type: "",
            typecode: ""
        };

        console.log("before ", formState.masterproduct.items);
        formState.masterproduct.items = {
            detail: {}
        };
        console.log("after ", formState.masterproduct.items);

        // formState.masterproduct = {
        //     item: {}
        // }
        const isinsertnewtype = ref(false);

        // if (route.path != "/product") {
        //     formState.masterproduct.isshowsteps = true
        // }

        formState.masterproduct.status = 1;
        formState.masterproduct.isshowsteps = true;

        const productData = [
            "ยันต์เขียนสด",
            "ยันต์ปลด/คิวด่วน",
            "วัตถุมงคล",
            "เคส/ภาพพื้นหลัง"
        ];
        const options = ref([]);
        const handleChange = value => {
            formState.masterproduct.items.type = value;
            // window.location = '/insertdetailproduct'
            //this.$router.push({ path: '/insertdetailproduct' })
            // router.push({ path: '/insertdetailproduct', hash: '#team' })

            setTimeout(() => {
                router.push("/insertdetailproduct");
            }, 100);
            console.log(`selected ${value}`);
        };
        const handleBlur = () => {
            console.log("blur");
        };
        const handleFocus = () => {
            console.log("focus");
        };
        const filterOption = (input, option) => {
            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        };

        const insertnewtype = txt => {
            if (txt == "เพิ่ม") {
                isinsertnewtype.value = true;
            } else {
                isinsertnewtype.value = false;
                apiinsertnewtype();
            }
        };

        const apiinsertnewtype = () => {
            var param = {
                Name: formState.masterproduct.createnew_type.name,
                ProductCode: formState.masterproduct.createnew_type.productcode,
                Type: formState.masterproduct.createnew_type.type,
                TypeCode: formState.masterproduct.createnew_type.typecode
            };
            BzbsUser.apiupdatemastertype(param)
                .then(res => {
                    console.log(res.data);
                    apigettype();
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const apigettype = () => {
            BzbsUser.apigetmastertype()
                .then(res => {
                    console.log(res.data);

                    res.data?.forEach(element => {
                        options.value.push({
                            value: element.Name,
                            label: element.Name
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };
        apigettype();

        const apigetallmasterproduct = type => {
            type = type_selected.value;

            BzbsUser.apigetmasterproduct(type)
                .then(res => {
                    data.value = [];
                    console.log(res.data);
                    res.data?.forEach((element, index) => {
                        data.value.push({
                            key: index + 1,
                            name: element.Name,
                            size: element.Size,
                            productcode: element.ProductCode,
                            category_1: element.Category_1,
                            category_2: element.Category_2,
                            category_3: element.Category_3,
                            category_4: element.Category_4

                            // address: 'New York No. 1 Lake Park, New York No. 1 Lake Park',
                            // tags: ['nice', 'developer'],
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        apigetallmasterproduct(formState.masterproduct.items.type);

        const showdetail = productcode => {
            formState.masterproduct.status = 2;

            apigetallmasterproductbyname(productcode);
        };

        const eventdelete = event => {
            apideletemasterproduct(event.name);
        };

        const isshowdetail = ref(false);
        const apigetallmasterproductbyname = productcode => {
            BzbsUser.apigetmasterproductbyname(productcode)
                .then(res => {
                    apigetLogmasterproductbyname(productcode);
                    console.log(res.data);
                    formState.masterproduct.items.detail = res.data[0];
                    isshowdetail.value = true;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const apigetLogmasterproductbyname = productcode => {
            BzbsUser.apiget_log_masterproductbyname(productcode)
                .then(res => {
                    formState.masterproduct.items.detaillog = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const apideletemasterproduct = name => {
            var param = {
                Name: name
            };
            BzbsUser.apideletemasterproduct(param)
                .then(res => {})
                .catch(error => {
                    console.log(error);
                });
        };

        const success = () => {
            Modal.success({
                title: "บันทึกสำเร็จ !!",
                onOk() {
                    // window.location = "/selllist";
                    //  this.$router.push({ path: "/selllist" });
                }
            });
        };
        const onSubmit = () => {
            apiinsertdetail();
            // console.log('submit!', toRaw(formState));
        };

        const apiinsertdetail = async () => {
            formState.masterproduct.items.detail.BarCode =
                formState.masterproduct.items.detail.ProductCode;

            if (
                formState.masterproduct.items.detail.ProductImage != undefined
            ) {
                var uploadparam = {};
                uploadparam = {
                    filename:
                        formState.masterproduct.items.detail.ProductImage[0]
                            .name +
                        "_" +
                        Math.floor(Date.now() / 1000) +
                        ".jpg",
                    base64:
                        formState.masterproduct.items.detail.ProductImage[0]
                            .preview,
                    folder: "product"
                };
                console.log("uploadparam : ", uploadparam);
                await BzbsUser.apiuploadimage(uploadparam)
                    .then(res => {
                        formState.masterproduct.items.detail.ProductImage = JSON.stringify(
                            [res.data.url]
                        );
                        console.log(res.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

            await BzbsUser.apiupdatemasterproduct(
                formState.masterproduct.items.detail
            )
                .then(res => {
                    apigetLogmasterproductbyname(
                        formState.masterproduct.items.detail.ProductCode
                    );
                    success();
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const confirm = e => {
            console.log(e);
            return new Promise(resolve => {
                apideletemasterproduct(e.name);
                apigetallmasterproduct(formState.masterproduct.items.type);
                // setTimeout(() => resolve(true), 3000);
            });
        };
        const cancel = e => {
            console.log(e);
            // message.error('Click on No');
        };
        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };
        const handleReset = clearFilters => {
            clearFilters({
                confirm: true
            });
            state.searchText = "";
        };
        const fomatNum = val => {
            return Util.formatNum(val, 0);
        };
        const visiblehistory = ref(false);
        const showModalhistory = () => {
            visiblehistory.value = true;
        };
        const handleOkhistory = e => {
            console.log(e);
            visiblehistory.value = false;
        };

        const formatdate = date => {
            return moment(date)
                .add(543, "year")
                .format("L");
        };
        const options_category_1 = ref([]);
        const options_category_2 = ref([]);
        const options_category_3 = ref([]);
        const options_category_4 = ref([]);

        const _init_options_category_1 = async () => {
            var obj = {
                type: type_selected.value
            };
            await BzbsUser.apigetoptionscategory1(obj)
                .then(res => {
                    debugger;
                    options_category_1.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const _init_options_category_2 = async () => {
            var obj = {
                type: type_selected.value,
                category_1: formState.masterproduct.items.detail.Category_1?.toString()
            };
            console.log("_init_options_category_2 :", obj);
            await BzbsUser.apigetoptionscategory2(obj)
                .then(res => {
                    debugger;
                    options_category_2.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const _init_options_category_3 = async () => {
            debugger;
            var obj = {
                type: type_selected.value,
                category_2: formState.masterproduct.items.detail.Category_2?.toString()
            };
            console.log("obj : ", obj);
            await BzbsUser.apigetoptionscategory3(obj)
                .then(res => {
                    debugger;
                    options_category_3.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };
        const _init_options_category_4 = async () => {
            debugger;
            var obj = {
                type: type_selected.value,
                category_3: formState.masterproduct.items.detail.Category_3?.toString()
            };
            console.log("obj : ", obj);
            await BzbsUser.apigetoptionscategory4(obj)
                .then(res => {
                    debugger;
                    options_category_4.value = res.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        _init_options_category_1();

        const handleChange_Category_1 = e => {
            formState.masterproduct.items.detail.Category_2 = [];
            formState.masterproduct.items.detail.Category_2_Code = [];
            formState.masterproduct.items.detail.Category_3 = [];
            formState.masterproduct.items.detail.Category_3_Code = [];
            formState.masterproduct.items.detail.Category_4 = [];
            formState.masterproduct.items.detail.Category_4_Code = [];
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_1.length > 1) {
                formState.masterproduct.items.detail.Category_1 = formState.masterproduct.items.detail.Category_1.pop();
            }
            formState.masterproduct.items.detail.Category_1_Code = options_category_1.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_1
            )?.Category_1_Code;
            _init_options_category_2();
        };
        const handleChange_Category_2 = e => {
            formState.masterproduct.items.detail.Category_3 = [];
            formState.masterproduct.items.detail.Category_3_Code = [];
            formState.masterproduct.items.detail.Category_4 = [];
            formState.masterproduct.items.detail.Category_4_Code = [];
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_2.length > 1) {
                formState.masterproduct.items.detail.Category_2 = formState.masterproduct.items.detail.Category_2.pop();
            }
            formState.masterproduct.items.detail.Category_2_Code = options_category_2.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_2
            )?.Category_2_Code;
            _init_options_category_3();
        };
        const handleChange_Category_3 = e => {
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_3.length > 1) {
                formState.masterproduct.items.detail.Category_3 = formState.masterproduct.items.detail.Category_3.pop();
            }
            formState.masterproduct.items.detail.Category_3_Code = options_category_3.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_3
            )?.Category_3_Code;
            _init_options_category_4();
        };
        const handleChange_Category_4 = e => {
            console.log("eee : ", e);

            if (formState.masterproduct.items.detail.Category_4.length > 1) {
                formState.masterproduct.items.detail.Category_4 = formState.masterproduct.items.detail.Category_4.pop();
            }
            formState.masterproduct.items.detail.Category_4_Code = options_category_4.value.find(
                item =>
                    item.value ==
                    formState.masterproduct.items.detail.Category_4
            )?.Category_4_Code;
        };

        return {
            options_category_1,
            options_category_2,
            options_category_3,
            options_category_4,
            handleChange_Category_1,
            handleChange_Category_2,
            handleChange_Category_3,
            handleChange_Category_4,
            formatdate,
            showModalhistory,
            visiblehistory,
            handleOkhistory,
            fomatNum,
            handleSearch,
            handleReset,
            confirm,
            cancel,
            eventdelete,
            apideletemasterproduct,
            onSubmit,
            apigettype,
            value: ref(undefined),
            filterOption,
            handleBlur,
            handleFocus,
            handleChange,
            options,
            formState,
            isinsertnewtype,
            insertnewtype,
            data,
            columns,
            showdetail,
            isshowdetail,
            labelCol: {
                style: {
                    width: "150px"
                }
            },
            wrapperCol: {
                span: 14
            }
        };
    }
});
</script>
